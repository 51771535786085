import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseControl } from "app/models/dynamic-form";
import { of, Observable } from "rxjs";
import Utils from "app/util/utils";
import Config from 'app/util/config';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';

@Injectable()
export class PersonalizeReturnService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    if (id != null)
    {
      return this.service.get(urlConfig.BASIC_INFO_Personalize_Return_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));
    } else
    {
      return this.service.get(urlConfig.BASIC_INFO_Personalize_Return_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }

  }

  submitPersonalizeReturnJson(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.BASIC_INFO_Personalize_Return_Submit_Url, model);
  }
  getJson(): any
  {
    const jsonData = [
      {
        "control": "LabelControl",
        "key": "personalInformation",
        "label": "Personalize Income",
        "errorType": "",
        "message": "",
        "tooltip": "Here taxpayer will personalize his/her personal income",
        "order": 1
      },

      {
        "control": "DropdownControl",
        "key": "OtherIncomeSources",
        "label": "Select your source of income",
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "Just add your data to all the sectors of income and assets that you have ticked here. You can identify the sectors from the left menu by the badges next to them, which show the amount of money.",
        "options": [
          {
            "key": "1",
            "value": "Salary",
            "label": "(Basic salary, festival allowance, medical allowance, support staff allowance etc.)"
          },
          {
            "key": "2",
            "value": "Interest On Security",
            "label": "(Shanchaypatra, bond, debenture etc.)"
          },
          {
            "key": "3",
            "value": "Property",
            "label": "(Rental income from house by giving for residential or commercial use.)"
          },
          {
            "key": "4",
            "value": "Agriculture",
            "label": "(Income by producing crops like rice, vegetable, wheat, potato etc.)"
          },
          {
            "key": "5",
            "value": "Business/ Profession",
            "label": "(Income from business or profession, for example, actor, doctor, engineer, lawyer etc.)"
          },
          {
            "key": "6",
            "value": "Share Of Profit In a Firm",
            "label": "(Income from partnership firm or individual-association.)"
          },
          {
            "key": "7",
            "value": "Spouse/ Child",
            "label": "(Spouse or minor child income. Add if they aren't individual taxpayers.)"
          },
          {
            "key": "8",
            "value": "Capital Gains",
            "label": "(Profit from transferring property. Refers to land, houses, apartments, commercial space, signing money, stock exchange shares or mutual fund units etc.)"
          },
          {
            "key": "9",
            "value": "Other Sources",
            "label": "(For example, Mutual Fund, Cash dividend, Bond, Derector's fee, Honorarium from writing articles, Remuneration as examiner and invigilator, Income from Tuition, Television or Radio.)"
          },
          {
            "key": "10",
            "value": "Foreign Income",
            "label": "(Income in foreign currency)"
          },
        ],
        "order": 2
      },
      {
        "control": "LabelControl",
        "key": "personalizeAsset",
        "label": "Personalize Asset",
        "errorType": "",
        "message": "",
        "tooltip": "Here taxpayer will personalize his/her assets",
        "order": 3
      },
      {
        "control": "RadioButtonControl",
        "key": "HaveEligibleAssetValue",
        "label": "On the last day of the income year, did your total assets exceed 40 lakh taka?",
        "options": [
          {
            "key": "1",
            "value": "Yes"
          },
          {
            "key": "2",
            "value": "No"
          }
        ],
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "It is mandatory for an individual assessee to submit the statement of assets, liabilities and lifestyle, if he has a gross wealth over Tk. 4 million, or owns a motor car, or has made an investment in a house property or an apartment in the city corporation area.",
        "order": 4
      },
      {
        "control": "RadioButtonControl",
        "key": "HaveMotorCar",
        "label": "On the last day of the income year, were you an owner of a motor car (jeep or microbus)?",
        "options": [
          {
            "key": "1",
            "value": "Yes"
          },
          {
            "key": "2",
            "value": "No"
          }
        ],
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "It is mandatory for an individual assessee to submit the statement of assets, liabilities and lifestyle, if he has a gross wealth over Tk. 4 million, or owns a motor car, or has made an investment in a house property or an apartment in the city corporation area.",
        "order": 5
      },
      {
        "control": "RadioButtonControl",
        "key": "HaveHouseProperty",
        "label": "On the last day of the income year, were you an owner or investor of any house property or apartment in the city corporation area?",
        "options": [
          {
            "key": "1",
            "value": "Yes"
          },
          {
            "key": "2",
            "value": "No"
          }
        ],
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "It is mandatory for an individual assessee to submit the statement of assets, liabilities and lifestyle, if he has a gross wealth over Tk. 4 million, or owns a motor car, or has made an investment in a house property or an apartment in the city corporation area.",
        "order": 6
      },
      {
        "control": "DropdownControl",
        "key": "AcquiredAssets",
        "label": "Select your gained assets",
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "Taxpayer will select his/her gained assets",
        "options": [
          {
            "key": "1",
            "value": "Business Capital",
            "label":"(If taxpayer have any business than closing balance of the business capital will mention here.)"
          },
          {
            "key": "2",
            "value": "Director's Shareholding Assets",
            "label":"(This refers the purchase cost of the share of director shareholding in limited company.)"
          },
          {
            "key": "3",
            "value": "Non-Agriculture Property",
            "label":"(This refers the purchase cost of the non agricultural property with legal expense.)"
          },
          {
            "key": "4",
            "value": "Agricultural Property",
            "label":"(This refers the purchase cost of the agricultural property with legal expense.)"
          },
          {
            "key": "5",
            "value": "Investments",
            "label":"(An asset or item that is purchased with the hope that it will generate income or appreciate in the future.)"
          },
          {
            "key": "6",
            "value": "Motor Vehicles",
            "label":"(This refers the purchase cost of all personal and family motor vehicle as like: car, bike etc.)"
          },
          {
            "key": "7",
            "value": "Furniture",
            "label":"(This refers all kinds residential and family furniture in purchase cost. As like : Cot, Sofa, Almirah, Showcase etc.)"
          },
          {
            "key": "8",
            "value": "Jewellery",
            "label":"(This refers the quantity and value of Jewellery at purchase cost. As like: Gold, Silver or diamond Jewellery.)"
          },
          {
            "key": "9",
            "value": "Electronic Equipment",
            "label":"(This refers all kinds residential and family use electric equipment in purchase cost. As like : TV, freeze, AC, Personal Computer etc.)"
          },
          {
            "key": "10",
            "value": "Cash Assets Outside Business",
            "label":"(Cash or deposit asset those are outside of business or profession.)"
          },
          {
            "key": "11",
            "value": "Other Assets",
            "label":"(If  any other asset except the following assets purchase cost on the date of June 30.)"
          },
          {
            "key": "12",
            "value": "Other Assets Receipt",
            "label":"(A unit of ownership that represents an equal proportion of a company's capital.)"
          },
          {
            "key": "13",
            "value": "Previous Year's Net Worth",
            "label":"(This indicates the amount of net assets in the previous year.)"
          }
        ],
        "order": 7
      }
    ]


    const jsonData2 = [
      {
        "control": "LabelControl",
        "key": "personalInformation",
        "label": "আয়ের উৎসের তথ্য",
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এখানে করদাতা তার আয়ের উৎস নির্বাচন করবেন",
        "order": 1
      },
      {
        "control": "DropdownControl",
        "key": "OtherIncomeSources",
        "label": "আয় আপনার উৎস নির্বাচন করুন",
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "আপনি এখানে ক্লিক করেছেন এমন সমস্ত আয় এবং সম্পদের সেক্টরে কেবল আপনার ডেটা যুক্ত করুন। সেক্টরগুলি সনাক্ত করতে লক্ষ্য করুন বাম পাশের মেনুতে তাদের পাশে ব্যাজ রয়েছে, যা অর্থের পরিমাণ দেখায়। আপনি বাম দিকের মেনু থেকে তাদের পাশের ব্যাজগুলো দ্বারা সেক্টরগুলো সনাক্ত করতে পারেন, যা অর্থের পরিমাণ দেখায়।",
        "options": [
          {
            "key": "1",
            "value": "বেতন হতে আয়",
            "label": "(বেসরকারী বেতন, উৎসব ভাতা, চিকিৎসা ভাতা, সহায়তা কর্মী ভাতা ইত্যাদি)"
          },
          {
            "key": "2",
            "value": "সিকিওরিটির উপর সুদ হতে আয়",
            "label": "(সঞ্চয়পত্র, বন্ড, ডিবেঞ্চার ইত্যাদি)"
          },
          {
            "key": "3",
            "value": "সম্পত্তি হতে আয়",
            "label": "(আবাসিক বা বাণিজ্যিক ব্যবহারের জন্য দেয়া বাড়ী থেকে আয়)"
          },
          {
            "key": "4",
            "value": "কৃষি আয়",
            "label": "(চাল, সবজি, গম, আলু ইত্যাদি ফসল উৎপাদন করে আয়)"
          },
          {
            "key": "5",
            "value": "ব্যবসায় বা পেশা হতে আয়",
            "label": "(ব্যবসা বা পেশা থেকে আয়, যেমন, অভিনেতা, ডাক্তার, প্রকৌশলী, আইনজীবী ইত্যাদি।)"
          },
          {
            "key": "6",
            "value": "মুনাফার অংশভাগ হতে আয়",
            "label": "(অংশীদারি ফার্ম বা ব্যক্তি-সংঘ হতে আয়)"
          },
          {
            "key": "7",
            "value": "স্ত্রী / সন্তানের হতে আয়",
            "label": "(স্ত্রী বা অপ্রাপ্তবয়স্ক সন্তান হতে আয়। তারা যদি পৃথক করদাতা না হয়ে থাকে তবেই যুক্ত করুন।)"
          },
          {
            "key": "8",
            "value": "মূলধন হতে আয়",
            "label": "(সম্পত্তি হস্তান্তর থেকে লাভ। এখানে জমি, বাড়ী, এপার্টমেন্ট, কমার্শিয়াল স্পেস, সাইনিং মানি, স্টক এক্সচেঞ্জ শেয়ার বা মিচুয়াল ফান্ড ইউনিট ইত্যাদি হতে আয় বোঝায়।)"
          },
          {
            "key": "9",
            "value": "অন্যান্য উৎস হতে আয়",
            "label": "(উদাহরণস্বরূপ, মিউচুয়াল ফান্ড, নগদ লভ্যাংশ, বন্ড, পরিচালক ফি, আর্টিকেল লেখা থেকে সম্মানী, পরীক্ষক এবং পরিদর্শক হিসাবে পারিশ্রমিক, টিউশন, টেলিভিশন বা রেডিও ইত্যাদি থেকে আয়।)"
          },
          {
            "key": "10",
            "value": "বৈদেশিক আয়",
            "label": "(বিদেশী মুদ্রা আয়)"
          },
        ],
        "order": 2
      },
      {
        "control": "LabelControl",
        "key": "personalizeAsset",
        "label": "সম্পদের তথ্য",
        "errorType": "",
        "message": "",
        "tooltip": "এখানে করদাতা তার সম্পদের উৎস নির্বাচন করবেন",
        "order": 3
      },
      {
        "control": "RadioButtonControl",
        "key": "HaveEligibleAssetValue",
        "label": "আয় বছরের শেষ দিনে, আপনার মোট সম্পদের পরিমাণ ২৫ লাখ টাকার বেশি ছিল?",
        "options": [
          {
            "key": "1",
            "value": "হ্যাঁ"
          },
          {
            "key": "2",
            "value": "না"
          }
        ],
        "required": true,
        "errorType": "",
        "message": "",
        "tooltip": "একজন ব্যক্তির মূল্য নির্ধারকের জন্য সম্পদ, দায়বদ্ধতা এবং জীবনযাত্রার বিবরণী জমা দেওয়া বাধ্যতামূলক, যদি তার মোট পরিসম্পত্তির পরিমান ২৫ লক্ষ টাকার অধিক, বা একটি মোটর গাড়ির মালিক, বা সিটি কর্পোরেশন অঞ্চলে কোনও বাড়ির সম্পত্তি বা অ্যাপার্টমেন্টে বিনিয়োগ করে থাকেে।",
        "order": 4
      },
      {
        "control": "RadioButtonControl",
        "key": "HaveMotorCar",
        "label": "আয়ের বছরের শেষ দিনে, আপনি কি একটি মোটর গাড়ির (জিপ বা মাইক্রোবাস) মালিক ছিলেন?",
        "options": [
          {
            "key": "1",
            "value": "হ্যাঁ"
          },
          {
            "key": "2",
            "value": "না"
          }
        ],
        "required": true,
        "errorType": "",
        "message": "",
        "tooltip": "একজন ব্যক্তির মূল্য নির্ধারকের জন্য সম্পদ, দায়বদ্ধতা এবং জীবনযাত্রার বিবরণী জমা দেওয়া বাধ্যতামূলক, যদি তার মোট পরিসম্পত্তির পরিমান ২৫ লক্ষ টাকার অধিক, বা একটি মোটর গাড়ির মালিক, বা সিটি কর্পোরেশন অঞ্চলে কোনও বাড়ির সম্পত্তি বা অ্যাপার্টমেন্টে বিনিয়োগ করে থাকেে।",
        "order": 5
      },
      {
        "control": "RadioButtonControl",
        "key": "HaveHouseProperty",
        "label": "আয়ের বছরের শেষ দিনে, আপনি কি সিটি কর্পোরেশন এলাকার কোনও বাড়ির সম্পত্তি অথবা অ্যাপার্টমেন্টের মালিক বা বিনিয়োগকারী ছিলেন?",
        "options": [
          {
            "key": "1",
            "value": "হ্যাঁ"
          },
          {
            "key": "2",
            "value": "না"
          }
        ],
        "required": true,
        "errorType": "",
        "message": "",
        "tooltip": "একজন ব্যক্তির মূল্য নির্ধারকের জন্য সম্পদ, দায়বদ্ধতা এবং জীবনযাত্রার বিবরণী জমা দেওয়া বাধ্যতামূলক, যদি তার মোট পরিসম্পত্তির পরিমান ২৫ লক্ষ টাকার অধিক, বা একটি মোটর গাড়ির মালিক, বা সিটি কর্পোরেশন অঞ্চলে কোনও বাড়ির সম্পত্তি বা অ্যাপার্টমেন্টে বিনিয়োগ করে থাকেে।",
        "order": 6
      },
      {
        "control": "DropdownControl",
        "key": "AcquiredAssets",
        "label": "আপনার অর্জিত সম্পদ নির্বাচন করুন",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "করদাতা তার অর্জিত সম্পদ নির্বাচন করবেন",
        "options": [
          {
            "key": "1",
            "value": "ব্যবসায় মূলধন",
            "label":"(করদাতার যদি কোন ব্যবসা থাকে তবে মূলধনের সর্বশেষ স্থিতি এ ক্ষেত্রে উল্লেখ করতে হবে।)"
          },
          {
            "key": "2",
            "value": "পরিচালকের শেয়ার সম্পদ",
            "label":"(এখানে পরিচালক হিসাবে লিমিটেড কোম্পানির শেয়ার এর ক্রয়মূল্য উল্লেখ করতে হবে।)"
          },
          {
            "key": "3",
            "value": "অ-কৃষি সম্পত্তি",
            "label":"(এখানে আইন সম্মত ব্যয়সহ অ - কৃষি সম্পত্তির ক্রয়মূল্য উল্লেখ করতে হবে।)"
          },
          {
            "key": "4",
            "value": "কৃষি সম্পত্তি",
            "label":"(এখানে আইন সম্মত ব্যয়সহ  কৃষি সম্পত্তির ক্রয়মূল্য উল্লেখ করতে হবে।)"
          },
          {
            "key": "5",
            "value": "বিনিয়োগ",
            "label":"(ভবিষ্যতে আয় বা লাভের উদ্দেশ্যে কোন সম্পত্তি বা এরূপ কোন কিছু ক্রয় করা।)"
          },
          {
            "key": "6",
            "value": "মোটরযান",
            "label":"(ব্যক্তিগত বা পারিবারিক কাজে যে কোন মোটরযান এর ক্রয়মূল্য যথা - কার ,বাইক ইত্যাদি।)"
          },
          {
            "key": "7",
            "value": "আসবাবপত্র",
            "label":"(আবাসিক বা পারিবারিক প্রয়োজনে যে কোন আসবাবপত্রের ক্রয়মূল্য যথা - সোফা, আলমিরা শোকেস ইত্যাদি।)"
          },
          {
            "key": "8",
            "value": "অলংকার",
            "label":"(যে কোন পরিমান অলংকারাদি এবং ক্রয়মূল্য প্রকাশ করা ,যথা - সোনা ,রূপা বা হীরার অলংকারাদি।)"
          },
          {
            "key": "9",
            "value": "বৈদ্যুতিক সরঞ্জাম",
            "label":"(আবাসিক বা পারিবারিক প্রয়োজনে সকল ইলেকট্রনিক সামগ্রীর ক্রয়মূল্য যেমন – টিভি ,ফ্রিজ,এসি ,ব্যক্তিগত কম্পিউটার ইত্যাদি।)"
          },
          {
            "key": "10",
            "value": "ব্যবসায়ের বাইরে নগদ সম্পদ",
            "label":"(ব্যবসা বা পেশা বর্হিভূত যে কোন নগদ বা ব্যাংকে গচ্ছিত অর্থ।)"
          },
          {
            "key": "11",
            "value": "অন্যান্য সম্পত্তি",
            "label":"(উক্ত তালিকাবহির্ভূত সম্পদ ব্যতীত অন্যান্য সম্পদ এর ক্রয়মূল্য যা জুনের ৩০ তারিখের মধ্যে ক্রয় করা হয়েছে।)"
          },
          {
            "key": "12",
            "value": "অন্যান্য সম্পত্তির প্রাপ্তি",
            "label":"(কোন কোম্পানির সমান অনুপাতের মূলধনের অংশবিশেষের মালিকানা থাকা।)"
          },
          {
            "key": "13",
            "value": "পূর্ববর্তী বছরের নেট মূল্য",
            "label":"(এটি আগের বছরের নিট সম্পদের পরিমাণকে নির্দেশ করে।)"
          }
        ],
        "order": 7
      }
    ]



    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }


}
