<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'PRICING.HEADER' | translate }}</h2>
                    <ul>
                        <li><span class="sub-title">{{ 'PRICING.SUB_HEADER' | translate }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<section class="features-area ptb-100 pt-0">
    <div class="container">
        <div class="tab features-list-tab">

            <ul class="tabs"
                style="justify-content: center !important;
            align-items: center !important;">

                <li><a href="#"
                        class="bg-fa7070">
                        <i class="flaticon-man"></i>
                        <span>{{ 'PRICING.PLAN_1' | translate }}</span>
                    </a></li>
                <li><a href="#"
                        class="bg-c679e3">
                        <i class="flaticon-team"></i>
                        <span>{{ 'PRICING.PLAN_2' | translate }}</span>
                    </a></li>

            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row table-responsive">
                        <table class="table table-striped table-light table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col"
                                        style="font-size: 20px;">{{ 'PRICING.TABLE_HEADER_PLANS' | translate }}</th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_FREE' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_0' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STARTER' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_499' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STANDARD' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_999' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_PREMIUM' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_1999' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>

                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_2' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_3' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_4' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_5' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_6' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_17' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_16' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row"></th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
                <div class="tabs_item">
                    <!-- <div class="page-title-content">
                        <h2> Coming Soon</h2>
                    </div> -->
                    <div class="row table-responsive">
                        <table class="table table-striped table-light table-hover table-bordered">

                            <thead>
                                <tr>
                                    <th scope="col"
                                        style="font-size: 20px;">{{ 'PRICING.TABLE_HEADER_PLANS' | translate }}</th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_FREE' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_0' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STARTER' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_250' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STANDARD' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_200' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_PREMIUM' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_150' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_1' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_8' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_9' | translate }}<br>
                                        {{ 'PRICING.PLAN_TEXT_9a' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_10' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_11' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_12' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_13' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_14' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_15' | translate }}</th>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_5c' | translate }}

                                    </td>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_50c' | translate }}

                                    </td>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_100c' | translate }}

                                    </td>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_UN' | translate }}

                                    </td>
                                </tr>
                                <!-- <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">Pricing Structure</th>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                        </ul>

                                    </td>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                            <li><span style="color: green;">&bull; </span> Next 45 clients ৳250 / per
                                                file yearly.</li>
                                        </ul>

                                    </td>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                            <li><span style="color: green;">&bull; </span>Next 45 clients ৳250 / per
                                                file yearly.</li>
                                            <li><span style="color: green;">&bull; </span>Next 50 clients ৳200/ per file
                                                yearly.</li>
                                        </ul>

                                    </td>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                            <li><span style="color: green;">&bull; </span>Next 45 clients ৳250 / per
                                                file yearly.</li>
                                            <li><span style="color: green;">&bull; </span>Next 50 clients ৳200/ per file
                                                yearly.</li>
                                            <li><span style="color: green;">&bull; </span>All clients after 100 is
                                                ৳150/ per file yearly.</li>
                                        </ul>

                                    </td>
                                </tr> -->
                                <tr>
                                    <th scope="row"></th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
