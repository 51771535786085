import { Component, ViewEncapsulation, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'app/auth';
import { BackButtonService } from 'app/services/BackButtonService';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { bangla, english } from 'app/util/locale';

@Component({
    selector: 'mail-verification-home',
    templateUrl: './mail-verification-home.component.html',
    styleUrls: ['./mail-verification-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MailVerificationHomeComponent implements OnInit, OnDestroy
{

    validationMessage: string;
    isValidation = false;
    userId: number;
    code: string;
    responseData: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _router: Router,
        private _homeTranslationLoaderService: HomeTranslationLoaderService,
        private back: BackButtonService,
        private meta: Meta,
        private title: Title,
    )
    {
        this.meta.addTags([
            { name: 'description', content: 'Verify your email with digiTax' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'Verify email digiTax, digiTax email verification' }
        ]);
        this.setTitle('digiTax | Email Verification');
        this._homeTranslationLoaderService.loadTranslations(english, bangla);

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        const hasUserId = this._route.snapshot.queryParamMap.has('userId');
        const hasCode = this._route.snapshot.queryParamMap.has('code');

        if (hasUserId && hasCode)
        {
            this.userId = Number(this._route.snapshot.queryParamMap.get('userId'));
            this.code = encodeURIComponent(this._route.snapshot.queryParamMap.get('code'));

            this._authService.confirmEmail(this.userId, this.code)
                .subscribe((data: any) =>
                {
                    if (data.Error === false)
                    {
                        this.responseData = data.Data;
                        this.isValidation = true;
                        this.validationMessage = 'Your email confirmation is successfull.';
                    }
                    else
                    {
                        this.isValidation = false;
                        this.validationMessage = 'Opps!!! Somthing is wrong, please reload you page.';
                    }
                },
                    (error: any) =>
                    {
                        this.isValidation = false;
                        this.validationMessage = 'Opps!!! Somthing is wrong, please try again.';
                    });

        } else
        {
            this.isValidation = false;
            this.validationMessage = 'Opps!!! Somthing is wrong, please try again.';
        }

    }

    ngOnDestroy(): void
    {

    }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event)
    // {

    //     this.back.setParam(true, 'fuse');
    // }
}
