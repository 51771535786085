
export class ProfileSaveModel
{
  Id: any;
  UserId: any;
  OrgName: string = "";
  ITPNo: string = "";
  BarMembershipNo: string = "";
  Mobile: string = "";
  Email: string = "";
  Address1: string = "";
  Address2: string = "";
}
