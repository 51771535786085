import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { Registeration } from '.';
import Config from 'app/util/config';


@Injectable({ providedIn: 'root' })
export class AuthenticationService
{

  constructor(private http: HttpClient, private router: Router) { }

  register(user: any): any
  {

    const body: Registeration = {
      UserType: user.userType,
      Name: user.name,
      Email: user.email,
      Password: user.password,
      ConfirmPassword: user.passwordConfirm
    };
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'No-Auth': 'True'
    });
    return this.http.post(Config.rootUrl + '/UserAuth/register', body, { headers: reqHeader });
  }

  resetPassword(user: any): any
  {

    const body = {
      UserId: user.userId,
      Code: user.code,
      NewPassword: user.password,
      ConfirmPassword: user.passwordConfirm
    };
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'No-Auth': 'True'
    });
    return this.http.post(Config.rootUrl + '/UserAuth/setpassword', body, { headers: reqHeader });
  }

  confirmEmail(userId: number, code: string): any
  {
    const url = Config.rootUrl + '/UserAuth/confirmemail?' + 'userId=' + userId + '&code=' + code + '';
    const headers = new HttpHeaders({
      //'Content-Type': 'application/x-www-urlencoded',
      'No-Auth': 'True'
    });
    return this.http.get(url, { headers: headers });
  }

  login(userName: string, password: string): any
  {
    // const body = 'username=' + userName + '&password=' + password + '&grant_type=password';

    let body = new HttpParams()
      .set(`username`, userName)
      .set(`password`, password)
      .set(`grant_type`, `password`);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-urlencoded',
      'No-Auth': 'True'
    });
    // debugger;
    return this.http.post(Config.rootUrl + '/token', body.toString(), { headers: headers });
  }

  forgotPassword(Email: any): any
  {
    const body = { "Email": Email };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'No-Auth': 'True'
    });

    return this.http.post(Config.rootUrl + '/UserAuth/forgotpassword', body, { headers: headers });
  }

  resendConfirmEmail(Email: any): any
  {
    const body = { "Email": Email };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'No-Auth': 'True'
    });

    return this.http.post(Config.rootUrl + '/UserAuth/resend-email-confirmation', body, { headers: headers });
  }

  refreshToken(refToken: string): any
  {
    const body = 'refresh_token=' + refToken + '&grant_type=refresh_token';
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-urlencoded',
      'No-Auth': 'True'
    });

    return this.http.post(Config.rootUrl + '/token', body, { headers: headers });
  }

  Logout(): void
  {
    // localStorage.setItem('lastUrl', this.router.url);

    let lastLogin = {
      'lastUrl': this.router.url,
      'lastUser': localStorage.getItem('userName')
    };
    localStorage.setItem('lastLogin', JSON.stringify(lastLogin));
    localStorage.removeItem('userToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('profileData');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRefToken');
    localStorage.removeItem('TokenInfo');
    localStorage.removeItem('AssesseeId');
    localStorage.removeItem('archiveReadiness');
    // localStorage.removeItem('SwitchedReturnID');
    localStorage.removeItem('stepMenu');
    localStorage.removeItem('IsCurrentYear');
    
    localStorage.setItem('selectedLanguage', 'en')

    // this.router.navigate(['login-home']);

    let url_D = "/login-home"
    let url_P = `/login-home`;
    // let url_P = `${window.location.hostname}/login-home`;
    // let url_P = "http://digitax.com.bd/login-home";

    window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;
  }
}
