
export class InterestOnSecuritiesClass
{
  constructor(
    private _Amount: number,
    private _ComissionInterestRate: number,
    private _NetIncome: number,
    private _SecurityType: string,
  )
  {

  }
  public get Amount(): number
  {
    return this._Amount;
  }
  public set Amount(x: number)
  {
    this._Amount = x;
  }

  public get ComissionInterestRate(): number
  {
    return this._ComissionInterestRate;
  }
  public set ComissionInterestRate(x: number)
  {
    this._ComissionInterestRate = x;
  }

  public get NetIncome(): number
  {
    return this._NetIncome = Number(this.Amount);
  }
  public set NetIncome(x: number)
  {
    this._NetIncome = x;
  }

  public get SecurityType(): string
  {
    return this._SecurityType;
  }
  public set SecurityType(x: string)
  {
    this._SecurityType = x;
  }
}
