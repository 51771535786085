<div class="page-layout simple"
    style="background-color: #ffffff;">

    <div class="center">

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'PROFILE.HEADER' | translate }}
            </span>

        </div>
        <div class="content p-24">

            <div class="mb-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row">

                <form class="page-layout simple fullwidth"
                    fxLayout="column wrap"
                    [formGroup]="form">

                    <div fxLayout="column"
                        fxLayoutAlign="start">

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'PROFILE.OrgName' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'PROFILE.OrgName' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    formControlName="OrgName"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'PROFILE.ITPNo' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'PROFILE.ITPNo' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    formControlName="ITPNo"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'PROFILE.BarMembershipNo' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'PROFILE.BarMembershipNo' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    formControlName="BarMembershipNo"
                                    NumbersOnly
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'PROFILE.Mobile' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'PROFILE.Mobile' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    formControlName="Mobile"
                                    NumbersOnly
                                    maxlength="11"
                                    minlength="11"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'PROFILE.Email' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'PROFILE.Email' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    type="email"
                                    formControlName="Email"
                                    [email]="true"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'PROFILE.Address2' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'PROFILE.Address2' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    formControlName="Address2"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50">{{ 'PROFILE.Address1' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'PROFILE.Address1' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    formControlName="Address1"
                                    matInput>
                            </mat-form-field>

                        </div>

                    </div>

                </form>

            </div>
            <div class="header"
                style="padding-bottom: 10px;"
                fxLayoutAlign="space-between center">
                <div disabled
                    class="mat-button mat-button-base primary-A400 secondary-text">

                </div>

                <!-- <button mat-raised-button
                    style="color: #444444 !important;"
                    *ngIf="id!=null"
                    [routerLink]="'/Jewellery'"
                    class="mat-button mat-button-base primary-100 secondary-text">
                    {{ 'COMMON.BUTTON_CANCEL' | translate }}
                </button>
                <button mat-raised-button
                    *ngIf="id==null"
                    (click)="OnSubmit()"
                    class="mat-button mat-button-base dupl px-16">
                    {{ 'COMMON.BUTTON_SAVE' | translate }}
                </button> -->
                <button mat-raised-button
                    (click)="OnSubmit()"
                    class="mat-button mat-button-base dupl px-16">
                    {{ 'COMMON.BUTTON_SAVE' | translate }}
                </button>
            </div>

            <!-- <div class="header"
                style="padding-top: 10px;"
                fxLayoutAlign="space-between center">
                <button mat-raised-button
                    class="mat-button mat-button-base primary-A400 secondary-text px-32"
                    [routerLink]="'/Furniture'">
                    {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
                </button>
                <button mat-raised-button
                    class="mat-button mat-button-base primary-A400 secondary-text accent"
                    [routerLink]="'/ElectronicEquipment'">
                    {{ 'COMMON.BUTTON_NEXT' | translate }}
                </button>
            </div> -->

        </div>
    </div>
</div>
