import { Component, OnInit } from '@angular/core';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { bangla, english } from 'app/util/locale';
import { Meta, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(

    private _spinner: NgxSpinnerService,

    private _homeTranslationLoaderService: HomeTranslationLoaderService,
    private back: BackButtonService,
    private meta: Meta,
    private title: Title




  )
  {
    this._spinner.hide();

    this.meta.addTags([
      { name: 'description', content: 'Help Center of digiTax' },
      { name: 'author', content: 'digiTax' },
      { name: 'keywords', content: 'Help Center of digiTax' }
    ]);
    this.setTitle('digiTax Help Center');
    this._homeTranslationLoaderService.loadTranslations(english, bangla);
    this.back.sharedData.subscribe(result =>
      {
          if (result)
          {
              // window.location.reload(true);
              window.location.reload();

              this.back.setParam(false, '');
          }
      });
  }
  public setTitle(newTitle: string)
  {
    this.title.setTitle(newTitle);
  }

  ngOnInit() {
  }

}
