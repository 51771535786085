import { Component, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ListDialogComponent } from '@fuse/components/list-dialog/list-dialog.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AppUser } from 'app/auth';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import Utils from 'app/util/utils';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from './Dashboard.service';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import * as lodash from 'lodash';
import { Subscription } from 'rxjs';
import { ReloadPageService } from 'app/services/reloadPage.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'Dashboard',
    templateUrl: './Dashboard.component.html',
    styleUrls: ['./Dashboard.component.scss']
})
export class DashboardComponent
{
    currDate: any;
    currDay: any;
    listDialogRef: MatDialogRef<ListDialogComponent>;
    lang = '';
    perc = '55%'
    time = new Date();
    timer;
    PercentageItem: any;
    BasicCard: any;
    IncomeCard: any;
    AssetCard: any;

    CardItem: any;
    WealthCard: any;
    TaxCard: any;
    FileCard: any;
    user: AppUser;
    subscription: Subscription;


    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: DashboardService,
        private back: BackButtonService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private eventEmitterService: EventEmitterService,
        private _route: ActivatedRoute,
        private sidePanelSharedService: SidePanelSharedService,
        private _fuseNavigationService: FuseNavigationService,
        private reloadPageService: ReloadPageService,


    )
    {
        this.meta.addTags([
            { name: 'description', content: 'digiTax Dashboard' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax Dashboard' }
        ]);
        this.setTitle('digiTax | Dashboard');
        this.lang = localStorage.getItem('selectedLanguage');
        this.user = Utils.getUserInfo();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this._spinner.show();
        this.getPageData();
        this.subscription = this.reloadPageService.currentMessage.subscribe(message =>
        {
            if (message == true)
            {
                // this.reloadPageService.changeMessage(false);
                window.location.reload();
            }
        })

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

    }

    getPageData(): void
    {
        this._formControlService.getControlJson()
            .subscribe((data: any) =>
            {
                // console.log(data, 'DashboardComponent data');
                if (data != null)
                {
                    this._spinner.hide();
                }
                if (data.isError === false)
                {
                    this.PercentageItem = data.data.PercentageItem;
                    this.BasicCard = this.PercentageItem[0];
                    this.IncomeCard = this.PercentageItem[1];
                    this.AssetCard = this.PercentageItem[2];

                    this.CardItem = data.data.CardItem;
                    // console.log(this.CardItem, 'this.CardItem');

                    this.WealthCard = this.CardItem[0];
                    this.TaxCard = this.CardItem[1];
                    this.FileCard = this.CardItem[2];

                }
                else
                {
                    console.log('error');
                    this._spinner.hide();
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            });
    }

    OpenLink(data): void
    {
        let blackStat = lodash.filter(data, { "status": "blank" });
        // console.log(blackStat, 'blackStat');
        let url = '';
        if (blackStat.length != 0)
        {
            url = Utils.getUrl(blackStat[0].item);
        }
        else
        {
            url = Utils.getUrl(data[0].item);
        }
        this._router.navigate([url]);



    }

    OpenDialog(data, type): void
    {
        let mes = '';
        if (type == 'BasicCard')
        {
            mes = "Please fill up your basic information"
        }

        if (type == 'IncomeCard')
        {
            mes = "Please fill up your income information"
        }

        if (type == 'AssetCard')
        {
            mes = "Please fill up your asset information"
        }

        if (data == null || data.length <= 0)
        {
            this._matSnackBar.open(mes, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            this.listDialogRef = this._matDialog.open(ListDialogComponent, {
                data: { items: data },
                disableClose: false
            });
        }

    }

    ngOnDestroy()
    {
        clearInterval(this.timer);
        this.subscription.unsubscribe();
    }



    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

