import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { BaseControl } from 'app/models/dynamic-form';

@Component({
  selector: 'etin-others-form-control',
  templateUrl: './etin-others-form-control.component.html',
  styleUrls: ['./Etin-Others.component.scss']

})
export class EtinOthersFormControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  @Input() rStatus: boolean;
  @Input() nStatus: boolean;
  toggle180p = false;
  toggle180m = false;
  toggleYes = false;
  toggleNo = false;
  showYesNo = false;
  showStaus = false;
  residentialStatus: boolean = true;
  IsCurrentYear: any;


  get isValid()
  {
    return this.form.controls[this.control.key].valid;
  }

  onToggle(event, key)
  {



    let floorElement1: HTMLElement = document.getElementById("guardianOfDisabled") as HTMLElement;


    if (key == 'HasDisableChild')
    {
      if (event.checked == true)
      {
        this.form.controls['NumberOfDisabledChildren'].setErrors({ 'required': true });
        if (floorElement1.classList.contains('hidden'))
        {
          floorElement1.classList.remove('hidden');
        }
      }

      if (event.checked == false)
      {
        this.form.controls['NumberOfDisabledChildren'].setErrors(null);
        if (!floorElement1.classList.contains('hidden'))
        {
          floorElement1.classList.add('hidden');
        }
        this.form.controls['SpouseAvailExtendTaxExempt'].reset();
        // this.form.controls['NumberOfDisabledChildren'].reset();
        this.form.patchValue({
          NumberOfDisabledChildren: ""
        });
      }

    }
  }


  radioChangeN(event)
  {

    let floorElement1: HTMLElement = document.getElementById("stayed1") as HTMLElement;

    if (event.value == "1" || event.value == "2")
    {
      if (floorElement1.classList.contains('hidden'))
      {
        floorElement1.classList.remove('hidden');
      }
    }

  }

  radioChange1(event)
  {

    let floorElement1: HTMLElement = document.getElementById("stayed2") as HTMLElement;
    let floorElement2: HTMLElement = document.getElementById("ResidentStatus") as HTMLElement;


    if (event.value == "1")
    {
      let lang = localStorage.getItem('selectedLanguage');
      let rs = '';
      lang == 'en' ? rs = 'Resident' : rs = 'আবাসিক';
      document.getElementById("resident").innerHTML = rs;

      if (!floorElement1.classList.contains('hidden'))
      {
        floorElement1.classList.add('hidden');
      }
      if (floorElement2.classList.contains('hidden'))
      {
        floorElement2.classList.remove('hidden');

        document.getElementById("resident").innerHTML = rs;
      }
      this.form.controls['stayed2'].reset();
    }
    if (event.value == "2")
    {
      let lang = localStorage.getItem('selectedLanguage');
      let rs = '';
      lang == 'en' ? rs = 'Non-Resident' : rs = 'অনাবাসিক';
      if (floorElement1.classList.contains('hidden'))
      {
        floorElement1.classList.remove('hidden');
      }
      if (floorElement2.classList.contains('hidden'))
      {
        floorElement2.classList.remove('hidden');
      }
      document.getElementById("resident").innerHTML = rs;
    }
  }

  radioChange2(event)
  {

    let floorElement1: HTMLElement = document.getElementById("ResidentStatus") as HTMLElement;

    let floorElement2: HTMLElement = document.getElementById("ResidentStatus") as HTMLElement;

    if (event.value == "1")
    {
      let lang = localStorage.getItem('selectedLanguage');
      let rs = '';
      lang == 'en' ? rs = 'Resident' : rs = 'আবাসিক';

      document.getElementById("resident").innerHTML = rs;

      if (floorElement1.classList.contains('hidden'))
      {
        floorElement1.classList.remove('hidden');
        document.getElementById("resident").innerHTML = rs;
      }
    }
    if (event.value == "2")
    {
      let lang = localStorage.getItem('selectedLanguage');
      let rs = '';
      lang == 'en' ? rs = 'Non-Resident' : rs = 'অনাবাসিক';
      document.getElementById("resident").innerHTML = rs;
    }
  }

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }


  ngAfterViewInit(): void
  {


    let HasDisableChild = this.form.controls["HasDisableChild"].value;

    let guardianOfDisabled: HTMLElement = document.getElementById("guardianOfDisabled") as HTMLElement;

    if (HasDisableChild != null)
    {
      if (HasDisableChild == "False")
      {
        HasDisableChild = false;
      }

      if (HasDisableChild == "false")
      {
        HasDisableChild = false;
      }

      if (HasDisableChild == "True")
      {
        HasDisableChild = true;
      }

      if (HasDisableChild == "true")
      {
        HasDisableChild = true;
      }

      if (guardianOfDisabled != null)
      {
        if (HasDisableChild == true)
        {
          if (guardianOfDisabled.classList.contains('hidden'))
          {
            guardianOfDisabled.classList.remove('hidden');
          }
        }
        else
        {
          if (!guardianOfDisabled.classList.contains('hidden'))
          {
            guardianOfDisabled.classList.add('hidden');
          }
        }
      }

      this.form.patchValue({
        HasDisableChild: HasDisableChild
      });
    }

    let DisablePerson = this.form.controls["DisablePerson"].value;

    if (DisablePerson != null)
    {
      if (DisablePerson == "False")
      {
        DisablePerson = false;
      }
      if (DisablePerson == "false")
      {
        DisablePerson = false;
      }
      if (DisablePerson == "True")
      {
        DisablePerson = true;
      }
      if (DisablePerson == "true")
      {
        DisablePerson = true;
      }
      this.form.patchValue({
        DisablePerson: DisablePerson
      });
    }

    let WarWoundedFreedomFighter = this.form.controls["WarWoundedFreedomFighter"].value;

    if (WarWoundedFreedomFighter != null)
    {
      if (WarWoundedFreedomFighter == "False")
      {
        WarWoundedFreedomFighter = false;
      }
      if (WarWoundedFreedomFighter == "false")
      {
        WarWoundedFreedomFighter = false;
      }
      if (WarWoundedFreedomFighter == "True")
      {
        WarWoundedFreedomFighter = true;
      }
      if (WarWoundedFreedomFighter == "true")
      {
        WarWoundedFreedomFighter = true;
      }
      this.form.patchValue({
        WarWoundedFreedomFighter: WarWoundedFreedomFighter
      });
    }

    let stayed1 = this.form.controls["stayed1"].value;
    let Nationality = this.form.controls["Nationality"].value;

    let floorElement1: HTMLElement = document.getElementById("stayed2") as HTMLElement;
    let floorElement2: HTMLElement = document.getElementById("ResidentStatus") as HTMLElement;
    let floorElement3: HTMLElement = document.getElementById("stayed1") as HTMLElement;

    let lang = localStorage.getItem('selectedLanguage');
    let rs = '';
    let gs = '';
    lang == 'en' ? rs = 'Resident' : rs = 'আবাসিক';
    lang == 'en' ? gs = 'Non-Resident' : gs = 'অনাবাসিক';

    if (Nationality == "1" || Nationality == "2")
    {
      if (floorElement3 != null)
      {
        if (floorElement3.classList.contains('hidden'))
        {
          floorElement3.classList.remove('hidden');
        }
      }
    }

    if (stayed1 == "1")
    {
      if (floorElement2 != null)
      {
        document.getElementById("resident").innerHTML = rs;
      }
      if (floorElement1 != null)
      {
        if (!floorElement1.classList.contains('hidden'))
        {
          floorElement1.classList.add('hidden');
        }
      }
      if (floorElement2 != null)
      {
        if (floorElement2.classList.contains('hidden'))
        {
          floorElement2.classList.remove('hidden');
          document.getElementById("resident").innerHTML = rs;
        }
      }
    }
    if (stayed1 == "2")
    {
      if (floorElement1 != null)
      {
        if (floorElement1.classList.contains('hidden'))
        {
          floorElement1.classList.remove('hidden');
        }
      }
      if (floorElement2 != null)
      {
        document.getElementById("resident").innerHTML = gs;
      }
      if (floorElement2 != null)
      {
        if (floorElement2.classList.contains('hidden'))
        {
          floorElement2.classList.remove('hidden');
        }
      }
    }

    let stayed2 = this.form.controls["stayed2"].value;

    if (stayed2 == "1")
    {
      if (floorElement2 != null)
      {
        document.getElementById("resident").innerHTML = rs;
      }
      if (floorElement2 != null)
      {
        if (floorElement2.classList.contains('hidden'))
        {
          floorElement2.classList.remove('hidden');
        }
      }
      if (floorElement1 != null)
      {
        if (floorElement1.classList.contains('hidden'))
        {
          floorElement1.classList.remove('hidden');
          document.getElementById("resident").innerHTML = rs;
        }
      }
    }
    if (stayed2 == "2")
    {
      if (floorElement2 != null)
      {
        document.getElementById("resident").innerHTML = gs;
      }
      if (floorElement2 != null)
      {
        if (floorElement2.classList.contains('hidden'))
        {
          floorElement2.classList.remove('hidden');
        }
      }
    }
    let SpouseAvailExtendTaxExempt = this.form.controls["SpouseAvailExtendTaxExempt"].value;

    if (SpouseAvailExtendTaxExempt == "false")
    {
      SpouseAvailExtendTaxExempt = false
    }
    if (SpouseAvailExtendTaxExempt == "False")
    {
      SpouseAvailExtendTaxExempt = false
    }
    if (SpouseAvailExtendTaxExempt == "true")
    {
      SpouseAvailExtendTaxExempt = true
    }
    if (SpouseAvailExtendTaxExempt == "True")
    {
      SpouseAvailExtendTaxExempt = true
    }
    if (SpouseAvailExtendTaxExempt != null)
    {
      this.form.patchValue({
        SpouseAvailExtendTaxExempt: SpouseAvailExtendTaxExempt
      });
    }
  }
}
