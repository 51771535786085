import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { Meta, Title } from '@angular/platform-browser';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { BackButtonService } from 'app/services/BackButtonService';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from 'app/util/utils';

@Component({
    selector: 'reset-password-home',
    templateUrl: './reset-password-home.component.html',
    styleUrls: ['./reset-password-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordHomeComponent implements OnInit, OnDestroy
{
    resetPasswordForm: FormGroup;
    errorMessage: string;
    isLoginError = false;
    fieldTextType: boolean;
    submitted = false;

    hasUserId: any;
    hasCode: any;

    userId: number;
    code: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private back: BackButtonService,
        private _authService: AuthenticationService,
        private _route: ActivatedRoute,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _homeTranslationLoaderService: HomeTranslationLoaderService,
        private meta: Meta,
        private title: Title,
    )
    {
        this.meta.addTags([
            { name: 'description', content: 'Reset your password with digiTax' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'Reset password digiTax, digiTax password reset' }
        ]);
        this.setTitle('digiTax | Password Reset');
        this._homeTranslationLoaderService.loadTranslations(english, bangla);

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }
    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.fieldTextType = false;
        this.hasUserId = this._route.snapshot.queryParamMap.has('userId');
        this.hasCode = this._route.snapshot.queryParamMap.has('code');

        if (this.hasUserId && this.hasCode)
        {
            this.userId = Number(this._route.snapshot.queryParamMap.get('userId'));
            this.code = encodeURIComponent(this._route.snapshot.queryParamMap.get('code'));
        }

        this.resetPasswordForm = this._formBuilder.group({
            password: ['', Validators.compose([Validators.required, Utils.passwordPatternValidator()])],
            passwordConfirm: ['', [Validators.required]]
        }, { validator: Utils.MatchPassword('password', 'passwordConfirm'), });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        // this.resetPasswordForm.get('password').valueChanges
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() =>
        //     {
        //         this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
        //     });
    }

    toggleFieldTextType()
    {
        this.fieldTextType = !this.fieldTextType;
    }

    get resetPasswordFormControl()
    {
        return this.resetPasswordForm.controls;
    }

    OnSubmit(): void
    {
        this.submitted = true;
        if (this.resetPasswordForm.valid)
        {
            this._spinner.show();
            let body = {
                "password": this.resetPasswordForm.controls["password"].value,
                "passwordConfirm": this.resetPasswordForm.controls["passwordConfirm"].value,
                "userId": this.userId,
                "code": this.code
            };

            this._authService.resetPassword(body)
                .subscribe((data: any) =>
                {
                    if (data.Error === false)
                    {
                        this._spinner.hide();
                        let message = data.Message;
                        // const email = this.resetPasswordForm.get('email').value;
                        this.resetForm();
                        this._matSnackBar.open(data.Message, 'Ok', {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                        this._router.navigate(['/login-home', message]);
                    }
                    else
                    {
                        this._spinner.hide();
                        this.isLoginError = true;
                        this.errorMessage = data.Message;
                    }
                },
                    (error: any) =>
                    {
                        this._spinner.hide();
                        this.isLoginError = true;
                        this.errorMessage = 'Opps!!! Somthing is wrong, please try again.';
                    });
        }
    }

    resetForm(): void
    {
        this.resetPasswordForm.reset();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event)
    // {

    //     this.back.setParam(true, 'fuse');
    // }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>
{

    if (!control.parent || !control)
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm)
    {
        return null;
    }

    if (passwordConfirm.value === '')
    {
        return null;
    }

    if (password.value === passwordConfirm.value)
    {
        return null;
    }

    return { passwordsNotMatching: true };
};
