<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'CONTACT.HEADER' | translate }}</h2>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100"
    style="padding-top: 0; padding-bottom: 50px;">
    <div class="container">
        <div class="row">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-call"></i>
                    </div>
                    <h3>{{ 'CONTACT.HELPLINE' | translate }}</h3>
                    <p><a href="tel:09643 224488">{{ 'CONTACT.R5' | translate }}</a></p>
                    <!-- <p><a href="tel:+8801791321068">{{ 'CONTACT.R6' | translate }}</a></p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-envelope"></i>
                    </div>
                    <h3>{{ 'CONTACT.EMAIL' | translate }}</h3>
                    <p><a href="mailto:support@digitax.com.bd">support@digitax.com.bd</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-4 offset-sm-4">

                <div class="contact-info-box">
                    <div class="icon">
                        <i class="fa fa-globe"></i>
                    </div>
                    <h3>{{ 'CONTACT.R7' | translate }}</h3>
                    <p><a href="https://www.facebook.com/digitax.com.bd/"
                            target="_blank"><i class="fab fa-facebook-f"></i> facebook.com/digitax.com.bd</a></p><br>
                    <p><a href="https://www.instagram.com/digitaxbd/"
                            target="_blank"><i class="fab fa-instagram"></i> instagram.com/digitaxbd</a></p><br>
                    <p><a href="https://www.youtube.com/channel/UCzYPNErfKeBNqBzxccr26qg"
                            target="_blank"><i class="fab fa-youtube"></i> youtube.com/c/digitax</a></p>
                </div>

            </div>
        </div>

    </div>
</section>
<!-- End Contact Area -->

<div class="page-title-area page-title-bg1"
    style="height: 200px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'CONTACT.HEADER_2' | translate }}</h2>
                </div>
            </div>
        </div>
    </div>

</div>

<section class="contact-area ptb-100"
    style="padding-top: 0;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8 col-sm-8">

                <iframe height="330"
                    width="100%"
                    style="border: 1px solid #267C28;"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.3851255600653!2d90.35021101496868!3d23.804900392609103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0e134900c81%3A0xe095eb2abd7305a7!2z4Kam4KeH4Ka2IOCmh-CmieCmqOCmv-CmreCmvuCmsOCnjeCmuOCmvuCmsiDgpqrgp43gprDgpr7gpofgpq3gp4fgpp8g4Kay4Ka_4Kau4Ka_4Kaf4KeH4Kah!5e0!3m2!1sbn!2sbd!4v1613629529584!5m2!1sbn!2sbd"></iframe>

            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 offset-lg-0 offset-md-4 offset-sm-4"
                style="padding: 30px;">
                <h2>{{ 'CONTACT.HEADER_3' | translate }}</h2>
                <hr class="line">
                <p style="padding-top: 10px;">
                    {{ 'CONTACT.ADDRESS_1' | translate }}<br class="d-none d-md-block"> {{ 'CONTACT.ADDRESS_2' |
                    translate }}<br class="d-none d-md-block"> {{ 'CONTACT.ADDRESS_3' | translate }}
                </p>
            </div>

        </div>

    </div>
</section>
