import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { OtherResourcesService } from './OtherResources.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { OtherResourcesModel } from './OtherResources.Model';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import Utils from 'app/util/utils';
import { AuthenticationService } from 'app/auth';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';



@Component({
    selector: 'OtherResources',
    templateUrl: './OtherResources.component.html',
    styleUrls: ['./OtherResources.component.scss']
})
export class OtherResourcesComponent
{
    @Input() controls: BaseControl<string>[] = [];
    @Input() count: number = 0;
    @Output()
    change: EventEmitter<number>;
    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';

    formcontrols$: Observable<BaseControl<string>[]>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    /**
     * Constructor
     * @param {MatSnackBar} _matSnackBar
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: OtherResourcesService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _authService: AuthenticationService,
        private sidePanelDeleteService: SidePanelDeleteService,


        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private _formBuilder: FormBuilder,
        private back: BackButtonService,


    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Income From Other Sources" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Income From Other Sources" }
        ]);
        this.setTitle("digiTax | Income From Other Sources");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');

        this.lang = localStorage.getItem('selectedLanguage');
        this.form = this._formBuilder.group(new OtherResourcesModel());
        // this.getDataBlank();
        this.getData();


    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }


    ngOnInit(): void
    {

    }

    getDataBlank(): void
    {
        this._formControlService.getPageData("0").subscribe((data: any) =>
        {
            if (data.data != null)
            {
                this._spinner.hide();
                this.form.patchValue(data.data);
            }
        })

    }

    getData(): void
    {
        this._spinner.show();
        if (this.id != null)
        {
            this._formControlService.getPageData(this.id).subscribe((data: any) =>
            {
                if (data.data != null)
                {
                    this._spinner.hide();
                    this.form.patchValue(data.data);
                }
            })
        }
        else
        {
            this._spinner.hide();
        }
    }


    increment()
    {
        this.count++;
        this.change.emit(this.count);
    }

    decrement()
    {
        this.count--;
        this.change.emit(this.count);
    }


    mySubmitExit(param): void
    {
        // debugger;
        if (param == 'next' || param == 'more')
        {
            this.OnSubmit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }


    OnSubmit(param): void
    {

        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }
        this._spinner.show();
        this.id = this._route.snapshot.paramMap.get('id');

        let obj = this.form.getRawValue();

        if (obj.InterestMutualOrUnitFundAmount == "" || obj.InterestMutualOrUnitFundAmount == null)
        {
            obj.InterestMutualOrUnitFundAmount = "0.00"
        }

        if (obj.InterestMutualOrUnitFundTDS == "" || obj.InterestMutualOrUnitFundTDS == null)
        {
            obj.InterestMutualOrUnitFundTDS = "0.00"
        }

        if (obj.CashDividendStockExchangeAmount == "" || obj.CashDividendStockExchangeAmount == null)
        {
            obj.CashDividendStockExchangeAmount = "0.00"
        }

        if (obj.CashDividendStockExchangeTDS == "" || obj.CashDividendStockExchangeTDS == null)
        {
            obj.CashDividendStockExchangeTDS = "0.00"
        }

        if (obj.InterestIncomeWEDBAmount == "" || obj.InterestIncomeWEDBAmount == null)
        {
            obj.InterestIncomeWEDBAmount = "0.00"
        }

        if (obj.InterestIncomeWEDBTDS == "" || obj.InterestIncomeWEDBTDS == null)
        {
            obj.InterestIncomeWEDBTDS = "0.00"
        }

        if (obj.USDollarPremiumInvestmentBondAmount == "" || obj.USDollarPremiumInvestmentBondAmount == null)
        {
            obj.USDollarPremiumInvestmentBondAmount = "0.00"
        }

        if (obj.USDollarPremiumInvestmentBondTDS == "" || obj.USDollarPremiumInvestmentBondTDS == null)
        {
            obj.USDollarPremiumInvestmentBondTDS = "0.00"
        }

        if (obj.PoundSterlingPremiumInvestmentBondAmount == "" || obj.PoundSterlingPremiumInvestmentBondAmount == null)
        {
            obj.PoundSterlingPremiumInvestmentBondAmount = "0.00"
        }

        if (obj.PoundSterlingPremiumInvestmentBondTDS == "" || obj.PoundSterlingPremiumInvestmentBondTDS == null)
        {
            obj.PoundSterlingPremiumInvestmentBondTDS = "0.00"
        }

        if (obj.EuroPremiumInvestmentBondAmount == "" || obj.EuroPremiumInvestmentBondAmount == null)
        {
            obj.EuroPremiumInvestmentBondAmount = "0.00"
        }

        if (obj.EuroPremiumInvestmentBondTDS == "" || obj.EuroPremiumInvestmentBondTDS == null)
        {
            obj.EuroPremiumInvestmentBondTDS = "0.00"
        }

        if (obj.ZeroCouponBondAmount == "" || obj.ZeroCouponBondAmount == null)
        {
            obj.ZeroCouponBondAmount = "0.00"
        }

        if (obj.ZeroCouponBondTDS == "" || obj.ZeroCouponBondTDS == null)
        {
            obj.ZeroCouponBondTDS = "0.00"
        }

        if (obj.InvestmentPensionerSavingsInstrumentAmount == "" || obj.InvestmentPensionerSavingsInstrumentAmount == null)
        {
            obj.InvestmentPensionerSavingsInstrumentAmount = "0.00"
        }

        if (obj.InterestReceivedPensionerSavingsInstrumentAmount == "" || obj.InterestReceivedPensionerSavingsInstrumentAmount == null)
        {
            obj.InterestReceivedPensionerSavingsInstrumentAmount = "0.00"
        }

        if (obj.InterestReceivedPensionerSavingsInstrumentTDS == "" || obj.InterestReceivedPensionerSavingsInstrumentTDS == null)
        {
            obj.InterestReceivedPensionerSavingsInstrumentTDS = "0.00"
        }

        if (obj.SumReceivedBecauseOfPositionLikeDirectorFeeAmount == "" || obj.SumReceivedBecauseOfPositionLikeDirectorFeeAmount == null)
        {
            obj.SumReceivedBecauseOfPositionLikeDirectorFeeAmount = "0.00"
        }

        if (obj.SumReceivedBecauseOfPositionLikeDirectorFeeTDS == "" || obj.SumReceivedBecauseOfPositionLikeDirectorFeeTDS == null)
        {
            obj.SumReceivedBecauseOfPositionLikeDirectorFeeTDS = "0.00"
        }

        if (obj.HonoriumWritingArticleAmount == "" || obj.HonoriumWritingArticleAmount == null)
        {
            obj.HonoriumWritingArticleAmount = "0.00"
        }

        if (obj.HonoriumWritingArticleTDS == "" || obj.HonoriumWritingArticleTDS == null)
        {
            obj.HonoriumWritingArticleTDS = "0.00"
        }

        if (obj.RemunerationExaminerInvigilatorAmount == "" || obj.RemunerationExaminerInvigilatorAmount == null)
        {
            obj.RemunerationExaminerInvigilatorAmount = "0.00"
        }

        if (obj.RemunerationExaminerInvigilatorTDS == "" || obj.RemunerationExaminerInvigilatorTDS == null)
        {
            obj.RemunerationExaminerInvigilatorTDS = "0.00"
        }

        if (obj.IncomeTuitionAmount == "" || obj.IncomeTuitionAmount == null)
        {
            obj.IncomeTuitionAmount = "0.00"
        }

        if (obj.IncomeTuitionTDS == "" || obj.IncomeTuitionTDS == null)
        {
            obj.IncomeTuitionTDS = "0.00"
        }

        if (obj.IncomeTelevisionRadioParticipatingProgramAmount == "" || obj.IncomeTelevisionRadioParticipatingProgramAmount == null)
        {
            obj.IncomeTelevisionRadioParticipatingProgramAmount = "0.00"
        }

        if (obj.IncomeTelevisionRadioParticipatingProgramTDS == "" || obj.IncomeTelevisionRadioParticipatingProgramTDS == null)
        {
            obj.IncomeTelevisionRadioParticipatingProgramTDS = "0.00"
        }

        if (obj.OthersAmount == "" || obj.OthersAmount == null)
        {
            obj.OthersAmount = "0.00"
        }

        if (obj.OthersTDS == "" || obj.OthersTDS == null)
        {
            obj.OthersTDS = "0.00"
        }

        if (obj.TotalAmount == "" || obj.TotalAmount == null)
        {
            obj.TotalAmount = "0.00"
        }

        let stringed = JSON.stringify(obj);



        if (obj.TotalAmount == "" || obj.TotalAmount == 0 || obj.TotalAmount == "0.00")
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_ENTER_AMOUNT : bangla.data.COMMON.BUTTON_ENTER_AMOUNT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (stringed != null)
            {
                this._formControlService.submitOtherResources(stringed, this.id)
                    .subscribe((data: any) =>
                    {
                        if (data != null)
                        {
                            this._spinner.hide();
                        }

                        if (data.isError === false)
                        {
                            console.log('submit succesfull');
                        }
                        else
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                        if (data.isError === false && data.responseCode == "200")
                        {
                            if (this.id != null)
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            if (param == 'next')
                            {
                                this.improvisedIMenu(param);
                            }
                            if (param == 'more')
                            {
                                this.sidePanelDeleteService.changeMessage(true);
                                this.form.reset();
                            }
                            if (param == 'exit')
                            {
                                this._authService.Logout();
                            }
                        }
                    }, (error: any) =>
                    {
                        console.log(error, 'error');
                    });
            }
        }
    }

    ConfirmLeave(url): void
    {
        if (this.form.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    // this._router.navigate([url]);
                    this.improvisedIMenu(url);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            // this._router.navigate([url]);
            this.improvisedIMenu(url);
        }
    }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'income');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }

}
// }
