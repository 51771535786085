<div [formGroup]="form"
  fxLayout="column wrap">
  <div [ngSwitch]="control.controlType"
    fxLayout="column wrap"
    fxLayoutAlign="start"
    fxFlex="1 0 auto">
    <div class="h2 mb-24"
      style="margin-top:20px;color: #206030;"
      *ngSwitchCase="'label'">{{control.label}}</div>

    <div *ngSwitchCase="'textbox'"
      [className]="control.relationClass"
      fxLayout="column"
      fxLayoutAlign="start">
      <div fxLayoutAlign="center"
        fxLayout="column wrap">

        <span class="name"
          style="font-size: 15px;padding-top: 10px;color: #444444;"
          *ngIf="control.key != 'PermanentAddress'"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon>
        </span>

        <mat-form-field appearance="outline"
          fxFlex="100"
          [id]="control.key"
          [attr.for]="control.key">
          <input *ngSwitchCase="'textbox'"
            autocomplete="off"
            [formControlName]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error> {{control.message}}</mat-error>
        </mat-form-field>
      </div>

    </div>
    <div *ngSwitchCase="'textboxGroup'"
      style="margin-top: 10px;">
      <div *ngFor="let Source of control.children"
        fxLayoutAlign="space-between center"
        fxLayout="row wrap"
        [id]="control.key"
        fxFlex="100"
        [className]="control.relationClass">
        <div fxLayoutAlign="center"
          fxLayout="column wrap"
          *ngIf="Source.controlType=='radio'"
          fxFlex="100">
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='Gender'">
            <span class="name"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}}<mat-icon style="margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon>
            </span>
            <div fxLayout="row">
              <mat-radio-group required={{Source.required}}
                [ngClass]="{'invalid': form.get('Gender').invalid && rGender}"
                [formControlName]="Source.key"
                id={{Source.key}}
                fxLayout="row">
                <mat-radio-button style="margin-right: 40px;"
                  *ngFor="let option of Source.options"
                  [value]="option.key"
                  (change)="radioChange($event,Source.key)">
                  {{option.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='MaritalStatus'"
            style="padding-left: 10px;">
            <span class="name"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}}<mat-icon style="margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon>
            </span>
            <div fxLayout="row">
              <mat-radio-group required={{Source.required}}
                [ngClass]="{'invalid': form.get('MaritalStatus').invalid && rMarital}"
                [formControlName]="Source.key"
                id={{Source.key}}
                fxLayout="row">
                <mat-radio-button style="margin-right: 40px;"
                  *ngFor="let option of Source.options"
                  [value]="option.key"
                  (change)="radioChange($event,Source.key)">
                  {{option.value}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

        </div>
        <div fxLayoutAlign="start"
          fxLayout="column wrap"
          fxFlex="100"
          *ngIf="Source.controlType=='dateSelector'">
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            style="padding-left: 10px;">
            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex=60>

              <input matInput
                readonly
                (click)="dp.open()"
                [max]="maxDate"
                [matDatepicker]="dp"
                [formControlName]="Source.key"
                value="Source.value">
              <mat-datepicker-toggle matSuffix
                [for]="dp"></mat-datepicker-toggle>

              <mat-datepicker #dp
                startView="multi-year"
                panelClass="example-month-picker">
              </mat-datepicker>
            </mat-form-field>
          </div>

        </div>
        <div *ngIf="Source.controlType=='textbox'"
          fxLayoutAlign="start"
          fxLayout="column wrap"
          fxFlex="100">
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='FatherName'"
            style="padding-right: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                autocomplete="off"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='MotherName'"
            style="padding-left: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                autocomplete="off"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='NID'"
            style="padding-right: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key">{{Source.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                NumbersOnly
                autocomplete="off"
                type="text"
                decimals="100"
                negative="1"
                maxlength={{Source.maxlength}}
                minlength={{Source.minlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <!-- <mat-error>{{Source.message}}</mat-error> -->
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='SpouseName'"
            style="padding-right: 10px;padding-bottom: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key">
              <!-- <span style="color: red;font-weight: bold;">* </span> -->
              {{Source.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon>
            </span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                autocomplete="off"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='SpouseTIN'"
            style="padding-left: 10px;padding-bottom: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key">{{Source.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                NumbersOnly
                autocomplete="off"
                type="text"
                decimals="100"
                negative="1"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='ContactNo'"
            style="padding-right: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                autocomplete="off"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                NumbersOnly
                type="text"
                decimals="100"
                negative="1"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='Email'"
            style="padding-left: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                autocomplete="off"
                tabindex="-1"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='PresentPostCode'"
            style="padding-left: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                autocomplete="off"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            *ngIf="Source.key=='PermanentPostCode'"
            style="padding-left: 10px;">

            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              fxFlex="60"
              class="{{Source.readonly==true ? 'readonly' : ''}}"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                autocomplete="off"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div fxLayoutAlign="start"
          fxLayout="column wrap"
          fxFlex="100"
          *ngIf="Source.controlType=='dropdown'">

          <div fxLayoutAlign="start"
            fxLayout="column wrap"
            fxFlex="100"
            style="padding-right: 10px;">
            <span class="name"
              fxFlex="35"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              [id]="Source.key"
              fxFlex="60">
              <mat-select formControlName="{{Source.key}}"
                required={{Source.required}}>

                <mat-option *ngFor="let option of Source.options"
                  [value]="option.key">
                  {{option.value}}
                </mat-option>

              </mat-select>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>
          </div>

        </div>
      </div>

    </div>
    <div *ngSwitchCase="'checkbox'"
      [className]="control.relationClass"
      fxLayoutAlign="start"
      fxLayout="row wrap"
      fxFlex="100"
      style="padding-top: 20px;">

      <span class="name"
        style="font-size: 15px;color: #444444;"
        [attr.for]="control.key"><span style="color: red;font-weight: bold;">* </span>{{control.label}}
        <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip="{{ 'PERSONAL.T_PERMANENT_ADDRESS' | translate }}"
          matSuffix>info
        </mat-icon>
        &nbsp;&nbsp;&nbsp;
      </span>

      <mat-checkbox style="margin-left: 5px;font-size: 13px;"
        (change)="showOptions($event)"
        [disabled]="checkOn"
        [formControlName]="control.key"
        [value]="control.value"> {{control.tooltip}}</mat-checkbox>
      <mat-icon style="margin-top: 5px;margin-left: 5px"
        matTooltip={{control.tooltip}}
        matSuffix>info
      </mat-icon>
    </div>

  </div>
</div>
