<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar *ngIf="user.ActiveRoleId=='8'"
        class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">
            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                Payment & Download
            </span>
        </div>

        <!-- transaction -->
        <div class="content"
            *ngIf="userIND">

            <div *ngIf="IsError && ErrorMessage && OrderId && PaymentStatus"
                fxLayout="row"
                fxLayoutAlign="center center">
                <img *ngIf="PaymentStatus==1"
                    class="m-12"
                    style="width: 15%; height: 15%;"
                    src="assets/home/img/success.png">
                <img *ngIf="PaymentStatus!=1"
                    class="m-12"
                    style="width: 15%; height: 15%;"
                    src="assets/home/img/failed.png">
                <div fxLayout="column"
                    fxLayoutAlign="center start">
                    <span *ngIf="PaymentStatus==1"
                        class="h1 bold"
                        style="color: #29793C;">Congrtulation!</span>
                    <span *ngIf="PaymentStatus!=1"
                        class="h1 bold"
                        style="color: red;">Sorry!</span>
                    <span *ngIf="PaymentStatus==1"
                        class="h2"
                        style="color: #29793C;">Your payment was successfull!</span>
                    <span *ngIf="PaymentStatus==0"
                        class="h2"
                        style="color: red;">Your payment has failed. Error detail: {{ErrorMessage}}</span>
                    <span *ngIf="PaymentStatus==2"
                        class="h2"
                        style="color: red;">There was an error</span>
                    <span *ngIf="PaymentStatus==3"
                        class="h2"
                        style="color: red;">Your payment was cancelled</span>
                    <span *ngIf="PaymentStatus==4"
                        class="h2"
                        style="color: red;">Your payment was rejected</span>
                    <span *ngIf="PaymentStatus==5"
                        class="h2"
                        style="color: red;">Your payment was already uploaded</span>
                </div>

            </div>

            <div class="header"
                *ngIf="CurrentPlanName!=''"
                fxLayoutAlign="center center">
                <button *ngIf="CurrentPlanName!='Free'"
                    mat-button
                    style="padding: 10px; margin:20px"
                    [matMenuTriggerFor]="userMenu"
                    class="user-button dupl">
                    <div fxLayout="row"
                        fxLayoutAlign="center center">

                        <span class="username"
                            style="margin-left: 10px;margin-right: 10px;">Download</span>
                        <mat-icon class="s-24">keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu"
                    [overlapTrigger]="false">

                    <button mat-menu-item
                        (click)="downloafPDF2()">
                        <span>Return file</span>
                    </button>

                    <button mat-menu-item
                        (click)="summaryPDF()">
                        <span>Calculation summary</span>
                    </button>

                    <button mat-menu-item
                        (click)="openChalanDialog()">
                        <span>Chalan</span>
                    </button>

                </mat-menu>
            </div>

            <div *ngIf="hasTransaction"
                fxLayoutAlign="center center"
                class="p-24"
                style="padding-bottom: 12px !important;">
                <span class="h3">
                    Last transaction Made through <b>{{lastTransactionData.ChannelName_Vendor}}</b> on
                    <b>{{TranDate}}</b>. Total Paid Amount, <b>৳{{lastTransactionData.TranAmount}}</b>.
                </span>
            </div>

            <!-- FREE PLAN -->

            <div fxLayout="column"
                class="p-12 m-24"
                fxLayoutAlign="space-between"
                fxLayout.gt-md="row"
                style="border: 1px solid #C4C4C4; margin-top: 12px !important; border-radius: 5px;">
                <div fxLayout="column"
                    fxLayoutAlign="space-between"
                    fxLayout.gt-md="column"
                    fxFlex="100">
                    <div fxLayout="row"
                        fxLayoutAlign="start center"
                        fxFlex="100"
                        style="padding: 6px !important;">
                        <span class="h3"
                            fxLayoutAlign="start center">
                            <mat-icon *ngIf="CurrentPlan==0"
                                style="color: red;  margin-right: 3px;"
                                matSuffix>info
                            </mat-icon><span>You are currently on a <b>{{CurrentPlanName}} plan</b>.<span
                                    *ngIf="CurrentPlan==0"> To unlock the download option, please upgrade your
                                    plan.</span></span>
                        </span>
                    </div>

                    <div *ngIf="CurrentPlan==2"
                        fxLayout="column"
                        fxLayoutAlign="space-between">
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="100"
                            style="padding: 3px !important;margin: 3px !important;">
                            <span class="h3"
                                fxLayoutAlign="start center">
                                <mat-icon style="color: red; margin-right: 3px;"
                                    matSuffix>info
                                </mat-icon><span>To get advisory support by a tax consultant upgrade to
                                    <b>Standard</b></span>
                            </span>
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="100"
                            style="padding: 3px !important;margin: 3px !important;">
                            <span class="h3"
                                fxLayoutAlign="start center">
                                <mat-icon style="color: red; margin-right: 3px;"
                                    matSuffix>info
                                </mat-icon><span>To submit your return to the NBR office by digiTax upgrade to
                                    <b>Premium</b></span>
                            </span>
                        </div>
                    </div>

                    <div *ngIf="CurrentPlan==3"
                        fxLayout="column"
                        fxLayoutAlign="space-between">
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="100"
                            style="padding: 3px !important;margin: 3px !important;">
                            <span class="h3"
                                fxLayoutAlign="start center">
                                <mat-icon style="color: red; margin-right: 3px;"
                                    matSuffix>info
                                </mat-icon><span>To submit your return to the NBR office by digiTax upgrade to
                                    <b>Premium</b></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="20"
                    *ngIf="CurrentPlanName!='Free' && CurrentPlanName!='Premium'"
                    style="padding: 3px !important;margin: 3px !important; margin-bottom: 10px !important; margin-top: 10px !important;">

                    <button mat-raised-button
                        *ngIf="CurrentPlanName!=''"
                        (click)="ShowTerm()"
                        class="mat-button mat-button-base primary-A400 dupl">
                        Upgrade Now
                    </button>

                </div>
            </div>
            <div fxLayout="column"
                *ngIf="CurrentPlanName=='Free' || showTerm==true">

                <div fxLayout="column"
                    class="p-12 m-24"
                    fxLayoutAlign="space-between center">
                    <div fxLayout="row"
                        fxLayoutAlign="center center"
                        style="margin-bottom: 20px;">
                        <span class="h1"
                            style="color: #208420; font-weight: bold;">
                            Upgrade Your Plan
                        </span>

                    </div>
                    <div fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="center start">
                        <mat-checkbox style="margin-right: 5px;"
                            (change)="showOptions($event,'term')"></mat-checkbox>
                        <span class="name"
                            style="color: #444444;">I hereby declare that the information provided in my
                            return file is correct and digiTax will not be responsible for any wrong information.
                            (82BB)<br>I also agree to all the <a [routerLink]="'/terms-and-conditions'"
                                target="_blank">Terms & Conditions</a> , <a [routerLink]="'/return-and-refund-policy'"
                                target="_blank">Refund Policy</a> & <a [routerLink]="'/privacy-policy'"
                                target="_blank">Privacy Policy</a> of digiTax.</span>
                    </div>

                    <div *ngIf="CurrentPlanName!='Free'"
                        fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="center center"
                        class="mt-24">
                        <span class="name"
                            style="color: #444444;">
                            <span style="color: red;">Note:</span> Your paid amount will be adjusted if you try to
                            upgrade later.
                        </span>
                    </div>

                </div>

                <div *ngIf="hasPlan">
                    <div *ngIf="CurrentPlan==0"
                        fxLayout="column"
                        class=" m-24"
                        fxLayoutAlign="center"
                        fxLayout.gt-md="row">
                        <div class="widget"
                            fxFlex="100"
                            fxFlex.gt-sm="33">

                            <div class="fuse-card auto-width mb-32"
                                fxLayout="column"
                                fxLayoutAlign="space-between"
                                fxLayout.gt-md="column"
                                [ngClass.gt-sm]="'mb-0 mr-12'"
                                style="background-color: #D7F0D7; border: 1px solid #C4C4C4 !important; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                                <div class="pt-16 pb-8"
                                    fxLayout="column wrap"
                                    fxLayoutAlign="center center"
                                    style="border-bottom: 1px solid #D7F0D7;">

                                    <div class="p-4">
                                        <div class="h1 primary-text"
                                            style="font-weight: bold;">
                                            ৳
                                            <span
                                                [ngClass]="StarterPlanNewPrice==null ? 'null' : 'strikethrough'">{{StarterPlan.PlanPrice}}</span>
                                            /
                                            <span class="h3">per year</span>
                                        </div>
                                        <div *ngIf="StarterPlanNewPrice!=null"
                                            class="h1 primary-text"
                                            style="font-weight: bold;">
                                            ৳
                                            <span>{{StarterPlanNewPrice}}</span>/
                                            <span class="h3">per year</span>
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <div class="h4 primary-text"
                                            style="font-weight: bold;">{{StarterPlan.PlanName}}</div>
                                    </div>

                                </div>

                                <div fxLayout="column"
                                    fxLayoutAlign="space-between"
                                    fxLayout.gt-md="column"
                                    fxFlex="100"
                                    style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px;">

                                    <div fxLayout="row"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-icon style="color: #29793C; margin-right: 3px;"
                                            matSuffix>check_circle_outline
                                        </mat-icon><span>Download return as PDF</span>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important; visibility: hidden;">
                                        <!-- <span class="h5"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <mat-checkbox style="margin-right: 5px; margin-left: 5px;"></mat-checkbox>
                                            <span>Also include Tax amount deposit by digiTax</span>
                                        </span> -->
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-form-field appearance="outline">
                                            <input type="text"
                                                placeholder="Apply Coupon"
                                                [formControl]="StarterPlanCouponControl"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>
                                        </mat-form-field>
                                        <button mat-raised-button
                                            *ngIf="StarterPlanNewPrice==null"
                                            (click)="ApplyStarterPlanCouponControl(StarterPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Apply</span>
                                        </button>

                                        <button mat-raised-button
                                            *ngIf="StarterPlanNewPrice!=null"
                                            style="color: #444444 !important; margin-right: 10px;"
                                            (click)="RemoveStarterPlanCoupon()"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            Remove
                                        </button>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <button mat-raised-button
                                            (click)="OnSubmitIND(StarterPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Select & Pay Now</span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="widget"
                            fxFlex="100"
                            fxFlex.gt-sm="33">

                            <div class="fuse-card auto-width mb-32"
                                fxLayout="column"
                                fxLayoutAlign="space-between"
                                fxLayout.gt-md="column"
                                [ngClass.gt-sm]="'mb-0 mr-12'"
                                style="background-color: #D7F0D7; border: 1px solid #C4C4C4 !important; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                                <div class="pt-16 pb-8"
                                    fxLayout="column wrap"
                                    fxLayoutAlign="center center"
                                    style="border-bottom: 1px solid #D7F0D7;">

                                    <div class="p-4">
                                        <div class="h1 primary-text"
                                            style="font-weight: bold;">
                                            ৳
                                            <span
                                                [ngClass]="StandardPlanNewPrice==null ? 'null' : 'strikethrough'">{{StandardPlan.PlanPrice}}</span>
                                            /
                                            <span class="h3">per year</span>
                                        </div>
                                        <div *ngIf="StandardPlanNewPrice!=null"
                                            class="h1 primary-text"
                                            style="font-weight: bold;">
                                            ৳
                                            <span>{{StandardPlanNewPrice}}</span>/
                                            <span class="h3">per year</span>
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <div class="h4 primary-text"
                                            style="font-weight: bold;">{{StandardPlan.PlanName}}</div>
                                    </div>

                                </div>

                                <div fxLayout="column"
                                    fxLayoutAlign="space-between"
                                    fxLayout.gt-md="column"
                                    fxFlex="100"
                                    style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px;">

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-icon style="color: #29793C; margin-right: 3px;"
                                            matSuffix>check_circle_outline
                                        </mat-icon><span>Get tax advisory support & verify your return by a tax
                                            consultant
                                            with
                                            the benefits offered with Starter.</span>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important; visibility: hidden;">
                                        <!-- <span class="h5"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <mat-checkbox style="margin-right: 5px; margin-left: 5px;"></mat-checkbox>
                                            <span>Also include Tax amount deposit by digiTax</span>
                                        </span> -->
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-form-field appearance="outline">
                                            <input type="text"
                                                placeholder="Apply Coupon"
                                                [formControl]="StandardPlanCouponControl"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>
                                        </mat-form-field>
                                        <button mat-raised-button
                                            *ngIf="StandardPlanNewPrice==null"
                                            (click)="ApplyStandardPlanCouponControl(StandardPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Apply</span>
                                        </button>

                                        <button mat-raised-button
                                            *ngIf="StandardPlanNewPrice!=null"
                                            style="color: #444444 !important; margin-right: 10px;"
                                            (click)="RemoveStandardPlanCoupon()"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            Remove
                                        </button>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <button mat-raised-button
                                            (click)="OnSubmitIND(StandardPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Select & Pay Now</span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div *ngIf="PremiumPlan!=null"
                            class="widget"
                            fxFlex="100"
                            fxFlex.gt-sm="33">

                            <div class="fuse-card auto-width"
                                fxLayout="column"
                                fxLayoutAlign="space-between"
                                fxLayout.gt-md="column"
                                [ngClass.gt-sm]="'mb-0 mr-6'"
                                style="background-color: #6AB27A;  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                                <div class="pt-16 pb-8"
                                    fxLayout="column wrap"
                                    fxLayoutAlign="center center"
                                    style="border-bottom: 1px solid #6AB27A;">

                                    <div class="p-4">
                                        <div class="h1 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">
                                            ৳
                                            <span
                                                [ngClass]="PremiumPlanNewPrice==null ? 'null' : 'strikethrough'">{{PremiumPlan.PlanPrice}}</span>
                                            /
                                            <span class="h3">per year</span>
                                        </div>
                                        <div *ngIf="PremiumPlanNewPrice!=null"
                                            class="h1 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">
                                            ৳
                                            <span>{{PremiumPlanNewPrice}}</span>/
                                            <span class="h3">per year</span>
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <div class="h4 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">{{PremiumPlan.PlanName}}</div>
                                    </div>

                                </div>

                                <div fxLayout="column"
                                    fxLayoutAlign="space-between"
                                    fxLayout.gt-md="column"
                                    fxFlex="100"
                                    style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px; ">

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-icon style="color: #29793C; margin-right: 3px;"
                                            matSuffix>check_circle_outline
                                        </mat-icon><span>Return submit to circle office by digiTax with the benefits
                                            offered
                                            with Standard.</span>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <span class="h5"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <mat-checkbox style="margin-right: 5px; margin-left: 5px;"
                                                (change)="showOptions($event,'add')"></mat-checkbox>
                                            <span>Also include Tax amount deposit by digiTax</span>
                                        </span>
                                    </div>

                                    <div class="p-8 hidden"
                                        fxLayout="column"
                                        fxLayoutAlign="start"
                                        id="TaxAmountDiv">

                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                digiTax Fee
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">
                                                <span *ngIf="PremiumPlanNewPrice==null">{{PremiumPlan.PlanPrice}}</span>
                                                <span *ngIf="PremiumPlanNewPrice!=null">{{PremiumPlanNewPrice}}</span>
                                            </div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Tax
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlan.TaxAmount}}</div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Bank Charge<br>
                                                <div style="font-size: 10px;"> (2.5% of Tax amount)</div>
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlan.BankCharge}}</div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Total
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlanSum}}</div>
                                        </div>
                                    </div>

                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-form-field appearance="outline">
                                            <input type="text"
                                                placeholder="Apply Coupon"
                                                [formControl]="PremiumPlanCouponControl"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>
                                        </mat-form-field>
                                        <button mat-raised-button
                                            *ngIf="PremiumPlanNewPrice==null"
                                            (click)="ApplyPremiumPlanCouponControl(PremiumPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Apply</span>
                                        </button>

                                        <button mat-raised-button
                                            *ngIf="PremiumPlanNewPrice!=null"
                                            style="color: #444444 !important; margin-right: 10px;"
                                            (click)="RemovePremiumPlanCoupon()"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            Remove
                                        </button>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <button mat-raised-button
                                            (click)="OnSubmitIND(PremiumPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <span>Select & Pay Now</span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div *ngIf="CurrentPlan==2"
                        fxLayout="column"
                        class=" m-24"
                        fxLayoutAlign="center"
                        fxLayout.gt-md="row">

                        <div class="widget"
                            fxFlex="100"
                            fxFlex.gt-sm="33">

                            <div class="fuse-card auto-width mb-32"
                                fxLayout="column"
                                fxLayoutAlign="space-between"
                                fxLayout.gt-md="column"
                                [ngClass.gt-sm]="'mb-0 mr-12'"
                                style="background-color: #D7F0D7; border: 1px solid #C4C4C4 !important; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                                <div class="pt-16 pb-8"
                                    fxLayout="column wrap"
                                    fxLayoutAlign="center center"
                                    style="border-bottom: 1px solid #D7F0D7;">

                                    <div class="p-4">
                                        <div class="h1 primary-text"
                                            style="font-weight: bold;">
                                            ৳
                                            <span
                                                [ngClass]="StandardPlanNewPrice==null ? 'null' : 'strikethrough'">{{StandardPlan.PlanPrice}}</span>
                                            /
                                            <span class="h3">per year</span>
                                        </div>
                                        <div *ngIf="StandardPlanNewPrice!=null"
                                            class="h1 primary-text"
                                            style="font-weight: bold;">
                                            ৳
                                            <span>{{StandardPlanNewPrice}}</span>/
                                            <span class="h3">per year</span>
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <div class="h4 primary-text"
                                            style="font-weight: bold;">{{StandardPlan.PlanName}}</div>
                                    </div>

                                </div>

                                <div fxLayout="column"
                                    fxLayoutAlign="space-between"
                                    fxLayout.gt-md="column"
                                    fxFlex="100"
                                    style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px;">

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-icon style="color: #29793C; margin-right: 3px;"
                                            matSuffix>check_circle_outline
                                        </mat-icon><span>Get tax advisory support & verify your return by a tax
                                            consultant
                                            with
                                            the benefits offered with Starter.</span>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important; visibility: hidden;">
                                        <!-- <span class="h5"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <mat-checkbox style="margin-right: 5px; margin-left: 5px;"></mat-checkbox>
                                            <span>Also include Tax amount deposit by digiTax</span>
                                        </span> -->
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-form-field appearance="outline">
                                            <input type="text"
                                                placeholder="Apply Coupon"
                                                [formControl]="StandardPlanCouponControl"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>
                                        </mat-form-field>
                                        <button mat-raised-button
                                            *ngIf="StandardPlanNewPrice==null"
                                            (click)="ApplyStandardPlanCouponControl(StandardPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Apply</span>
                                        </button>

                                        <button mat-raised-button
                                            *ngIf="StandardPlanNewPrice!=null"
                                            style="color: #444444 !important; margin-right: 10px;"
                                            (click)="RemoveStandardPlanCoupon()"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            Remove
                                        </button>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <button mat-raised-button
                                            (click)="OnSubmitIND(StandardPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Select & Pay Now</span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div *ngIf="PremiumPlan!=null"
                            class="widget"
                            fxFlex="100"
                            fxFlex.gt-sm="33">

                            <div class="fuse-card auto-width"
                                fxLayout="column"
                                fxLayoutAlign="space-between"
                                fxLayout.gt-md="column"
                                [ngClass.gt-sm]="'mb-0 mr-6'"
                                style="background-color: #6AB27A;  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                                <div class="pt-16 pb-8"
                                    fxLayout="column wrap"
                                    fxLayoutAlign="center center"
                                    style="border-bottom: 1px solid #6AB27A;">

                                    <div class="p-4">
                                        <div class="h1 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">
                                            ৳
                                            <span
                                                [ngClass]="PremiumPlanNewPrice==null ? 'null' : 'strikethrough'">{{PremiumPlan.PlanPrice}}</span>
                                            /
                                            <span class="h3">per year</span>
                                        </div>
                                        <div *ngIf="PremiumPlanNewPrice!=null"
                                            class="h1 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">
                                            ৳
                                            <span>{{PremiumPlanNewPrice}}</span>/
                                            <span class="h3">per year</span>
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <div class="h4 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">{{PremiumPlan.PlanName}}</div>
                                    </div>

                                </div>

                                <div fxLayout="column"
                                    fxLayoutAlign="space-between"
                                    fxLayout.gt-md="column"
                                    fxFlex="100"
                                    style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px; ">

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-icon style="color: #29793C; margin-right: 3px;"
                                            matSuffix>check_circle_outline
                                        </mat-icon><span>Return submit to circle office by digiTax with the benefits
                                            offered
                                            with Standard.</span>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <span class="h5"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <mat-checkbox style="margin-right: 5px; margin-left: 5px;"
                                                (change)="showOptions($event,'add')"></mat-checkbox>
                                            <span>Also include Tax amount deposit by digiTax</span>
                                        </span>
                                    </div>

                                    <div class="p-8 hidden"
                                        fxLayout="column"
                                        fxLayoutAlign="start"
                                        id="TaxAmountDiv">

                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                digiTax Fee
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">
                                                <span *ngIf="PremiumPlanNewPrice==null">{{PremiumPlan.PlanPrice}}</span>
                                                <span *ngIf="PremiumPlanNewPrice!=null">{{PremiumPlanNewPrice}}</span>
                                            </div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Tax
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlan.TaxAmount}}</div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Bank Charge<br>
                                                <div style="font-size: 10px;"> (2.5% of Tax amount)</div>
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlan.BankCharge}}</div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Total
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlanSum}}</div>
                                        </div>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-form-field appearance="outline">
                                            <input type="text"
                                                placeholder="Apply Coupon"
                                                [formControl]="PremiumPlanCouponControl"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>
                                        </mat-form-field>
                                        <button mat-raised-button
                                            *ngIf="PremiumPlanNewPrice==null"
                                            (click)="ApplyPremiumPlanCouponControl(PremiumPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Apply</span>
                                        </button>

                                        <button mat-raised-button
                                            *ngIf="PremiumPlanNewPrice!=null"
                                            style="color: #444444 !important; margin-right: 10px;"
                                            (click)="RemovePremiumPlanCoupon()"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            Remove
                                        </button>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <button mat-raised-button
                                            (click)="OnSubmitIND(PremiumPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <span>Select & Pay Now</span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div *ngIf="CurrentPlan==3"
                        fxLayout="column"
                        class=" m-24"
                        fxLayoutAlign="center"
                        fxLayout.gt-md="row">

                        <div *ngIf="PremiumPlan!=null"
                            class="widget"
                            fxFlex="100"
                            fxFlex.gt-sm="33">

                            <div class="fuse-card auto-width"
                                fxLayout="column"
                                fxLayoutAlign="space-between"
                                fxLayout.gt-md="column"
                                [ngClass.gt-sm]="'mb-0 mr-6'"
                                style="background-color: #6AB27A;  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                                <div class="pt-16 pb-8"
                                    fxLayout="column wrap"
                                    fxLayoutAlign="center center"
                                    style="border-bottom: 1px solid #6AB27A;">

                                    <div class="p-4">
                                        <div class="h1 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">
                                            ৳
                                            <span
                                                [ngClass]="PremiumPlanNewPrice==null ? 'null' : 'strikethrough'">{{PremiumPlan.PlanPrice}}</span>
                                            /
                                            <span class="h3">per year</span>
                                        </div>
                                        <div *ngIf="PremiumPlanNewPrice!=null"
                                            class="h1 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">
                                            ৳
                                            <span>{{PremiumPlanNewPrice}}</span>/
                                            <span class="h3">per year</span>
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <div class="h4 primary-text"
                                            style="font-weight: bold; color: #FFFFFF;">{{PremiumPlan.PlanName}}</div>
                                    </div>

                                </div>

                                <div fxLayout="column"
                                    fxLayoutAlign="space-between"
                                    fxLayout.gt-md="column"
                                    fxFlex="100"
                                    style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px; ">

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-icon style="color: #29793C; margin-right: 3px;"
                                            matSuffix>check_circle_outline
                                        </mat-icon><span>Return submit to circle office by digiTax with the benefits
                                            offered
                                            with Standard.</span>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <span class="h5"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <mat-checkbox style="margin-right: 5px;"
                                                (change)="showOptions($event,'add')"></mat-checkbox>
                                            <span>Also include Tax amount deposit by digiTax</span>
                                        </span>
                                    </div>

                                    <div class="p-8 hidden"
                                        fxLayout="column"
                                        fxLayoutAlign="start"
                                        id="TaxAmountDiv">

                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                digiTax Fee
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">
                                                <span *ngIf="PremiumPlanNewPrice==null">{{PremiumPlan.PlanPrice}}</span>
                                                <span *ngIf="PremiumPlanNewPrice!=null">{{PremiumPlanNewPrice}}</span>
                                            </div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Tax
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlan.TaxAmount}}</div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Bank Charge<br>
                                                <div style="font-size: 10px;"> (2.5% of Tax amount)</div>
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlan.BankCharge}}</div>
                                        </div>
                                        <div class="p-2"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center">
                                            <div class="h5 primary-text"
                                                style="margin-left: 16px; font-weight: bold;">
                                                Total
                                            </div>
                                            <div class="h5 primary-text"
                                                style="font-weight: bold;">{{PremiumPlanSum}}</div>
                                        </div>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <mat-form-field appearance="outline">
                                            <input type="text"
                                                placeholder="Apply Coupon"
                                                [formControl]="PremiumPlanCouponControl"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>
                                        </mat-form-field>
                                        <button mat-raised-button
                                            *ngIf="PremiumPlanNewPrice==null"
                                            (click)="ApplyPremiumPlanCouponControl(PremiumPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">

                                            <span>Apply</span>
                                        </button>

                                        <button mat-raised-button
                                            *ngIf="PremiumPlanNewPrice!=null"
                                            style="color: #444444 !important; margin-right: 10px;"
                                            (click)="RemovePremiumPlanCoupon()"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            Remove
                                        </button>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center"
                                        fxFlex="100"
                                        style="padding: 3px !important;margin: 3px !important;">
                                        <button mat-raised-button
                                            (click)="OnSubmitIND(PremiumPlan)"
                                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                                            class="mat-button dupl"
                                            fxFlex="0 0 auto"
                                            fxLayout="row"
                                            fxLayoutAlign="center center">
                                            <span>Select & Pay Now</span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="content"
            *ngIf="userPRAC">

            <div *ngIf="IsError && ErrorMessage && OrderId && PaymentStatus"
                fxLayout="row"
                fxLayoutAlign="center center">
                <img *ngIf="PaymentStatus==1"
                    class="m-12"
                    style="width: 15%; height: 15%;"
                    src="assets/home/img/success.png">
                <img *ngIf="PaymentStatus!=1"
                    class="m-12"
                    style="width: 15%; height: 15%;"
                    src="assets/home/img/failed.png">
                <div fxLayout="column"
                    fxLayoutAlign="center start">
                    <span *ngIf="PaymentStatus==1"
                        class="h1 bold"
                        style="color: #29793C;">Congrtulation!</span>
                    <span *ngIf="PaymentStatus!=1"
                        class="h1 bold"
                        style="color: red;">Sorry!</span>
                    <span *ngIf="PaymentStatus==1"
                        class="h2"
                        style="color: #29793C;">Your payment was successfull!</span>
                    <span *ngIf="PaymentStatus==0"
                        class="h2"
                        style="color: red;">Your payment has failed. Error detail: {{ErrorMessage}}</span>
                    <span *ngIf="PaymentStatus==2"
                        class="h2"
                        style="color: red;">There was an error</span>
                    <span *ngIf="PaymentStatus==3"
                        class="h2"
                        style="color: red;">Your payment was cancelled</span>
                    <span *ngIf="PaymentStatus==4"
                        class="h2"
                        style="color: red;">Your payment was rejected</span>
                    <span *ngIf="PaymentStatus==5"
                        class="h2"
                        style="color: red;">Your payment was already uploaded</span>
                </div>

            </div>

            <div *ngIf="hasTransaction"
                fxLayoutAlign="center center"
                class="p-24"
                style="padding-bottom: 12px !important;">
                <span class="h3">
                    Last transaction Made through <b>{{lastTransactionData.ChannelName_Vendor}}</b> on
                    <b>{{TranDate}}</b>. Total Paid Amount, <b>৳{{lastTransactionData.TranAmount}}</b>.
                </span>
            </div>

            <div fxLayout="column"
                class="p-12 m-24"
                fxLayoutAlign="space-between"
                fxLayout.gt-md="row"
                style="border: 1px solid #C4C4C4; margin-top: 12px !important; border-radius: 5px;">
                <div fxLayout="column"
                    fxLayoutAlign="space-between"
                    fxLayout.gt-md="column"
                    fxFlex="100">
                    <div fxLayout="row"
                        fxLayoutAlign="start center"
                        fxFlex="100"
                        style="padding: 6px !important;">
                        <span class="h3"
                            fxLayoutAlign="start center">
                            <mat-icon style="color: red;  margin-right: 3px;"
                                matSuffix>info
                            </mat-icon><span>You currently Have <b>{{NoOfClientFiles}}</b> paid clients. To prepare more
                                client files, please select one of the following plans.
                            </span>
                        </span>
                    </div>

                </div>

            </div>

            <div fxLayout="column"
                class="p-12 m-24"
                fxLayoutAlign="space-between center">
                <div fxLayout="row"
                    fxLayoutAlign="center center"
                    style="margin-bottom: 20px;">
                    <span class="h1"
                        style="color: #208420; font-weight: bold;">
                        Upgrade Your Plan
                    </span>

                </div>

            </div>
            <div fxLayout="column"
                class=" m-24"
                fxLayoutAlign="center"
                fxLayout.gt-md="row">
                <div class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width mb-32"
                        fxLayout="column"
                        fxLayoutAlign="space-between"
                        fxLayout.gt-md="column"
                        [ngClass.gt-sm]="'mb-0 mr-12'"
                        style="background-color: #D7F0D7; border: 1px solid #C4C4C4 !important; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                        <div class="pt-16 pb-8"
                            fxLayout="column wrap"
                            fxLayoutAlign="center center"
                            style="border-bottom: 1px solid #D7F0D7;">

                            <div class="p-4">
                                <div class="h1 primary-text"
                                    style="font-weight: bold;">৳ {{nPlanPrice2}}/ <span class="h3">per
                                        file yearly</span>
                                </div>
                            </div>
                            <div class="p-4">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">Starter</div>
                            </div>

                        </div>

                        <div fxLayout="column"
                            fxLayoutAlign="space-between"
                            fxLayout.gt-md="column"
                            fxFlex="100"
                            style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px;">

                            <div fxLayout="row"
                                fxLayoutAlign="center center"
                                fxFlex="100"
                                style="padding: 3px !important;margin: 3px !important;">
                                <mat-icon style="color: #29793C; margin-right: 3px;"
                                    matSuffix>check_circle_outline
                                </mat-icon><span>This rate is applicable for the next 45 client files; i.e, from 6th to
                                    50th client file</span>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width mb-32"
                        fxLayout="column"
                        fxLayoutAlign="space-between"
                        fxLayout.gt-md="column"
                        [ngClass.gt-sm]="'mb-0 mr-12'"
                        style="background-color: #D7F0D7; border: 1px solid #C4C4C4 !important; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                        <div class="pt-16 pb-8"
                            fxLayout="column wrap"
                            fxLayoutAlign="center center"
                            style="border-bottom: 1px solid #D7F0D7;">

                            <div class="p-4">
                                <div class="h1 primary-text"
                                    style="font-weight: bold;">৳ {{nPlanPrice3}}/ <span class="h3">per
                                        file yearly</span>
                                </div>
                            </div>
                            <div class="p-4">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">Standard</div>
                            </div>

                        </div>

                        <div fxLayout="column"
                            fxLayoutAlign="space-between"
                            fxLayout.gt-md="column"
                            fxFlex="100"
                            style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px;">

                            <div fxLayout="row"
                                fxLayoutAlign="start center"
                                fxFlex="100"
                                style="padding: 3px !important;margin: 3px !important;">
                                <mat-icon style="color: #29793C; margin-right: 3px;"
                                    matSuffix>check_circle_outline
                                </mat-icon><span>This rate is applicable for the next 50 client files; i.e, from 51st to
                                    100th client file</span>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width"
                        fxLayout="column"
                        fxLayoutAlign="space-between"
                        fxLayout.gt-md="column"
                        [ngClass.gt-sm]="'mb-0 mr-6'"
                        style="background-color: #6AB27A;  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1;">

                        <div class="pt-16 pb-8"
                            fxLayout="column wrap"
                            fxLayoutAlign="center center"
                            style="border-bottom: 1px solid #6AB27A;">

                            <div class="p-4">
                                <div class="h1 primary-text"
                                    style="font-weight: bold; color: #FFFFFF;">৳ {{nPlanPrice4}}/ <span class="h3">per
                                        file yearly</span>
                                </div>
                            </div>
                            <div class="p-4">
                                <div class="h4 primary-text"
                                    style="font-weight: bold; color: #FFFFFF;">Premium</div>
                            </div>

                        </div>

                        <div fxLayout="column"
                            fxLayoutAlign="space-between"
                            fxLayout.gt-md="column"
                            fxFlex="100"
                            style="background-color: #FFFFFF; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;padding-bottom: 30px;padding-top: 30px; ">

                            <div fxLayout="row"
                                fxLayoutAlign="start center"
                                fxFlex="100"
                                style="padding: 3px !important;margin: 3px !important;">
                                <mat-icon style="color: #29793C; margin-right: 3px;"
                                    matSuffix>check_circle_outline
                                </mat-icon><span>This rate is applicable for rest of the client files; i.e, the rest of
                                    the client files from the 101st</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div fxLayout="column"
                class="p-12 m-24"
                fxLayoutAlign="space-between center">
                <div fxLayout="row"
                    fxFlex="100"
                    fxLayoutAlign="center center">
                    <mat-checkbox style="margin-right: 5px;"
                        (change)="showOptions($event,'term')"></mat-checkbox>
                    <span class="name"
                        style="color: #444444;">I agree to all the <a [routerLink]="'/terms-and-conditions'"
                            target="_blank">Terms & Conditions</a> , <a [routerLink]="'/return-and-refund-policy'"
                            target="_blank">Refund Policy</a> & <a [routerLink]="'/privacy-policy'"
                            target="_blank">Privacy Policy</a> of digiTax.</span>
                </div>

            </div>
            <div fxLayout="column"
                class="p-12 m-24"
                fxLayoutAlign="space-between center">
                <div fxLayout="column"
                    fxFlex="100"
                    fxLayoutAlign="center center">
                    <span class="name"
                        style="color: #444444; font-weight: bold;">Enter how many more client files you want to
                        create.</span>
                </div>
                <div fxLayout="column"
                    fxFlex="100"
                    fxLayoutAlign="center center"
                    style="margin-top: 20px;">
                    <form fxLayout="column">
                        <mat-form-field appearance="outline"
                            fxFlex="50">
                            <input class="leftA"
                                type="text"
                                NumbersOnly
                                id="inputCount"
                                (keyup)="onFocusoutEvent($event)"
                                matInput>
                        </mat-form-field>
                    </form>
                    <div fxLayout="column"
                        fxLayoutAlign="center center"
                        fxFlex="100"
                        style="padding: 3px !important;margin: 3px !important;">
                        <mat-form-field appearance="outline">
                            <input type="text"
                                placeholder="Apply Coupon"
                                [formControl]="PractitionerCouponControl"
                                class="rightA"
                                autocomplete="off"
                                matInput>
                        </mat-form-field>
                        <button mat-raised-button
                            *ngIf="PractitionerTotalPrice!=0 && PractitionerNewTotalPrice==0"
                            (click)="ApplyPractitionerCouponControl(PractitionerTotalPrice)"
                            style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                            class="mat-button dupl"
                            fxFlex="0 0 auto"
                            fxLayout="row"
                            fxLayoutAlign="center center">

                            <span>Apply</span>
                        </button>

                        <button mat-raised-button
                            *ngIf="PractitionerNewTotalPrice!=0"
                            style="color: #444444 !important; margin-right: 10px;"
                            (click)="RemovePractitionerCoupon()"
                            class="mat-button mat-button-base primary-100 secondary-text">
                            Remove
                        </button>
                    </div>
                    <!-- <div fxLayout="column"
                        fxFlex="100"
                        fxLayoutAlign="start center">
                        <button longPress
                            style="width: 25px; margin: 2px; margin-left: 5px;"
                            (click)="nCountPlus()"
                            (mouseLongPress)="nCountPlus()">+
                        </button>
                        <button longPress
                            style="width: 25px; margin: 2px; margin-left: 5px;"
                            (click)="nCountMinus()"
                            (mouseLongPress)="nCountMinus()">-
                        </button>
                    </div> -->
                </div>
            </div>
            <div fxLayout="column"
                class="mt-24"
                fxLayoutAlign="space-between center">
                <div fxLayout="row"
                    fxLayoutAlign="center center"
                    style="margin-bottom: 20px;">

                    <span class="h2">
                        Total Price <span class="h1"
                            style="font-weight: bold;">
                            <span [ngClass]="PractitionerNewTotalPrice==0 ? 'null' : 'strikethrough'"><span
                                    style="font-weight: normal;">৳</span>{{PractitionerTotalPrice}}</span>
                            &nbsp;
                            <span *ngIf="PractitionerNewTotalPrice!=0"><span
                                    style="font-weight: normal;">৳</span>{{PractitionerNewTotalPrice}}</span>
                        </span>
                    </span>
                    <button style="border: none; background: transparent; cursor: pointer;"
                        (click)="ShowDetails()"
                        matTooltip="Show Details">
                        <mat-icon *ngIf="showDetail"
                            style="color: #29793C; margin-right: 3px; transform: rotate(180deg);"
                            matSuffix>
                            <span class="material-icons-outlined">arrow_drop_down_circle</span>
                        </mat-icon>
                        <mat-icon *ngIf="!showDetail"
                            style="color: #29793C; margin-right: 3px;"
                            matSuffix>
                            <span class="material-icons-outlined">arrow_drop_down_circle</span>
                        </mat-icon>
                    </button>

                </div>
            </div>
            <div fxLayout="column"
                fxLayoutAlign="space-between center">
                <div *ngIf="showDetail"
                    fxLayout="column"
                    fxFlex="100"
                    fxLayoutAlign="space-between"
                    style="background-color: #D7F0D7; border: 1px solid #C4C4C4 !important; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 2px 6px -1px #D1ECD1; padding: 10px; padding-left: 20px; padding-right: 20px;">
                    <div fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Total no. of clients:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">{{nCount}}
                        </span>
                    </div>
                    <div fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Price of first {{nCountTier1}} client files:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">৳0
                        </span>
                    </div>
                    <div fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Price of next {{nCountTier2}} client files:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">৳{{nPriceTier2}}
                        </span>
                    </div>
                    <div fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Price of next {{nCountTier3}} client files:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">৳{{nPriceTier3}}
                        </span>
                    </div>
                    <div fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Price of rest {{nCountTier4}} client files:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">৳{{nPriceTier4}}
                        </span>
                    </div>
                    <div *ngIf="hasTransaction"
                        fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Previously Total Paid:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">৳(-){{TotalTransactions}}
                        </span>
                    </div>
                    <div *ngIf="hasAppliedVouchers"
                        fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Previously Applied Discounts:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">৳(-){{TotalAppliedVouchers}}
                        </span>
                    </div>
                    <div *ngIf="PractitionerNewTotalPrice!=0"
                        fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="space-between">
                        <span class="name"
                            style="color: #444444;">Coupon Discount:&nbsp;&nbsp;
                        </span>
                        <span class="name"
                            style="color: #444444; font-weight: bold;">৳(-){{PractitionerCouponDiscount}}
                        </span>
                    </div>
                </div>

                <div fxLayout="row"
                    fxLayoutAlign="center center"
                    fxFlex="100"
                    style="margin: 15px !important;">
                    <button mat-raised-button
                        style="padding: 15px !important; padding-top: 10px !important; padding-bottom: 10px !important;"
                        class="mat-button dupl"
                        fxFlex="0 0 auto"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        (click)="OnSubmit()">
                        <span>Proceed to Payment</span>
                    </button>
                </div>

            </div>

        </div>
    </div>
</div>
