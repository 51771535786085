<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2> Privacy Policy</h2>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area ptb-100"
    style="padding-top: 0; ">
    <div class="container">
        <div class="row">

            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">

                    <div class="features-text">
                        <h4>Updated at 2021-09-06</h4>
                        <p style="color: #444444;">digiTax (“we,” “our,” or “us”) is committed to protecting your
                            privacy. This Privacy Policy
                            explains how your personal information is collected, used, and disclosed by digiTax.</p>
                        <p style="color: #444444;">This Privacy Policy applies to our website, and its associated
                            subdomains (collectively, our
                            “Service”) alongside our application, digiTax. By accessing or using our Service, you
                            signify that you have read, understood, and agree to our collection, storage, use, and
                            disclosure of your personal information as described in this Privacy Policy and our Terms of
                            Service.</p>
                    </div>
                    <div class="features-text">
                        <h4>Definitions and key terms</h4>
                        <p style="color: #444444;">To help explain things as clearly as possible in this Privacy Policy,
                            every time any of these
                            terms are referenced, are strictly defined as:</p>
                        <p style="color: #444444;"> &bull; Cookie: small amount of data generated by a website and saved by
                            your web browser. It is
                            used to identify your browser, provide analytics, remember information about you such as
                            your language preference or login information.</p>
                        <p style="color: #444444;"> &bull; Company: when this policy mentions “Company,” “we,” “us,” or “our,”
                            it refers to Desh
                            Universal (Pvt.) Limited, (Desh Universal (Pvt.) Limited As-Salam Tower (Level 5), 82-83 Zoo
                            Road, Block-A, Section-2, Mirpur, Dhaka-1216) that is responsible for your information under
                            this Privacy Policy.</p>
                        <p style="color: #444444;"> &bull; Country: where digiTax or the owners/founders of digiTax are based,
                            in this case, is
                            Bangladesh.</p>
                        <p style="color: #444444;"> &bull; Customer: refers to the company, organization or person that signs
                            up to use the digiTax
                            Service to manage the relationships with your consumers or service users.</p>
                        <p style="color: #444444;"> &bull; Device: any internet-connected device such as a phone, tablet,
                            computer or any other device
                            that can be used to visit digiTax and use the services.</p>
                        <p style="color: #444444;"> &bull; IP address: Every device connected to the Internet is assigned a
                            number known as an
                            Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An
                            IP address can often be used to identify the location from which a device is connecting to
                            the Internet.</p>
                        <p style="color: #444444;"> &bull; Personnel: refers to those individuals who are employed by digiTax
                            or are under contract to
                            perform a service on behalf of one of the parties.</p>
                        <p style="color: #444444;"> &bull; Personal Data: Any information that directly, indirectly, or in
                            connection with other
                            information — including a personal identification number — allows for the identification or
                            identifiability of a natural person.</p>
                        <p style="color: #444444;"> &bull; Service: refers to the service provided by digiTax as described in
                            the relative terms (if
                            available) and on this platform.</p>
                        <p style="color: #444444;"> &bull; Third-party service: refers to advertisers, contest sponsors,
                            promotional and marketing
                            partners, and others who provide our content or whose products or services we think may
                            interest you.</p>
                        <p style="color: #444444;"> &bull; Website: “digiTax" site, which can be accessed via this URL:
                            https://digitax.com.bd</p>
                        <p style="color: #444444;"> &bull; You: a person or entity that is registered with digiTax to use the
                            Services.</p>
                    </div>
                    <div class="features-text">
                        <h4>What Information Do We Collect?</h4>
                        <p style="color: #444444;">We collect information from you when you visit our website/app,
                            register on our site, place
                            an order, subscribe to our newsletter, respond to a survey or fill out a form.</p>
                        <p style="color: #444444;"> &bull; Name / Username</p>
                        <p style="color: #444444;"> &bull; Phone Numbers</p>
                        <p style="color: #444444;"> &bull; Email Addresses</p>
                        <p style="color: #444444;"> &bull; Mailing Addresses</p>
                        <p style="color: #444444;"> &bull; Debit/credit card numbers</p>
                        <p style="color: #444444;"> &bull; Age</p>
                        <p style="color: #444444;">We also collect information from mobile devices for a better user
                            experience, although these
                            features are completely optional:</p>
                        <p style="color: #444444;"> &bull; Photo Gallery (Pictures): Granting photo gallery access allows the
                            user to upload any
                            picture from their photo gallery, you can safely deny photo gallery access for this
                            website/app.</p>
                    </div>
                    <div class="features-text">
                        <h4>How Do We Use The Information We Collect?</h4>
                        <p style="color: #444444;">Any of the information we collect from you may be used in one of the
                            following ways:</p>
                        <p style="color: #444444;"> &bull; To personalize your experience (your information helps us to better
                            respond to your
                            individual needs)</p>
                        <p style="color: #444444;"> &bull; To improve our website/app (we continually strive to improve our
                            website/app offerings
                            based on the information and feedback we receive from you)</p>
                        <p style="color: #444444;"> &bull; To improve customer service (your information helps us to more
                            effectively respond to your
                            customer service requests and support needs)</p>
                        <p style="color: #444444;"> &bull; To process transactions</p>
                        <p style="color: #444444;"> &bull; To administer a contest, promotion, survey or other site feature
                        </p>
                        <p style="color: #444444;"> &bull; To send periodic emails</p>
                    </div>
                    <div class="features-text">
                        <h4>When does digiTax use end-user information from third parties?</h4>
                        <p style="color: #444444;">digiTax will collect End User Data necessary to provide the digiTax
                            services to our
                            customers.
                            End-users may voluntarily provide us with the information they have made available on social
                            media websites. If you provide us with any such information, we may collect publicly
                            available information from the social media websites you have indicated. You can control how
                            much of your information social media websites make public by visiting these websites and
                            changing your privacy settings.
                        </p>
                    </div>
                    <div class="features-text">
                        <h4>When does digiTax use customer information from third parties?</h4>
                        <p style="color: #444444;">We receive some information from the third parties when you contact
                            us. For example, when you
                            submit your email address to us to show interest in becoming a digiTax customer, we receive
                            information from a third party that provides automated fraud detection services to digiTax.
                            We also occasionally collect information that is made publicly available on social media
                            websites. You can control how much of your information social media websites make public by
                            visiting these websites and changing your privacy settings.</p>
                    </div>
                    <div class="features-text">
                        <h4>Where and when is information collected from customers and end-users?</h4>
                        <p style="color: #444444;">digiTax will collect personal information that you submit to us.</p>
                    </div>
                    <div class="features-text">
                        <h4>How Do We Use Your Email Address?</h4>
                        <p style="color: #444444;">By submitting your email address on this website/app, you agree to
                            receive emails from us.
                            You can cancel your participation in any of these email lists at any time by clicking on the
                            opt-out link or other unsubscribe option that is included in the respective email. We only
                            send emails to people who have authorized us to contact them, either directly, or through a
                            third party. We do not send unsolicited commercial emails, because we hate spam as much as
                            you do. By submitting your email address, you also agree to allow us to use your email
                            address for customer audience targeting on sites like Facebook, where we display custom
                            advertising to specific people who have opted-in to receive communications from us. Email
                            addresses submitted only through the order processing page will be used for the sole purpose
                            of sending you information and updates pertaining to your order. If, however, you have
                            provided the same email to us through another method, we may use it for any of the purposes
                            stated in this Policy. Note: If at any time you would like to unsubscribe from receiving
                            future emails, we include detailed unsubscribe instructions at the bottom of each email.</p>
                    </div>
                    <div class="features-text">
                        <h4>How Long Do We Keep Your Information?</h4>
                        <p style="color: #444444;">We keep your information only so long as we need it to provide
                            digiTax to you and fulfill the
                            purposes described in this policy. This is also the case for anyone that we share your
                            information and who carries out services on our behalf. When we no longer need to use your
                            information and there is no need for us to keep it to comply with our legal or regulatory
                            obligations, we’ll either remove it from our systems or depersonalize it so that we can't
                            identify you.</p>
                    </div>
                    <div class="features-text">
                        <h4>How Do We Protect Your Information?</h4>
                        <p style="color: #444444;">We implement a variety of security measures to maintain the safety of
                            your personal
                            information when you place an order or enter, submit, or access your personal information.
                            We offer the use of a secure server. All supplied sensitive/credit information is
                            transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment
                            gateway providers database only to be accessible by those authorized with special access
                            rights to such systems, and are required to keep the information confidential. After a
                            transaction, your private information (credit cards, social security numbers, financials,
                            etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of
                            any information you transmit to digiTax or guarantee that your information on the Service
                            may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical,
                            technical, or managerial safeguards.</p>
                    </div>
                    <div class="features-text">
                        <h4>Could my information be transferred to other countries?</h4>
                        <p style="color: #444444;">digiTax is incorporated in Bangladesh. Information collected via our
                            website, through direct
                            interactions with you, or from the use of our help services may be transferred from time to
                            time to our offices or personnel, or to third parties, located throughout the world, and
                            maybe viewed and hosted anywhere in the world, including countries that may not have laws of
                            general applicability regulating the use and transfer of such data. To the fullest extent
                            allowed by applicable law, by using any of the above, you voluntarily consent to the
                            trans-border transfer and hosting of such information.</p>
                    </div>
                    <div class="features-text">
                        <h4>Is the information collected through the digiTax Service secure?</h4>
                        <p style="color: #444444;">We take precautions to protect the security of your information. We
                            have physical,
                            electronic, and managerial procedures to help safeguard, prevent unauthorized access,
                            maintain data security, and correctly use your information. However, neither people nor
                            security systems are foolproof, including encryption systems. In addition, people can commit
                            intentional crimes, make mistakes or fail to follow policies. Therefore, while we use
                            reasonable efforts to protect your personal information, we cannot guarantee its absolute
                            security. If applicable law imposes any non-disclaimable duty to protect your personal
                            information, you agree that intentional misconduct will be the standard used to measure our
                            compliance with that duty.</p>
                    </div>
                    <div class="features-text">
                        <h4>Can I update or correct my information?</h4>
                        <p style="color: #444444;">The rights you have to request updates or corrections to the
                            information digiTax collects
                            depend on your relationship with digiTax. Personnel may update or correct their information
                            as detailed in our internal company employment policies.</p>
                        <p style="color: #444444;">Customers have the right to request the restriction of certain uses
                            and disclosures of
                            personally identifiable information as follows. You can contact us in order to (1) update or
                            correct your personally identifiable information, (2) change your preferences with respect
                            to communications and other information you receive from us, or (3) delete the personally
                            identifiable information maintained about you on our systems (subject to the following
                            paragraph), by canceling your account. Such updates, corrections, changes and deletions will
                            have no effect on other information that we maintain or information that we have provided to
                            third parties in accordance with this Privacy Policy prior to such update, correction,
                            change or deletion. To protect your privacy and security, we may take reasonable steps (such
                            as requesting a unique password) to verify your identity before granting you profile access
                            or making corrections. You are responsible for maintaining the secrecy of your unique
                            password and account information at all times.</p>
                        <p style="color: #444444;">You should be aware that it is not technologically possible to remove
                            each and every record
                            of the information you have provided to us from our system. The need to back up our systems
                            to protect information from inadvertent loss means that a copy of your information may exist
                            in a non-erasable form that will be difficult or impossible for us to locate. Promptly after
                            receiving your request, all personal information stored in databases we actively use, and
                            other readily searchable media will be updated, corrected, changed or deleted, as
                            appropriate, as soon as and to the extent reasonably and technically practicable.</p>
                        <p style="color: #444444;">If you are an end-user and wish to update, delete, or receive any
                            information we have about
                            you, you may do so by contacting the organization of which you are a customer.</p>
                    </div>
                    <div class="features-text">
                        <h4>Personnel</h4>
                        <p style="color: #444444;">If you are a digiTax worker or applicant, we collect the information
                            you voluntarily provide
                            to us. We use the information collected for Human Resources purposes in order to administer
                            benefits to workers and screen applicants.</p>
                        <p style="color: #444444;">You may contact us in order to (1) update or correct your
                            information, (2) change your
                            preferences with respect to communications and other information you receive from us, or (3)
                            receive a record of the information we have relating to you. Such updates, corrections,
                            changes and deletions will have no effect on other information that we maintain or
                            information that we have provided to third parties in accordance with this Privacy Policy
                            prior to such update, correction, change or deletion.</p>
                    </div>
                    <div class="features-text">
                        <h4>Sale of Business</h4>
                        <p style="color: #444444;">We reserve the right to transfer information to a third party in the
                            event of a sale, merger
                            or other transfer of all or substantially all of the assets of digiTax or any of its
                            Corporate Affiliates (as defined herein), or that portion of digiTax or any of its Corporate
                            Affiliates to which the Service relates, or in the event that we discontinue our business or
                            file a petition or have filed against us a petition in bankruptcy, reorganization or similar
                            proceeding, provided that the third party agrees to adhere to the terms of this Privacy
                            Policy.</p>
                    </div>
                    <div class="features-text">
                        <h4>Affiliates</h4>
                        <p style="color: #444444;">We may disclose information (including personal information) about
                            you to our Corporate
                            Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or
                            entity which directly or indirectly controls, is controlled by or is under common control
                            with digiTax, whether by ownership or otherwise. Any information relating to you that we
                            provide to our Corporate Affiliates will be treated by those Corporate Affiliates in
                            accordance with the terms of this Privacy Policy.</p>
                    </div>
                    <div class="features-text">
                        <h4>Governing Law</h4>
                        <p style="color: #444444;">This Privacy Policy is governed by the laws of Bangladesh without
                            regard to its conflict of
                            laws provision. You consent to the exclusive jurisdiction of the courts in connection with
                            any action or dispute arising between the parties under or in connection with this Privacy
                            Policy except for those individuals who may have rights to make claims under Privacy Shield
                            or the Swiss-US framework.</p>
                        <p style="color: #444444;">The laws of Bangladesh, excluding its conflicts of law rules, shall
                            govern this Agreement and
                            your use of the website/app. Your use of the website/app may also be subject to other local,
                            state, national, or international laws.</p>
                        <p style="color: #444444;">By using digiTax or contacting us directly, you signify your
                            acceptance of this Privacy
                            Policy. If you do not agree to this Privacy Policy, you should not engage with our website,
                            or use our services. Continued use of the website, direct engagement with us, or following
                            the posting of changes to this Privacy Policy that do not significantly affect the use or
                            disclosure of your personal information will mean that you accept those changes.</p>
                    </div>
                    <div class="features-text">
                        <h4>Your Consent</h4>
                        <p style="color: #444444;">We've updated our Privacy Policy to provide you with complete
                            transparency into what is being
                            set when you visit our site and how it's being used. By using our website/app, registering
                            an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its
                            terms.</p>
                    </div>
                    <div class="features-text">
                        <h4>Links to Other Websites</h4>
                        <p style="color: #444444;">This Privacy Policy applies only to the Services. The Services may
                            contain links to other
                            websites not operated or controlled by digiTax. We are not responsible for the content,
                            accuracy or opinions expressed in such websites, and such websites are not investigated,
                            monitored or checked for accuracy or completeness by us. Please remember that when you use a
                            link to go from the Services to another website, our Privacy Policy is no longer in effect.
                            Your browsing and interaction on any other website, including those that have a link on our
                            platform, is subject to that website’s own rules and policies. Such third parties may use
                            their own cookies or other methods to collect information about you.</p>
                    </div>
                    <div class="features-text">
                        <h4>Cookies</h4>
                        <p style="color: #444444;"></p>
                    </div>
                    <div class="features-text">
                        <h4>Could</h4>
                        <p style="color: #444444;">digiTax uses "Cookies" to identify the areas of our website that you
                            have visited. A Cookie
                            is a small piece of data stored on your computer or mobile device by your web browser. We
                            use Cookies to enhance the performance and functionality of our website/app but are
                            non-essential to their use. However, without these cookies, certain functionality like
                            videos may become unavailable or you would be required to enter your login details every
                            time you visit the website/app as we would not be able to remember that you had logged in
                            previously. Most web browsers can be set to disable the use of Cookies. However, if you
                            disable Cookies, you may not be able to access functionality on our website correctly or at
                            all. We never place Personally Identifiable Information in Cookies.</p>
                    </div>
                    <div class="features-text">
                        <h4>Blocking and disabling cookies and similar technologies</h4>
                        <p style="color: #444444;">Wherever you're located you may also set your browser to block
                            cookies and similar
                            technologies, but this action may block our essential cookies and prevent our website from
                            functioning properly, and you may not be able to fully utilize all of its features and
                            services. You should also be aware that you may also lose some saved information (e.g. saved
                            login details, site preferences) if you block cookies on your browser. Different browsers
                            make different controls available to you. Disabling a cookie or category of cookie does not
                            delete the cookie from your browser, you will need to do this yourself from within your
                            browser, you should visit your browser's help menu for more information.</p>
                    </div>
                    <div class="features-text">
                        <h4>Payment Details</h4>
                        <p style="color: #444444;">In respect to any credit card or other payment processing details you
                            have provided us, we
                            commit that this confidential information will be stored in the most secure manner possible.
                        </p>
                    </div>
                    <div class="features-text">
                        <h4>Kids' Privacy</h4>
                        <p style="color: #444444;">We do not address anyone under the age of 18. We do not knowingly
                            collect personally
                            identifiable information from anyone under the age of 18. If You are a parent or guardian
                            and You are aware that Your child has provided Us with Personal Data, please Contact Us. If
                            We become aware that We have collected Personal Data from anyone under the age of 18 without
                            verification of parental consent, We take steps to remove that information from Our servers.
                        </p>
                    </div>
                    <div class="features-text">
                        <h4>Changes To Our Privacy Policy</h4>
                        <p style="color: #444444;">We may change our Services and policies, and we may need to make
                            changes to this Privacy
                            Policy so that they accurately reflect our Service and policies. Unless otherwise required
                            by law, we will notify you (for example, through our Service) before we make changes to this
                            Privacy Policy and give you an opportunity to review them before they go into effect. Then,
                            if you continue to use the Service, you will be bound by the updated Privacy Policy. If you
                            do not want to agree to this or any updated Privacy Policy, you can delete your account.</p>
                    </div>
                    <div class="features-text">
                        <h4>Third-Party Services</h4>
                        <p style="color: #444444;">We may display, include or make available third-party content
                            (including data, information,
                            applications and other products services) or provide links to third-party websites or
                            services ("Third- Party Services").
                            You acknowledge and agree that digiTax shall not be responsible for any Third-Party
                            Services, including their accuracy, completeness, timeliness, validity, copyright
                            compliance, legality, decency, quality or any other aspect thereof. digiTax does not assume
                            and shall not have any liability or responsibility to you or any other person or entity for
                            any Third-Party Services.
                            Third-Party Services and links thereto are provided solely as a convenience to you and you
                            access and use them entirely at your own risk and subject to such third parties' terms and
                            conditions.
                        </p>
                    </div>
                    <div class="features-text">
                        <h4>Facebook Pixel</h4>
                        <p style="color: #444444;">Facebook pixel is an analytics tool that allows you to measure the
                            effectiveness of your
                            advertising by understanding the actions people take on your website. You can use the pixel
                            to: Make sure your ads are shown to the right people. Facebook pixel may collect information
                            from your device when you use the service. Facebook pixel collects information that is held
                            in accordance with its Privacy Policy.</p>
                    </div>
                    <div class="features-text">
                        <h4>Tracking Technologies</h4>
                        <p style="color: #444444;"> &bull; Google Maps API</p>
                        <p style="color: #444444;"> Google Maps API is a robust tool that can be used to create a custom
                            map, a searchable map,
                            check-in functions, display live data syncing with location, plan routes, or create a mashup
                            just to name a few.</p>
                        <p style="color: #444444;"> Google Maps API may collect information from You and from Your
                            Device for security purposes.
                        </p>
                        <p style="color: #444444;"> Google Maps API collects information that is held in accordance with
                            its Privacy Policy</p>
                        <p style="color: #444444;"> &bull; Cookies</p>
                        <p style="color: #444444;"> We use Cookies to enhance the performance and functionality of our
                            website/app but are
                            non-essential to their use. However, without these cookies, certain functionality like
                            videos may become unavailable or you would be required to enter your login details every
                            time you visit the website/app as we would not be able to remember that you had logged in
                            previously.</p>
                        <p style="color: #444444;"> &bull; Local Storage</p>
                        <p style="color: #444444;"> Local Storage sometimes known as DOM storage, provides web apps with
                            methods and protocols
                            for storing client-side data. Web storage supports persistent data storage, similar to
                            cookies but with a greatly enhanced capacity and no information stored in the HTTP request
                            header.</p>
                        <p style="color: #444444;"> &bull; Sessions</p>
                        <p style="color: #444444;"> digiTax uses "Sessions" to identify the areas of our website that
                            you have visited. A
                            Session is a small piece of data stored on your computer or mobile device by your web
                            browser.</p>
                    </div>
                    <div class="features-text">
                        <h4>Contact Us</h4>
                        <p style="color: #444444;">Don't hesitate to contact us if you have any questions.</p>
                        <p style="color: #444444;"> &bull; Via Email: support@digitax.com.bd</p>
                        <p style="color: #444444;"> &bull; Via Phone Number: 09643 224488</p>
                        <p style="color: #444444;"> &bull; Via this Link: https://digitax.com.bd/contact</p>
                        <p style="color: #444444;"> &bull; Via this Address: Desh Universal (Pvt.) Limited As-Salam Tower
                            (Level 5), 82-83 Zoo Road,
                            Block-A, Section-2, Mirpur, Dhaka-1216</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->
