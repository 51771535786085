import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class CapitalGainsService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    if (id != null)
    {
      return this.service.get(urlConfig.INCOME_Capital_Gains_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
      return this.service.get(urlConfig.INCOME_Capital_Gains_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }

  }

  submitCapitalGains(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Capital_Gains_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Capital_Gains_Submit_Url + `/${id}`, model);
    }
  }


  getJson(): any
  {
    const jsonData = [
      {
        "control": "DropdownControl",
        "key": "GainType",
        "label": "Type",
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the type of the capital gains.",
        "options": [
          {
            "key": "1",
            "value": "Sale of Land"
          },
          {
            "key": "2",
            "value": "Sale of House"
          },
          {
            "key": "3",
            "value": "Sale of Flat"
          },
          {
            "key": "4",
            "value": "Sale of Commercial Space"
          },
          {
            "key": "5",
            "value": "Signing Money Received"
          },
          {
            "key": "6",
            "value": "Sale of Share"
          },
          {
            "key": "7",
            "value": "Others (Business Capital Assets)"
          }
        ],
        "order": 1
      },
      {
        "control": "TextboxControl",
        "key": "Description",
        "label": "Detail",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "Here taxpayer will add details of his/her income from capital gains.",
        "order": 2
      },
      {
        "control": "TextboxControl",
        "key": "AmountReceived",
        "label": "Amount",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the annual income from the capital gains.",
        "order": 3
      },
      {
        "control": "DropdownControl",
        "relationClass": "shareholder",
        "key": "GainWeight1",
        "label": "Percentage of Share",
        "errorType": "",
        "message": "Required",
        "tooltip": "If the taxpayer is a shareholder of more than 10%, the full amount will be taxable. If not then full amount will be exempted.",
        "required": false,
        "order": 4,
        "options": [
          {
            "key": "1",
            "value": "Less than 10%"
          },
          {
            "key": "2",
            "value": "More than 10%"
          }
        ]
      },
      {
        "control": "DropdownControl",
        "relationClass": "shareholder",
        "key": "GainWeight2",
        "label": "Ownership Period",
        "errorType": "",
        "message": "Required",
        "tooltip": "Refers to the ownership period.",
        "required": false,
        "order": 5,
        "options": [
          {
            "key": "1",
            "value": "Within 5 years"
          },
          {
            "key": "2",
            "value": "More than 5 years"
          }
        ]
      },
      {
        "control": "TextboxControl",
        "key": "TaxDeductedAtSource_TDS",
        "label": "Tax Deducted at Source",
        "required": false,
        "readonly": false,
        "errorType": "",
        "message": "Required",
        "relationClass": "shareholder",
        "tooltip": "Refers to the tax deducted at source.",
        "order": 6
      },
      {
        "control": "TextboxControl",
        "key": "TaxableIncome",
        "relationClass": "shareholder",
        "label": "Taxable Income",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the net taxable income that the taxpayer earns from the capital gains.",
        "order": 7
      }
    ];

    const jsonData2 = [
      {
        "control": "DropdownControl",
        "key": "GainType",
        "label": "ধরণ",
        "errorType": "",
        "message": "আবশ্যক",
        "required": true,
        "tooltip": "এটি মূলধনের লাভের ধরণকে বোঝায়।",
        "options": [
          {
            "key": "1",
            "value": "জমি বিক্রয়"
          },
          {
            "key": "2",
            "value": "বাড়ী বিক্রয়"
          },
          {
            "key": "3",
            "value": "ফ্ল্যাট বিক্রয়"
          },
          {
            "key": "4",
            "value": "বাণিজ্যিক স্থান বিক্রয়"
          },
          {
            "key": "5",
            "value": "স্বাক্ষর টাকা প্রাপ্তি"
          },
          {
            "key": "6",
            "value": "শেয়ার বিক্রয়"
          },
          {
            "key": "7",
            "value": "অন্যান্য (ব্যবসায় মূলধন সম্পদ)"
          }
        ],
        "order": 1
      },
      {
        "control": "TextboxControl",
        "key": "Description",
        "label": "বিস্তারিত",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এখানে করদাতা মূলধন লাভ থেকে তার আয়ের বিবরণ যুক্ত করবে।",
        "order": 2
      },
      {
        "control": "TextboxControl",
        "key": "AmountReceived",
        "label": "পরিমাণ",
        "required": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি মূলধন লাভ থেকে বার্ষিক আয় বোঝায়।",
        "order": 3
      },
      {
        "control": "DropdownControl",
        "relationClass": "shareholder",
        "key": "GainWeight1",
        "label": "শেয়ারের অংশ",
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "যদি করদাতা ১০% এর বেশি অংশীদার হয় তবে পুরো পরিমাণটি করযোগ্য হবে। তা না হলে পুরো পরিমাণ ছাড় দেওয়া হবে।",
        "required": false,
        "order": 4,
        "options": [
          {
            "key": "1",
            "value": "১০% এর কম"
          },
          {
            "key": "2",
            "value": "১০% এর বেশি"
          }
        ]
      },
      {
        "control": "DropdownControl",
        "relationClass": "shareholder",
        "key": "GainWeight2",
        "label": "মালিকানার সময়কাল",
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "মালিকানার সময়কাল বোঝায়।",
        "required": false,
        "order": 5,
        "options": [
          {
            "key": "1",
            "value": "৫ বছরের মধ্যে"
          },
          {
            "key": "2",
            "value": "৫ বছরেরও বেশি সময়"
          }
        ]
      },
      {
        "control": "TextboxControl",
        "key": "TaxDeductedAtSource_TDS",
        "label": "উৎসে কেটে নেয়া কর",
        "required": false,
        "readonly": false,
        "errorType": "",
        "message": "আবশ্যক",
        "relationClass": "shareholder",
        "tooltip": "উৎসে কেটে নেয়া কর বোঝায়।",
        "order": 6
      },
      {
        "control": "TextboxControl",
        "key": "TaxableIncome",
        "label": "করযোগ্য আয়",
        "required": false,
        "tabindex": "-1",
        "readonly": true,
        "errorType": "",
        "relationClass": "shareholder",
        "message": "আবশ্যক",
        "tooltip": "এটি করদাতা মূলধন লাভ থেকে আয় করে এমন নীট করযোগ্য আয়কে বোঝায়।",
        "order": 7
      }
    ];
    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }


}
