<div [formGroup]="form">

  <div [ngSwitch]="control.controlType"
    fxLayout="column wrap"
    fxLayoutAlign="start"
    fxFlex="100">

    <div *ngSwitchCase="'labelGroup'"
      fxLayoutAlign="space-between start"
      fxLayout="wrap"
      fxFlex="100">
      <div fxLayout="wrap"
        fxLayoutAlign="space-between center"
        *ngFor="let child of control.children">

        <span class="name"
          style="font-size: 15px; font-weight: bold;"
          [attr.for]="child.key">{{child.label}}</span>

      </div>
    </div>
    <div *ngSwitchCase="'textboxGroup'"
      fxLayoutAlign="space-between center"
      fxLayout="row wrap"
      fxFlex="100">

      <div fxLayoutAlign="space-between center"
        fxLayout="row wrap"
        fxFlex="100"
        style="margin-top: 10px;margin-bottom: 10px;">

        <span class="name"
          style="font-size: 15px;"
          fxFlex="30"
          [attr.for]="control.key">{{control.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>

        <div *ngFor="let Source of control.children"
          fxLayoutAlign="start center"
          fxLayout="row wrap">
          <div fxLayoutAlign="start space-between"
            fxLayout="column wrap">

            <mat-form-field appearance="outline"
              *ngIf="Source.key.includes('Amount')"
              fxLayout="row wrap"
              [attr.for]="Source.key"
              fxLayoutAlign="space-between center"
              fxLayout="row wrap">
              <input *ngSwitchCase="'textboxGroup'"
                class="rightA"
                (click)="$event.target.select()"
                autocomplete="off"
                NumbersOnly
                type="text"
                decimals="100"
                negative="1"
                [formControlName]="Source.key"
                [id]="Source.key"
                [type]="Source.type"
                matInput>

            </mat-form-field>

            <mat-form-field appearance="outline"
              *ngIf="Source.key.includes('Description')"
              fxLayout="row wrap"
              [attr.for]="Source.key"
              fxLayoutAlign="space-between center"
              fxLayout="row wrap">
              <input *ngSwitchCase="'textboxGroup'"
                class="leftA"
                autocomplete="off"
                [formControlName]="Source.key"
                [id]="Source.key"
                [type]="Source.type"
                matInput>

            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
