<h2 mat-dialog-title>Collection Entry</h2>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16">

        <div fxLayout="column"
            fxLayoutAlign="start"
            fxFlex="33"
            style="margin: 10px;">
            <span class="name"
                style="font-size: 15px;"
                fxFlex="100">Search By Id or Name:
            </span>

            <mat-form-field class="example-full-width"
                appearance="outline">
                <input type="text"
                    tabindex="-1"
                    matInput
                    [formControl]="BcTabSearchControl"
                    [matAutocomplete]="BcTabAuto">
                <mat-autocomplete #BcTabAuto="matAutocomplete">
                    <mat-option *ngFor="let option of BcTabfilteredOptions"
                        (onSelectionChange)="BcTabSearchClient(option)"
                        [value]="option.AssesseeName">
                        {{option.AssesseeName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxLafxLayout="row wrap"
            fxLayoutAlign="space-between center">
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                Name: <b>{{BcTabClientName == "" ? '--' : BcTabClientName }}</b>
            </h3>
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                eTIN: <b>{{BcTabClientEtin == "" ? '--' : BcTabClientEtin }}</b>
            </h3>
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                Mobile: <b>{{BcTabClientMobile == "" ? '--' : BcTabClientMobile }}</b>
            </h3>
        </div>

        <div fxLafxLayout="row wrap"
            fxLayoutAlign="start center">
            <h3 style="margin: 10px;"
                fxFlex="33">
                Reference: <b>{{BcTabClientRef == null || BcTabClientRef == '' ? '--' : BcTabClientRef}}</b>
            </h3>

            <div fxLayout="row"
                fxLayoutAlign="start center"
                style="margin: 10px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Transaction Date:
                </span>

                <mat-form-field appearance="outline">
                    <input matInput
                        [formControl]="BcTabDateControl"
                        [matDatepicker]="BcTabTranDate"
                        readonly
                        [max]="maxDate"
                        (click)="BcTabTranDate.open()">
                    <mat-datepicker-toggle matSuffix
                        [for]="BcTabTranDate"></mat-datepicker-toggle>
                    <mat-datepicker #BcTabTranDate
                        disabled="false"></mat-datepicker>
                </mat-form-field>

            </div>
        </div>

    </div>
    <div *ngIf="PageResponseData!=null"
        fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16">
        <div *ngIf="showModal">
            <table *ngIf="entryDisabled == true"
                class="simple invoice-table"
                [formGroup]="BcTabTempForm">
                <thead>
                    <tr>
                        <th class="text-left thNewline"
                            style="color: #444444;">
                            SL
                        </th>

                        <th class="text-left thNewline"
                            style="color: #444444; padding-left: 20px;">
                            Account Name
                        </th>
                        <th class="text-right thNewline"
                            style="color: #444444;  padding-right: 20px;">
                            Dues
                        </th>
                        <th class="text-right thNewline"
                            style="color: #444444;">
                            Collection
                        </th>
                        <th class="text-left thNewline"
                            style="color: #444444;">
                            Notes
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="items"
                    *ngFor="let item of BcTabTempFormitems().controls; let i=index">
                    <tr [formGroupName]="i">
                        <td class="text-center">
                            {{i+1}}
                        </td>
                        <td>

                            <input type="text"
                                formControlName="AccounHeadtName"
                                readonly
                                class="leftA"
                                style="border:0;">
                        </td>
                        <td>
                            <input type="text"
                                formControlName="Dues"
                                readonly
                                class="rightA"
                                style="border:0;">
                        </td>
                        <td>
                            <mat-form-field appearance="outline">
                                <input NumbersOnly
                                    readonly
                                    type="text"
                                    decimals="100"
                                    negative="1"
                                    formControlName="Collection"
                                    class="rightA"
                                    autocomplete="off"
                                    matInput>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline">
                                <input type="text"
                                    readonly
                                    decimals="100"
                                    negative="1"
                                    formControlName="Notes"
                                    class="leftA"
                                    autocomplete="off"
                                    matInput>
                            </mat-form-field>
                        </td>

                    </tr>
                </tbody>

            </table>
        </div>
        <div *ngIf="showModal">
            <table *ngIf="entryDisabled == false"
                class="simple invoice-table"
                [formGroup]="BcTabMainForm">
                <thead>
                    <tr>
                        <th class="text-left thNewline"
                            style="color: #444444;">
                            SL
                        </th>

                        <th class="text-left thNewline"
                            style="color: #444444; padding-left: 20px;">
                            Account Name
                        </th>
                        <th class="text-right thNewline"
                            style="color: #444444;  padding-right: 20px;">
                            Dues
                        </th>
                        <th class="text-right thNewline"
                            style="color: #444444;">
                            Collection
                        </th>
                        <th class="text-left thNewline"
                            style="color: #444444;">
                            Notes
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="items"
                    *ngFor="let item of BcTabMainFormitems().controls; let i=index">
                    <tr [formGroupName]="i">
                        <td class="text-center">
                            {{i+1}}
                        </td>
                        <td>

                            <input type="text"
                                formControlName="AccounHeadtName"
                                readonly
                                class="leftA"
                                style="border:0;">
                        </td>
                        <td>
                            <input type="text"
                                formControlName="Dues"
                                readonly
                                class="rightA"
                                style="border:0;">
                        </td>
                        <td>
                            <mat-form-field appearance="outline">
                                <input NumbersOnly
                                    type="text"
                                    decimals="100"
                                    negative="1"
                                    formControlName="Collection"
                                    (click)="$event.target.select()"
                                    (keyup)="BcTabOnFocusoutEvent($event,'Collection',i)"
                                    class="rightA"
                                    autocomplete="off"
                                    matInput>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline">
                                <input type="text"
                                    decimals="100"
                                    negative="1"
                                    formControlName="Notes"
                                    (click)="$event.target.select()"
                                    class="leftA"
                                    autocomplete="off"
                                    matInput>
                            </mat-form-field>
                        </td>

                    </tr>
                </tbody>

            </table>
        </div>
        <div *ngIf="BcTabgetData!=null"
            fxLafxLayout="row wrap"
            fxLayoutAlign="space-between center"
            style="margin-top: 10px; margin-bottom: 10px;">

            <div fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="32">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Remarks:
                </span>

                <mat-form-field appearance="outline">
                    <input class="leftA"
                        [formControl]="BcTabRemarksControl"
                        value=""
                        matInput>

                </mat-form-field>

            </div>
            <div fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="32">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Payment Type :
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100"
                    *ngIf="dropDownData != null">
                    <mat-select [formControl]="BcTabPayTypeControl">
                        <mat-option *ngFor="let option of dropDownData.PractitionerPaymentTypes"
                            [value]="option.key">
                            {{option.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div fxFlex="32">
                <span style="margin: 10px;">
                    <b> Total Collection: {{BcTabTotalCollection}}</b>
                </span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
    <button class="mat-button mat-button-base primary-100 secondary-text"
        cdkFocusInitial
        mat-dialog-close>Cancel</button>
    <button *ngIf="BcTabgetData!=null"
        class="mat-button mat-button-base dupl"
        (click)="SaveCollection()">{{ 'COMMON.BUTTON_SAVE' | translate }}</button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
