import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';



import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';
import { Observable } from 'rxjs';
import { PersonalizeReturnService } from './Personalize-Return.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as lodash from 'lodash';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { bangla, english } from 'app/util/locale';
import { SalaryEmitterService } from 'app/services/salaryEmiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import Utils from 'app/util/utils';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';
import { QuickPanelService } from 'app/layout/components/quick-panel/quick-panel.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthenticationService } from 'app/auth';




@Component({
    selector: 'PersonalizeReturn',
    templateUrl: './Personalize-Return.component.html',
    styleUrls: ['./Personalize-Return.component.scss']
})
export class PersonalizeReturnComponent
{
    @Input() controls: BaseControl<string>[] = [];
    form: FormGroup;
    payLoad = '';
    salaryType = '';
    id: any;
    selectedIncome: any;
    selectedAssets: any;
    lang = '';
    showCancel: boolean;
    incomeReDirect = '';
    assetReDirect = '';
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @Input() rSource: boolean;
    @Input() rAssets: boolean;
    @Input() rMotor: boolean;
    @Input() rProperty: boolean;
    @Input() gAssets: boolean;

    formcontrols$: Observable<BaseControl<string>[]>;

    selectedFirstIncome: any;


    /**
     * Constructor
     *
     * @param {MatSnackBar} _matSnackBar
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: PersonalizeReturnService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _authService: AuthenticationService,

        private eventEmitterService: EventEmitterService,
        private _quickPanelService: QuickPanelService,

        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private salaryEmitterService: SalaryEmitterService,
        private back: BackButtonService,
        private sidePanelDeleteService: SidePanelDeleteService,
        private _fuseNavigationService: FuseNavigationService,

        private _route: ActivatedRoute

    )
    {
        this.meta.addTags([
            { name: 'description', content: 'digiTax Personalise Return' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax Personalise Return' }
        ]);
        this.setTitle('digiTax | Personalise Return');
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.id = this._route.snapshot.paramMap.get('id');
        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));

        if (profileData.incompleteprofile.includes('3'))
        {
            this.showCancel = false;
        }
        else { this.showCancel = true; }
        this._formControlService.getControlJson(this.id).subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            this.controls = data;

            this.form = this._formControlService.toFormGroup(this.controls);
            this.selectedIncome = this.form.controls.OtherIncomeSources.value;
            this.selectedAssets = this.form.controls.AcquiredAssets.value;

        });


        // this._formControlService.getControls().subscribe((data: any) =>
        // {
        //     this.controls = data;
        //     this.form = this._formControlService.toFormGroup(this.controls);
        // });
        this.form = this._formControlService.toFormGroup(this.controls);
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {



    }

    ConfirmLeave(url): void
    {
        if (this.form.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this._router.navigate([url]);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            this._router.navigate([url]);
        }
    }

    // OLD METHOD

    // mySubmit(): void
    // {

    //     if (!this.form.controls.MainSourceOfIncome.valid)
    //     {
    //         this.rSource = true;
    //     }

    //     if (this.form.value.MainSourceOfIncome != this.salaryType && this.salaryType != "")
    //     {
    //         this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //             disableClose: false
    //         });

    //         this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.PERSONALIZE.R_MAIN_SOURCE_CHANGE : bangla.data.PERSONALIZE.R_MAIN_SOURCE_CHANGE;

    //         this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

    //         this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

    //         this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

    //         this.confirmDialogRef.afterClosed().subscribe(result =>
    //         {
    //             if (result)
    //             {
    //                 this.OnSubmit();
    //             }
    //             this.confirmDialogRef = null;
    //         });
    //     }
    //     else
    //     {
    //         this.OnSubmit();
    //     }
    // }

    mySubmitNext(param): void
    {
        // debugger;
        if (this.selectedIncome.length == 0 && this.selectedAssets.length == 0)
        {
            this.OnSubmit(param);
        }

        if (this.selectedIncome.every(elem => this.form.controls.OtherIncomeSources.value.includes(elem)) == false || this.selectedAssets.every(elem => this.form.controls.AcquiredAssets.value.includes(elem)) == false)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.PERSONALIZE.R_INCOME_ASSET_CHANGE : bangla.data.PERSONALIZE.R_INCOME_ASSET_CHANGE;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            this.OnSubmit(param);
        }
    }

    mySubmitExit(param): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        if (this.selectedIncome.every(elem => this.form.controls.OtherIncomeSources.value.includes(elem)) == false || this.selectedAssets.every(elem => this.form.controls.AcquiredAssets.value.includes(elem)) == false)
        {
            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.PERSONALIZE.R_INCOME_ASSET_CHANGE_EXIT : bangla.data.PERSONALIZE.R_INCOME_ASSET_CHANGE_EXIT;
        }
        else
        {
            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;
        }

        this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

        this.confirmDialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.OnSubmit(param);
            }
            this.confirmDialogRef = null;
        });

    }


    mySubmit2(): void
    {
        // let io = lodash.isEmpty(lodash.xor(this.form.controls.OtherIncomeSources.value, this.selectedIncome));

        // let io= this.form.controls.OtherIncomeSources.value.every((val) => this.selectedIncome.includes(val))

        // let io = this.form.controls.OtherIncomeSources.value.includes((val) => this.selectedIncome.includes(val));

        let io = this.form.controls.OtherIncomeSources.value;

        // console.log(io, 'io');

        let so = this.selectedIncome;

        // console.log(so, 'so');


        // const found = io.some(r => so.includes(r));
        let found = so.every(elem => io.includes(elem));


        // console.log(found, 'found');

        // var flag = true;
        // for (let i = 0; i < io.length; i++)
        // {
        //     if (io.includes(so[i]))
        //     {
        //         flag = true;
        //     }
        //     else flag = false;
        // }
        // console.log(flag);


    }

    OnSubmit(param): void
    {
        // debugger;
        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }

        if (!this.form.controls.OtherIncomeSources.valid)
        {
            this.rSource = true;
        }

        if (!this.form.controls.AcquiredAssets.valid)
        {
            this.gAssets = true;
        }

        this._spinner.show();

        let obj = this.form.getRawValue();

        // if (obj.MainSourceOfIncome == "2")
        // {
        //     obj.IsGovtStaff = true
        // }
        // if (obj.MainSourceOfIncome != "2" || "")
        // {
        //     obj.IsGovtStaff = false
        // }

        if (obj.HaveEligibleAssetValue == "1")
        {
            obj.HaveEligibleAssetValue = true
        }
        else
        {
            obj.HaveEligibleAssetValue = false
        }
        if (obj.HaveMotorCar == "1")
        {
            obj.HaveMotorCar = true
        }
        else
        {
            obj.HaveMotorCar = false
        }
        if (obj.HaveHouseProperty == "1")
        {
            obj.HaveHouseProperty = true
        }
        else
        {
            obj.HaveHouseProperty = false
        }

        if (obj.BIN == "0" || isNaN(Number(obj.BIN)) == true)
        {
            obj.BIN = "";
        }



        if (obj.OtherIncomeSources != null)
        {
            this.selectedFirstIncome = obj.OtherIncomeSources[0];
        }



        obj.AcquiredAssets = obj.AcquiredAssets.toString();
        obj.OtherIncomeSources = obj.OtherIncomeSources.toString();

        // let url = Utils.getIncomeUrl(this.selectedFirstIncome);
        // console.log(url, 'obj.OtherIncomeSources');

        let stringed = JSON.stringify(obj);

        if (obj.OtherIncomeSources == "" || obj.OtherIncomeSources == null)
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONALIZE.R_MAIN_SOURCE : bangla.data.PERSONALIZE.R_MAIN_SOURCE, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if ((obj.HaveEligibleAssetValue == true || obj.HaveMotorCar == true || obj.HaveHouseProperty == true) && obj.AcquiredAssets == "")
            {
                this._spinner.hide();
                this.form.controls['AcquiredAssets'].setErrors({ 'required': true });
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONALIZE.R_ACQ_ASSETS : bangla.data.PERSONALIZE.R_ACQ_ASSETS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {

                if (stringed != null)
                {
                    this._formControlService.submitPersonalizeReturnJson(stringed)
                        .subscribe((data: any) =>
                        {
                            // if (data != null)
                            // {
                            //     this._spinner.hide();
                            // }
                            if (data.isError === false)
                            {
                                console.log('submit succesfull');
                            }
                            else
                            {
                                this._spinner.hide();
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            if (data.isError === false && data.responseCode == "200")
                            {
                                if (this.id != null)
                                {
                                    this.getPanelData();
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                }
                                else
                                {
                                    if (param == 'next')
                                    {
                                        this.getPanelData();
                                    }
                                    if (param == 'exit')
                                    {
                                        this._authService.Logout();
                                    }

                                }

                                // this.sidePanelDeleteService.changeMessage(true);
                                // this._router.navigate(['/Salary']);
                                // this._router.navigate(['/PersonalizeReturn']);
                                // let incomeArr = obj.OtherIncomeSources.split(",")[0];
                                // this._router.navigate([Utils.getIncomeUrl(incomeArr)]);                         
                            }
                        }, (error: any) =>
                        {
                            this._spinner.hide();
                            console.log(error, 'error');
                        });
                }
            }
        }
    }

    getPanelData()
    {
        // debugger;
        this._quickPanelService.getProfileCompleteJson().subscribe((data: any) =>
        {
            this._spinner.show();

            if (data.data.checkprofile != null)
            {
                let pd = JSON.stringify(data.data.checkprofile);
                localStorage.setItem('profileData', pd);

            };
            this._spinner.hide();

            let url = Utils.getIncomeUrl(this.selectedFirstIncome);
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 8000
            });
            this._router.navigate([url]);
        }, (error: any) =>
        {
            this._spinner.hide();
            console.log(error, 'error');
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        });
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
