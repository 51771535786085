<section class="about-area ptb-100"
    style="background-color: #F5F5FC;">
    <div class="container">
        <div class="row align-items-center flex-column-reverse flex-lg-row">
            <div class="col-lg-7 col-md-12">

                <div class="about-content">
                    <h3 style="text-align: center; margin-bottom: 30px;">{{ 'HOW_IT_WORKS.HEADER_2' | translate }}</h3>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item"
                            src="https://www.youtube.com/embed/-f7pLaUHv8g"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div style="padding: 48px 48px 48px 48px; background-color: #fff;">

                    <div style="width: 128px;margin: 32px auto;">
                        <img src="assets/home/img/logo-n.png"
                            style="max-width: 100%;height: auto;vertical-align: top;border: none;">
                    </div>

                    <div style="text-align: center;font-size: 21px;font-weight: 300;line-height: 1;">{{
                        'AUTH_TRAN.REGISTER_HEADER' | translate }}</div>

                    <div *ngIf="isLoginError"
                        style="color: #f44336; margin-top: 20px;">
                        {{errorMessage}}
                    </div>

                    <div class="d-flex flex-row"
                        style="justify-content: center !important;
                        align-items: center !important; margin-top: 20px;">

                        <a (click)="setInd()"
                            id="indSet"
                            style="text-align: center;
                            cursor: pointer;
                            padding-right: 18px;
                            padding-top: 15px;
                            padding-bottom: 18px;
                            padding-left: 18px;
                            margin-right: 9px;
                            box-shadow: 0px 10px 40px 0px rgb(0 0 255 / 22%);"
                            class="d-flex justify-content-center flex-column mySelected">
                            <i class="flaticon-man"
                                style="font-size: 40px;"></i>
                            <span style="text-align: center; font-weight: bold;">{{ 'PRICING.PLAN_1_s' | translate
                                }}</span>
                        </a>

                        <a (click)="setPro()"
                            id="proSet"
                            style="text-align: center;
                            cursor: pointer;
                            padding-right: 18px;
                            padding-top: 15px;
                            padding-bottom: 18px;
                            padding-left: 18px;
                            margin-left: 9px;
                            box-shadow: 0px 10px 40px 0px rgb(0 0 255 / 22%);"
                            class="d-flex justify-content-center flex-column">
                            <i class="flaticon-team"
                                style="font-size: 40px;"></i>
                            <span style="text-align: center; font-weight: bold;">{{ 'PRICING.PLAN_2_s' | translate
                                }}</span>
                        </a>

                    </div>

                    <form *ngIf="isIndFrom"
                        name="registerFormInd"
                        style="width: 100%;padding-top: 32px;"
                        [formGroup]="registerFormInd"
                        (ngSubmit)="OnSubmitInd()">

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text"
                                        required
                                        formControlName="name"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_NAME' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormIndControl.name.touched || submittedInd) && registerFormIndControl.name.errors?.required">
                                        &nbsp; Name is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email"
                                        formControlName="email"
                                        required
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_EMAIL' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormIndControl.email.touched || submittedInd) && registerFormIndControl.email.errors?.required">
                                        &nbsp; Email is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormIndControl.email.touched && registerFormIndControl.email.errors?.invalidEmail">
                                        &nbsp; Enter a valid email address
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextTypeInd ? 'text' : 'password'"
                                        required
                                        formControlName="password"
                                        name="password"
                                        minlength="6"
                                        id="password"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormIndControl.password.touched || submittedInd) && registerFormIndControl.password.errors?.required">
                                        &nbsp; Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormIndControl.password.touched && registerFormIndControl.password.errors?.invalidPassword">
                                        &nbsp; Password should have minimum 6 characters, at least 1
                                        uppercase letter, 1
                                        lowercase
                                        letter and 1 number
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextTypeInd ? 'text' : 'password'"
                                        required
                                        formControlName="passwordConfirm"
                                        name="passwordConfirm"
                                        minlength="6"
                                        id="passwordConfirm"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_C_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormIndControl.passwordConfirm.touched || submittedInd)&& registerFormIndControl.passwordConfirm.errors?.required">
                                        &nbsp; Confirm Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormIndControl.passwordConfirm.touched && registerFormIndControl.passwordConfirm.errors?.passwordMismatch">
                                        &nbsp; Passwords do not match
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12"
                                style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center space-between;align-items: center;">

                                <div diasbled>

                                </div>
                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center space-between;align-items: center;justify-content: center;">
                                    <a (click)="toggleFieldTextTypeInd()"
                                        style="cursor: pointer;"
                                        *ngIf="!fieldTextTypeInd">Show Password</a>
                                    <a (click)="toggleFieldTextTypeInd()"
                                        style="cursor: pointer;"
                                        *ngIf="fieldTextTypeInd">Hide Password</a>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12"
                                style="margin-top: 8px; margin-bottom: 8px;flex-direction: column;box-sizing: border-box;display: flex;place-content: center center;align-items: center;">

                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center center;align-items: center;justify-content: center;">
                                    <input type="checkbox"
                                        style="margin-right: 8px; background-color: red;"
                                        required
                                        formControlName="acceptTerm"
                                        name="acceptTermInd"
                                        id="acceptTermInd">
                                    <label for="acceptTermInd"
                                        style="padding-top: 8px;"> Accept <a href="/terms-and-conditions"
                                            target="_blank"
                                            style="color: #29793C !important;">terms and
                                            conditions</a></label>

                                </div>

                                <div>
                                    <span class="text-danger"
                                        *ngIf="(registerFormIndControl.acceptTerm.touched || submittedInd)&& registerFormIndControl.acceptTerm.errors?.required">
                                        &nbsp; Please accept our terms and conditions
                                    </span>
                                </div>

                            </div>
                            <!-- <div *ngIf="!acceptTermInd"
                                class="col-lg-12 col-md-12"
                                style="margin-bottom: 8px;flex-direction: row;box-sizing: border-box;display: flex;place-content: center center;align-items: center; color: #f44336;">
                                Please accept terms and conditions
                            </div> -->

                            <div class="col-lg-12 col-md-12">
                                <button
                                    style="background-color: #29793C !important; color: #fff !important;width: 100%;margin: 16px auto;
                        display: block;box-sizing: border-box;position: relative;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;cursor: pointer;outline: none;border: none;-webkit-tap-highlight-color: transparent;display: inline-block;white-space: nowrap;text-decoration: none;text-align: center;margin: 0;min-width: 64px;line-height: 36px;padding: 0 16px;border-radius: 4px;overflow: visible;transform: translate3d(0, 0, 0);transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);"
                                    type="submit">
                                    {{ 'AUTH_TRAN.TEXT_CREATE_ACCOUNT_IND' | translate }}
                                </button>
                            </div>

                        </div>
                    </form>

                    <form *ngIf="!isIndFrom"
                        name="registerFormPro"
                        style="width: 100%;padding-top: 32px;"
                        [formGroup]="registerFormPro"
                        (ngSubmit)="OnSubmitPro()">

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text"
                                        required
                                        formControlName="name"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_NAME' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormProControl.name.touched || submittedPro) && registerFormProControl.name.errors?.required">
                                        &nbsp; Name is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email"
                                        formControlName="email"
                                        required
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_EMAIL' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormProControl.email.touched || submittedPro) && registerFormProControl.email.errors?.required">
                                        &nbsp; Email is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormProControl.email.touched && registerFormProControl.email.errors?.invalidEmail">
                                        &nbsp; Enter a valid email address
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextTypePro ? 'text' : 'password'"
                                        required
                                        formControlName="password"
                                        name="password"
                                        minlength="6"
                                        id="password"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormProControl.password.touched || submittedPro) && registerFormProControl.password.errors?.required">
                                        &nbsp; Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormProControl.password.touched && registerFormProControl.password.errors?.invalidPassword">
                                        &nbsp; Password should have minimum 6 characters, at least 1
                                        uppercase letter, 1
                                        lowercase
                                        letter and 1 number
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextTypePro ? 'text' : 'password'"
                                        required
                                        formControlName="passwordConfirm"
                                        name="passwordConfirm"
                                        minlength="6"
                                        id="passwordConfirm"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_C_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormProControl.passwordConfirm.touched || submittedPro)&& registerFormProControl.passwordConfirm.errors?.required">
                                        &nbsp; Confirm Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormProControl.passwordConfirm.touched && registerFormProControl.passwordConfirm.errors?.passwordMismatch">
                                        &nbsp; Passwords do not match
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12"
                                style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center space-between;align-items: center;">

                                <div diasbled>

                                </div>
                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center space-between;align-items: center;justify-content: center;">
                                    <a (click)="toggleFieldTextTypePro()"
                                        style="cursor: pointer;"
                                        *ngIf="!fieldTextTypePro">Show Password</a>
                                    <a (click)="toggleFieldTextTypePro()"
                                        style="cursor: pointer;"
                                        *ngIf="fieldTextTypePro">Hide Password</a>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12"
                                style="margin-top: 8px; margin-bottom: 8px;flex-direction: column;box-sizing: border-box;display: flex;place-content: center center;align-items: center;">

                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center center;align-items: center;justify-content: center;">
                                    <input type="checkbox"
                                        style="margin-right: 8px; background-color: red;"
                                        required
                                        formControlName="acceptTerm"
                                        name="acceptTermPro"
                                        id="acceptTermPro">
                                    <label for="acceptTermPro"
                                        style="padding-top: 8px;"> Accept <a href="/terms-and-conditions"
                                            target="_blank"
                                            style="color: #29793C !important;">terms and
                                            conditions</a></label>

                                </div>

                                <div>
                                    <span class="text-danger"
                                        *ngIf="(registerFormProControl.acceptTerm.touched || submittedPro)&& registerFormProControl.acceptTerm.errors?.required">
                                        &nbsp; Please accept our terms and conditions
                                    </span>
                                </div>

                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    style="background-color: #29793C !important; color: #fff !important;width: 100%;margin: 16px auto;
                        display: block;box-sizing: border-box;position: relative;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;cursor: pointer;outline: none;border: none;-webkit-tap-highlight-color: transparent;display: inline-block;white-space: nowrap;text-decoration: none;text-align: center;margin: 0;min-width: 64px;line-height: 36px;padding: 0 16px;border-radius: 4px;overflow: visible;transform: translate3d(0, 0, 0);transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);"
                                    type="submit">
                                    {{ 'AUTH_TRAN.TEXT_CREATE_ACCOUNT_PRO' | translate }}
                                </button>
                            </div>

                        </div>
                    </form>

                    <!-- <form name="registerForm"
                        style="width: 100%;padding-top: 32px;"
                        [formGroup]="registerForm"
                        (ngSubmit)="OnSubmit()">

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group"
                                    style="margin-top: 8px;margin-bottom: 16px;">
                                      <input type="radio"
                                        id="itp"
                                        formControlName="userType"
                                        value="1">
                                      <label for="itp">{{ 'AUTH_TRAN.TYPE_IND' | translate }}</label><br>
                                      <input type="radio"
                                        id="tp"
                                        formControlName="userType"
                                        value="2">
                                      <label for="tp">{{ 'AUTH_TRAN.TYPE_PRO' | translate }}</label><br>
                                    <span class="text-danger"
                                        *ngIf="(registerFormControl.userType.touched || submitted) && registerFormControl.userType.errors?.required">
                                        &nbsp; Type is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text"
                                        required
                                        formControlName="name"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_NAME' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormControl.name.touched || submitted) && registerFormControl.name.errors?.required">
                                        &nbsp; Name is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email"
                                        formControlName="email"
                                        required
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_EMAIL' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                                        &nbsp; Email is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormControl.email.touched && registerFormControl.email.errors?.invalidEmail">
                                        &nbsp; Enter a valid email address
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextType ? 'text' : 'password'"
                                        required
                                        formControlName="password"
                                        name="password"
                                        minlength="6"
                                        id="password"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required">
                                        &nbsp; Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
                                        &nbsp; Password should have minimum 6 characters, at least 1 uppercase letter, 1
                                        lowercase
                                        letter and 1 number
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextType ? 'text' : 'password'"
                                        required
                                        formControlName="passwordConfirm"
                                        name="passwordConfirm"
                                        minlength="6"
                                        id="passwordConfirm"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_C_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(registerFormControl.passwordConfirm.touched || submitted)&& registerFormControl.passwordConfirm.errors?.required">
                                        &nbsp; Confirm Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="registerFormControl.passwordConfirm.touched && registerFormControl.passwordConfirm.errors?.passwordMismatch">
                                        &nbsp; Passwords do not match
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12"
                                style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center space-between;align-items: center;">

                                <div diasbled>

                                </div>
                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center space-between;align-items: center;justify-content: center;">
                                    <a (click)="toggleFieldTextType()"
                                        style="cursor: pointer;"
                                        *ngIf="!fieldTextType">Show Password</a>
                                    <a (click)="toggleFieldTextType()"
                                        style="cursor: pointer;"
                                        *ngIf="fieldTextType">Hide Password</a>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12"
                                style="margin-top: 8px;flex-direction: row;box-sizing: border-box;display: flex;place-content: center center;align-items: center;">
                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;margin-bottom: 16px;display: flex;place-content: center center;align-items: center;justify-content: center;">
                                    <input type="checkbox"
                                        style="margin-right: 8px; background-color: red;"
                                        id="isRem"
                                        name="isRem">
                                    <label for="isRem"
                                        style="padding-top: 8px;"> Accept <a href="/terms-and-conditions"
                                            target="_blank"
                                            style="color: #29793C !important;">terms and conditions</a></label>
                                </div>

                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    style="background-color: #29793C !important; color: #fff !important;width: 100%;margin: 16px auto;
                                display: block;box-sizing: border-box;position: relative;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;cursor: pointer;outline: none;border: none;-webkit-tap-highlight-color: transparent;display: inline-block;white-space: nowrap;text-decoration: none;text-align: center;margin: 0;min-width: 64px;line-height: 36px;padding: 0 16px;border-radius: 4px;overflow: visible;transform: translate3d(0, 0, 0);transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);"
                                    type="submit">
                                    {{ 'AUTH_TRAN.REGISTER_HEADER' | translate }}
                                </button>
                            </div>

                        </div>
                    </form> -->
                    <div
                        style="width: 100%;margin: 16px auto;display: block;flex-direction: column;box-sizing: border-box;display: flex;place-content: center;align-items: center;">
                        <span style="margin-right: 8px;">{{ 'AUTH_TRAN.TEXT_AL_ACCOUNT' | translate }}</span>
                        <a class="link"
                            style="color: #29793C !important; font-weight: bold;"
                            [routerLink]="'/login-home'">{{ 'AUTH_TRAN.BTN_LOGIN_S' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>
