import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { PropertyClass } from './Property.Class';

@Component({
  selector: 'Property-form-control',
  templateUrl: './Property-form-control.component.html',
  styleUrls: ['./Property.component.scss']

})
export class PropertyFormControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  @Input() rPropertyType: boolean;
  IsCurrentYear: any;


  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }

  onKey(event: any, key)
  {
    let rawForm = this.form.getRawValue();

    if (key == "AnnualAmountReceived" || key == "MunicipalLocalTax" || key == "LandRevenue" || key == "LoanMortgageCapitalInterest" || key == "InsurancePremium" || key == "VacancyAllowance" || key == "Other" || key == "IncomeAfterDeduction" || key == "AsesseeSharePercent")
    {

      let AnnualAmountReceived = null;

      rawForm.AnnualAmountReceived == '' ?
        AnnualAmountReceived = 0 : AnnualAmountReceived = rawForm.AnnualAmountReceived;

      let MunicipalLocalTax, LandRevenue, LoanMortgageCapitalInterest, InsurancePremium, VacancyAllowance, Other = null

      rawForm.MunicipalLocalTax == '' ?
        MunicipalLocalTax = 0 : MunicipalLocalTax = rawForm.MunicipalLocalTax;

      rawForm.LandRevenue == '' ?
        LandRevenue = 0 : LandRevenue = rawForm.LandRevenue;

      rawForm.LoanMortgageCapitalInterest == '' ?
        LoanMortgageCapitalInterest = 0 : LoanMortgageCapitalInterest = rawForm.LoanMortgageCapitalInterest;

      rawForm.InsurancePremium == '' ?
        InsurancePremium = 0 : InsurancePremium = rawForm.InsurancePremium;

      rawForm.VacancyAllowance == '' ?
        VacancyAllowance = 0 : VacancyAllowance = rawForm.VacancyAllowance;

      rawForm.Other == '' ?
        Other = 0 : Other = rawForm.Other;

      let AsesseeSharePercent = null;

      rawForm.AsesseeSharePercent == '' ?
        AsesseeSharePercent = 0 : AsesseeSharePercent = rawForm.AsesseeSharePercent;

      if (isNaN(Number(rawForm.AnnualAmountReceived)) == true)
      {
        AnnualAmountReceived = '0.00';
      }

      if (isNaN(Number(rawForm.MunicipalLocalTax)) == true)
      {
        MunicipalLocalTax = '0.00';
      }

      if (isNaN(Number(rawForm.LandRevenue)) == true)
      {
        LandRevenue = '0.00';
      }

      if (isNaN(Number(rawForm.LoanMortgageCapitalInterest)) == true)
      {
        LoanMortgageCapitalInterest = '0.00';
      }

      if (isNaN(Number(rawForm.InsurancePremium)) == true)
      {
        InsurancePremium = '0.00';
      }

      if (isNaN(Number(rawForm.VacancyAllowance)) == true)
      {
        VacancyAllowance = '0.00';
      }

      if (isNaN(Number(rawForm.Other)) == true)
      {
        Other = '0.00';
      }

      if (isNaN(Number(rawForm.AsesseeSharePercent)) == true)
      {
        AsesseeSharePercent = '0.00';
      }

      var propertyClass = new PropertyClass(
        rawForm.RepairCollectionEtc,
        AnnualAmountReceived,
        MunicipalLocalTax,
        LandRevenue,
        LoanMortgageCapitalInterest,
        InsurancePremium,
        VacancyAllowance,
        Other,
        rawForm.TotalExpense,
        rawForm.IncomeAfterDeduction,
        AsesseeSharePercent,
        rawForm.ShareOfIncomeAmount,
        rawForm.PropertyType
      )

      this.form.patchValue({
        RepairCollectionEtc: Number(propertyClass.RepairCollectionEtc).toFixed(2),
      })

      this.form.patchValue({
        TotalExpense: Number(propertyClass.TotalExpense).toFixed(2),

      })

      this.form.patchValue({
        IncomeAfterDeduction: Number(propertyClass.IncomeAfterDeduction).toFixed(2),
      })


      this.form.patchValue({
        ShareOfIncomeAmount: Number(propertyClass.ShareOfIncomeAmount).toFixed(2),
      })
    }
  }

  radioChange2(event)
  {
    let rawForm = this.form.getRawValue();

    let AnnualAmountReceived = null;

    rawForm.AnnualAmountReceived == '' ?
      AnnualAmountReceived = 0 : AnnualAmountReceived = rawForm.AnnualAmountReceived;

    var propertyClass = new PropertyClass(
      rawForm.RepairCollectionEtc,
      AnnualAmountReceived,
      rawForm.MunicipalLocalTax,
      rawForm.LandRevenue,
      rawForm.LoanMortgageCapitalInterest,
      rawForm.InsurancePremium,
      rawForm.VacancyAllowance,
      rawForm.Other,
      rawForm.TotalExpense,
      rawForm.IncomeAfterDeduction,
      rawForm.AsesseeSharePercent,
      rawForm.ShareOfIncomeAmount,
      event.value
    )

    this.form.patchValue({
      RepairCollectionEtc: Number(propertyClass.RepairCollectionEtc).toFixed(2),
    })

    this.form.patchValue({
      TotalExpense: Number(propertyClass.TotalExpense).toFixed(2),

    })

    this.form.patchValue({
      IncomeAfterDeduction: Number(propertyClass.IncomeAfterDeduction).toFixed(2),
    })

    this.form.patchValue({
      ShareOfIncomeAmount: Number(propertyClass.ShareOfIncomeAmount).toFixed(2),

    })
  }
}
