import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

import { FuseDemoContentComponent } from './demo-content/demo-content.component';
import { FuseDemoSidebarComponent } from './demo-sidebar/demo-sidebar.component';
import { MyDemoSidebarComponent } from './my-demo-sidebar/my-demo-sidebar.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MyDemoArchiveHeader } from './demo-archive-header/my-demo-archive-header.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [
        FuseDemoContentComponent,
        FuseDemoSidebarComponent,
        MyDemoSidebarComponent,
        MyDemoArchiveHeader
    ],
    imports: [
        RouterModule,
        TranslateModule,
        MatDialogModule,
        MatDividerModule,
        MatListModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatTreeModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        MatButtonModule,
        MatButtonToggleModule,
        FuseSharedModule,
    ],
    exports: [
        FuseDemoContentComponent,
        FuseDemoSidebarComponent,
        MyDemoSidebarComponent,
        MyDemoArchiveHeader
    ]
})
export class FuseDemoModule
{
}
