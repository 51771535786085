import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Layouts } from 'app/enum/layouts.enum';

import { FaqComponent } from './faq.component';

const routes = [
    {
        path     : 'faq',
        component: FaqComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        FaqComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule        
    ],
    exports     : [
        FaqComponent
    ]
})

export class FaqModule
{
}
