import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import Config from 'app/util/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { NotificationService } from '../Notification.service';



@Component({
    selector: 'TemplateListDialog',
    templateUrl: 'template-list-Dialog.component.html',
    styleUrls: ['./template-list-Dialog.component.scss'],
})
export class TemplateListDialog
{

    public files: any[] = [];
    FileBase64String: any;
    FileName: any;
    lang = '';
    constructor(
        public dialogRef: MatDialogRef<TemplateListDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: NotificationService,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');

    }

    onFocusoutEvent()
    {


        // console.log(Comments, 'Comments');


    }



    onNoClick(): void
    {
        let param = {
            type: "no",
            data: null
        }
        this.dialogRef.close(param);
    }

    onEditClick(data): void
    {
        let param = {
            type: "edit",
            data: data
        }
        this.dialogRef.close(param);
    }

    passData(data): void
    {
        let param = {
            type: "pass",
            data: data
        }
        this.dialogRef.close(param);
    }

    deleteData(data): void
    {
        let param = {
            type: "delete",
            data: data
        }
        this.dialogRef.close(param);
    }


}
