import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Layouts } from 'app/enum/layouts.enum';

import { HiwComponent } from './how-it-works.component';

const routes = [
    {
        path: 'how-it-works',
        component: HiwComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        HiwComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule
    ],
    exports: [
        HiwComponent
    ]
})

export class HiwModule
{
}
