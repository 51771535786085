<section class="about-area ptb-100"
    style="background-color: #F5F5FC;">
    <div class="container">
        <div class="row align-items-center flex-column-reverse flex-lg-row">
            <!-- <div class="col-lg-7 col-md-12  d-none d-md-block"> -->
            <div class="col-lg-7 col-md-12">

                <div class="about-content">
                    <h3 style="text-align: center; margin-bottom: 30px;">{{ 'HOW_IT_WORKS.HEADER_2' | translate }}</h3>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item"
                            src="https://www.youtube.com/embed/-f7pLaUHv8g"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
            <div *ngIf="isLoggedInNav==false"
                class="col-lg-5 col-md-12">
                <div style="padding: 48px 48px 48px 48px; background-color: #fff;">

                    <div style="width: 128px;
                    margin: 32px auto;">
                        <img src="assets/home/img/logo-n.png"
                            style="max-width: 100%;height: auto;vertical-align: top;border: none;">
                    </div>

                    <div style="text-align: center;font-size: 21px;font-weight: 300;line-height: 1;">{{
                        'AUTH_TRAN.LOGIN_HEADER' | translate }}</div>

                    <div *ngIf="meassage!=null"
                        style="color: #29793C; margin-top: 20px;">
                        {{meassage}}<br>
                        Please login using the new Password.
                    </div>

                    <!-- <div id="errorToast"
                        *ngIf="isLoginError"
                        class="col-lg-12 col-md-12 hidden"
                        style="flex-direction: column;box-sizing: border-box;display: flex;place-content: center space-between; border: 1px solid red; border-radius: 15px; padding-left: 5px; padding-right: 5px; margin-top: 32px;">

                        <div
                            style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center space-between;align-items: center;">

                            <div diasbled>

                            </div>
                            <div
                                style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center space-between;align-items: center;justify-content: center;margin-right: 6px;">
                                <a (click)="showtoast()"
                                    style="cursor: pointer;">Close</a>

                            </div>
                        </div>
                        <div
                            style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center space-between;align-items: center; margin-bottom: 24px;">

                            <span style="margin-left: 6px; color: #f44336;">Incorrect username or password.</span>
                        </div>
                    </div> -->
                    <div *ngIf="isLoginError">
                        <span style="margin-left: 6px; color: #f44336;">Incorrect username or password.</span>
                    </div>

                    <form name="loginForm"
                        style="width: 100%;padding-top: 32px;"
                        [formGroup]="loginForm"
                        novalidate
                        (ngSubmit)="OnSubmit()">

                        <div class="row align-items-center">

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email"
                                        required
                                        formControlName="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_EMAIL' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(loginFormControl.email.touched || submitted) && loginFormControl.email.errors?.required">
                                        &nbsp; Email is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="loginFormControl.email.touched && loginFormControl.email.errors?.invalidEmail">
                                        &nbsp; Enter a valid email address
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextType ? 'text' : 'password'"
                                        required
                                        formControlName="password"
                                        name="password"
                                        id="password"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_PASSWORD' | translate }}">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12"
                                style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center space-between;align-items: center;">

                                <div>
                                    <a style="color: #29793C !important;font-size: 13px;font-weight: 600;margin-bottom: 16px"
                                        [routerLink]="'/forgot-password-home'">
                                        {{ 'AUTH_TRAN.FORGOT_PASSWORD' | translate }}
                                    </a>
                                </div>
                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center space-between;align-items: center;justify-content: center;">
                                    <a (click)="toggleFieldTextType()"
                                        style="cursor: pointer;"
                                        *ngIf="!fieldTextType">Show Password</a>
                                    <a (click)="toggleFieldTextType()"
                                        style="cursor: pointer;"
                                        *ngIf="fieldTextType">Hide Password</a>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12"
                                style="margin-top: 16px;">
                                <button
                                    style="background-color: #29793C !important; color: #fff !important;  width: 100%;margin: 16px auto;display: block;box-sizing: border-box;position: relative;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;cursor: pointer;outline: none;border: none;-webkit-tap-highlight-color: transparent;display: inline-block;white-space: nowrap;text-decoration: none;text-align: center;margin: 0;min-width: 64px;line-height: 36px;padding: 0 16px;border-radius: 4px;overflow: visible;transform: translate3d(0, 0, 0);    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);"
                                    aria-label="LOGIN"
                                    type="submit">
                                    {{ 'AUTH_TRAN.BTN_LOGIN' | translate }}
                                </button>
                            </div>
                        </div>

                    </form>

                    <div
                        style=" font-size: 15px;font-weight: 600;margin: 24px auto;position: relative;overflow: hidden;width: 100px;text-align: center;">
                        <span style=" display: inline-flex;position: relative;padding: 0 8px;z-index: 9999;">&#8213;
                            {{ 'AUTH_TRAN.TEXT_OR' | translate }} &#8213;</span>
                    </div>

                    <div
                        style="flex-direction: column;box-sizing: border-box;display: flex;place-content: center;align-items: center;">
                        <span style="margin-right: 8px;">{{ 'AUTH_TRAN.TEXT_NO_ACCOUNT' | translate }}</span>
                        <a class="link"
                            style="color: #29793C !important; font-weight: bold;"
                            [routerLink]="'/register-home'">{{ 'AUTH_TRAN.TEXT_CREATE_ACCOUNT' | translate }}</a>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoggedInNav==true"
                class="col-lg-5 col-md-12">
                <div style="padding: 48px 48px 48px 48px; background-color: #fff;">

                    <div style="width: 128px;
                    margin: 32px auto;">
                        <img src="assets/home/img/logo-n.png"
                            style="max-width: 100%;height: auto;vertical-align: top;border: none;">
                    </div>

                    <div style="text-align: center;">You are already
                        logged in as <span style="color: #29793C; font-weight: bold;">{{userInfo.Name}}</span>.<br>
                        <span>to login using another ID please <a (click)="Logout()"
                                class="nav-link"
                                style="color: red; border: 0; background: transparent; align-items: flex-start !important; cursor: pointer;">
                                Logout
                            </a></span>
                    </div>

                    <div
                        style=" font-size: 15px;font-weight: 600;margin: 24px auto;position: relative;overflow: hidden;width: 100px;text-align: center;">
                        <span style=" display: inline-flex;position: relative;padding: 0 8px;z-index: 9999;">&#8213;
                            {{ 'AUTH_TRAN.TEXT_OR' | translate }} &#8213;</span>
                    </div>

                    <div
                        style="flex-direction: column;box-sizing: border-box;display: flex;place-content: center;align-items: center;">
                        <span style="margin-right: 8px;">Want to create another account?</span>
                        <a class="link"
                            style="color: #29793C !important; font-weight: bold;"
                            [routerLink]="'/register-home'">{{ 'AUTH_TRAN.TEXT_CREATE_ACCOUNT' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>
