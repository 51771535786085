import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsListService } from '../ClientsList.service';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';

import * as moment from 'moment';

@Component({
    selector: 'GeneralSmsDialog',
    templateUrl: 'general-sms-dialog.component.html',
    styleUrls: ['./general-sms-dialog.component.scss'],
})
export class GeneralSmsDialog
{


    lang = '';



    SmsBody = new FormControl();
    BcTabDateControl = new FormControl(new Date());
    BcTabPayTypeControl = new FormControl();

    BcTabMainForm: FormGroup;

    maxDate = new Date();
    BcTabBillEnabled = false;


    constructor(
        public dialogRef: MatDialogRef<GeneralSmsDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: ClientsListService,
        private _transactionControlService: TransactionService,

        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        dialogRef.disableClose = true;
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');

    }















    SendSms(): void
    {
        if (this.SmsBody.value.length < 0 || this.SmsBody.value.length == null)
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please enter a Sms Text" : "Please enter a Sms Text", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        } else
        {
            this._spinner.show();


            let query = {
                "ReturnId": this.dialogdata.ReturnId,
                "AssesseeId": this.dialogdata.AssesseeId,
                "CustomMassegeBody": this.SmsBody.value,
                "SearchFlag": "general"
            }
            // console.log(query, 'query');
            debugger;
            this._formControlService.NotificationSendSms(query).subscribe(data =>
            {
                // console.log(data, 'data');
                // this._spinner.hide();
                if (data.isError === false)
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? "Sms is being sent" : "Sms is being sent", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                this.dialogRef.close('refresh');


            });
        }

    }




    onNoClick(): void
    {
        this.dialogRef.close("no");
    }







}
