<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            fusePerfectScrollbar
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'SPECIAL.HEADER' | translate }}
            </span>

        </div>
        <div class="content mb-48"
            fusePerfectScrollbar>
            <div class="course-step ml-24 mb-12"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">

                <form [formGroup]="festivalForm"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    style="margin-top: 20px;">
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <span style="font-weight: bold; color: #000000;">{{ 'SPECIAL.ITEM1' | translate }}</span>

                    </div>
                    <div formArrayName="festivalItems"
                        *ngFor="let item of festivalItems().controls; let i=index">
                        <div fxLayout="row"
                            fxLayoutAlign="space-between center"
                            fxFlex="100"
                            [formGroupName]="i">
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_AMOUNT' | translate }}
                                </mat-label>

                                <input formControlName="Amount"
                                    readonly={{readonly}}
                                    class="rightA"
                                    (click)="$event.target.select()"
                                    NumbersOnly
                                    type="text"
                                    decimals="100"
                                    negative="1"
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_COMMENT' | translate }}
                                </mat-label>

                                <input formControlName="Comment"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_DESC' | translate }}
                                </mat-label>

                                <input formControlName="Description"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <button mat-icon-button
                                style="border: 0;cursor: pointer;background-color: transparent;"
                                [disabled]="readonly"
                                (click)="removeFestivalItem(i)"
                                matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                <mat-icon class="s-20">clear</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <div disabled
                            class="mat-button mat-button-base primary-A400 secondary-text">

                        </div>

                        <button mat-flat-button
                            [disabled]="readonly"
                            style="border: 0;cursor: pointer;background-color: transparent; margin-right: 40px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            (click)="addFestivalItem()">
                            <label style="color:#339ce5;font-size: 25px;margin-top: -5px;">+ </label><u
                                style="color:#339ce5;">{{ 'COMMON.BUTTON_ADD_MORE_ROWS' | translate }}</u>
                        </button>
                    </div>
                </form>
                <form [formGroup]="domesticForm"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    style="margin-top: 20px;">
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <span style="font-weight: bold; color: #000000;"> {{ 'SPECIAL.ITEM2' | translate }}</span>

                    </div>
                    <div formArrayName="domesticItems"
                        *ngFor="let item of domesticItems().controls; let i=index">
                        <div fxLayout="row"
                            fxLayoutAlign="space-between center"
                            fxFlex="100"
                            [formGroupName]="i">
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_AMOUNT' | translate }}
                                </mat-label>

                                <input formControlName="Amount"
                                    class="rightA"
                                    readonly={{readonly}}
                                    (click)="$event.target.select()"
                                    NumbersOnly
                                    type="text"
                                    decimals="100"
                                    negative="1"
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_COMMENT' | translate }}
                                </mat-label>

                                <input formControlName="Comment"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_DESC' | translate }}
                                </mat-label>

                                <input formControlName="Description"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <button mat-icon-button
                                style="border: 0;cursor: pointer;background-color: transparent;"
                                [disabled]="readonly"
                                (click)="removeDomesticItem(i)"
                                matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                <mat-icon class="s-20">clear</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <div disabled
                            class="mat-button mat-button-base primary-A400 secondary-text">

                        </div>

                        <button mat-flat-button
                            [disabled]="readonly"
                            style="border: 0;cursor: pointer;background-color: transparent; margin-right: 40px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            (click)="addDomesticItem()">
                            <label style="color:#339ce5;font-size: 25px;margin-top: -5px;">+ </label><u
                                style="color:#339ce5;">{{ 'COMMON.BUTTON_ADD_MORE_ROWS' | translate }}</u>
                        </button>
                    </div>
                </form>

                <form [formGroup]="philanthropyForm"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    style="margin-top: 20px;">
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <span style="font-weight: bold; color: #000000;">{{ 'SPECIAL.ITEM3' | translate }}</span>

                    </div>
                    <div formArrayName="philanthropyItems"
                        *ngFor="let item of philanthropyItems().controls; let i=index">
                        <div fxLayout="row"
                            fxLayoutAlign="space-between center"
                            fxFlex="100"
                            [formGroupName]="i">
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_AMOUNT' | translate }}
                                </mat-label>

                                <input formControlName="Amount"
                                    class="rightA"
                                    readonly={{readonly}}
                                    (click)="$event.target.select()"
                                    NumbersOnly
                                    type="text"
                                    decimals="100"
                                    negative="1"
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_COMMENT' | translate }}
                                </mat-label>

                                <input formControlName="Comment"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_DESC' | translate }}
                                </mat-label>

                                <input formControlName="Description"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <button mat-icon-button
                                style="border: 0;cursor: pointer;background-color: transparent;"
                                [disabled]="readonly"
                                (click)="removePhilanthropyItem(i)"
                                matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                <mat-icon class="s-20">clear</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <div disabled
                            class="mat-button mat-button-base primary-A400 secondary-text">

                        </div>

                        <button mat-flat-button
                            [disabled]="readonly"
                            style="border: 0;cursor: pointer;background-color: transparent; margin-right: 40px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            (click)="addPhilanthropyItem()">
                            <label style="color:#339ce5;font-size: 25px;margin-top: -5px;">+ </label><u
                                style="color:#339ce5;">{{ 'COMMON.BUTTON_ADD_MORE_ROWS' | translate }}</u>
                        </button>
                    </div>
                </form>

                <form [formGroup]="otherForm"
                    fxLayout="column"
                    fxLayoutAlign="start"
                    fxFlex="1 0 auto"
                    style="margin-top: 20px;">
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <span style="font-weight: bold; color: #000000;">{{ 'SPECIAL.ITEM4' | translate }}</span>

                    </div>
                    <div formArrayName="otherItems"
                        *ngFor="let item of otherItems().controls; let i=index">
                        <div fxLayout="row"
                            fxLayoutAlign="space-between center"
                            fxFlex="100"
                            [formGroupName]="i">
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_AMOUNT' | translate }}
                                </mat-label>

                                <input formControlName="Amount"
                                    class="rightA"
                                    readonly={{readonly}}
                                    (click)="$event.target.select()"
                                    NumbersOnly
                                    type="text"
                                    decimals="100"
                                    negative="1"
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_COMMENT' | translate }}
                                </mat-label>

                                <input formControlName="Comment"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <mat-form-field appearance="outline"
                                fxFlex="30">
                                <mat-label>{{ 'COMMON.BUTTON_DESC' | translate }}
                                </mat-label>

                                <input formControlName="Description"
                                    readonly={{readonly}}
                                    matInput>

                            </mat-form-field>
                            <button mat-icon-button
                                style="border: 0;cursor: pointer;background-color: transparent;"
                                [disabled]="readonly"
                                (click)="removeOtherItem(i)"
                                matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                <mat-icon class="s-20">clear</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center">
                        <div disabled
                            class="mat-button mat-button-base primary-A400 secondary-text">

                        </div>

                        <button mat-flat-button
                            [disabled]="readonly"
                            style="border: 0;cursor: pointer;background-color: transparent; margin-right: 40px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="center"
                            (click)="addOtherItem()">
                            <label style="color:#339ce5;font-size: 25px;margin-top: -5px;">+ </label><u
                                style="color:#339ce5;">{{ 'COMMON.BUTTON_ADD_MORE_ROWS' | translate }}</u>
                        </button>
                    </div>
                </form>
            </div>

        </div>
        <div class="step-navigation"
            *ngIf="!showCancel">
            <button mat-raised-button
                (click)="ConfirmLeave('/ChildrensEducation')"
                style="border: 2px solid #37793c;line-height: 30px;background-color: #ffffff;cursor: pointer;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>

            <button mat-raised-button
                style="margin-left: 10px;background-color: #37793c;border: 0;border-top-left-radius: 4px;border-bottom-left-radius: 4px;color: #ffffff; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>

            <button [matMenuTriggerFor]="sNeBtn"
                style=" background-color: #37793c; border: 0; border-radius: 4px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;cursor: pointer; line-height: 34px;">
                <mat-icon style="color: #ffffff; font-size: 15px;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit('exit')">
                    <span style="font-weight: bold; color: #37793c;"> {{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}
                    </span>
                </button>
            </mat-menu>

            <button mat-raised-button
                (click)="ConfirmLeave('/OtherExpenses')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer;line-height: 30px;margin-left: 10px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>

        <div class="step-navigation"
            *ngIf="showCancel">
            <button mat-raised-button
                (click)="ConfirmLeave('/ChildrensEducation')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer; margin-right: 10px;line-height: 30px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>
            <button mat-raised-button
                style="color: #444444 !important; margin-right: 10px;"
                [routerLink]="'/SpecialExpenses'"
                class="mat-button mat-button-base primary-100 secondary-text">
                {{ 'COMMON.BUTTON_CANCEL' | translate }}
            </button>
            <button mat-raised-button
                style="margin-left: right;background-color: #37793c; border: 0; border-top-left-radius: 4px;border-bottom-left-radius: 4px; color: #ffffff; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>
            <button [matMenuTriggerFor]="sNeBtn"
                style=" background-color: #37793c; border: 0; border-radius: 4px;border-top-left-radius: 0; border-bottom-left-radius: 0;color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;cursor: pointer; line-height: 34px;">
                <mat-icon style="color: #ffffff; font-size: 15px;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit('exit')">
                    <span style="font-weight: bold; color: #37793c;">{{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}</span>
                </button>
            </mat-menu>
            <button mat-raised-button
                (click)="ConfirmLeave('/OtherExpenses')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer; margin-left: 10px;line-height: 30px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>
    </div>
</div>
