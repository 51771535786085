<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>নতুন করদাতা কখন হবেন? এবং করদাতা হলে সরকার থেকে কি কি সুবিধা পাবেন? </h2>
                 
                </div>
            </div>
        </div>
    </div>


</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-100" >
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/home/img/blog-image/blog_m01_1.jpg"
                            alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On: </span> <span style="color: #29793C;">August 16, 2021</span></li>
                                <li><span>Posted By: </span> <span style="color: #29793C;">digiTax</span></li>
                            </ul>
                        </div>

                        <h3>নতুন করদাতা কখন হবেন?</h3>
                        <p>প্রথম কথা হলো, আপনি করদাতা হতে চাইলে বা যদি আপনার আয়কর যোগ্য হয়ে থাকে তাহলে আপনার টিন থাকতে
                            হবে।</p>
                        <p>আপনার যখন টিন হয়ে যাবে তার পর প্রশ্ন আসে এবার কি আপনাকে রিটার্ন দাখিল করতে হবে কিনা? বা টিন
                            থাকলেই কি রিটার্ন দাখিল করতে হয়?</p>
                        <p>টিন এর সাথে রিটার্ন দাখিল করার মধ্যে কিছু পার্থক্য রয়েছে। টিন থাকলেই আয়কর রিটার্ন দাখিল করা
                            বাধ্যতামূলক নয়।</p>
                        <p>আবার রিটার্ন দাখিল করলেই আয়কর দেওয়ার দরকার হয় না। আয়কর তখনই দিতে হয় যখন একজন ব্যক্তির
                            করযোগ্য আয় থাকে।</p>
                        <p>যেমন, এখন ১৬.০০০ টাকা বা তার বেশি যদি কোন চাকরিজীবী মূল বেতন পান তাহলে তাকে রিটার্ন দাখিল
                            করতে হয়। এখন তিনি রিটার্ন দাখিল করলেন, কিন্তু তার করযোগ্য আয় ২৫০,০০০ টাকা অতিক্রম করেনি।
                            তাহলে তাকে কর দিতে হবে না, কিন্তু যদি তার ই-টিন থাকে তাহলে তাকে শূন্য রিটার্ন সাবমিট করতে
                            হবে।</p>
                        <p>তাহলে মূল কথা হলাে, আপনি তখনই একজন নতুন করদাতা হবেন যখন আপনার আয় করযোগ্য সীমা অতিক্রম করবে।
                        </p>
                        <p>ই-টিন/টিন আছে এমন কোনো ব্যক্তি করদাতার বাৎসরিক আয় যদি করমুক্ত সীমা অতিক্রম করে অর্থাৎ যদি
                            ৩.০০,০০০ টাকার বেশি হয় তাহলে তাকে শূন্য আয়কর রিটার্ন জমা দিতে হবে। মহিলা এবং ৬৫ বছর বা তার
                            বেশি কোন করদাতার বাৎসরিক আয় যদি ৩,৫০,০০০ টাকার বেশি . প্রতিবন্ধী করদাতার ক্ষেত্রে বাৎসরিক
                            ৪,৫০,০০০ টাকা এবং গেজেট যুদ্ধাহত মুক্তিযোদ্ধা করদাতার ক্ষেত্রে বাৎসরিক ৪,৭৫,০০০ টাকার বেশি
                            হলে তাকে বাধ্যতামূলক রিটার্ন দাখিল করতে হবে। কত টাকা কর দিতে হবে সেটি করদাতার আয় এর ওপর
                            নির্ভর করবে। উপরে উল্লেখিত কর সীমার নিচে কারাে বাৎসরিক আয় থাকলে তাকে কর বা রিটার্ন কিছুই
                            জমা দিতে হবে না।</p>
                        <p>একজন করদাতা হতে হলে রিটার্ন দাখিল করতে হয় তা আগে বলেছি। তবে নিম্নবর্ণিত ক্ষেত্রসমূহে আয়ের
                            পরিমাণ যাই হকনা কেন, ব্যক্তি করদাতার সংশ্লিষ্ট আয় বছরের জন্য আবশ্যিক ভাবে আয়কর রিটার্ন
                            দাখিল করতে হবে</p>
                        <ul class="features-list">
                            <li><i class="fas fa-check"></i> কোন কোম্পানির শেয়ারহোল্ডার পরিচালক বা শেয়ারহোল্ডার
                                চাকরিজীবী</li>
                            <li><i class="fas fa-check"></i> কোন ফার্মের অংশীদার</li>
                            <li><i class="fas fa-check"></i> সরকারি কোন প্রতিষ্ঠানের কর্মচারী হয়ে আয় বছরের যেকোন সময়
                                ১৬,০০० টাকা বা তদূর্ধ্ব পরিমাণ মূল বেতন আহরণ করে থাকলে,</li>
                            <li><i class="fas fa-check"></i> কোন ব্যবসায় বা পেশায় নির্বাহী ব্যবস্থাপনা পদে, যে নামেই
                                অভিহিত হােক না কেন, বেতনভোগী কর্মী হয়ে থাকলে।</li>
                            
                        </ul>
                        <p>এর বাইরে কিছু ক্ষেত্রে শর্তম্বরূপ আয়কর রিটার্ন দেখাতে হয়। যেমন, জাতীয় নির্বাচন থেকে শুরু
                            করে স্থানীয় পর্যায়ের নির্বাচন অংশগ্রহণকারী, দরপত্রে অংশগ্রহণকারী, সমাজের কোন প্রতিষ্ঠিত
                            ক্লাবের সদস্য ইত্যাদি।</p>
                        <p>যেসব ক্ষেত্রে এমন শর্ত রয়েছে যে বাধ্যতামূলকভাবে জাতীয় রাজস্ব বাের্ডে আয়কর রিটার্ন দাখিল
                            করতে হবে সেগুলাে হলােঃ</p>
                        <ul class="features-list">
                            <li><i class="fas fa-check"></i> মোটর গাড়ির মালিক (মোটরগাড়ি বলতে জীপ বা মাইক্রোবাস ও
                                বুঝাবে)</li>
                            <li><i class="fas fa-check"></i> মূল্য সংযোজন কর আইনের অধীন নিবন্ধিত কোন ক্লাবের সদস্য</li>
                            <li><i class="fas fa-check"></i> কোন সিটি কর্পোরেশন, পৌরসভা বা ইউনিয়ন পরিষদ হতে ট্রেড
                                লাইসেন্স গ্রহণ করে কোন ব্যবসা বা পেশা পরিচালনা করে থাকেন এমন ব্যক্তি</li>
                            <li><i class="fas fa-check"></i> চিকিৎসক, দন্ত চিকিৎসক, আইনজীবী, চার্টার্ড অ্যাকাউন্ট্যান্ট,
                                কস্ট অ্যান্ড ম্যানেজমেন্ট অ্যাকাউন্ট্যান্ট, প্রকৌশলী, স্থপতি অথবা সার্ভেয়ার হিসেবে বা
                                সমজাতীয় পেশাজীবী হিসেবে কোন স্বীকৃত পেশাজীবী সংস্থার নিবন্ধন ভুক্ত ব্যক্তি</li>
                            <li><i class="fas fa-check"></i> জাতীয় রাজস্ব বোর্ডে নিবন্ধিত আয়কর পেশাজীবী।</li>
                            <li><i class="fas fa-check"></i> কোন বণিক বা শিল্প বিষয়ক চেম্বার বা ব্যবসায়িক সংঘ বা
                                সংস্থার সদস্য</li>
                            <li><i class="fas fa-check"></i> কোন পৌরসভা বা সিটি কর্পোরেশনের কোন পদে বা সংসদ সদস্য পদে
                                প্রার্থী হওয়া</li>
                            <li><i class="fas fa-check"></i> কোন সরকারি, আধা-সরকারি, স্বায়ত্বশাসিত সংস্থা বা কোন
                                স্থানীয় সরকারের কোন দরপত্রে</li>
                            <li><i class="fas fa-check"></i> কোন কোম্পানি বা কোন গ্রুপ অফ কোম্পানিজ এর পরিচালনা পরিষদে
                                থাকা</li>
                            <li><i class="fas fa-check"></i> রাইড শেয়ারিং ব্যবসা মোটরযান প্রদান করা। (উবার, পাঠাও
                                ইত্যাদি)</li>
                        </ul>
                        <p>তাহলে উপর থেকে জানলেন কখন একজন ব্যক্তি নতুন করদাতা হবেন। সরকার প্রতি বছরই চেষ্টা করে করদাতার সংখ্যা বাড়াতে।</p>
                        <h3>করদাতারা সরকার থেকে কি কি সুবিধা পাবেন?</h3>
                        <p>করদাতারা সরকার থেকে বিভিন্ন সুযোগ সুবিধা পেয়ে থাকেন। যেমন, সরকারি হাসপাতালগুলোতে খুবই কম খরচে সেবা নিতে পারেন। তবে আপনি বলতে পারেন, করদাতা না হয়েও আপনি এই সুবিধা অন্যান্য নাগরিকের মতাে নিতে পারেন।</p>
                        <p>তা ঠিক।</p>
                        <p>কিন্তু আপনার যখন আয় বাড়তে থাকবে তখন আপনি বিভিন্ন ব্যক্তিগত সুবিধা নিতে থাকবেন যেটা অন্যরা নিতে পারবে না।</p>
                        <p>আপনি যদি ক্রেডিট কার্ড নিতে চান তাহলে আপনার ট্যাক্স সার্টিফিকেট লাগবে। আপনি যদি সরকারি বা বেসরকারি ঠিকাদারি কাজ করতে চান তাহলে টিন লাগবে। দেশের বাইরে বেড়াতে গেলে ট্যাক্স সার্টিফিকেট লাগবে। এছাড়াও এক লক্ষ টাকার অধিক ক্রয় করতে গেলে এখন টিন থাকা বাধ্যতামূলক করা হয়েছে।</p>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">


                    <section class="widget widget_karlson_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"
                                class="thumb">
                                <span class="fullimage cover bg2"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2021-08-16">August 16, 2021</time>
                                <h4 class="title usmall"><a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R2' | translate }}</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"
                                class="thumb">
                                <span class="fullimage cover bg3"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2021-08-16">August 16, 2021</time>
                                <h4 class="title usmall"><a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R3' | translate }}</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <!-- <article class="item">
                            <a routerLink="/blog-details"
                                class="thumb">
                                <span class="fullimage cover bg3"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">{{ 'BLOG.R8' | translate }}</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article> -->
                    </section>



                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R2' | translate }}</a></li>
                            <li><a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R3' | translate }}</a></li>
                            <!-- <li><a routerLink="/blog-details">{{ 'BLOG.R8' | translate }}</a></li> -->
                        </ul>
                    </section>


                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->
