import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';

@Component({
  selector: 'ForeignIncome-form-control',
  templateUrl: './ForeignIncome-form-control.component.html',
  styleUrls: ['./ForeignIncome.component.scss']

})
export class ForeignIncomeFormControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }

    let IsFollowProperBankingChannel = this.form.controls["IsFollowProperBankingChannel"].value;


    if (IsFollowProperBankingChannel == "false")
    {
      IsFollowProperBankingChannel = false
    }
    if (IsFollowProperBankingChannel == "true")
    {
      IsFollowProperBankingChannel = true
    }
    if (IsFollowProperBankingChannel != null)
    {
      this.form.patchValue({
        IsFollowProperBankingChannel: IsFollowProperBankingChannel
      });
    }
  }
}
