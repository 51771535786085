import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import
{
  RouterModule, //Routes
} from '@angular/router';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
// import { MatButtonModule } from '@angular/material/button';
// import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

// import { FuseModule } from '@fuse/fuse.module';
// import { FuseSharedModule } from '@fuse/shared.module';
// import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

// import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
// import { LayoutModule } from 'app/layout/layout.module';

import { appRoutes } from './app.routes';
import { AuthGuard, AuthInterceptor, AuthenticationService } from 'app/auth';

// import { LoginModule } from 'app/pages/user-auth/login/login.module';
// import { RegisterModule } from 'app/pages/user-auth/register/register.module';
// import { ForgotPasswordModule } from 'app/pages/user-auth/forgot-password/forgot-password.module';
// import { ResetPasswordModule } from 'app/pages/user-auth/reset-password/reset-password.module';
// import { MailConfirmModule } from 'app/pages/user-auth/mail-confirm/mail-confirm.module';
// import { MailVerificationModule } from './pages/user-auth/mail-verification/mail-verification.module';

import { EtinOthersModule } from './pages/Basic-info/etin-others/Etin-Others.module';
import { PersonalInfoModule } from './pages/Basic-info/personal-information/Personal-Information.module';
import { PersonalizeReturnModule } from './pages/Basic-info/Personalize-Return/Personalize-Return.module';

import { SalaryModule } from './pages/Income/salary/Salary.module';
import { InterestOnSecuritiesModule } from './pages/Income/Interest-On-Securities/InterestOnSecurities.module';
import { PropertyModule } from './pages/Income/Property/Property.module';
import { AgricultureModule } from './pages/Income/Agriculture/Agriculture.module';
import { BuisnessOrProfessionModule } from './pages/Income/Buisness-Or-Profession/BuisnessOrProfession.module';
import { ShareofProfitInaFirmModule } from './pages/Income/Share-of-Profit-In-a-Firm/ShareofProfitInaFirm.module';
import { SpouseOrChildModule } from './pages/Income/Spouse-Or-Child/SpouseOrChild.module';
import { CapitalGainsModule } from './pages/Income/Capital-Gains/CapitalGains.module';
import { OtherResourcesModule } from './pages/Income/Other-Resources/OtherResources.module';
import { ForeignIncomeModule } from './pages/Income/Foreign-Income/ForeignIncome.module';

import { TaxRebateModule } from './pages/Tax-Rebate-Adjustment/Tax-Rebate/TaxRebate.module';
import { TaxDeductedAtSourceModule } from './pages/Tax-Rebate-Adjustment/Tax-Deducted-At-Source/TaxDeductedAtSource.module';
import { AdvancePaidTaxModule } from './pages/Tax-Rebate-Adjustment/Advance-Paid-Tax/AdvancePaidTax.module';
import { AdjustmentofTaxRefundModule } from './pages/Tax-Rebate-Adjustment/Adjustment-of-Tax-Refund/AdjustmentofTaxRefund.module';





import { Service } from './services';
import { AgriculturalPropertyModule } from './pages/Assets/Agricultural-property/AgriculturalProperty.module';
import { InvestmentsModule } from './pages/Assets/Investments/Investments.module';
import { MotorVehicleModule } from './pages/Assets/Motor-vehicle/MotorVehicle.module';
import { FurnitureModule } from './pages/Assets/Furniture/Furniture.module';
import { JewelleryModule } from './pages/Assets/Jewellery/Jewellery.module';
import { ElectronicEquipmentModule } from './pages/Assets/Electronic-equipment/ElectronicEquipment.module';
import { CashAssetsModule } from './pages/Assets/Cash-assets/CashAssets.module';
import { OtherAssetsModule } from './pages/Assets/Other-assets/OtherAssets.module';
import { OtherAssetsReceiptModule } from './pages/Assets/Other-assets-receipt/OtherAssetsReceipt.module';
import { PreviousYearNetWealthModule } from './pages/Assets/Previous-year-net-wealth/PreviousYearNetWealth.module';
import { MortgagesModule } from './pages/Liabilities/Mortgages/Mortgages.module';
import { UnsecuredLoansModule } from './pages/Liabilities/Unsecured-loans/UnsecuredLoans.module';
import { BankLoansModule } from './pages/Liabilities/Bank-loans/BankLoans.module';
import { OtherLiabilitiesModule } from './pages/Liabilities/Other-liabilities/OtherLiabilities.module';
import { FoodClothingOtherEssentialsModule } from './pages/Expenses/Food-clothing-other-essentials/FoodClothingOtherEssentials.module';
import { HousingModule } from './pages/Expenses/Housing/Housing.module';
import { AutoTransportationModule } from './pages/Expenses/Auto-transportation/AutoTransportation.module';
import { HouseholdUtilityModule } from './pages/Expenses/Household-utility/HouseholdUtility.module';
import { ChildrensEducationModule } from './pages/Expenses/Childrens-education/ChildrensEducation.module';
import { SpecialExpensesModule } from './pages/Expenses/Special-expenses/SpecialExpenses.module';
import { OtherExpensesModule } from './pages/Expenses/Other-expenses/OtherExpenses.module';
import { PaymentTaxChargesModule } from './pages/Expenses/Payment-tax-charges/PaymentTaxCharges.module';
import { PaymentModule } from './pages/Payment/Payment.module';
import { DocumentsModule } from './pages/Documents/Documents.module';
import { EtinOthersListModule } from './pages/Basic-info/etin-others-list/Etin-Others-List.module';
import { PersonalInfoListModule } from './pages/Basic-info/personal-information-list/Personal-Information-List.module';

// import { PersonalizeReturnListModule } from './pages/Basic-info/Personalize-Return-List/Personalize-Return-List.module';

import { SalaryListModule } from './pages/Income/salary-list/Salary-List.module';
import { InterestOnSecuritiesListModule } from './pages/Income/Interest-On-Securities-List/InterestOnSecurities-List.module';
import { PropertyListModule } from './pages/Income/Property-List/Property-List.module';
import { AgricultureListModule } from './pages/Income/Agriculture-list/Agriculture-list.module';
import { BuisnessOrProfessionListModule } from './pages/Income/Buisness-Or-Profession-list/Buisness-Or-Profession-list.module';
import { ShareofProfitInaFirmListModule } from './pages/Income/Share-of-Profit-In-a-Firm-List/Share-of-Profit-In-a-Firm-List.module';
import { SpouseOrChildListModule } from './pages/Income/Spouse-Or-Child-List/Spouse-Or-Child-List.module';
import { CapitalGainsListModule } from './pages/Income/Capital-Gains-list/Capital-Gains-list.module';
import { ForeignIncomeListModule } from './pages/Income/Foreign-Income-list/Foreign-Income-list.module';

import { TaxRebateListModule } from './pages/Tax-Rebate-Adjustment/Tax-Rebate-list/Tax-Rebate-list.module';
import { TaxDeductedAtSourceListModule } from './pages/Tax-Rebate-Adjustment/Tax-Deducted-At-Source-list/Tax-Deducted-At-Source-list.module';
import { AdvancePaidTaxListModule } from './pages/Tax-Rebate-Adjustment/Advance-Paid-Tax-list/Advance-Paid-Tax-list.module';
import { AdjustmentofTaxRefundListModule } from './pages/Tax-Rebate-Adjustment/Adjustment-of-Tax-Refund-list/Adjustment-of-Tax-Refund-list.module';

import { BankLoansListModule } from './pages/Liabilities/Bank-loans-list/Bank-loans-list.module';
import { OtherResourcesListModule } from './pages/Income/Other-Resources-list/Other-Resources-list.module';
import { MortgagesListModule } from './pages/Liabilities/Mortgages-list/Mortgages-list.module';
import { OtherLiabilitiesListModule } from './pages/Liabilities/Other-liabilities-list/Other-liabilities-list.module';
import { UnsecuredLoansListModule } from './pages/Liabilities/Unsecured-loans-list/Unsecured-loans.module';
import { InvestmentsListModule } from './pages/Assets/Investments-list/Investments-list.module';
import { JewelleryListModule } from './pages/Assets/Jewellery-list/Jewellery-list.module';
import { ElectronicEquipmentListModule } from './pages/Assets/Electronic-equipment-list/Electronic-equipment-list.module';
import { FurnitureListModule } from './pages/Assets/Furniture-list/Furniture-list.module';
import { OtherAssetsListModule } from './pages/Assets/Other-assets-list/Other-assets-list.module';
import { OtherAssetsReceiptListModule } from './pages/Assets/Other-assets-receipt-list/Other-assets-receipt-list.module';
import { PreviousYearNetWealthListModule } from './pages/Assets/Previous-year-net-wealth-list/Previous-year-net-wealth-list.module';
import { AgriculturalPropertyListModule } from './pages/Assets/Agricultural-property-list/Agricultural-property-list.module';
import { NonAgriculturalPropertyListModule } from './pages/Assets/Non-agricultural-property-list/Non-agricultural-property-list.module';
import { NonAgriculturalPropertyModule } from './pages/Assets/Non-agricultural-property/Non-agriculture-property.module';
import { CashAssetsListModule } from './pages/Assets/Cash-assets-list/Cash-assets-list.module';
import { MotorVehicleListModule } from './pages/Assets/Motor-vehicle-list/Motor-vehicle-list.module';

import { DirectorsShareholdingModule } from './pages/Assets/Director-share-holding/Director-share-holding.module';
import { BuisnessCapitalModule } from './pages/Assets/Buisness-capital/Buisness-Capital.module';
import { BuisnessCapitalListModule } from './pages/Assets/Buisness-capital-list/Buisness-capital-list.module';
import { DirectorsShareholdingListModule } from './pages/Assets/Director-share-holding-list/Director-share-holding-list.module';

// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoaderService } from './services/loader.service';
import { TaxReturnModule } from './pages/reports/tax-return/tax-return.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { EventEmitterService } from './services/eventemiter.service';

import { SalaryGovtModule } from './pages/Income/salary-govt/Salary-govt.module';
import { FoodClothingOtherEssentialsListModule } from './pages/Expenses/Food-clothing-other-essentials-list/FoodClothingOtherEssentials-list.module';
import { ChildrensEducationListModule } from './pages/Expenses/Childrens-education-list/ChildrensEducation-list.module';
import { HousingListModule } from './pages/Expenses/Housing-list/Housing-list.module';
import { AutoTransportationListModule } from './pages/Expenses/Auto-transportation-list/Auto-transportation-list.module';
import { HouseholdUtilityListModule } from './pages/Expenses/Household-utility-list/Household-utility-list.module';
import { SpecialExpensesListModule } from './pages/Expenses/Special-expenses-list/Special-expenses-list.module';
import { OtherExpensesListModule } from './pages/Expenses/Other-expenses-list/Other-expenses-list.module';
import { PaymentTaxChargesListModule } from './pages/Expenses/Payment-tax-charges-list/Payment-tax-charges-list.module';
import { DashboardModule } from './pages/Dashboard/Dashboard.module';

import { FuseAdminModule } from './layout/admin/fuseadmin.module';
import { HomeLayoutModule } from './layout/home/homelayout.module';
import { HomeModule } from './pages/home/home/Home.module';
import { AboutModule } from './pages/home/about/About.module';
import { BlogModule } from './pages/home/blog/blog.module';


import { ContactModule } from './pages/home/contact/contact.module';
import { FaqModule } from './pages/home/faq/faq.module';
import { HiwModule } from './pages/home/how-it-works/how-it-works.module';
import { NewTaxpayerModule } from './pages/home/new-taxpayer/new-taxpayer.module';
import { PricingModule } from './pages/home/pricing/pricing.module';
import { SalaryEmitterService } from './services/salaryEmiter.service';
import { BackButtonService } from './services/BackButtonService';
import { LoginHomeModule } from './pages/home/home-auth/login-home/login-home.module';
import { RegisterHomeModule } from './pages/home/home-auth/register-home/register-home.module';
import { ForgotPasswordHomeModule } from './pages/home/home-auth/forgot-password-home/forgot-password-home.module';
import { SidePanelSharedService } from './services/SidePanelShared.service';
import { SidePanelDeleteService } from './services/sidePanelDelete.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MailConfirmHomeModule } from './pages/home/home-auth/mail-confirm-home/mail-confirm-home.module';
import { MailVerificationHomeModule } from './pages/home/home-auth/mail-verification-home/mail-verification-home.module';
import { ResetPasswordHomeModule } from './pages/home/home-auth/reset-password-home/reset-password-home.module';
import { TaxReturnPrivateModule } from './pages/home/tax-return-pri/tax-return-pri.module';
import { TaxReturnGovtmentModule } from './pages/home/tax-return-gov/tax-return-gov.module';
import { LossDeductionsExpensesListModule } from './pages/Expenses/Loss-deductions-expenses-list/Loss-deductions-expenses-list.module';
import { LossDeductionsExpensesModule } from './pages/Expenses/Loss-deductions-expenses/LossDeductionsExpenses.module';
import { GiftDonationContributionModule } from './pages/Expenses/Gift-Donation-Contribution/Gift-Donation-Contribution.module';
import { GiftDonationContributionListModule } from './pages/Expenses/Gift-Donation-Contribution-list/Gift-Donation-Contribution-list.module';
import { ProfileSaveModule } from './pages/Profile/Profile-Save/ProfileSave.module';
import { ProfileListModule } from './pages/Profile/Profile-List/ProfileList.module';
import { ClientsListModule } from './pages/Manage-Clients/Clients-List/ClientsList.module';
import { ClientsSaveModule } from './pages/Manage-Clients/Clients-Save/ClientsSave.module';
import { ViewDashboardGuard } from './auth/viewDashboard.guard';
import { ViewClientsGuard } from './auth/viewClients.guard';
import { ViewProfileGuard } from './auth/viewProfile.guard';
import { FetchClientsService } from './services/fetchClients.service';
import { PrivacyPolicyModule } from './pages/home/privacy-policy/privacy-policy.module';
import { ReturnRefundPolicyModule } from './pages/home/return-and-refund-policy/return-and-refund-policy.module';
import { TermsConditionsModule } from './pages/home/terms-and-conditions/terms-and-conditions.module';
import { PasswordConfirmHomeModule } from './pages/home/home-auth/password-confirm-home/password-confirm-home.module';
import { ReloadPageService } from './services/reloadPage.service';
import { ProPaymentModule } from './pages/Pro-Payment/ProPayment.module';
import { viewProPayment } from './auth/viewProPayment.guard';
import { MyAccountModule } from './pages/my-account/my-account.module';
import { ChangeImageService } from './services/changeImage.service';
import { ShowNoClient } from './services/showNoClient.service';
import { NewsEventsModule } from './pages/home/news-events/news-events.module';
import { ArchiveModule } from './pages/Archive/Archive.module';
import { TransactionModule } from './pages/Transaction/Transaction.module';
import { ViewArchiveGuard } from './auth/viewArchive.guard';
import { NotificationModule } from './pages/Notification/Notification.module';


// const appRoutes: Routes = [
//     {
//         path: '**',
//         redirectTo: 'login'
//     },

// ];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),
    NgxSpinnerModule,

    HomeLayoutModule,
    HomeModule,
    AboutModule,
    BlogModule,
    ContactModule,
    FaqModule,
    HiwModule,
    NewTaxpayerModule,
    PricingModule,
    TaxReturnPrivateModule,
    TaxReturnGovtmentModule,
    PrivacyPolicyModule,
    ReturnRefundPolicyModule,
    TermsConditionsModule,
    NewsEventsModule,


    LoginHomeModule,
    RegisterHomeModule,
    ForgotPasswordHomeModule,
    MailConfirmHomeModule,
    MailVerificationHomeModule,
    ResetPasswordHomeModule,
    PasswordConfirmHomeModule,
    // Material moment date module
    // MatMomentDateModule,
    // MatProgressSpinnerModule,
    // Material
    //  MatButtonModule,
    // MatIconModule,

    // Fuse modules
    // FuseModule.forRoot(fuseConfig),
    // FuseProgressBarModule,
    // FuseSharedModule,
    // FuseSidebarModule,
    // FuseThemeOptionsModule,

    //  NgxSpinnerModule,

    FuseAdminModule,
    // App modules
    // LayoutModule,


    // AUTH
    // LoginModule,
    // RegisterModule,
    // ForgotPasswordModule,
    // ResetPasswordModule,
    // MailConfirmModule,
    // MailVerificationModule,

    // BASIC
    EtinOthersModule,
    PersonalInfoModule,
    PersonalizeReturnModule,

    EtinOthersListModule,
    PersonalInfoListModule,
    // PersonalizeReturnListModule,

    // INCOME
    SalaryModule,
    SalaryGovtModule,
    SalaryListModule,
    InterestOnSecuritiesModule,
    InterestOnSecuritiesListModule,
    PropertyModule,
    PropertyListModule,
    AgricultureModule,
    AgricultureListModule,
    BuisnessOrProfessionModule,
    BuisnessOrProfessionListModule,
    ShareofProfitInaFirmModule,
    ShareofProfitInaFirmListModule,
    SpouseOrChildModule,
    SpouseOrChildListModule,
    CapitalGainsModule,
    CapitalGainsListModule,
    OtherResourcesModule,
    OtherResourcesListModule,
    ForeignIncomeModule,
    ForeignIncomeListModule,

    // TAX
    TaxRebateModule,
    TaxRebateListModule,
    TaxDeductedAtSourceModule,
    TaxDeductedAtSourceListModule,
    AdvancePaidTaxModule,
    AdvancePaidTaxListModule,
    AdjustmentofTaxRefundModule,
    AdjustmentofTaxRefundListModule,


    //ASSETS
    BuisnessCapitalModule,
    BuisnessCapitalListModule,
    NonAgriculturalPropertyModule,
    NonAgriculturalPropertyListModule,
    AgriculturalPropertyModule,
    AgriculturalPropertyListModule,
    InvestmentsModule,
    InvestmentsListModule,
    MotorVehicleModule,
    MotorVehicleListModule,
    FurnitureModule,
    FurnitureListModule,
    JewelleryModule,
    JewelleryListModule,
    DirectorsShareholdingModule,
    DirectorsShareholdingListModule,
    ElectronicEquipmentModule,
    ElectronicEquipmentListModule,
    CashAssetsModule,
    CashAssetsListModule,
    OtherAssetsModule,
    OtherAssetsListModule,
    OtherAssetsReceiptModule,
    OtherAssetsReceiptListModule,
    PreviousYearNetWealthModule,
    PreviousYearNetWealthListModule,

    //LIABILITIES
    MortgagesModule,
    MortgagesListModule,
    UnsecuredLoansModule,
    UnsecuredLoansListModule,
    BankLoansModule,
    BankLoansListModule,
    OtherLiabilitiesModule,
    OtherLiabilitiesListModule,

    //EXPENSES
    FoodClothingOtherEssentialsModule,
    FoodClothingOtherEssentialsListModule,
    HousingModule,
    HousingListModule,
    AutoTransportationModule,
    AutoTransportationListModule,
    HouseholdUtilityModule,
    HouseholdUtilityListModule,
    ChildrensEducationModule,
    ChildrensEducationListModule,
    SpecialExpensesModule,
    SpecialExpensesListModule,
    OtherExpensesModule,
    OtherExpensesListModule,
    PaymentTaxChargesModule,
    PaymentTaxChargesListModule,
    LossDeductionsExpensesModule,
    LossDeductionsExpensesListModule,
    GiftDonationContributionModule,
    GiftDonationContributionListModule,

    //PAYMENT
    PaymentModule,
    ProPaymentModule,


    //DOCUMENT
    DocumentsModule,

    //DASHBOARD
    DashboardModule,


    //report
    TaxReturnModule,


    // PROFILE
    ProfileSaveModule,
    ProfileListModule,

    MyAccountModule,

    // CLIENTS
    ClientsListModule,
    ClientsSaveModule,

    //ARCHIVE
    ArchiveModule,

    //TRANSACTION
    TransactionModule,

    //NOTIFICATION
    NotificationModule,

    NgCircleProgressModule.forRoot()

  ],
  providers: [
    Service,
    EventEmitterService,
    SidePanelSharedService,
    BackButtonService,
    SalaryEmitterService,
    SidePanelDeleteService,
    FetchClientsService,
    ChangeImageService,
    ShowNoClient,
    ReloadPageService,
    AuthenticationService,
    AuthGuard,
    ViewDashboardGuard,
    ViewClientsGuard,
    ViewArchiveGuard,
    ViewProfileGuard,
    viewProPayment,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule
{
}
