
export class AgricultureClass
{
  constructor(
    private _ProductionCost: number,
    private _NetIncome: number,
    private _GrossRevenue: number,
    private _IsAccountsRecordMaintened: string,
  )
  {

  }

  public get IsAccountsRecordMaintened(): string
  {
    return this._IsAccountsRecordMaintened;
  }
  public set IsAccountsRecordMaintened(x: string)
  {
    this._IsAccountsRecordMaintened = x;
  }


  public get NetIncome(): number
  {
    let ni = null;
    if (this.IsAccountsRecordMaintened == "" || this.IsAccountsRecordMaintened == "2" || this.IsAccountsRecordMaintened == "false")
    {
      ni = (Number(this.GrossRevenue) * .4).toFixed(2);
    }

    if (this.IsAccountsRecordMaintened == "1" || this.IsAccountsRecordMaintened == "true")
    {
      ni = Number(this.GrossRevenue) - Number(this.ProductionCost)
    }
    return this._NetIncome = ni;
  }
  public set NetIncome(x: number)
  {
    this._NetIncome = x;
  }


  public get GrossRevenue(): number
  {
    return this._GrossRevenue;
  }
  public set GrossRevenue(x: number)
  {
    this._GrossRevenue = x;
  }


  public get ProductionCost(): number
  {
    let pc = (Number(this.GrossRevenue) * .6).toFixed(2);

    if (this.IsAccountsRecordMaintened == "" || this.IsAccountsRecordMaintened == "2" || this.IsAccountsRecordMaintened == "false")
    {
      return this._ProductionCost = Number(pc);
    }

    if (this.IsAccountsRecordMaintened == "1" || this.IsAccountsRecordMaintened == "true")
    {
      return this._ProductionCost;
    }
  }
  public set ProductionCost(x: number)
  {
    this._ProductionCost = x;
  }
}
