import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsListService } from '../ClientsList.service';
import Config from 'app/util/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';



@Component({
    selector: 'BulkSalaryUploadDocumentDialog',
    templateUrl: 'bulk-salary-Dialog.component.html',
    styleUrls: ['./bulk-salary-Dialog.component.scss'],
})
export class BulkSalaryUploadDocumentDialog
{

    public files: any[] = [];
    FileBase64String: any;
    FileName: any;
    lang = '';
    constructor(
        public dialogRef: MatDialogRef<BulkSalaryUploadDocumentDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: ClientsListService,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');

    }

    onFocusoutEvent()
    {
        let Comments = (<HTMLInputElement>document.getElementById('Comments')).value;

        // console.log(Comments, 'Comments');


    }

    OnSubmit(): void
    {
        this._spinner.show();
        if (!Utils.isEmptyObject(this.files))
        {
            let obj = {
                "Id": "0",
                "DocumentId": "0",
                "FileName": this.FileName,
                "FileBase64String": this.FileBase64String
            };
            // console.log(obj, 'obj');
            // let stringed = JSON.stringify(obj);
            this._formControlService.BULK_SALARY_UPLOAD(obj)
                .subscribe((data: any) =>
                {
                    // console.log('data', data);

                    if (data != null)
                    {
                        this._spinner.hide();
                    }

                    if (data.isError === false)
                    {
                        console.log('submit succesfull');
                    }
                    else
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                    }
                    if (data.isError === false && data.responseCode == "200")
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                        this.dialogRef.close("yes");
                    }
                }, (error: any) =>
                {
                    this._spinner.hide();
                    console.log(error, 'error');
                });
        }
        else
        {
            this._matSnackBar.open("Please attach a file!", 'Close', {
                verticalPosition: 'top',
                duration: 2000,
            });
        }
    }

    onNoClick(): void
    {
        this.dialogRef.close("no");
    }

    onFileChange(pFileList: File[])
    {
        this.files = Object.keys(pFileList).map(key => pFileList[key]);

        const file = this.files[0];

        const reader = new FileReader();
        console.log(file, 'file');
        if (file.size < 10485760)
        {
            reader.readAsDataURL(file);
            reader.onload = () =>
            {
                this.FileBase64String = (<string>reader.result).split(',')[1];
                // console.log(this.FileBase64String);
                this.FileName = file.name;
            };
        } else
        {
            this.files = null;
            this._matSnackBar.open("File Size Cant exceed 10 Megabtyes", 'Close', {
                verticalPosition: 'top',
                duration: 2000,
            });
        }

        // console.log(this.FileBase64String);
        // console.log(this.FileName);
    }

    deleteFile(f)
    {
        this.files = this.files.filter(function (w) { return w.name != f.name });
        this._matSnackBar.open("File deleted!", 'Close', {
            verticalPosition: 'top',
            duration: 2000,
        });
    }

    deleteFromArray(index)
    {
        // console.log(this.files);
        this.files.splice(index, 1);
    }

    downloadTemplate(): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.BULK_SALARY_TEMPLATE_DL, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                // console.log(res, 'res');
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, 'Bulk Salary Template');
                }
                this._spinner.hide();

                // const fileURL = URL.createObjectURL(res);


                // window.open(fileURL, '_blank');
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');

            })
    }

}
