import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[NumbersOnly]' })


export class NumbersOnly
{
    @Input("decimals") decimals: number = 0;
    @Input("negative") negative: number = 0;
    @Input("separator") separator: string = ".";

    private checkAllowNegative(value: string)
    {
        if (this.decimals <= 0)
        {
            return String(value).match(new RegExp(/^-?\d+$/));
        } else
        {
            var regExpString =
                "^-?\\s*((\\d+(\\" + this.separator + "\\d{0," +
                this.decimals +
                "})?)|((\\d*(\\" + this.separator + "\\d{1," +
                this.decimals +
                "}))))\\s*$";
            return String(value).match(new RegExp(regExpString));
        }
    }

    private check(value: string)
    {
        if (this.decimals <= 0)
        {
            return String(value).match(new RegExp(/^\d+$/));
        } else
        {
            var regExpString =
                "^\\s*((\\d+(\\" + this.separator + "\\d{0," +
                this.decimals +
                "})?)|((\\d*(\\" + this.separator + "\\d{1," +
                this.decimals +
                "}))))\\s*$";
            return String(value).match(new RegExp(regExpString));
        }
    }

    private run(oldValue)
    {
        setTimeout(() =>
        {
            let currentValue: string = this.el.nativeElement.value;
            let allowNegative = this.negative > 0 ? true : false;

            if (allowNegative)
            {
                if (
                    !["", "-"].includes(currentValue) &&
                    !this.checkAllowNegative(currentValue)
                )
                {
                    // if (isNaN(Number(currentValue)) && Number(currentValue) < 0)
                    // {
                    //     oldValue = 0;
                    // }
                    // this.el.nativeElement.value = oldValue;
                    this.el.nativeElement.value = '0';



                }
            } else
            {
                if (currentValue !== "" && !this.check(currentValue))
                {
                    // let isN = isNaN(Number(currentValue));
                    // let sZ = Number(currentValue) < 0;
                    // if (isN == true && sZ == false)
                    // {
                    //     oldValue = 0;
                    // }

                    // this.el.nativeElement.value = oldValue;
                    this.el.nativeElement.value = '0';

                }
            }
        });
    }

    constructor(private el: ElementRef) { }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent)
    {
        this.run(this.el.nativeElement.value);
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent)
    {
        this.run(this.el.nativeElement.value);
    }
}
