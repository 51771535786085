<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">
            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'DOCUMENTS.HEADER' | translate }}
            </span>
        </div>

        <div class="content p-24">

            <!-- <span class="h2"
                style="border-bottom: 1px solid #E1E1E1;">
                Documents List
            </span> -->

            <div fxLayout="column"
                fxLayoutAlign="start">
                <!-- <span class="h4"
                    style="margin-top: 5px;">
                    As per your answers, to submit the return form, the red icon marked rows files are recommended
                    documents for you.
                </span> -->

                <div style="margin-top: 20px;"
                    fxLayout="column wrap"
                    fxLayoutAlign="start">
                    <span class="h5">
                        {{ 'DOCUMENTS.HEADER_1' | translate }}
                    </span>
                    <!-- <span class="h5">
                        ** click the arrow icon beside the upload file to view example.
                    </span> -->
                </div>
            </div>
            <div style="margin-top: 12px !important;"
                fxLayout="column"
                fxLayoutAlign="start">
                <div fxLayout="column"
                    fxLayoutAlign="space-between"
                    fxLayout.gt-md="row"
                    style="padding: 10px;">
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <span class="h4 text-bold"> {{ 'DOCUMENTS.FILE_NAME' | translate }}
                        </span>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="border: 1px solid;border-radius: 28px;padding: 0 18px;margin: 0 6px;">
                            <mat-icon>search</mat-icon>
                            <input [(ngModel)]="searchTerm"
                                (input)="filterCoursesByTerm()"
                                style="width: 100%;height: 36px;min-height: 36px;max-height: 36px;padding: 0 16px;border: none;outline: none;line-height: 36px;"
                                placeholder="{{ 'DOCUMENTS.SEARCH_1' | translate }}">
                            <button style="background-color: transparent; border:0; cursor: pointer;"
                                (click)="clearSearch()">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>

                    </div>
                    <div fxLayout="row"
                        *ngIf="fillteredData.length>0"
                        fxLayoutAlign="start center"
                        style="margin: 10px;margin-right: 0;">

                        <button mat-raised-button
                            id='recbutton'
                            (click)="switchData('rec')"
                            style="border-radius: 15px; margin-right: 10px;"
                            class="mat-button mat-button-base dupl mt-md-0">
                            <span>Recommended</span>
                        </button>
                        <button mat-raised-button
                            id='allbutton'
                            (click)="switchData('all')"
                            style="border-radius: 15px; margin-left: 10px;"
                            class="mat-button mat-button-base primary-A400 secondary-text mt-md-0">
                            <span>ALL</span>
                        </button>
                    </div>
                </div>
                <mat-accordion class="example-headers-align"
                    [togglePosition]="'before'"
                    style="height: max-content !important;">
                    <mat-expansion-panel #matExpansionPanel1
                        *ngFor="let value of shownData"
                        style="height: max-content !important;">
                        <mat-expansion-panel-header style="height: max-content !important; padding: 0 6px !important;"
                            (click)="expandPanel(matExpansionPanel1, $event)">
                            <mat-panel-title fxLayout="row"
                                fxLayoutAlign="start center">
                                <h3 fxLayout="row"
                                    fxLayoutAlign="start center">{{value.DocumentName}} &nbsp;<h3
                                        *ngIf="value.Items.length!=0"
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        style="color: #444444; font-weight: bold;">
                                        <mat-icon class="file"
                                            style="color: #29793C">attach_file</mat-icon>{{value.Items.length}}
                                    </h3>
                                </h3>

                            </mat-panel-title>
                            <mat-panel-description fxLayout="row"
                                fxLayoutAlign="end center"
                                style="width: 120px !important; max-width: 120px !important; min-width: 120px !important;">
                                <button (click)="openUploadDialog(value.Id)"
                                    style="background-color: #37793c; border: 0; border-radius: 5px; border-top-right-radius: 5px; border-bottom-right-radius: 5px; color: #ffffff; padding-left: 0; margin-right: 2px; cursor: pointer; padding-top: 2px; padding-bottom: 2px;">
                                    <span fxLayout="row"
                                        fxLayoutAlign="center center"
                                        style="padding: 0;margin: 0;">
                                        <mat-icon style="color: #ffffff;">arrow_upward
                                        </mat-icon> <span>Upload</span>
                                    </span>
                                </button>
                                <!-- <button [matMenuTriggerFor]="userMenu"
                                    style="background-color: #126CBF; border: 0; border-radius: 5px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px; cursor: pointer;">
                                    <mat-icon style="color: #ffffff;">keyboard_arrow_down
                                    </mat-icon>
                                </button>
                                <mat-menu #userMenu="matMenu"
                                    [overlapTrigger]="false">

                                    <button mat-menu-item>
                                        <span style="font-weight: bold;">Example</span>
                                    </button>

                                </mat-menu> -->
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-expansion-panel-body fxLayout="column"
                            fxFlex="100"
                            fxLayoutAlign="start"
                            style="overflow-y: scroll !important;">
                            <table class="simple invoice-table"
                                *ngIf="value.Items.length>0">
                                <thead>
                                    <tr>
                                        <th class="thNewline"
                                            style="color: #444444;">
                                            {{ 'DOCUMENTS.FILE_TH' | translate }}
                                        </th>
                                        <th class="thNewline"
                                            style="color: #444444;">
                                            {{ 'DOCUMENTS.FILE_SIZE_TH' | translate }}
                                        </th>
                                        <th class="thNewline"
                                            style="color: #444444;">
                                            {{ 'DOCUMENTS.UPLOAD_DATE_TH' | translate }}

                                        </th>
                                        <th class="thNewline"
                                            style="color: #444444;">
                                            {{ 'DOCUMENTS.COMMENTS_TH' | translate }}

                                        </th>
                                        <th class="thNewline"
                                            style="color: #444444;">
                                            {{ 'DOCUMENTS.ACTION_TH' | translate }}

                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let data of value.Items">
                                        <td>
                                            {{data.FilePath}}
                                        </td>
                                        <td>
                                            {{data.FileSize}}
                                        </td>
                                        <td>
                                            {{data.CreateTime}}
                                        </td>
                                        <td>
                                            {{data.Comments}}
                                        </td>
                                        <td>
                                            <div fxLayout="row wrap"
                                                fxLayoutAlign="start center">
                                                <a style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                                    fxLayout="column wrap"
                                                    (click)="openEditDialog(data.Id,data.Comments)"
                                                    matTooltip="{{ 'COMMON.BUTTON_EDIT' | translate }}">
                                                    <mat-icon class="nav-link-icon">create</mat-icon>
                                                </a>
                                                <a style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                                    fxLayout="column wrap"
                                                    (click)="CheckDelete(data.Id)"
                                                    matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                                    <mat-icon class="nav-link-icon">delete</mat-icon>
                                                </a>
                                                <a style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                                    fxLayout="column wrap"
                                                    (click)="DownloadFile(data.FilePath)"
                                                    matTooltip="{{ 'DOCUMENTS.DOC_DL_FILE' | translate }}">
                                                    <mat-icon class="nav-link-icon">arrow_downward</mat-icon>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div *ngIf="value.Items.length==0">
                                <h3 style="padding-left: 24px;">{{ 'DOCUMENTS.FILE_N' | translate }}</h3>
                            </div>
                        </mat-expansion-panel-body>

                        <!-- <div fxLayout="row"
                            fxFlex="100"
                            fxLayoutAlign="space-between center"
                            style="height: max-content !important;">
                            <div fxLayout="column"
                                fxFlex="100"
                                fxLayoutAlign="start">
                                <table class="simple invoice-table"
                                    *ngIf="value.Items.length>0">
                                    <thead>
                                        <tr>
                                            <th class="thNewline"
                                                style="color: #444444;">
                                                Files
                                            </th>
                                            <th class="thNewline"
                                                style="color: #444444;">
                                                File Size
                                            </th>
                                            <th class="thNewline"
                                                style="color: #444444;">
                                                Upload Date
                                            </th>
                                            <th class="thNewline"
                                                style="color: #444444;">
                                                Comments
                                            </th>
                                            <th class="thNewline"
                                                style="color: #444444;">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let data of value.Items">
                                            <td>
                                                {{data.FilePath}}
                                            </td>
                                            <td>
                                                {{data.FileSize}}
                                            </td>
                                            <td>
                                                {{data.CreateTime}}
                                            </td>
                                            <td>
                                                {{data.Comments}}
                                            </td>
                                            <td>
                                                <div fxLayout="row wrap"
                                                    fxLayoutAlign="start center">
                                  

                                                    <a style="cursor: pointer; border: 0; color: #29793C;"
                                                        fxLayout="column wrap"
                                                        (click)="CheckDelete(data.Id)"
                                                        matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                                        <mat-icon class="nav-link-icon">delete</mat-icon>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="value.Items.length==0">
                                    <h3>No File Uploaded</h3>
                                </div>
                            </div>
                        </div> -->
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>
