import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Layouts } from './enum/layouts.enum';
import { navigation } from 'app/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent
{
  Layouts = Layouts;
  layout: Layouts;
  // navigation: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseNavigationService: FuseNavigationService,

  )
  {
    // this.navigation = navigation;
  }

  // We can't use `ActivatedRoute` here since we are not within a `router-outlet` context yet.
  ngOnInit()
  {
    this.router.events.subscribe((data) =>
    {
      if (data instanceof RoutesRecognized)
      {
        this.layout = data.state.root.firstChild.data.layout;
        if (this.layout == Layouts.Admin)
        {
          this.loadFuseAssets();
          // this._fuseNavigationService.unregister('main');
        } else
        {
          this.loadHomeAssets();
          // this._fuseNavigationService.unregister('main');
        }

      }
    });
  }

  loadStyle(styleName: string, styleId: string)
  {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      styleId
    ) as HTMLLinkElement;
    if (themeLink)
    {
      // themeLink.href = styleName;
    } else
    {
      const style = this.document.createElement('link');
      style.id = styleId;
      style.rel = 'stylesheet';
      style.type = "text/css";
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  loadScript(scriptName: string, scriptId: string)
  {
    const head = this.document.getElementsByTagName('head')[0];

    let scriptLink = this.document.getElementById(
      scriptId
    ) as HTMLLinkElement;
    if (scriptLink)
    {
      // scriptLink.href = scriptName;
    } else
    {
      const script = this.document.createElement('script');
      script.id = scriptId;
      script.type = 'text/javascript';
      script.async = false;
      script.src = `${scriptName}`;
      head.appendChild(script);
    }
  }


  loadHomeAssets(): void
  {
    this.loadStyle("bootstrap-scss.css", "bootstrap-scss-style");
    this.loadStyle("flaticon-css.css", "flaticon-css-style");
    this.loadStyle("fontawesome-css.css", "fontawesome-css-style");
    this.loadStyle("home-styles-css.css", "home-styles-css-style");
    this.loadStyle("responsive-css.css", "responsive-css-style");
    this.loadScript("jquery-script.js", "jquery-script");
    this.loadScript("bootstrap-script.js", "bootstrap-script");
    this.loadScript("custom-script.js", "custom-script");

    
    var fusemainstyle = document.getElementById("fuse-main-style");
    if (fusemainstyle)
    {
      fusemainstyle.parentNode.removeChild(fusemainstyle);
    }

    var meteoconsstyle = document.getElementById("meteocons-style");
    if (meteoconsstyle)
    {
      meteoconsstyle.parentNode.removeChild(meteoconsstyle);
    }

    var materialiconsstyle = document.getElementById("material-icons-style");
    if (materialiconsstyle)
    {
      materialiconsstyle.parentNode.removeChild(materialiconsstyle);
    }

    var googlefont = document.getElementById("google-font");
    if (googlefont)
    {
      googlefont.parentNode.removeChild(googlefont);
    }


  }

  loadFuseAssets(): void
  {

    this.loadStyle("fuse-style.css", "fuse-main-style");
    this.loadStyle("assets/icons/meteocons/style.css", "meteocons-style");
    this.loadStyle("assets/icons/material-icons/outline/style.css", "material-icons-style");
    this.loadStyle("https://fonts.googleapis.com/css?family=Muli:300,400,600,700", "google-font");

    var bootstrapscssstyle = document.getElementById("bootstrap-scss-style");
    if (bootstrapscssstyle)
    {
      bootstrapscssstyle.parentNode.removeChild(bootstrapscssstyle);
    }

    // var owlcarouselstyle = document.getElementById("owl-carousel-style");
    // if (owlcarouselstyle)
    // {
    //   owlcarouselstyle.parentNode.removeChild(owlcarouselstyle);
    // }

    // var owlthemestyle = document.getElementById("owl-theme-style");
    // if (owlthemestyle)
    // {
    //   owlthemestyle.parentNode.removeChild(owlthemestyle);
    // }

    // var animateminstyle = document.getElementById("animate-min-style");
    // if (animateminstyle)
    // {
    //   animateminstyle.parentNode.removeChild(animateminstyle);
    // }

    var flaticoncssstyle = document.getElementById("flaticon-css-style");
    if (flaticoncssstyle)
    {
      flaticoncssstyle.parentNode.removeChild(flaticoncssstyle);
    }

    // var odometercssstyle = document.getElementById("odometer-css-style");
    // if (odometercssstyle)
    // {
    //   odometercssstyle.parentNode.removeChild(odometercssstyle);
    // }

    // var magnificcssstyle = document.getElementById("magnific-css-style");
    // if (magnificcssstyle)
    // {
    //   magnificcssstyle.parentNode.removeChild(magnificcssstyle);
    // }

    // var niceselectcssstyle = document.getElementById("nice-select-css-style");
    // if (niceselectcssstyle)
    // {
    //   niceselectcssstyle.parentNode.removeChild(niceselectcssstyle);
    // }

    var fontawesomecssstyle = document.getElementById("fontawesome-css-style");
    if (fontawesomecssstyle)
    {
      fontawesomecssstyle.parentNode.removeChild(fontawesomecssstyle);
    }

    var homestylescssstyle = document.getElementById("home-styles-css-style");
    if (homestylescssstyle)
    {
      homestylescssstyle.parentNode.removeChild(homestylescssstyle);
    }

    var responsivecssstyle = document.getElementById("responsive-css-style");
    if (responsivecssstyle)
    {
      responsivecssstyle.parentNode.removeChild(responsivecssstyle);
    }

    var jqueryscript = document.getElementById("jquery-script");
    if (jqueryscript)
    {
      jqueryscript.parentNode.removeChild(jqueryscript);
    }

    // var popperscript = document.getElementById("popper-script");
    // if (popperscript)
    // {
    //   popperscript.parentNode.removeChild(popperscript);
    // }

    var bootstrapscript = document.getElementById("bootstrap-script");
    if (bootstrapscript)
    {
      bootstrapscript.parentNode.removeChild(bootstrapscript);
    }

    // var owlcarouselscript = document.getElementById("owl-carousel-script");
    // if (owlcarouselscript)
    // {
    //   owlcarouselscript.parentNode.removeChild(owlcarouselscript);
    // }

    // var appearscript = document.getElementById("appear-script");
    // if (appearscript)
    // {
    //   appearscript.parentNode.removeChild(appearscript);
    // }

    // var odometerscript = document.getElementById("odometer-script");
    // if (odometerscript)
    // {
    //   odometerscript.parentNode.removeChild(odometerscript);
    // }

    // var magnificpopupscript = document.getElementById("magnific-popup-script");
    // if (magnificpopupscript)
    // {
    //   magnificpopupscript.parentNode.removeChild(magnificpopupscript);
    // }

    // var parallaxscript = document.getElementById("parallax-script");
    // if (parallaxscript)
    // {
    //   parallaxscript.parentNode.removeChild(parallaxscript);
    // }

    // var niceselectscript = document.getElementById("nice-select-script");
    // if (niceselectscript)
    // {
    //   niceselectscript.parentNode.removeChild(niceselectscript);
    // }

    // var wowscript = document.getElementById("wow-script");
    // if (wowscript)
    // {
    //   wowscript.parentNode.removeChild(wowscript);
    // }

    var customscript = document.getElementById("custom-script");
    if (customscript)
    {
      customscript.parentNode.removeChild(customscript);
    }


  }
}
