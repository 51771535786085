import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class InterestOnSecuritiesService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    if (id != null)
    {
      return this.service.get(urlConfig.INCOME_Interest_On_Securities_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
      return this.service.get(urlConfig.INCOME_Interest_On_Securities_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }

  }


  submitInterestOnSecurities(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Interest_On_Securities_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Interest_On_Securities_Submit_Url + `/${id}`, model);
    }
  }


  getJson(): any
  {
    const jsonData = [
      {
        "control": "DropdownControl",
        "key": "SecurityType",
        "label": "Type",
        "errorType": "",
        "message": "Required",
        "required": true,
        "tooltip": "Interest on Securities is the annual amount of interest received by the taxpayer for all government or government approved securities.",
        "options": [
          {
            "key": "1",
            "value": "Sanchaypotro"
          },
          {
            "key": "2",
            "value": "Others"
          },

        ],
        "order": 1
      },
      {
        "control": "TextboxControl",
        "key": "Amount",
        "label": "Amount",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "Enter the annual amount of interest received",
        "order": 3
      },
      {
        "control": "TextboxControl",
        "key": "ComissionInterestRate",
        "label": "Tax Deducted At Source",
        "required": false,
        "errorType": "",
        "relationClass": "nonSanchaypotro",
        "message": "Required",
        "tooltip": "This refers to the commission/interest rate",
        "order": 4
      },
      {
        "control": "TextboxControl",
        "key": "NetIncome",
        "label": "Net Income",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers the total taxable income from interest on securities ",
        "readonly": true,
        "order": 5
      }
    ]

    return jsonData;
  }

  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
