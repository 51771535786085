<h2 mat-dialog-title style="margin: 0 !important">Send Sms</h2>
<mat-dialog-content class="mat-typography">

    <div fxLayout="column"
        class="mb-16">

        <mat-form-field appearance="outline"
            fxFlex="100">
            <textarea style="resize:vertical"
                rows="5"
                type="text"
                [formControl]="SmsBody"
                class="leftA"
                autocomplete="off"
                matInput>
            </textarea>
        </mat-form-field>

    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
    <button class="mat-button mat-button-base primary-100 secondary-text"
        cdkFocusInitial
        mat-dialog-close>Cancel</button>
    <button class="mat-button mat-button-base dupl"
        (click)="SendSms()">SEND</button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
