import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import Utils from 'app/util/utils';



@Injectable()
export class ViewArchiveGuard implements CanActivate
{
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean
  {
    if (Utils.getUserInfo().ActiveRoleId == "8" && Utils.getArchiveStatus().IsNewReturn == true)
    {
      return true;
    }
    this.router.navigate(['/Dashboard']);
    return false;
  }
}

