import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class PaymentTaxChargesService
{
  constructor(private service: Service) { }

  submitExpense(model: any, categoryId: string,
  ): Observable<any>
  {
    let urlConfig = new UrlConfig();
    let url = urlConfig.EXPENSES_POST + `/${categoryId}`;
    return this.service.post(url, model);
  }

  getControlJson(categoryId: string): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.EXPENSES_GET + `/${categoryId}`);
  }
}
