import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import { NonAgriculturalPropertyClass } from './NonAgriculturalProperty.Class';

@Component({
  selector: 'NonAgriculturalProperty-form-control',
  templateUrl: './Non-agriculture-property-form-control.component.html',
  styleUrls: ['./Non-agriculture-property.component.scss']

})
export class NonAgriculturalPropertyControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }

  onKey(event: any, key)
  {


    if (key == "StartingValue" || key == "ValueChanged")
    {
      let rawForm = this.form.getRawValue();
      let StartingValue, ValueChanged = null;


      rawForm.StartingValue == '' ?
        StartingValue = 0 : StartingValue = rawForm.StartingValue;

      rawForm.ValueChanged == '' ?
        ValueChanged = 0 : ValueChanged = rawForm.ValueChanged;

      if (ValueChanged == "-")
      {
        ValueChanged = 0
      }

      if (isNaN(Number(rawForm.StartingValue)) == true)
      {
        StartingValue = '0.00';
      }

      if (isNaN(Number(rawForm.ValueChanged)) == true)
      {
        ValueChanged = '0.00';
      }


      var nonAgriculturalPropertyClass = new NonAgriculturalPropertyClass(
        StartingValue,
        ValueChanged,
        rawForm.Amount,
      )
      this.form.patchValue({
        Amount: Number(nonAgriculturalPropertyClass.Amount).toFixed(2)
      })
    }

  }

}
