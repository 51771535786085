<div fxLayout="column">

    <div fileDragDropForExcel
        (filesChangeEmiter)="onFileChange($event)"
        fxLayoutAlign="center center"
        fxLayout="column"
        style="width: 100%; height: 150px !important;">

        <mat-icon matSuffix>cloud_upload</mat-icon>
        <span>{{ 'DOCUMENTS.DOC_DRAG' | translate }}</span>
        <span>{{ 'DOCUMENTS.DOC_OR' | translate }}</span>
        <br>
        <input type="file"
            accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
            name="file"
            id="file"
            (change)="onFileChange($event.target.files)">
        <label for="file"><span class="textLink">{{ 'DOCUMENTS.DOC_SELECT' | translate }}</span></label>
        <!-- <div class="text-wrapper">
            <div class="centered"> -->
        <!-- <input type="file"
            name="file"
            id="file"
            (change)="onFileChange($event.target.files)">
        <label for="file"><span class="textLink">Select your file</span> or Drop it here!</label> -->
        <!-- </div>
        </div> -->
    </div>
    <!-- </div> -->
    <!-- <hr> -->
    <!-- <h3 mat-subheader>Files</h3> -->
    <div fxLayoutAlign="center center"
        fxLayout="row"
        style="padding-top: 10px; padding-bottom: 10px;">
        <button mat-button
            class="dupl"
            fxFlex="0 0 auto"
            fxLayout="row"
            fxLayoutAlign="center center"
            style="padding: 10px; margin-right: 10px; border: 0; cursor: pointer;"
            (click)="downloadTemplate()">
            <mat-icon class="s-16 mr-sm-4"
                style="transform: rotate(180deg)">arrow_upward</mat-icon>
            <span>Download Template</span>
        </button>
    </div>
    <div fxLayoutAlign="start"
        fxLayout="column"
        style="margin-top: 10px; margin-bottom: 10px;">
        <div fxLayoutAlign="start center"
            fxLayout="row"
            style="padding-top: 5px; padding-bottom: 5px;">
            <span style="width: 30%;">{{ 'DOCUMENTS.FILE_T' | translate }}: </span>
            <!-- <span style="width: 70%;font-weight: bold;">f.name</span> -->
            <div style="width: 70%;"
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngFor="let f of files; let ind = index">
                <!-- <div class="col-sm-12 fileItemIconDiv"
                    (click)="deleteFromArray(ind);">
                    <i class="fa-6x fileItemIcon"></i>
                </div> -->

                <span style="font-weight: bold;">{{f.name}}</span>
                <button style="background-color: transparent; border:0; cursor: pointer;"
                    (click)="deleteFromArray(ind)">
                    <mat-icon>clear</mat-icon>
                </button>

            </div>

        </div>
        <div mat-dialog-actions
            fxLayoutAlign="end center">
            <button mat-button
                class="warn"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 10px; margin-right: 10px; border: 0; cursor: pointer;"
                (click)="onNoClick()">
                <!-- <mat-icon class="s-16 mr-sm-4">arrow_upward</mat-icon> -->
                <span>{{ 'COMMON.BUTTON_CANCEL' | translate }}</span>
            </button>

            <button mat-button
                class="dupl"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 10px; margin-left: 10px; border: 0; cursor: pointer;"
                (click)="OnSubmit()">
                <mat-icon class="s-16 mr-sm-4">arrow_upward</mat-icon>
                <span>{{ 'DOCUMENTS.DOC_Upload' | translate }}</span>
            </button>

        </div>
    </div>

</div>
