import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { util } from 'prismjs';
import Utils from 'app/util/utils';
import { promise } from 'protractor';
import { log } from 'console';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatSnackBar } from '@angular/material/snack-bar';
import { EtinOthersListService } from './Etin-Others-List.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { filter, map, tap } from 'rxjs/operators';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog } from '@angular/material/dialog';



@Component({
    selector: 'EtinOthersList',
    templateUrl: './Etin-Others-List.component.html',
    styleUrls: ['./Etin-Others-List.component.scss']
})
export class EtinOthersListComponent implements OnInit
{
    @Input() controls: BaseControl<string>[] = [];

    state$: Observable<object>;

    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    getData: any;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _formControlService: EtinOthersListService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private eventEmitterService: EventEmitterService,
        private _route: ActivatedRoute,
        private back: BackButtonService,

    )
    {
        this.meta.addTags([
            { name: 'description', content: 'digiTax E-TIN & Other Information' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax E-TIN & Other Information' }
        ]);
        this.setTitle('digiTax | E-TIN & Other Information');
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

        
        // if (this.getData.length == 0)
        // {
        //     this._router.navigate(['/EtinOthers']);
        // }

        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
                                
        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));

        // if (profileData.iscomplete == false)
        // {
        //     let incompletefields = null;

        //     if (profileData.incompletefields[0] != null)
        //     {
        //         incompletefields = profileData.incompletefields[0].fields;
        //     }

        //     let incompleteprofile = null;
        //     if (profileData.incompleteprofile[0] != null)
        //     {
        //         incompleteprofile = profileData.incompleteprofile[0];
        //     }

        //     let fl = "Please Fillup the Following Fields: ";

        //     if (incompleteprofile == "1")
        //     {
        //         this._matSnackBar.open(fl + incompletefields, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //             verticalPosition: 'top',
        //             duration: 2000
        //         });
        //         this._router.navigate(['/EtinOthers/save']);
        //     }

        //     if (incompleteprofile == "2")
        //     {
        //         this._matSnackBar.open(fl + incompletefields, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //             verticalPosition: 'top',
        //             duration: 2000
        //         });
        //         this._router.navigate(['/PersonalInfo/save']);
        //     }

        //     if (incompleteprofile == "3")
        //     {
        //         this._matSnackBar.open(fl + incompletefields, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //             verticalPosition: 'top',
        //             duration: 2000
        //         });
        //         this._router.navigate(['/PersonalizeReturn']);
        //     }

        // }
        // else
        // {
        this._formControlService.getControlJson().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            if (data.data == "requiredblank")
            {
                this._router.navigate(['/EtinOthers/save']);
            } else
            {
                this.getData = data.data;

                
            }

            

            // this.getData = data.data;

            // 

            // if (Utils.isEmptyObject(data.data))
            // {
            //     this._router.navigate(['/EtinOthers']);
            // }
        });
        // }
    }


    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }

}

