import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientsListService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }


  getControlJson(model): Observable<BaseControl<string>[]>
  {
    if (model == 'null')
    {
      model = {};
    }
    let urlConfig = new UrlConfig();

    // return this.service.get(urlConfig.PRACTITIONER_GET_CLIENTS);
    return this.service.post(urlConfig.PRACTITIONER_GET_CLIENTS, model);

  }

  GetCommomDropdown(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.PRACTITIONER_GET_CLIENTS_COMMON_DROPDOWN);
  }

  // GetClientsDashBoardData(model: any): Observable<any>
  // {
  //   let urlConfig = new UrlConfig();
  //   return this.service.post(urlConfig.PRACTITIONER_GET_CLIENTS_DASHBOARD, model);
  // }

  GetClientsDashBoardData(): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.PRACTITIONER_GET_CLIENTS_DASHBOARD);
  }

  deleteControl(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.delete(urlConfig.PRACTITIONER_DELETE_CLIENTS + `${id}`);
  }

  deleteSlipControl(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.delete(urlConfig.SLIP_DELETE + `${id}`);
  }

  BULK_CLIENT_UPLOAD(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.BULK_UPLOAD_CLIENT_SUBMIT, model);
  }

  getDocumentsList(AssesseeId): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.DOCUMENT_URL_WO_ASSESSEID + `/${AssesseeId}`);
  }

  NotificationSendSms(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.NOTIFICATION_MANAGE_CLIENT_NOTICE, model);
  }

  BULK_SALARY_UPLOAD(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.BULK_UPLOAD_SALARY_CLIENT_SUBMIT, model);
  }


  SearchTransaction(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.CLIENT_DASHBOARD_ACCOUNTING_SEARCHTRANSACTION, model);
  }

  ClientAutoComplete(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.MANAGE_CLIENT_SEARCH_AUTOCOMPLETE, model);
  }


  SLIP_SUBMIT(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.SLIP_SUBMIT + `${model.AssesseeId}/`, model);
  }

  SLIP_DELETE(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.delete(urlConfig.SLIP_DELETE + `${id}`);
  }

  SLIP_DOWNLOAD(path: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.SLIP_DOWNLOADFILE + `${path}`);
  }

  switchReturnWIthReturnId_AssesseeId(ReturnId, AssesseeId): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.SWITCH_RETURN + `/${ReturnId}/${AssesseeId}`);
  }

  ExitReturn(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.EXIT_RETURN );
  }

  ManageClientEmail(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.MANAGE_CLIENT_EMAIL, model);
  }

  LoadEmailBasicInfo(ReturnId: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.LOAD_EMAIL_BASIC_INFO + `/${ReturnId}`);
  }

  getJson(): any
  {
    const jsonData = [
      {

        "data": [
          {
            "source": "Name (as per trade license)",
            "value": "--",
          },
          {
            "source": "ITP",
            "value": "--",
          },
          {
            "source": "Bar Membership Number",
            "value": "--",
          },
          {
            "source": "Mobile No.",
            "value": "--",
          },
          {
            "source": "Contact Email",
            "value": "--",
          },
          {
            "source": "Address",
            "value": "--",
          },
          {
            "source": "City",
            "value": "--",
          },
        ]

      }


    ]

    return jsonData;
  }

  getControls(): Promise<any>
  {
    return new Promise((resolve) =>
    {

      resolve(this.getJson)

    });
  }

}


let ff = [
  {
    "Id": 93,
    "PractitionerId": 1,
    "AssesseeId": 2205,
    "ReturnId": 0,
    "ETIN": "999999999999",
    "AssesseeName": "Assessee Name",
    "MobileNo": "19118111111",
    "Email": "email2@gmail.com",
    "TaxAmount": null,
    "Percent": "100%",
    "Reference": null,
    "Zone": null,
    "Circle": null,
    "ResidentialAreaId": null,
    "Gender": null,
    "DateOfBirth": null,
    "FatherName": null,
    "MotherName": null,
    "MaritalStatus": null,
    "SpouseName": null,
    "SpouseTIN": null,
    "ReturnHistory": [
      {
        "DocId": null,
        "AssesseeId": 2205,
        "ReturnId": 2192,
        "AcknowledgementSlipName": null,
        "TaxYear": "Year 2022-2023",
        "AmountToBePaid": null,
        "PaidWithReturn": null,
        "UploadedDate": "1900-01-01T00:00:00"
      }
    ]
  },
  {
    "Id": 91,
    "PractitionerId": 1,
    "AssesseeId": 2203,
    "ReturnId": 0,
    "ETIN": "99999999999",
    "AssesseeName": "Assessee Name",
    "MobileNo": "1911111111",
    "Email": "email@gmail.com",
    "TaxAmount": null,
    "Percent": "100%",
    "Reference": null,
    "Zone": null,
    "Circle": null,
    "ResidentialAreaId": null,
    "Gender": null,
    "DateOfBirth": null,
    "FatherName": null,
    "MotherName": null,
    "MaritalStatus": null,
    "SpouseName": null,
    "SpouseTIN": null,
    "ReturnHistory": [
      {
        "DocId": 1,
        "AssesseeId": 2203,
        "ReturnId": 2190,
        "AcknowledgementSlipName": "sample-pdf-file.pdf",
        "TaxYear": "Year 2022-2023",
        "AmountToBePaid": null,
        "PaidWithReturn": null,
        "UploadedDate": "2022-06-19T14:50:46.7666667"
      }
    ]
  },
  {
    "Id": 90,
    "PractitionerId": 1,
    "AssesseeId": 2199,
    "ReturnId": 0,
    "ETIN": "111111111111",
    "AssesseeName": "a4",
    "MobileNo": "22222222222",
    "Email": "a2@a5.com",
    "TaxAmount": null,
    "Percent": "100%",
    "Reference": null,
    "Zone": null,
    "Circle": null,
    "ResidentialAreaId": null,
    "Gender": null,
    "DateOfBirth": null,
    "FatherName": null,
    "MotherName": null,
    "MaritalStatus": null,
    "SpouseName": null,
    "SpouseTIN": null,
    "ReturnHistory": [
      {
        "DocId": 3,
        "AssesseeId": 2199,
        "ReturnId": 2186,
        "AcknowledgementSlipName": "Summary File.pdf",
        "TaxYear": "Year 2022-2023",
        "AmountToBePaid": null,
        "PaidWithReturn": null,
        "UploadedDate": "2022-06-23T11:30:20.72"
      }
    ]
  },
  {
    "Id": 31,
    "PractitionerId": 1,
    "AssesseeId": 405,
    "ReturnId": 0,
    "ETIN": "769698685767",
    "AssesseeName": "jhj yuuo",
    "MobileNo": "23456464745",
    "Email": "asda@eweer.com",
    "TaxAmount": null,
    "Percent": "100%",
    "Reference": null,
    "Zone": null,
    "Circle": null,
    "ResidentialAreaId": null,
    "Gender": null,
    "DateOfBirth": null,
    "FatherName": null,
    "MotherName": null,
    "MaritalStatus": null,
    "SpouseName": null,
    "SpouseTIN": null,
    "ReturnHistory": [
      {
        "DocId": null,
        "AssesseeId": 405,
        "ReturnId": 402,
        "AcknowledgementSlipName": null,
        "TaxYear": "Year 2021-2022",
        "AmountToBePaid": 0,
        "PaidWithReturn": 0,
        "UploadedDate": "1900-01-01T00:00:00"
      }
    ]
  },
  {
    "Id": 30,
    "PractitionerId": 1,
    "AssesseeId": 404,
    "ReturnId": 0,
    "ETIN": "234242342423",
    "AssesseeName": "fgr trrt",
    "MobileNo": "12313131313",
    "Email": "fsdf@daer.com",
    "TaxAmount": null,
    "Percent": "100%",
    "Reference": null,
    "Zone": null,
    "Circle": null,
    "ResidentialAreaId": null,
    "Gender": null,
    "DateOfBirth": null,
    "FatherName": null,
    "MotherName": null,
    "MaritalStatus": null,
    "SpouseName": null,
    "SpouseTIN": null,
    "ReturnHistory": [
      {
        "DocId": 2,
        "AssesseeId": 404,
        "ReturnId": 401,
        "AcknowledgementSlipName": "sample-pdf-file.pdf",
        "TaxYear": "Year 2021-2022",
        "AmountToBePaid": 0,
        "PaidWithReturn": 0,
        "UploadedDate": "2022-06-23T11:03:15.7233333"
      }
    ]
  },
  {
    "Id": 17,
    "PractitionerId": 1,
    "AssesseeId": 331,
    "ReturnId": 0,
    "ETIN": "123131231313",
    "AssesseeName": "dsfe",
    "MobileNo": "12121212112",
    "Email": "asd@ddf.com",
    "TaxAmount": null,
    "Percent": "100%",
    "Reference": null,
    "Zone": null,
    "Circle": null,
    "ResidentialAreaId": null,
    "Gender": null,
    "DateOfBirth": null,
    "FatherName": null,
    "MotherName": null,
    "MaritalStatus": null,
    "SpouseName": null,
    "SpouseTIN": null,
    "ReturnHistory": [
      {
        "DocId": null,
        "AssesseeId": 331,
        "ReturnId": 329,
        "AcknowledgementSlipName": null,
        "TaxYear": "Year 2021-2022",
        "AmountToBePaid": 0,
        "PaidWithReturn": 0,
        "UploadedDate": "1900-01-01T00:00:00"
      }
    ]
  }
]
