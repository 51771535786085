import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import { BuisnessOrProfessionClass } from './BuisnessOrProfession.Class';

@Component({
  selector: 'BuisnessOrProfession-form-control',
  templateUrl: './BuisnessOrProfession-form-control.component.html',
  styleUrls: ['./BuisnessOrProfession.component.scss']

})
export class BuisnessOrProfessionControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  @Input() rBusinessOrProfessionType: boolean;
  IsCurrentYear: any;

  showOptions(event)
  {
    let rawForm = this.form.getRawValue();

    var buisnessOrProfessionClass = new BuisnessOrProfessionClass(
      rawForm.NetProfitOfSummeryOfIncome,
      rawForm.GrossProfit,
      rawForm.SalesOrTurnoverOrReceipts,
      rawForm.GeneralExpenses,
      rawForm.TotalAssets,
      rawForm.CashInhandAndBank,
      rawForm.Inventories,
      rawForm.FixedAssets,
      rawForm.OtherAssets,
      rawForm.ClosingCapital,
      rawForm.OpeningCapital,
      rawForm.WithdrawInIncomeYear,
      rawForm.TotalCapitalAndLiabilities,
      rawForm.Liabilities,
    )

    if (event.checked == false)
    {
      this.form.patchValue({
        TaxableIncome: Number(buisnessOrProfessionClass.NetProfitOfSummeryOfIncome).toFixed(2),
      })

    }

    if (event.checked == true)
    {
      this.form.patchValue({

        TaxableIncome: 0
      })

    }
  }

  onKey(event: any, key)
  {
    if (key == "GrossProfit" || key == "SalesOrTurnoverOrReceipts" || key == "GeneralExpenses" || key == "CashInhandAndBank" || key == "Inventories" || key == "FixedAssets" || key == "OtherAssets" || key == "OpeningCapital" || key == "WithdrawInIncomeYear" || key == "Liabilities")
    {
      let rawForm = this.form.getRawValue();
      let GrossProfit, GeneralExpenses, CashInhandAndBank, Inventories, FixedAssets, OtherAssets, OpeningCapital, WithdrawInIncomeYear, Liabilities = null;



      rawForm.GrossProfit == '' ?
        GrossProfit = 0 : GrossProfit = rawForm.GrossProfit;
      if (isNaN(GrossProfit))
      {
        GrossProfit = 0;
      }



      rawForm.GeneralExpenses == '' ?
        GeneralExpenses = 0 : GeneralExpenses = rawForm.GeneralExpenses;
      if (isNaN(GeneralExpenses))
      {
        GeneralExpenses = 0;
      }

      rawForm.CashInhandAndBank == '' ?
        CashInhandAndBank = 0 : CashInhandAndBank = rawForm.CashInhandAndBank;

      rawForm.Inventories == '' ?
        Inventories = 0 : Inventories = rawForm.Inventories;

      rawForm.FixedAssets == '' ?
        FixedAssets = 0 : FixedAssets = rawForm.FixedAssets;

      rawForm.OtherAssets == '' ?
        OtherAssets = 0 : OtherAssets = rawForm.OtherAssets;



      rawForm.OpeningCapital == '' ?
        OpeningCapital = 0 : OpeningCapital = rawForm.OpeningCapital;

      rawForm.WithdrawInIncomeYear == '' ?
        WithdrawInIncomeYear = 0 : WithdrawInIncomeYear = rawForm.WithdrawInIncomeYear;





      rawForm.Liabilities == '' ?
        Liabilities = 0 : Liabilities = rawForm.Liabilities;

      if (isNaN(Number(rawForm.GrossProfit)) == true)
      {
        GrossProfit = '0.00';
      }

      if (isNaN(Number(rawForm.GeneralExpenses)) == true)
      {
        GeneralExpenses = '0.00';
      }

      if (isNaN(Number(rawForm.GeneralExpenses)) == true)
      {
        GeneralExpenses = '0.00';
      }

      if (isNaN(Number(rawForm.CashInhandAndBank)) == true)
      {
        CashInhandAndBank = '0.00';
      }

      if (isNaN(Number(rawForm.Inventories)) == true)
      {
        Inventories = '0.00';
      }

      if (isNaN(Number(rawForm.FixedAssets)) == true)
      {
        FixedAssets = '0.00';
      }

      if (isNaN(Number(rawForm.OtherAssets)) == true)
      {
        OtherAssets = '0.00';
      }

      if (isNaN(Number(rawForm.OpeningCapital)) == true)
      {
        OpeningCapital = '0.00';
      }

      if (isNaN(Number(rawForm.WithdrawInIncomeYear)) == true)
      {
        WithdrawInIncomeYear = '0.00';
      }

      if (isNaN(Number(rawForm.Liabilities)) == true)
      {
        Liabilities = '0.00';
      }

      var buisnessOrProfessionClass = new BuisnessOrProfessionClass(
        rawForm.NetProfitOfSummeryOfIncome,
        GrossProfit,
        rawForm.SalesOrTurnoverOrReceipts,
        GeneralExpenses,
        rawForm.TotalAssets,
        CashInhandAndBank,
        Inventories,
        FixedAssets,
        OtherAssets,
        rawForm.ClosingCapital,
        OpeningCapital,
        WithdrawInIncomeYear,
        rawForm.TotalCapitalAndLiabilities,
        Liabilities,
      )

      this.form.patchValue({

        NetProfitOfSummeryOfIncome: Number(buisnessOrProfessionClass.NetProfitOfSummeryOfIncome).toFixed(2),
        NetProfitOfSummeryOfBalanceSheet: Number(buisnessOrProfessionClass.NetProfitOfSummeryOfIncome).toFixed(2),
        TotalIncome: Number(buisnessOrProfessionClass.NetProfitOfSummeryOfIncome).toFixed(2),
        TaxableIncome: Number(buisnessOrProfessionClass.NetProfitOfSummeryOfIncome).toFixed(2),
      })

      this.form.patchValue({

        TotalAssets: Number(buisnessOrProfessionClass.TotalAssets).toFixed(2),
      })

      this.form.patchValue({

        ClosingCapital: Number(buisnessOrProfessionClass.ClosingCapital).toFixed(2),

      })

      this.form.patchValue({

        TotalCapitalAndLiabilities: Number(buisnessOrProfessionClass.TotalCapitalAndLiabilities).toFixed(2),


      })
    }


  }

  ngOnInit(): void
  {

    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }

    let IsTaxFreeBusiness = this.form.controls["IsTaxFreeBusiness"].value;


    if (IsTaxFreeBusiness == "false")
    {
      IsTaxFreeBusiness = false
    }
    if (IsTaxFreeBusiness == "true")
    {
      IsTaxFreeBusiness = true
    }
    if (IsTaxFreeBusiness != null)
    {
      this.form.patchValue({
        IsTaxFreeBusiness: IsTaxFreeBusiness
      });
    }
  }
}
