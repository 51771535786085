<section class="about-area ptb-100"
    style="background-color: #F5F5FC;">
    <div class="container">
        <div class="row align-items-center flex-column-reverse flex-lg-row">
            <div class="col-lg-7 col-md-12">

                <div class="about-content">
                    <h3 style="text-align: center; margin-bottom: 30px;">{{ 'HOW_IT_WORKS.HEADER_2' | translate }}</h3>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item"
                            src="https://www.youtube.com/embed/-f7pLaUHv8g"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div style="padding: 48px 48px 48px 48px; background-color: #fff;">

                    <div style="width: 128px;
                    margin: 32px auto;">
                        <img src="assets/home/img/logo-n.png"
                            style="max-width: 100%;height: auto;vertical-align: top;border: none;">
                    </div>

                    <div style="text-align: center;font-size: 21px;font-weight: 300;line-height: 1;">{{ 'AUTH_TRAN.TEXT_CONFIRM' | translate }}</div>

                    <div class="row align-items-center"
                        style="width: 100%;padding-top: 32px; text-align: center;">
                        <div>
                            {{ 'AUTH_TRAN.TEXT_CONFIRM_EMAIL' | translate }} <b>{{email}}</b>.
                        </div>
                        <div>
                            {{ 'AUTH_TRAN.TEXT_CONFIRM_INBOX' | translate }} "Reset Password" {{ 'AUTH_TRAN.TEXT_VERIFY_B' | translate }}
                        </div>
                    </div>

                    <div
                        style="width: 100%;margin: 16px auto;margin-top: 32px; display: block;flex-direction: column;box-sizing: border-box;display: flex;place-content: center;align-items: center;">
                        <a class="link"
                            style="color: #29793C !important; font-weight: bold;"
                            [routerLink]="'/login-home'">{{ 'AUTH_TRAN.TEXT_BACK_LOGIN' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>
