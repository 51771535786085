import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Layouts } from 'app/enum/layouts.enum';
import { ReturnRefundPolicyComponent } from './return-and-refund-policy.component';


const routes = [
    {
        path     : 'return-and-refund-policy',
        component: ReturnRefundPolicyComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        ReturnRefundPolicyComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule        
    ],
    exports     : [
        ReturnRefundPolicyComponent
    ]
})

export class ReturnRefundPolicyModule
{
}
