import Utils from './utils';

export default class Config
{
   static apiVersion = 1;
   // DO NOT USE THIS, DEPRCICATED
   // static readonly rootUrl: string = 'https://digitax.cloudcampus24.com/api';
   // static readonly apiUrl: string = `https://digitax.cloudcampus24.com/api/v1`;


   // LIVE
   // static readonly rootUrl: string = 'https://api.digitax.com.bd/api';
   // static readonly apiUrl: string = `https://api.digitax.com.bd/api/v1`;

   // LIVE TEMP
   static readonly rootUrl: string = 'https://digitaxapi.cloudcampus24.com/api';
   static readonly apiUrl: string = `https://digitaxapi.cloudcampus24.com/api/v1`;

   // SANDBOX
   // static readonly rootUrl: string = 'https://digitaxsandboxapi.cloudcampus24.com/api';
   // static readonly apiUrl: string = `https://digitaxsandboxapi.cloudcampus24.com/api/v1`;


   static userToken: string = localStorage.getItem('userToken');
   static authToken = `Bearer ${Config.userToken}`;

   static getCulture()
   {
      let ct = localStorage.getItem('selectedLanguage');

      return ct != null ? ct : "en";
   }

   static getAssesseeId()
   {
      let ct = JSON.parse(localStorage.getItem('AssesseeId'));

      return ct != null ? ct.AssesseeId : "0";
   }


   //BASIC-INFO


   // static BASIC_INFO_Etin_Others_List_Url = Config.apiUrl + `/basic_personalize/etin_and_others/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Etin_Others_Control_Url = Config.apiUrl + `/basic_personalize/etin_and_others/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Etin_Others_Submit_Url = Config.apiUrl + `/basic_personalize/etin_and_others/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Etin_Others_Delete_Url = Config.apiUrl + `/basic_personalize/etin_and_others/${Config.getAssesseeId()}/`;



   // static BASIC_INFO_Personal_Information_List_Url = Config.apiUrl + `/basic_personalize/personal_info/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Personal_Information_Control_Url = Config.apiUrl + `/basic_personalize/personal_info/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Personal_Information_Submit_Url = Config.apiUrl + `/basic_personalize/personal_info/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Personal_Information_Delete_Url = Config.apiUrl + `/basic_personalize/personal_info/${Config.getAssesseeId()}/`;



   // static BASIC_INFO_Personalize_Return_List_Url = Config.apiUrl + `/basic_personalize/personalize_return/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Personalize_Return_Control_Url = Config.apiUrl + `/basic_personalize/personalize_return/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Personalize_Return_Submit_Url = Config.apiUrl + `/basic_personalize/personalize_return/${Config.getAssesseeId()}/`;

   // static BASIC_INFO_Personalize_Return_Delete_Url = Config.apiUrl + `/basic_personalize/personalize_return/${Config.getAssesseeId()}/`;



   //ASSET
   // static ASSET_Agricultural_Property_List_Url = Config.apiUrl + `/assets/agricultural_property/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Agricultural_Property_Control_Url = Config.apiUrl + `/assets/agricultural_property/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Agricultural_Property_Submit_Url = Config.apiUrl + `/assets/agricultural_property/${Config.getAssesseeId()}/`;

   // static ASSET_Agricultural_Property_Delete_Url = Config.apiUrl + `/assets/agricultural_property/${Config.getAssesseeId()}/`;



   // static ASSET_Buisness_Capital_List_Url = Config.apiUrl + `/assets/buisness_capital/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Buisness_Capital_Control_Url = Config.apiUrl + `/assets/buisness_capital/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Buisness_Capital_Submit_Url = Config.apiUrl + `/assets/buisness_capital/${Config.getAssesseeId()}/`;

   // static ASSET_Buisness_Capital_Delete_Url = Config.apiUrl + `/assets/buisness_capital/${Config.getAssesseeId()}/`;



   // static ASSET_Cash_Assets_List_Url = Config.apiUrl + `/assets/cash_assets/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Cash_Assets_Control_Url = Config.apiUrl + `/assets/cash_assets/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Cash_Assets_Submit_Url = Config.apiUrl + `/assets/cash_assets/${Config.getAssesseeId()}/`;

   // static ASSET_Cash_Assets_Delete_Url = Config.apiUrl + `/assets/cash_assets/${Config.getAssesseeId()}/`;



   // static ASSET_Directors_Shareholding_List_Url = Config.apiUrl + `/assets/director_share_holding/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Directors_Shareholding_Control_Url = Config.apiUrl + `/assets/director_share_holding/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Directors_Shareholding_Submit_Url = Config.apiUrl + `/assets/director_share_holding/${Config.getAssesseeId()}/`;

   // static ASSET_Directors_Shareholding_Delete_Url = Config.apiUrl + `/assets/director_share_holding/${Config.getAssesseeId()}/`;



   // static ASSET_Electronic_Equipment_List_Url = Config.apiUrl + `/assets/electronic_equipment/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Electronic_Equipment_Control_Url = Config.apiUrl + `/assets/electronic_equipment/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Electronic_Equipment_Submit_Url = Config.apiUrl + `/assets/electronic_equipment/${Config.getAssesseeId()}/`;

   // static ASSET_Electronic_Equipment_Delete_Url = Config.apiUrl + `/assets/electronic_equipment/${Config.getAssesseeId()}/`;


   // static ASSET_Furniture_List_Url = Config.apiUrl + `/assets/furniture/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Furniture_Control_Url = Config.apiUrl + `/assets/furniture/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Furniture_Submit_Url = Config.apiUrl + `/assets/furniture/${Config.getAssesseeId()}/`;

   // static ASSET_Furniture_Delete_Url = Config.apiUrl + `/assets/furniture/${Config.getAssesseeId()}/`;



   // static ASSET_Investments_List_Url = Config.apiUrl + `/assets/investments/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Investments_Control_Url = Config.apiUrl + `/assets/investments/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Investments_Submit_Url = Config.apiUrl + `/assets/investments/${Config.getAssesseeId()}/`;

   // static ASSET_Investments_Delete_Url = Config.apiUrl + `/assets/investments/${Config.getAssesseeId()}/`;



   // static ASSET_Jewellery_List_Url = Config.apiUrl + `/assets/jewellery/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Jewellery_Control_Url = Config.apiUrl + `/assets/jewellery/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Jewellery_Submit_Url = Config.apiUrl + `/assets/jewellery/${Config.getAssesseeId()}/`;

   // static ASSET_Jewellery_Delete_Url = Config.apiUrl + `/assets/jewellery/${Config.getAssesseeId()}/`;




   // static ASSET_Motor_Vehicle_List_Url = Config.apiUrl + `/assets/motor_vehicle/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Motor_Vehicle_Control_Url = Config.apiUrl + `/assets/motor_vehicle/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Motor_Vehicle_Submit_Url = Config.apiUrl + `/assets/motor_vehicle/${Config.getAssesseeId()}/`;

   // static ASSET_Motor_Vehicle_Delete_Url = Config.apiUrl + `/assets/motor_vehicle/${Config.getAssesseeId()}/`;



   // static ASSET_Non_Agricultural_Property_List_Url = Config.apiUrl + `/assets/non_agricultural_property/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Non_Agricultural_Property_Control_Url = Config.apiUrl + `/assets/non_agricultural_property/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Non_Agricultural_Property_Submit_Url = Config.apiUrl + `/assets/non_agricultural_property/${Config.getAssesseeId()}/`;

   // static ASSET_Non_Agricultural_Property_Delete_Url = Config.apiUrl + `/assets/non_agricultural_property/${Config.getAssesseeId()}/`;



   // static ASSET_Other_Assets_List_Url = Config.apiUrl + `/assets/other_assets/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Other_Assets_Control_Url = Config.apiUrl + `/assets/other_assets/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Other_Assets_Submit_Url = Config.apiUrl + `/assets/other_assets/${Config.getAssesseeId()}/`;

   // static ASSET_Other_Assets_Delete_Url = Config.apiUrl + `/assets/other_assets/${Config.getAssesseeId()}/`;



   // static ASSET_Other_Assets_Receipt_List_Url = Config.apiUrl + `/assets/other_assets_receipt/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Other_Assets_Receipt_Control_Url = Config.apiUrl + `/assets/other_assets_receipt/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Other_Assets_Receipt_Submit_Url = Config.apiUrl + `/assets/other_assets_receipt/${Config.getAssesseeId()}/`;

   // static ASSET_Other_Assets_Receipt_Delete_Url = Config.apiUrl + `/assets/other_assets_receipt/${Config.getAssesseeId()}/`;




   // static ASSET_Previous_Year_Net_Wealth_List_Url = Config.apiUrl + `/assets/previous_year_net_wealth/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Previous_Year_Net_Wealth_Control_Url = Config.apiUrl + `/assets/previous_year_net_wealth/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static ASSET_Previous_Year_Net_Wealth_Submit_Url = Config.apiUrl + `/assets/previous_year_net_wealth/${Config.getAssesseeId()}/`;

   // static ASSET_Previous_Year_Net_Wealth_Delete_Url = Config.apiUrl + `/assets/previous_year_net_wealth/${Config.getAssesseeId()}/`;





   //INCOME
   // static INCOME_Agricultural_List_Url = Config.apiUrl + `/income/agriculture/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Agricultural_Control_Url = Config.apiUrl + `/income/agriculture/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Agricultural_Submit_Url = Config.apiUrl + `/income/agriculture/${Config.getAssesseeId()}/`;

   // static INCOME_Agricultural_Delete_Url = Config.apiUrl + `/income/agriculture/${Config.getAssesseeId()}/`;


   // static INCOME_Buisness_Or_Profession_List_Url = Config.apiUrl + `/income/buisness_or_profession/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Buisness_Or_Profession_Control_Url = Config.apiUrl + `/income/buisness_or_profession/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Buisness_Or_Profession_Submit_Url = Config.apiUrl + `/income/buisness_or_profession/${Config.getAssesseeId()}/`;

   // static INCOME_Buisness_Or_Profession_Delete_Url = Config.apiUrl + `/income/buisness_or_profession/${Config.getAssesseeId()}/`;


   // static INCOME_Capital_Gains_List_Url = Config.apiUrl + `/income/capital_gains/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Capital_Gains_Control_Url = Config.apiUrl + `/income/capital_gains/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Capital_Gains_Submit_Url = Config.apiUrl + `/income/capital_gains/${Config.getAssesseeId()}/`;

   // static INCOME_Capital_Gains_Delete_Url = Config.apiUrl + `/income/capital_gains/${Config.getAssesseeId()}/`;


   // static INCOME_Foreign_Income_List_Url = Config.apiUrl + `/income/foreign_income/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Foreign_Income_Control_Url = Config.apiUrl + `/income/foreign_income/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Foreign_Income_Submit_Url = Config.apiUrl + `/income/foreign_income/${Config.getAssesseeId()}/`;

   // static INCOME_Foreign_Income_Delete_Url = Config.apiUrl + `/income/foreign_income/${Config.getAssesseeId()}/`;



   // static INCOME_Interest_On_Securities_List_Url = Config.apiUrl + `/income/interest_on_securities/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Interest_On_Securities_Control_Url = Config.apiUrl + `/income/interest_on_securities/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Interest_On_Securities_Submit_Url = Config.apiUrl + `/income/interest_on_securities/${Config.getAssesseeId()}/`;

   // static INCOME_Interest_On_Securities_Delete_Url = Config.apiUrl + `/income/interest_on_securities/${Config.getAssesseeId()}/`;



   // static INCOME_Other_Resources_List_Url = Config.apiUrl + `/income/other_resources/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Other_Resources_Control_Url = Config.apiUrl + `/income/other_resources/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Other_Resources_Submit_Url = Config.apiUrl + `/income/other_resources/${Config.getAssesseeId()}/`;

   // static INCOME_Other_Resources_Delete_Url = Config.apiUrl + `/income/other_resources/${Config.getAssesseeId()}/`;



   // static INCOME_Property_List_Url = Config.apiUrl + `/income/property/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Property_Control_Url = Config.apiUrl + `/income/property/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Property_Submit_Url = Config.apiUrl + `/income/property/${Config.getAssesseeId()}/`;

   // static INCOME_Property_Delete_Url = Config.apiUrl + `/income/property/${Config.getAssesseeId()}/`;



   // Salary_Private------

   // static INCOME_Salary_Private_List_Url = Config.apiUrl + `/income/salary/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Salary_Private_Control_Url = Config.apiUrl + `/income/salary/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Salary_Private_Submit_Url = Config.apiUrl + `/income/salary/${Config.getAssesseeId()}/`;

   // static INCOME_Salary_Private_Delete_Url = Config.apiUrl + `/income/salary/${Config.getAssesseeId()}/`;

   // ------Salary_Private




   // Salary_Govt ------

   // static INCOME_Salary_Govt_List_Url = Config.apiUrl + `/income/salary_private/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Salary_Govt_Control_Url = Config.apiUrl + `/income/salary_private/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Salary_Govt_Submit_Url = Config.apiUrl + `/income/salary_private/${Config.getAssesseeId()}/`;

   // static INCOME_Salary_Govt_Delete_Url = Config.apiUrl + `/income/salary_private/${Config.getAssesseeId()}/`;

   // ------Salary_Govt


   // static INCOME_Share_of_Profit_In_a_Firm_List_Url = Config.apiUrl + `/income/share_of_profit_in_a_firm/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Share_of_Profit_In_a_Firm_Control_Url = Config.apiUrl + `/income/share_of_profit_in_a_firm/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Share_of_Profit_In_a_Firm_Submit_Url = Config.apiUrl + `/income/share_of_profit_in_a_firm/${Config.getAssesseeId()}/`;

   // static INCOME_Share_of_Profit_In_a_Firm_Delete_Url = Config.apiUrl + `/income/share_of_profit_in_a_firm/${Config.getAssesseeId()}/`;



   // static INCOME_Spouse_Or_Child_List_Url = Config.apiUrl + `/income/spouse_or_child/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Spouse_Or_Child_Control_Url = Config.apiUrl + `/income/spouse_or_child/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static INCOME_Spouse_Or_Child_Submit_Url = Config.apiUrl + `/income/spouse_or_child/${Config.getAssesseeId()}/`;

   // static INCOME_Spouse_Or_Child_Delete_Url = Config.apiUrl + `/income/spouse_or_child/${Config.getAssesseeId()}/`;



   // LIABILITIES
   // static LIABILITIES_Bank_Loans_List_Url = Config.apiUrl + `/liabilities/bank_loans/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Bank_Loans_Control_Url = Config.apiUrl + `/liabilities/bank_loans/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Bank_Loans_Submit_Url = Config.apiUrl + `/liabilities/bank_loans/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Bank_Loans_Delete_Url = Config.apiUrl + `/liabilities/bank_loans/${Config.getAssesseeId()}/`;



   // static LIABILITIES_Mortgages_List_Url = Config.apiUrl + `/liabilities/mortgages/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Mortgages_Control_Url = Config.apiUrl + `/liabilities/mortgages/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Mortgages_Submit_Url = Config.apiUrl + `/liabilities/mortgages/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Mortgages_Delete_Url = Config.apiUrl + `/liabilities/mortgages/${Config.getAssesseeId()}/`;



   // static LIABILITIES_Other_Liabilities_List_Url = Config.apiUrl + `/liabilities/other_liabilities/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Other_Liabilities_Control_Url = Config.apiUrl + `/liabilities/other_liabilities/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Other_Liabilities_Submit_Url = Config.apiUrl + `/liabilities/other_liabilities/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Other_Liabilities_Delete_Url = Config.apiUrl + `/liabilities/other_liabilities/${Config.getAssesseeId()}/`;



   // static LIABILITIES_Unsecured_Loans_List_Url = Config.apiUrl + `/liabilities/unsecured_loans/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Unsecured_Loans_Control_Url = Config.apiUrl + `/liabilities/unsecured_loans/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Unsecured_Loans_Submit_Url = Config.apiUrl + `/liabilities/unsecured_loans/${Config.getAssesseeId()}/`;

   // static LIABILITIES_Unsecured_Loans_Delete_Url = Config.apiUrl + `/liabilities/unsecured_loans/${Config.getAssesseeId()}/`;

   // TAX

   // static TAX_RA_Adjustment_of_Tax_Refund_List_Url = Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Adjustment_of_Tax_Refund_Control_Url = Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Adjustment_of_Tax_Refund_Submit_Url = Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/${Config.getAssesseeId()}/`;

   // static TAX_RA_Adjustment_of_Tax_Refund_Delete_Url = Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/${Config.getAssesseeId()}/`;



   // static TAX_RA_Advance_Paid_Tax_List_Url = Config.apiUrl + `/tax_rebate/advance_paid_tax/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Advance_Paid_Tax_Control_Url = Config.apiUrl + `/tax_rebate/advance_paid_tax/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Advance_Paid_Tax_Submit_Url = Config.apiUrl + `/tax_rebate/advance_paid_tax/${Config.getAssesseeId()}/`;

   // static TAX_RA_Advance_Paid_Tax_Delete_Url = Config.apiUrl + `/tax_rebate/advance_paid_tax/${Config.getAssesseeId()}/`;



   // static TAX_RA_Tax_Deducted_At_Source_List_Url = Config.apiUrl + `/tax_rebate/tax_deducted_at_source/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Tax_Deducted_At_Source_Control_Url = Config.apiUrl + `/tax_rebate/tax_deducted_at_source/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Tax_Deducted_At_Source_Submit_Url = Config.apiUrl + `/tax_rebate/tax_deducted_at_source/${Config.getAssesseeId()}/`;

   // static TAX_RA_Tax_Deducted_At_Source_Delete_Url = Config.apiUrl + `/tax_rebate/tax_deducted_at_source/${Config.getAssesseeId()}/`;



   // static TAX_RA_Tax_Rebate_List_Url = Config.apiUrl + `/tax_rebate/tax_rebate/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Tax_Rebate_Control_Url = Config.apiUrl + `/tax_rebate/tax_rebate/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_RA_Tax_Rebate_Submit_Url = Config.apiUrl + `/tax_rebate/tax_rebate/${Config.getAssesseeId()}/`;

   // static TAX_RA_Tax_Rebate_Delete_Url = Config.apiUrl + `/tax_rebate/tax_rebate/${Config.getAssesseeId()}/`;


   // CALCULATIONS

   // static INCOME_CALCULATE_Private_Salary = Config.apiUrl + `/TaxCalculation/calculate_private_salary`;

   // static INCOME_CALCULATE_Govt_Salary = Config.apiUrl + `/TaxCalculation/calculate_govt_salary`;

   // static INCOME_CALCULATE_Agriculture = Config.apiUrl + `/TaxCalculation/calculate_agriculture`;

   // static INCOME_CALCULATE_Buisness_Or_Profession = Config.apiUrl + `/TaxCalculation/calculate_business_or_professione`;

   // static INCOME_CALCULATE_Capital_Gains = Config.apiUrl + `/TaxCalculation/calculate_capital_gain`;


   // static INCOME_CALCULATE_Foreign_Income = Config.apiUrl + `/TaxCalculation/calculate_foreign_income`;


   // static INCOME_CALCULATE_Interest_On_Securities = Config.apiUrl + `/TaxCalculation/calculate_interest_on_security`;


   // static INCOME_CALCULATE_Other_Resources = Config.apiUrl + `/TaxCalculation/calculate_others_income`;


   // static INCOME_CALCULATE_Property = Config.apiUrl + `/TaxCalculation/calculate_property_income`;


   // static INCOME_CALCULATE_Share_of_Profit_In_a_Firm = Config.apiUrl + `/TaxCalculation/calculate_share_of_profit_in_firm`;


   // static INCOME_CALCULATE_Spouse_Or_Child = Config.apiUrl + `/TaxCalculation/calculate_spouse_or_child_income`;


   // static TAX_RA_CALCULATE_Adjustment_of_Tax_Refund = Config.apiUrl + `/TaxCalculation/calculate_adjustment_of_tax_refund`;


   // static TAX_RA_CALCULATE_Advance_Paid_Tax = Config.apiUrl + `/TaxCalculation/calculate_advance_tax_paid`;


   // static TAX_RA_CALCULATE_Tax_Deducted_At_Source = Config.apiUrl + `/TaxCalculation/calculate_tax_deducted_at_source`;

   // static TAX_RA_CALCULATE_Tax_Rebate = Config.apiUrl + `/TaxCalculation/calculate_rebate`;

   // static CALCULATE_Net_Wealth_Surcharge = Config.apiUrl + `/TaxCalculation/calculate_net_wealth_surcharge`;


   // static CALCULATE_Interest_or_Any_other_Amount_Under_the_Ordinance = Config.apiUrl + `/TaxCalculation/calculate_interest_or_anyOther_amount_under_the_ordinance`;



   // assessee_returns

   // static ASSESSEE_RETURNS = Config.apiUrl + `/assessee/assessee_returns/${Config.getAssesseeId()}/`;

   // static PROFILECHECK_LEFTMENU_RIGHTPANEL = Config.apiUrl + `/assessee/profilecheck-leftmenu-rightpanel/${Config.getCulture()}/${Config.getAssesseeId()}/`;



   // expenses

   // static EXPENSES_POST = Config.apiUrl + `/expenses/${Config.getAssesseeId()}/`;

   // static EXPENSES_GET = Config.apiUrl + `/expenses/detail/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static EXPENSES_LIST = Config.apiUrl + `/expenses/${Config.getCulture()}/${Config.getAssesseeId()}/`;


   //payment
   // static TAX_PAYMENT_INDIVIUDAL_Url = Config.apiUrl + `/Payment/Individual/${Config.getCulture()}/${Config.getAssesseeId()}/`;

   // static TAX_PAYMENT_INITIATEPAYMENT_Url = Config.apiUrl + `/Payment/InitiatePayment/${Config.getCulture()}/${Config.getAssesseeId()}/`;



   // static DOCUMENT_URL = Config.apiUrl + `/upload-and-download/document/${Config.getCulture()}/${Config.getAssesseeId()}/`;



   // practitioner
   // static PROFILE_POST = Config.apiUrl + `/practitioner-profile/save`;


   // static CLIENT_POST = Config.apiUrl + `/practitioner-profile/save-client`;


   // RETURNS

   static check_asset_filled_up = Config.apiUrl + `/basic_personalize/check_asset_filled_up/${Config.getAssesseeId()}/`;

   static REPORT_DL = Config.apiUrl + `/reports/print-assessee-return/`;

   static SUMMARY_DL = Config.apiUrl + `/reports/print-calculation-summary/`;


   static EXCEL_TEMPLATE_DL = Config.apiUrl + `/practitioner-profile/client-bulk-excel/download-template/`;

   static BULK_SALARY_TEMPLATE_DL = Config.apiUrl + `/practitioner-profile/manage-client/bulk-salary-template`;

   static SLIP_DOWNLOADFILE = Config.apiUrl + `/practitioner-profile/acknowledgement-slip/download/`;

   //document

   static DOCUMENT_DOWNLOADFILE = Config.apiUrl + `/upload-and-download/downloadfile/`;


   static BILL_REGISTER_DOWNLOAD_EXCEL = Config.apiUrl + `/practitioner-transaction/bill-register/download-bill-excel`;


   static COLLECTION_REGISTER_DOWNLOAD_EXCEL = Config.apiUrl + `/practitioner-transaction/collection-register/download-collection-excel`;


   static DUES_REGISTER_DOWNLOAD_EXCEL = Config.apiUrl + `/practitioner-transaction/dues-register/download-collection-excel`;


   static REMISSION_REGISTER_DOWNLOAD_EXCEL = Config.apiUrl + `/practitioner-transaction/remission-register/download-collection-excel`;


   static LEDGER_DOWNLOAD_EXCEL = Config.apiUrl + `/practitioner-transaction/ledger/download-ledger-excel/`;


   static CHALAN_DOWNLOAD = Config.apiUrl + `/challan/save-download-challan-info`;



}
