import { Injectable } from '@angular/core';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { AppUser } from 'app/auth';



@Injectable()
export class TaxReturnService
{
    constructor(private service: Service) { }

    getControlJson(): Observable<BaseControl<string>[]>
    {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.ASSESSEE_RETURNS);
    }

    getReport(): Observable<BaseControl<string>[]>
    {
        return this.service.get(Config.REPORT_DL);
    }

    getAssetFilled():Observable<BaseControl<string>[]>
    {
        return this.service.get(Config.check_asset_filled_up);
    }



    getUserInfo(): AppUser
    {
        let storage = JSON.parse(localStorage.getItem('userInfo'));
        let user = Object.assign(new AppUser(), storage);
        return user;
    }

}
