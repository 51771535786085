import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsListService } from '../ClientsList.service';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';

import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';


@Component({
    selector: 'LedgerDialog',
    templateUrl: 'ledger-Dialog.component.html',
    styleUrls: ['./ledger-Dialog.component.scss'],
})
export class LedgerDialog
{


    lang = '';


    BcTabgetData: any;

    moment: any = moment;


    constructor(
        public dialogRef: MatDialogRef<LedgerDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: ClientsListService,
        private _transactionControlService: TransactionService,

        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        dialogRef.disableClose = true;
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata element');
        this.BcTabSearchLedger();
    }



    BcTabSearchLedger(): void
    {
        this._spinner.show();

        // this.BcTabSearchControl.disable();


        let query = {
            "AssesseId": this.dialogdata.AssesseeId,
            "ReturnId": this.dialogdata.ReturnId,
            // "DateRange": `${moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY/MM/DD")}-${moment(new Date()).format("YYYY/MM/DD")}`,
            // "AssessmentYearId": this.dialogdata.year,
            "DateRange": null,
            // "AssessmentYearId": null
        }

        // console.log(query, 'query');
        this._transactionControlService.LedgerSearchTransaction(query).subscribe(data =>
        {

            // console.log(data, 'data');

            if (data != null)
            {
                // this.BcTabgetData = data.data;
                // this.LgTabTotalDues = data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                // console.log(this.LgTabTotalDues, 'this.LgTabTotalDues');

                if (data.data.length != 0)
                {
                    this.BcTabgetData = data.data;
                }
                else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 4000
                    });
                    this.dialogRef.close('refresh');
                }

                this._spinner.hide();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this.dialogRef.close();
            }

        })

    }


    DownloadExcel()
    {
        let data = {
            "AssesseId": this.dialogdata.AssesseeId,
            "ReturnId": this.dialogdata.ReturnId,
            // "DateRange": `${moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY/MM/DD")}-${moment(new Date()).format("YYYY/MM/DD")}`,
            // "AssessmentYearId": this.dialogdata.year,
            "DateRange": null,
            // "AssessmentYearId": null
        };
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.post(Config.LEDGER_DOWNLOAD_EXCEL, data, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, `ledger_${this.dialogdata.AssesseeId}_${this.dialogdata.ReturnId}.xlsx`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            })
    }


    onNoClick(): void
    {
        this.dialogRef.close("no");
    }







}
