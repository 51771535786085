import Config from './config';



export class UrlConfig
{
  private getCulture(): string
  {
    let ct = localStorage.getItem('selectedLanguage');
    return ct != null ? ct : "en";
  }

  private getAssesseeId(): string
  {
    let ct = JSON.parse(localStorage.getItem('userInfo'));
    let AssesseeId = null;

    if (ct.ActiveRoleId == "8")
    {
      AssesseeId = "0"
    } else
    {
      let cl = JSON.parse(localStorage.getItem('AssesseeId'));
      AssesseeId = cl.AssesseeId;
    }
    return AssesseeId;

    // return ct != null ? ct.AssesseeId : "0";
    // return "0";
  }

  private getReturnId(): string
  {
    let ct = JSON.parse(localStorage.getItem('userInfo'));


    return ct != null ? ct.ReturnId : "0";
    // return "0";
  }

  public get BASIC_INFO_Etin_Others_List_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/etin_and_others/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Etin_Others_Control_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/etin_and_others/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Etin_Others_Submit_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/etin_and_others/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Etin_Others_Delete_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/etin_and_others/${this.getAssesseeId()}/`;
  }

  public get BASIC_INFO_Personal_Information_List_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personal_info/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Personal_Information_Control_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personal_info/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Personal_Information_Submit_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personal_info/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Personal_Information_Delete_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personal_info/${this.getAssesseeId()}/`;
  }

  public get BASIC_INFO_Personalize_Return_List_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personalize_return/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Personalize_Return_Control_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personalize_return/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Personalize_Return_Submit_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personalize_return/${this.getAssesseeId()}/`;
  }
  public get BASIC_INFO_Personalize_Return_Delete_Url(): string
  {
    return Config.apiUrl + `/basic_personalize/personalize_return/${this.getAssesseeId()}/`;
  }


  public get ASSET_Agricultural_Property_List_Url(): string
  {
    return Config.apiUrl + `/assets/agricultural_property/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Agricultural_Property_Control_Url(): string
  {
    return Config.apiUrl + `/assets/agricultural_property/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Agricultural_Property_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/agricultural_property/${this.getAssesseeId()}/`;
  }
  public get ASSET_Agricultural_Property_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/agricultural_property/${this.getAssesseeId()}/`;
  }

  public get ASSET_Buisness_Capital_List_Url(): string
  {
    return Config.apiUrl + `/assets/buisness_capital/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Buisness_Capital_Control_Url(): string
  {
    return Config.apiUrl + `/assets/buisness_capital/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Buisness_Capital_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/buisness_capital/${this.getAssesseeId()}/`;
  }
  public get ASSET_Buisness_Capital_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/buisness_capital/${this.getAssesseeId()}/`;
  }


  public get ASSET_Cash_Assets_List_Url(): string
  {
    return Config.apiUrl + `/assets/cash_assets/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Cash_Assets_Control_Url(): string
  {
    return Config.apiUrl + `/assets/cash_assets/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Cash_Assets_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/cash_assets/${this.getAssesseeId()}/`;
  }
  public get ASSET_Cash_Assets_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/cash_assets/${this.getAssesseeId()}/`;
  }


  public get ASSET_Directors_Shareholding_List_Url(): string
  {
    return Config.apiUrl + `/assets/director_share_holding/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Directors_Shareholding_Control_Url(): string
  {
    return Config.apiUrl + `/assets/director_share_holding/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Directors_Shareholding_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/director_share_holding/${this.getAssesseeId()}/`;
  }
  public get ASSET_Directors_Shareholding_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/director_share_holding/${this.getAssesseeId()}/`;
  }

  public get ASSET_Electronic_Equipment_List_Url(): string
  {
    return Config.apiUrl + `/assets/electronic_equipment/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Electronic_Equipment_Control_Url(): string
  {
    return Config.apiUrl + `/assets/electronic_equipment/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Electronic_Equipment_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/electronic_equipment/${this.getAssesseeId()}/`;
  }
  public get ASSET_Electronic_Equipment_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/electronic_equipment/${this.getAssesseeId()}/`;
  }


  public get ASSET_Furniture_List_Url(): string
  {
    return Config.apiUrl + `/assets/furniture/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Furniture_Control_Url(): string
  {
    return Config.apiUrl + `/assets/furniture/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Furniture_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/furniture/${this.getAssesseeId()}/`;
  }
  public get ASSET_Furniture_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/furniture/${this.getAssesseeId()}/`;
  }

  public get ASSET_Investments_List_Url(): string
  {
    return Config.apiUrl + `/assets/investments/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Investments_Control_Url(): string
  {
    return Config.apiUrl + `/assets/investments/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Investments_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/investments/${this.getAssesseeId()}/`;
  }
  public get ASSET_Investments_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/investments/${this.getAssesseeId()}/`;
  }


  public get ASSET_Jewellery_List_Url(): string
  {
    return Config.apiUrl + `/assets/jewellery/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Jewellery_Control_Url(): string
  {
    return Config.apiUrl + `/assets/jewellery/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Jewellery_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/jewellery/${this.getAssesseeId()}/`;
  }
  public get ASSET_Jewellery_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/jewellery/${this.getAssesseeId()}/`;
  }


  public get ASSET_Motor_Vehicle_List_Url(): string
  {
    return Config.apiUrl + `/assets/motor_vehicle/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Motor_Vehicle_Control_Url(): string
  {
    return Config.apiUrl + `/assets/motor_vehicle/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Motor_Vehicle_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/motor_vehicle/${this.getAssesseeId()}/`;
  }
  public get ASSET_Motor_Vehicle_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/motor_vehicle/${this.getAssesseeId()}/`;
  }


  public get ASSET_Non_Agricultural_Property_List_Url(): string
  {
    return Config.apiUrl + `/assets/non_agricultural_property/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Non_Agricultural_Property_Control_Url(): string
  {
    return Config.apiUrl + `/assets/non_agricultural_property/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Non_Agricultural_Property_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/non_agricultural_property/${this.getAssesseeId()}/`;
  }
  public get ASSET_Non_Agricultural_Property_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/non_agricultural_property/${this.getAssesseeId()}/`;
  }


  public get ASSET_Other_Assets_List_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Other_Assets_Control_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Other_Assets_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets/${this.getAssesseeId()}/`;
  }
  public get ASSET_Other_Assets_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets/${this.getAssesseeId()}/`;
  }


  public get ASSET_Other_Assets_Receipt_List_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets_receipt/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Other_Assets_Receipt_Control_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets_receipt/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Other_Assets_Receipt_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets_receipt/${this.getAssesseeId()}/`;
  }
  public get ASSET_Other_Assets_Receipt_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/other_assets_receipt/${this.getAssesseeId()}/`;
  }


  public get ASSET_Previous_Year_Net_Wealth_List_Url(): string
  {
    return Config.apiUrl + `/assets/previous_year_net_wealth/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Previous_Year_Net_Wealth_Control_Url(): string
  {
    return Config.apiUrl + `/assets/previous_year_net_wealth/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get ASSET_Previous_Year_Net_Wealth_Submit_Url(): string
  {
    return Config.apiUrl + `/assets/previous_year_net_wealth/${this.getAssesseeId()}/`;
  }
  public get ASSET_Previous_Year_Net_Wealth_Delete_Url(): string
  {
    return Config.apiUrl + `/assets/previous_year_net_wealth/${this.getAssesseeId()}/`;
  }


  public get INCOME_Agricultural_List_Url(): string
  {
    return Config.apiUrl + `/income/agriculture/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Agricultural_Control_Url(): string
  {
    return Config.apiUrl + `/income/agriculture/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Agricultural_Submit_Url(): string
  {
    return Config.apiUrl + `/income/agriculture/${this.getAssesseeId()}/`;
  }
  public get INCOME_Agricultural_Delete_Url(): string
  {
    return Config.apiUrl + `/income/agriculture/${this.getAssesseeId()}/`;
  }


  public get INCOME_Buisness_Or_Profession_List_Url(): string
  {
    return Config.apiUrl + `/income/buisness_or_profession/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Buisness_Or_Profession_Control_Url(): string
  {
    return Config.apiUrl + `/income/buisness_or_profession/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Buisness_Or_Profession_Submit_Url(): string
  {
    return Config.apiUrl + `/income/buisness_or_profession/${this.getAssesseeId()}/`;
  }
  public get INCOME_Buisness_Or_Profession_Delete_Url(): string
  {
    return Config.apiUrl + `/income/buisness_or_profession/${this.getAssesseeId()}/`;
  }


  public get INCOME_Capital_Gains_List_Url(): string
  {
    return Config.apiUrl + `/income/capital_gains/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Capital_Gains_Control_Url(): string
  {
    return Config.apiUrl + `/income/capital_gains/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Capital_Gains_Submit_Url(): string
  {
    return Config.apiUrl + `/income/capital_gains/${this.getAssesseeId()}/`;
  }
  public get INCOME_Capital_Gains_Delete_Url(): string
  {
    return Config.apiUrl + `/income/capital_gains/${this.getAssesseeId()}/`;
  }


  public get INCOME_Foreign_Income_List_Url(): string
  {
    return Config.apiUrl + `/income/foreign_income/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Foreign_Income_Control_Url(): string
  {
    return Config.apiUrl + `/income/foreign_income/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Foreign_Income_Submit_Url(): string
  {
    return Config.apiUrl + `/income/foreign_income/${this.getAssesseeId()}/`;
  }
  public get INCOME_Foreign_Income_Delete_Url(): string
  {
    return Config.apiUrl + `/income/foreign_income/${this.getAssesseeId()}/`;
  }


  public get INCOME_Interest_On_Securities_List_Url(): string
  {
    return Config.apiUrl + `/income/interest_on_securities/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Interest_On_Securities_Control_Url(): string
  {
    return Config.apiUrl + `/income/interest_on_securities/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Interest_On_Securities_Submit_Url(): string
  {
    return Config.apiUrl + `/income/interest_on_securities/${this.getAssesseeId()}/`;
  }
  public get INCOME_Interest_On_Securities_Delete_Url(): string
  {
    return Config.apiUrl + `/income/interest_on_securities/${this.getAssesseeId()}/`;
  }


  public get INCOME_Other_Resources_List_Url(): string
  {
    return Config.apiUrl + `/income/other_resources/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Other_Resources_Control_Url(): string
  {
    return Config.apiUrl + `/income/other_resources/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Other_Resources_Submit_Url(): string
  {
    return Config.apiUrl + `/income/other_resources/${this.getAssesseeId()}/`;
  }
  public get INCOME_Other_Resources_Delete_Url(): string
  {
    return Config.apiUrl + `/income/other_resources/${this.getAssesseeId()}/`;
  }


  public get INCOME_Property_List_Url(): string
  {
    return Config.apiUrl + `/income/property/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Property_Control_Url(): string
  {
    return Config.apiUrl + `/income/property/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Property_Submit_Url(): string
  {
    return Config.apiUrl + `/income/property/${this.getAssesseeId()}/`;
  }
  public get INCOME_Property_Delete_Url(): string
  {
    return Config.apiUrl + `/income/property/${this.getAssesseeId()}/`;
  }


  public get INCOME_Salary_Private_List_Url(): string
  {
    // return Config.apiUrl + `/income/salary_private/${this.getCulture()}/`;
    return Config.apiUrl + `/income/salary/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Salary_Private_Control_Url(): string
  {
    // return Config.apiUrl + `/income/salary_private/detail/${this.getCulture()}/`;
    return Config.apiUrl + `/income/salary/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Salary_Private_Submit_Url(): string
  {
    // return Config.apiUrl + `/income/salary_private/`;
    return Config.apiUrl + `/income/salary/${this.getAssesseeId()}/`;
  }
  public get INCOME_Salary_Private_Delete_Url(): string
  {
    // return Config.apiUrl + `/income/salary_private/`;
    return Config.apiUrl + `/income/salary/${this.getAssesseeId()}/`;
  }


  public get INCOME_Salary_Govt_List_Url(): string
  {
    return Config.apiUrl + `/income/salary_private/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Salary_Govt_Control_Url(): string
  {
    return Config.apiUrl + `/income/salary_private/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Salary_Govt_Submit_Url(): string
  {
    return Config.apiUrl + `/income/salary_private/${this.getAssesseeId()}/`;
  }
  public get INCOME_Salary_Govt_Delete_Url(): string
  {
    return Config.apiUrl + `/income/salary_private/${this.getAssesseeId()}/`;
  }

  public get INCOME_Share_of_Profit_In_a_Firm_List_Url(): string
  {
    return Config.apiUrl + `/income/share_of_profit_in_a_firm/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Share_of_Profit_In_a_Firm_Control_Url(): string
  {
    return Config.apiUrl + `/income/share_of_profit_in_a_firm/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Share_of_Profit_In_a_Firm_Submit_Url(): string
  {
    return Config.apiUrl + `/income/share_of_profit_in_a_firm/${this.getAssesseeId()}/`;
  }
  public get INCOME_Share_of_Profit_In_a_Firm_Delete_Url(): string
  {
    return Config.apiUrl + `/income/share_of_profit_in_a_firm/${this.getAssesseeId()}/`;
  }


  public get INCOME_Spouse_Or_Child_List_Url(): string
  {
    return Config.apiUrl + `/income/spouse_or_child/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Spouse_Or_Child_Control_Url(): string
  {
    return Config.apiUrl + `/income/spouse_or_child/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get INCOME_Spouse_Or_Child_Submit_Url(): string
  {
    return Config.apiUrl + `/income/spouse_or_child/${this.getAssesseeId()}/`;
  }
  public get INCOME_Spouse_Or_Child_Delete_Url(): string
  {
    return Config.apiUrl + `/income/spouse_or_child/${this.getAssesseeId()}/`;
  }

  public get LIABILITIES_Bank_Loans_List_Url(): string
  {
    return Config.apiUrl + `/liabilities/bank_loans/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Bank_Loans_Control_Url(): string
  {
    return Config.apiUrl + `/liabilities/bank_loans/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Bank_Loans_Submit_Url(): string
  {
    return Config.apiUrl + `/liabilities/bank_loans/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Bank_Loans_Delete_Url(): string
  {
    return Config.apiUrl + `/liabilities/bank_loans/${this.getAssesseeId()}/`;
  }


  public get LIABILITIES_Mortgages_List_Url(): string
  {
    return Config.apiUrl + `/liabilities/mortgages/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Mortgages_Control_Url(): string
  {
    return Config.apiUrl + `/liabilities/mortgages/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Mortgages_Submit_Url(): string
  {
    return Config.apiUrl + `/liabilities/mortgages/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Mortgages_Delete_Url(): string
  {
    return Config.apiUrl + `/liabilities/mortgages/${this.getAssesseeId()}/`;
  }


  public get LIABILITIES_Other_Liabilities_List_Url(): string
  {
    return Config.apiUrl + `/liabilities/other_liabilities/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Other_Liabilities_Control_Url(): string
  {
    return Config.apiUrl + `/liabilities/other_liabilities/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Other_Liabilities_Submit_Url(): string
  {
    return Config.apiUrl + `/liabilities/other_liabilities/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Other_Liabilities_Delete_Url(): string
  {
    return Config.apiUrl + `/liabilities/other_liabilities/${this.getAssesseeId()}/`;
  }


  public get LIABILITIES_Unsecured_Loans_List_Url(): string
  {
    return Config.apiUrl + `/liabilities/unsecured_loans/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Unsecured_Loans_Control_Url(): string
  {
    return Config.apiUrl + `/liabilities/unsecured_loans/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Unsecured_Loans_Submit_Url(): string
  {
    return Config.apiUrl + `/liabilities/unsecured_loans/${this.getAssesseeId()}/`;
  }
  public get LIABILITIES_Unsecured_Loans_Delete_Url(): string
  {
    return Config.apiUrl + `/liabilities/unsecured_loans/${this.getAssesseeId()}/`;
  }


  public get TAX_RA_Adjustment_of_Tax_Refund_List_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Adjustment_of_Tax_Refund_Control_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Adjustment_of_Tax_Refund_Submit_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Adjustment_of_Tax_Refund_Delete_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/adjustment_of_tax_refund/${this.getAssesseeId()}/`;
  }

  public get TAX_RA_Advance_Paid_Tax_List_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/advance_paid_tax/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Advance_Paid_Tax_Control_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/advance_paid_tax/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Advance_Paid_Tax_Submit_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/advance_paid_tax/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Advance_Paid_Tax_Delete_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/advance_paid_tax/${this.getAssesseeId()}/`;
  }


  public get TAX_RA_Tax_Deducted_At_Source_List_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_deducted_at_source/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Tax_Deducted_At_Source_Control_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_deducted_at_source/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Tax_Deducted_At_Source_Submit_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_deducted_at_source/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Tax_Deducted_At_Source_Delete_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_deducted_at_source/${this.getAssesseeId()}/`;
  }

  public get TAX_RA_Tax_Rebate_List_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_rebate/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Tax_Rebate_Control_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_rebate/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Tax_Rebate_Submit_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_rebate/${this.getAssesseeId()}/`;
  }
  public get TAX_RA_Tax_Rebate_Delete_Url(): string
  {
    return Config.apiUrl + `/tax_rebate/tax_rebate/${this.getAssesseeId()}/`;
  }

  public get VOUCHER_CODE_APPLY(): string
  {
    return Config.apiUrl + `/Payment/VoucherCodeApply`;
  }

  public get TAX_PAYMENT_INDIVIUDAL_Url(): string
  {
    return Config.apiUrl + `/Payment/Individual/${this.getCulture()}`;
  }

  public get CHALLAN_COMMON_DROPDOWN(): string
  {
    return Config.apiUrl + `/challan/common-dropdown/`;
  }

  public get LOAD_CHALLAN_BASIC_INFO(): string
  {
    return Config.apiUrl + `/challan/load-challan-basic-info`;
  }

  public get LOAD_EMAIL_BASIC_INFO(): string
  {
    return Config.apiUrl + `/practitioner-profile/manage-client-attachment-list`;
  }

  public get MANAGE_CLIENT_EMAIL(): string
  {
    return Config.apiUrl + `/reports/manage-client-email`;
  }



  // Challan Common Dropdown

  public get TAX_PAYMENT_INITIATEPAYMENT_Url(): string
  {
    return Config.apiUrl + `/Payment/InitiatePayment/${this.getCulture()}`;
  }

  public get EXPENSES_POST(): string
  {
    return Config.apiUrl + `/expenses/${this.getAssesseeId()}/`;
  }

  public get EXPENSES_GET(): string
  {
    return Config.apiUrl + `/expenses/detail/${this.getCulture()}/${this.getAssesseeId()}/`;
  }

  public get EXPENSES_LIST(): string
  {
    return Config.apiUrl + `/expenses/${this.getCulture()}/${this.getAssesseeId()}/`;
  }

  public get DASHBOARD_GETDATA_URL(): string
  {
    return Config.apiUrl + `/Dashboard/get-items/${this.getCulture()}/${this.getAssesseeId()}/`;
  }


  public get DOCUMENT_URL(): string
  {
    return Config.apiUrl + `/upload-and-download/document/${this.getCulture()}/${this.getAssesseeId()}/`;
  }

  public get DOCUMENT_URL_WO_ASSESSEID(): string
  {
    return Config.apiUrl + `/upload-and-download/document/${this.getCulture()}/`;
  }

  public get DOCUMENT_ASSESSEE_URL(): string
  {
    return Config.apiUrl + `/upload-and-download/assessee-document/${this.getCulture()}/${this.getAssesseeId()}/`;
  }

  public get DOCUMENT_DETAIL(): string
  {
    return Config.apiUrl + `/upload-and-download/assessee-document/detail/${this.getAssesseeId()}/`;
  }

  public get DOCUMENT_DELETE(): string
  {
    return Config.apiUrl + `/upload-and-download/assessee-document/delete/`;
  }

  public get DOCUMENT_DOWNLOADFILE(): string
  {
    return Config.apiUrl + `/upload-and-download/downloadfile/`;
  }

  public get DOCUMENT_SUBMIT(): string
  {
    return Config.apiUrl + `/upload-and-download/assessee-document/${this.getAssesseeId()}/`;
  }


  public get SLIP_SUBMIT(): string
  {
    return Config.apiUrl + `/practitioner-profile/acknowledgement-slip/save/`;
  }

  public get SLIP_DOWNLOADFILE(): string
  {
    return Config.apiUrl + `/practitioner-profile/acknowledgement-slip/downloadfile/`;
  }

  public get SLIP_DELETE(): string
  {
    return Config.apiUrl + `/practitioner-profile/acknowledgement-slip/delete/`;
  }

  public get DOCUMENT_UPDATE_COMMENT(): string
  {
    return Config.apiUrl + `/upload-and-download/assessee-document/update-comment/`;
  }

  public get PROFILE_POST(): string
  {
    return Config.apiUrl + `/practitioner-profile/save`;
  }

  public get CLIENT_POST(): string
  {
    return Config.apiUrl + `/practitioner-profile/save-client`;
  }


  public get BULK_UPLOAD_CLIENT_SUBMIT(): string
  {
    return Config.apiUrl + `/practitioner-profile/client-bulk-excel/`;
  }

  public get BULK_UPLOAD_SALARY_CLIENT_SUBMIT(): string
  {
    return Config.apiUrl + `/practitioner-profile/manage-client/bulk-salary`;
  }

  public get CLIENT_DASHBOARD_ACCOUNTING_SEARCHTRANSACTION(): string
  {
    return Config.apiUrl + `/practitioner-transaction/client-dashboard-accounting/searchtransaction/`;
  }



  public get PROFILE_DETAIL(): string
  {
    return Config.apiUrl + `/practitioner-profile/detail/`;
  }

  public get CHECK_PROFILE(): string
  {
    return Config.apiUrl + `/practitioner-profile/is-complete-profile/`;
  }


  public get GET_PROFILE_CONTACT(): string
  {
    return Config.apiUrl + `/assessee/get-profile-contact/`;
  }

  public get GET_PROFILE_BASIC(): string
  {
    return Config.apiUrl + `/assessee/get-profile-basic/`;
  }

  public get UPDATE_PROFILE_BASIC(): string
  {
    return Config.apiUrl + `/assessee/update-profile-basic/`;
  }

  public get PRACTITIONER_GET_CLIENTS_DETAIL(): string
  {
    return Config.apiUrl + `/practitioner-profile/client-detail/`;
  }

  public get PRACTITIONER_GET_CLIENTS_COMMON_DROPDOWN(): string
  {
    return Config.apiUrl + `/practitioner-profile/manage-client/common-dropdown/`;
  }

  public get PRACTITIONER_GET_CLIENTS_DASHBOARD(): string
  {
    return Config.apiUrl + `/practitioner-profile/manage-client/dashboard/`;
  }

  public get PRACTITIONER_GET_CLIENTS(): string
  {
    return Config.apiUrl + `/practitioner-profile/manage-client/get-clients/`;
  }

  public get PRACTITIONER_DELETE_CLIENTS(): string
  {
    return Config.apiUrl + `/practitioner-profile/client-delete/`;
  }


  public get PROFILECHECK_LEFTMENU_RIGHTPANEL(): string
  {
    return Config.apiUrl + `/assessee/profilecheck-leftmenu-rightpanel/${this.getCulture()}/${this.getAssesseeId()}`;
  }

  public get PROFILE_CHECK_ONLY(): string
  {
    return Config.apiUrl + `/assessee/profilecheck-only/${this.getCulture()}/${this.getAssesseeId()}`;
  }

  public get check_asset_filled_up(): string
  {
    return Config.apiUrl + `/basic_personalize/check_asset_filled_up/${this.getAssesseeId()}/`;
  }

  public get ASSESSEE_RETURNS(): string
  {
    return Config.apiUrl + `/assessee/assessee_returns/${this.getAssesseeId()}/`;
  }

  public get REPORT_DL(): string
  {
    return Config.apiUrl + `/reports/print-assessee-return/`;
  }

  static SUMMARY_DL = Config.apiUrl + `/reports/print-calculation-summary/`;

  public get CHANGE_PASSWORD(): string
  {
    return Config.rootUrl + `/UserAuth/changepassword`;
  }

  public get ARCHIVE_READINESS(): string
  {
    return Config.apiUrl + `/archive/get-alert`;
  }

  public get ARCHIVE_PREPARE(): string
  {
    return Config.apiUrl + `/archive/prepare-archive`;
  }

  public get ARCHIVE_POST(): string
  {
    return Config.apiUrl + `/archive/send-to-archive`;
  }

  public get MANAGE_CLIENT_SEARCH_AUTOCOMPLETE(): string
  {
    return Config.apiUrl + `/practitioner-profile/manage-client/clientbyautocomplete`;
  }


  public get TRANSACTION_SEARCH_AUTOCOMPLETE(): string
  {
    return Config.apiUrl + `/practitioner-transaction/bill-n-collection/clientbyautocomplete`;
  }

  public get TRANSACTION_COMMON_DROPDOWN(): string
  {
    return Config.apiUrl + `/practitioner-transaction/bill-n-collection/common-dropdown`;
  }

  public get TRANSACTION_ACCOUNT_HEAD(): string
  {
    return Config.apiUrl + `/practitioner-transaction/bill-n-collection/practitioner-account-head`;
  }

  

  public get TRANSACTION_SEARCH(): string
  {
    return Config.apiUrl + `/practitioner-transaction/bill-n-collection/searchtransaction`;
  }

  public get TRANSACTION_CLIENT_SEARCH(): string
  {
    return Config.apiUrl + `/practitioner-transaction/bill-n-collection/searchclientdetails`;
  }

  public get TRANSACTION_SAVE(): string
  {
    return Config.apiUrl + `/practitioner-transaction/bill-n-collection/save`;
  }

  public get REMISSION_SAVE(): string
  {
    return Config.apiUrl + `/practitioner-transaction/remission/save-remission`;
  }

  public get REMISSION_SEARCH(): string
  {
    return Config.apiUrl + `/practitioner-transaction/remission-register/searchtransaction`;
  }

  public get DUES_SEARCH(): string
  {
    return Config.apiUrl + `/practitioner-transaction/dues-register/searchtransaction`;
  }

  public get BILL_REGISTER_SEARCH_TRANSACTION(): string
  {
    return Config.apiUrl + `/practitioner-transaction/bill-register/searchtransaction`;
  }

  public get COLLECTION_REGISTER_SEARCH_TRANSACTION(): string
  {
    return Config.apiUrl + `/practitioner-transaction/collection-register/searchtransaction`;
  }

  public get LEDGER_SEARCH_TRANSACTION(): string
  {
    return Config.apiUrl + `/practitioner-transaction/ledger/searchledger`;
  }

  public get NOTIFICATION_INIT_PROPERTIES(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/initial-properties`;
  }

  public get NOTIFICATION_SMS_BALANCE_BY_ID(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/smsbalance-byid/`;
  }


  public get NOTIFICATION_CLIENT_BY_AUTO_COMPLETE(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/clientbyautocomplete`;
  }

  public get NOTIFICATION_SAVE_TEMPLATE(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/save-template`;
  }

  public get NOTIFICATION_UPDATE_TEMPLATE(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/update-template`;
  }

  public get NOTIFICATION_DELETE_TEMPLATE(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/delete-template`;
  }

  public get NOTIFICATION_PREPARE_CLIENTS(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/prepareclients`;
  }

  public get NOTIFICATION_PREPARE_SINGLE_CLIENT(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/preparesingleclient`;
  }

  public get NOTIFICATION_SEND(): string
  {
    return Config.apiUrl + `/practitioner-client-notifications/manage-sms`;
  }

  public get NOTIFICATION_MANAGE_CLIENT_NOTICE(): string
  {
    return Config.apiUrl + `/practitioner-transaction/manage-client-notice`;
  }



  public get SWITCH_RETURN(): string
  {
    return Config.rootUrl + `/UserAuth/SwitchReturn`;
  }

  public get EXIT_RETURN(): string
  {
    return Config.rootUrl + `/UserAuth/Exit-SwitchReturn`;
  }

  



  // static TAX_PAYMENT_INDIVIUDAL_Url = Config.apiUrl + `/Payment/Individual/en`;

  // static TAX_PAYMENT_INITIATEPAYMENT_Url = Config.apiUrl + `/Payment/InitiatePayment/en`;

}


