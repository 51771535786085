
export class BankLoansClass
{
  constructor(
    private _OpeningBalanceAmount: number,
    private _LoanOutstandingAmount: number,
    private _PrincipalPaidAmount: number
  )
  {

  }



  public get OpeningBalanceAmount(): number
  {
    return this._OpeningBalanceAmount;
  }
  public set OpeningBalanceAmount(x: number)
  {
    this._OpeningBalanceAmount = x;
  }

  public get LoanOutstandingAmount(): number
  {
    return this._LoanOutstandingAmount;
  }
  public set LoanOutstandingAmount(x: number)
  {
    this._LoanOutstandingAmount = x;
  }

  public get PrincipalPaidAmount(): number
  {
    return this._PrincipalPaidAmount = Number(this.OpeningBalanceAmount)
      - Number(this.LoanOutstandingAmount)
  }
  public set PrincipalPaidAmount(x: number)
  {
    this._PrincipalPaidAmount = x;
  }

}
