import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';

@Injectable()
export class NotificationService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }


  getControlJson(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.PROFILE_DETAIL);
  }

  CheckControlJson(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.CHECK_PROFILE);
  }

  ClientAutoComplete(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.NOTIFICATION_CLIENT_BY_AUTO_COMPLETE, model);
  }


  SaveTemplate(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.NOTIFICATION_SAVE_TEMPLATE, model);
  }

  UpdateTemplate(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.NOTIFICATION_UPDATE_TEMPLATE, model);
  }

  DeleteTemplate(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.NOTIFICATION_DELETE_TEMPLATE, model);
  }

  GetCommomDropdown(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.NOTIFICATION_INIT_PROPERTIES);
  }

  GetSmsBalanceById(id): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.NOTIFICATION_SMS_BALANCE_BY_ID + `${id}`);
  }


  NotificationPrepareClients(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.NOTIFICATION_PREPARE_CLIENTS, model);
  }


  NotificationPrepareSingleClient(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.NOTIFICATION_PREPARE_SINGLE_CLIENT, model);
  }



  SendNotification(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.NOTIFICATION_SEND, model);
  }


  getJson(): any
  {
    const jsonData = [
      {

        "data": [
          {
            "source": "Name (as per trade license)",
            "value": "--",
          },
          {
            "source": "ITP",
            "value": "--",
          },
          {
            "source": "Bar Membership Number",
            "value": "--",
          },
          {
            "source": "Mobile No.",
            "value": "--",
          },
          {
            "source": "Contact Email",
            "value": "--",
          },
          {
            "source": "Address",
            "value": "--",
          },
          {
            "source": "City",
            "value": "--",
          },
        ]

      }


    ]

    return jsonData;
  }

  getControls(): Promise<any>
  {
    return new Promise((resolve) =>
    {

      resolve(this.getJson)

    });
  }

}
