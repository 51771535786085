export class AppUser
{
    Id: string;
    UserId: string;
    UserName: string;
    Name: string;
    Expired: string;
    ActiveRoleName: string;
    RoleNames: string;
    ActiveRoleId: string;
    RoleIds: string;
    ConnectionName: string;
    ActiveModule: string;
    PermittedModules: string;
    PhotoUrl: string;
    AssesseeId: string;
    ReturnId: string;
}
