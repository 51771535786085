import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SalaryEmitterService
{
  // private messageSource = new BehaviorSubject('NoMessage');
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  // changeMessage(message: string)
  // {
  //   this.messageSource.next(message)
  // }
  changeMessage(message: any)
  {
    this.messageSource.next(message)
  }

}
