
export class JewelleryModel
{




  ALEId: any;
  SlNo: any;
  Amount: string = "";
  Description: string = "";
  UnknownAmount: boolean = false;



}
