<h2 mat-dialog-title>Send Files via Email</h2>
<mat-dialog-content class="mat-typography"
    *ngIf="PageResponseData!=null">

    <div fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16"
        style="margin-top: 10px">
        <span class="h2"
            style="padding: 10px; margin-left: 10px;">Select file to be sent</span>
        <mat-selection-list [formControl]="MainListGroupControl"
            id="MainListGroupControl"
            (selectionChange)="onSelect($event)"
            fxLayout="row wrap"
            required>
            <mat-list-option *ngFor="let option of MainListGroup"
                fxLayoutAlign="start start"
                [value]="option"
                style="height: auto; margin-bottom: 20px;"
                [disabled]="SlipDisabled && option.DocumentName == 'Acknowledgement Slip'"
                checkboxPosition="before">
                <span style="color: #444444; font-weight: bold;">{{option.DocumentName}}</span>
            </mat-list-option>

        </mat-selection-list>
        <!-- <mat-selection-list [formControl]="SubListGroupControl"
            if="SubListGroup != null"
            fxLayout="row wrap"
            required>
            <mat-list-option *ngFor="let option of SubListGroup"
                fxLayoutAlign="start start"
                [value]="option"
                style="height: auto; margin-bottom: 20px; width: 50%;"
                checkboxPosition="before">
                <span style="color: #444444; font-weight: bold;">{{option.DocumentName}}</span>
            </mat-list-option>

        </mat-selection-list> -->
        <div fxLayout="column"
            fxLayoutAlign="start start"
            id="ChalanDiv"
            class="hidden">
            <div fxLayout="row wrap"
                fxLayoutAlign="space-between"
                style="margin-bottom: 10px">
                <div fxLayout="row wrap"
                    fxLayoutAlign="start center"
                    style="margin: 5px;">

                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Tax Amount:
                    </span>

                    <mat-form-field appearance="outline"
                        class="readonly">
                        <input class="rightA"
                            readonly
                            [formControl]="TaxAmountControl"
                            matInput>

                    </mat-form-field>

                </div>
                <div fxLayout="row wrap"
                    fxLayoutAlign="start center"
                    style="margin: 5px;">

                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Chalan Amount:
                    </span>

                    <mat-form-field appearance="outline">
                        <input class="rightA"
                            NumbersOnly
                            [formControl]="ChalanAmountControl"
                            matInput>

                    </mat-form-field>

                </div>
            </div>
            <div fxLayout="row wrap"
                fxLayoutAlign="start"
                style="margin-bottom: 10px">
                <div fxLayout="row wrap"
                    fxLayoutAlign="start center"
                    style="margin: 5px;">

                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Payment Mode:
                    </span>

                    <mat-radio-group fxLayout="row wrap"
                        [formControl]="PaymentModeControl">
                        <mat-radio-button style="margin: 5px;"
                            *ngFor="let option of paytypes"
                            [value]="option.key"
                            (change)="radioChangeN($event)">
                            {{option.value}}
                        </mat-radio-button>
                    </mat-radio-group>

                </div>
            </div>
            <!-- <div fxLayout="row wrap"
                id="Cheque"
                fxLayoutAlign="start center"
                style="margin-bottom: 10px"
                class="hidden">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Cheque/ P.O. No:
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <input class="leftA"
                        [formControl]="ChequePOControl"
                        matInput>

                </mat-form-field>

            </div> -->
            <div fxLayout="row wrap"
                fxLayoutAlign="start start"
                id="BankN"
                style="margin-bottom: 10px"
                class="hidden">
                <div fxLayout="column"
                    fxLayoutAlign="start"
                    style="margin: 10px;">
                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Cheque/ P.O. No:
                    </span>

                    <mat-form-field appearance="outline"
                        fxFlex="100">
                        <input class="leftA"
                            [formControl]="ChequePOControl"
                            matInput>

                    </mat-form-field>
                </div>
                <div fxLayout="column"
                    fxLayoutAlign="start"
                    style="margin: 10px;">

                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Bank:
                    </span>

                    <mat-form-field appearance="outline"
                        fxFlex="100">
                        <mat-select [formControl]="BankNamesControl">
                            <mat-option *ngFor="let option of DropdownData.Banks"
                                [value]="option">
                                {{option.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div fxLayout="column"
                    fxLayoutAlign="start"
                    style="margin: 10px;">

                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Branch Name:
                    </span>

                    <mat-form-field appearance="outline"
                        fxFlex="100">
                        <input class="leftA"
                            [formControl]="BranchNamesControl"
                            matInput>

                    </mat-form-field>

                </div>
            </div>
            <div fxLayout="row wrap"
                fxLayoutAlign="start start"
                id="TransactionN"
                style="margin-bottom: 10px"
                class="hidden">
                <div fxLayout="column"
                    fxLayoutAlign="start"
                    style="margin: 10px;">
                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Transaction No:
                    </span>

                    <mat-form-field appearance="outline"
                        fxFlex="100">
                        <input class="leftA"
                            [formControl]="TransactionNoControl"
                            matInput>

                    </mat-form-field>
                </div>

                <div fxLayout="column"
                    fxLayoutAlign="start"
                    style="margin: 10px;">

                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Remarks:
                    </span>

                    <mat-form-field appearance="outline"
                        fxFlex="100">
                        <input class="leftA"
                            [formControl]="TransactionRemarksControl"
                            matInput>

                    </mat-form-field>

                </div>
            </div>
            <div fxLayout="row wrap"
                fxLayoutAlign="start"
                style="margin-bottom: 10px">
                <div fxLayout="row wrap"
                    fxLayoutAlign="start center"
                    style="margin: 5px;">

                    <span class="name"
                        style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                        Submitted By:
                    </span>

                    <mat-radio-group fxLayout="row wrap"
                        [formControl]="SubmittedByControl">
                        <mat-radio-button style="margin: 5px;"
                            value="1">
                            Self
                        </mat-radio-button>
                        <mat-radio-button style="margin: 5px;"
                            value="2">
                            ITP
                        </mat-radio-button>
                    </mat-radio-group>

                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end"
    *ngIf="PageResponseData!=null">
    <button class="mat-button mat-button-base primary-100 secondary-text"
        cdkFocusInitial
        mat-dialog-close>Cancel</button>
    <button class="mat-button mat-button-base dupl"
        (click)="SendEmail()">
        SEND
    </button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
