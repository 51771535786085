<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">

                    <h2>{{ 'HOW_IT_WORKS.HEADER' | translate }}</h2>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- <section class="solution-area ptb-100 extra-pb jarallax"
    data-jarallax='{"speed": 0.3}'
    style="margin-bottom: 200px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">{{ 'HOME.V_TAG_8' | translate }}</span>
                    <h2>{{ 'HOW_IT_WORKS.HEADER_1' | translate }}</h2>
                    <p>
                    </p>
                    <a routerLink="/contact"
                        class="default-btn">{{ 'CONTACT.CONTACT_US' | translate }}<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=-f7pLaUHv8g"
                        class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="solution-area-1"
    style="padding-top: 100px;padding-bottom: 100px;margin-bottom: 100px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <!-- <span class="sub-title">{{ 'HOME.V_TAG_8' | translate }}</span> -->
                    <h2>{{ 'HOW_IT_WORKS.HEADER_1' | translate }}</h2>
                    <p>
                    </p>
                    <a routerLink="/contact"
                        class="default-btn">{{ 'CONTACT.CONTACT_US' | translate }}<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item"
                        src="https://www.youtube.com/embed/-f7pLaUHv8g"
                        allowfullscreen></iframe>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="about-area ptb-100 extra-pb"
    style="padding-top: 0; ">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12"
                style="margin-bottom: 100px;">
                <div class="about-content">
                    <span class="sub-title"
                        style="margin-top: 10px; text-align: center;">{{ 'HOW_IT_WORKS.TAB_1_HEADER' | translate
                        }}</span>

                    <div class="about-image">
                        <img src="assets/home/img/Frame_339.png"
                            alt="image">
                    </div>

                </div>
            </div>

            <div class="col-lg-6 col-md-12 offset-lg-0 offset-md-4 offset-sm-4">

                <div class="about-text">

                    <ul class="features-list">
                        <li>{{ 'HOW_IT_WORKS.TAB_1_ITEM_1' | translate }}
                            <ul style="margin-top: 10px;">
                                <li>&bull; {{ 'HOW_IT_WORKS.TAB_1_ITEM_2' | translate }}</li>
                                <li>&bull; {{ 'HOW_IT_WORKS.TAB_1_ITEM_3' | translate }}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12"
                style="margin-bottom: 100px;">
                <div class="about-content">
                    <span class="sub-title"
                        style="margin-top: 10px; text-align: center;">{{ 'HOW_IT_WORKS.TAB_2_HEADER' | translate
                        }}</span>

                    <div class="about-image">
                        <img src="assets/home/img/Frame_340.png"
                            alt="image">
                    </div>

                </div>
            </div>

            <div class="col-lg-6 col-md-12 offset-lg-0 offset-md-4 offset-sm-4">

                <div class="about-text">

                    <ul class="features-list">
                        <li>{{ 'HOW_IT_WORKS.TAB_2_ITEM_1' | translate }}</li>
                        <li>&nbsp;&nbsp; {{ 'HOW_IT_WORKS.TAB_2_ITEM_2' | translate }}</li>
                        <li>&nbsp;&nbsp; {{ 'HOW_IT_WORKS.TAB_2_ITEM_3' | translate }}</li>
                        <li>&nbsp;&nbsp; {{ 'HOW_IT_WORKS.TAB_2_ITEM_4' | translate }}</li>
                        <li>&nbsp;&nbsp; {{ 'HOW_IT_WORKS.TAB_2_ITEM_5' | translate }}</li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12"
                style="margin-bottom: 100px;">
                <div class="about-content">
                    <span class="sub-title"
                        style="margin-top: 10px; text-align: center;">{{ 'HOW_IT_WORKS.TAB_3_HEADER' | translate
                        }}</span>

                    <div class="about-image">
                        <img src="assets/home/img/Frame_341.png"
                            alt="image">
                    </div>

                </div>
            </div>

            <div class="col-lg-6 col-md-12 offset-lg-0 offset-md-4 offset-sm-4">

                <div class="about-text">

                    <ul class="features-list">
                        <li> {{ 'HOW_IT_WORKS.TAB_3_ITEM_1' | translate }}</li>
                        <li> {{ 'HOW_IT_WORKS.TAB_3_ITEM_2' | translate }}</li>
                        <li> {{ 'HOW_IT_WORKS.TAB_3_ITEM_3' | translate }}</li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12"
            style="margin-bottom: 100px;">
            <div class="about-content">
                <span class="sub-title"
                    style="margin-top: 10px; text-align: center;">{{ 'HOW_IT_WORKS.TAB_4_HEADER' | translate
                    }}</span>

                <div class="about-image">
                    <img src="assets/home/img/Frame_342.png"
                        alt="image">
                </div>

            </div>
        </div>

        <div class="col-lg-6 col-md-12 offset-lg-0 offset-md-4 offset-sm-4">

            <div class="about-text">

                <ul class="features-list">
                    <li> {{ 'HOW_IT_WORKS.TAB_4_ITEM_1' | translate }}</li>
                    <li> {{ 'HOW_IT_WORKS.TAB_4_ITEM_2' | translate }}</li>
                    <li> {{ 'HOW_IT_WORKS.TAB_4_ITEM_3' | translate }}</li>

                </ul>
            </div>
        </div>

        </div>

    </div>

</section>
