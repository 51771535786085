import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseControl } from "app/models/dynamic-form";
import { of, Observable } from "rxjs";
import { RadioGroupControl } from "app/models/dynamic-form/radioGroup-control";
import Utils from "app/util/utils";
import Config from 'app/util/config';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';


@Injectable()
export class PersonalInformationService
{
  constructor(private service: Service) { }


  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }


  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    if (id != null)
    {


      return this.service.get(urlConfig.BASIC_INFO_Personal_Information_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));
    } else
    {
   

      return this.service.get(urlConfig.BASIC_INFO_Personal_Information_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }

  }

  submitPersonalInfoJson(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.BASIC_INFO_Personal_Information_Submit_Url, model);
  }

  getJson(): any
  {

    const jsonData = [
      {
        "control": "LabelControl",
        "key": "personalInformation",
        "label": "Personal Information",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 1
      },
      {
        "control": "TextboxControl",
        "key": "AssesseeName",
        "label": "Full Name",
        "errorType": "",
        "message": "Required",
        "tooltip": "Taxpayer's full name as per e-tin certificate",
        "required": true,
        "order": 2
      },
      {
        "control": "TextboxGroupControl",
        "key": "gendernDob",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 3,
        "children": [
          {
            "control": "TextboxControl",
            "key": "NID",
            "label": "NID",
            "required": false,
            "maxlength": "17",
            "minlength": "10",
            "errorType": "",
            "message": "Required",
            "tooltip": "The national identification number given by Bangladesh election commission.",
            "order": 1
          },
          {
            "control": "DateSelectorControl",
            "key": "DateOfBirth",
            "label": "Date of Birth",
            "value": "",
            "required": true,
            "errorType": "",
            "message": "Required",
            "tooltip": "Taxpayer's date of birth. For senior citizens aged 65 years or above, tax exemption limit is Tk. 3,50,000.",
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "parentsbox",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 4,
        "children": [
          {
            "control": "TextboxControl",
            "key": "FatherName",
            "label": "Father's Name",
            "required": false,
            "errorType": "",
            "message": "",
            "tooltip": "Taxpayer's father's name",
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "MotherName",
            "label": "Mother's Name",
            "required": false,
            "errorType": "",
            "message": "",
            "tooltip": "Taxpayer's mother's name",
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "nidMarital",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 5,
        "children": [

          {
            "control": "RadioButtonControl",
            "key": "Gender",
            "label": "Gender",
            "errorType": "",
            "message": "Required",
            "tooltip": "Taxpayer's gender. For male taxpayer, tax exemption limit is Tk 3,00,000 and for female, exemption limit is Tk 3,50,000.",
            "required": true,
            "order": 1,
            "options": [
              {
                "key": "1",
                "value": "Male"
              },
              {
                "key": "2",
                "value": "Female"
              }
            ]
          },
          {
            "control": "RadioButtonControl",
            "key": "MaritalStatus",
            "label": "Marital Status",
            "errorType": "",
            "message": "Required",
            "tooltip": "Taxpayer's marital status",
            "order": 2,
            "required": true,
            "options": [
              {
                "key": "1",
                "value": "Single"
              },
              {
                "key": "2",
                "value": "Married"
              }
            ]
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "spouseInfo",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "relationClass": "maritalStautus",
        "order": 6,
        "children": [
          {
            "control": "TextboxControl",
            "key": "SpouseName",
            "label": "Spouse's Name",
            "required": false,
            "errorType": "",
            "message": "",
            "tooltip": "Taxpayer's spouse's name",
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "SpouseTIN",
            "label": "Spouse's E-TIN",
            "required": false,
            "errorType": "",
            "maxlength": "12",
            "message": "",
            "tooltip": "Taxpayer's spouse's E-TIN",
            "order": 2
          }
        ]
      },
      {
        "control": "LabelControl",
        "key": "contactInformation",
        "label": "Contact Information",
        "errorType": "",
        "message": "",
        "tooltip": "Taxpayer's contact information",
        "order": 7
      },
      {
        "control": "TextboxGroupControl",
        "key": "contactbox",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 8,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContactNo",
            "label": "Mobile No",
            "required": true,
            "maxlength": "11",
            "errorType": "",
            "message": "Required",
            "tooltip": "Taxpayer's mobile no.",
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "Email",
            "label": "Email",
            "required": false,
            "readonly": true,
            "errorType": "",
            "message": "",
            "tooltip": "Taxpayer's email address",
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxControl",
        "key": "PresentAddress",
        "label": "Present Address",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "Taxpayer's present address",
        "order": 9
      },
      {
        "control": "TextboxGroupControl",
        "key": "presentDistrictPostalbox",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 10,
        "children": [
          {
            "control": "DropdownControl",
            "key": "PresentDistrictId",
            "label": "District",
            "required": true,
            "errorType": "",
            "value": "",
            "message": "",
            "tooltip": "",
            "options": [
              {
                "key": "1",
                "value": "Dhaka"
              },
              {
                "key": "2",
                "value": "Khulna"
              },
              {
                "key": "3",
                "value": "Comilla"
              },
              {
                "key": "4",
                "value": "Pabna"
              }
            ],
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "PresentPostCode",
            "label": "Postal Code",
            "required": true,
            "errorType": "",
            "message": "Required",
            "tooltip": "",
            "order": 2
          }
        ]
      },
      {
        "control": "CheckboxControl",
        "key": "hasPermanentAddress",
        "label": "Permanent Address",
        "value": null,
        "errorType": "",
        "message": "",
        "tooltip": "Same as Present Address",
        "required": false,
        "order": 11
      },
      {
        "control": "TextboxControl",
        "key": "PermanentAddress",
        "label": "",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "Taxpayer's permanent address",
        "relationClass": "PermanentAddress",
        "order": 12
      },
      {
        "control": "TextboxGroupControl",
        "key": "permanentDistrictPostalbox",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "relationClass": "permanentDistrictPostalbox",
        "order": 13,
        "children": [
          {
            "control": "DropdownControl",
            "key": "PermanentDistrictId",
            "label": "District",
            "required": true,
            "value": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "options": [
              {
                "key": "1",
                "value": "Dhaka"
              },
              {
                "key": "2",
                "value": "Khulna"
              },
              {
                "key": "3",
                "value": "Comilla"
              },
              {
                "key": "4",
                "value": "Pabna"
              }
            ],
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "PermanentPostCode",
            "label": "Postal Code",
            "required": true,
            "errorType": "",
            "message": "Required",
            "tooltip": "",
            "order": 2
          }
        ]
      }
    ]



    return jsonData;
  }

  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
