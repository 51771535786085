import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';

@Component({
  selector: 'AdvancePaidTax-form-control',
  templateUrl: './AdvancePaidTax-form-control.component.html',
  styleUrls: ['./AdvancePaidTax.component.scss']

})
export class AdvancePaidTaxControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  pageLoaded: boolean;
  IsCurrentYear: any;

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }

  onSelect(event, key)
  {


    let drop2: HTMLElement = document.getElementById("Description") as HTMLElement;

    let Type = key === 'Type' ? event.value : this.form.controls['Type'].value;

    if (Type == "2")
    {
      if (drop2.classList.contains('hidden'))
      {
        drop2.classList.remove('hidden');
      }
      this.form.patchValue({
        Description: '',
        Amount: ''
      })
    }
    else
    {
      this.form.patchValue({
        Description: '',
        Amount: ''
      })
      if (!drop2.classList.contains('hidden'))
      {
        drop2.classList.add('hidden');
      }
    }
  }

  get isValid()
  {
    if (this.pageLoaded)
    {
      return this.form.controls[this.control.key].valid;
    } else
    {
      return false;
    }
  }

  ngAfterViewInit(): void
  {
    this.pageLoaded = true;

    let Type = this.form.controls["Type"].value;
    let drop2: HTMLElement = document.getElementById("Description") as HTMLElement;

    if (Type == "2")
    {
      if (drop2 != null)
      {
        if (drop2.classList.contains('hidden'))
        {
          drop2.classList.remove('hidden');
        }
      }
    }
    if (Type == "1")
    {
      if (drop2 != null)
      {
        if (!drop2.classList.contains('hidden'))
        {
          drop2.classList.add('hidden');
        }
      }
    }
  }

}
