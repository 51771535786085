import { Observable, of } from 'rxjs';
import { BaseControl, LabelControl, TextboxControl, ButtonGroupControl, SlideToggleControl, DropdownControl, DateSelectorControl, LabelGroupControl, TextAreaControl, TextboxGroupControl, ButtonControl, CheckboxControl, RadioGroupControl, CheckboxGroupControl } from 'app/models/dynamic-form';
import { RadioButtonControl } from 'app/models/dynamic-form/radioButton-control';
import { AppUser } from 'app/auth';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Assessee } from 'app/auth/Assessee';

export default class Utils
{
    static getControls(json: any): Observable<BaseControl<string>[]>
    {
        const items: BaseControl<string>[] = Utils.toControls(json);
        return of(items.sort((a, b) => a.order - b.order));
    }

    static getUserId()
    {
        let userid = ""
        if (localStorage.getItem('TokenInfo') != null)
        {
            userid = localStorage.getItem('TokenInfo');
        }
        return userid;
    }

    static getUserInfo(): AppUser
    {
        let storage = JSON.parse(localStorage.getItem('userInfo'));
        let user = Object.assign(new AppUser(), storage);
        return user;
    }

    static getArchiveStatus()
    {
        let archiveStat = JSON.parse(localStorage.getItem('archiveReadiness'));
        return archiveStat;
    }

    static getAssesseeId(): Assessee
    {
        let storage = JSON.parse(localStorage.getItem('AssesseeId'));
        let assessee = Object.assign(new Assessee(), storage);
        return assessee;
    }

    static validateEmail(email)
    {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regularExpression.test(String(email).toLowerCase());
    }

    static isEmptyObject(obj)
    {
        return (obj && (Object.keys(obj).length === 0));
    }

    static toControls(json: any): BaseControl<string>[]
    {
        const items: BaseControl<string>[] = [];
        for (const key in json)
        {
            if (json.hasOwnProperty(key))
            {
                items.push(Utils.toControl(json[key]));
            }
        }
        return items.sort((a, b) => a.order - b.order);
    }

    static toControl(obj: any): BaseControl<string>
    {
        let item: BaseControl<string> = new BaseControl();

        if (obj.control === 'LabelControl')
        {
            item = LabelControl.getFromJsonObj(obj);
        }
        if (obj.control === 'ButtonControl')
        {
            item = ButtonControl.getFromJsonObj(obj);
        }
        if (obj.control === 'LabelGroupControl')
        {
            item = LabelGroupControl.getFromJsonObj(obj);
        }
        if (obj.control === 'TextboxControl')
        {
            item = TextboxControl.getFromJsonObj(obj);
        }
        if (obj.control === 'CheckboxControl')
        {
            item = CheckboxControl.getFromJsonObj(obj);
        }
        if (obj.control === 'TextboxGroupControl')
        {
            item = TextboxGroupControl.getFromJsonObj(obj);
        }
        if (obj.control === 'CheckboxGroupControl')
        {
            item = CheckboxGroupControl.getFromJsonObj(obj);
        }
        if (obj.control === 'ButtonGroupControl')
        {
            item = ButtonGroupControl.getFromJsonObj(obj);
        }
        if (obj.control === 'TextAreaControl')
        {
            item = TextAreaControl.getFromJsonObj(obj);
        }
        if (obj.control === 'DateSelectorControl')
        {
            item = DateSelectorControl.getFromJsonObj(obj);
        }
        if (obj.control === 'SlideToggleControl')
        {
            item = SlideToggleControl.getFromJsonObj(obj);
        }
        if (obj.control === 'RadioGroupControl')
        {
            item = RadioGroupControl.getFromJsonObj(obj);
        }
        if (obj.control === 'DropdownControl')
        {
            item = DropdownControl.getFromJsonObj(obj);
        }
        if (obj.control === 'RadioButtonControl')
        {
            item = RadioButtonControl.getFromJsonObj(obj);
        }
        return item;

    }

    static getIncomeUrl(item): string
    {
        let url = "";

        if (item == "1")
        {
            url = "/Salary";
        }

        if (item == "2")
        {
            url = "/InterestOnSecurities";
        }

        if (item == "3")
        {
            url = "/Property";
        }

        if (item == "4")
        {
            url = "/Agriculture";
        }

        if (item == "5")
        {
            url = "/BuisnessOrProfession";
        }

        if (item == "6")
        {
            url = "/ShareofProfitInaFirm";
        }

        if (item == "7")
        {
            url = "/SpouseOrChild";
        }

        if (item == "8")
        {
            url = "/CapitalGains";
        }

        if (item == "9")
        {
            url = "/OtherResources";
        }

        if (item == "10")
        {
            url = "/ForeignIncome";
        }

        return url;
    }

    static getUrl(item): string
    {
        let url = "";

        if (item == "E-tin & other info")
        {
            url = "/EtinOthers";
        }

        if (item == "E-tin & Other Info")
        {
            url = "/EtinOthers";
        }

        if (item == "Personal information")
        {
            url = "/PersonalInfo";
        }

        if (item == "Personal Information")
        {
            url = "/PersonalInfo";
        }

        if (item == "Personalise return")
        {
            url = "/PersonalizeReturn";
        }

        if (item == "Personalise Return")
        {
            url = "/PersonalizeReturn";
        }

        if (item == "Salary")
        {
            url = "/Salary";
        }

        if (item == "Interest On Security")
        {
            url = "/InterestOnSecurities";
        }

        if (item == "Interest On Securities")
        {
            url = "/InterestOnSecurities";
        }

        if (item == "Property")
        {
            url = "/Property";
        }

        if (item == "Agriculture")
        {
            url = "/Agriculture";
        }

        if (item == "Business/ Profession")
        {
            url = "/BuisnessOrProfession";
        }

        if (item == "Share of Profit In a Firm")
        {
            url = "/ShareofProfitInaFirm";
        }

        if (item == "Spouse/Child")
        {
            url = "/SpouseOrChild";
        }

        if (item == "Capital Gains")
        {
            url = "/CapitalGains";
        }

        if (item == "Other Sources")
        {
            url = "/OtherResources";
        }

        if (item == "Foreign Income")
        {
            url = "/ForeignIncome";
        }




        if (item == "Buisness Capital")
        {
            url = "/BuisnessCapital";
        }

        if (item == "Director's Shareholding Assets")
        {
            url = "/DirectorsShareholding";
        }

        if (item == "Non-Agriculture Property")
        {
            url = "/NonAgriculturalProperty";
        }

        if (item == "Agricultural Property")
        {
            url = "/AgriculturalProperty";
        }

        if (item == "Investments")
        {
            url = "/Investments";
        }

        if (item == "Motor Vehicles")
        {
            url = "/MotorVehicle";
        }

        if (item == "Furniture")
        {
            url = "/Furniture";
        }

        if (item == "Jewellery")
        {
            url = "/Jewellery";
        }

        if (item == "Electronic Equipment")
        {
            url = "/ElectronicEquipment";
        }

        if (item == "Cash Assets")
        {
            url = "/CashAssets";
        }

        if (item == "Other Assets")
        {
            url = "/OtherAssets";
        }

        if (item == "Other Assets Receipt")
        {
            url = "/OtherAssetsReceipt";
        }

        if (item == "Previous Year's Net Worth")
        {
            url = "/PreviousYearNetWealth";
        }

        return url;
    }

    static emailPatternValidator(): ValidatorFn
    {
        return (control: AbstractControl): { [key: string]: any } =>
        {
            if (!control.value)
            {
                return null;
            }
            const regex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
            const valid = regex.test(control.value);
            return valid ? null : { invalidEmail: true };
        };
    }

    static passwordPatternValidator(): ValidatorFn
    {
        return (control: AbstractControl): { [key: string]: any } =>
        {
            if (!control.value)
            {
                return null;
            }
            const regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
            const valid = regex.test(control.value);
            return valid ? null : { invalidPassword: true };
        };
    }

    static MatchPassword(password: string, passwordConfirm: string)
    {
        return (formGroup: FormGroup) =>
        {
            const passwordControl = formGroup.controls[password];
            const passwordConfirmControl = formGroup.controls[passwordConfirm];

            if (!passwordControl || !passwordConfirmControl)
            {
                return null;
            }

            if (passwordConfirmControl.errors && !passwordConfirmControl.errors.passwordMismatch)
            {
                return null;
            }

            if (passwordControl.value !== passwordConfirmControl.value)
            {
                passwordConfirmControl.setErrors({ passwordMismatch: true });
            } else
            {
                passwordConfirmControl.setErrors(null);
            }
        }
    }

    static IMenu(currentUrl, routeType, routeGroup): string
    {
        // debugger;
        let stepMenu = JSON.parse(localStorage.getItem('stepMenu'));
        let routeMenu = null;
        let closestItem = '';
        let closestIndex = null;
        // let currentUrl = this._router.url;
        if (stepMenu)
        {
            if (routeGroup == 'income')
            {
                routeMenu = stepMenu.incomeMenu;
            }
            if (routeGroup == 'asset')
            {
                routeMenu = stepMenu.assetsMenu;
            }
        }
        // console.log(routeMenu, 'routeMenu');

        if (currentUrl.includes('/Salary/Govt/'))
        {
            currentUrl = currentUrl.replace('/Govt', '');
        };

        if (currentUrl.includes('/save'))
        {
            // currentUrl = currentUrl.replace('/save', '');
            currentUrl = currentUrl.split("/save")[0];
        };
        // console.log(currentUrl, 'currentUrl');

        const currentIndex = routeMenu.indexOf(currentUrl);

        if (routeMenu.length == 1)
        {
            if (routeType == 'prev')
            {
                if (routeGroup == 'income')
                {
                    closestItem = '/PersonalizeReturn';
                }
                if (routeGroup == 'asset')
                {
                    closestItem = '/AdjustmentofTaxRefund';
                }
            } else
            {
                if (routeGroup == 'income')
                {
                    closestItem = '/TaxRebate';
                }
                if (routeGroup == 'asset')
                {
                    closestItem = '/Mortgages';
                }
            }
        }
        else
        {
            if (currentIndex == 0)
            {
                if (routeType == 'next')
                {
                    closestIndex = (currentIndex + 1);
                    closestItem = routeMenu[closestIndex];
                }
                else
                {
                    if (routeGroup == 'income')
                    {
                        closestItem = '/PersonalizeReturn';
                    }
                    if (routeGroup == 'asset')
                    {
                        closestItem = '/AdjustmentofTaxRefund';
                    }
                }
            }
            else
            {
                if (routeType == 'next')
                {
                    closestIndex = (currentIndex + 1);

                    if (closestIndex == routeMenu.length)
                    {
                        if (routeGroup == 'income')
                        {
                            closestItem = '/TaxRebate';
                        }
                        if (routeGroup == 'asset')
                        {
                            closestItem = '/Mortgages';
                        }
                    }
                    else
                    {
                        closestItem = routeMenu[closestIndex];
                    }
                }

                if (routeType == 'prev')
                {
                    closestIndex = (currentIndex - 1);
                    closestItem = routeMenu[closestIndex];
                }
            }
        }

        // if (routeMenu.length == 1)
        // {
        //     if (routeType == 'prev')
        //     {
        //         if (routeGroup == 'income')
        //         {
        //             closestItem = '/PersonalizeReturn';
        //         }
        //         if (routeGroup == 'asset')
        //         {
        //             closestItem = '/AdjustmentofTaxRefund';
        //         }
        //     } else
        //     {
        //         if (routeGroup == 'income')
        //         {
        //             closestItem = '/TaxRebate';
        //         }
        //         if (routeGroup == 'asset')
        //         {
        //             closestItem = '/Mortgages';
        //         }
        //     }
        // }
        // else
        // {
        //     if (currentIndex == 0)
        //     {
        //         if (routeType == 'next')
        //         {
        //             closestIndex = (currentIndex + 1) % routeMenu.length;
        //         }

        //         if (routeType == 'prev')
        //         {
        //             if (routeGroup == 'income')
        //             {
        //                 closestItem = '/PersonalizeReturn';
        //             }
        //             if (routeGroup == 'asset')
        //             {
        //                 closestItem = '/AdjustmentofTaxRefund';
        //             }
        //         }
        //     }
        //     else
        //     {
        //         if (routeType == 'next')
        //         {
        //             closestIndex = (currentIndex + 1) % routeMenu.length;
        //         }

        //         if (routeType == 'prev')
        //         {
        //             closestIndex = (currentIndex - 1) % routeMenu.length;
        //         }
        //     }

        //     if (currentIndex == routeMenu.length - 1)
        //     {
        //         if (routeType == 'prev')
        //         {
        //             if (routeGroup == 'income')
        //             {
        //                 closestItem = '/PersonalizeReturn';
        //             }
        //             if (routeGroup == 'asset')
        //             {
        //                 closestItem = '/AdjustmentofTaxRefund';
        //             }
        //         } else
        //         {
        //             if (routeGroup == 'income')
        //             {
        //                 closestItem = '/TaxRebate';
        //             }
        //             if (routeGroup == 'asset')
        //             {
        //                 closestItem = '/Mortgages';
        //             }
        //         }
        //     }



        //     if (closestIndex == null || closestIndex == 0)
        //     {
        //         if (routeType == 'prev')
        //         {
        //             if (routeGroup == 'income')
        //             {
        //                 closestItem = '/PersonalizeReturn';
        //             }
        //             if (routeGroup == 'asset')
        //             {
        //                 closestItem = '/AdjustmentofTaxRefund';
        //             }
        //         } else
        //         {
        //             if (routeGroup == 'income')
        //             {
        //                 closestItem = '/TaxRebate';
        //             }
        //             if (routeGroup == 'asset')
        //             {
        //                 closestItem = '/Mortgages';
        //             }
        //         }
        //     }
        //     else
        //     {
        //         closestItem = routeMenu[closestIndex];
        //     }
        // }
        // console.log(closestItem, 'closestItem');

        return closestItem;
    }

    static transformAccounHeadtName(AccounHeadtName)
    {
        let headName = '';
        switch (AccounHeadtName)
        {
            case 'TaxPay':
                headName = 'Tax Pay'
                break;
            case 'OfficeExpense':
                headName = 'Office Expense'
                break;

            default:
                headName = AccounHeadtName;
                break;
        }
        return headName;
    }
}
