import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';



@Injectable()
export class EtinOthersListService
{
    constructor(private service: Service) { }

    toFormGroup(items: BaseControl<string>[]): FormGroup
    {
        const group: any = {};
        items.forEach(item =>
        {

            group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
                : new FormControl(item.value || '');
            if (item.hasOwnProperty('children'))
            {
                item.children.forEach(child =>
                {
                    group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
                        : new FormControl(child.value || '');
                });
            }
            if (item.hasOwnProperty('innerContent'))
            {
                item.innerContent.forEach(child =>
                {
                    group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
                        : new FormControl(child.value || '');
                });
            }
        });

        return new FormGroup(group);
    }

    getControlJson(): Observable<BaseControl<string>[]>
    {
        let urlConfig = new UrlConfig();

        return this.service.get(urlConfig.BASIC_INFO_Etin_Others_List_Url);
    }



    getJson(): any
    {
        const jsonData = [
            {

                "head": "Etin Information",
                "data": [
                    {
                        "source": "Salary",
                        "value": "123131213",
                    },
                    {
                        "source": "Tax Zone",
                        "value": "Circle:123 , Zone:113",
                    },
                ]

            },

            {
                "head": "Residential Status",
                "data": [
                    {
                        "source": "Residential Status",
                        "value": "Resident",
                    },
                ]
            }

            ,

            {
                "head": "Other Information",
                "data": [
                    {
                        "source": "A Gazetted War-wounded\r\n freedom fighter",
                    },
                    {
                        "source": "A Person With Disablity",
                    },
                    {
                        "source": "Parent / Legal Guardian\r\n of A Person with Disablity",
                    },
                ]
            },
            {
                "head": "Minimum Tax Area",
                "data": [
                    {
                        "source": "Area of Resident",
                        "value": "Dhaka",
                    },
                ]
            }


        ]

        return jsonData;
    }

    getControls(): Promise<any>
    {
        return new Promise((resolve) =>
        {

            resolve(this.getJson)

        });
    }
}
