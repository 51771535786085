<div class="page-layout simple right-sidebar inner-scroll"
  style="background-color: #ffffff;">

  <fuse-sidebar class="sidebar"
    name="my-demo-sidebar"
    position="right"
    lockedOpen="gt-md"
    style="background: #fafafa !important;">
    <div class="content">
      <my-demo-sidebar></my-demo-sidebar>
    </div>
  </fuse-sidebar>

  <div class="center">

    <div fxLayoutAlign="space-between center"
      style="background-color: #dde1e2;">

      <span class="h1"
        style="padding: 10px; margin-left: 10px; ">
        REPORT
      </span>
      <div fxLayout="row">
        <button (click)="summaryPDF()"
          mat-raised-button
          style="margin-right: 20px;"
          class="mat-button dupl"
          fxFlex="0 0 auto"
          fxLayout="row"
          fxLayoutAlign="start center">

          <span>Download Summary</span>
        </button>

        <button (click)="downloadPDF()"
          mat-raised-button
          style="margin-right: 20px;"
          class="mat-button dupl"
          fxFlex="0 0 auto"
          fxLayout="row"
          fxLayoutAlign="start center">

          <span>Download PDF</span>
        </button>
      </div>
    </div>
    <div id="pdfTable"
      #pdfTable
      style="padding: 50px; width: 800px;"
      *ngIf="getData != null">
      <div id="page1"
        style="height: 1275px;">
        <div fxLayout="row wrap"
          fxLayoutAlign="space-between"
          fxLayout.gt-md="row">
          <div fxLayout="column wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="column">
            <div style="font: 13px 'Times New Roman'">National Board of Revenue</div>
            <div style="font: 13px 'Times New Roman';">www.nbr.gov.bd</div>

          </div>
          <div fxLayout="column wrap"
            fxLayoutAlign="space-between"
            fxLayout.gt-md="column"
            style="padding-right: 10px;">
            <div style="font: bold 14px 'Times New Roman';
          ">IT-11GA2016</div>

          </div>

        </div>
        <div fxLayout="column wrap"
          fxLayoutAlign="center center"
          fxLayout.gt-md="column"
          style="padding-bottom: 10px;">
          <div style="font: bold 19px 'Times New Roman';
        ">RETURN OF INCOME</div>
          <div style="font: 19px 'Times New Roman';
        ">For an Individual Assessee</div>
        </div>
        <div fxLayout="row wrap"
          fxLayoutAlign="space-between"
          fxLayout.gt-md="row"
          style="padding-top: 10px;padding-bottom: 10px;">
          <div fxLayout="column wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="column"
            style="padding-left: 5px;">
            <div style="font: 15px 'Times New Roman';">The following schedules shall be the integral part of this return
              and must be annexed
              to</div>
            <div style="font: 15px 'Times New Roman';">return in the following cases:</div>
            <div style="font: italic 15px 'Times New Roman';">Schedule 24A &nbsp;&nbsp;&nbsp;if you have income from
              Salaries</div>
            <div style="font: italic 15px 'Times New Roman';">Schedule 24B &nbsp;&nbsp;&nbsp;if you have income from
              house
              property</div>
            <div style="font: italic 15px 'Times New Roman';">Schedule 24C &nbsp;&nbsp;&nbsp;if you have income from
              business or profession</div>
            <div style="font: italic 15px 'Times New Roman';">Schedule 24D &nbsp;&nbsp;&nbsp;if you claim tax rebate
            </div>

          </div>
          <div>
            <div fxLayoutAlign="center center"
              style="height: 100px;width: 100px; border: 1px solid black;">
              <div fxLayoutAlign="center"
                style="font: 15px 'Times New Roman';
            ">
                Photo
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column wrap"
          fxLayoutAlign="center center"
          fxLayout.gt-md="column">
          <div style="font: bold 16px 'Times New Roman';
        ">PART I</div>
          <div style="font: bold 16px 'Times New Roman';
        ">Basic information</div>
        </div>

        <div style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <!-- row 1 -->
          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 75px; width: 5%;border-right: 1px solid black;
          border-bottom: 1px solid black;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp; 01</div>
            </div>
            <div style="height: 75px; width: 45%;border-right: 1px solid black;
          border-bottom: 1px solid black;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font: 16px 'Times New Roman';padding-bottom: 5px;
              ">&nbsp; Assessment Year</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="font: 16px;">
                  &nbsp;&nbsp;
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                    2</div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                    0</div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                  </div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                  </div>
                  <div style="height: 30px;width: 30px; margin-right: 5px;text-align: center;">-</div>

                  <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                    &nbsp;
                  </div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">&nbsp;</div>

                </div>
              </div>
            </div>
            <div style="height: 75px; width: 5%;border-right: 1px solid black;
          border-bottom: 1px solid black;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;02</div>
            </div>
            <div style="width: 45%;height: 75px;
          border-bottom: 1px solid black;"
              fxLayout="column wrap"
              fxLayoutAlign="space-between"
              fxLayout.gt-md="column">

              <div style="font: 16px 'Times New Roman';
               ">&nbsp; Return submitted under section 82BB?
              </div>
              <div style="font: 16px 'Times New Roman';
              border-bottom: 1px solid black;">&nbsp; (tick one)
              </div>
              <div fxLayout="row wrap"
                fxLayoutAlign="space-between"
                fxLayout.gt-md="row"
                style="height: 35px;">
                <div fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 50%;">
                  <div style="font: 16px 'Times New Roman';">&nbsp;Yes</div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px;margin-top: 1px; margin-right: 10px;">
                  </div>
                </div>

                <div fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 50%; border-left: 1px solid black;">
                  <div style="font: 16px 'Times New Roman';">&nbsp;No</div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px;margin-top: 1px; margin-right: 10px;">
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- row 2 -->
          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="width: 5%;height: 50px;border-right: 1px solid black;
          border-bottom: 1px solid black;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;03</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
          border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font: 16px 'Times New Roman';
              ">&nbsp;Name of the Assessee</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp; {{getData.AssesseeName}}
                </div>
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
          border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;04</div>
            </div>
            <div style="height: 50px;border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="row wrap"
                fxLayoutAlign="space-between"
                fxLayout.gt-md="row">
                <div style="font: 16px 'Times New Roman';">&nbsp; Gender (tick one)
                </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row">
                  <div fxLayout="row wrap"
                    fxLayoutAlign="start center"
                    fxLayout.gt-md="row"
                    style="padding: 5px;">
                    <div style="font:bold 16px 'Times New Roman';
                  border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px; text-align: center;">
                      M</div>
                    <div style="border: 0.5px solid black; height: 30px;width:30px; margin-right: 10px;">
                      &nbsp;{{genderM}}
                    </div>

                    <div
                      style="font:bold 16px 'Times New Roman';border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px; text-align: center;">
                      F</div>
                    <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                      &nbsp;{{genderF}}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <!-- row 3 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;
          border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;05</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font: 16px 'Times New Roman';
              ">&nbsp;Twelve-digit TIN </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.ETIN}}
                </div>
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;06</div>
            </div>
            <div style="height: 50px;border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font: 16px 'Times New Roman';
              ">&nbsp;Old TIN </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.OldTIN}}
                </div>
              </div>
            </div>
          </div>

          <!-- row 4 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;07</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font: 16px 'Times New Roman';
            ">&nbsp;Circle </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.Circle}}
                </div>
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;08</div>
            </div>
            <div style="height: 50px;border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font: 16px 'Times New Roman';">&nbsp;Zone </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.Zone}}
                </div>
              </div>
            </div>
          </div>

          <!-- row 5 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;
          border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;09</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
          border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font: 16px 'Times New Roman';
              ">&nbsp;Resident Status (tick one) </div>
              </div>
            </div>

            <div fxLayout="row wrap"
              fxLayoutAlign="space-between"
              fxLayout.gt-md="row"
              style="height: 50px;
              border-bottom: 1px solid black; width: 50%;">
              <div fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayout.gt-md="row"
                style="width: 50%;">
                <div style="font: 16px 'Times New Roman';
              ">&nbsp;Resident</div>
                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                  &nbsp;{{ResidentStatusYes}}
                </div>
              </div>

              <div fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayout.gt-md="row"
                style="width: 50%; border-left: 1px solid black;">
                <div style="font: 16px 'Times New Roman';
              ">&nbsp;Non-resident</div>
                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                  &nbsp;{{ResidentStatusNo}}
                </div>
              </div>
            </div>

            
          </div>

          <!-- row 6 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 120px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
             ">&nbsp;10</div>
            </div>
            <div style="height: 120px;border-bottom: 1px solid black; width: 95%;"
              fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column">

              <div style="font: 16px 'Times New Roman';
              border-bottom: 1px solid black;height: 30px;">&nbsp;Tick on the box(es) below if you are:
              </div>
              <div fxLayout="row wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row"
                style="height: 45px;">
                <div fxLayoutAlign="start start"
                  style="font: 16px 'Times New Roman';
                border-right: 1px solid black;border-bottom: 1px solid black;width: 5%;">&nbsp;10A</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 43%;padding: 5px;border-right: 1px solid black;
                  border-bottom: 1px solid black; ">
                  <div style="font: 16px 'Times New Roman';margin-left: 5px;">A gazetted war-wounded <br>
                    freedom fighter</div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                    &nbsp;{{WarWoundedFreedomFighter}}
                  </div>
                </div>
                <div fxLayoutAlign="start start"
                  style="font: 16px 'Times New Roman';border-right: 1px solid black;border-bottom: 1px solid black;width: 5.3%;">
                  &nbsp;10B</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 48%;padding: 5px;
                  border-bottom: 1px solid black; ">
                  <div style="font: 16px 'Times New Roman';margin-left: 5px;">A person with disability</div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                    &nbsp;{{DisablePerson}}
                  </div>
                </div>
              </div>
              <div fxLayout="row wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row"
                style="height: 45px;">
                <div fxLayoutAlign="start start"
                  style="font: 16px 'Times New Roman';
                border-right: 1px solid black;width: 5%;">&nbsp;10C</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 43%;padding: 5px;border-right: 1px solid black;">
                  <div style="font: 16px 'Times New Roman';margin-left: 5px;">Aged 65 years or more</div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                  </div>
                </div>
                <div fxLayoutAlign="start start"
                  style="font: 16px 'Times New Roman';border-right: 1px solid black;width: 5.3%;">&nbsp;10D</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 48%;padding: 5px;">
                  <div style="font: 16px 'Times New Roman';margin-left: 5px;">A parent/legal guardian of a person<br>
                    with disability</div>
                  <div style="border: 0.5px solid black; height: 30px;width:30px; margin-right: 10px;">
                    &nbsp;{{HasDisableChild}}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- row 7 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 60px; width: 5%;border-right: 1px solid black;border-bottom: 1px solid black;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;11</div>
            </div>
            <div style="height: 60px; width: 45%;border-right: 1px solid black;border-bottom: 1px solid black;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row">
                  <div style="font: 16px 'Times New Roman';">&nbsp;Date of birth</div>
                  <div style="font: 11px 'Times New Roman';margin-left: 5px;margin-top: 2px;">(DD-MM-YYYY)</div>
                </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px; margin-left: 20px;">
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                    {{ getData.DateOfBirthStr != null?getData.DateOfBirthStr [0]:''}}
                  </div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                    {{ getData.DateOfBirthStr != null?DateOfBirthStr[1]:''}}
                  </div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                    {{ getData.DateOfBirthStr != null?DateOfBirthStr[3]:''}}
                  </div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                    {{ getData.DateOfBirthStr != null?DateOfBirthStr[4]:''}}
                  </div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                    {{ getData.DateOfBirthStr != null?DateOfBirthStr[6]:''}}</div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                    {{ getData.DateOfBirthStr != null?DateOfBirthStr[7]:''}}</div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                    {{ getData.DateOfBirthStr != null?DateOfBirthStr[8]:''}}
                  </div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                    {{ getData.DateOfBirthStr != null?DateOfBirthStr[9]:''}}
                  </div>

                </div>
              </div>
            </div>
            <div style="height: 60px; width: 5%;border-right: 1px solid black;border-bottom: 1px solid black;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';"> &nbsp;12</div>
            </div>
            <div style="width: 45%;height: 60px;border-bottom: 1px solid black;">
              <div fxLayout="column wrap"
                fxLayoutAlign="space-between"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';"> Income Year
                </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="font:bold 16px ;">
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 100px; margin-right: 5px;font: bold;text-align: center;">
                  </div>
                  <div style="height: 30px;width: 30px; margin-right: 5px;text-align: center;">to</div>
                  <div
                    style="border: 0.5px solid black; height: 30px;width: 100px; margin-right: 5px;font: bold;text-align: center;">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- row 8 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px; width: 5%;border-right: 1px solid black;border-bottom: 1px solid black;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;13</div>
            </div>
            <div style="height: 50px; width: 95%;border-bottom: 1px solid black;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">

                <div style="font: 16px 'Times New Roman';">If employed, employer’s name</div>
                <div style="font: 16px 'Times New Roman';
                  ">&nbsp;
                </div>

              </div>
            </div>

          </div>

          <!-- row 9 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;
                border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
                   ">&nbsp;14</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
                border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';
                    ">Spouse Name</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.SpouseName}}
                </div>
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
                border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
                   ">&nbsp;15</div>
            </div>
            <div style="height: 50px;
                border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';
                    ">Spouse TIN (if any)</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.SpouseTIN}}
                </div>
              </div>
            </div>
          </div>

          <!-- row 10 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;
                border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
                   ">&nbsp;16</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
                border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';
                    ">Father’s Name </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.FatherName}}
                </div>
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
                border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
                   ">&nbsp;17</div>
            </div>
            <div style="height: 50px;
                border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';
                    ">Mother’s Name
                </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.MotherName}}
                </div>
              </div>
            </div>
          </div>

          <!-- row 11 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 100px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;18</div>
            </div>
            <div style="height: 100px;border-right: 1px solid black;border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Present Address</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.PresentAddress}}
                </div>
              </div>
            </div>
            <div style="height: 100px;border-right: 1px solid black;
        border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;19</div>
            </div>
            <div style="height: 100px;border-bottom: 1px solid black; width: 45%">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Permanent Address </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.PermanentAddress}}
                </div>
              </div>
            </div>
          </div>

          <!-- row 12 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;
              border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
                 ">&nbsp;20</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
              border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';
                  ">Contact Telephone </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.ContactNo}}
                </div>
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;
              border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
                 ">&nbsp;21</div>
            </div>
            <div style="height: 50px;
              border-bottom: 1px solid black; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';
                  ">E-mail </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.Email}}
                </div>
              </div>
            </div>
          </div>

          <!-- row 13 -->

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;22</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">National Identification Number</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.NID}}
                </div>
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;23</div>
            </div>
            <div style="height: 50px; width: 45%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Business Identification Number(s)
                </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row"
                  style="margin-bottom: 10px;font:bold 16px ;">
                  &nbsp;{{getData.BIN}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="page_2"
        style="height: 1270px;">
        <div fxLayout="column wrap"
          fxLayoutAlign="center center"
          fxLayout.gt-md="column"
          style="padding-bottom: 10px;">
          <div style="font: bold 19px 'Times New Roman';">PART II</div>
          <div style="font: bold 19px 'Times New Roman';">Particulars of Income and Tax
          </div>
          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="margin-bottom: 10px;margin-top: 5px;">
            <div style="font:18px 'Times New Roman';
          text-align: center">TIN: &nbsp; &nbsp;
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{ getData.ETIN != null?etinSplit[0]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[1]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[2]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[3]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[4]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[5]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[6]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[7]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[8]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[9]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[10]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[11]:''}}
            </div>

          </div>
        </div>
        <div fxLayout="row wrap"
          fxLayoutAlign="space-between"
          fxLayout.gt-md="row">
          <div style="font: 16px 'Times New Roman';">Particulars of Total Income</div>
          <div style="font: 16px 'Times New Roman';margin-right: 20px;">Amount ৳</div>
        </div>
        <div style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;24</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Salaries (annex Schedule 24A) </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;S.21</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.SalaryIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;25</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Interest on securities </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;S.22</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.InterestOnSecuritiesIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;26</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Income from house property (annex Schedule 24B) </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;S.24</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.HousePropertyIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;27</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Agricultural income </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;S.26</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.AgricultureIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;28</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Income from business or profession (annex Schedule 24C)
                </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;S.28</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.BusinessOrProfession}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;29</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Capital gains </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;S.31</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.CapitalGainsIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;30</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';
            ">Income from other sources </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;S.33</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.OtherSourceIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;31</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Share of income from firm or AOP </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.ShareOrFirmIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;32</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Income of minor or spouse under section 43(4) </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;S.43</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.MinorOrSpouseIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;33</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Foreign income </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
         ">&nbsp;</div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';
          ">&nbsp;{{getData.ForeignIncome}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;34</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;width: 60%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Total income (aggregate of 24 to 33) </div>
              </div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;width: 10%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;</div>
            </div>
            <div style="height: 35px;width: 25%;">
              <div style="font: 16px 'Times New Roman';
        ">&nbsp;{{getData.TotalIncome}}</div>
            </div>
          </div>

        </div>
        <div fxLayout="row wrap"
          fxLayoutAlign="space-between"
          fxLayout.gt-md="row"
          style="margin-top: 15px;">
          <div style="font: 16px 'Times New Roman';
        ">Tax Computation and Payment </div>
          <div style="font: 16px 'Times New Roman';margin-right: 20px;">Amount ৳</div>
        </div>
        <div style="width: 100%;border-collapse: collapse;border: 1px solid black;">

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;35</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Gross tax before tax rebate </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.GrossTaxBeforRebate}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;36</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Tax rebate (annex Schedule 24D) </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.TaxRebate}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;37</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Net tax after tax rebate </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.NetTaxAfterRebate}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;38</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Minimum tax </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.MinimumTax}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;39</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Net wealth surcharge </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.NetWealthSurcharge}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;40</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Interest or any other amount under the Ordinance (if any)
                </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.InterestOrOtherAmount}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;41</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Total amount payable </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.TotalAmountPayable}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;42</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Tax deducted or collected at source (attach proof) </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.TaxDeductedAtSource_TDS}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;43</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Advance tax paid (attach proof) </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.AdvanceTaxPaidAIT}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 55px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;44</div>
            </div>
            <div style="height: 55px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Adjustment of tax refund [mention assessment year(s) of
                  refund]
                </div>
              </div>
            </div>
            <div style="height: 55px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.TaxRefundAdjustment}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;45</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Amount paid with return (attach proof) </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.AmountPaidWithReturn}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;46</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Total amount paid and adjusted (42+43+44+45) </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.AmountPaidAndAdjusted}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;47</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;border-bottom: 1px solid black; width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Deficit or excess (refundable) (41-46)
                </div>
              </div>
            </div>
            <div style="height: 35px;border-bottom: 1px solid black; width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.DeficitOrExcess}}</div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 35px;border-right: 1px solid black;width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;48</div>
            </div>
            <div style="height: 35px;border-right: 1px solid black;width: 70%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Tax exempted income </div>
              </div>
            </div>
            <div style="height: 35px;width: 25%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.TaxExemptedIncome}}</div>
            </div>
          </div>

        </div>
      </div>

      <div id="page_3"
        style="height: 1220px;">
        <div fxLayout="column wrap"
          fxLayoutAlign="center center"
          fxLayout.gt-md="column"
          style="padding-bottom: 10px;">
          <div style="font: bold 19px 'Times New Roman';">PART III</div>
          <div style="font: bold 19px 'Times New Roman';">Instruction, Enclosures and Verification
          </div>
          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="margin-bottom: 10px;margin-top: 5px;">
            <div style="font:18px 'Times New Roman';
          text-align: center">TIN: &nbsp; &nbsp;
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{ getData.ETIN != null?etinSplit[0]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[1]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[2]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[3]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[4]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[5]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[6]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[7]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[8]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[9]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[10]:''}}
            </div>
            <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
              &nbsp;{{getData.ETIN != null?etinSplit[11]:''}}
            </div>

          </div>
        </div>

        <div style="width: 100%;border-collapse: collapse;
      border: 1px solid black;">

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 130px;border-right: 1px solid black;
        border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;49</div>
            </div>
            <div style="height: 130px;
        border-bottom: 1px solid black; width: 95%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font:bold 16px 'Times New Roman';
            ">Instructions </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row">
                  <div style="font: 16px 'Times New Roman';
          ">1.&nbsp;</div>
                  <div style="font: 16px 'Times New Roman';
          ">Statement of assets, liabilities and expenses (IT-10B2016) and statement of life style
                    expense
                    (IT-10BB2016) must be furnished with the return unless you are exempted from furnishing
                    such statement(s) under section 80.</div>
                </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row">
                  <div style="font: 16px 'Times New Roman';">
                    2.&nbsp;
                  </div>
                  <div style="font: 16px 'Times New Roman';">
                    Proof of payments of tax, including advance tax and withholding tax and the proof of
                    investment for tax rebate must be provided along with return
                  </div>
                </div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row">
                  <div style="font: 16px 'Times New Roman';">
                    3.&nbsp;</div>
                  <div style="font: 16px 'Times New Roman';">
                    Attach account statements and other documents where applicable
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';
           ">&nbsp;50</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 65%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">
                  If you are a parent of a person with disability, has your spouse
                  availed the extended tax exemption threshold? (tick one)
                </div>
              </div>
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="space-between center"
              fxLayout.gt-md="row"
              style="height: 50px;border-bottom: 1px solid black; width: 15%; border-right: 1px solid black;padding: 5px;">
              <div style="font: 16px 'Times New Roman';">
                Yes
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                &nbsp;{{getData.SpouseExemptedDisableChildYes}}
              </div>
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="space-between center"
              fxLayout.gt-md="row"
              style="height: 50px;border-bottom: 1px solid black; width: 15%; padding: 5px;">
              <div style="font: 16px 'Times New Roman';">
                No
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                &nbsp;{{getData.SpouseExemptedDisableChildNo}}
              </div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">
                &nbsp;51
              </div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 65%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">
                  Are you required to submit a statement of assets, liabilities and
                  expenses (IT-10B2016) under section 80(1)? (tick one)
                </div>
              </div>
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="space-between center"
              fxLayout.gt-md="row"
              style="height: 50px;border-bottom: 1px solid black; width: 15%; border-right: 1px solid black;padding: 5px;">
              <div style="font: 16px 'Times New Roman';">
                Yes</div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                &nbsp;{{getData.RequiredIT10B2016Yes}}
              </div>
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="space-between center"
              fxLayout.gt-md="row"
              style="height: 50px;border-bottom: 1px solid black; width: 15%; padding: 5px;">
              <div style="font: 16px 'Times New Roman';">
                No</div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 10px;">
                &nbsp;{{getData.RequiredIT10B2016No}}
              </div>
            </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;52</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 30%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Schedules annexed
                </div>
                <div style="font: 16px 'Times New Roman';">(tick all that are applicable)
                </div>
              </div>
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="center center"
              fxLayout.gt-md="row"
              style="height: 50px;border-bottom: 1px solid black; width: 65%; padding: 5px;">
              <div fxLayout="row wrap"
                fxLayoutAlign="center center"
                fxLayout.gt-md="row"
                style="margin-right: 20px;">
                <div style="border: 0.5px solid black; height: 30px;width: 30px; ">24A
                </div>
                <div style="border: 0.5px solid black;border-left: 0px; height: 30px;width: 30px; ">
                  &nbsp;{{getData.ScheduleAnnexed24A}}
                </div>
              </div>

              <div fxLayout="row wrap"
                fxLayoutAlign="center center"
                fxLayout.gt-md="row"
                style="margin-right: 20px;">
                <div style="border: 0.5px solid black; height: 30px;width: 30px; ">24B
                </div>
                <div style="border: 0.5px solid black;border-left: 0px; height: 30px;width: 30px; ">
                  &nbsp;{{getData.ScheduleAnnexed24B}}
                </div>
              </div>

              <div fxLayout="row wrap"
                fxLayoutAlign="center center"
                fxLayout.gt-md="row"
                style="margin-right: 20px;">
                <div style="border: 0.5px solid black; height: 30px;width: 30px; ">24C
                </div>
                <div style="border: 0.5px solid black;border-left: 0px; height: 30px;width: 30px; ">
                  &nbsp;{{getData.ScheduleAnnexed24C}}
                </div>
              </div>

              <div fxLayout="row wrap"
                fxLayoutAlign="center center"
                fxLayout.gt-md="row"
                style="margin-right: 20px;">
                <div style="border: 0.5px solid black; height: 30px;width: 30px; ">24D
                </div>
                <div style="border: 0.5px solid black;border-left: 0px; height: 30px;width: 30px; ">
                  &nbsp;{{getData.ScheduleAnnexed24D}}
                </div>
              </div>
            </div>

          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;53</div>
            </div>
            <div style="height: 50px;border-right: 1px solid black;border-bottom: 1px solid black; width: 30%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Schedules annexed
                </div>
                <div style="font: 16px 'Times New Roman';">(tick all that are applicable)
                </div>
              </div>
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="center center"
              fxLayout.gt-md="row"
              style="height: 50px;border-bottom: 1px solid black; width: 65%; padding: 5px;">
              <div fxLayout="row wrap"
                fxLayoutAlign="center center"
                fxLayout.gt-md="row"
                style="margin-right: 40px;">
                <div style="border: 0.5px solid black; height: 30px;width: 100px; ">&nbsp;IT-10B2016
                </div>
                <div style="border: 0.5px solid black;border-left: 0px; height: 30px;width: 30px; ">
                  &nbsp;{{getData.StatementAnnexedIT10B2016}}
                </div>
              </div>

              <div fxLayout="row wrap"
                fxLayoutAlign="center center"
                fxLayout.gt-md="row"
                style="margin-right: 20px;">
                <div style="border: 0.5px solid black; height: 30px;width: 100px; ">&nbsp;IT-10BB2016
                </div>
                <div style="border: 0.5px solid black;border-left: 0px; height: 30px;width: 30px; ">
                  &nbsp;{{getData.StatementAnnexedIT10BB2016}}
                </div>
              </div>

            </div>

          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 160px;border-right: 1px solid black;width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;54</div>
            </div>
            <div style="height: 160px;width: 95%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding-left: 5px;">
                <div style="font: 16px 'Times New Roman';">Other statements, documents, etc. attached (list all)
                </div>
                <div style="font: 16px 'Times New Roman';">&nbsp;</div>
              </div>
            </div>
          </div>

        </div>

        <div fxLayout="column wrap"
          fxLayoutAlign="center center"
          fxLayout.gt-md="column"
          style="padding-bottom: 5px;
        margin-top: 20px;">
          <div style="font: bold 19px 'Times New Roman';">Verification and signature
          </div>
        </div>
        <div style="width: 100%;border-collapse: collapse;
      border: 1px solid black;">
          <div fxLayout="row wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="row">
            <div style="height: 170px;border-right: 1px solid black;width: 5%;">
              <div fxLayoutAlign="start start"
                style="font: 16px 'Times New Roman';">&nbsp;55</div>
            </div>
            <div style="height: 170px;width: 95%;">
              <div fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div style="font:bold 16px 'Times New Roman';padding-left: 5px;">Verification</div>
                <div style="font: 16px 'Times New Roman';padding-left: 5px;">I solemnly declare that to the best of my
                  knowledge and belief the information
                  given in this return
                  and statements and documents annexed or attached herewith are correct and complete.</div>
                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row">
                  <div fxLayout="column wrap"
                    fxLayoutAlign="start"
                    fxLayout.gt-md="column"
                    style="width: 50%;border: 1px solid black;border-right: 0;
                  border-left: 0;padding-left: 5px;">
                    <div style="font: 16px 'Times New Roman';
                  ">Name </div>
                    <div style="font: 16px 'Times New Roman';
                    ">&nbsp; {{getData.AssesseeName}}</div>
                    <div style="font: 16px 'Times New Roman';
                    ">&nbsp; </div>
                  </div>
                  <div fxLayout="column wrap"
                    fxLayoutAlign="start"
                    fxLayout.gt-md="column"
                    style="width: 50%;border: 1px solid black;border-right: 0;padding-left: 5px;">
                    <div style="font: 16px 'Times New Roman';">Signature</div>
                    <div style="font: 16px 'Times New Roman';
                      ">&nbsp; </div>
                    <div style="font: 16px 'Times New Roman';
                      ">&nbsp; </div>
                  </div>

                </div>

                <div fxLayout="row wrap"
                  fxLayoutAlign="start"
                  fxLayout.gt-md="row">
                  <div fxLayout="column wrap"
                    fxLayoutAlign="start"
                    fxLayout.gt-md="column"
                    style="width: 50%;padding-left: 5px;">
                    <div fxLayout="row wrap"
                      fxLayoutAlign="start"
                      fxLayout.gt-md="row">
                      <div style="font: 16px 'Times New Roman';">Date of Signature </div>
                      <div style="font: 11px 'Times New Roman';margin-left: 5px;margin-top: 2px;">(DD-MM-YYYY)</div>
                    </div>
                    <div fxLayout="row wrap"
                      fxLayoutAlign="start"
                      fxLayout.gt-md="row"
                      style="margin-bottom: 10px;font:bold 16px;">
                      <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                        &nbsp;
                      </div>
                      <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                        &nbsp;
                      </div>
                      <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                        &nbsp;
                      </div>
                      <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                        &nbsp;
                      </div>
                      <div
                        style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                        2</div>
                      <div
                        style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                        0</div>
                      <div
                        style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                      </div>
                      <div
                        style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                      </div>

                    </div>
                  </div>
                  <div fxLayout="column wrap"
                    fxLayoutAlign="start"
                    fxLayout.gt-md="column"
                    style="width: 50%;border-left: 1px solid black;padding-left: 5px;">
                    <div style="font: 16px 'Times New Roman';">Place of Signature </div>
                    <div style="font: 16px 'Times New Roman';
                    ">&nbsp; </div>
                    <div style="font: 16px 'Times New Roman';
                    ">&nbsp; </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>

        <div fxLayout="column wrap"
          fxLayoutAlign="center center"
          fxLayout.gt-md="column"
          style="padding-bottom: 10px;
        padding-top: 10px;">
          <div style="font: bold 19px 'Times New Roman';">For official use only</div>
          <div style="font:  19px 'Times New Roman';">Return Submission Information
          </div>

        </div>

        <div fxLayout="row wrap"
          fxLayoutAlign="start"
          fxLayout.gt-md="row"
          style="border: 1px solid black; height: 70px;">
          <div fxLayout="column wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="column"
            style="width: 50%;padding-left: 5px;">
            <div fxLayout="row wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="row">
              <div style="font: 16px 'Times New Roman';">Date of Submission </div>
              <div style="font: 11px 'Times New Roman';margin-left: 5px;margin-top: 2px;">(DD-MM-YYYY)</div>
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="row"
              style="margin-bottom: 10px;font:bold 16px;">
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div
                style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                2</div>
              <div
                style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                0</div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
              </div>

            </div>
          </div>
          <div fxLayout="column wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="column"
            style="width: 50%;border-left: 1px solid black;padding-left: 5px;">
            <div style="font: 16px 'Times New Roman';">Tax Office Entry Number
            </div>
            <div fxLayout="row wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="row"
              style="margin-bottom: 10px;font:bold 16px;">
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                &nbsp;
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
              </div>
              <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
              </div>

            </div>
          </div>

        </div>

      </div>

      <div id="page_4"
        style="height: 1200px;">
        <div fxLayout="row wrap"
          fxLayoutAlign="space-between"
          fxLayout.gt-md="row">
          <div fxLayout="column wrap"
            fxLayoutAlign="center center"
            fxLayout.gt-md="column">
            <div style="font: 13px 'Times New Roman'">National Board of Revenue</div>
            <div style="font: 13px 'Times New Roman';">www.nbr.gov.bd</div>

          </div>
          <div fxLayout="column wrap"
            fxLayoutAlign="space-between"
            fxLayout.gt-md="column"
            style="padding-right: 10px;">
            <div style="font: bold 14px 'Times New Roman';">Individual</div>

          </div>

        </div>
        <div fxLayout="column wrap"
          fxLayoutAlign="center center"
          fxLayout.gt-md="column"
          style="padding-bottom: 20px;padding-top: 30px;">
          <div style="font: bold 19px 'Times New Roman';">ACKNOWLEDGEMENT RECEIPT OF </div>
          <div style="font: bold 19px 'Times New Roman';">RETURN OF INCOME </div>
        </div>

        <div style="width: 100%;border-collapse: collapse;border: 1px solid black;">
          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="height: 70px;">
            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;padding-left: 5px;">
              <div fxLayout="row wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row">
                <div style="font: 16px 'Times New Roman';">&nbsp;Assessment Year </div>

              </div>
              <div fxLayout="row wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row"
                style="margin-bottom: 10px;font:bold 16px ;">
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                  2</div>
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                  0</div>
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                </div>
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                </div>
                <div style="height: 30px;width: 30px; margin-right: 5px;text-align: center;">-</div>

                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                  &nbsp;
                </div>
                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">&nbsp;</div>

              </div>
            </div>
            <div fxLayout="column wrap"
              fxLayoutAlign="space-between"
              fxLayout.gt-md="column"
              style="width: 50%;
            border-left: 1px solid black;">
              <div style="font: 16px 'Times New Roman';border-bottom: 1px solid black;">&nbsp; Return under section
                82BB?
                (tick one)
              </div>
              <div fxLayout="row wrap"
                fxLayoutAlign="space-between"
                fxLayout.gt-md="row"
                style="height: 50px;">
                <div fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 50%;">
                  <div style="font: 16px 'Times New Roman';">&nbsp;Yes</div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px;margin-top: 1px; margin-right: 10px;">
                  </div>
                </div>

                <div fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayout.gt-md="row"
                  style="width: 50%; border-left: 1px solid black;">
                  <div style="font: 16px 'Times New Roman';">&nbsp;No</div>
                  <div style="border: 0.5px solid black; height: 30px;width: 30px;margin-top: 1px; margin-right: 10px;">
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div fxLayout="column wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="column"
            style="height: 70px; border-top: 1px solid black;">
            <div style="font: 16px 'Times New Roman';">&nbsp;Name of the Assessee </div>
            <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.AssesseeName}}</div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="height: 70px; border-top: 1px solid black;">

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;Twelve-digit TIN </div>
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.ETIN}}</div>
            </div>

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;border-left: 1px solid black;">
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;Old TIN </div>
              <div style="font: 16px 'Times New Roman';
            ">&nbsp;{{getData.OldTIN}}</div>
            </div>

          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="height: 70px; border-top: 1px solid black;">

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Circle </div>
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.Circle}}</div>
            </div>

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;border-left: 1px solid black;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Taxes Zone </div>
              <div style="font: 16px 'Times New Roman';">&nbsp;{{getData.Zone}}</div>
            </div>

          </div>

          <div fxLayout="column wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="column"
            style="height: 70px; border-top: 1px solid black;">
            <div style="font: 16px 'Times New Roman';">&nbsp;Total income shown (serial 34) </div>
            <div style="font: 16px 'Times New Roman';">&nbsp;৳ &nbsp;{{getData.TotalIncome}}</div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="height: 70px; border-top: 1px solid black;">

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Amount payable (serial 41) </div>
              <div style="font: 16px 'Times New Roman';">&nbsp;৳&nbsp;{{getData.TotalAmountPayable}}</div>
            </div>

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;border-left: 1px solid black;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Amount paid and adjusted (serial 46) </div>
              <div style="font: 16px 'Times New Roman';">&nbsp;৳ &nbsp;{{getData.AmountPaidAndAdjusted}}</div>
            </div>

          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="height: 70px; border-top: 1px solid black;">

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Amount of net wealth shown in IT10B2016 </div>
              <div style="font: 16px 'Times New Roman';">&nbsp;৳</div>
            </div>

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;border-left: 1px solid black;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Amount of net wealth surcharge paid </div>
              <div style="font: 16px 'Times New Roman';">&nbsp;৳ &nbsp;{{getData.NetWealthSurcharge}}</div>
            </div>

          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="border-left: 0;border-top: 1px solid black; height: 70px;">
            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;padding-left: 5px;">
              <div fxLayout="row wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row">
                <div style="font: 16px 'Times New Roman';">Date of Submission </div>
                <div style="font: 11px 'Times New Roman';margin-left: 5px;margin-top: 2px;">(DD-MM-YYYY)</div>
              </div>
              <div fxLayout="row wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row"
                style="margin-bottom: 10px;font:bold 16px;">
                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                  &nbsp;
                </div>
                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                  &nbsp;
                </div>
                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                  &nbsp;
                </div>
                <div style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;">
                  &nbsp;
                </div>
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                  2</div>
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;font: bold;text-align: center;">
                  0</div>
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                </div>
                <div
                  style="border: 0.5px solid black; height: 30px;width: 30px; margin-right: 5px;text-align: center;">
                </div>

              </div>
            </div>
            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;padding-left: 5px;border-left: 1px solid black;">
              <div style="font: 16px 'Times New Roman';">Tax Office Entry Number </div>
              <div style="font: 16px 'Times New Roman';">&nbsp; </div>
            </div>

          </div>

          <div fxLayout="column wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="column"
            style="height: 150px; border-top: 1px solid black;">
            <div style="font: 16px 'Times New Roman';">&nbsp;Signature and seal of the official receiving the return
            </div>
            <div style="font: 16px 'Times New Roman';">&nbsp; </div>
          </div>

          <div fxLayout="row wrap"
            fxLayoutAlign="start"
            fxLayout.gt-md="row"
            style="height: 70px; border-top: 1px solid black;">

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Date of Signature </div>
              <div style="font: 16px 'Times New Roman';">&nbsp; </div>
            </div>

            <div fxLayout="column wrap"
              fxLayoutAlign="start"
              fxLayout.gt-md="column"
              style="width: 50%;border-left: 1px solid black;">
              <div style="font: 16px 'Times New Roman';">&nbsp;Contact Number of Tax Office </div>
              <div style="font: 16px 'Times New Roman';">&nbsp; </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
