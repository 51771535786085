import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import { BankLoansClass } from './BankLoans.Class';

@Component({
  selector: 'BankLoans-form-control',
  templateUrl: './BankLoans-form-control.component.html',
  styleUrls: ['./BankLoans.component.scss']

})
export class BankLoansControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;


  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }

  onKey(event: any, key)
  {
    let rawForm = this.form.getRawValue();

    if (key == "OpeningBalanceAmount" || key == "LoanOutstandingAmount")
    {

      var bankLoansClass = new BankLoansClass(
        rawForm.OpeningBalanceAmount,
        rawForm.LoanOutstandingAmount,
        rawForm.PrincipalPaidAmount
      )

      this.form.patchValue({
        PrincipalPaidAmount: Number(bankLoansClass.PrincipalPaidAmount).toFixed(2),

      })

    }

  }
}
