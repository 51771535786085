import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';


import { Layouts } from 'app/enum/layouts.enum';
import { MailVerificationHomeComponent } from './mail-verification-home.component';
import { TranslateModule } from '@ngx-translate/core';

const routes = [
    {
        path     : 'mail-verification',
        component: MailVerificationHomeComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        MailVerificationHomeComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,

        MatIconModule,

        FuseSharedModule
    ]
})
export class MailVerificationHomeModule
{
}
