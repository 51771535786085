import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AppUser, AuthenticationService } from 'app/auth';
import Utils from 'app/util/utils';
import { Router } from '@angular/router';
import { FetchClientsService } from 'app/services/fetchClients.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangeImageService } from 'app/services/changeImage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientsListService } from 'app/pages/Manage-Clients/Clients-List/ClientsList.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: AppUser;
    AssesseeId: any;
    subscription: Subscription;
    subscription_2: Subscription;
    lang = '';

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _authService: AuthenticationService,
        public _router: Router,
        private fetchClientsService: FetchClientsService,
        private changeImageService: ChangeImageService,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _formControlService: ClientsListService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        public dialog: MatDialog,

    )
    {
        this.lang = localStorage.getItem('selectedLanguage');
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            // {
            //     id: 'en',
            //     title: 'English',
            //     flag: 'us'
            // },
            // {
            //     id: 'bn',
            //     title: 'Bangla',
            //     flag: 'bn'
            // }


            {
                id: 'en',
                title: 'English',
                name: 'Eng',
                flag: 'us'
            },
            {
                id: 'bn',
                title: 'বাংলা',
                name: 'বাং',
                flag: 'bn'
            }
        ];

        this.navigation = navigation;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.user = Utils.getUserInfo();
        this.AssesseeId = JSON.parse(localStorage.getItem('AssesseeId'));
        // console.log(this.user, 'this.user');

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) =>
            {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

        let localCulture = localStorage.getItem('selectedLanguage');

        if (localCulture != null)
        {
            this._translateService.use(localCulture);
            this.selectedLanguage = _.find(this.languages, { id: localCulture });
        }
        else
        {
            localStorage.setItem('selectedLanguage', this._translateService.currentLang)
        }

        this.subscription = this.fetchClientsService.currentMessage.subscribe(message =>
        {

            if (message == true)
            {
                this.AssesseeId = JSON.parse(localStorage.getItem('AssesseeId'));
            }
        })

        this.subscription_2 = this.changeImageService.currentMessage.subscribe(message =>
        {

            if (message == true)
            {
                this.user = Utils.getUserInfo();
            }
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subscription.unsubscribe();
        this.subscription_2.unsubscribe();

        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */

    clearAssessee(): void
    {
        localStorage.removeItem('AssesseeId');
        this._router.navigateByUrl('/Clients');
    }

    ExitClient(): void
    {

        this._spinner.show();

        this._formControlService.ExitReturn().subscribe((data: any) =>
        {
            // console.log(data, 'data');

            localStorage.setItem('userToken', data.access_token);
            localStorage.setItem('userRefToken', data.refresh_token);

            delete data['access_token'];
            delete data['refresh_token'];
            delete data['token_type'];
            delete data['expires_in'];
            delete data['error'];
            delete data['.issued'];
            delete data['.expires'];

            localStorage.removeItem('stepMenu');
            localStorage.removeItem('IsCurrentYear');
            localStorage.removeItem('archiveReadiness');
            
            localStorage.setItem('userInfo', JSON.stringify(data));
            localStorage.setItem('TokenInfo', data.UserId);
            localStorage.setItem('userName', data.UserName);
            // localStorage.setItem('IsCurrentYear', JSON.stringify(object));
            // localStorage.setItem('SwitchedReturnID', ReturnId);

            this.clearAssessee();

            this._spinner.hide();

            // if (data.ActiveRoleId == "8")
            // {
            // this._router.navigateByUrl('/Dashboard');
            // }

        },
            (err: HttpErrorResponse) =>
            {
                console.log(err, 'err');
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        )
    }


    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    toggleSidebar(name): void
    {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }


    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        // console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        if (localStorage.getItem('selectedLanguage') != null)
        {
            localStorage.removeItem('selectedLanguage');
        }
        localStorage.setItem('selectedLanguage', lang.id);
        // Use the selected language for translations
        this._translateService.use(lang.id);
        window.location.reload();
    }

    public Logout(): void
    {
        this._authService.Logout();
    }

}
