<!-- <div class="navbar-top"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"> -->
<div class="navbar-top">

    <div class="logo">
        <button (click)="goToHome()"
            style="border: 0px; background-color: transparent; align-items: center !Important;cursor: pointer;">
            <!-- <img class="logo-icon"
                src="assets/images/logos/fuse.svg"> -->
            <img width="50%"
                height="50%"
                src="assets/home/img/logo-n.png">

            <!-- <span class="logo-text secondary-text">assets/home/img/logo-n.png</span> -->
        </button>
    </div>

    <div class="buttons">

        <button mat-icon-button
            class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()"
            fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button
            class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()"
            fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>

<!-- <div class="navbar-scroll-container"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{suppressScrollX: true}"> -->
<div class="navbar-scroll-container">

    <div class="navbar-content"
        style="padding-top: 10px !important;">
        <fuse-navigation class="material2"
            layout="vertical"></fuse-navigation>
    </div>

</div>
