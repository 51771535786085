import { Component, HostListener, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ListDialogComponent } from '@fuse/components/list-dialog/list-dialog.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsSaveModel } from './ClientsSave.Model';
import { ClientsSaveService } from './ClientsSave.service';

import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import Utils from 'app/util/utils';
import { AppUser } from 'app/auth';
import { Meta, Title } from '@angular/platform-browser';
import { ClientsListService } from '../Clients-List/ClientsList.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'ClientsSave',
    templateUrl: './ClientsSave.component.html',
    styleUrls: ['./ClientsSave.component.scss']
})
export class ClientsSaveComponent
{
    form: FormGroup;
    id: any;
    lang = '';
    payLoad: any;
    user: AppUser;
    DateOfBirth: any;
    dropDownData: any;
    dashBoardData: any;
    isSingle = false;
    isMarried = false;
    sameAddress = false;
    enableCheck = false;

    SameAsPresentAddress = new FormControl();
    @Input() nStatus: boolean;
    @Input() rStatus: boolean;
    @Input() r2Status: boolean;


    maxDate = new Date();


    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: ClientsSaveService,
        private _clientsListService: ClientsListService,
        private _formControlListService: ClientsListService,
        private _spinner: NgxSpinnerService,
        private sidePanelSharedService: SidePanelSharedService,
        private _router: Router,


        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private _formBuilder: FormBuilder,
        private back: BackButtonService,
        private _fuseNavigationService: FuseNavigationService,

    )
    {

        this.meta.addTags([
            { name: 'description', content: "digiTax Add Clients" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Add Clients" }
        ]);
        this.setTitle("digiTax | Add Clients");
        // this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');
        this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }

        this.lang = localStorage.getItem('selectedLanguage');

        if (this.lang == 'en')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        if (this.lang == 'bn')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        this.form = this._formBuilder.group(new ClientsSaveModel());

        if (this.id != null)
        {
            this.getPageData();
        }
        else
        {
            this.getDashBoard_And_DropdownData();
        }



    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }



    ngOnInit(): void
    {
        // this.getData();


    }

    ValueSet(): void
    {

        this.form.patchValue({
            Stayed1: this.form.controls["Stayed1"].value == "" ? null : parseInt(this.form.controls["Stayed1"].value.toString())
        });
        let Stayed1 = this.form.controls["Stayed1"].value;
        // console.log(typeof Stayed1, 'typeof Stayed1');

        this.form.patchValue({
            Nationality: this.form.controls["Nationality"].value == 0 || "0" ? 1 : this.form.controls["Nationality"].value
        });
        let Nationality = this.form.controls["Nationality"].value;
        // console.log(typeof Nationality, 'typeof Nationality');

        let floorElement1: HTMLElement = document.getElementById("Stayed2") as HTMLElement;
        let floorElement2: HTMLElement = document.getElementById("ResidentStatus") as HTMLElement;
        let floorElement3: HTMLElement = document.getElementById("Stayed1") as HTMLElement;

        let lang = localStorage.getItem('selectedLanguage');
        let rs = '';
        let gs = '';
        lang == 'en' ? rs = 'Resident' : rs = 'আবাসিক';
        lang == 'en' ? gs = 'Non-Resident' : gs = 'অনাবাসিক';

        if (Nationality == "1" || Nationality == "2" || Nationality == 1 || Nationality == 2)
        {
            if (floorElement3 != null)
            {
                if (floorElement3.classList.contains('hidden'))
                {
                    floorElement3.classList.remove('hidden');
                }
            }
        }

        if (Stayed1 == "1" || Stayed1 == 1)
        {
            if (floorElement2 != null)
            {
                document.getElementById("resident").innerHTML = rs;
            }
            if (floorElement1 != null)
            {
                if (!floorElement1.classList.contains('hidden'))
                {
                    floorElement1.classList.add('hidden');
                }
            }
            if (floorElement2 != null)
            {
                if (floorElement2.classList.contains('hidden'))
                {
                    floorElement2.classList.remove('hidden');
                    document.getElementById("resident").innerHTML = rs;
                }
            }
        }
        if (Stayed1 == "2" || Stayed1 == 2)
        {
            if (floorElement1 != null)
            {
                if (floorElement1.classList.contains('hidden'))
                {
                    floorElement1.classList.remove('hidden');
                }
            }
            if (floorElement2 != null)
            {
                document.getElementById("resident").innerHTML = gs;
            }
            if (floorElement2 != null)
            {
                if (floorElement2.classList.contains('hidden'))
                {
                    floorElement2.classList.remove('hidden');
                }
            }
        }

        this.form.patchValue({
            Stayed2: this.form.controls["Stayed2"].value == "" ? null : parseInt(this.form.controls["Stayed2"].value.toString())
        });
        let Stayed2 = this.form.controls["Stayed2"].value;
        // console.log(typeof Stayed2, 'typeof Stayed2');

        if (Stayed2 == "1" || Stayed2 == 1)
        {
            if (floorElement2 != null)
            {
                document.getElementById("resident").innerHTML = rs;
            }
            if (floorElement2 != null)
            {
                if (floorElement2.classList.contains('hidden'))
                {
                    floorElement2.classList.remove('hidden');
                }
            }
            if (floorElement1 != null)
            {
                if (floorElement1.classList.contains('hidden'))
                {
                    floorElement1.classList.remove('hidden');
                    document.getElementById("resident").innerHTML = rs;
                }
            }
        }
        if (Stayed2 == "2" || Stayed2 == 2)
        {
            if (floorElement2 != null)
            {
                document.getElementById("resident").innerHTML = gs;
            }
            if (floorElement2 != null)
            {
                if (floorElement2.classList.contains('hidden'))
                {
                    floorElement2.classList.remove('hidden');
                }
            }
        }
    }

    getMainDropData(): void
    {
        this._spinner.show();
        this._formControlListService.GetCommomDropdown().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
                this.dropDownData = data;
                // console.log(this.dropDownData, 'this.dropDownData');

                // this.AssessmentYearSearchControl.setValue(this.dropDownData.CurrentYearId.toString());

            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        });
    }


    getPageData()
    {
        this._spinner.show();
        forkJoin([
            this._formControlService.getControlJson(this.id),
            this._formControlListService.GetCommomDropdown(),
            this._clientsListService.GetClientsDashBoardData()
        ]).subscribe(
            (result: any) =>
            {
                let pageDataResponse = result[0];
                // console.log(pageDataResponse, 'pageDataResponse');

                let dropDownResponse = result[1];
                // console.log(dropDownResponse, 'dropDownResponse');

                let dashBoardResponse = result[2];
                // console.log(dashBoardResponse, 'dashBoardResponse');

                if (dropDownResponse != null)
                {
                    this.dropDownData = dropDownResponse;
                }
                // console.log(this.dropDownData, 'dropDownData');


                if (pageDataResponse.data != null)
                {
                    // console.log(pageDataResponse.data, 'pageDataResponse.data');

                    if (pageDataResponse.data.MaritalStatus != null)
                    {
                        this.onSelectMaritalStatus(pageDataResponse.data.MaritalStatus);
                    }






                    this.form = this._formBuilder.group(pageDataResponse.data);

                    let DateOfBirth = this.form.controls["DateOfBirth"].value;

                    if (DateOfBirth != null)
                    {
                        this.form.patchValue({
                            DateOfBirth: moment(DateOfBirth, "DD-MM-YYYY")
                        });
                        this.DateOfBirth = new FormControl(moment(DateOfBirth, "DD-MM-YYYY"));
                    }

                    let PermanentDistrictId = this.form.controls["PermanentDistrictId"].value;

                    if (PermanentDistrictId != null)
                    {
                        this.form.patchValue({
                            PermanentDistrictId: PermanentDistrictId.toString()
                        });
                    }

                    let PresentDistrictId = this.form.controls["PresentDistrictId"].value;

                    if (PresentDistrictId != null)
                    {
                        this.form.patchValue({
                            PresentDistrictId: PresentDistrictId.toString()
                        });
                    }

                    let ZoneId = this.form.controls["ZoneId"].value;

                    if (ZoneId != null)
                    {
                        this.form.patchValue({
                            ZoneId: ZoneId.toString()
                        });
                    }

                    // if (pageDataResponse.data.PresentAddress != null || "" && pageDataResponse.data.PresentAddress === pageDataResponse.data.PermanentAddress)
                    // {

                    //     this.sameAddress = true;
                    //     this.SameAsPresentAddress.setValue(true);
                    //     this.showOptions(true);

                    // }

                    this.ValueSet();

                }

                if (!Utils.isEmptyObject(dashBoardResponse.data) || dashBoardResponse.data != null)
                {
                    this.dashBoardData = dashBoardResponse.data;
                }

                this._spinner.hide();

            }, (error: any) =>
        {
            this._spinner.hide();
            console.log(error, 'error');
        })

    }

    getDashBoard_And_DropdownData()
    {
        this._spinner.show();
        forkJoin([
            this._formControlListService.GetCommomDropdown(),
            this._clientsListService.GetClientsDashBoardData()
        ]).subscribe(
            (result: any) =>
            {
                let dropDownResponse = result[0];
                // console.log(dropDownResponse, 'dropDownResponse');

                let dashBoardResponse = result[1];
                // console.log(dashBoardResponse, 'dashBoardResponse');

                if (dropDownResponse != null)
                {
                    this.dropDownData = dropDownResponse;
                }
                // console.log(this.dropDownData, 'dropDownData');

                if (!Utils.isEmptyObject(dashBoardResponse.data) || dashBoardResponse.data != null)
                {
                    this.dashBoardData = dashBoardResponse.data;
                }

                this._spinner.hide();

            }, (error: any) =>
        {
            this._spinner.hide();
            console.log(error, 'error');
        })

    }

    getData(): void
    {

        this._spinner.show();

        this._formControlService.getControlJson(this.id).subscribe((data: any) =>
        {
            if (data.data != null)
            {
                this.form = this._formBuilder.group(data.data);

                let DateOfBirth = this.form.controls["DateOfBirth"].value;



                if (DateOfBirth != null)
                {
                    this.form.patchValue({
                        DateOfBirth: moment(DateOfBirth, "DD-MM-YYYY")
                    });
                    this.DateOfBirth = new FormControl(moment(DateOfBirth, "DD-MM-YYYY"));
                }


            }

        });
        this._spinner.hide();
    }

    OnSubmit(): void
    {
        if (this.dashBoardData.NumberOfClient >= this.dashBoardData.AllowedNumberOfClient)
        {
            this._matSnackBar.open("You Have Not Purchased Enough Client for this operation", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (!this.form.valid)
            {
                this.form.markAllAsTouched();
            }

            if (this.form.controls.Stayed1.valid == false)
            {
                this.rStatus = true;
            }

            if ((this.form.controls.Stayed1.value == '2' || this.form.controls.Stayed1.value == 2) && (this.form.controls.Stayed2.value == '' || this.form.controls.Stayed2.value == null))
            {
                this.r2Status = true;
            }

            if (this.form.controls.Nationality.valid == false)
            {
                this.nStatus = true;
            }

            this._spinner.show();

            let obj = this.form.getRawValue();


            if (obj.ETIN == null || obj.ETIN == "0" || isNaN(Number(obj.ETIN)) == true)
            {
                obj.ETIN = "";
            }

            if (obj.AssesseeName == null)
            {
                obj.AssesseeName = "";
            }

            if (obj.ContactNo == null || obj.ContactNo == "0" || isNaN(Number(obj.ContactNo)) == true)
            {
                obj.ContactNo = "";
            }

            if (obj.Email == null)
            {
                obj.Email = "";
            }

            if (this.id == null)
            {
                obj.AssesseeId = "0";
            }

            if (obj.Stayed1 == "1" && obj.Stayed2 == "")
            {
                obj.ResidentStatus = '1';
            }
            if (obj.Stayed1 == "1" && obj.Stayed2 == null)
            {
                obj.ResidentStatus = '1';
            }
            if (obj.Stayed1 == "2" && obj.Stayed2 == "1")
            {
                obj.ResidentStatus = '2';
            }
            if (obj.Stayed1 == "2" && obj.Stayed2 == "2")
            {
                obj.ResidentStatus = '3';
            }
            if (obj.Stayed1 == "2" && obj.Stayed2 == "")
            {
                obj.ResidentStatus = '3';
            }
            if (obj.Stayed1 == "2" && obj.Stayed2 == null)
            {
                obj.ResidentStatus = '3';
            }
            if (obj.ZoneId != null)
            {
                obj.Zone = obj.ZoneId;
            }

            if (this.SameAsPresentAddress.value == true)
            {
                obj.PermanentPostCode = obj.PresentPostCode;
                obj.PermanentDistrictId = obj.PresentDistrictId;
                obj.PermanentAddress = obj.PresentAddress;
            };


            let stringed = JSON.stringify(obj);

            // console.log(stringed, 'stringed');

            debugger;
            if (obj.ETIN.length != 12 || obj.ETIN == "")
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.CLIENT.ENTER_ETIN : bangla.data.CLIENT.ENTER_ETIN, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (obj.AssesseeName == "")
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.CLIENT.ENTER_AssesseeName : bangla.data.CLIENT.ENTER_AssesseeName, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    if (obj.ContactNo.length != 11 || obj.ContactNo == "")
                    {
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.CLIENT.ENTER_Mobile : bangla.data.CLIENT.ENTER_Mobile, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                    else
                    {
                        if (Utils.validateEmail((obj.Email)) == false)
                        {
                            this._spinner.hide();
                            this._matSnackBar.open(this.lang == 'en' ? english.data.CLIENT.ENTER_Email : bangla.data.CLIENT.ENTER_Email, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                        else
                        {
                            if (obj.Circle == "")
                            {
                                this._spinner.hide();
                                this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_CIRCLE : bangla.data.ETIN.R_CIRCLE, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',

                                });
                            } else
                            {
                                if (obj.ZoneId == "" || obj.ZoneId == null)
                                {
                                    this._spinner.hide();
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ZONE : bangla.data.ETIN.R_ZONE, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',

                                    });
                                } else
                                {
                                    if (obj.Nationality == "" || obj.Nationality == null)
                                    {
                                        this._spinner.hide();
                                        this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_NAT_STATUS : bangla.data.ETIN.R_NAT_STATUS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',

                                        });
                                    }
                                    else
                                    {
                                        if (obj.ResidentialAreaId == "" || obj.ResidentialAreaId == null)
                                        {
                                            this._spinner.hide();
                                            this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_AREA_OF_RESIDENT : bangla.data.ETIN.R_AREA_OF_RESIDENT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                verticalPosition: 'top',

                                            });
                                        }
                                        else
                                        {
                                            // console.log(obj.Stayed1, 'obj.Stayed1');

                                            if (obj.Stayed1 == "" || obj.Stayed1 == null)
                                            {
                                                this._spinner.hide();
                                                this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_RES_STATUS : bangla.data.ETIN.R_RES_STATUS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                    verticalPosition: 'top',

                                                });
                                            }
                                            else
                                            {
                                                if ((obj.Stayed1 == "2" || obj.Stayed1 == 2) && (obj.Stayed2 == "" || obj.Stayed2 == null))
                                                {
                                                    this._spinner.hide();
                                                    this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_RES_STATUS : bangla.data.ETIN.R_RES_STATUS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                        verticalPosition: 'top',

                                                    });
                                                }
                                                else
                                                {
                                                    if (moment(obj.DateOfBirth).isValid() === false)
                                                    {
                                                        this._spinner.hide();
                                                        this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_DOB : bangla.data.PERSONAL.R_DOB, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                            verticalPosition: 'top',
                                                            duration: 2000
                                                        });
                                                    }
                                                    else
                                                    {
                                                        if (obj.Gender == null || obj.Gender == "")
                                                        {
                                                            this._spinner.hide();
                                                            this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_SELECT_GENDER : bangla.data.PERSONAL.R_SELECT_GENDER, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                verticalPosition: 'top',
                                                                duration: 2000
                                                            });
                                                        }
                                                        else
                                                        {
                                                            if (obj.MaritalStatus == null || obj.MaritalStatus == "")
                                                            {
                                                                this._spinner.hide();
                                                                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_MARITAL_STATUS : bangla.data.PERSONAL.R_MARITAL_STATUS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                    verticalPosition: 'top',
                                                                    duration: 2000
                                                                });
                                                            }
                                                            else
                                                            {
                                                                if (obj.PresentAddress == null || obj.PresentAddress == "")
                                                                {
                                                                    this._spinner.hide();
                                                                    this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_PRESENT_ADDRESS : bangla.data.PERSONAL.R_PRESENT_ADDRESS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                        verticalPosition: 'top',
                                                                        duration: 2000
                                                                    });
                                                                }
                                                                else
                                                                {
                                                                    if (obj.PermanentAddress == null || obj.PermanentAddress == "")
                                                                    {
                                                                        this._spinner.hide();
                                                                        this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_PERMANENT_ADDRESS : bangla.data.PERSONAL.R_PERMANENT_ADDRESS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                            verticalPosition: 'top',
                                                                            duration: 2000
                                                                        });
                                                                    }
                                                                    else
                                                                    {
                                                                        if (stringed != null)
                                                                        {
                                                                            this._formControlService.submitData(stringed)
                                                                                .subscribe((data: any) =>
                                                                                {
                                                                                    if (data != null)
                                                                                    {
                                                                                        this._spinner.hide();
                                                                                    }

                                                                                    if (data.isError === false)
                                                                                    {
                                                                                        console.log('submit succesfull');
                                                                                        this._spinner.hide();
                                                                                    }
                                                                                    else
                                                                                    {
                                                                                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                                            verticalPosition: 'top',
                                                                                            duration: 2000
                                                                                        });
                                                                                        this._spinner.hide();
                                                                                    }
                                                                                    if (data.isError === false && data.responseCode == "200")
                                                                                    {
                                                                                        if (this.id != null)
                                                                                        {
                                                                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                                                verticalPosition: 'top',
                                                                                                duration: 2000
                                                                                            });
                                                                                            this._spinner.hide();
                                                                                        }
                                                                                        else
                                                                                        {
                                                                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                                                verticalPosition: 'top',
                                                                                                duration: 2000
                                                                                            });
                                                                                            this._spinner.hide();
                                                                                        }
                                                                                        this._router.navigate(['/Clients']);


                                                                                    }
                                                                                }, (error: any) =>
                                                                                {
                                                                                    this._spinner.hide();
                                                                                    console.log(error, 'error');
                                                                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                                        verticalPosition: 'top',
                                                                                        duration: 2000
                                                                                    });
                                                                                });
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    onSelectMaritalStatus(event)
    {

        let floorElement1: HTMLElement = document.getElementById("SpouseName") as HTMLElement;
        let floorElement2: HTMLElement = document.getElementById("SpouseTIN") as HTMLElement;

        if (event.value == "2" || event == "2")
        {
            this.isSingle = false;
            this.isMarried = true;

            if (floorElement1.classList.contains('readonly'))
            {
                floorElement1.classList.remove('readonly');
            }
            if (!floorElement1.classList.contains('block'))
            {
                floorElement1.classList.add('block');
            }

            if (floorElement2.classList.contains('readonly'))
            {
                floorElement2.classList.remove('readonly');
            }
            if (!floorElement2.classList.contains('block'))
            {
                floorElement2.classList.add('block');
            }

        }
        else
        {
            this.isSingle = true;
            this.isMarried = false;

            if (!floorElement1.classList.contains('readonly'))
            {
                floorElement1.classList.add('readonly');
            }
            if (floorElement1.classList.contains('block'))
            {
                floorElement1.classList.remove('block');
            }

            if (!floorElement2.classList.contains('readonly'))
            {
                floorElement2.classList.add('readonly');
            }
            if (floorElement2.classList.contains('block'))
            {
                floorElement2.classList.remove('block');
            }
            this.form.patchValue({
                SpouseName: '',
                SpouseTIN: '',
            });
        }



    }

    showOptions(event)
    {
        let floorElement1: HTMLElement = document.getElementById("PermanentAddress") as HTMLElement;
        let floorElement2: HTMLElement = document.getElementById("PermanentDistrictId") as HTMLElement;
        let floorElement3: HTMLElement = document.getElementById("PermanentPostCode") as HTMLElement;

        if (event.checked == true || event == true)
        {
            this.sameAddress = true;

            if (!floorElement1.classList.contains('readonly'))
            {
                floorElement1.classList.add('readonly');
            }
            if (floorElement1.classList.contains('block'))
            {
                floorElement1.classList.remove('block');
            }

            if (!floorElement2.classList.contains('readonly'))
            {
                floorElement2.classList.add('readonly');
            }
            if (floorElement2.classList.contains('block'))
            {
                floorElement2.classList.remove('block');
            }

            if (!floorElement3.classList.contains('readonly'))
            {
                floorElement3.classList.add('readonly');
            }
            if (floorElement3.classList.contains('block'))
            {
                floorElement3.classList.remove('block');
            }

            this.form.patchValue({
                PermanentAddress: this.form.controls["PresentAddress"].value,
                PermanentDistrictId: this.form.controls["PresentDistrictId"].value,
                PermanentPostCode: this.form.controls["PresentPostCode"].value,
            });
            this.form.controls["PermanentDistrictId"].disable();
            // this.form.controls["PermanentDistrictId"].disabled;

        }
        else
        {
            this.sameAddress = false;

            if (floorElement1.classList.contains('readonly'))
            {
                floorElement1.classList.remove('readonly');
            }
            if (!floorElement1.classList.contains('block'))
            {
                floorElement1.classList.add('block');
            }

            if (floorElement2.classList.contains('readonly'))
            {
                floorElement2.classList.remove('readonly');
            }
            if (!floorElement2.classList.contains('block'))
            {
                floorElement2.classList.add('block');
            }

            if (floorElement3.classList.contains('readonly'))
            {
                floorElement3.classList.remove('readonly');
            }
            if (!floorElement3.classList.contains('block'))
            {
                floorElement3.classList.add('block');
            }

            this.form.patchValue({
                PermanentAddress: '',
                PermanentDistrictId: '',
                PermanentPostCode: '',
            });
            this.form.controls["PermanentDistrictId"].enable();

        }

    }

    radioChangeN(event)
    {

        let floorElement1: HTMLElement = document.getElementById("Stayed1") as HTMLElement;

        if (event.value == "1" || event.value == "2")
        {
            if (floorElement1.classList.contains('hidden'))
            {
                floorElement1.classList.remove('hidden');
            }
            this.nStatus = false;
        }

    }

    radioChange1(event)
    {

        let floorElement1: HTMLElement = document.getElementById("Stayed2") as HTMLElement;
        let floorElement2: HTMLElement = document.getElementById("ResidentStatus") as HTMLElement;

        this.rStatus = false;

        if (event.value == "1")
        {
            let lang = localStorage.getItem('selectedLanguage');
            let rs = '';
            lang == 'en' ? rs = 'Resident' : rs = 'আবাসিক';
            document.getElementById("resident").innerHTML = rs;

            if (!floorElement1.classList.contains('hidden'))
            {
                floorElement1.classList.add('hidden');
            }
            if (floorElement2.classList.contains('hidden'))
            {
                floorElement2.classList.remove('hidden');

                document.getElementById("resident").innerHTML = rs;
            }
            this.form.controls['Stayed2'].reset();
        }
        if (event.value == "2")
        {
            let lang = localStorage.getItem('selectedLanguage');
            let rs = '';
            lang == 'en' ? rs = 'Non-Resident' : rs = 'অনাবাসিক';
            if (floorElement1.classList.contains('hidden'))
            {
                floorElement1.classList.remove('hidden');
            }
            if (floorElement2.classList.contains('hidden'))
            {
                floorElement2.classList.remove('hidden');
            }
            document.getElementById("resident").innerHTML = rs;
        }
    }

    radioChange2(event)
    {

        let floorElement1: HTMLElement = document.getElementById("ResidentStatus") as HTMLElement;
        this.r2Status = false;

        if (event.value == "1")
        {
            let lang = localStorage.getItem('selectedLanguage');
            let rs = '';
            lang == 'en' ? rs = 'Resident' : rs = 'আবাসিক';

            document.getElementById("resident").innerHTML = rs;

            if (floorElement1.classList.contains('hidden'))
            {
                floorElement1.classList.remove('hidden');
                document.getElementById("resident").innerHTML = rs;
            }
        }
        if (event.value == "2")
        {
            let lang = localStorage.getItem('selectedLanguage');
            let rs = '';
            lang == 'en' ? rs = 'Non-Resident' : rs = 'অনাবাসিক';
            document.getElementById("resident").innerHTML = rs;
        }
    }

    radioChangeS1(event)
    {
        // console.log(event);

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}


let ob = {
    "Id": 0,
    "PractitionerId": 4,
    "AssesseeId": 2319,
    "ReturnId": 0,
    "ETIN": "123432123432",
    "AssesseeName": "Md Test",
    "ContactNo": "01912531068",
    "Email": "allworksbd@gmail.com",
    "TaxAmount": 0,
    "Percent": null,
    "DuesAmount": null,
    "Reference": "gertgrt",
    "ZoneId": null,
    "ResidentStatus": 2,
    "Nationality": 1,
    "Stayed1": "2",
    "Stayed2": "1",
    "SelectedResidentStatus": null,
    "PresentAddress": null,
    "PermanentAddress": null,
    "Zone": "2",
    "Circle": "3",
    "ResidentialAreaId": 2,
    "Gender": 1,
    "DateOfBirth": null,
    "FatherName": "dfgdrgttr",
    "MotherName": "dg",
    "MaritalStatus": 1,
    "SpouseName": "retgerstger",
    "SpouseTIN": "",
    "ReturnHistory": null
}
