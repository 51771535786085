import { Component } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as lodash from 'lodash';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { QuickPanelService } from 'app/layout/components/quick-panel/quick-panel.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SalaryEmitterService } from 'app/services/salaryEmiter.service';
import { Subscription } from 'rxjs';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';

@Component({
    selector: 'my-demo-archive-header',
    templateUrl: './my-demo-archive-header.component.html',
    styleUrls: ['./my-demo-archive-header.component.scss']
})
export class MyDemoArchiveHeader
{

    subscription: Subscription;

    topEtinData = '';
    topOwedData = null;
    topYearData = null;
    AllData = null;
    archiveStat: any;
    userInfo: any;
    showHeader: boolean;





    /**
     * Constructor
     */
    constructor(
        private sidePanelSharedService: SidePanelSharedService,
        private eventEmitterService: EventEmitterService,

        private salaryEmitterService: SalaryEmitterService,
        private sidePanelDeleteService: SidePanelDeleteService,

        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        private _quickPanelService: QuickPanelService,
        private _fuseNavigationService: FuseNavigationService,


    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.archiveStat = JSON.parse(localStorage.getItem('archiveReadiness'));
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (this.userInfo.ActiveRoleId == '8')
        {
            if (this.archiveStat.IsNewReturn)
            {
                this.showHeader = true;
            }
            else
            {
                this.showHeader = false;
            }
        }
        else
        {
            this.showHeader = false;
        }

    }

    ngOnInit(): void
    {


    }

    ngOnDestroy()
    {
        // this.subscription.unsubscribe();
    }





}

