import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsListService } from '../ClientsList.service';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';

import * as moment from 'moment';

@Component({
    selector: 'RemissionDialog',
    templateUrl: 'remission-Dialog.component.html',
    styleUrls: ['./remission-Dialog.component.scss'],
})
export class RemissionDialog
{


    lang = '';

    BcTabClientEtin: any = "";
    BcTabClientName: any = "";
    BcTabClientMobile: any = "";
    BcTabClientEmail: any = "";
    BcTabClientRef: any = "";
    BcTabgetData: any;
    BcTabTotalDues: any = "";
    BcTabTotalBill: any = "";
    BcTabTotalCollection: any = "";
    BcTabTotalRemission = 0;

    BcTabRemarksControl = new FormControl();
    BcTabDateControl = new FormControl(new Date());

    BcTabMainForm: FormGroup;
    TransactionDetailsList: any;

    maxDate = new Date();
    BcTabBillEnabled = false;


    constructor(
        public dialogRef: MatDialogRef<RemissionDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: ClientsListService,
        private _transactionControlService: TransactionService,

        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        dialogRef.disableClose = true;
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');
        this.BcTabSearchClient();
    }



    BcTabSearchClient(): void
    {
        this._spinner.show();

        // this.BcTabSearchControl.disable();


        let query = {
            "AssesseId": this.dialogdata.AssesseeId,
            "ReturnId": this.dialogdata.ReturnId,
            // "DateRange": `${moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY/MM/DD")}-${moment(new Date()).format("YYYY/MM/DD")}`,
            // "AssessmentYearId": this.dialogdata.year,
            "DateRange": null,
            "AssessmentYearId": null
        }

        // console.log(query, 'query');
        this._formControlService.SearchTransaction(query).subscribe(data =>
        {
            // console.log(data, 'SearchTransaction');

            if (data != null)
            {
                this.BcTabgetData = data.data;

                this.BcTabClientEtin = data.data.ETIN;
                this.BcTabClientName = data.data.AssesseName;
                this.BcTabClientMobile = data.data.ContactNo;
                this.BcTabClientEmail = data.data.Email;
                this.BcTabClientRef = data.data.Reference;

                // this.BcTabTotalDues = data.data.TotalDues;
                // this.BcTabTotalBill = data.data.TotalBill;
                // this.BcTabTotalCollection = data.data.TotalCollection;


                // if (data.data.PaymentTypeId != null)
                // {
                //     this.BcTabPayTypeControl.setValue(data.data.PaymentTypeId.toString());
                // }

                // this.BcTabRemarksControl.setValue(data.data.Remarks);
                if (data.data.TransactionDetailsList.length != 0)
                {
                    this.TransactionDetailsList = data.data.TransactionDetailsList;
                    let tempData = [];
                    data.data.TransactionDetailsList.forEach((item, index) =>
                    {
                        tempData.push(this.gotItem(item));
                    });
                    this.BcTabMainForm = this._formBuilder.group({
                        items: this._formBuilder.array(tempData),
                    });
                } else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 4000
                    });
                    this.dialogRef.close('refresh');
                }
                this._spinner.hide();

            } else
            {
                this._spinner.hide();

                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }


            this._spinner.hide();
        });

    }


    gotItem(item): FormGroup
    {
        return this._formBuilder.group({

            MasterId: item.MasterId,
            ReturnId: item.ReturnId,
            AssesseeId: item.AssesseeId,
            AssessmentYearId: item.AssessmentYearId,
            AccounHeadId: item.AccounHeadId,
            // AccounHeadtName: item.AccounHeadtName == 'TaxPay' ? 'Tax Pay' : item.AccounHeadtName,
            AccounHeadtName: Utils.transformAccounHeadtName(item.AccounHeadtName),
            Dues: item.Dues,
            // Bill: item.Bill,
            // Collection: item.Collection,
            Remission: 0,
            Notes: item.Notes
        })
    }

    BcTabMainFormitems(): FormArray
    {
        return this.BcTabMainForm.get("items") as FormArray
    }


    BcTabOnFocusoutEvent(event, key, i)
    {
        let rawForm = this.BcTabMainForm.getRawValue();

        let Bill = null;

        rawForm.items[i].Bill == '' ?
            Bill = 0 : Bill = rawForm.items[i].Bill;

        Bill = Number(Bill);

        let Collection = null;

        rawForm.items[i].Collection == '' ?
            Collection = 0 : Collection = rawForm.items[i].Collection;

        Collection = Number(Collection);

        let Remission = null;

        rawForm.items[i].Remission == '' ?
            Remission = 0 : Remission = rawForm.items[i].Remission;

        Remission = Number(Remission);


        // let Dues = null;

        // rawForm.items[i].Dues == '' ?
        //     Dues = 0 : Dues = rawForm.items[i].Dues;

        // Dues = Number(Dues);

        // console.log(this.BcTabgetData.TransactionDetailsList[i]);


        // if (key == "Bill" || key == "Collection")
        // {
        //     ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(Bill - Collection);



        // }

        if (key == "Bill")
        {

            let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            let duesCal = Dues + Bill;

            // ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(duesCal);

            // if (Bill == 0 || Bill == "")
            // {
            //     ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Bill').patchValue(this.BcTabgetData.TransactionDetailsList[i].Bill);
            // }
            this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);

        }

        if (key == "Collection")
        {
            let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            let duesCal = Dues - Collection;
            // ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(duesCal);
            this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);


        }


        if (key == "Remission")
        {

            let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            if (Remission > Dues)
            {

                ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Remission').patchValue(Number(Remission.toString().slice(0, -1)))

                this._matSnackBar.open(this.lang == 'en' ? "Discount amount can't be greater than Due amount" : "Discount amount can't be greater than Due amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            this.BcTabTotalRemission = this.BcTabMainForm.value.items.reduce((n, { Remission }) => n + Number(Remission), 0);


        }
        // this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0) - this.BcTabgetData.TotalRemission;

        // this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);
        // this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);
        // this.BcTabTotalDues = this.BcTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);


    }

    showBcTabBills(event)
    {
        if (event.checked == true)
        {
            this.BcTabBillEnabled = true;

            for (let index = 0; index < (this.BcTabMainForm.get('items') as FormArray).controls.length; index++)
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Bill').patchValue(0);
            }

            this.BcTabTotalBill = 0;


        }
        else
        {
            this.BcTabBillEnabled = false;

            this.BcTabgetData.TransactionDetailsList.forEach((item, index) =>
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Bill').patchValue(item.Bill);
            });

            this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);

            // this.BcTabTotalDues = this.BcTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);
        }


    }
    SaveRemission(): void
    {

        let tb = Number(this.BcTabTotalRemission) == 0;





        if (tb == true)
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please enter an amount" : "Please enter an amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {



            let flag = 'Remission';



            let child = this.BcTabMainForm.value.items.map(value =>
            ({
                "TransactionId": 0,
                "AccountHeadId": value.AccounHeadId,
                // "Dues": value.Dues,
                "Dues": 0,

                "Bill": 0,
                "Collection": 0,
                "Remission": value.Remission,
                "Notes": "",
            }));

            let query = {
                "Id": 0,
                "TransactionDate": moment(this.BcTabDateControl.value).format("YYYY-MM-DD"),
                "ReturnId": this.dialogdata.ReturnId,
                "AssesseeId": this.dialogdata.AssesseeId,
                "AssessmentYearId": this.dialogdata.year,
                "ReceiptNo": 0,
                "TotalDues": 0,
                "TotalBill": 0,
                "TotalCollection": 0,
                "TotalRemission": this.BcTabTotalRemission,
                "PaymentTypeId": null,
                "Remarks": this.BcTabRemarksControl.value,
                "TransactionFlag": flag,
                "PractitionerTransactionDetailsViewModels": child
            }
            // console.log(query, 'query');

            this._transactionControlService.SaveTransaction(query).subscribe(data =>
            {
                this._spinner.show();
                // console.log(data, 'data');
                if (data.isError == false)
                {
                    if (this.BcTabgetData.Id == 0)
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    } else
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }

                    this._spinner.hide();
                    this.dialogRef.close('refresh');
                }
                else
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
            });

        }


    }

    onNoClick(): void
    {
        this.dialogRef.close("no");
    }







}
