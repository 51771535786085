<div fxLayout="column">

    <h3 mat-subheader>SAVE TEMPLATE</h3>

    <div fxLayoutAlign="start"
        fxLayout="column">

        <div fxLayout="column"
            fxLayoutAlign="start"
            style="margin: 10px;">
            <span class="name"
                style="font-size: 15px;">Title:
            </span>
            <mat-form-field appearance="outline">
                <input class="leftA"
                    [formControl]="templateNameFormControl"
                    matInput>

            </mat-form-field>
        </div>

        <div mat-dialog-actions
            style="margin-top: 10px"
            fxLayoutAlign="end center">
            <button mat-button
                class="warn"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 10px; margin-right: 10px; border: 0; cursor: pointer;"
                matTooltip="close"
                (click)="onNoClick()">
                <!-- <mat-icon class="s-16 mr-sm-4">arrow_upward</mat-icon> -->
                <span>CLOSE</span>
            </button>

            <button mat-button
                class="dupl"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 10px; margin-left: 10px; border: 0; cursor: pointer;"
                (click)="SaveTemplate()">
                <span>Save</span>
            </button>

        </div>
    </div>

</div>
