<div class="page-layout simple right-sidebar"
    style="background-color: #ffffff;">

    <div class="center">

        <div fxLayoutAlign="space-between center"
            fxLayout="row wrap"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px;">{{ 'CLIENT.HEADER' | translate }}
            </span>
            <div fxLayout="row">
                <!-- <a [routerLink]="'/Clients/save'" -->
                <button (click)="AddClient()"
                    mat-raised-button
                    style="margin: 10px;"
                    class="mat-button dupl"
                    fxFlex="0 0 auto"
                    fxLayout="row"
                    fxLayoutAlign="start center">
                    <mat-icon class="s-16 mr-sm-4">person_add</mat-icon>
                    <span>{{ 'CLIENT.ADD' | translate }}</span>
                </button>
                <!-- </a> -->
                <!-- <a (click)="openBulkUploadDialog()"
                    mat-raised-button
                    style="margin: 10px;"
                    class="mat-button dupl"
                    fxFlex="0 0 auto"
                    fxLayout="row"
                    fxLayoutAlign="start center">
                    <mat-icon class="s-16 mr-sm-4">person_add</mat-icon>
                    <span>BULK UPLOAD</span>
                </a> -->

                <button mat-button
                    class="dupl user-button"
                    fxLayout="column wrap"
                    [matMenuTriggerFor]="BulkMenu"
                    matTooltip="Upload"
                    style="border: 0; cursor: pointer; padding: 10px !important; margin: 10px;">
                    <mat-icon class="s-16 mr-sm-4">person_add</mat-icon>
                    <span>BULK UPLOAD</span>
                </button>
                <mat-menu #BulkMenu="matMenu"
                    [overlapTrigger]="false">

                    <button mat-menu-item
                        (click)="openBulkUploadDialog()">
                        <span>Bulk Client Upload</span>
                    </button>

                    <!-- <button mat-menu-item
                        (click)="openBulkSalaryUploadDialog()">
                        <span>Bulk Salary Upload</span>
                    </button> -->

                </mat-menu>
            </div>
        </div>
        <div class="content p-24">
            <div *ngIf="hasClient==false"
                class="mb-24"
                fxLayout="column wrap"
                fxLayoutAlign="center"
                fxLayout.gt-md="row">
                <div fxLayoutAlign="center center">

                    <span class="h1"
                        style="padding: 10px;"
                        [ngStyle]="{'color': loading ? '#ffffff' : 'black'}">You do not have any clients listed.
                        Please add clients.
                    </span>
                </div>
            </div>

            <div class="mb-24"
                *ngIf="hasClient"
                fxLayout="column"
                fxLayoutAlign="start">

                <div fxLayout="column"
                    *ngIf="dashBoardData != null"
                    class="fuse-card auto-width mb-16 mt-16 p-12">
                    <div fxLafxLayout="row wrap"
                        fxLayoutAlign="space-between center">
                        <h3 style="margin: 10px;"
                            fxFlex="1 0 auto">
                            Current Dues: <b>{{dashBoardData.TotalDues==null?'0':dashBoardData.TotalDues}}</b>
                        </h3>
                        <h3 style="margin: 10px;"
                            fxFlex="1 0 auto">
                            Number Of Created Clients:
                            <b>{{dashBoardData.NumberOfClient==null?'0':dashBoardData.NumberOfClient}}</b>
                        </h3>
                        <h3 style="margin: 10px;"
                            fxFlex="1 0 auto">
                            Number Of Allowed Clients:
                            <b>{{dashBoardData.AllowedNumberOfClient==null?'0':dashBoardData.AllowedNumberOfClient}}</b>
                            <!-- Data r: <b>{{dashBoardData.NumberOfClient}}</b> -->
                        </h3>
                    </div>
                    <div fxLafxLayout="row wrap"
                        fxLayoutAlign="space-between center">

                        <h3 style="margin: 10px;"
                            fxFlex="1 0 auto">
                            Bill In This Month: <b>{{dashBoardData.TotalBill==null?'0':dashBoardData.TotalBill}}</b>
                        </h3>
                        <h3 style="margin: 10px;"
                            fxFlex="1 0 auto">
                            Collection In This Month:
                            <b>{{dashBoardData.TotalCollection==null?'0':dashBoardData.TotalCollection}}</b>
                        </h3>
                        <h3 style="margin: 10px;"
                            fxFlex="1 0 auto">
                            Remission In This Month:
                            <b>{{dashBoardData.TotalRemmission==null?'0':dashBoardData.TotalRemmission}}</b>
                        </h3>
                    </div>

                </div>
                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <input matInput
                        (keyup)="applyFilter($event)"
                        placeholder="Search" />
                    <button matSuffix
                        (click)="showSeachFilter()"
                        mat-icon-button>
                        <mat-icon>tune</mat-icon>
                    </button>
                </mat-form-field>
                <div style="overflow-x: scroll !important;"
                    fxLayout="column"
                    *ngIf="showFilter">
                    <div fxLayoutAlign="start center"
                        fxLayout="row wrap"
                        fxLayoutAlign="space-between start"
                        fxLayout.gt-md="row">
                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="25"
                            style="margin: 10px;">
                            <span class="name"
                                style="font-size: 15px; font-weight: bold;"
                                fxFlex="100">Assessee eTIN:
                            </span>

                            <mat-form-field appearance="outline">
                                <input type="text"
                                    [formControl]="ETINSearchControl"
                                    (click)="$event.target.select()"
                                    class="rightA"
                                    autocomplete="off"
                                    matInput>

                            </mat-form-field>
                        </div>
                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="25"
                            style="margin: 10px;">
                            <span class="name"
                                style="font-size: 15px; font-weight: bold;"
                                fxFlex="100">Assessee Name :
                            </span>

                            <mat-form-field appearance="outline">
                                <input type="text"
                                    matInput
                                    [formControl]="AssesseeNameSearchControl"
                                    [matAutocomplete]="AssesseeName">
                                <mat-autocomplete #AssesseeName="matAutocomplete">
                                    <mat-option *ngFor="let option of AssesseeNamefilteredOptions"
                                        (onSelectionChange)="AssesseeNameSearch(option)"
                                        [value]="option.AssesseName">
                                        {{option.AssesseName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="25"
                            style="margin: 10px;">
                            <span class="name"
                                style="font-size: 15px; font-weight: bold;"
                                fxFlex="100">Assessee Contact No:
                            </span>

                            <mat-form-field appearance="outline">
                                <input type="text"
                                    [formControl]="ContactNoSearchControl"
                                    (click)="$event.target.select()"
                                    class="rightA"
                                    maxlength="11"
                                    autocomplete="off"
                                    matInput>

                            </mat-form-field>
                        </div>
                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="25"
                            style="margin: 10px;">
                            <span class="name"
                                style="font-size: 15px; font-weight: bold;"
                                fxFlex="100">Assessee Email:
                            </span>

                            <mat-form-field appearance="outline">
                                <input type="text"
                                    [formControl]="EmailSearchControl"
                                    (click)="$event.target.select()"
                                    class="rightA"
                                    autocomplete="off"
                                    matInput>

                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayoutAlign="start center"
                        fxLayout="row wrap"
                        fxLayoutAlign="space-between start"
                        fxLayout.gt-md="row">
                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="25"
                            style="margin: 10px;">
                            <span class="name"
                                style="font-size: 15px; font-weight: bold;"
                                fxFlex="100">Is Due:
                            </span>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="IsDuesClientSearchControl">
                                    <mat-option [value]="1">
                                        Yes
                                    </mat-option>
                                    <mat-option [value]="2">
                                        No
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="25"
                            style="margin: 10px;">
                            <span class="name"
                                style="font-size: 15px; font-weight: bold;"
                                fxFlex="100">Is Profile Complete:
                            </span>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="IsCompleteSearchControl">
                                    <mat-option [value]="1">
                                        Yes
                                    </mat-option>
                                    <mat-option [value]="2">
                                        No
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="25"
                            style="margin: 10px;">
                            <span class="name"
                                style="font-size: 15px; font-weight: bold;"
                                fxFlex="100">Assessment Year:
                            </span>
                            <mat-form-field appearance="outline"
                                *ngIf="dropDownData!=null">
                                <mat-select [formControl]="AssessmentYearSearchControl">
                                    <mat-option *ngFor="let option of dropDownData.AssessmentYears"
                                        [value]="option.key">
                                        {{option.value}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                        </div>
                        <div fxLayout="row wrap"
                            fxLayoutAlign="center center"
                            fxFlex="25"
                            style="margin: 10px;">

                            <button mat-raised-button
                                (click)="SearchClient()"
                                style="margin: 10px;"
                                class="mat-button mat-button-base dupl px-16">
                                SEARCH
                            </button>

                            <button mat-raised-button
                                (click)="RefreshSearch()"
                                style="color: #444444 !important; margin: 10px;"
                                class="mat-button mat-button-base primary-100 secondary-text">
                                Refresh
                            </button>
                        </div>
                    </div>
                </div>

                <div style="overflow-x: scroll !important;">
                    <table mat-table
                        *ngIf="foundClient"
                        style="width: 100%"
                        [dataSource]="dataSource"
                        multiTemplateDataRows>

                        <ng-container matColumnDef="Actions">
                            <th class="thNewline"
                                style="color: #444444; font-size: 15px; min-width: 150px;"
                                mat-header-cell
                                *matHeaderCellDef>
                                Actions
                            </th>
                            <td mat-cell
                                *matCellDef="let element">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="start center">
                                    <button mat-icon-button
                                        aria-label="expand row"
                                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                                    </button>
                                    <a class="nav-link"
                                        style="color: #29793C; cursor: pointer;"
                                        fxLayout="column wrap"
                                        [routerLink]="'/Clients/save/' + element.AssesseeId"
                                        matTooltip="{{ 'COMMON.BUTTON_EDIT' | translate }}">
                                        <mat-icon class="nav-link-icon">create</mat-icon>
                                    </a>

                                    <a style="cursor: pointer; border: 0; color: #29793C;"
                                        fxLayout="column wrap"
                                        (click)="CheckDelete(element.AssesseeId,'client')"
                                        matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                        <mat-icon class="nav-link-icon">delete</mat-icon>
                                    </a>

                                    <a style="cursor: pointer; border: 0; color: #29793C;"
                                        fxLayout="column wrap"
                                        (click)="LaunchClient(element.ReturnId,element.AssesseeId,element.AssesseeName)"
                                        matTooltip="launch">
                                        <mat-icon class="nav-link-icon">launch</mat-icon>
                                    </a>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="DuesAmount">
                            <th class="thNewline"
                                style="color: #444444; font-size: 15px; min-width: 130px;"
                                mat-header-cell
                                *matHeaderCellDef>
                                Dues
                            </th>
                            <td mat-cell
                                style="text-align: right; padding-right: 20px;"
                                *matCellDef="let element"> {{element.DuesAmount}} </td>
                        </ng-container>

                        <ng-container matColumnDef="ETIN">
                            <th class="thNewline"
                                style="color: #444444; font-size: 15px; min-width: 150px;"
                                mat-header-cell
                                *matHeaderCellDef>
                                eTIN
                            </th>
                            <td mat-cell
                                style="padding-right: 20px;"
                                *matCellDef="let element"> {{element.ETIN}} </td>
                        </ng-container>

                        <ng-container matColumnDef="AssesseeName">
                            <th class="thNewline"
                                style="color: #444444; font-size: 15px; min-width: 150px;"
                                mat-header-cell
                                *matHeaderCellDef>
                                Name
                            </th>
                            <td mat-cell
                                style="padding-right: 20px;"
                                *matCellDef="let element"> {{element.AssesseeName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="ContactNo">
                            <th class="thNewline"
                                style="color: #444444; font-size: 15px; min-width: 150px; margin-right: 5px;"
                                mat-header-cell
                                *matHeaderCellDef>
                                Mobile No
                            </th>
                            <td mat-cell
                                style="padding-right: 20px;"
                                *matCellDef="let element"> {{element.ContactNo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <th class="thNewline"
                                style="color: #444444; font-size: 15px; min-width: 150px;  margin-right: 5px;"
                                mat-header-cell
                                *matHeaderCellDef>
                                Email
                            </th>
                            <td mat-cell
                                style="padding-right: 20px;"
                                *matCellDef="let element"> {{element.Email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Reference">
                            <th class="thNewline"
                                style="color: #444444; font-size: 15px; min-width: 150px;"
                                mat-header-cell
                                *matHeaderCellDef>
                                Reference
                            </th>
                            <td mat-cell
                                *matCellDef="let element"> {{element.Reference}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell
                                *matCellDef="let element"
                                style="background-color: mintcream"
                                [attr.colspan]="displayedColumns.length">
                                <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                                    style="overflow: hidden; display: flex;;"
                                    fxLayout="column wrap">

                                    <div style="margin-top: 20px;">
                                        <span style="font-size: 16px; font-weight: bold;">Return History</span>
                                    </div>
                                    <table class="simple invoice-table"
                                        *ngIf="element.ReturnHistory!=null"
                                        style="height: 100%;">

                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    {{ 'CLIENT.TABLE_HEADER_ACTIONS' | translate }}
                                                </th>

                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    Acknowledgement Slip
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    Accounting
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    Tax Year
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    Uploaded Date
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    Amount to be Paid
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    Paid With Return
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let data of element.ReturnHistory">

                                                <td>

                                                    <div fxLayout="row wrap"
                                                        fxLayoutAlign="start center">

                                                        <a style="cursor: pointer; border: 0; color: #29793C;"
                                                            fxLayout="column wrap"
                                                            [matMenuTriggerFor]="AcMenu"
                                                            matTooltip="Download">
                                                            <mat-icon class="nav-link-icon">assignment</mat-icon>
                                                        </a>
                                                        <mat-menu #AcMenu="matMenu"
                                                            [overlapTrigger]="false">

                                                            <button mat-menu-item
                                                                (click)="summaryPDF(data.AssesseeId,data.ReturnId)">
                                                                <span>Download Calculation summary</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                (click)="downloadReurnFile(data.AssesseeId,data.ReturnId)">
                                                                <span>Download Return file</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                (click)="openChalanDialog(data.AssesseeId,data.ReturnId)">
                                                                <span>Download Chalan</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                *ngIf="data.DocId!=null"
                                                                (click)="downloadSlip(data.AcknowledgementSlipName)">
                                                                <span>Download Slip</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                *ngIf="data.DocId!=null"
                                                                (click)="CheckDelete(data.DocId,'slip')">
                                                                <span>Delete Slip</span>
                                                            </button>

                                                        </mat-menu>

                                                        <a style="cursor: pointer; border: 0; color: #29793C;"
                                                            fxLayout="column wrap"
                                                            [matMenuTriggerFor]="SmsMenu"
                                                            matTooltip="Send Sms">
                                                            <mat-icon class="nav-link-icon">message</mat-icon>
                                                        </a>
                                                        <mat-menu #SmsMenu="matMenu"
                                                            [overlapTrigger]="false">
                                                            <button mat-menu-item
                                                                (click)="openGeneralSmsDialog(data.ReturnId, data.AssesseeId)">
                                                                <span>General SMS</span>
                                                            </button>
                                                            <button mat-menu-item
                                                                (click)="ConfirmSms(data.AssesseeId,data.ReturnId,'missing-docs')">
                                                                <span>Missing Document SMS</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                (click)="ConfirmSms(data.AssesseeId,data.ReturnId,'date-running-out')">
                                                                <span>Date Running Out SMS</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                (click)="ConfirmSms(data.AssesseeId,data.ReturnId,'dues-notice')">
                                                                <span>Dues Notice SMS</span>
                                                            </button>
                                                        </mat-menu>
                                                        <a style="cursor: pointer; border: 0; color: #29793C;"
                                                            fxLayout="column wrap"
                                                            (click)="openEmailDialog(data.AssesseeId,data.ReturnId,data.AcknowledgementSlipName)"
                                                            matTooltip="Send Files via Email">
                                                            <mat-icon class="nav-link-icon">email</mat-icon>
                                                        </a>
                                                        <a style="cursor: pointer; border: 0; color: #29793C;"
                                                            fxLayout="column wrap"
                                                            (click)="openClientDocumentDialog(data.AssesseeId)"
                                                            matTooltip="Download Client Documents">
                                                            <mat-icon class="nav-link-icon">insert_drive_file</mat-icon>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    <div *ngIf="data.AcknowledgementSlipName ==null || data.AcknowledgementSlipName ==''"
                                                        fxLayout="row wrap"
                                                        fxLayoutAlign="end center">

                                                        <button mat-button
                                                            class="dupl"
                                                            fxLayout="column wrap"
                                                            (click)="openSlipUploadDialog(data.ReturnId, data.AssesseeId)"
                                                            matTooltip="Upload slip"
                                                            style="border: 0; cursor: pointer; padding: 10px !important; ">
                                                            Upload Slip
                                                        </button>
                                                    </div>
                                                    <div class="text-right"
                                                        *ngIf="data.AcknowledgementSlipName !==null || data.AcknowledgementSlipName !==''"
                                                        fxLayout="row wrap"
                                                        fxLayoutAlign="end center">
                                                        {{data.AcknowledgementSlipName}}
                                                    </div>
                                                </td>

                                                <td class="text-right">
                                                    <div fxLayout="row wrap"
                                                        fxLayoutAlign="end center">

                                                        <button mat-button
                                                            class="dupl user-button"
                                                            fxLayout="column wrap"
                                                            [matMenuTriggerFor]="AccountMenu"
                                                            matTooltip="Options"
                                                            style="border: 0; cursor: pointer; padding: 10px !important; ">
                                                            Options
                                                        </button>
                                                        <mat-menu #AccountMenu="matMenu"
                                                            [overlapTrigger]="false">

                                                            <button mat-menu-item
                                                                (click)="openBillDialog(data.ReturnId, data.AssesseeId)">
                                                                <span>Generate Bill</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                (click)="openCollectionDialog(data.ReturnId, data.AssesseeId)">
                                                                <span>Collection</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                (click)="openRemissionDialog(data.ReturnId, data.AssesseeId)">
                                                                <span>Discount</span>
                                                            </button>

                                                            <button mat-menu-item
                                                                (click)="openLedgerDialog(data.ReturnId, data.AssesseeId, element.ETIN, element.AssesseeName,element.ContactNo, element.Reference)">
                                                                <span>Ledger</span>
                                                            </button>

                                                        </mat-menu>
                                                    </div>

                                                </td>

                                                <td class="text-right">
                                                    {{data.TaxYear}}
                                                </td>
                                                <td class="text-right">

                                                    <div
                                                        *ngIf="moment(element.UploadedDate).format('DD-MM-YYYY') == '01-01-1900'">
                                                        --</div>
                                                    <div
                                                        *ngIf="moment(element.UploadedDate).format('DD-MM-YYYY') !== '01-01-1900'">
                                                        {{moment(data.UploadedDate).format('DD-MM-YYYY')}}</div>
                                                </td>
                                                <td class="text-right">
                                                    {{data.AmountToBePaid}}
                                                </td>
                                                <td class="text-right">
                                                    {{data.PaidWithReturn}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row
                            *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row
                            *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-row
                            *matRowDef="let row; columns: ['expandedDetail']"
                            style="height: 0;"></tr>
                    </table>

                    <div *ngIf="!foundClient"
                        class="mb-24"
                        fxLayout="column wrap"
                        fxLayoutAlign="center"
                        fxLayout.gt-md="row">
                        <div fxLayoutAlign="center center">

                            <span class="h1"
                                style="padding: 10px;"
                                [ngStyle]="{'color': loading ? '#ffffff' : 'black'}">No client found matching
                                search
                                criteria.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
