import { TaxConfig } from '../../../util/tax.config';

export class SalaryClass
{
  constructor(
    private _BasicPayAmount: number,
    private _BasicPayExempted: number,
    private _BasicPayTaxable: number,

    private _SpecialPayAmount: number,
    private _SpecialPayExempted: number,
    private _SpecialPayTaxable: number,

    private _DearnessAllowanceAmount: number,
    private _DearnessAllowanceExempted: number,
    private _DearnessAllowanceTaxable: number,

    private _ConveyanceAllowanceAmount: number,
    private _ConveyanceAllowanceExempted: number,
    private _ConveyanceAllowanceTaxable: number,

    private _HouseRentAllowanceAmount: number,
    private _HouseRentAllowanceExempted: number,
    private _HouseRentAllowanceTaxable: number,

    private _MedicalAllowanceAmount: number,
    private _MedicalAllowanceExempted: number,
    private _MedicalAllowanceTaxable: number,
    private _IsDisabledPerson: boolean,

    private _ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount: number,
    private _ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted: number,
    private _ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable: number,
    private _IsShareHolder: boolean,

    private _LeaveFairAssistanceStaffAmount: number,
    private _LeaveFairAssistanceStaffExempted: number,
    private _LeaveFairAssistanceStaffTaxable: number,
    private _IsMentionedInEmploymentContract: boolean,

    private _LeaveEncashmentAmount: number,
    private _LeaveEncashmentExempted: number,
    private _LeaveEncashmentTaxable: number,

    private _AllowanceForSupportStaffAmount: number,
    private _AllowanceForSupportStaffExempted: number,
    private _AllowanceForSupportStaffTaxable: number,

    private _HonorariumOrRewardAmount: number,
    private _HonorariumOrRewardExempted: number,
    private _HonorariumOrRewardTaxable: number,

    private _OvertimeAllowanceAmount: number,
    private _OvertimeAllowanceExempted: number,
    private _OvertimeAllowanceTaxable: number,

    private _BonusAmount: number,
    private _BonusExempted: number,
    private _BonusTaxable: number,

    private _OtherAllowancesAmount: number,
    private _OtherAllowancesExempted: number,
    private _OtherAllowancesTaxable: number,

    private _EmployersContributionProvidentFundAmount: number,
    private _EmployersContributionProvidentFundExempted: number,
    private _EmployersContributionProvidentFundTaxable: number,

    private _RateOfInterestOfProvidentFund: number,
    private _InterestAccruedProvidentFundRateOfInterestAmount: number,
    private _InterestAccruedProvidentFundRateOfInterestExempted: number,
    private _InterestAccruedProvidentFundRateOfInterestTaxable: number,

    private _ReceivedTransportFromEmployerTaxable: number,
    private _IsReceivedAnyTransportFromEmployer: boolean,

    private _IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer: boolean,
    private _EmployerPaidRentalAmount: number,
    private _EmployeePaidRentalAmount: number,
    private _DeemedFreeAccommodationExempted: number,

    private _OthersAmount: number,
    private _OthersExempted: number,
    private _OthersTaxable: number,

    private _ArrearPayAmount: number,
    private _ArrearPayExempted: number,
    private _ArrearPayTaxable: number,

    private _ReceivedFromGratuityFundAmount: number,
    private _ReceivedFromGratuityFundExempted: number,
    private _ReceivedFromGratuityFundTaxable: number,

    private _WorkersProfitParticipationFundAmount: number,
    private _WorkersProfitParticipationFundExempted: number,
    private _WorkersProfitParticipationFundTaxable: number,

    private _PensionAmount: number,
    private _PensionExempted: number,
    private _PensionTaxable: number,

    private _ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount: number,
    private _ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted: number,
    private _ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable: number,

    private _TotalAmount: number,
    private _TotalExempted: number,
    private _NetTaxable: number,

    private _GrossAmount: number,
    private _GrossExempted: number,
    private _GrossTaxable: number,
  )
  {
    this.taxConfig = new TaxConfig();
    this.taxConfig.MaxExemptedGratuityFund = 25000000;
    this.taxConfig.MaxExemptedConveyance = 30000;
    this.taxConfig.MaxExemptedHouseRent = 300000;
    this.taxConfig.MaxExemptedMedicalAllowanceForDisabledPerson = 1000000;
    this.taxConfig.MaxExemptedMedicalAllowance = 120000;
    this.taxConfig.MaxExemptedWorkersProfitParticipationFund = 50000;

  }
  taxConfig: TaxConfig;


  public get BasicPayAmount(): number
  {
    return this._BasicPayAmount;
  }
  public set BasicPayAmount(x: number)
  {
    this._BasicPayAmount = x;
  }

  public get BasicPayExempted(): number
  {
    return this._BasicPayExempted = 0.00;
  }
  public set BasicPayExempted(x: number)
  {
    this._BasicPayExempted = x;
  }

  public get BasicPayTaxable(): number
  {
    return this._BasicPayTaxable = this.BasicPayAmount;
  }
  public set BasicPayTaxable(x: number)
  {
    this._BasicPayTaxable = x;
  }

  public get DearnessAllowanceAmount(): number
  {
    return this._DearnessAllowanceAmount;
  }
  public set DearnessAllowanceAmount(x: number)
  {
    this._DearnessAllowanceAmount = x;
  }

  public get DearnessAllowanceExempted(): number
  {
    return this._DearnessAllowanceExempted = 0.00;
  }
  public set DearnessAllowanceExempted(x: number)
  {
    this._DearnessAllowanceExempted = x;
  }

  public get DearnessAllowanceTaxable(): number
  {
    return this._DearnessAllowanceTaxable = this.DearnessAllowanceAmount;
  }
  public set DearnessAllowanceTaxable(x: number)
  {
    this._DearnessAllowanceTaxable = x;
  }

  public get SpecialPayAmount(): number
  {
    return this._SpecialPayAmount;
  }
  public set SpecialPayAmount(x: number)
  {
    this._SpecialPayAmount = x;
  }

  public get SpecialPayExempted(): number
  {
    return this._SpecialPayExempted = 0.00;
  }
  public set SpecialPayExempted(x: number)
  {
    this._SpecialPayExempted = x;
  }

  public get SpecialPayTaxable(): number
  {
    return this._SpecialPayTaxable = this.SpecialPayAmount;
  }
  public set SpecialPayTaxable(x: number)
  {
    this._SpecialPayTaxable = x;
  }



  public get HouseRentAllowanceAmount(): number
  {
    return this._HouseRentAllowanceAmount;
  }
  public set HouseRentAllowanceAmount(x: number)
  {
    this._HouseRentAllowanceAmount = x;
  }

  // public get HouseRentAllowanceExempted(): number
  // {
  //   let HouseRentMIN_Exempted = Math.min(Number(this.BasicPayAmount) * 0.5, Number(this.taxConfig.MaxExemptedHouseRent));

  //   if (this.HouseRentAllowanceAmount <= HouseRentMIN_Exempted)
  //   {
  //     return this._HouseRentAllowanceExempted = this.HouseRentAllowanceAmount;
  //   }
  //   else
  //   {
  //     return this._HouseRentAllowanceExempted = HouseRentMIN_Exempted;
  //   }

  // }
  // public set HouseRentAllowanceExempted(x: number)
  // {
  //   this._HouseRentAllowanceExempted = x;
  // }

  // public get HouseRentAllowanceTaxable(): number
  // {

  //   return this._HouseRentAllowanceTaxable = Number(this.HouseRentAllowanceAmount) <= Number(this.HouseRentAllowanceExempted) ? 0 : Number(this.HouseRentAllowanceAmount) - Number(this.HouseRentAllowanceExempted);
  // }
  // public set HouseRentAllowanceTaxable(x: number)
  // {
  //   this._HouseRentAllowanceTaxable = x;
  // }

  public get HouseRentAllowanceExempted(): number
  {
    return this._HouseRentAllowanceExempted = 0.00;
  }
  public set HouseRentAllowanceExempted(x: number)
  {
    this._HouseRentAllowanceExempted = x;
  }

  public get HouseRentAllowanceTaxable(): number
  {
    return this._HouseRentAllowanceTaxable = this.HouseRentAllowanceAmount;
  }
  public set HouseRentAllowanceTaxable(x: number)
  {
    this._HouseRentAllowanceTaxable = x;
  }


  public get ConveyanceAllowanceAmount(): number
  {
    return this._ConveyanceAllowanceAmount;
  }
  public set ConveyanceAllowanceAmount(x: number)
  {
    this._ConveyanceAllowanceAmount = x;
  }

  // public get ConveyanceAllowanceExempted(): number
  // {
  //   let cAE = this.ConveyanceAllowanceAmount > this.taxConfig.MaxExemptedConveyance
  //     ? this.taxConfig.MaxExemptedConveyance : this.ConveyanceAllowanceAmount;

  //   if (this.IsReceivedAnyTransportFromEmployer)
  //   {
  //     cAE = 0.00
  //   }
  //   return this._ConveyanceAllowanceExempted = cAE;


  // }
  // public set ConveyanceAllowanceExempted(x: number)
  // {
  //   if (this.IsReceivedAnyTransportFromEmployer)
  //   {
  //     x = 0.00
  //   }
  //   this._ConveyanceAllowanceExempted = x;
  // }

  // public get ConveyanceAllowanceTaxable(): number
  // {
  //   let cAT = Number(this.ConveyanceAllowanceAmount) > Number(this.taxConfig.MaxExemptedConveyance)
  //     ? Number(this.ConveyanceAllowanceAmount) - Number(this.taxConfig.MaxExemptedConveyance)
  //     : 0;

  //   if (this.IsReceivedAnyTransportFromEmployer)
  //   {
  //     cAT = this.ConveyanceAllowanceAmount;
  //   }

  //   return this._ConveyanceAllowanceTaxable = cAT;
  // }
  // public set ConveyanceAllowanceTaxable(x: number)
  // {
  //   this._ConveyanceAllowanceTaxable = x;
  // }

  public get ConveyanceAllowanceExempted(): number
  {
    return this._ConveyanceAllowanceExempted = 0.00;
  }
  public set ConveyanceAllowanceExempted(x: number)
  {
    this._ConveyanceAllowanceExempted = x;
  }

  public get ConveyanceAllowanceTaxable(): number
  {
    return this._ConveyanceAllowanceTaxable = this.ConveyanceAllowanceAmount;
  }
  public set ConveyanceAllowanceTaxable(x: number)
  {
    this._ConveyanceAllowanceTaxable = x;
  }

  public get IsReceivedAnyTransportFromEmployer(): boolean
  {
    return this._IsReceivedAnyTransportFromEmployer;
  }
  public set IsReceivedAnyTransportFromEmployer(x: boolean)
  {
    this._IsReceivedAnyTransportFromEmployer = x;
  }

  public get ReceivedTransportFromEmployerTaxable(): number
  {
    let cAT = this.IsReceivedAnyTransportFromEmployer ? Math.max(Number(this.BasicPayAmount) * 0.05, 60000) : 0;
    return this._ReceivedTransportFromEmployerTaxable = cAT;
  }
  public set ReceivedTransportFromEmployerTaxable(x: number)
  {
    this._ReceivedTransportFromEmployerTaxable = x;
  }

  public get IsDisabledPerson(): boolean
  {
    return this._IsDisabledPerson;
  }
  public set IsDisabledPerson(x: boolean)
  {
    this._IsDisabledPerson = x;
  }



  public get MedicalAllowanceAmount(): number
  {
    return this._MedicalAllowanceAmount;
  }
  public set MedicalAllowanceAmount(x: number)
  {
    this._MedicalAllowanceAmount = x;
  }

  // public get MedicalAllowanceExempted(): number
  // {
  //   let MedicalAllowanceMIN_Exempted = this.IsDisabledPerson
  //     ? Number(this.taxConfig.MaxExemptedMedicalAllowanceForDisabledPerson)
  //     : Math.min(Number(this.BasicPayAmount) * 0.1, Number(this.taxConfig.MaxExemptedMedicalAllowance));
  //   let mAE = 0;

  //   if (this.MedicalAllowanceAmount <= MedicalAllowanceMIN_Exempted)
  //   {
  //     mAE = this.MedicalAllowanceAmount
  //   }
  //   else
  //   {
  //     mAE = MedicalAllowanceMIN_Exempted
  //   }
  //   return this._MedicalAllowanceExempted = mAE;
  // }
  // public set MedicalAllowanceExempted(x: number)
  // {
  //   this._MedicalAllowanceExempted = x;
  // }

  // public get MedicalAllowanceTaxable(): number
  // {
  //   let mAT = Number(this.MedicalAllowanceAmount) - Number(this.MedicalAllowanceExempted);
  //   return this._MedicalAllowanceTaxable = mAT;
  // }
  // public set MedicalAllowanceTaxable(x: number)
  // {
  //   this._MedicalAllowanceTaxable = x;
  // }

  public get MedicalAllowanceExempted(): number
  {
    return this._MedicalAllowanceExempted = 0.00;
  }
  public set MedicalAllowanceExempted(x: number)
  {
    this._MedicalAllowanceExempted = x;
  }

  public get MedicalAllowanceTaxable(): number
  {
    return this._MedicalAllowanceTaxable = this.MedicalAllowanceAmount;
  }
  public set MedicalAllowanceTaxable(x: number)
  {
    this._MedicalAllowanceTaxable = x;
  }

  public get RateOfInterestOfProvidentFund(): number
  {
    return this._RateOfInterestOfProvidentFund;
  }
  public set RateOfInterestOfProvidentFund(x: number)
  {
    this._RateOfInterestOfProvidentFund = x;
  }

  public get InterestAccruedProvidentFundRateOfInterestAmount(): number
  {
    return this._InterestAccruedProvidentFundRateOfInterestAmount;
  }
  public set InterestAccruedProvidentFundRateOfInterestAmount(x: number)
  {
    this._InterestAccruedProvidentFundRateOfInterestAmount = x;
  }

  public get InterestAccruedProvidentFundRateOfInterestExempted(): number
  {
    let fOI = (1 / 3) * (Number(this.BasicPayAmount) + Number(this.DearnessAllowanceAmount))

    let iAP = 0.00;

    if (Number(this.RateOfInterestOfProvidentFund) != 0)
    {
      if (Number(this.RateOfInterestOfProvidentFund) <= 14.5)
      {
        iAP = Math.min(fOI, Number(this.InterestAccruedProvidentFundRateOfInterestAmount));
      }
      else
      {
        let aPF = Number(this.InterestAccruedProvidentFundRateOfInterestAmount) / (Number(this.RateOfInterestOfProvidentFund) / 100);

        iAP = Math.min(fOI, (aPF * 0.145));
      }
    }
    else
    {
      iAP = 0.00;
    }

    return this._InterestAccruedProvidentFundRateOfInterestExempted = iAP;
  }
  public set InterestAccruedProvidentFundRateOfInterestExempted(x: number)
  {
    this._InterestAccruedProvidentFundRateOfInterestExempted = x;
  }

  public get InterestAccruedProvidentFundRateOfInterestTaxable(): number
  {
    let iAT = 0.00;
    if (Number(this.RateOfInterestOfProvidentFund) != 0)
    {
      iAT = Number(this.InterestAccruedProvidentFundRateOfInterestAmount) - Number(this.InterestAccruedProvidentFundRateOfInterestExempted);
    }
    else
    {
      iAT = 0.00;
    }
    return this._InterestAccruedProvidentFundRateOfInterestTaxable = iAT;
  }
  public set InterestAccruedProvidentFundRateOfInterestTaxable(x: number)
  {
    this._InterestAccruedProvidentFundRateOfInterestTaxable = x;
  }

  public get IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer(): boolean
  {
    return this._IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer;
  }
  public set IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer(x: boolean)
  {
    this._IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer = x;
  }

  public get EmployerPaidRentalAmount(): number
  {
    let mID = Number(this.BasicPayAmount) * 0.25;

    let dFAE = this.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer
      ? mID : 0;
    return this._EmployerPaidRentalAmount = dFAE;
  }
  public set EmployerPaidRentalAmount(x: number)
  {
    this._EmployerPaidRentalAmount = x;
  }

  public get EmployeePaidRentalAmount(): number
  {
    return this._EmployeePaidRentalAmount;
  }
  public set EmployeePaidRentalAmount(x: number)
  {
    this._EmployeePaidRentalAmount = x;
  }

  public get DeemedFreeAccommodationExempted(): number
  {

    let mID = Number(this.BasicPayAmount) * 0.25;

    let dFAE = this.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer
      ? mID - Number(this.EmployeePaidRentalAmount) : 0;

    if (dFAE < 0)
    {
      dFAE = 0;
    }
    return this._DeemedFreeAccommodationExempted = dFAE;
  }
  public set DeemedFreeAccommodationExempted(x: number)
  {
    this._DeemedFreeAccommodationExempted = x;
  }

  public get LeaveEncashmentAmount(): number
  {
    return this._LeaveEncashmentAmount;
  }
  public set LeaveEncashmentAmount(x: number)
  {
    this._LeaveEncashmentAmount = x;
  }

  public get LeaveEncashmentExempted(): number
  {
    return this._LeaveEncashmentExempted = 0.00;
  }
  public set LeaveEncashmentExempted(x: number)
  {
    this._LeaveEncashmentExempted = x;
  }

  public get LeaveEncashmentTaxable(): number
  {
    return this._LeaveEncashmentTaxable = this.LeaveEncashmentAmount;
  }
  public set LeaveEncashmentTaxable(x: number)
  {
    this._LeaveEncashmentTaxable = x;
  }

  public get OthersAmount(): number
  {
    return this._OthersAmount;
  }
  public set OthersAmount(x: number)
  {
    this._OthersAmount = x;
  }

  public get OthersExempted(): number
  {
    return this._OthersExempted = 0.00;
  }
  public set OthersExempted(x: number)
  {
    this._OthersExempted = x;
  }

  public get OthersTaxable(): number
  {
    return this._OthersTaxable = this.OthersAmount;
  }
  public set OthersTaxable(x: number)
  {
    this._OthersTaxable = x;
  }

  public get ArrearPayAmount(): number
  {
    return this._ArrearPayAmount;
  }
  public set ArrearPayAmount(x: number)
  {
    this._ArrearPayExempted = x;
  }

  public get ArrearPayExempted(): number
  {
    return this._ArrearPayExempted = 0.00;
  }
  public set ArrearPayExempted(x: number)
  {
    this._ArrearPayExempted = x;
  }

  public get ArrearPayTaxable(): number
  {
    return this._ArrearPayTaxable = this.ArrearPayAmount;
  }
  public set ArrearPayTaxable(x: number)
  {
    this._ArrearPayTaxable = x;
  }

  public get AllowanceForSupportStaffAmount(): number
  {
    return this._AllowanceForSupportStaffAmount;
  }
  public set AllowanceForSupportStaffAmount(x: number)
  {
    this._AllowanceForSupportStaffAmount = x;
  }

  public get AllowanceForSupportStaffExempted(): number
  {
    return this._AllowanceForSupportStaffExempted = 0.00;
  }
  public set AllowanceForSupportStaffExempted(x: number)
  {
    this._AllowanceForSupportStaffExempted = x;
  }

  public get AllowanceForSupportStaffTaxable(): number
  {
    return this._AllowanceForSupportStaffTaxable = this.AllowanceForSupportStaffAmount;
  }
  public set AllowanceForSupportStaffTaxable(x: number)
  {
    this._AllowanceForSupportStaffTaxable = x;
  }

  public get HonorariumOrRewardAmount(): number
  {
    return this._HonorariumOrRewardAmount;
  }
  public set HonorariumOrRewardAmount(x: number)
  {
    this._HonorariumOrRewardAmount = x;
  }

  public get HonorariumOrRewardExempted(): number
  {
    return this._HonorariumOrRewardExempted = 0.00;
  }
  public set HonorariumOrRewardExempted(x: number)
  {
    this._HonorariumOrRewardExempted = x;
  }

  public get HonorariumOrRewardTaxable(): number
  {
    return this._HonorariumOrRewardTaxable = this.HonorariumOrRewardAmount;
  }
  public set HonorariumOrRewardTaxable(x: number)
  {
    this._HonorariumOrRewardTaxable = x;
  }

  public get OvertimeAllowanceAmount(): number
  {
    return this._OvertimeAllowanceAmount;
  }
  public set OvertimeAllowanceAmount(x: number)
  {
    this._OvertimeAllowanceAmount = x;
  }

  public get OvertimeAllowanceExempted(): number
  {
    return this._OvertimeAllowanceExempted = 0.00;
  }
  public set OvertimeAllowanceExempted(x: number)
  {
    this._OvertimeAllowanceExempted = x;
  }

  public get OvertimeAllowanceTaxable(): number
  {
    return this._OvertimeAllowanceTaxable = this.OvertimeAllowanceAmount;
  }
  public set OvertimeAllowanceTaxable(x: number)
  {
    this._OvertimeAllowanceTaxable = x;
  }

  public get BonusAmount(): number
  {
    return this._BonusAmount;
  }
  public set BonusAmount(x: number)
  {
    this._BonusAmount = x;
  }

  public get BonusExempted(): number
  {
    return this._BonusExempted = 0.00;
  }
  public set BonusExempted(x: number)
  {
    this._BonusExempted = x;
  }

  public get BonusTaxable(): number
  {
    return this._BonusTaxable = this.BonusAmount;
  }
  public set BonusTaxable(x: number)
  {
    this._BonusTaxable = x;
  }

  public get OtherAllowancesAmount(): number
  {
    return this._OtherAllowancesAmount;
  }
  public set OtherAllowancesAmount(x: number)
  {
    this._OtherAllowancesAmount = x;
  }

  public get OtherAllowancesExempted(): number
  {
    return this._OtherAllowancesExempted = 0.00;
  }
  public set OtherAllowancesExempted(x: number)
  {
    this._OtherAllowancesExempted = x;
  }

  public get OtherAllowancesTaxable(): number
  {
    return this._OtherAllowancesTaxable = this.OtherAllowancesAmount;
  }
  public set OtherAllowancesTaxable(x: number)
  {
    this._OtherAllowancesTaxable = x;
  }

  public get EmployersContributionProvidentFundAmount(): number
  {
    return this._EmployersContributionProvidentFundAmount;
  }
  public set EmployersContributionProvidentFundAmount(x: number)
  {
    this._EmployersContributionProvidentFundAmount = x;
  }

  public get EmployersContributionProvidentFundExempted(): number
  {
    return this._EmployersContributionProvidentFundExempted = 0.00;
  }
  public set EmployersContributionProvidentFundExempted(x: number)
  {
    this._EmployersContributionProvidentFundExempted = x;
  }

  public get EmployersContributionProvidentFundTaxable(): number
  {
    return this._EmployersContributionProvidentFundTaxable = this.EmployersContributionProvidentFundAmount;
  }
  public set EmployersContributionProvidentFundTaxable(x: number)
  {
    this._EmployersContributionProvidentFundTaxable = x;
  }



  public get ReceivedFromGratuityFundAmount(): number
  {
    return this._ReceivedFromGratuityFundAmount;
  }
  public set ReceivedFromGratuityFundAmount(x: number)
  {
    this._ReceivedFromGratuityFundAmount = x;
  }

  // public get ReceivedFromGratuityFundExempted(): number
  // {
  //   let rGE = this.ReceivedFromGratuityFundAmount < this.taxConfig.MaxExemptedGratuityFund ? this.ReceivedFromGratuityFundAmount : this.taxConfig.MaxExemptedGratuityFund;
  //   return this._ReceivedFromGratuityFundExempted = rGE;
  // }
  // public set ReceivedFromGratuityFundExempted(x: number)
  // {
  //   this._ReceivedFromGratuityFundExempted = x;
  // }

  // public get ReceivedFromGratuityFundTaxable(): number
  // {
  //   let rGA = Number(this.ReceivedFromGratuityFundAmount) - Number(this.ReceivedFromGratuityFundExempted);
  //   return this._ReceivedFromGratuityFundTaxable = rGA;
  // }
  // public set ReceivedFromGratuityFundTaxable(x: number)
  // {
  //   this._ReceivedFromGratuityFundTaxable = x;
  // }

  public get ReceivedFromGratuityFundExempted(): number
  {
    return this._ReceivedFromGratuityFundExempted = 0.00;
  }
  public set ReceivedFromGratuityFundExempted(x: number)
  {
    this._ReceivedFromGratuityFundExempted = x;
  }

  public get ReceivedFromGratuityFundTaxable(): number
  {
    return this._ReceivedFromGratuityFundTaxable = this.ReceivedFromGratuityFundAmount;
  }
  public set ReceivedFromGratuityFundTaxable(x: number)
  {
    this._ReceivedFromGratuityFundTaxable = x;
  }

  public get IsShareHolder(): boolean
  {
    return this._IsShareHolder;
  }
  public set IsShareHolder(x: boolean)
  {
    this._IsShareHolder = x;
  }

  public get ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount(): number
  {
    return this._ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount;
  }
  public set ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount(x: number)
  {
    this._ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = x;
  }

  public get ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted(): number
  {
    let rSHL = this.IsShareHolder ? 0 : this.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount;
    return this._ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted = rSHL;
  }
  public set ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted(x: number)
  {
    this._ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted = x;
  }

  public get ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable(): number
  {
    let rSHL = this.IsShareHolder ? this.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount : 0;
    return this._ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable = rSHL;
  }
  public set ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable(x: number)
  {
    this._ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable = x;
  }

  public get IsMentionedInEmploymentContract(): boolean
  {
    return this._IsMentionedInEmploymentContract;
  }
  public set IsMentionedInEmploymentContract(x: boolean)
  {
    this._IsMentionedInEmploymentContract = x;
  }

  public get LeaveFairAssistanceStaffAmount(): number
  {
    return this._LeaveFairAssistanceStaffAmount;
  }
  public set LeaveFairAssistanceStaffAmount(x: number)
  {
    this._LeaveFairAssistanceStaffAmount = x;
  }

  public get LeaveFairAssistanceStaffExempted(): number
  {
    let rSHL = this.IsMentionedInEmploymentContract ? this.LeaveFairAssistanceStaffAmount : 0;
    return this._LeaveFairAssistanceStaffExempted = rSHL;
  }
  public set LeaveFairAssistanceStaffExempted(x: number)
  {
    this._LeaveFairAssistanceStaffExempted = x;
  }

  public get LeaveFairAssistanceStaffTaxable(): number
  {
    let rSHL = this.IsMentionedInEmploymentContract ? 0 : this.LeaveFairAssistanceStaffAmount;
    return this._LeaveFairAssistanceStaffTaxable = rSHL;
  }
  public set LeaveFairAssistanceStaffTaxable(x: number)
  {
    this._LeaveFairAssistanceStaffTaxable = x;
  }



  public get WorkersProfitParticipationFundAmount(): number
  {
    return this._WorkersProfitParticipationFundAmount;
  }
  public set WorkersProfitParticipationFundAmount(x: number)
  {
    this._WorkersProfitParticipationFundAmount = x;
  }

  // public get WorkersProfitParticipationFundExempted(): number
  // {
  //   let rSHL = this.WorkersProfitParticipationFundAmount < this.taxConfig.MaxExemptedWorkersProfitParticipationFund ? this.WorkersProfitParticipationFundAmount : this.taxConfig.MaxExemptedWorkersProfitParticipationFund;
  //   return this._WorkersProfitParticipationFundExempted = rSHL;
  // }
  // public set WorkersProfitParticipationFundExempted(x: number)
  // {
  //   this._WorkersProfitParticipationFundExempted = x;
  // }

  // public get WorkersProfitParticipationFundTaxable(): number
  // {
  //   let rSHL = Number(this.WorkersProfitParticipationFundAmount) - Number(this.WorkersProfitParticipationFundExempted);
  //   return this._WorkersProfitParticipationFundTaxable = rSHL;
  // }
  // public set WorkersProfitParticipationFundTaxable(x: number)
  // {
  //   this._WorkersProfitParticipationFundTaxable = x;
  // }

  public get WorkersProfitParticipationFundExempted(): number
  {
    return this._WorkersProfitParticipationFundExempted = 0.00;
  }
  public set WorkersProfitParticipationFundExempted(x: number)
  {
    this._WorkersProfitParticipationFundExempted = x;
  }

  public get WorkersProfitParticipationFundTaxable(): number
  {
    return this._WorkersProfitParticipationFundTaxable = this.WorkersProfitParticipationFundAmount;
  }
  public set WorkersProfitParticipationFundTaxable(x: number)
  {
    this._WorkersProfitParticipationFundTaxable = x;
  }

  public get PensionAmount(): number
  {
    return this._PensionAmount;
  }
  public set PensionAmount(x: number)
  {
    this._PensionAmount = x;
  }

  public get PensionExempted(): number
  {
    return this._PensionExempted = this.PensionAmount;
  }
  public set PensionExempted(x: number)
  {
    this._PensionExempted = x;
  }

  public get PensionTaxable(): number
  {
    return this._PensionTaxable = 0.00;
  }
  public set PensionTaxable(x: number)
  {
    this._PensionTaxable = x;
  }

  public get ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted(): number
  {
    return this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted = this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount;
  }
  public set ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted(x: number)
  {
    this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted = x;
  }

  public get ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount(): number
  {
    return this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount;
  }
  public set ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount(x: number)
  {
    this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = x;
  }

  public get ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable(): number
  {
    return this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable = 0.00;
  }
  public set ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable(x: number)
  {
    this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable = x;
  }

  public get TotalAmount(): number
  {
    let ta = Number(this.BasicPayAmount)
      + Number(this.SpecialPayAmount)
      + Number(this.DearnessAllowanceAmount)
      + Number(this.ConveyanceAllowanceAmount)
      + Number(this.HouseRentAllowanceAmount)
      + Number(this.MedicalAllowanceAmount)
      + Number(this.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount)
      + Number(this.AllowanceForSupportStaffAmount)
      + Number(this.LeaveFairAssistanceStaffAmount)
      + Number(this.LeaveEncashmentAmount)
      + Number(this.HonorariumOrRewardAmount)
      + Number(this.OvertimeAllowanceAmount)
      + Number(this.BonusAmount)
      + Number(this.OtherAllowancesAmount)
      + Number(this.EmployersContributionProvidentFundAmount)
      + Number(this.InterestAccruedProvidentFundRateOfInterestAmount)
      + Number(this.OthersAmount)
      + Number(this.ArrearPayAmount)
      + Number(this.ReceivedFromGratuityFundAmount)
      + Number(this.WorkersProfitParticipationFundAmount)
      + Number(this.PensionAmount)
      + Number(this.DeemedFreeAccommodationExempted)
      + Number(this.ReceivedTransportFromEmployerTaxable)
      + Number(this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount);


    return this._TotalAmount = ta;
  }
  public set TotalAmount(x: number)
  {
    this._TotalAmount = x;
  }

  public get TotalExempted(): number
  {
    // let te = Number(this.BasicPayExempted)
    //   + Number(this.SpecialPayExempted)
    //   + Number(this.DearnessAllowanceExempted)
    //   + Number(this.ConveyanceAllowanceExempted)
    //   + Number(this.HouseRentAllowanceExempted)
    //   + Number(this.MedicalAllowanceExempted)
    //   + Number(this.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted)
    //   + Number(this.AllowanceForSupportStaffExempted)
    //   + Number(this.LeaveFairAssistanceStaffExempted)
    //   + Number(this.LeaveEncashmentExempted)
    //   + Number(this.HonorariumOrRewardExempted)
    //   + Number(this.OvertimeAllowanceExempted)
    //   + Number(this.BonusExempted)
    //   + Number(this.OtherAllowancesExempted)
    //   + Number(this.EmployersContributionProvidentFundExempted)
    //   + Number(this.InterestAccruedProvidentFundRateOfInterestExempted)
    //   + Number(this.OthersExempted)
    //   + Number(this.ArrearPayExempted)
    //   + Number(this.ReceivedFromGratuityFundExempted)
    //   + Number(this.WorkersProfitParticipationFundExempted)
    //   + Number(this.PensionExempted)
    //   + Number(this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted);

    // return this._TotalExempted = te;
    debugger;
    let threeExem = this.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted + this.PensionExempted + this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted;

    let afterDeductingExempted = this.TotalAmount - threeExem;

    let ExemIncome = (afterDeductingExempted * 1 / 3) + threeExem;

    let finalExemtedIncome = Math.min(ExemIncome, 450000)

    // let mainExemp = this.TotalAmount - threeExem;

    // let deductedTotal = this.TotalAmount - mainExemp;

    // let exemtedIncome = (deductedTotal / 3) + threeExem;

    // let finalExemtedIncome = Math.min(exemtedIncome, 450000)

    return this._TotalExempted = finalExemtedIncome;

  }
  public set TotalExempted(x: number)
  {
    debugger;
    this._TotalExempted = x;
  }

  public get NetTaxable(): number
  {
    // let nt = Number(this.BasicPayTaxable)
    //   + Number(this.SpecialPayTaxable)
    //   + Number(this.DearnessAllowanceTaxable)
    //   + Number(this.ConveyanceAllowanceTaxable)
    //   + Number(this.HouseRentAllowanceTaxable)
    //   + Number(this.MedicalAllowanceTaxable)
    //   + Number(this.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable)
    //   + Number(this.AllowanceForSupportStaffTaxable)
    //   + Number(this.LeaveFairAssistanceStaffTaxable)
    //   + Number(this.LeaveEncashmentTaxable)
    //   + Number(this.HonorariumOrRewardTaxable)
    //   + Number(this.OvertimeAllowanceTaxable)
    //   + Number(this.BonusTaxable)
    //   + Number(this.OtherAllowancesTaxable)
    //   + Number(this.EmployersContributionProvidentFundTaxable)
    //   + Number(this.InterestAccruedProvidentFundRateOfInterestTaxable)
    //   + Number(this.DeemedFreeAccommodationExempted)
    //   + Number(this.OthersTaxable)
    //   + Number(this.ArrearPayTaxable)
    //   + Number(this.ReceivedFromGratuityFundTaxable)
    //   + Number(this.WorkersProfitParticipationFundTaxable)
    //   + Number(this.ReceivedTransportFromEmployerTaxable);

    return this._NetTaxable = this.TotalAmount - this.TotalExempted;
  }
  public set NetTaxable(x: number)
  {
    this._NetTaxable = x;
  }

  public get GrossAmount(): number
  {
    return this._GrossAmount = this.TotalAmount;
  }
  public set GrossAmount(x: number)
  {
    this._GrossAmount = x;
  }

  public get GrossExempted(): number
  {
    return this._GrossExempted = this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted + this.PensionExempted + this.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted + this.TotalExempted;
  }
  public set GrossExempted(x: number)
  {
    this._GrossExempted = x;
  }

  public get GrossTaxable(): number
  {
    return this._GrossTaxable = this.GrossAmount - this.GrossExempted;
  }
  public set GrossTaxable(x: number)
  {
    this._GrossTaxable = x;
  }
}
