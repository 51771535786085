import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MyAccountService
{
  constructor(
    private service: Service,
    private http: HttpClient
  )
  {

  }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }


  getProfileBasic(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.GET_PROFILE_BASIC);
  }

  submitProfileBasic(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();

    return this.service.post(urlConfig.UPDATE_PROFILE_BASIC, model);
  }

  changePasword(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    // console.log(urlConfig.CHANGE_PASSWORD, 'urlConfig.CHANGE_PASSWORD');


    return this.service.post(urlConfig.CHANGE_PASSWORD, model);
  }

  getProfileContact(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.GET_PROFILE_CONTACT);
  }

  CheckControlJson(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.get(urlConfig.CHECK_PROFILE);
  }



  getJson(): any
  {
    const jsonData = [
      {

        "data": [
          {
            "source": "Name (as per trade license)",
            "value": "--",
          },
          {
            "source": "ITP",
            "value": "--",
          },
          {
            "source": "Bar Membership Number",
            "value": "--",
          },
          {
            "source": "Mobile No.",
            "value": "--",
          },
          {
            "source": "Contact Email",
            "value": "--",
          },
          {
            "source": "Address",
            "value": "--",
          },
          {
            "source": "City",
            "value": "--",
          },
        ]

      }


    ]

    return jsonData;
  }

  getControls(): Promise<any>
  {
    return new Promise((resolve) =>
    {

      resolve(this.getJson)

    });
  };


  imageUrlToBase64(urL: string)
  {
    return this.http.get(urL, {
      observe: 'body',
      responseType: 'arraybuffer',
    })
      .pipe(
        take(1),
        map((arrayBuffer) =>
          btoa(
            Array.from(new Uint8Array(arrayBuffer))
              .map((b) => String.fromCharCode(b))
              .join('')
          )
        ),
      )
  }

}
