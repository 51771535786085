
export class AgriculturalPropertyClass
{
  constructor(
    private _StartingValue: number,
    private _ValueChanged: number,
    private _Amount: number,
  )
  {

  }

  public get StartingValue(): number
  {
    return this._StartingValue;
  }
  public set StartingValue(x: number)
  {
    this._StartingValue = x;
  }

  public get ValueChanged(): number
  {
    return this._ValueChanged;
  }
  public set ValueChanged(x: number)
  {
    this._ValueChanged = x;
  }

  public get Amount(): number
  {
    return this._Amount = Number(this.StartingValue) + Number(this.ValueChanged);
  }
  public set Amount(x: number)
  {
    this._Amount = x;
  }
}
