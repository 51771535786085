<mat-toolbar style="height: 40px !important;">

    <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutAlign.gt-xs="start center"
        style="overflow-y: scroll;"
        fxFlex>

        <!-- <a href="https://1.envato.market/c/1257954/275988/4415?u=https%3A%2F%2Fthemeforest.net%2Fitem%2Ffuse-angularjs-material-design-admin-template%2F12931855"
           target="_blank" mat-button class="pink" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">shopping_cart</mat-icon>
            <span>Purchase FUSE (Angular 8+)</span>
        </a> -->

        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a mat-button routerLink="/documentation/changelog">Changelog</a>
        </div> -->
        <!-- <a style="color: white !important; min-width: max-content !important; font-size: 14px; text-decoration: none; padding: 0 16px;"
        routerLink="/home"
        class="nav-link"
        target="_blank">{{ 'COMMON.NAV_HOME' | translate }}</a> -->
        <button mat-button
            (click)="goToHome()"
            style="color: white !important; min-width: max-content !important;">{{ 'COMMON.NAV_HOME' | translate
            }}</button>
        <span>&bull;</span>
        <button mat-button
            (click)="goToAbout()"
            style="color: white !important; min-width: max-content !important;">{{ 'COMMON.NAV_ABOUT' | translate
            }}</button>
        <span>&bull;</span>
        <button mat-button
            (click)="goToHowTo()"
            style="color: white !important; min-width: max-content !important;">{{ 'COMMON.NAV_HOW' | translate
            }}</button>
        <span>&bull;</span>
        <button mat-button
            (click)="goToPricing()"
            style="color: white !important; min-width: max-content !important;">{{ 'COMMON.NAV_PRICE' | translate
            }}</button>
        <span>&bull;</span>
        <button mat-button
            (click)="goToFAQ()"
            style="color: white !important; min-width: max-content !important;">{{ 'COMMON.NAV_HELP' | translate
            }}</button>
        <span>&bull;</span>
        <button mat-button
            (click)="goToBlog()"
            style="color: white !important; min-width: max-content !important;">{{ 'COMMON.NAV_BLOG' | translate
            }}</button>
        <span>&bull;</span>
        <button mat-button
            (click)="goToContact()"
            style="color: white !important; min-width: max-content !important;">{{ 'COMMON.NAV_CONTACT' | translate
            }}</button>
        <div style="min-width: max-content !important; visibility: hidden;">
            Testing testing testing
        </div>
    </div>

</mat-toolbar>
