import { Component, HostListener, Input } from '@angular/core';


import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CashAssetsListService } from './Cash-assets-list.service';
import Utils from 'app/util/utils';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'CashAssetsList',
    templateUrl: './Cash-assets-list.component.html',
    styleUrls: ['./Cash-assets-list.component.scss']
})
export class CashAssetsListComponent
{

    @Input() controls: BaseControl<string>[] = [];
    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    getData: any;
    data: any;

    formcontrols$: Observable<BaseControl<string>[]>;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: CashAssetsListService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,


    )
    {
        this.meta.addTags([
            { name: 'description', content: 'digiTax Cash Assets Outside Of Business' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax Cash Assets Outside Of Business' }
        ]);
        this.setTitle('digiTax | Cash Assets Outside Of Business');

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }
    
    ngOnInit(): void
    {
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));

        if (profileData.iscomplete == false)
        {
            let incompletefields = null;

            if (profileData.incompletefields[0] != null)
            {
                incompletefields = profileData.incompletefields[0].fields;
            }

            let incompleteprofile = null;
            if (profileData.incompleteprofile[0] != null)
            {
                incompleteprofile = profileData.incompleteprofile[0];
            }

                        if (incompleteprofile == "1")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ETIN_OTHER : bangla.data.ETIN.R_ETIN_OTHER, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/EtinOthers/save']);
            }

            if (incompleteprofile == "2")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_PERSONAL_INFO : bangla.data.PERSONAL.R_ENTER_PERSONAL_INFO, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/PersonalInfo/save']);
            }

            if (incompleteprofile == "3")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONALIZE.R_PER_RETURN : bangla.data.PERSONALIZE.R_PER_RETURN, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/PersonalizeReturn']);
            }

        }
        else
        {
            this._formControlService.getControlJson().subscribe((data: any) =>
            {
                if (data != null)
                {
                    this._spinner.hide();
                }

                if (Utils.isEmptyObject(data.data) || Utils.isEmptyObject(data.data.Body))
                {
                    this._router.navigate(['/CashAssets/save']);
                }
                this.getData = data.data;
            });
        }
    }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'asset');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
