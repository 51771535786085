import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PersonalInfoComponent } from './Personal-Information.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PersonalInformationControlComponent } from './Personal-Information-form-control.component';
import { MY_FORMATS, PersonalInformationService } from './Personal-Information.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';


import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseDemoModule, FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ViewDashboardGuard } from 'app/auth/viewDashboard.guard';
import { MatMenuModule } from '@angular/material/menu';


const routes = [
    {
        path: 'PersonalInfo/save',
        component: PersonalInfoComponent,
        canActivate: [AuthGuard, ViewDashboardGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        PersonalInfoComponent,
        PersonalInformationControlComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatFormFieldModule,

        MatInputModule,

        MatMenuModule,

        MatSlideToggleModule,

        MatSelectModule,

        MatRadioModule,

        MatDatepickerModule,

        MatSnackBarModule,

        MatTooltipModule,

        MatCheckboxModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,

    ],
    exports: [
        PersonalInfoComponent
    ],
    providers: [

        PersonalInformationService,
        Service,

        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
})

export class PersonalInfoModule
{
}
