<div fxLayout="column">
    <form name="pform"
        [formGroup]="pform">
        <div fxLayoutAlign="start center"
            fxLayout="row"
            style="padding-top: 5px; padding-bottom: 5px;">
            <span style="width: 30%;">{{ 'COMMON.BUTTON_COMMENT' | translate }}: </span>
            <mat-form-field style="width: 70%;"
                appearance="outline">
                <input matInput
                    required
                    formControlName="Comments"
                    (keyup)="onFocusoutEvent()"
                    id="Comments">
            </mat-form-field>
        </div>
        <div fxLayoutAlign="start center">
            <span class="h4"
                style="color: red;">*{{ 'DOCUMENTS.DOC_REP' | translate }}</span>
        </div>
        <div mat-dialog-actions
            fxLayoutAlign="end center">

            <button mat-button
                class="warn"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 10px; margin-right: 10px; border: 0; cursor: pointer;"
                (click)="onNoClick()">
                <span>{{ 'COMMON.BUTTON_CANCEL' | translate }}</span>
            </button>

            <button mat-button
                class="dupl"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 10px; margin-left: 10px; border: 0; cursor: pointer;"
                (click)="OnSubmit()">
                <mat-icon class="s-16 mr-sm-4">arrow_upward</mat-icon>
                <span>{{ 'COMMON.BUTTON_SAVE' | translate }}</span>
            </button>

        </div>
    </form>
</div>
