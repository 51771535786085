import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class SalaryService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }
  submitPrivateSalaryJson(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Salary_Private_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Salary_Private_Submit_Url + `/${id}`, model);
    }
  }

  getPageData(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.INCOME_Salary_Private_Control_Url + id);
  }


  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    if (id != null)
    {
      return this.service.get(urlConfig.INCOME_Salary_Private_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
      return this.service.get(urlConfig.INCOME_Salary_Private_Control_Url).pipe(map(item => Utils.toControls(item.data)));

    }

  }

  // calculateSalaryJson(model: any): Observable<any>
  // {
  //   return this.service.post(Config.INCOME_CALCULATE_Private_Salary, model);
  // }


  getJson(): any
  {

    const jsonData = [
      {
        "control": "LabelGroupControl",
        "key": "salaryHeaders",
        "errorType": "",
        "label": "Pay & Allowance",
        "message": "",
        "tooltip": "",
        "order": 1,
        "children": [
          {
            "control": "LabelControl",
            "key": "header2",
            "label": "Amount Of Yearly Income",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 1
          },
          {
            "control": "LabelControl",
            "key": "header3",
            "label": "Amount Of Exempted Income",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 2
          },
          {
            "control": "LabelControl",
            "key": "header4",
            "label": "Net Taxable Income",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "basicPay",
        "label": "Basic Pay",
        "errorType": "",
        "message": "",
        "tooltip": "Basic pay is the annual amount of money that the taxpayer earned as an employee. Basic pay does not include benefits, bonuses, or other compensation mentioned in the sections below.",
        "order": 2,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "BasicPayAmount",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "",
            "tabindex": "",
            "required": true,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "BasicPayExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "BasicPayTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "specialPay",
        "label": "Special Pay",
        "errorType": "",
        "message": "",
        "tooltip": "An allowance specially pay by employer to taxpayer and is subject to income tax. ",
        "order": 3,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "SpecialPayAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "SpecialPayExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "SpecialPayTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "dearnessAllowance",
        "label": "Dearness Allowance",
        "errorType": "",
        "message": "",
        "tooltip": "Dearness Allowance is the cost of living adjustment allowance paid to government employees, public sector employees (PSU) and pensioners.",
        "order": 4,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "DearnessAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "DearnessAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "DearnessAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "conveyanceStatus",
        "label": "Conveyance Allowance",
        "errorType": "",
        "message": "",
        "tooltip": "The conveyance allowance is a type of allowance which is received for transportation cost.",
        "order": 5,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ConveyanceAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ConveyanceAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ConveyanceAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "houseRentAllowance",
        "label": "House Rent Allowance",
        "errorType": "",
        "message": "",
        "tooltip": "House Rent Allowance refers to the annual compensation the taxpayer received that is connected with rent or accommodation.",
        "order": 6,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "HouseRentAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "HouseRentAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "HouseRentAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "medicalAllowanceWoDisability",
        "label": "Medical Allowance",
        "errorType": "",
        "message": "",
        "tooltip": "Medical Allowance refers to the annual compensation the taxpayer received that is connected with medical purposes.",
        "order": 7,
        "innerContent": [
          {
            "control": "CheckboxControl",
            "key": "IsDisabledPerson",
            "label": "Is a disabled person",
            "errorType": "",
            "tabindex": "-1",
            "message": "",
            "tooltip": "",
            "required": false,
            "readonly": false,
          }
        ],
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "MedicalAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "MedicalAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "MedicalAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "amountReceivedForSurgery",
        "label": "Any Amount Received For Surgery of Heart, Eye, Liver, Kidney, Cancer",
        "errorType": "",
        "message": "",
        "tooltip": "Any Amount Received For Surgery of Heart, Eye, Liver, Kidney, Cancer",
        "order": 8,
        "innerContent": [
          {
            "control": "CheckboxControl",
            "key": "IsShareHolder",
            "label": "Is a shareholder or director",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "readonly": false,
          }
        ],
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "lfa",
        "label": "Leave Fare Assistance (LFA)",
        "errorType": "",
        "message": "",
        "tooltip": "Leave Allowance / LFA refers to the annual compensation the taxpayer received that is connected to paid time off or vacation.",
        "order": 9,
        "innerContent": [
          {
            "control": "CheckboxControl",
            "key": "IsMentionedInEmploymentContract",
            "label": "Mentioned In Employee Contract regarding LFA/FREE or Concessional Passage",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "readonly": false,
          }
        ],
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "LeaveFairAssistanceStaffAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "LeaveFairAssistanceStaffExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "LeaveFairAssistanceStaffTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "leaveEncashment",
        "label": "Leave Encashment",
        "errorType": "",
        "message": "",
        "tooltip": "If any employee doesn't take all his paid holidays, he can get payment in return of those days.",
        "order": 10,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "LeaveEncashmentAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "LeaveEncashmentExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "LeaveEncashmentTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "allowanceForSupportStaff",
        "label": "Allowance For Support Staff",
        "errorType": "",
        "message": "",
        "tooltip": "Servant Allowance refers to the annual compensation the taxpayer received that is connected hiring a servant.",
        "order": 11,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "AllowanceForSupportStaffAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "AllowanceForSupportStaffExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "AllowanceForSupportStaffTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "HonorariumReward",
        "label": "Honorarium or Reward",
        "errorType": "",
        "message": "",
        "tooltip": "This refers to the annual income the taxpayer received for service or honourable work.",
        "order": 12,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "HonorariumOrRewardAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "HonorariumOrRewardExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "HonorariumOrRewardTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "overtimeAllowance",
        "label": "Overtime Allowance",
        "errorType": "",
        "message": "",
        "tooltip": "Overtime Allowance refers to the annual income the taxpayer received for working beyond normal hours.",
        "order": 13,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OvertimeAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OvertimeAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OvertimeAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "bonus",
        "label": "Bonus",
        "errorType": "",
        "message": "",
        "tooltip": "Bonus / Ex-gratia refers to the annual income the taxpayer received for a sum of money paid when there was no obligation or liability to pay it. For example, a holiday bonus or a lump sum payment over and above the pension benefits of a retiring employee.",
        "order": 14,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "BonusAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "BonusExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "BonusTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "otherAllowances",
        "label": "Other Allowances",
        "errorType": "",
        "message": "",
        "tooltip": "Other Allowances refers to the annual compensation the taxpayer received that is connected to any other allowance they received including examples like residential security, gardener, chef, etc.",
        "order": 15,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OtherAllowancesAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OtherAllowancesExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OtherAllowancesTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "employersContributionProvidentFund",
        "label": "Employers Contribution Provident Fund",
        "errorType": "",
        "message": "",
        "tooltip": "This refers to the total annual contribution to a provident fund which has been recognised by the Commissioner.",
        "order": 16,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "EmployersContributionProvidentFundAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "EmployersContributionProvidentFundExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "EmployersContributionProvidentFundTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "interestAccruedProvidentFund",
        "label": "Interest Accrued Provident Fund",
        "errorType": "",
        "message": "",
        "tooltip": "This refers to the total annual interest accrued from a provident fund which has been recognised by the Commissioner.",
        "order": 17,
        "innerContent": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "RateOfInterestOfProvidentFund",
            "label": "Rate Of Interest",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "readonly": false,
          }
        ],
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "InterestAccruedProvidentFundRateOfInterestAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "InterestAccruedProvidentFundRateOfInterestExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "InterestAccruedProvidentFundRateOfInterestTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "receivedAnyTransport",
        "label": "Have You Received Any Transport From Your Employer?",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 18,
        "innerContent": [
          {
            "control": "CheckboxControl",
            "key": "IsReceivedAnyTransportFromEmployer",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "readonly": false,
          }
        ],
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedTransportFromEmployerTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "receivedAnyHouse",
        "label": "Received Any Furnished or Unfurnished House From Your Employer?",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 19,
        "innerContent": [
          {
            "control": "CheckboxControl",
            "key": "IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
          }
        ],
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "EmployerPaidRentalAmount",
            "label": "Paid by Employer",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "EmployeePaidRentalAmount",
            "label": "Paid by You",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "DeemedFreeAccommodationExempted",
            "label": "netTaxableRental",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "others",
        "label": "Others",
        "errorType": "",
        "message": "",
        "tooltip": "Any amount received from the employer for the purpose of something else other than mentioned ones.",
        "order": 20,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OthersAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OthersExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "OthersTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "arrearPay",
        "label": "Arrear Pay (If not included in taxable income earlier)",
        "errorType": "",
        "message": "",
        "tooltip": "If any other amount received from employer by salary purpose.",
        "order": 21,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ArrearPayAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ArrearPayExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ArrearPayTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "gratuityFundIncome",
        "label": "Income Received From Gratuity Fund",
        "errorType": "",
        "message": "",
        "tooltip": "Gratuity income is the minimum payment made to a worker during dismissal who has stayed at least 6 months in a organisation. The rate of payment is at least 30 days' wages and if he have spent more than 10 years, minimum 45 days' salary",
        "order": 22,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedFromGratuityFundAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedFromGratuityFundExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedFromGratuityFundTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "workersProfitFund",
        "label": "Income From Workers Profit Participation Fund",
        "errorType": "",
        "message": "",
        "tooltip": "Fund established under Labour Act 2006",
        "order": 23,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "WorkersProfitParticipationFundAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "WorkersProfitParticipationFundExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "WorkersProfitParticipationFundTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "pension",
        "label": "Pension",
        "errorType": "",
        "message": "",
        "tooltip": "A pension is a fund into which a sum of money is added during an employee's employment years and from which payments are drawn to support the person's retirement from work in the form of periodic payments.",
        "order": 24,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "PensionAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "PensionExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "PensionTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "providentFundIncome",
        "label": "Income Received From Recognized Provident Fund and Recognized Super Annuation Fund",
        "errorType": "",
        "message": "",
        "tooltip": "The Funds are to be recognized from the relevant authority.",
        "order": 25,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "netTaxableIncome",
        "label": "Net Taxable Income",
        "errorType": "",
        "message": "",
        "tooltip": "This refers the total taxable income from salary",
        "order": 26,
        "children": [
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "TotalAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "TotalExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",
            "type": "number",
            "key": "NetTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      }
    ]
      ;
    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
