<div class="page-layout simple right-sidebar inner-scroll"
    fxFLex=100>

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'ARCHIVE.HEADER' | translate }}
            </span>

        </div>

        <div class="content"
            fusePerfectScrollbar>
            <div *ngIf="archiveStat.IsNewReturn"
                fxLayout="column"
                fxLayoutAlign="space-between center"
                fxLayout.gt-md="row"
                style="padding: 10px; margin-left: 10px; margin-right: 10px;">
                <span class="h4 text-bold"> {{ 'ARCHIVE.HEADER_1' | translate }}</span>

                <button mat-raised-button
                    (click)="ArchiveReturn()"
                    class="mat-button mat-button-base dupl">
                    <span>{{ 'ARCHIVE.BUTTON_S' | translate }}</span>
                </button>

            </div>

            <div *ngIf="archiveStat.IsNewReturn"
                fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-md="column"
                style="padding: 10px; background-color: #ffeaed; margin: 10px;">
                <span class="h4 text-bold">{{ 'ARCHIVE.NOTICE_H' | translate }}</span>
                <span class="h4"> {{ 'ARCHIVE.NOTICE_1' | translate }}</span>
                <span class="h4"> {{ 'ARCHIVE.NOTICE_2' | translate }}</span>
                <span class="h4"> {{ 'ARCHIVE.NOTICE_3' | translate }}</span>
                <span class="h4"> {{ 'ARCHIVE.NOTICE_4' | translate }}</span>

            </div>
            <table class="simple invoice-table"
                *ngIf="hasArchivedData">
                <!-- <table class="simple invoice-table"> -->
                <thead>
                    <tr *ngIf="getData!=null">
                        <th class="thNewline"
                            style="color: #444444;">
                            SL
                        </th>

                        <th class="thNewline"
                            style="color: #444444;">
                            TAX YEAR
                        </th>
                        <th class="thNewline"
                            style="color: #444444;">
                            PAYER NAME
                        </th>
                        <th class="text-right thNewline"
                            style="color: #444444;">
                            TOTAL PAYABLE
                        </th>
                        <th class="text-right thNewline"
                            style="color: #444444;">
                            AMOUNT PAID WITH RETURN
                        </th>

                        <th class="text-center thNewline"
                            style="color: #444444;">
                            ACTIONS
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let data of getData; index as i">
                        <td>
                            {{i +1}}
                        </td>

                        <td>
                            {{data.TaxYearName}}
                        </td>
                        <td>
                            {{data.PayerName}}
                        </td>
                        <td class="text-right">
                            {{data.TotalTaxPayble}}
                        </td>
                        <td class="text-right">
                            {{data.AmountPaid}}
                        </td>

                        <td class="text-right">
                            <div fxLayoutAlign="center center">

                                <a *ngIf="data.IsPaidPlan==false"
                                    style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                    fxLayout="column wrap"
                                    matTooltip="{{ 'DOCUMENTS.DOC_DL_FILE' | translate }}"
                                    (click)="NotPaid()">
                                    <mat-icon class="nav-link-icon">arrow_downward</mat-icon>
                                </a>

                                <a *ngIf="data.IsPaidPlan==true"
                                    style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                    fxLayout="column wrap"
                                    matTooltip="{{ 'DOCUMENTS.DOC_DL_FILE' | translate }}"
                                    [matMenuTriggerFor]="userMenu">
                                    <mat-icon class="nav-link-icon">arrow_downward</mat-icon>
                                </a>
                            </div>
                            <!-- <button mat-button
                                style="padding: 5px;"
                                [matMenuTriggerFor]="userMenu"
                                class="user-button dupl">
                                <div fxLayout="row"
                                    fxLayoutAlign="center center">
                                    <span class="username"
                                        style="margin-left: 5px;margin-right: 5px;">Download</span>
                                    <mat-icon class="s-24">keyboard_arrow_down</mat-icon>
                                </div>
                            </button> -->

                            <mat-menu #userMenu="matMenu"
                                [overlapTrigger]="false">

                                <button mat-menu-item
                                    (click)="downloadPDF(data)">
                                    <span>Return file</span>
                                </button>

                                <button mat-menu-item
                                    (click)="summaryPDF(data)">
                                    <span>Calculation summary</span>
                                </button>

                            </mat-menu>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>
