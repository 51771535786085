import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';

@Component({
  selector: 'SpouseOrChild-form-control',
  templateUrl: './SpouseOrChild-form-control.component.html',
  styleUrls: ['./SpouseOrChild.component.scss']

})
export class SpouseOrChildFormControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  pageLoaded: boolean;
  IsCurrentYear: any;

  constructor(
    private cdr: ChangeDetectorRef,
  )
  {

  }



  ngAfterViewInit()
  {
    this.cdr.detectChanges();
  }

  get isValid()
  {
    if (this.pageLoaded)
    {
      return this.form.controls[this.control.key].valid;
    } else
    {
      return false;
    }

  }

  ngOnInit()
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }

    this.pageLoaded = true;

  }
}


