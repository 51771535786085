import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ShareofProfitInaFirmService } from './ShareofProfitInaFirm.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import Utils from 'app/util/utils';
import { AuthenticationService } from 'app/auth';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';



@Component({
    selector: 'ShareofProfitInaFirm',
    templateUrl: './ShareofProfitInaFirm.component.html',
    styleUrls: ['./ShareofProfitInaFirm.component.scss']
})
export class ShareofProfitInaFirmComponent
{




    @Input() controls: BaseControl<string>[] = [];
    form: FormGroup;
    payLoad = '';
    id: any;
    lang = '';

    formcontrols$: Observable<BaseControl<string>[]>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    /**
      * Constructor
     * @param {MatSnackBar} _matSnackBar
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: ShareofProfitInaFirmService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private sidePanelDeleteService: SidePanelDeleteService,

        private _authService: AuthenticationService,

        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,


    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Income From Share of Profit In a Firm" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Income From Share of Profit In a Firm" }
        ]);
        this.setTitle("digiTax | Income From Share of Profit In a Firm");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');

        this.lang = localStorage.getItem('selectedLanguage');
        this._formControlService.getControlJson(this.id).subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            this.controls = data;
            this.form = this._formControlService.toFormGroup(this.controls);
        });
        // this._formControlService.getControls().subscribe((data: any) =>
        // {
        //     this.controls = data;
        //     this.form = this._formControlService.toFormGroup(this.controls);
        // });
        this.form = this._formControlService.toFormGroup(this.controls);

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

    }

    mySubmitExit(param): void
    {
        // debugger;
        if (param == 'next' || param == 'more')
        {
            this.OnSubmit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }

    OnSubmit(param): void
    {
        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }
        this._spinner.show();
        this.id = this._route.snapshot.paramMap.get('id');

        let obj = this.form.getRawValue();



        if (isNaN(Number(obj.FirmIncome)) == true)
        {
            obj.FirmIncome = "";
        }

        if (isNaN(Number(obj.PercentOfShare)) == true)
        {
            obj.PercentOfShare = "";
        }



        let stringed = JSON.stringify(obj);

        if (obj.FirmName == "")
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.SHAREPROFIT.ENTER_NAME : bangla.data.SHAREPROFIT.ENTER_NAME, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {

            if (obj.FirmIncome == "" || obj.FirmIncome == 0)
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.SHAREPROFIT.ENTER_INCOME : bangla.data.SHAREPROFIT.ENTER_INCOME, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (obj.PercentOfShare == "" || obj.PercentOfShare == 0)
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.SHAREPROFIT.ENTER_OWNERSSHIP : bangla.data.SHAREPROFIT.ENTER_OWNERSSHIP, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });

                } else
                {
                    if (stringed != null)
                    {
                        this._formControlService.submitShareofProfitInaFirm(stringed, this.id)
                            .subscribe((data: any) =>
                            {
                                if (data != null)
                                {
                                    this._spinner.hide();
                                }

                                if (data.isError === false)
                                {
                                    console.log('submit succesfull');
                                }
                                else
                                {
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                }
                                if (data.isError === false && data.responseCode == "200")
                                {
                                    if (this.id != null)
                                    {
                                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                    }
                                    else
                                    {
                                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                    }
                                    if (param == 'next')
                                    {
                                        this.improvisedIMenu(param);
                                    }
                                    if (param == 'more')
                                    {
                                        this.sidePanelDeleteService.changeMessage(true);
                                        this.form.reset();
                                    }
                                    if (param == 'exit')
                                    {
                                        this._authService.Logout();
                                    }


                                }
                            }, (error: any) =>
                            {
                                console.log(error, 'error');
                            });
                    }
                }
            }
        }
    }

    ConfirmLeave(url): void
    {
        if (this.form.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    // this._router.navigate([url]);
                    this.improvisedIMenu(url);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            // this._router.navigate([url]);
            this.improvisedIMenu(url);
        }
    }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'income');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
