<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            fusePerfectScrollbar
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'REBATE.HEADER' | translate }}
            </span>

        </div>
        <div class="content mb-48"
            fusePerfectScrollbar>
            <div class="course-step p-24">

                <form class="page-layout simple fullwidth"
                    fxLayout="column wrap"
                    [formGroup]="form">

                    <div *ngFor="let control of controls"
                        class="form-row">
                        <TaxRebate-form-control [control]="control"
                            [form]="form">
                        </TaxRebate-form-control>
                    </div>
                </form>
            </div>
        </div>
        <div class="step-navigation"
            *ngIf="id==null">
            <button mat-raised-button
                (click)="ConfirmLeave('prev')"
                style="border: 2px solid #37793c;line-height: 30px;background-color: #ffffff;cursor: pointer;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>

            <button mat-raised-button
                style="margin-left: 10px; font-weight: bold !important; color: #37793c !important; background-color: #e5e5e5 ;border: 0; border-radius: 4px; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit('more')">
                {{ 'COMMON.BUTTON_SAVE_ADD_MORE' | translate }}
            </button>

            <button mat-raised-button
                style="margin-left: 10px;background-color: #37793c;border: 0;border-top-left-radius: 4px;border-bottom-left-radius: 4px;color: #ffffff; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>

            <button [matMenuTriggerFor]="sNeBtn"
                style=" background-color: #37793c; border: 0; border-radius: 4px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;cursor: pointer; line-height: 34px;">
                <mat-icon style="color: #ffffff;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit('exit')">
                    <span style="font-weight: bold; color: #37793c;"> {{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}
                    </span>
                </button>
            </mat-menu>

            <button mat-raised-button
                (click)="ConfirmLeave('/TaxDeductedAtSource')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer;line-height: 30px;margin-left: 10px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>

        <div class="step-navigation"
            *ngIf="id!=null">
            <button mat-raised-button
                (click)="ConfirmLeave('prev')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer; margin-right: 10px;line-height: 30px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>
            <button mat-raised-button
                style="color: #444444 !important; margin-right: 10px;"
                [routerLink]="'/TaxRebate'"
                class="mat-button mat-button-base primary-100 secondary-text">
                {{ 'COMMON.BUTTON_CANCEL' | translate }}
            </button>
            <button mat-raised-button
                style="margin-left: right;background-color: #37793c; border: 0; border-top-left-radius: 4px;border-bottom-left-radius: 4px; color: #ffffff; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>
            <button [matMenuTriggerFor]="sNeBtn"
                style=" background-color: #37793c; border: 0; border-radius: 4px;border-top-left-radius: 0; border-bottom-left-radius: 0;color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;cursor: pointer; line-height: 34px;">
                <mat-icon style="color: #ffffff;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit('exit')">
                    <span style="font-weight: bold; color: #37793c;">{{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}</span>
                </button>
            </mat-menu>
            <button mat-raised-button
                (click)="ConfirmLeave('/TaxDeductedAtSource')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer; margin-left: 10px;line-height: 30px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>
    </div>
</div>
