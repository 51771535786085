<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2> Terms & Conditions</h2>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area ptb-100"
    style="padding-top: 0; ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <div class="features-text">
                        <h4>Updated at 2021-09-06</h4>
                    </div>
                    <div class="features-text">
                        <h4>General Terms</h4>
                        <p style="color: #444444;">By accessing and using digiTax, you confirm that you are in agreement
                            with and bound by the terms of service contained in the Terms & Conditions outlined below.
                            These terms apply to the entire website and any email or other type of communication between
                            you and digiTax.</p>
                        <p style="color: #444444;">Under no circumstances shall digiTax team be liable for any direct,
                            indirect, special, incidental or consequential damages, including, but not limited to, loss
                            of data or profit, arising out of the use, or the inability to use, the materials on this
                            site, even if digiTax team or an authorized representative has been advised of the
                            possibility of such damages. If your use of materials from this site results in the need for
                            servicing, repair or correction of equipment or data, you assume any costs thereof.</p>
                        <p style="color: #444444;">digiTax will not be responsible for any outcome that may occur during
                            the course of usage of our resources. We reserve the right to change prices and revise the
                            resources usage policy at any moment.</p>
                    </div>
                    <div class="features-text">
                        <h4>License</h4>
                        <p style="color: #444444;">digiTax grants you a revocable, non-exclusive, non-transferable,
                            limited license to download, install and use the website/app strictly in accordance with the
                            terms of this Agreement.</p>
                        <p style="color: #444444;">These Terms & Conditions are a contract between you and digiTax
                            (referred to in these Terms & Conditions as "digiTax", "us", "we" or "our"), the provider of
                            the digiTax website and the services accessible from the digiTax website (which are
                            collectively referred to in these Terms & Conditions as the "digiTax Service").</p>
                        <p style="color: #444444;">You are agreeing to be bound by these Terms & Conditions. If you do
                            not agree to these Terms & Conditions, please do not use the digiTax Service. In these Terms
                            & Conditions, "you" refers both to you as an individual and to the entity you represent. If
                            you violate any of these Terms & Conditions, we reserve the right to cancel your account or
                            block access to your account without notice.</p>
                    </div>
                    <div class="features-text">
                        <h4>Meanings For this Terms & Conditions:</h4>
                        <p style="color: #444444;"> &bull; Cookie: Small amount of data generated by a website and saved
                            by
                            your web browser. It is used to identify your browser, provide analytics, remember
                            information about you such as your language preference or login information.</p>
                        <p style="color: #444444;"> &bull; Company: when this policy mentions “Company,” “we,” “us,” or
                            “our,”
                            it refers to Desh Universal (Pvt.) Limited, (Desh Universal (Pvt.) Limited As-Salam Tower
                            (Level 5), 82-83 Zoo Road, Block-A, Section-2, Mirpur, Dhaka-1216) that is responsible for
                            your information under this Terms & Conditions.</p>
                        <p style="color: #444444;"> &bull; Country: where digiTax or the owners/founders of digiTax are
                            based,
                            in this case, is Bangladesh</p>
                        <p style="color: #444444;"> &bull; Device: any internet-connected device such as a phone,
                            tablet,
                            computer or any other device that can be used to visit digiTax and use the services.</p>
                        <p style="color: #444444;"> &bull; Service: refers to the service provided by digiTax as
                            described in
                            the relative terms (if available) and on this platform.</p>
                        <p style="color: #444444;"> &bull; Third-party service: refers to advertisers, contest sponsors,
                            promotional and marketing partners, and others who provide our content or whose products or
                            services we think may interest you.</p>
                        <p style="color: #444444;"> &bull; Website: "digiTax’s" site, which can be accessed via this
                            URL:
                            www.digitax.com.bd</p>
                        <p style="color: #444444;"> &bull; You: a person or entity that is registered with digiTax to
                            use the
                            Services.</p>
                    </div>
                    <div class="features-text">
                        <h4>Restrictions</h4>
                        <p style="color: #444444;">You agree not to, and you will not permit others to:</p>
                        <p style="color: #444444;"> &bull; License, sell, rent, lease, assign, distribute, transmit,
                            host,
                            outsource, disclose or otherwise commercially exploit the website/app or make the platform
                            available to any third party.</p>
                        <p style="color: #444444;"> &bull; Modify, make derivative works of, disassemble, decrypt,
                            reverse
                            compile or reverse engineer any part of the website/app.</p>
                        <p style="color: #444444;"> &bull; Remove, alter or obscure any proprietary notice (including
                            any
                            notice of copyright or trademark) of digiTax or its affiliates, partners, suppliers or the
                            licensors of the website/app.</p>
                    </div>
                    <div class="features-text">
                        <h4>Return and Refund Policy</h4>
                        <p style="color: #444444;">Thanks for using digiTax. We appreciate the fact that you like to use
                            the product we build. We also want to make sure you have a rewarding experience while you’re
                            exploring, evaluating, and using our products</p>
                        <p style="color: #444444;">As with any purchase experience, there are terms and conditions that
                            apply to transactions at digiTax. We’ll be as brief as our attorneys will allow. The main
                            thing to remember is that by making a purchase at digiTax, you agree to the terms set forth
                            below along with the Policy.</p>
                        <p style="color: #444444;">Once the payment has been completed and the download option is unlocked immediately, you will not be able to claim a refund.</p>
                    </div>
                    <div class="features-text">
                        <h4>“digiTax’s" Privacy Policy</h4>
                        <p style="color: #444444;">If, for any reason, You are not completely satisfied with any good or
                            service that we provide, don't hesitate to contact us and we will discuss any of the issues
                            you are going through with our product.</p>
                    </div>
                    <div class="features-text">
                        <h4>Your Suggestions</h4>
                        <p style="color: #444444;">Any feedback, comments, ideas, improvements or suggestions
                            (collectively, "Suggestions") provided by you to digiTax with respect to the website/app
                            shall remain the sole and exclusive property of digiTax.</p>
                        <p style="color: #444444;">digiTax shall be free to use, copy, modify, publish, or redistribute
                            the Suggestions for any purpose and in any way without any credit or any compensation to
                            you.</p>
                    </div>
                    <div class="features-text">
                        <h4>Definitions</h4>
                        <p style="color: #444444;"></p>
                    </div>
                    <div class="features-text">
                        <h4>Your Consent</h4>
                        <p style="color: #444444;">We've updated our Terms & Conditions to provide you with complete
                            transparency into what is being set when you visit our site and how it's being used. By
                            using our website/app, registering an account, or making a purchase, you hereby consent to
                            our Terms & Conditions.</p>
                    </div>
                    <div class="features-text">
                        <h4>Links to Other Websites</h4>
                        <p style="color: #444444;">These Terms & Conditions apply only to the Services. The Services may
                            contain links to other websites not operated or controlled by digiTax. We are not
                            responsible for the content, accuracy or opinions expressed in such websites, and such
                            websites are not investigated, monitored or checked for accuracy or completeness by us.
                            Please remember that when you use a link to go from the Services to another website, our
                            Terms & Conditions are no longer in effect. Your browsing and interaction on any other
                            website, including those that have a link on our platform, is subject to that website’s own
                            rules and policies. Such third parties may use their own cookies or other methods to collect
                            information about you.</p>
                    </div>
                    <div class="features-text">
                        <h4>Cookies</h4>
                        <p style="color: #444444;">digiTax uses "Cookies" to identify the areas of our website that you
                            have visited. A Cookie is a small piece of data stored on your computer or mobile device by
                            your web browser. We use Cookies to enhance the performance and functionality of our
                            website/app but are non-essential to their use. However, without these cookies, certain
                            functionality like videos may become unavailable or you would be required to enter your
                            login details every time you visit the website/app as we would not be able to remember that
                            you had logged in previously. Most web browsers can be set to disable the use of Cookies.
                            However, if you disable Cookies, you may not be able to access functionality on our website
                            correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
                    </div>
                    <div class="features-text">
                        <h4>Changes To Our Terms & Conditions</h4>
                        <p style="color: #444444;">You acknowledge and agree that digiTax may stop (permanently or
                            temporarily) providing the Service (or any features within the Service) to you or to users
                            generally at digiTax’s sole discretion, without prior notice to you. You may stop
                            using the Service at any time. You do not need to specifically inform digiTax when
                            you stop using the Service. You acknowledge and agree that if digiTax disables
                            access to your account, you may be prevented from accessing the Service, your account
                            details or any files or other materials which is contained in your account.</p>
                        <p style="color: #444444;">If we decide to change our Terms & Conditions, we will post those
                            changes on this page, and/or update the Terms & Conditions modification date below.</p>

                    </div>
                    <div class="features-text">
                        <h4>Modifications to Our website/app</h4>
                        <p style="color: #444444;">digiTax reserves the right to modify, suspend or discontinue,
                            temporarily or permanently, the website/app or any service to which it connects, with or
                            without notice and without liability to you.</p>
                    </div>
                    <div class="features-text">
                        <h4>Updates to Our website/app</h4>
                        <p style="color: #444444;">digiTax may from time to time provide enhancements or improvements to
                            the features/ functionality of the website/app, which may include patches, bug fixes,
                            updates, upgrades and other modifications ("Updates").</p>
                        <p style="color: #444444;">Updates may modify or delete certain features and/or functionalities
                            of the website/app. You agree that digiTax has no obligation to (i) provide any Updates, or
                            (ii) continue to provide or enable any particular features and/or functionalities of the
                            website/app to you.</p>
                        <p style="color: #444444;">You further agree that all Updates will be (i) deemed to constitute
                            an integral part of the website/app, and (ii) subject to the terms and conditions of this
                            Agreement.</p>
                    </div>
                    <div class="features-text">
                        <h4>Third-Party Services</h4>
                        <p style="color: #444444;">We may display, include or make available third-party content
                            (including data, information, applications and other products services) or provide links to
                            third-party websites or services ("Third- Party Services").</p>
                        <p style="color: #444444;">You acknowledge and agree that digiTax shall not be responsible for
                            any Third-Party Services, including their accuracy, completeness, timeliness, validity,
                            copyright compliance, legality, decency, quality or any other aspect thereof. digiTax does
                            not assume and shall not have any liability or responsibility to you or any other person or
                            entity for any Third-Party Services.</p>
                        <p style="color: #444444;">Third-Party Services and links thereto are provided solely as a
                            convenience to you and you access and use them entirely at your own risk and subject to such
                            third parties' terms and conditions.</p>
                    </div>
                    <div class="features-text">
                        <h4>Term and Termination</h4>
                        <p style="color: #444444;">This Agreement shall remain in effect until terminated by you or
                            digiTax.</p>
                        <p style="color: #444444;">digiTax may, in its sole discretion, at any time and for any or no
                            reason, suspend or terminate this Agreement with or without prior notice.</p>
                        <p style="color: #444444;">This Agreement will terminate immediately, without prior notice from
                            digiTax, in the event that you fail to comply with any provision of this Agreement. You may
                            also terminate this Agreement by deleting the website/app and all copies thereof from your
                            computer.</p>
                        <p style="color: #444444;">Upon termination of this Agreement, you shall cease all use of the
                            website/app and delete all copies of the website/app from your computer.</p>
                        <p style="color: #444444;">Termination of this Agreement will not limit any of digiTax's rights
                            or remedies at law or in equity in case of breach by you (during the term of this Agreement)
                            of any of your obligations under the present Agreement.</p>
                    </div>
                    <div class="features-text">
                        <h4>Copyright Infringement Notice</h4>
                        <p style="color: #444444;">If you are a copyright owner or such owner’s agent and believe any
                            material on our website/app constitutes an infringement on your copyright, please contact us
                            setting forth the following information: (a) a physical or electronic signature of the
                            copyright owner or a person authorized to act on his behalf; (b) identification of the
                            material that is claimed to be infringing; (c) your contact information, including your
                            address, telephone number, and an email; (d) a statement by you that you have a good faith
                            belief that use of the material is not authorized by the copyright owners; and (e) the
                            statement that the information in the notification is accurate, and, under penalty of
                            perjury you are authorized to act on behalf of the owner.</p>
                    </div>
                    <div class="features-text">
                        <h4>Amendments to this Agreement</h4>
                        <p style="color: #444444;">digiTax reserves the right, at its sole discretion, to modify or
                            replace this Agreement at any time. If a revision is material we will provide at least 30
                            days notice prior to any new terms taking effect. What constitutes a material change will be
                            determined at our sole discretion.
                            By continuing to access or use our website/app after any revisions become effective, you
                            agree to be bound by the revised terms. If you do not agree to the new terms, you are no
                            longer authorized to use digiTax.
                        </p>
                    </div>
                    <div class="features-text">
                        <h4>Entire Agreement</h4>
                        <p style="color: #444444;">The Agreement constitutes the entire agreement between you and
                            digiTax regarding your use of the website/app and supersedes all prior and contemporaneous
                            written or oral agreements between you and digiTax.
                            You may be subject to additional terms and conditions that apply when you use or purchase
                            other digiTax's services, which digiTax will provide to you at the time of such use or
                            purchase.
                        </p>
                    </div>
                    <div class="features-text">
                        <h4>Updates to Our Terms</h4>
                        <p style="color: #444444;"></p>
                    </div>
                    <div class="features-text">
                        <h4>Definitions</h4>
                        <p style="color: #444444;">We may change our Service and policies, and we may need to make
                            changes to these Terms so that they accurately reflect our Service and policies. Unless
                            otherwise required by law, we will notify you (for example, through our Service) before we
                            make changes to these Terms and give you an opportunity to review them before they go into
                            effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If
                            you do not want to agree to these or any updated Terms, you can delete your account.</p>
                    </div>
                    <div class="features-text">
                        <h4>Intellectual Property</h4>
                        <p style="color: #444444;">The website/app and its entire contents, features and functionality
                            (including but not limited to all information, software, text, displays, images, video and
                            audio, and the design, selection and arrangement thereof), are owned by digiTax, its
                            licensors or other providers of such material and are protected by Bangladesh and
                            international copyright, trademark, patent, trade secret and other intellectual property or
                            proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or
                            distributed in any way, in whole or in part, without the express prior written permission of
                            digiTax, unless and except as is expressly provided in these Terms & Conditions. Any
                            unauthorized use of the material is prohibited.</p>
                    </div>
                    <div class="features-text">
                        <h4>Agreement to Arbitrate</h4>
                        <p style="color: #444444;">This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A
                            DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR
                            VALIDITY OF YOUR OR digiTax."’s" INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any
                            dispute, action, or other controversies between you and digiTax concerning the Services or
                            this agreement, whether in contract, warranty, tort, statute, regulation, ordinance, or any
                            other legal or equitable basis. “Dispute” will be given the broadest possible meaning
                            allowable under law.</p>
                    </div>
                    <div class="features-text">
                        <h4>Notice of Dispute</h4>
                        <p style="color: #444444;">In the event of a dispute, you or digiTax must give the other a
                            Notice of Dispute, which is a written statement that sets forth the name, address, and
                            contact information of the party giving it, the facts giving rise to the dispute, and the
                            relief requested. You must send any Notice of Dispute via email to: support@digitax.com.bd.
                            digiTax will send any Notice of Dispute to you by mail to your address if we have it, or
                            otherwise to your email address. You and digiTax will attempt to resolve any dispute through
                            informal negotiation within sixty (60) days from the date the Notice of Dispute is sent.
                            After sixty (60) days, you or digiTax may commence arbitration.</p>
                    </div>
                    <div class="features-text">
                        <h4>Submissions and Privacy</h4>
                        <p style="color: #444444;">In the event that you submit or post any ideas, creative suggestions,
                            designs, photographs, information, advertisements, data or proposals, including ideas for
                            new or improved products, services, features, technologies or promotions, you expressly
                            agree that such submissions will automatically be treated as non-confidential and
                            non-proprietary and will become the sole property of digiTax without any compensation or
                            credit to you whatsoever. digiTax and its affiliates shall have no obligations with respect
                            to such submissions or posts and may use the ideas contained in such submissions or posts
                            for any purposes in any medium in perpetuity, including, but not limited to, developing,
                            manufacturing, and marketing products and services using such ideas.</p>
                    </div>
                    <div class="features-text">
                        <h4>Promotions</h4>
                        <p style="color: #444444;">digiTax may, from time to time, include contests, promotions,
                            sweepstakes, or other activities (“Promotions”) that require you to submit material or
                            information concerning yourself. Please note that all Promotions may be governed by separate
                            rules that may contain certain eligibility requirements, such as restrictions as to age and
                            geographic location. You are responsible to read all Promotions rules to determine whether
                            or not you are eligible to participate. If you enter any Promotion, you agree to abide by
                            and to comply with all Promotions Rules.</p>
                        <p style="color: #444444;">Additional terms and conditions may apply to purchases of goods or
                            services on or through the Services, which terms and conditions are made a part of this
                            Agreement by this reference.</p>
                    </div>
                    <div class="features-text">
                        <h4>Typographical Errors</h4>
                        <p style="color: #444444;">In the event a product and/or service is listed at an incorrect price
                            or with incorrect information due to typographical error, we shall have the right to refuse
                            or cancel any orders placed for the product and/or service listed at the incorrect price. We
                            shall have the right to refuse or cancel any such order whether or not the order has been
                            confirmed and your credit card charged. If your credit card has already been charged for the
                            purchase and your order is canceled, we shall immediately issue a credit to your credit card
                            account or other payment accounts in the amount of the charge.</p>
                    </div>
                    <div class="features-text">
                        <h4>Miscellaneous</h4>
                        <p style="color: #444444;">If for any reason a court of competent jurisdiction finds any
                            provision or portion of these Terms & Conditions to be unenforceable, the remainder of these
                            Terms & Conditions will continue in full force and effect. Any waiver of any provision of
                            these Terms & Conditions will be effective only if in writing and signed by an authorized
                            representative of digiTax. digiTax will be entitled to injunctive or other equitable relief
                            (without the obligations of posting any bond or surety) in the event of any breach or
                            anticipatory breach by you. digiTax operates and controls the digiTax Service from its
                            offices in Bangladesh. The Service is not intended for distribution to or use by any person
                            or entity in any jurisdiction or country where such distribution or use would be contrary to
                            law or regulation. Accordingly, those persons who choose to access the digiTax Service from
                            other locations do so on their own initiative and are solely responsible for compliance with
                            local laws, if and to the extent local laws are applicable. These Terms & Conditions (which
                            include and incorporate the digiTax Privacy Policy) contain the entire understanding, and
                            supersedes all prior understandings, between you and digiTax concerning its subject matter,
                            and cannot be changed or modified by you. The section headings used in this Agreement are
                            for convenience only and will not be given any legal import.</p>
                    </div>
                    <div class="features-text">
                        <h4>Disclaimer</h4>
                        <p style="color: #444444;">digiTax is not responsible for any content, code or any other
                            imprecision.</p>
                        <p style="color: #444444;">digiTax does not provide warranties or guarantees.</p>
                        <p style="color: #444444;">In no event shall digiTax be liable for any special, direct,
                            indirect, consequential, or incidental damages or any damages whatsoever, whether in an
                            action of contract, negligence or other torts, arising out of or in connection with the use
                            of the Service or the contents of the Service. digiTax reserves the right to make additions,
                            deletions, or modifications to the contents of the Service at any time without prior notice.
                        </p>
                        <p style="color: #444444;">The digiTax Service and its contents are provided "as is" and "as
                            available" without any warranty or representations of any kind, whether express or implied.
                            digiTax is a distributor and not a publisher of the content supplied by third parties; as
                            such, digiTax exercises no editorial control over such content and makes no warranty or
                            representation as to the accuracy, reliability or currency of any information, content,
                            service or merchandise provided through or accessible via the digiTax Service. Without
                            limiting the foregoing, digiTax specifically disclaims all warranties and representations in
                            any content transmitted on or in connection with the digiTax Service or on sites that may
                            appear as links on the digiTax Service, or in the products provided as a part of, or
                            otherwise in connection with, the digiTax Service, including without limitation any
                            warranties of merchantability, fitness for a particular purpose or non-infringement of third
                            party rights. No oral advice or written information given by digiTax or any of its
                            affiliates, employees, officers, directors, agents, or the like will create a warranty.
                            Price and availability information is subject to change without notice. Without limiting the
                            foregoing, digiTax does not warrant that the digiTax Service will be uninterrupted,
                            uncorrupted, timely, or error-free.
                        </p>
                    </div>
                    <div class="features-text">
                        <h4>Contact Us</h4>
                        <p style="color: #444444;">Don't hesitate to contact us if you have any questions.</p>
                        <p style="color: #444444;"> &bull; Via Email: support@digitax.com.bd</p>
                        <p style="color: #444444;"> &bull; Via Phone Number: 09643 224488</p>
                        <p style="color: #444444;"> &bull; Via this Link: https://digitax.com.bd/contact</p>
                        <p style="color: #444444;"> &bull; Via this Address: Desh Universal (Pvt.) Limited As-Salam
                            Tower
                            (Level 5), 82-83 Zoo Road,
                            Block-A, Section-2, Mirpur, Dhaka-1216</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->
