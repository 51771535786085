import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { fuseConfig } from 'app/fuse-config';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LayoutModule } from '../layout.module';
import { FuseAdminComponent } from './fuseadmin.component';

@NgModule({
    declarations: [
        FuseAdminComponent
    ],
    imports     : [

        
       // Material moment date module
    MatMomentDateModule,
    MatProgressSpinnerModule,
    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    NgxSpinnerModule,

    LayoutModule
    ],
    exports     : [
        FuseAdminComponent
    ]
})

export class FuseAdminModule
{
}
