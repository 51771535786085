import { Component, HostListener, Input } from '@angular/core';


import { HouseholdUtilityService } from './HouseholdUtility.service';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { AfterViewChecked, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/services/eventemiter.service';
import Utils from 'app/util/utils';
import * as lodash from 'lodash';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from 'app/auth';




@Component({
    selector: 'HouseholdUtility',
    templateUrl: './HouseholdUtility.component.html',
    styleUrls: ['./HouseholdUtility.component.scss']
})
export class HouseholdUtilityComponent
{
    getData: any;
    payLoad: any;
    otherArray: any;
    PhoneArray: any;
    GasArray: any;
    electricityArray: any;
    showCancel: boolean;
    electricityForm: FormGroup;
    gasForm: FormGroup;
    phoneForm: FormGroup;
    otherForm: FormGroup;
    lang = '';
    categoryId = '4';
    subCategoryIdElectricity = '7';
    subCategoryIdGas = '8';
    subCategoryIdPhone = '9';
    subCategoryIdOther = '10';
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    IsCurrentYear: any;
    readonly = false;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private _formControlService: HouseholdUtilityService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _authService: AuthenticationService,
        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,
    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Household & Utility Expenses" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Household & Utility Expenses" }
        ]);
        this.setTitle("digiTax | Household & Utility Expenses");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);


        this.electricityForm = this.fb.group({
            electricityItems: this.fb.array([this.newElectricityItem()]),
        });

        this.gasForm = this.fb.group({
            gasItems: this.fb.array([this.newGasItem()]),
        });

        this.phoneForm = this.fb.group({
            phoneItems: this.fb.array([this.newPhoneItem()]),
        });

        this.otherForm = this.fb.group({
            otherItems: this.fb.array([this.newOtherItem()]),
        });

        this.lang = localStorage.getItem('selectedLanguage');

        this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

        this._formControlService.getControlJson(this.categoryId).subscribe((data: any) =>
        {
            this.getData = data.data;
            if (Utils.isEmptyObject(data.data))
            {
                this.electricityForm = this.fb.group({
                    electricityItems: this.fb.array([this.newElectricityItem()]),
                });

                this.gasForm = this.fb.group({
                    gasItems: this.fb.array([this.newGasItem()]),
                });

                this.phoneForm = this.fb.group({
                    phoneItems: this.fb.array([this.newPhoneItem()]),
                });

                this.otherForm = this.fb.group({
                    otherItems: this.fb.array([this.newOtherItem()]),
                });

                if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
                {
                    this.readonly = true;
                }
                else
                {
                    this.readonly = false;
                }

            }
            else
            {
                let tempElectricityData = [];
                let tempGasData = [];
                let tempPhoneData = [];
                let tempOtherData = [];

                this.electricityArray = lodash.filter(this.getData, { "ExpSubCategory": parseInt(this.subCategoryIdElectricity) });

                if (!Utils.isEmptyObject(this.electricityArray))
                {
                    this.electricityArray.forEach((item, index) =>
                    {
                        tempElectricityData.push(this.getElectricityItem(item));
                    });
                    this.electricityForm = this.fb.group({
                        electricityItems: this.fb.array(tempElectricityData),
                    });
                }
                else
                {
                    this.electricityForm = this.fb.group({
                        electricityItems: this.fb.array([this.newElectricityItem()]),
                    });
                }

                this.GasArray = lodash.filter(this.getData, { "ExpSubCategory": parseInt(this.subCategoryIdGas) });

                if (!Utils.isEmptyObject(this.GasArray))
                {
                    this.GasArray.forEach((item, index) =>
                    {
                        tempGasData.push(this.getGasItem(item));
                    });
                    this.gasForm = this.fb.group({
                        gasItems: this.fb.array(tempGasData),
                    });
                }
                else
                {
                    this.gasForm = this.fb.group({
                        gasItems: this.fb.array([this.newGasItem()]),
                    });
                }

                this.PhoneArray = lodash.filter(this.getData, { "ExpSubCategory": parseInt(this.subCategoryIdPhone) });

                if (!Utils.isEmptyObject(this.PhoneArray))
                {
                    this.PhoneArray.forEach((item, index) =>
                    {
                        tempPhoneData.push(this.getPhoneItem(item));
                    });
                    this.phoneForm = this.fb.group({
                        phoneItems: this.fb.array(tempPhoneData),
                    });
                }
                else
                {
                    this.phoneForm = this.fb.group({
                        phoneItems: this.fb.array([this.newPhoneItem()]),
                    });
                }

                this.otherArray = lodash.filter(this.getData, { "ExpSubCategory": parseInt(this.subCategoryIdOther) });


                if (!Utils.isEmptyObject(this.otherArray))
                {
                    this.otherArray.forEach((item, index) =>
                    {
                        tempOtherData.push(this.getOtherItem(item));
                    });
                    this.otherForm = this.fb.group({
                        otherItems: this.fb.array(tempOtherData),
                    });
                }
                else
                {
                    this.otherForm = this.fb.group({
                        otherItems: this.fb.array([this.newOtherItem()]),
                    });
                }
                if (this.otherArray == null && this.PhoneArray == null && this.GasArray == null && this.electricityArray == null)
                {
                    this.showCancel = false;
                }
                else { this.showCancel = true; }

                if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
                {
                    this.readonly = true;
                }
                else
                {
                    this.readonly = false;
                }
            }
            if (data != null)
            {
                this._spinner.hide();
            }
        });

        if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
        {
            this.readonly = true;
        }
        else
        {
            this.readonly = false;
        }
    }

    ngOnInit(): void
    {

    }


    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngAfterViewChecked()
    {
        this.cdr.detectChanges();
    }

    electricityItems(): FormArray
    {
        return this.electricityForm.get("electricityItems") as FormArray
    }

    gasItems(): FormArray
    {
        return this.gasForm.get("gasItems") as FormArray
    }

    phoneItems(): FormArray
    {
        return this.phoneForm.get("phoneItems") as FormArray
    }

    otherItems(): FormArray
    {
        return this.otherForm.get("otherItems") as FormArray
    }

    getElectricityItem(item): FormGroup
    {
        return this.fb.group({
            Id: item.Id,
            ALEId: item.ALEId,
            SlNo: item.SlNo,
            ExpCategory: item.ExpCategory,
            ExpSubCategory: item.ExpSubCategory,
            Amount: item.Amount,
            Comment: item.Comment,
            Description: item.Description,
        })
    }

    getGasItem(item): FormGroup
    {
        return this.fb.group({
            Id: item.Id,
            ALEId: item.ALEId,
            SlNo: item.SlNo,
            ExpCategory: item.ExpCategory,
            ExpSubCategory: item.ExpSubCategory,
            Amount: item.Amount,
            Comment: item.Comment,
            Description: item.Description,
        })
    }

    getPhoneItem(item): FormGroup
    {
        return this.fb.group({
            Id: item.Id,
            ALEId: item.ALEId,
            SlNo: item.SlNo,
            ExpCategory: item.ExpCategory,
            ExpSubCategory: item.ExpSubCategory,
            Amount: item.Amount,
            Comment: item.Comment,
            Description: item.Description,
        })
    }

    getOtherItem(item): FormGroup
    {
        return this.fb.group({
            Id: item.Id,
            ALEId: item.ALEId,
            SlNo: item.SlNo,
            ExpCategory: item.ExpCategory,
            ExpSubCategory: item.ExpSubCategory,
            Amount: item.Amount,
            Comment: item.Comment,
            Description: item.Description,
        })
    }

    newElectricityItem(): FormGroup
    {
        return this.fb.group({
            SlNo: '',
            ExpCategory: this.categoryId,
            ExpSubCategory: this.subCategoryIdElectricity,
            Amount: '',
            Comment: '',
            Description: '',
        })
    }

    newGasItem(): FormGroup
    {
        return this.fb.group({
            SlNo: '',
            ExpCategory: this.categoryId,
            ExpSubCategory: this.subCategoryIdGas,
            Amount: '',
            Comment: '',
            Description: '',
        })
    }

    newPhoneItem(): FormGroup
    {
        return this.fb.group({
            SlNo: '',
            ExpCategory: this.categoryId,
            ExpSubCategory: this.subCategoryIdPhone,
            Amount: '',
            Comment: '',
            Description: '',
        })
    }

    newOtherItem(): FormGroup
    {
        return this.fb.group({
            SlNo: '',
            ExpCategory: this.categoryId,
            ExpSubCategory: this.subCategoryIdOther,
            Amount: '',
            Comment: '',
            Description: '',
        })
    }

    addElectricityItem()
    {
        this.electricityItems().push(this.newElectricityItem());
    }

    addGasItem()
    {
        this.gasItems().push(this.newGasItem());
    }

    addPhoneItem()
    {
        this.phoneItems().push(this.newPhoneItem());
    }

    addOtherItem()
    {
        this.otherItems().push(this.newOtherItem());
    }

    removeElectricityItem(i: number)
    {
        this.electricityItems().removeAt(i);
    }

    removeGasItem(i: number)
    {
        this.gasItems().removeAt(i);
    }

    removePhoneItem(i: number)
    {
        this.phoneItems().removeAt(i);
    }

    removeOtherItem(i: number)
    {
        this.otherItems().removeAt(i);
    }

    mySubmitExit(param): void
    {
        // debugger;
        if (param == 'next' || param == 'more')
        {
            this.OnSubmit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }

    OnSubmit(param): void
    {
        this._spinner.show();
        let electricityItems = this.electricityForm.value.electricityItems;
        electricityItems.forEach((item, index) =>
        {
            let i = index + 1;
            if (item.Amount == "" || item.Amount == "0" || isNaN(Number(item.Amount)) == true)
            {
                item.Amount = 0
            }
            item.Id != null ? item.Id = item.Id : item.Id = 0
            item.ALEId != null ? item.ALEId = item.ALEId : item.ALEId = 0
            item.SlNo = i.toString();
        });

        let gasItems = this.gasForm.value.gasItems;
        gasItems.forEach((item, index) =>
        {
            let i = index + 1;
            if (item.Amount == "" || item.Amount == "0" || isNaN(Number(item.Amount)) == true)
            {
                item.Amount = 0
            }
            item.Id != null ? item.Id = item.Id : item.Id = 0
            item.ALEId != null ? item.ALEId = item.ALEId : item.ALEId = 0
            item.SlNo = i.toString();
        });

        let phoneItems = this.phoneForm.value.phoneItems;
        phoneItems.forEach((item, index) =>
        {
            let i = index + 1;
            if (item.Amount == "" || item.Amount == "0" || isNaN(Number(item.Amount)) == true)
            {
                item.Amount = 0
            }
            item.Id != null ? item.Id = item.Id : item.Id = 0
            item.ALEId != null ? item.ALEId = item.ALEId : item.ALEId = 0
            item.SlNo = i.toString();
        });

        let otherItems = this.otherForm.value.otherItems;
        otherItems.forEach((item, index) =>
        {
            let i = index + 1;
            if (item.Amount == "" || item.Amount == "0" || isNaN(Number(item.Amount)) == true)
            {
                item.Amount = 0
            }
            item.Id != null ? item.Id = item.Id : item.Id = 0
            item.ALEId != null ? item.ALEId = item.ALEId : item.ALEId = 0
            item.SlNo = i.toString();
        });

        let ElcGas = electricityItems.concat(gasItems);
        let PhoOth = phoneItems.concat(otherItems);

        let stringed = ElcGas.concat(PhoOth);

        stringed = stringed.filter(item => item.Amount != 0);

        // if (stringed.length === 0)
        // {
        //     this._spinner.hide();
        //     this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_ENTER_AMOUNT : bangla.data.COMMON.BUTTON_ENTER_AMOUNT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //         verticalPosition: 'top',
        //         duration: 2000
        //     });
        // }
        // else
        // {
        this._formControlService.submitExpense(stringed, this.categoryId)
            .subscribe((data: any) =>
            {
                if (data != null)
                {
                    this._spinner.hide();
                }

                if (data.isError === false)
                {
                    console.log('submit succesfull');
                }
                else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                if (data.isError === false && data.responseCode == "200")
                {

                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    if (param == 'next')
                    {
                        this._router.navigate(['/ChildrensEducation']);
                    }
                    if (param == 'exit')
                    {
                        this._authService.Logout();
                    }
                }
            }, (error: any) =>
            {
                this._spinner.hide();
                console.log(error, 'error');
            });
        // }
    }

    ConfirmLeave(url): void
    {
        if (this.electricityForm.dirty ||
            this.gasForm.dirty ||
            this.phoneForm.dirty ||
            this.otherForm.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this._router.navigate([url]);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            this._router.navigate([url]);
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
