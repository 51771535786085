import { Component, HostListener, Input } from '@angular/core';


import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SalaryService } from './Salary.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { PrivateSalaryModel } from './p-salary.Model';
import { GovtSalaryModel } from './g-salary.Model';
import { SalaryEmitterService } from 'app/services/salaryEmiter.service';
import { Subscription } from 'rxjs';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import Utils from 'app/util/utils';
import { AuthenticationService } from 'app/auth';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';


@Component({
    selector: 'Salary',
    templateUrl: './Salary.component.html',
    styleUrls: ['./Salary.component.scss']
})
export class SalaryComponent
{

    @Input() controls: BaseControl<string>[] = [];
    pform: FormGroup;
    gform: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    isPrivate = true;
    message: string;
    salaryType: string;
    subscription: Subscription;
    isDashboardUpdate: boolean;
    CheckIfDisabledPerson: any;
    formcontrols$: Observable<BaseControl<string>[]>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    /**
     * Constructor
     * @param {MatSnackBar} _matSnackBar
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: SalaryService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private dataService: SalaryEmitterService,
        private sidePanelDeleteService: SidePanelDeleteService,
        private back: BackButtonService,
    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Income From Salary" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Income From Salary" }
        ]);
        this.setTitle("digiTax | Income From Salary");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');

        this.lang = localStorage.getItem('selectedLanguage');

        this.pform = this._formBuilder.group(new PrivateSalaryModel());
        this.gform = this._formBuilder.group(new GovtSalaryModel());
        this.getDataBlank();
        this.getData();
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }


    getDataBlank(): void
    {
        this._formControlService.getPageData("0").subscribe((data: any) =>
        {
            if (data.data != null)
            {
                this._spinner.hide();
                this.pform.patchValue(data.data);
                this.gform.patchValue(data.data);
            }
        })
    }

    getData(): void
    {

        if (this.id != null)
        {
            this._formControlService.getPageData(this.id).subscribe((data: any) =>
            {
                if (data.data != null)
                {
                    this._spinner.hide();
                    this.pform.patchValue(data.data);
                }
            })
        }
    }
    ngOnDestroy()
    {

    }

    ngOnInit(): void
    {

    }

    setPrivate(): void
    {
        this.isPrivate = true;
        let PrivateBtn: HTMLElement = document.getElementById("PrivateSet") as HTMLElement;

        if (!PrivateBtn.classList.contains('mySelected'))
        {
            PrivateBtn.classList.add('mySelected');
        }

        let GovtBtn: HTMLElement = document.getElementById("GovtSet") as HTMLElement;

        if (GovtBtn.classList.contains('mySelected'))
        {
            GovtBtn.classList.remove('mySelected');
        }
        this.gform.reset();
    }

    dsetPrivate(): void
    {
        this.isPrivate = false;
        let PrivateBtn: HTMLElement = document.getElementById("PrivateSet") as HTMLElement;

        if (PrivateBtn.classList.contains('mySelected'))
        {
            PrivateBtn.classList.remove('mySelected');
        }

        let GovtBtn: HTMLElement = document.getElementById("GovtSet") as HTMLElement;

        if (!GovtBtn.classList.contains('mySelected'))
        {
            GovtBtn.classList.add('mySelected');
        }
        this.pform.reset();
    }

    ConfirmLeave(url): void
    {
        if (this.pform.dirty || this.gform.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    if (url == '/PersonalizeReturn')
                    {
                        this._router.navigate([url]);
                    } else
                    {
                        this.improvisedIMenu(url);
                    }
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            if (url == '/PersonalizeReturn')
            {
                this._router.navigate([url]);
            } else
            {
                this.improvisedIMenu(url);
            }
        }
    }

    mySubmitExit_P(param): void
    {
        if (param == 'next' || param == 'more')
        {
            this.On_P_Submit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.On_P_Submit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }

    mySubmitExit_G(param): void
    {
        if (param == 'next' || param == 'more')
        {
            this.On_G_Submit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.On_G_Submit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }


    On_P_Submit(param): void
    {
        debugger;
        if (!this.pform.valid)
        {
            this.pform.markAllAsTouched();
        }
        this._spinner.show();
        this.id = this._route.snapshot.paramMap.get('id');

        let obj = null;
        if (this.isPrivate == true)
        {
            obj = this.pform.getRawValue();
        }
        else
        {
            obj = this.gform.getRawValue();
        }

        if (this.isPrivate == true)
        {
            if (obj.IsReceivedAnyTransportFromEmployer == "")
            {
                obj.IsReceivedAnyTransportFromEmployer = false
            }
            if (obj.IsDisabledPerson == "")
            {
                obj.IsDisabledPerson = false
            }
            if (obj.IsShareHolder == "")
            {
                obj.IsShareHolder = false
            }
            if (obj.IsMentionedInEmploymentContract == "")
            {
                obj.IsMentionedInEmploymentContract = false
            }
            if (obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer == "")
            {
                obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer = false
            }
            if (obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer == "")
            {
                obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer = false
            }

            if (isNaN(Number(obj.BasicPayAmount)) == true)
            {
                obj.BasicPayAmount = "";
            }

            if (isNaN(Number(obj.SpecialPayAmount)) == true)
            {
                obj.SpecialPayAmount = "";
            }

            if (isNaN(Number(obj.DearnessAllowanceAmount)) == true)
            {
                obj.DearnessAllowanceAmount = "";
            }

            if (isNaN(Number(obj.ConveyanceAllowanceAmount)) == true)
            {
                obj.ConveyanceAllowanceAmount = "";
            }

            if (isNaN(Number(obj.HouseRentAllowanceAmount)) == true)
            {
                obj.HouseRentAllowanceAmount = "";
            }

            if (isNaN(Number(obj.MedicalAllowanceAmount)) == true)
            {
                obj.MedicalAllowanceAmount = "";
            }

            if (isNaN(Number(obj.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount)) == true)
            {
                obj.ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount = "";
            }

            if (isNaN(Number(obj.LeaveFairAssistanceStaffAmount)) == true)
            {
                obj.LeaveFairAssistanceStaffAmount = "";
            }

            if (isNaN(Number(obj.LeaveEncashmentAmount)) == true)
            {
                obj.LeaveEncashmentAmount = "";
            }

            if (isNaN(Number(obj.AllowanceForSupportStaffAmount)) == true)
            {
                obj.AllowanceForSupportStaffAmount = "";
            }

            if (isNaN(Number(obj.AllowanceForSupportStaffExempted)) == true)
            {
                obj.AllowanceForSupportStaffExempted = "";
            }

            if (isNaN(Number(obj.HonorariumOrRewardAmount)) == true)
            {
                obj.HonorariumOrRewardAmount = "";
            }

            if (isNaN(Number(obj.OvertimeAllowanceAmount)) == true)
            {
                obj.OvertimeAllowanceAmount = "";
            }

            if (isNaN(Number(obj.BonusAmount)) == true)
            {
                obj.BonusAmount = "";
            }

            if (isNaN(Number(obj.OtherAllowancesAmount)) == true)
            {
                obj.OtherAllowancesAmount = "";
            }

            if (isNaN(Number(obj.EmployersContributionProvidentFundAmount)) == true)
            {
                obj.EmployersContributionProvidentFundAmount = "";
            }

            if (isNaN(Number(obj.RateOfInterestOfProvidentFund)) == true)
            {
                obj.RateOfInterestOfProvidentFund = "";
            }

            if (isNaN(Number(obj.InterestAccruedProvidentFundRateOfInterestAmount)) == true)
            {
                obj.InterestAccruedProvidentFundRateOfInterestAmount = "";
            }

            if (isNaN(Number(obj.EmployerPaidRentalAmount)) == true)
            {
                obj.EmployerPaidRentalAmount = "";
            }

            if (isNaN(Number(obj.EmployeePaidRentalAmount)) == true)
            {
                obj.EmployeePaidRentalAmount = "";
            }

            if (isNaN(Number(obj.OthersAmount)) == true)
            {
                obj.OthersAmount = "";
            }

            if (isNaN(Number(obj.ArrearPayAmount)) == true)
            {
                obj.ArrearPayAmount = "";
            }

            if (isNaN(Number(obj.ReceivedFromGratuityFundAmount)) == true)
            {
                obj.ReceivedFromGratuityFundAmount = "";
            }

            if (isNaN(Number(obj.WorkersProfitParticipationFundAmount)) == true)
            {
                obj.WorkersProfitParticipationFundAmount = "";
            }

            if (isNaN(Number(obj.PensionAmount)) == true)
            {
                obj.PensionAmount = "";
            }

            if (isNaN(Number(obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount)) == true)
            {
                obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = "";
            }

            if (isNaN(Number(obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount)) == true)
            {
                obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = "";
            }


            if (isNaN(Number(obj.BIN)) == true || obj.BIN == null)
            {
                obj.BIN = "";
            }
            obj.IsGovtStaff = false;
        }
        else
        {
            if (obj.IsReceivedAnyTransportFromEmployer == "")
            {
                obj.IsReceivedAnyTransportFromEmployer = false
            }
            if (obj.IsDisabledPerson == "")
            {
                obj.IsDisabledPerson = false
            }
            if (obj.IsShareHolder == "")
            {
                obj.IsShareHolder = false
            }
            if (obj.IsMentionedInEmploymentContract == "")
            {
                obj.IsMentionedInEmploymentContract = false
            }
            if (obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer == "")
            {
                obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer = false
            }
            if (obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer == "")
            {
                obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer = false
            }

            if (isNaN(Number(obj.BasicPayAmount)) == true)
            {
                obj.BasicPayAmount = "";
            }

            if (isNaN(Number(obj.SpecialPayAmount)) == true)
            {
                obj.SpecialPayAmount = "";
            }

            if (isNaN(Number(obj.DearnessAllowanceAmount)) == true)
            {
                obj.DearnessAllowanceAmount = "";
            }

            if (isNaN(Number(obj.ConveyanceAllowanceAmount)) == true)
            {
                obj.ConveyanceAllowanceAmount = "";
            }

            if (isNaN(Number(obj.HouseRentAllowanceAmount)) == true)
            {
                obj.HouseRentAllowanceAmount = "";
            }

            if (isNaN(Number(obj.MedicalAllowanceAmount)) == true)
            {
                obj.MedicalAllowanceAmount = "";
            }

            if (isNaN(Number(obj.AllowanceForSupportStaffAmount)) == true)
            {
                obj.AllowanceForSupportStaffAmount = "";
            }

            if (isNaN(Number(obj.LeaveFairAssistanceStaffAmount)) == true)
            {
                obj.LeaveFairAssistanceStaffAmount = "";
            }

            if (isNaN(Number(obj.LeaveEncashmentAmount)) == true)
            {
                obj.LeaveEncashmentAmount = "";
            }

            if (isNaN(Number(obj.HonorariumOrRewardAmount)) == true)
            {
                obj.HonorariumOrRewardAmount = "";
            }

            if (isNaN(Number(obj.OvertimeAllowanceAmount)) == true)
            {
                obj.OvertimeAllowanceAmount = "";
            }

            if (isNaN(Number(obj.BonusAmount)) == true)
            {
                obj.BonusAmount = "";
            }

            if (isNaN(Number(obj.OtherAllowancesAmount)) == true)
            {
                obj.OtherAllowancesAmount = "";
            }

            if (isNaN(Number(obj.EmployersContributionProvidentFundAmount)) == true)
            {
                obj.EmployersContributionProvidentFundAmount = "";
            }

            if (isNaN(Number(obj.InterestAccruedProvidentFundRateOfInterestAmount)) == true)
            {
                obj.InterestAccruedProvidentFundRateOfInterestAmount = "";
            }

            if (isNaN(Number(obj.DeemedIncomeTransportAmount)) == true)
            {
                obj.DeemedIncomeTransportAmount = "";
            }

            if (isNaN(Number(obj.EmployerPaidRentalAmount)) == true)
            {
                obj.EmployerPaidRentalAmount = "";
            }

            if (isNaN(Number(obj.FestivalBonusAmount)) == true)
            {
                obj.FestivalBonusAmount = "";
            }

            if (isNaN(Number(obj.BengaliNewYearBonusAmount)) == true)
            {
                obj.BengaliNewYearBonusAmount = "";
            }

            if (isNaN(Number(obj.FestivalAllowanceAmount)) == true)
            {
                obj.FestivalAllowanceAmount = "";
            }

            if (isNaN(Number(obj.PensionAmount)) == true)
            {
                obj.PensionAmount = "";
            }

            if (isNaN(Number(obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount)) == true)
            {
                obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = "";
            }

            if (isNaN(Number(obj.OthersAmount)) == true)
            {
                obj.OthersAmount = "";
            }

            if (isNaN(Number(obj.ArrearPayAmount)) == true)
            {
                obj.ArrearPayAmount = "";
            }

            if (isNaN(Number(obj.BIN)) == true || obj.BIN == null)
            {
                obj.BIN = "";
            }


            obj.IsGovtStaff = true;
        }

        let stringed = JSON.stringify(obj);

        console.log(stringed, 'stringed');




        if (obj.TotalAmount == null || obj.TotalAmount == 0 || obj.TotalAmount == 0.00 || obj.TotalAmount == "" || obj.TotalAmount == "0" || obj.TotalAmount == "0.00")
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_ENTER_AMOUNT : bangla.data.COMMON.BUTTON_ENTER_AMOUNT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            // if (obj.OrganizationName == null || obj.OrganizationName == "")
            // {
            //     this._spinner.hide();
            //     this._matSnackBar.open(this.lang == 'en' ? english.data.SALARY.R6 : bangla.data.SALARY.R6, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
            //         verticalPosition: 'top',
            //         duration: 2000
            //     });
            // }
            // else
            // {

            if (obj.BIN.length != 9 && obj.BIN != "")
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.SALARY.R7 : bangla.data.SALARY.R7, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (stringed != null)
                {
                    this._formControlService.submitPrivateSalaryJson(stringed, this.id)
                        .subscribe((data: any) =>
                        {
                            if (data != null)
                            {
                                this._spinner.hide();
                            }

                            if (data.isError === false)
                            {
                                console.log('submit succesfull');
                            }
                            else
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            if (data.isError === false && data.responseCode == "200")
                            {
                                if (this.id != null)
                                {
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                }
                                else
                                {
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                }
                                if (param == 'next')
                                {
                                    this.improvisedIMenu(param);
                                }
                                if (param == 'more')
                                {
                                    this.sidePanelDeleteService.changeMessage(true);
                                    this.gform.reset();
                                    this.pform.reset();
                                }
                                if (param == 'exit')
                                {
                                    this._authService.Logout();
                                }

                            }
                        }, (error: any) =>
                        {
                            console.log(error, 'error');
                        });
                }
            }
        }
    }
    // }

    On_G_Submit(param): void
    {
        debugger;
        if (!this.gform.valid)
        {
            this.gform.markAllAsTouched();
        }
        this._spinner.show();
        this.id = this._route.snapshot.paramMap.get('id');

        let obj = this.gform.getRawValue();

        if (obj.IsReceivedAnyTransportFromEmployer == "")
        {
            obj.IsReceivedAnyTransportFromEmployer = false
        }
        if (obj.IsDisabledPerson == "")
        {
            obj.IsDisabledPerson = false
        }
        if (obj.IsShareHolder == "")
        {
            obj.IsShareHolder = false
        }
        if (obj.IsMentionedInEmploymentContract == "")
        {
            obj.IsMentionedInEmploymentContract = false
        }
        if (obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer == "")
        {
            obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer = false
        }
        if (obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer == "")
        {
            obj.IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer = false
        }

        if (isNaN(Number(obj.BasicPayAmount)) == true)
        {
            obj.BasicPayAmount = "";
        }

        if (isNaN(Number(obj.SpecialPayAmount)) == true)
        {
            obj.SpecialPayAmount = "";
        }

        if (isNaN(Number(obj.DearnessAllowanceAmount)) == true)
        {
            obj.DearnessAllowanceAmount = "";
        }

        if (isNaN(Number(obj.ConveyanceAllowanceAmount)) == true)
        {
            obj.ConveyanceAllowanceAmount = "";
        }

        if (isNaN(Number(obj.HouseRentAllowanceAmount)) == true)
        {
            obj.HouseRentAllowanceAmount = "";
        }

        if (isNaN(Number(obj.MedicalAllowanceAmount)) == true)
        {
            obj.MedicalAllowanceAmount = "";
        }

        if (isNaN(Number(obj.AllowanceForSupportStaffAmount)) == true)
        {
            obj.AllowanceForSupportStaffAmount = "";
        }

        if (isNaN(Number(obj.LeaveFairAssistanceStaffAmount)) == true)
        {
            obj.LeaveFairAssistanceStaffAmount = "";
        }

        if (isNaN(Number(obj.LeaveEncashmentAmount)) == true)
        {
            obj.LeaveEncashmentAmount = "";
        }

        if (isNaN(Number(obj.HonorariumOrRewardAmount)) == true)
        {
            obj.HonorariumOrRewardAmount = "";
        }

        if (isNaN(Number(obj.OvertimeAllowanceAmount)) == true)
        {
            obj.OvertimeAllowanceAmount = "";
        }

        if (isNaN(Number(obj.BonusAmount)) == true)
        {
            obj.BonusAmount = "";
        }

        if (isNaN(Number(obj.OtherAllowancesAmount)) == true)
        {
            obj.OtherAllowancesAmount = "";
        }

        if (isNaN(Number(obj.EmployersContributionProvidentFundAmount)) == true)
        {
            obj.EmployersContributionProvidentFundAmount = "";
        }

        if (isNaN(Number(obj.InterestAccruedProvidentFundRateOfInterestAmount)) == true)
        {
            obj.InterestAccruedProvidentFundRateOfInterestAmount = "";
        }

        if (isNaN(Number(obj.DeemedIncomeTransportAmount)) == true)
        {
            obj.DeemedIncomeTransportAmount = "";
        }

        if (isNaN(Number(obj.EmployerPaidRentalAmount)) == true)
        {
            obj.EmployerPaidRentalAmount = "";
        }

        if (isNaN(Number(obj.FestivalBonusAmount)) == true)
        {
            obj.FestivalBonusAmount = "";
        }

        if (isNaN(Number(obj.BengaliNewYearBonusAmount)) == true)
        {
            obj.BengaliNewYearBonusAmount = "";
        }

        if (isNaN(Number(obj.FestivalAllowanceAmount)) == true)
        {
            obj.FestivalAllowanceAmount = "";
        }

        if (isNaN(Number(obj.PensionAmount)) == true)
        {
            obj.PensionAmount = "";
        }

        if (isNaN(Number(obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount)) == true)
        {
            obj.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = "";
        }

        if (isNaN(Number(obj.OthersAmount)) == true)
        {
            obj.OthersAmount = "";
        }

        if (isNaN(Number(obj.ArrearPayAmount)) == true)
        {
            obj.ArrearPayAmount = "";
        }

        if (isNaN(Number(obj.BIN)) == true || obj.BIN == null)
        {
            obj.BIN = "";
        }

        obj.IsGovtStaff = true;

        let stringed = JSON.stringify(obj);



        if (obj.TotalAmount == null || obj.TotalAmount == 0 || obj.TotalAmount == 0.00 || obj.TotalAmount == "" || obj.TotalAmount == "0" || obj.TotalAmount == "0.00")
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_ENTER_AMOUNT : bangla.data.COMMON.BUTTON_ENTER_AMOUNT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            // if (obj.OrganizationName == null || obj.OrganizationName == "")
            // {
            //     this._spinner.hide();
            //     this._matSnackBar.open(this.lang == 'en' ? english.data.SALARY.R6 : bangla.data.SALARY.R6, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
            //         verticalPosition: 'top',
            //         duration: 2000
            //     });
            // }
            // else
            // {

            if (obj.BIN.length != 9 && obj.BIN != "")
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.SALARY.R7 : bangla.data.SALARY.R7, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (stringed != null)
                {
                    this._formControlService.submitPrivateSalaryJson(stringed, this.id)
                        .subscribe((data: any) =>
                        {
                            if (data != null)
                            {
                                this._spinner.hide();
                            }

                            if (data.isError === false)
                            {
                                console.log('submit succesfull');
                            }
                            else
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            if (data.isError === false && data.responseCode == "200")
                            {
                                if (this.id != null)
                                {
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                }
                                else
                                {
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                }
                                if (param == 'next')
                                {
                                    this.improvisedIMenu(param);
                                }
                                if (param == 'more')
                                {
                                    this.sidePanelDeleteService.changeMessage(true);
                                    this.gform.reset();
                                    this.pform.reset();
                                }
                                if (param == 'exit')
                                {
                                    this._authService.Logout();
                                }


                            }
                        }, (error: any) =>
                        {
                            console.log(error, 'error');
                        });
                }
            }
        }
    }
    // }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'income');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    nextIMenu(): void
    {
        let stepMenu = JSON.parse(localStorage.getItem('stepMenu'));
        let incomeMenu = '';
        let nextItem = '';
        let currentUrl = this._router.url;
        if (stepMenu)
        {
            incomeMenu = stepMenu.incomeMenu;
        }
        // console.log(incomeMenu, 'incomeMenu');
        // console.log(this._router.url, 'incomeMenu');
        // console.log(this._router.url.replace('/save',''), 'incomeMenu');
        // console.log(this._router.url.includes('/save'), 'incomeMenu');

        if (currentUrl.includes('/save'))
        {
            currentUrl = currentUrl.replace('/save', '');
        };

        const currentIndex = incomeMenu.indexOf(currentUrl);
        const nextIndex = (currentIndex + 1) % incomeMenu.length;
        nextItem = incomeMenu[nextIndex];
        // console.log(nextItem, 'nextItem');

        // debugger;
        // if (incomeMenu.includes(currentUrl))
        // {
        //     let index = incomeMenu.indexOf(currentUrl);
        //     if (index >= 0 && index < incomeMenu.length - 1)
        //     {
        //         nextItem = incomeMenu[index + 1];
        //     }
        //     nextItem = incomeMenu[index + 1];
        //     console.log(nextItem, 'nextItem');
        // }




    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }





}

// }

