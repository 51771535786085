import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class EventEmitterService
{
  isDashboardUpdate: boolean;
  isPanelUpdate: boolean;
  menuData: any;


  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() panelChange: EventEmitter<boolean> = new EventEmitter();
  // @Output() menu: EventEmitter<any> = new EventEmitter();

  constructor() { }

  emitDashboaordUpdate(values: boolean)
  {
    this.isDashboardUpdate = values;
    this.change.emit(this.isDashboardUpdate);
  }


  emitPanelUpdate(values: boolean)
  {
    this.isPanelUpdate = values;
    this.panelChange.emit(this.isPanelUpdate);
  }


  // emitMenuData(values: any)
  // {
  //   this.menuData = values;
  //   this.menu.emit(this.menuData);
  // }
}
