<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <div class="center">

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px;">{{ 'PROFILE.HEADER' | translate }}
            </span>
            <a [routerLink]="'/Profile/save'"
                mat-raised-button
                style="margin-right: 20px;"
                class="mat-button dupl"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="s-16 mr-sm-4">create</mat-icon>
                <span>{{ 'COMMON.BUTTON_EDIT' | translate }}</span>
            </a>

        </div>
        <div class="content p-24">
            <div class="mb-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row">
                <table class="simple invoice-table"
                    *ngIf="getData!=null"
                    fxLayout="column"
                    fxLayoutAlign="start">

                    <tr>
                        <td style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{ 'PROFILE.OrgName' | translate }} </div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{getData.OrgName}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{ 'PROFILE.ITPNo' | translate }} </div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{getData.ITPNo}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{ 'PROFILE.BarMembershipNo' | translate }} </div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{getData.BarMembershipNo}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{ 'PROFILE.Mobile' | translate }} </div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{getData.Mobile}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{ 'PROFILE.Email' | translate }} </div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{getData.Email}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{ 'PROFILE.Address2' | translate }} </div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{getData.Address2}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{ 'PROFILE.Address1' | translate }} </div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{getData.Address1}}</div>
                        </td>
                    </tr>

                </table>
            </div>
            <!-- <div class="header"
                style="padding-top: 10px;"
                fxLayoutAlign="space-between center">
                <div disabled
                    class="mat-button mat-button-base primary-A400 secondary-text">

                </div>

                <button mat-raised-button
                    [routerLink]="'/PersonalInfo'"
                    class="mat-button mat-button-base primary-A400 secondary-text accent">
                    {{ 'COMMON.BUTTON_NEXT' | translate }}
                </button>

            </div> -->
        </div>
    </div>
</div>
