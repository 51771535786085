import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { DocumentsComponent } from './Documents.component';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { DocumentsService } from './Documents.service';
import { FileDragNDropDirective } from './file-drag-n-drop.directive';
import { UploadDocumentDialog } from './upload-dialog/Dialog.component';
import { EditDocumentDialog } from './edit-dialog/edit-Dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { ViewDashboardGuard } from 'app/auth/viewDashboard.guard';

const routes = [
    {
        path: 'Documents',
        component: DocumentsComponent,
        canActivate: [AuthGuard, ViewDashboardGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        DocumentsComponent,
        UploadDocumentDialog,
        EditDocumentDialog,
        FileDragNDropDirective,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatPaginatorModule,

        MatTabsModule,

        MatBadgeModule,

        MatMenuModule,

        MatButtonModule,

        MatDialogModule,

        MatExpansionModule,

        TranslateModule,

        MatIconModule,

        MatFormFieldModule,

        MatInputModule,

        MatSnackBarModule,

        MatSlideToggleModule,

        MatSelectModule,

        MatTooltipModule,

        MatCheckboxModule,

        MatRadioModule,

        MatTableModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,
    ],
    exports: [
        DocumentsComponent
    ],
    providers: [

        DocumentsService
    ],
})

export class DocumentsModule
{
}
