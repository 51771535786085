import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Layouts } from 'app/enum/layouts.enum';

import { ContactComponent } from './contact.component';

const routes = [
    {
        path     : 'contact',
        component: ContactComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        ContactComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule        
    ],
    exports     : [
        ContactComponent
    ]
})

export class ContactModule
{
}
