import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { InterestOnSecuritiesClass } from './InterestOnSecurities.Class';

@Component({
  selector: 'InterestOnSecurities-form-control',
  templateUrl: './InterestOnSecurities-form-control.component.html',
  styleUrls: ['./InterestOnSecurities.component.scss']

})
export class InterestOnSecuritiesFormControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  pageLoaded: boolean;
  IsCurrentYear: any;

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }

  onSelect(event)
  {



    // let non1: HTMLElement = document.getElementsByClassName("nonSanchaypotro")[0] as HTMLElement;

    // let non2: HTMLElement = document.getElementsByClassName("nonSanchaypotro")[1] as HTMLElement;





    // let lang = localStorage.getItem('selectedLanguage');
    // let rs = '';
    // let gs = '';
    // lang == 'en' ? rs = 'Tax Deducted At Source' : rs = 'উৎসমূলে কাটা কর';
    // lang == 'en' ? gs = 'Commission or Interest' : gs = 'কমিশন বা সুদ';


    // if (event.value == "1")
    // {
    //   if (!non1.classList.contains('hidden'))
    //   {
    //     non1.classList.add('hidden');
    //     non1.classList.remove('block');
    //   }


    //   non2.firstChild.childNodes[0].childNodes[1].nodeValue = rs;



    //   this.form.patchValue({
    //     Description: "",
    //     ComissionInterestRate: "",
    //     NetIncome: "",
    //     Amount: "",
    //   })
    // }
    // else
    // {
    //   if (non1.classList.contains('hidden'))
    //   {
    //     non1.classList.remove('hidden');
    //     non1.classList.add('block');
    //   }

    //   non2.firstChild.childNodes[0].childNodes[1].nodeValue = gs;



    //   this.form.patchValue({
    //     Description: "",
    //     ComissionInterestRate: "",
    //     NetIncome: "",
    //     Amount: "",
    //   })
    // }
  }

  onKey(event: any, key)
  {

    let rawForm = this.form.getRawValue();

    if (key == "Amount" || key == "ComissionInterestRate")
    {
      let Amount, ComissionInterestRate, NetIncome = null;

      rawForm.Amount == '' ?
        Amount = 0 : Amount = rawForm.Amount;

      rawForm.ComissionInterestRate == '' ?
        ComissionInterestRate = 0 : ComissionInterestRate = rawForm.ComissionInterestRate;

      if (isNaN(Number(rawForm.Amount)) == true)
      {
        Amount = '0.00';
      }

      if (isNaN(Number(rawForm.ComissionInterestRate)) == true)
      {
        ComissionInterestRate = '0.00';
      }

      var interestOnSecuritiesClass = new InterestOnSecuritiesClass(
        Amount,
        ComissionInterestRate,
        rawForm.NetIncome,
        rawForm.SecurityType
      )
      this.form.patchValue({
        NetIncome: Number(interestOnSecuritiesClass.NetIncome).toFixed(2),
      })
    }
  }

  get isValid()
  {
    if (this.pageLoaded)
    {
      return this.form.controls[this.control.key].valid;
    } else
    {
      return false;
    }
  }

  ngAfterViewInit(): void
  {
    let non1: HTMLElement = document.getElementById("NetIncome") as HTMLElement;

    if (non1 != null)
    {
      non1.classList.add('hidden');
    }
  }

}
