import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import { DirectorsShareholdingClass } from './Director-share-holding.Class';

@Component({
  selector: 'DirectorsShareholding-form-control',
  templateUrl: './Director-share-holding-form-control.component.html',
  styleUrls: ['./Director-share-holding.component.scss']

})
export class DirectorsShareholdingControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }


  onKey(event: any, key)
  {
    if (key == "NoOfShare" || key == "PerShareValue")
    {
      let rawForm = this.form.getRawValue();
      let PerShareValue, NoOfShare, Amount = null;



      rawForm.PerShareValue == '' ?
        PerShareValue = 0 : PerShareValue = rawForm.PerShareValue;

      rawForm.NoOfShare == '' ?
        NoOfShare = 0 : NoOfShare = rawForm.NoOfShare;

      Amount = parseFloat(PerShareValue) * parseFloat(NoOfShare);

      if (isNaN(Number(rawForm.NoOfShare)) == true)
      {
        NoOfShare = '0.00';
      }

      if (isNaN(Number(rawForm.PerShareValue)) == true)
      {
        PerShareValue = '0.00';
      }

      var directorsShareholdingClass = new DirectorsShareholdingClass(
        PerShareValue,
        NoOfShare,
        rawForm.Amount,
      )

      this.form.patchValue({

        Amount: Number(directorsShareholdingClass.Amount).toFixed(2)

      })
    }
  }
}
