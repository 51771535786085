import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BackButtonService
{
  constructor() { }

  private paramSource = new BehaviorSubject(false);
  sharedData = this.paramSource.asObservable();
  setParam(param: boolean, route: string) 
  {
    
    if (route == 'fuse')
    {
      this.paramSource.next(param)
    }
  }
}
