<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'NEWS.HEADER' | translate }}</h2>
                    <!-- <ul>
                        <li><span class="sub-title">{{ 'PRICING.SUB_HEADER' | translate }}</span></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- <section class="blog-area ptb-100 pt-0">
    <div class="container">
        <div class="tab features-list-tab">

            <ul class="tabs"
                style="justify-content: center !important;
            align-items: center !important;">

                <li><a href="#"
                        class="bg-fa7070">
                        <span>{{ 'NEWS.HEADER_SUB_N' | translate }}</span>
                    </a></li>
                <li><a href="#"
                        class="bg-c679e3">
                        <span>{{ 'NEWS.HEADER_SUB_O' | translate }}</span>
                    </a></li>
                <li><a href="#"
                        class="bg-c679e3">
                        <span>{{ 'NEWS.HEADER_SUB_D' | translate }}</span>
                    </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">
                                <a href="https://bdnews24.com/business/2021/11/27/income-tax-returns-deadline-won-t-be-extended-past-nov-30-nbr"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://d30fl32nd2baj9.cloudfront.net/media/2013/01/04/logo1.png1/BINARY/logo1.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>Income tax returns deadline won't be extended past Nov 30: NBR</h3>
                                        <span>27 Nov 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://thefinancialexpress.com.bd/economy/nbr-not-to-extend-deadline-for-income-tax-return-submission-1638023939"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://fe-bd.sgp1.cdn.digitaloceanspaces.com/logo.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>NBR not to extend deadline for income tax return submission</h3>
                                        <span>27 Nov 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://thefinancialexpress.com.bd/trade/ereturn-of-tax-files-getting-massive-response-1638075006"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://fe-bd.sgp1.cdn.digitaloceanspaces.com/logo.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>eReturn of tax files getting massive response</h3>
                                        <span>28 Nov 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://bdnews24.com/economy/2021/10/26/nbr-cancels-income-tax-fair-for-second-year-in-pandemic"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://d30fl32nd2baj9.cloudfront.net/media/2013/01/04/logo1.png1/BINARY/logo1.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>NBR cancels income tax fair for second year in pandemic</h3>
                                        <span>26 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">
                                <a href="https://www.prothomalo.com/feature/pro-business/%E0%A6%95%E0%A6%B0%E0%A6%A6%E0%A6%BE%E0%A6%A4%E0%A6%BE%E0%A6%B0-%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%A4%E0%A7%88%E0%A6%B0%E0%A6%BF-%E0%A6%95%E0%A6%B0%E0%A7%87-%E0%A6%A6%E0%A7%87%E0%A6%AC%E0%A7%87-%E0%A6%8F%E0%A6%A8%E0%A6%AC%E0%A6%BF%E0%A6%86%E0%A6%B0"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://assets.prothomalo.com/prothomalo/assets/palo-bangla-bb996cdb70d2e0ccec8c.svg"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>করদাতার রিটার্ন তৈরি করে দেবে এনবিআর</h3>
                                        <span>02 Aug 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://www.jugantor.com/economics/revenue/492022/%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%AC%E0%A6%BF%E0%A6%AC%E0%A6%B0%E0%A6%A3%E0%A7%80-%E0%A6%9C%E0%A6%AE%E0%A6%BE%E0%A6%B0-%E0%A6%B8%E0%A6%AE%E0%A7%9F-%E0%A6%AC%E0%A6%BE%E0%A7%9C%E0%A6%9B%E0%A7%87-%E0%A6%A8%E0%A6%BE"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://www.jugantor.com/web-assets/img/jugantor-logo.svg"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>আয়কর বিবরণী জমার সময় বাড়ছে না</h3>
                                        <span>27 Nov 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://www.bd-pratidin.com/economy/2021/10/23/704313"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://www.bd-pratidin.com/assets/newDesktop/img/logo.png?v=2"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>বাংলায় প্রণয়ন করা হচ্ছে আয়কর আইন</h3>
                                        <span>23 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://www.facebook.com/Sarabangla.net/videos/578887746681852/"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://sarabangla.net/wp-content/uploads/2020/09/Logo-Sarabangla--300x117.jpg"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>অনলাইনে আয়কর রির্টান প্রস্তুত করে দিবে ডিজিট্যাক্স</h3>
                                        <span>23 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">
                                <a href="https://www.ittefaq.com.bd/300477/%E0%A6%98%E0%A6%B0%E0%A7%87-%E0%A6%AC%E0%A6%B8%E0%A7%87%E0%A6%87-%E0%A6%AA%E0%A7%82%E0%A6%B0%E0%A6%A3-%E0%A6%95%E0%A6%B0%E0%A6%BE-%E0%A6%AF%E0%A6%BE%E0%A6%AC%E0%A7%87-%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%AB%E0%A6%B0%E0%A6%AE"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://cdn.ittefaq.com/contents/themes/public/style/images/logo.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>ঘরে বসেই পূরণ করা যাবে আয়কর রিটার্ন ফরম</h3>
                                        <span>24 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://www.prothomalo.com/business/economics/%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%9C%E0%A6%AE%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%AA%E0%A6%B8-%E0%A6%A1%E0%A6%BF%E0%A6%9C%E0%A6%BF%E0%A6%9F%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%95%E0%A7%8D%E0%A6%B8-%E0%A6%9A%E0%A6%BE%E0%A6%B2%E0%A7%81"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://assets.prothomalo.com/prothomalo/assets/palo-bangla-bb996cdb70d2e0ccec8c.svg"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>রিটার্ন জমার অ্যাপস ডিজিট্যাক্স চালু</h3>
                                        <span>24 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://samakal.com/todays-print-edition/tp-industry-trade/article/2110127163/%E0%A6%98%E0%A6%B0%E0%A7%87-%E0%A6%AC%E0%A6%B8%E0%A7%87%E0%A6%87-%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%AB%E0%A6%B0%E0%A6%AE-%E0%A6%AA%E0%A7%82%E0%A6%B0%E0%A6%A3"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://samakal.com/assets/images/logo-bn.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>ঘরে বসেই আয়কর রিটার্ন ফরম পূরণ</h3>
                                        <span>24 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://www.tbsnews.net/bangladesh/web-based-app-launched-simplify-income-tax-submission-319846"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://www.tbsnews.net/sites/all/themes/sloth/banner.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>Web-based app launched to simplify income tax submission</h3>
                                        <span>23 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6"
                            style="margin-bottom: 30px;">
                            <div class="single-blog-post"
                                style="padding-top: 30px; border: 1px solid #dddfe1;">

                                <a href="https://www.tbsnews.net/bangladesh/web-based-app-launched-simplify-income-tax-submission-319846"
                                    target="_blank"
                                    class="single-team-box"
                                    style="cursor: pointer;">
                                    <div class="image"
                                        style="height: 80px; padding-left: 10px; padding-right: 10px;">
                                        <img src="https://www.tbsnews.net/sites/all/themes/sloth/banner.png"
                                            alt="News & Events">

                                    </div>
                                    <div class="content"
                                        style="text-align: left; border-top: solid #37793c;">
                                        <h3>Web-based app launched to simplify income tax submission</h3>
                                        <span>23 Oct 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</section> -->

<section class="blog-details-area pb-100">
    <div class="container">
        <h3 style="font-weight: bold;">{{ 'NEWS.HEADER_SUB_D' | translate }}</h3>
        <hr class="line">
        <br>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">
                    <a href="https://www.ittefaq.com.bd/300477/%E0%A6%98%E0%A6%B0%E0%A7%87-%E0%A6%AC%E0%A6%B8%E0%A7%87%E0%A6%87-%E0%A6%AA%E0%A7%82%E0%A6%B0%E0%A6%A3-%E0%A6%95%E0%A6%B0%E0%A6%BE-%E0%A6%AF%E0%A6%BE%E0%A6%AC%E0%A7%87-%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%AB%E0%A6%B0%E0%A6%AE"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://cdn.ittefaq.com/contents/themes/public/style/images/logo.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>ঘরে বসেই পূরণ করা যাবে আয়কর রিটার্ন ফরম</h3>
                            <span>24 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.prothomalo.com/business/economics/%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%9C%E0%A6%AE%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%AA%E0%A6%B8-%E0%A6%A1%E0%A6%BF%E0%A6%9C%E0%A6%BF%E0%A6%9F%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%95%E0%A7%8D%E0%A6%B8-%E0%A6%9A%E0%A6%BE%E0%A6%B2%E0%A7%81"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://assets.prothomalo.com/prothomalo/assets/palo-bangla-bb996cdb70d2e0ccec8c.svg"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>রিটার্ন জমার অ্যাপস ডিজিট্যাক্স চালু</h3>
                            <span>24 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://samakal.com/todays-print-edition/tp-industry-trade/article/2110127163/%E0%A6%98%E0%A6%B0%E0%A7%87-%E0%A6%AC%E0%A6%B8%E0%A7%87%E0%A6%87-%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%AB%E0%A6%B0%E0%A6%AE-%E0%A6%AA%E0%A7%82%E0%A6%B0%E0%A6%A3"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://samakal.com/assets/images/logo-bn.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>ঘরে বসেই আয়কর রিটার্ন ফরম পূরণ</h3>
                            <span>24 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.tbsnews.net/bangladesh/web-based-app-launched-simplify-income-tax-submission-319846"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://www.tbsnews.net/sites/all/themes/sloth/banner.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>Web-based app launched to simplify income tax submission</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.banglatribune.com/708742/%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%86%E0%A6%87%E0%A6%A8-%E0%A6%B9%E0%A6%AC%E0%A7%87-%E0%A6%AC%E0%A6%BE%E0%A6%82%E0%A6%B2%E0%A6%BE%E0%A7%9F-%E0%A6%8F%E0%A6%A8%E0%A6%AC%E0%A6%BF%E0%A6%86%E0%A6%B0"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://cdn.banglatribune.net/contents/themes/public/style/images/logo.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>আয়কর আইন হবে বাংলায়: এনবিআর</h3>
                            <span>30 Nov 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.dhakatribune.com/business/2021/10/23/taxpayers-can-fill-up-returns-staying-at-home-thru-digitax"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://www.dhakatribune.com/img/logo.svg"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>Taxpayers can fill up returns staying at home thru digiTax</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://thefinancialexpress.com.bd/economy/bangladesh/online-tax-return-application-digitax-launched-1634996067"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://fe-bd.sgp1.cdn.digitaloceanspaces.com/logo.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>Online tax return application ‘digiTax’ launched</h3>
                            <span>07 Nov 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.risingbd.com/economics/news/430739"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://cdn.risingbd.com/media/common/risingbd-logo-small-sm.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>ঘরে বসেই হবে আয়কর রিটার্ন ফরম পূরণ</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.dhakapost.com/economy/71209"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://allonlinebanglanewspapers.com/source/upload/Dhaka_postlogo.jpg"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>বাংলায় হচ্ছে আয়কর আইন</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://sarabangla.net/post/sb-608382/"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://sarabangla.net/wp-content/uploads/2020/09/Logo-Sarabangla--300x117.jpg"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>অনলাইনে আয়কর রিটার্ন প্রস্তুত করে দেবে ডিজিট্যাক্স</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.businessinsiderbd.com/bangla/economy/news/9238/%E0%A6%98%E0%A6%B0%E0%A7%87-%E0%A6%AC%E0%A6%B8%E0%A7%87%E0%A6%87-%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A7%81%E0%A6%AF%E0%A7%8B%E0%A6%97"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://www.businessinsiderbd.com/media/common/Logo5.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>ঘরে বসেই আয়কর রিটার্নের সুযোগ</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.youtube.com/watch?v=1Hh-s8Lu3ug"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c1/Independent_Television_Logo.svg/330px-Independent_Television_Logo.svg.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>অনলাইনে কর আদায় সহজ করতে চালু হচ্ছে ডিজিটেক্স ।। Digitax</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="blog-details-area pb-100">
    <div class="container">
        <h3 style="font-weight: bold;">{{ 'NEWS.HEADER_SUB_N' | translate }}</h3>
        <hr class="line">
        <br>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">
                    <a href="https://bdnews24.com/business/2021/11/27/income-tax-returns-deadline-won-t-be-extended-past-nov-30-nbr"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://d30fl32nd2baj9.cloudfront.net/media/2013/01/04/logo1.png1/BINARY/logo1.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>Income tax returns deadline won't be extended past Nov 30: NBR</h3>
                            <span>27 Nov 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://thefinancialexpress.com.bd/economy/nbr-not-to-extend-deadline-for-income-tax-return-submission-1638023939"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://fe-bd.sgp1.cdn.digitaloceanspaces.com/logo.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>NBR not to extend deadline for income tax return submission</h3>
                            <span>27 Nov 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://thefinancialexpress.com.bd/trade/ereturn-of-tax-files-getting-massive-response-1638075006"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://fe-bd.sgp1.cdn.digitaloceanspaces.com/logo.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>eReturn of tax files getting massive response</h3>
                            <span>28 Nov 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://bdnews24.com/economy/2021/10/26/nbr-cancels-income-tax-fair-for-second-year-in-pandemic"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://d30fl32nd2baj9.cloudfront.net/media/2013/01/04/logo1.png1/BINARY/logo1.png"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>NBR cancels income tax fair for second year in pandemic</h3>
                            <span>26 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="blog-details-area pb-100">
    <div class="container">
        <h3 style="font-weight: bold;">{{ 'NEWS.HEADER_SUB_O' | translate }}</h3>
        <hr class="line">
        <br>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">
                    <a href="https://www.prothomalo.com/feature/pro-business/%E0%A6%95%E0%A6%B0%E0%A6%A6%E0%A6%BE%E0%A6%A4%E0%A6%BE%E0%A6%B0-%E0%A6%B0%E0%A6%BF%E0%A6%9F%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A8-%E0%A6%A4%E0%A7%88%E0%A6%B0%E0%A6%BF-%E0%A6%95%E0%A6%B0%E0%A7%87-%E0%A6%A6%E0%A7%87%E0%A6%AC%E0%A7%87-%E0%A6%8F%E0%A6%A8%E0%A6%AC%E0%A6%BF%E0%A6%86%E0%A6%B0"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://assets.prothomalo.com/prothomalo/assets/palo-bangla-bb996cdb70d2e0ccec8c.svg"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>করদাতার রিটার্ন তৈরি করে দেবে এনবিআর</h3>
                            <span>02 Aug 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.jugantor.com/economics/revenue/492022/%E0%A6%86%E0%A7%9F%E0%A6%95%E0%A6%B0-%E0%A6%AC%E0%A6%BF%E0%A6%AC%E0%A6%B0%E0%A6%A3%E0%A7%80-%E0%A6%9C%E0%A6%AE%E0%A6%BE%E0%A6%B0-%E0%A6%B8%E0%A6%AE%E0%A7%9F-%E0%A6%AC%E0%A6%BE%E0%A7%9C%E0%A6%9B%E0%A7%87-%E0%A6%A8%E0%A6%BE"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://www.jugantor.com/web-assets/img/jugantor-logo.svg"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>আয়কর বিবরণী জমার সময় বাড়ছে না</h3>
                            <span>27 Nov 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.bd-pratidin.com/economy/2021/10/23/704313"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://www.bd-pratidin.com/assets/newDesktop/img/logo.png?v=2"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>বাংলায় প্রণয়ন করা হচ্ছে আয়কর আইন</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6"
                style="margin-bottom: 30px;">
                <div class="single-blog-post"
                    style="padding-top: 30px; border: 1px solid #dddfe1;">

                    <a href="https://www.facebook.com/Sarabangla.net/videos/578887746681852/"
                        target="_blank"
                        class="single-team-box"
                        style="cursor: pointer;">
                        <div class="image"
                            style="height: 80px; padding-left: 10px; padding-right: 10px;">
                            <img src="https://sarabangla.net/wp-content/uploads/2020/09/Logo-Sarabangla--300x117.jpg"
                                alt="News & Events">

                        </div>
                        <div class="content"
                            style="text-align: left; border-top: solid #37793c;">
                            <h3>অনলাইনে আয়কর রির্টান প্রস্তুত করে দিবে ডিজিট্যাক্স</h3>
                            <span>23 Oct 2021</span>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</section>
