<div fxLayoutAlign="space-between center"
    style="background-color: #fde28b; margin-bottom: 10px;"
    *ngIf="showHeader">
    <span fxLayout="row"
        fxLayoutAlign="center center"
        style="padding: 10px; margin-left: 10px;">
        <mat-icon style="transform: rotate(180deg)">wb_incandescent</mat-icon>
        <span style="margin-left: 10px;">Return form of tax {{this.archiveStat.CurrentYearDesc}} is ready for creation.</span>
        <a style="cursor: pointer; border: 0; margin-left: 5px; margin-right: 5px; color: #000000; text-decoration: underline; font-weight: bold;"
            [routerLink]="'/Archive'"
            fxLayout="row wrap"
            class="nav-link">
            Click here
        </a>
    </span>
</div>
