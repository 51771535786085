import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { JsonpInterceptor } from '@angular/common/http';
import { BaseControl } from 'app/models/dynamic-form';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import { bangla, english } from 'app/util/locale';


@Component({
  selector: 'Personalize-Return-form-control',
  templateUrl: './Personalize-Return-form-control.component.html',
  styleUrls: ['./Personalize-Return.component.scss']

})
export class PersonalizeReturnControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  pageLoaded: boolean;

  @Input() rSource: boolean;
  @Input() rAssets: boolean;
  @Input() rMotor: boolean;
  @Input() rProperty: boolean;
  @Input() gAssets: boolean;

  sources: string;
  yesNo: string = "";
  asset: string = "";
  motor: string = "";
  house: string = "";
  lang = '';
  IsCurrentYear: any;

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;


  constructor(
    private _matDialog: MatDialog,
  )
  {
    this.lang = localStorage.getItem('selectedLanguage');
  }

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }


  radioChange(event, key)
  {
    let OrganizationName: HTMLElement = document.getElementById("OrganizationName") as HTMLElement;
    let OrganizationAddress: HTMLElement = document.getElementById("OrganizationAddress") as HTMLElement;
    let BIN: HTMLElement = document.getElementById("BIN") as HTMLElement;
    let drop1: HTMLElement = document.getElementById("OtherIncomeSources") as HTMLElement;

    if (key == "MainSourceOfIncome")
    {
      if (event.value == "1" || event.value == "2" || event.value == "3")
      {
        if (OrganizationName.classList.contains('hidden'))
        {
          OrganizationName.classList.remove('hidden');
        }
        if (OrganizationAddress.classList.contains('hidden'))
        {
          OrganizationAddress.classList.remove('hidden');
        }
        if (BIN.classList.contains('hidden'))
        {
          BIN.classList.remove('hidden');
        }
        if (drop1.classList.contains('hidden'))
        {
          drop1.classList.remove('hidden');
        }
      }

      if (event.value == "4")
      {
        if (!OrganizationName.classList.contains('hidden'))
        {
          OrganizationName.classList.add('hidden');
        }
        if (!OrganizationAddress.classList.contains('hidden'))
        {
          OrganizationAddress.classList.add('hidden');
        }
        if (!BIN.classList.contains('hidden'))
        {
          BIN.classList.add('hidden');
        }
        if (drop1.classList.contains('hidden'))
        {
          drop1.classList.remove('hidden');
        }

        this.form.patchValue({
          OrganizationName: "",
          OrganizationAddress: "",
          BIN: ""
        });
      }

    }
  }

  ConfirmAsset(): void
  {
    let drop2: HTMLElement = document.getElementById("AcquiredAssets") as HTMLElement;

    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.PERSONALIZE.ENTER_ASSET_EXPENSE : bangla.data.PERSONALIZE.ENTER_ASSET_EXPENSE;

    this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

    this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

    this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

    this.confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        if (drop2.classList.contains('hidden'))
        {
          drop2.classList.remove('hidden');
        }
      }
      this.confirmDialogRef = null;
    });
  }


  radioChangeTwo(event, key)
  {
    // console.log(event);
    // console.log(event.value, 'value');
    // console.log(event.source);
    let drop2: HTMLElement = document.getElementById("AcquiredAssets") as HTMLElement;

    let HaveEligibleAssetValue = key === 'HaveEligibleAssetValue' ? event.value : this.form.controls['HaveEligibleAssetValue'].value;

    let HaveMotorCar = key === 'HaveMotorCar' ? event.value : this.form.controls['HaveMotorCar'].value;

    let HaveHouseProperty = key === 'HaveHouseProperty' ? event.value : this.form.controls['HaveHouseProperty'].value;

    if (HaveEligibleAssetValue == "1" || HaveMotorCar == "1" || HaveHouseProperty == "1")
    {
      if (drop2.classList.contains('hidden'))
      {
        drop2.classList.remove('hidden');
      }

      this.form.controls['AcquiredAssets'].setErrors({ 'required': true });
      this.form.controls.AcquiredAssets.setValidators(Validators.compose([Validators.required]))

      // console.log(this.form.controls.AcquiredAssets.valid, 'this.form.controls.AcquiredAssets.valid');

    }
    else
    {
      if (!drop2.classList.contains('hidden'))
      {
        drop2.classList.add('hidden');
      }
      this.form.controls['AcquiredAssets'].setErrors(null);
    }

    if (HaveEligibleAssetValue == "2" && HaveMotorCar == "2" && HaveHouseProperty == "2")
    {
      this.form.patchValue({
        AcquiredAssets: []
      });
      this.ConfirmAsset();
    }
  }



  get isValid()
  {
    if (this.pageLoaded)
    {
      return this.form.controls[this.control.key].valid;
    } else
    {
      return false;
    }

  }

  ngAfterViewInit(): void
  {
    this.pageLoaded = true;
    let AcquiredAssets = this.form.controls["AcquiredAssets"].value;

    let drop2: HTMLElement = document.getElementById("AcquiredAssets") as HTMLElement;

    let HaveEligibleAssetValue = this.form.controls["HaveEligibleAssetValue"].value;
    let HaveMotorCar = this.form.controls["HaveMotorCar"].value;
    let HaveHouseProperty = this.form.controls["HaveHouseProperty"].value;

    if (HaveEligibleAssetValue != null)
    {
      if (HaveEligibleAssetValue == "No")
      {
        HaveEligibleAssetValue = "2"
      }
      if (HaveEligibleAssetValue == "no")
      {
        HaveEligibleAssetValue = "2"
      }
      if (HaveEligibleAssetValue == "Yes")
      {
        HaveEligibleAssetValue = "1"
      }
      if (HaveEligibleAssetValue == "yes")
      {
        HaveEligibleAssetValue = "1"
      }
      this.form.patchValue({
        HaveEligibleAssetValue: HaveEligibleAssetValue
      });
    }
    if (HaveMotorCar != null)
    {
      if (HaveMotorCar == "No")
      {
        HaveMotorCar = "2"
      }
      if (HaveMotorCar == "no")
      {
        HaveMotorCar = "2"
      }
      if (HaveMotorCar == "Yes")
      {
        HaveMotorCar = "1"
      }
      if (HaveMotorCar == "yes")
      {
        HaveMotorCar = "1"
      }
      this.form.patchValue({
        HaveMotorCar: HaveMotorCar
      });
    }
    if (HaveHouseProperty != null)
    {
      if (HaveHouseProperty == "No")
      {
        HaveHouseProperty = "2"
      }
      if (HaveHouseProperty == "no")
      {
        HaveHouseProperty = "2"
      }
      if (HaveHouseProperty == "Yes")
      {
        HaveHouseProperty = "1"
      }
      if (HaveHouseProperty == "yes")
      {
        HaveHouseProperty = "1"
      }
      this.form.patchValue({
        HaveHouseProperty: HaveHouseProperty
      });
    }

    if (HaveEligibleAssetValue == "1" || HaveMotorCar == "1" || HaveHouseProperty == "1")
    {
      if (drop2 != null)
      {
        if (drop2.classList.contains('hidden'))
        {
          drop2.classList.remove('hidden');
        }
      }
    }

    if ((HaveEligibleAssetValue == "2" && HaveMotorCar == "2" && HaveHouseProperty == "2") && AcquiredAssets.length > 0)
    {
      if (drop2 != null)
      {
        if (drop2.classList.contains('hidden'))
        {
          drop2.classList.remove('hidden');
        }
      }
    }
  }
}

