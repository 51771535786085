import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class SpouseOrChildService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }


  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    if (id != null)
    {
      return this.service.get(urlConfig.INCOME_Spouse_Or_Child_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
      return this.service.get(urlConfig.INCOME_Spouse_Or_Child_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }

  }

  submitSpouseOrChild(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Spouse_Or_Child_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Spouse_Or_Child_Submit_Url + `/${id}`, model);
    }
  }


  getJson(): any
  {
    const jsonData = [
      {
        "value": "2",
        "valueField": null,
        "control": "DropdownControl",
        "key": "RelationType",
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the relationship with the taxpayer.",
        "required": "false",
        "label": "Relation",
        "icon": null,
        "options": [
          {
            "key": "1",
            "value": "Spouse"
          },
          {
            "key": "2",
            "value": "Child"
          }
        ],
        "order": "1",
        "children": null
      },
      {
        "value": null,
        "valueField": null,
        "control": "TextboxControl",
        "key": "Name",
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the name of the spouse or child of the taxpayer.",
        "required": "false",
        "label": "Name",
        "icon": null,
        "options": null,
        "order": "2",
        "children": null
      },
      {
        "value": null,
        "valueField": null,
        "control": "TextboxControl",
        "key": "TaxableIncome",
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the amount of net income of the taxpayer from his/her spouse or child.",
        "required": "true",
        "label": "Net Income",
        "icon": null,
        "options": null,
        "order": "3",
        "children": null
      }
    ]

    return jsonData;
  }

  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
