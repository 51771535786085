
export class PropertyClass
{
  constructor(
    private _RepairCollectionEtc: number,
    private _AnnualAmountReceived: number,
    private _MunicipalLocalTax: number,
    private _LandRevenue: number,
    private _LoanMortgageCapitalInterest: number,
    private _InsurancePremium: number,
    private _VacancyAllowance: number,
    private _Other: number,
    private _TotalExpense: number,
    private _IncomeAfterDeduction: number,
    private _AsesseeSharePercent: number,
    private _ShareOfIncomeAmount: number,
    private _PropertyType: string,
  )
  {

  }

  public get RepairCollectionEtc(): number
  {
    let rc = 0;
    if (this.PropertyType == "1")
    {
      rc = Number(this.AnnualAmountReceived) * .25;
    }

    if (this.PropertyType == "2")
    {
      rc = Number(this.AnnualAmountReceived) * .30;
    }

    return this._RepairCollectionEtc = rc;
  }
  public set RepairCollectionEtc(x: number)
  {
    this._RepairCollectionEtc = x;
  }

  public get AnnualAmountReceived(): number
  {
    return this._AnnualAmountReceived;
  }
  public set AnnualAmountReceived(x: number)
  {
    this._AnnualAmountReceived = x;
  }

  public get MunicipalLocalTax(): number
  {
    return this._MunicipalLocalTax;
  }
  public set MunicipalLocalTax(x: number)
  {
    this._MunicipalLocalTax = x;
  }

  public get LandRevenue(): number
  {
    return this._LandRevenue;
  }
  public set LandRevenue(x: number)
  {
    this._LandRevenue = x;
  }


  public get LoanMortgageCapitalInterest(): number
  {
    return this._LoanMortgageCapitalInterest;
  }
  public set LoanMortgageCapitalInterest(x: number)
  {
    this._LoanMortgageCapitalInterest = x;
  }


  public get InsurancePremium(): number
  {
    return this._InsurancePremium;
  }
  public set InsurancePremium(x: number)
  {
    this._InsurancePremium = x;
  }


  public get VacancyAllowance(): number
  {
    return this._VacancyAllowance;
  }
  public set VacancyAllowance(x: number)
  {
    this._VacancyAllowance = x;
  }

  public get Other(): number
  {
    return this._Other;
  }
  public set Other(x: number)
  {
    this._Other = x;
  }

  public get TotalExpense(): number
  {
    return this._TotalExpense = Number(this.RepairCollectionEtc)
      + Number(this.MunicipalLocalTax)
      + Number(this.LandRevenue)
      + Number(this.LoanMortgageCapitalInterest)
      + Number(this.InsurancePremium)
      + Number(this.VacancyAllowance)
      + Number(this.Other);
  }
  public set TotalExpense(x: number)
  {
    this._TotalExpense = x;
  }

  public get IncomeAfterDeduction(): number
  {
    return this._IncomeAfterDeduction = Number(this.AnnualAmountReceived) - Number(this.TotalExpense);
  }
  public set IncomeAfterDeduction(x: number)
  {
    this._IncomeAfterDeduction = x;
  }

  public get AsesseeSharePercent(): number
  {
    return this._AsesseeSharePercent;
  }
  public set AsesseeSharePercent(x: number)
  {
    this._AsesseeSharePercent = x;
  }

  public get ShareOfIncomeAmount(): number
  {
    let sa = null;
    if (this.AsesseeSharePercent == 0 || this.AsesseeSharePercent == null)
    {
      sa = this.IncomeAfterDeduction;
    }
    else
    {
      sa = Number(this.IncomeAfterDeduction) * Number(this.AsesseeSharePercent) * 0.01;
    }
    return this._ShareOfIncomeAmount = sa
  }
  public set ShareOfIncomeAmount(x: number)
  {
    this._ShareOfIncomeAmount = x;
  }

  public get PropertyType(): string
  {
    return this._PropertyType;
  }
  public set PropertyType(x: string)
  {
    this._PropertyType = x;
  }

}
