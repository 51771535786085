<div [formGroup]="form"
  style="background-color: #ffffff;">

  <div [ngSwitch]="control.controlType"
    fxLayout="column"
    fxLayoutAlign="start"
    fxFlex="100">
    <div class="h2 mb-24"
      style="padding-top:20px;color: #206030;"
      *ngSwitchCase="'label'">{{control.label}}</div>
    <div *ngSwitchCase="'textbox'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">

      <div fxLayoutAlign="start"
        fxLayout="column"
        fxFlex="100">

        <span class="name"
          style="font-size: 15px;color: #444444;"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>

        <mat-form-field appearance="outline">
          <input *ngSwitchCase="'textbox'"
            autocomplete="off"
            maxlength={{control.maxlength}}
            NumbersOnly
            type="text"
            decimals="100"
            negative="1"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="control.key==='ETIN'"
        fxLayout="row"
        fxLayoutAlign="start"
        fxFlex="100"
        style="margin-top: 10px;margin-bottom: 20px;">

        <div class="h5 mb-4"> {{ 'ETIN.EXTRA1' | translate }} <a class="forgot-password"
            href="https://secure.incometax.gov.bd/TINHome"
            target="_blank">
            {{ 'ETIN.EXTRA2' | translate }}
          </a>
        </div>
      </div>

    </div>
    <div *ngSwitchCase="'textboxGroup'">

      <div fxLayoutAlign="space-between start"
        style="margin-top: -5px; margin-left: 20px; margin-right: 20px;"
        fxLayout="column"
        fxLayout.gt-md="row"
        fxFlex="100"
        [id]="control.key"
        *ngIf="control.key!='taxZoneBox'">
        <div *ngFor="let Source of control.children"
          fxLayoutAlign="space-between start"
          fxLayout="column"
          fxLayout.gt-md="row"
          fxFlex="100">
          <div *ngIf="Source.controlType=='checkbox'"
            fxLayoutAlign="start start"
            fxLayout="row"
            fxFlex="100"
            style="margin: 10px;">
            <mat-checkbox style="margin-left: 5px; margin-right: 5px;"
              [formControlName]="Source.key"
              [value]="Source.value"></mat-checkbox>
            <span class="name"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key"><span *ngIf="Source.required==true"
                style="color: red;font-weight: bold;">* </span>{{Source.label}}</span>

          </div>

          <div *ngIf="Source.controlType=='textbox'"
            fxLayoutAlign="start start"
            fxLayout="column"
            fxLayout.gt-md="row"
            fxFlex="100"
            style="margin: 10px;">

            <span class="name"
              style="font-size: 15px;color: #444444;"
              fxFlex="50"><span style="color: red;font-weight: bold;">* </span>{{Source.label}}</span>

            <mat-form-field appearance="outline"
              [id]="Source.key"
              [attr.for]="Source.key"
              fxFlex="50">
              <input autocomplete="off"
                NumbersOnly
                type="text"
                decimals="100"
                negative="1"
                [formControlName]="Source.key"
                [id]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>

              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div fxLayoutAlign="space-between center"
        fxFlex="100"
        style="margin-bottom: 20px;"
        [id]="control.key"
        *ngIf="control.key=='taxZoneBox'">
        <div *ngFor="let Source of control.children"
          fxLayoutAlign="space-between center"
          fxLayout="row"
          fxFlex="50">
          <div fxLayout="column"
            fxLayoutAlign="space-between"
            fxFlex="100">
            <div fxLayoutAlign="space-between"
              fxLayout="column"
              *ngIf="Source.key=='Circle'"
              style="padding-right: 10px;">

              <span class="name"
                style="font-size: 15px;color: #444444;"
                [attr.for]="Source.key"><span *ngIf="Source.required==true"
                  style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                  style="margin-top: 5px;margin-left: 5px"
                  matTooltip={{Source.tooltip}}
                  matSuffix>info
                </mat-icon></span>

              <mat-form-field appearance="outline"
                [attr.for]="Source.key"
                [id]="Source.key">
                <input *ngSwitchCase="'textboxGroup'"
                  autocomplete="off"
                  [formControlName]="Source.key"
                  [id]="Source.key"
                  [type]="Source.type"
                  matInput
                  required={{Source.required}}>
                <mat-error>{{Source.message}}</mat-error>
              </mat-form-field>

            </div>
            <div fxLayoutAlign="space-between"
              fxLayout="column"
              *ngIf="Source.key=='Zone'"
              style="padding-left: 10px;">

              <span class="name"
                style="font-size: 15px;color: #444444;"
                [attr.for]="Source.key"><span *ngIf="Source.required==true"
                  style="color: red;font-weight: bold;">* </span>{{Source.label}} <mat-icon
                  style="margin-top: 5px;margin-left: 5px"
                  matTooltip={{Source.tooltip}}
                  matSuffix>info
                </mat-icon></span>

              <!-- <mat-form-field appearance="outline"
                [attr.for]="Source.key"
                [id]="Source.key">
                <input *ngSwitchCase="'textboxGroup'"
                  autocomplete="off"
                  [formControlName]="Source.key"
                  [id]="Source.key"
                  [type]="Source.type"
                  matInput
                  required={{Source.required}}>
                <mat-error>{{Source.message}}</mat-error>
              </mat-form-field> -->
              <mat-form-field appearance="outline"
                [attr.for]="Source.key"
                [id]="Source.key"
                fxFlex="100">
                <mat-select formControlName="{{Source.key}}"
                  [id]="Source.key"
                  required={{Source.required}}>
                  <mat-option *ngFor="let option of Source.options"
                    [value]="option.key">
                    {{option.value}}
                  </mat-option>
                </mat-select>
                <mat-error>{{Source.message}}</mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div *ngSwitchCase="'radio'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">

      <div fxLayout="column"
        *ngIf="control.key=='Nationality'"
        fxLayoutAlign="center start"
        appearance="outline"
        fxFlex="100"
        id={{control.key}}>
        <span class="name"
          style="font-size: 15px;color: #444444;"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>
        <div fxLayout="row"
          style="margin-top: 10px;margin-bottom: 10px;">
          <mat-radio-group [formControlName]="control.key"
            [ngClass]="{'invalid': form.get('Nationality').invalid && nStatus}"
            required={{control.required}}
            [id]="control.key">
            <mat-radio-button style="margin: 5px; margin-right: 10px;"
              [ngClass]="{'invalid': form.get('Nationality').invalid && nStatus}"
              *ngFor="let Source of control.options"
              [value]="Source.key"
              (change)="radioChangeN($event,control.key)">
              {{Source.value}}
            </mat-radio-button>
          </mat-radio-group>

        </div>

      </div>

      <div fxLayout="column"
        *ngIf="control.key=='stayed1'"
        class="hidden"
        fxLayoutAlign="center start"
        appearance="outline"
        fxFlex="100"
        id={{control.key}}>
        <span class="name"
          style="font-size: 15px;color: #444444;"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>
        <div fxLayout="row"
          style="margin-top: 10px;margin-bottom: 10px;">
          <mat-radio-group [formControlName]="control.key"
            [ngClass]="{'invalid': form.get('stayed1').invalid && rStatus}"
            required={{control.required}}
            [id]="control.key">
            <mat-radio-button style="margin: 5px; margin-right: 10px;"
              [ngClass]="{'invalid': form.get('stayed1').invalid && rStatus}"
              *ngFor="let Source of control.options"
              [value]="Source.key"
              (change)="radioChange1($event,control.key)">
              {{Source.value}}
            </mat-radio-button>
          </mat-radio-group>

        </div>

      </div>
      <div fxLayout="column"
        style="margin-top: 10px;"
        *ngIf="control.key=='stayed2'"
        class="hidden"
        fxLayoutAlign="center start"
        appearance="outline"
        fxFlex="100"
        id={{control.key}}>
        <span class="name"
          style="font-size: 15px;color: #444444;"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>
        <div fxLayout="row"
          style="margin-top: 10px;margin-bottom: 10px;">

          <mat-radio-group [formControlName]="control.key"
            required={{control.required}}
            [id]="control.key">
            <mat-radio-button style="margin-right: 10px;"
              *ngFor="let Source of control.options"
              [value]="Source.key"
              (change)="radioChange2($event,control.key)">
              {{Source.value}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
    </div>
    <div *ngSwitchCase="'button'"
      style="margin-top: 10px;"
      class="header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      id={{control.key}}
      class="hidden">
      <div disabled
        class="mat-button mat-button-base primary-A400 secondary-text">

      </div>
      <div class="h3 mb-12"
        style="color: #444444;">{{control.label}} <span class="username"
          id="resident"
          style="font-weight: bold; color: #206030;"></span>
      </div>
      <div disabled
        class="mat-button mat-button-base primary-A400 secondary-text">

      </div>
    </div>

    <div *ngSwitchCase="'slideToggle'"
      fxLayout="row"
      fxFlex="100"
      fxLayoutAlign="start start"
      style="margin-bottom: 10px;">

      <mat-slide-toggle formControlName="{{control.key}}"
        (change)="onToggle($event,control.key)"
        aria-label="All day">
        <mat-icon matSuffix
          class="secondary-text"
          color="Accent">{{control.icon}}</mat-icon>
      </mat-slide-toggle>
      <span class="name"
        style="font-size: 15px;color: #444444;"
        [attr.for]="control.key"><span *ngIf="control.required==true"
          style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
          style="margin-top: 5px;margin-left: 5px"
          matTooltip={{control.tooltip}}
          matSuffix>info
        </mat-icon></span>
    </div>
    <div fxLayout="column"
      fxLayoutAlign="start space-between"
      fxFlex="1 0 auto">
      <div *ngSwitchCase="'dropdown'"
        fxLayout="column"
        fxLayoutAlign="start space-between"
        fxFlex="1 0 auto">
        <span class="name"
          style="font-size: 15px;color: #444444;"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>
        <div fxLayout="row"
          fxLayoutAlign="start center"
          fxFlex="1 0 auto">

          <mat-form-field appearance="outline"
            fxFlex="100">
            <mat-select formControlName="{{control.key}}"
              [id]="control.key"
              required={{control.required}}>
              <mat-option *ngFor="let option of control.options"
                [value]="option.key">
                {{option.value}}
              </mat-option>
            </mat-select>
            <mat-error>{{control.message}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>

</div>
