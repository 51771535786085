import { Component, ViewEncapsulation, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'app/auth';
import { BackButtonService } from 'app/services/BackButtonService';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'mail-confirm-home',
    templateUrl: './mail-confirm-home.component.html',
    styleUrls: ['./mail-confirm-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MailConfirmHomeComponent implements OnInit
{
    email: string;
    resendClickEnabled: boolean = false;
    isConfirmError = false;
    isConfirmOk = false;
    timeLeft: number = 60;
    interval: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _router: Router,
        private back: BackButtonService,
        private _spinner: NgxSpinnerService,
        private _homeTranslationLoaderService: HomeTranslationLoaderService,
        private meta: Meta,
        private title: Title,
    )
    {
        this.email = this._route.snapshot.paramMap.get('email');

        this.meta.addTags([
            { name: 'description', content: 'Confirm your email with digiTax' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'Confirm email digiTax, digiTax email confirmation' }
        ]);
        this.setTitle(`digiTax | Email Confirm: ${this.email}`);
        this._homeTranslationLoaderService.loadTranslations(english, bangla);

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

    }

    OnSubmit(): void
    {
        // debugger;
        this._spinner.show();
        this._authService.resendConfirmEmail(this.email).subscribe((data: any) =>
        {
            if (data != null && data.Error == false)
            {
                this.startTimer();
                this._spinner.hide();
                this.isConfirmOk = true;
                this.isConfirmError = false;

            }
            else
            {
                this._spinner.hide();
                this.isConfirmError = true;
                this.isConfirmOk = false;
            }

        },
            (err: HttpErrorResponse) =>
            {
                this._spinner.hide();
                this.isConfirmError = true;
                this.isConfirmOk = false;
            }
        );

    }

    startTimer()
    {
        this.interval = setInterval(() =>
        {
            if (this.timeLeft > 0)
            {
                this.resendClickEnabled = true;
                this.timeLeft--;
            } else
            {
                this.timeLeft = 60;
                this.pauseTimer();
                this.resendClickEnabled = false;
            }
        }, 1000);
    }

    pauseTimer()
    {
        clearInterval(this.interval);
    }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event)
    // {

    //     this.back.setParam(true, 'fuse');
    // }
}
