import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import Utils from 'app/util/utils';
import * as lodash from 'lodash';
import { ShowNoClient } from 'app/services/showNoClient.service';



@Injectable()
export class ViewDashboardGuard implements CanActivate
{
  constructor(
    private router: Router,
    private showNoClient: ShowNoClient,
  ) 
  {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean
  {
    let hc = lodash.isEmpty(Utils.getAssesseeId());
    if (Utils.getUserInfo().ActiveRoleId == "7" && hc == false)
    {
      return true;
    }
    if (Utils.getUserInfo().ActiveRoleId == "8")
    {
      return true;
    }
    this.router.navigate(['/Clients']);
    if (Utils.getUserInfo().ActiveRoleId == "7" && hc == true)
    {
      this.showNoClient.changeMessage(true);
    }
    return false;
  }
}

