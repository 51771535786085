import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileSaveModel } from './ProfileSave.Model';
import { ProfileSaveService } from './ProfileSave.service';

import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { AppUser } from 'app/auth';
import Utils from 'app/util/utils';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ProfileSave',
    templateUrl: './ProfileSave.component.html',
    styleUrls: ['./ProfileSave.component.scss']
})
export class ProfileSaveComponent
{
    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    user: AppUser;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: ProfileSaveService,
        private _spinner: NgxSpinnerService,
        private sidePanelSharedService: SidePanelSharedService,
        private _router: Router,


        private _matSnackBar: MatSnackBar,

        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private _formBuilder: FormBuilder,
        private back: BackButtonService,
        private _fuseNavigationService: FuseNavigationService,

    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Organization Profile" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Organization Profile" }
        ]);
        this.setTitle("digiTax | Organization Profile");
        // this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        // this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }

        this.lang = localStorage.getItem('selectedLanguage');
        if (this.lang == 'en')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        if (this.lang == 'bn')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        this.form = this._formBuilder.group(new ProfileSaveModel());
        this.getData();

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }



    ngOnInit(): void
    {
        // this.getData();
    }

    getData(): void
    {
        this._spinner.show();
        {
            this._formControlService.getControlJson().subscribe((data: any) =>
            {
                if (data.data != null)
                {

                    this.form = this._formBuilder.group(data.data);
                    // this.form.patchValue({
                    //     OrgName: data.data.OrgName,
                    //     ITPNo: data.data.ITPNo,
                    //     BarMembershipNo: data.data.BarMembershipNo,
                    //     Mobile: data.data.Mobile,
                    //     Email: data.data.Email,
                    //     Address1: data.data.Address1,
                    //     Address2: data.data.Address2
                    // });
                }

            })
        }
        this._spinner.hide();
    }

    OnSubmit(): void
    {
        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }

        this._spinner.show();

        let obj = this.form.getRawValue();

        if (obj.OrgName == null)
        {
            obj.OrgName = "";
        }

        if (obj.ITPNo == null)
        {
            obj.ITPNo = "";
        }

        if (obj.BarMembershipNo == null || obj.BarMembershipNo == "0" || isNaN(Number(obj.BarMembershipNo)) == true)
        {
            obj.BarMembershipNo = "";
        }

        if (obj.Mobile == null || obj.Mobile == "0" || isNaN(Number(obj.Mobile)) == true)
        {
            obj.Mobile = "";
        }

        if (obj.Email == null)
        {
            obj.Email = "";
        }

        if (obj.Address2 == null)
        {
            obj.Address2 = "";
        }

        let stringed = JSON.stringify(obj);



        if (obj.OrgName == "" || obj.OrgName == null)
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_OrgName : bangla.data.PROFILE.ENTER_OrgName, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (obj.ITPNo == "" || obj.ITPNo == null)
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_ITPNo : bangla.data.PROFILE.ENTER_ITPNo, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (obj.BarMembershipNo == "" || obj.BarMembershipNo == null)
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_BarMembershipNo : bangla.data.PROFILE.ENTER_BarMembershipNo, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    if (obj.Mobile == "" || obj.Mobile == null || obj.Mobile.length != 11)
                    {
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_Mobile : bangla.data.PROFILE.ENTER_Mobile, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                    else
                    {
                        if (Utils.validateEmail((obj.Email)) == false)
                        {
                            this._spinner.hide();
                            this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_Email : bangla.data.PROFILE.ENTER_Email, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                        else
                        {
                            if (obj.Address2 == "" || obj.Address2 == null)
                            {
                                this._spinner.hide();
                                this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_Address2 : bangla.data.PROFILE.ENTER_Address2, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {

                                if (stringed != null)
                                {
                                    this._formControlService.submitData(stringed)
                                        .subscribe((data: any) =>
                                        {
                                            if (data != null)
                                            {
                                                this._spinner.hide();
                                            }

                                            if (data.isError === false)
                                            {
                                                console.log('submit succesfull');
                                            }
                                            else
                                            {
                                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                    verticalPosition: 'top',
                                                    duration: 2000
                                                });
                                            }
                                            if (data.isError === false && data.responseCode == "200")
                                            {
                                                if (this.id != null)
                                                {
                                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                        verticalPosition: 'top',
                                                        duration: 2000
                                                    });
                                                }
                                                else
                                                {
                                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                        verticalPosition: 'top',
                                                        duration: 2000
                                                    });
                                                }
                                                this._router.navigate(['/Profile']);


                                            }
                                        }, (error: any) =>
                                        {
                                            console.log(error, 'error');
                                        });
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

