import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { bangla, english } from 'app/util/locale';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
    selector: 'home',
    templateUrl: './Home.component.html',
    styleUrls: ['./Home.component.scss']
})
export class HomeComponent implements OnInit
{
    lang = '';
    loginForm: FormGroup;
    isLoginError = false;
    isLoggedIn = false;
    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _spinner: NgxSpinnerService,

        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private _router: Router,
        private _homeTranslationLoaderService: HomeTranslationLoaderService,
        private back: BackButtonService,
        private meta: Meta,
        private title: Title,




    )
    {
        this._spinner.hide();

        this.meta.addTags([
            { name: 'description', content: 'Welcome to digiTax' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'Welcome to digiTax' }
        ]);
        this.setTitle('Welcome to digiTax');
        this.lang = localStorage.getItem('selectedLanguage');
        this._homeTranslationLoaderService.loadTranslations(english, bangla);
        this.back.sharedData.subscribe(result =>
        {


            if (result)
            {
                // window.location.reload(true);
                window.location.reload();

                this.back.setParam(false, '');
            }
        });
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        if (localStorage.getItem("home_userToken") === null)
        {
            this.isLoggedIn = false;


        }
        else
        {
            this.isLoggedIn = true;


        }

        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    goToMain(): void
    {
        let url = "http://localhost:4401/EtinOthers"
        window.location.href = url;
    }

    OnSubmit(): void
    {
        const userName = this.loginForm.get('email').value;
        const password = this.loginForm.get('password').value;

        this._authService.login(userName, password).subscribe((data: any) =>
        {


            localStorage.setItem('home_userToken', data.access_token);
            localStorage.setItem('home_userRefToken', data.refresh_token);

            delete data['access_token'];
            delete data['refresh_token'];
            delete data['token_type'];
            delete data['expires_in'];
            delete data['error'];
            delete data['.issued'];
            delete data['.expires'];


            localStorage.setItem('home_userInfo', JSON.stringify(data));
            localStorage.setItem('home_TokenInfo', data.UserId);



            let nu = encodeURIComponent(userName);
            let wp = encodeURIComponent(password);

            let userRefToken = encodeURIComponent(nu + '৳' + wp);


        },
            (err: HttpErrorResponse) =>
            {
                this.isLoginError = true;
            },
            (error: any) =>
            {
                this.isLoginError = true;
            });
    }
}
