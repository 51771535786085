import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSelectionListChange, MatSelectionList } from "@angular/material/list";

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';

import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';
import { PaymentService } from 'app/pages/Payment/Payment.service';
import * as lodash from 'lodash';
import { ClientsListService } from '../ClientsList.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
    selector: 'ClientDocumentDialog',
    templateUrl: 'client-document-Dialog.component.html',
    styleUrls: ['./client-document-Dialog.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ClientDocumentDialog
{


    lang = '';


    PageResponseData: any;


    shownData: any;









    constructor(
        public dialogRef: MatDialogRef<ClientDocumentDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: PaymentService,
        private _clientsListService: ClientsListService,
        private _transactionControlService: TransactionService,
        private http: HttpClient,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        dialogRef.disableClose = true;
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');



        this.getPageData(this.dialogdata.AssesseeId);

    }








    getPageData(AssesseeId): void
    {
        this._spinner.show();
        this._clientsListService.getDocumentsList(AssesseeId)
            .subscribe((data: any) =>
            {

                if (data.isError === false)
                {
                    this.PageResponseData = data.data;
                    // console.log(this.PageResponseData, 'this.getData');
                    this.shownData = this.PageResponseData.filter(x => x.Items.length > 0);
                    // console.log(this.shownData, 'this.shownData');

                }
                else
                {
                    console.log('error');
                    this._spinner.hide();
                }
                this._spinner.hide();

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            });
    }

    DownloadFile(path): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.DOCUMENT_DOWNLOADFILE + `${path}`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`
            }), responseType: 'blob'
        }).subscribe(res =>
        {
            //  console.log(blob, 'blob');
            if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
            {
                let reader = new FileReader();
                let base64data = null;
                reader.readAsDataURL(res);
                reader.onloadend = function ()
                {
                    base64data = reader.result;
                    
                    let pdfWindow = window.open("")
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src=" +
                        base64data + "></iframe>"
                    )
                }
            }
            else
            {
                saveAs(res, path);
            }
            this._spinner.hide();
        },
            error =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            })
    }


    expandPanel(matExpansionPanel: MatExpansionPanel, event: any)
    {
        event.stopPropagation();

        if (!this._isExpansionIndicator(event.target))
        {
            // this.card9Expanded = !this.card9Expanded;
            matExpansionPanel.close();
        }
    }

    private _isExpansionIndicator(target: EventTarget | any): boolean
    {
        const expansionIndicatorClass = "mat-expansion-indicator";
        return (
            target.classList && target.classList.contains(expansionIndicatorClass)
        );
    }


    onNoClick(): void
    {
        this.dialogRef.close("no");
    }







}


let gg =

    [
        {
            "ETIN": "357951456987",
            "AssesseName": "Taijuddin",
            "AssesseeId": 2326,
            "ReturnId": 2365,
            "ContactNo": "01916387657",
            "Email": "allworksbd@gmail.com",
            "Year": "Year 2022-2023",
            "ChallanAmount": null,
            "SubmittedBy": null,
            "PaymentTypeId": 0,
            "ChequeNo": null,
            "BankId": 0,
            "BankName": null,
            "Branch": null,
            "UploadedDocuments": [
                {
                    "DocId": 2,
                    "SlNo": 1,
                    "DocumentName": "Salary statement for salary income;"
                },
                {
                    "DocId": 3,
                    "SlNo": 2,
                    "DocumentName": "Certificate for interest on savings instruments;"
                },
                {
                    "DocId": 4,
                    "SlNo": 3,
                    "DocumentName": "Interest statement out of SC;"
                },
                {
                    "DocId": 5,
                    "SlNo": 4,
                    "DocumentName": "Rent agreement,"
                },
                {
                    "DocId": 1000,
                    "SlNo": 1000,
                    "DocumentName": "Return File"
                },
                {
                    "DocId": 1001,
                    "SlNo": 1001,
                    "DocumentName": "Calculation Sheet"
                },
                {
                    "DocId": 1002,
                    "SlNo": 1002,
                    "DocumentName": "Challan"
                },
                {
                    "DocId": 1003,
                    "SlNo": 1003,
                    "DocumentName": "Acknowledgement Slip"
                }
            ]
        }
    ]


