import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { QuickPanelService } from 'app/layout/components/quick-panel/quick-panel.service';

@Injectable()
export class SidePanelSharedService
{
  SidePanelData;
  constructor(
    private _quickPanelService: QuickPanelService,
    private _fuseNavigationService: FuseNavigationService,
  )
  {
    this.SidePanelData = [];
  }
  setSidePanelData()
  {

    this._quickPanelService.getControlJson().subscribe((data: any) =>
    {
      if (data.data.leftmenulist != null)
      {

        this._fuseNavigationService.unregister('main');
        this._fuseNavigationService.register('main', data.data.leftmenulist);


        this._fuseNavigationService.setCurrentNavigation('main');

      };
      if (data.data.rightpanel != null)
      {



        let rp = JSON.stringify(data.data.rightpanel);


        localStorage.setItem('rightpanel', rp);


      };

      if (data.data.checkprofile != null)
      {
        let pd = JSON.stringify(data.data.checkprofile);


        localStorage.setItem('profileData', pd);
      };


    });

  }
  getSidePanelData()
  {
    return this.setSidePanelData();
  }
}
