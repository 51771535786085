import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import { OtherResourcesClass } from './OtherResources.Class';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { english, bangla } from 'app/util/locale';

@Component({
  selector: 'OtherResources-form-control',
  templateUrl: './OtherResources-form-control.component.html',
  styleUrls: ['./OtherResources.component.scss']

})
export class OtherResourcesControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,


  )
  {
  }

  ngOnInit(): void
  {
    this._fuseTranslationLoaderService.loadTranslations(english, bangla);

    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }

  onFocusoutEvent(event, key)
  {
    let rawForm = this.form.getRawValue();

    let InterestMutualOrUnitFundAmount, InterestMutualOrUnitFundTDS, InterestMutualOrUnitFundTaxable = null;

    rawForm.InterestMutualOrUnitFundAmount == '' || rawForm.InterestMutualOrUnitFundAmount == null ?
      InterestMutualOrUnitFundAmount = 0 : InterestMutualOrUnitFundAmount = rawForm.InterestMutualOrUnitFundAmount;
    InterestMutualOrUnitFundAmount = parseFloat(InterestMutualOrUnitFundAmount);

    rawForm.InterestMutualOrUnitFundTDS == '' || rawForm.InterestMutualOrUnitFundTDS == null ?
      InterestMutualOrUnitFundTDS = 0 : InterestMutualOrUnitFundTDS = rawForm.InterestMutualOrUnitFundTDS;
    InterestMutualOrUnitFundTDS = parseFloat(InterestMutualOrUnitFundTDS);

    rawForm.InterestMutualOrUnitFundTaxable == '' || rawForm.InterestMutualOrUnitFundTaxable == null ?
      InterestMutualOrUnitFundTaxable = 0 : InterestMutualOrUnitFundTaxable = rawForm.InterestMutualOrUnitFundTaxable;
    InterestMutualOrUnitFundTaxable = parseFloat(InterestMutualOrUnitFundTaxable);


    let CashDividendStockExchangeAmount, CashDividendStockExchangeTDS, CashDividendStockExchangeTaxable = null;

    rawForm.CashDividendStockExchangeAmount == '' || rawForm.CashDividendStockExchangeAmount == null ?
      CashDividendStockExchangeAmount = 0 : CashDividendStockExchangeAmount = rawForm.CashDividendStockExchangeAmount;
    CashDividendStockExchangeAmount = parseFloat(CashDividendStockExchangeAmount);

    rawForm.CashDividendStockExchangeTDS == '' || rawForm.CashDividendStockExchangeTDS == null ?
      CashDividendStockExchangeTDS = 0 : CashDividendStockExchangeTDS = rawForm.CashDividendStockExchangeTDS;
    CashDividendStockExchangeTDS = parseFloat(CashDividendStockExchangeTDS);

    rawForm.CashDividendStockExchangeTaxable == '' || rawForm.CashDividendStockExchangeTaxable == null ?
      CashDividendStockExchangeTaxable = 0 : CashDividendStockExchangeTaxable = rawForm.CashDividendStockExchangeTaxable;
    CashDividendStockExchangeTaxable = parseFloat(CashDividendStockExchangeTaxable);


    let InterestIncomeWEDBAmount, InterestIncomeWEDBTDS, InterestIncomeWEDBTaxable = null;

    rawForm.InterestIncomeWEDBAmount == '' || rawForm.InterestIncomeWEDBAmount == null ?
      InterestIncomeWEDBAmount = 0 : InterestIncomeWEDBAmount = rawForm.InterestIncomeWEDBAmount;
    InterestIncomeWEDBAmount = parseFloat(InterestIncomeWEDBAmount);

    rawForm.InterestIncomeWEDBTDS == '' || rawForm.InterestIncomeWEDBTDS == null ?
      InterestIncomeWEDBTDS = 0 : InterestIncomeWEDBTDS = rawForm.InterestIncomeWEDBTDS;
    InterestIncomeWEDBTDS = parseFloat(InterestIncomeWEDBTDS);

    rawForm.InterestIncomeWEDBTaxable == '' || rawForm.InterestIncomeWEDBTaxable == null ?
      InterestIncomeWEDBTaxable = 0 : InterestIncomeWEDBTaxable = rawForm.InterestIncomeWEDBTaxable;
    InterestIncomeWEDBTaxable = parseFloat(InterestIncomeWEDBTaxable);


    let USDollarPremiumInvestmentBondAmount, USDollarPremiumInvestmentBondTDS, USDollarPremiumInvestmentBondTaxable = null;

    rawForm.USDollarPremiumInvestmentBondAmount == '' || rawForm.USDollarPremiumInvestmentBondAmount == null ?
      USDollarPremiumInvestmentBondAmount = 0 : USDollarPremiumInvestmentBondAmount = rawForm.USDollarPremiumInvestmentBondAmount;
    USDollarPremiumInvestmentBondAmount = parseFloat(USDollarPremiumInvestmentBondAmount);

    rawForm.USDollarPremiumInvestmentBondTDS == '' || rawForm.USDollarPremiumInvestmentBondTDS == null ?
      USDollarPremiumInvestmentBondTDS = 0 : USDollarPremiumInvestmentBondTDS = rawForm.USDollarPremiumInvestmentBondTDS;
    USDollarPremiumInvestmentBondTDS = parseFloat(USDollarPremiumInvestmentBondTDS);

    rawForm.USDollarPremiumInvestmentBondTaxable == '' || rawForm.USDollarPremiumInvestmentBondTaxable == null ?
      USDollarPremiumInvestmentBondTaxable = 0 : USDollarPremiumInvestmentBondTaxable = rawForm.USDollarPremiumInvestmentBondTaxable;
    USDollarPremiumInvestmentBondTaxable = parseFloat(USDollarPremiumInvestmentBondTaxable);


    let PoundSterlingPremiumInvestmentBondAmount, PoundSterlingPremiumInvestmentBondTDS, PoundSterlingPremiumInvestmentBondTaxable = null;

    rawForm.PoundSterlingPremiumInvestmentBondAmount == '' || rawForm.PoundSterlingPremiumInvestmentBondAmount == null ?
      PoundSterlingPremiumInvestmentBondAmount = 0 : PoundSterlingPremiumInvestmentBondAmount = rawForm.PoundSterlingPremiumInvestmentBondAmount;
    PoundSterlingPremiumInvestmentBondAmount = parseFloat(PoundSterlingPremiumInvestmentBondAmount);

    rawForm.PoundSterlingPremiumInvestmentBondTDS == '' || rawForm.PoundSterlingPremiumInvestmentBondTDS == null ?
      PoundSterlingPremiumInvestmentBondTDS = 0 : PoundSterlingPremiumInvestmentBondTDS = rawForm.PoundSterlingPremiumInvestmentBondTDS;
    PoundSterlingPremiumInvestmentBondTDS = parseFloat(PoundSterlingPremiumInvestmentBondTDS);

    rawForm.PoundSterlingPremiumInvestmentBondTaxable == '' || rawForm.PoundSterlingPremiumInvestmentBondTaxable == null ?
      PoundSterlingPremiumInvestmentBondTaxable = 0 : PoundSterlingPremiumInvestmentBondTaxable = rawForm.PoundSterlingPremiumInvestmentBondTaxable;
    PoundSterlingPremiumInvestmentBondTaxable = parseFloat(PoundSterlingPremiumInvestmentBondTaxable);

    let EuroPremiumInvestmentBondAmount, EuroPremiumInvestmentBondTDS, EuroPremiumInvestmentBondTaxable = null;

    rawForm.EuroPremiumInvestmentBondAmount == '' || rawForm.EuroPremiumInvestmentBondAmount == null ?
      EuroPremiumInvestmentBondAmount = 0 : EuroPremiumInvestmentBondAmount = rawForm.EuroPremiumInvestmentBondAmount;
    EuroPremiumInvestmentBondAmount = parseFloat(EuroPremiumInvestmentBondAmount);

    rawForm.EuroPremiumInvestmentBondTDS == '' || rawForm.EuroPremiumInvestmentBondTDS == null ?
      EuroPremiumInvestmentBondTDS = 0 : EuroPremiumInvestmentBondTDS = rawForm.EuroPremiumInvestmentBondTDS;
    EuroPremiumInvestmentBondTDS = parseFloat(EuroPremiumInvestmentBondTDS);

    rawForm.EuroPremiumInvestmentBondTaxable == '' || rawForm.EuroPremiumInvestmentBondTaxable == null ?
      EuroPremiumInvestmentBondTaxable = 0 : EuroPremiumInvestmentBondTaxable = rawForm.EuroPremiumInvestmentBondTaxable;
    EuroPremiumInvestmentBondTaxable = parseFloat(EuroPremiumInvestmentBondTaxable);

    let ZeroCouponBondAmount, ZeroCouponBondTDS, ZeroCouponBondTaxable = null;

    rawForm.ZeroCouponBondAmount == '' || rawForm.ZeroCouponBondAmount == null ?
      ZeroCouponBondAmount = 0 : ZeroCouponBondAmount = rawForm.ZeroCouponBondAmount;
    ZeroCouponBondAmount = parseFloat(ZeroCouponBondAmount);

    rawForm.ZeroCouponBondTDS == '' || rawForm.ZeroCouponBondTDS == null ?
      ZeroCouponBondTDS = 0 : ZeroCouponBondTDS = rawForm.ZeroCouponBondTDS;
    ZeroCouponBondTDS = parseFloat(ZeroCouponBondTDS);

    rawForm.ZeroCouponBondTaxable == '' || rawForm.ZeroCouponBondTaxable == null ?
      ZeroCouponBondTaxable = 0 : ZeroCouponBondTaxable = rawForm.ZeroCouponBondTaxable;
    ZeroCouponBondTaxable = parseFloat(ZeroCouponBondTaxable);

    let InvestmentPensionerSavingsInstrumentAmount = null;

    rawForm.InvestmentPensionerSavingsInstrumentAmount == '' || rawForm.InvestmentPensionerSavingsInstrumentAmount == null ?
      InvestmentPensionerSavingsInstrumentAmount = 0 : InvestmentPensionerSavingsInstrumentAmount = rawForm.InvestmentPensionerSavingsInstrumentAmount;
    InvestmentPensionerSavingsInstrumentAmount = parseFloat(InvestmentPensionerSavingsInstrumentAmount);

    let InterestReceivedPensionerSavingsInstrumentAmount, InterestReceivedPensionerSavingsInstrumentTDS, InterestReceivedPensionerSavingsInstrumentTaxable = null;

    rawForm.InterestReceivedPensionerSavingsInstrumentAmount == '' || rawForm.InterestReceivedPensionerSavingsInstrumentAmount == null ?
      InterestReceivedPensionerSavingsInstrumentAmount = 0 : InterestReceivedPensionerSavingsInstrumentAmount = rawForm.InterestReceivedPensionerSavingsInstrumentAmount;
    InterestReceivedPensionerSavingsInstrumentAmount = parseFloat(InterestReceivedPensionerSavingsInstrumentAmount);

    rawForm.InterestReceivedPensionerSavingsInstrumentTDS == '' || rawForm.InterestReceivedPensionerSavingsInstrumentTDS == null ?
      InterestReceivedPensionerSavingsInstrumentTDS = 0 : InterestReceivedPensionerSavingsInstrumentTDS = rawForm.InterestReceivedPensionerSavingsInstrumentTDS;
    InterestReceivedPensionerSavingsInstrumentTDS = parseFloat(InterestReceivedPensionerSavingsInstrumentTDS);

    rawForm.InterestReceivedPensionerSavingsInstrumentTaxable == '' || rawForm.InterestReceivedPensionerSavingsInstrumentTaxable == null ?
      InterestReceivedPensionerSavingsInstrumentTaxable = 0 : InterestReceivedPensionerSavingsInstrumentTaxable = rawForm.InterestReceivedPensionerSavingsInstrumentTaxable;
    InterestReceivedPensionerSavingsInstrumentTaxable = parseFloat(InterestReceivedPensionerSavingsInstrumentTaxable);

    let SumReceivedBecauseOfPositionLikeDirectorFeeAmount, SumReceivedBecauseOfPositionLikeDirectorFeeTDS, SumReceivedBecauseOfPositionLikeDirectorFeeTaxable = null;

    rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeAmount == '' || rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeAmount == null ?
      SumReceivedBecauseOfPositionLikeDirectorFeeAmount = 0 : SumReceivedBecauseOfPositionLikeDirectorFeeAmount = rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeAmount;
    SumReceivedBecauseOfPositionLikeDirectorFeeAmount = parseFloat(SumReceivedBecauseOfPositionLikeDirectorFeeAmount);

    rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeTDS == '' || rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeTDS == null ?
      SumReceivedBecauseOfPositionLikeDirectorFeeTDS = 0 : SumReceivedBecauseOfPositionLikeDirectorFeeTDS = rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeTDS;
    SumReceivedBecauseOfPositionLikeDirectorFeeTDS = parseFloat(SumReceivedBecauseOfPositionLikeDirectorFeeTDS);

    rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeTaxable == '' || rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeTaxable == null ?
      SumReceivedBecauseOfPositionLikeDirectorFeeTaxable = 0 : SumReceivedBecauseOfPositionLikeDirectorFeeTaxable = rawForm.SumReceivedBecauseOfPositionLikeDirectorFeeTaxable;
    SumReceivedBecauseOfPositionLikeDirectorFeeTaxable = parseFloat(SumReceivedBecauseOfPositionLikeDirectorFeeTaxable);


    let HonoriumWritingArticleAmount, HonoriumWritingArticleTDS, HonoriumWritingArticleTaxable = null;

    rawForm.HonoriumWritingArticleAmount == '' || rawForm.HonoriumWritingArticleAmount == null ?
      HonoriumWritingArticleAmount = 0 : HonoriumWritingArticleAmount = rawForm.HonoriumWritingArticleAmount;
    HonoriumWritingArticleAmount = parseFloat(HonoriumWritingArticleAmount);

    rawForm.HonoriumWritingArticleTDS == '' || rawForm.HonoriumWritingArticleTDS == null ?
      HonoriumWritingArticleTDS = 0 : HonoriumWritingArticleTDS = rawForm.HonoriumWritingArticleTDS;
    HonoriumWritingArticleTDS = parseFloat(HonoriumWritingArticleTDS);

    rawForm.HonoriumWritingArticleTaxable == '' || rawForm.HonoriumWritingArticleTaxable == null ?
      HonoriumWritingArticleTaxable = 0 : HonoriumWritingArticleTaxable = rawForm.HonoriumWritingArticleTaxable;
    HonoriumWritingArticleTaxable = parseFloat(HonoriumWritingArticleTaxable);

    let RemunerationExaminerInvigilatorAmount, RemunerationExaminerInvigilatorTDS, RemunerationExaminerInvigilatorTaxable = null;

    rawForm.RemunerationExaminerInvigilatorAmount == '' || rawForm.RemunerationExaminerInvigilatorAmount == null ?
      RemunerationExaminerInvigilatorAmount = 0 : RemunerationExaminerInvigilatorAmount = rawForm.RemunerationExaminerInvigilatorAmount;
    RemunerationExaminerInvigilatorAmount = parseFloat(RemunerationExaminerInvigilatorAmount);

    rawForm.RemunerationExaminerInvigilatorTDS == '' || rawForm.RemunerationExaminerInvigilatorTDS == null ?
      RemunerationExaminerInvigilatorTDS = 0 : RemunerationExaminerInvigilatorTDS = rawForm.RemunerationExaminerInvigilatorTDS;
    RemunerationExaminerInvigilatorTDS = parseFloat(RemunerationExaminerInvigilatorTDS);

    rawForm.RemunerationExaminerInvigilatorTaxable == '' || rawForm.RemunerationExaminerInvigilatorTaxable == null ?
      RemunerationExaminerInvigilatorTaxable = 0 : RemunerationExaminerInvigilatorTaxable = rawForm.RemunerationExaminerInvigilatorTaxable;
    RemunerationExaminerInvigilatorTaxable = parseFloat(RemunerationExaminerInvigilatorTaxable);

    let IncomeTuitionAmount, IncomeTuitionTDS, IncomeTuitionTaxable = null;

    rawForm.IncomeTuitionAmount == '' || rawForm.IncomeTuitionAmount == null ?
      IncomeTuitionAmount = 0 : IncomeTuitionAmount = rawForm.IncomeTuitionAmount;
    IncomeTuitionAmount = parseFloat(IncomeTuitionAmount);

    rawForm.IncomeTuitionTDS == '' || rawForm.IncomeTuitionTDS == null ?
      IncomeTuitionTDS = 0 : IncomeTuitionTDS = rawForm.IncomeTuitionTDS;
    IncomeTuitionTDS = parseFloat(IncomeTuitionTDS);

    rawForm.IncomeTuitionTaxable == '' || rawForm.IncomeTuitionTaxable == null ?
      IncomeTuitionTaxable = 0 : IncomeTuitionTaxable = rawForm.IncomeTuitionTaxable;
    IncomeTuitionTaxable = parseFloat(IncomeTuitionTaxable);

    let IncomeTelevisionRadioParticipatingProgramAmount, IncomeTelevisionRadioParticipatingProgramTDS, IncomeTelevisionRadioParticipatingProgramTaxable = null;

    rawForm.IncomeTelevisionRadioParticipatingProgramAmount == '' || rawForm.IncomeTelevisionRadioParticipatingProgramAmount == null ?
      IncomeTelevisionRadioParticipatingProgramAmount = 0 : IncomeTelevisionRadioParticipatingProgramAmount = rawForm.IncomeTelevisionRadioParticipatingProgramAmount;
    IncomeTelevisionRadioParticipatingProgramAmount = parseFloat(IncomeTelevisionRadioParticipatingProgramAmount);

    rawForm.IncomeTelevisionRadioParticipatingProgramTDS == '' || rawForm.IncomeTelevisionRadioParticipatingProgramTDS == null ?
      IncomeTelevisionRadioParticipatingProgramTDS = 0 : IncomeTelevisionRadioParticipatingProgramTDS = rawForm.IncomeTelevisionRadioParticipatingProgramTDS;
    IncomeTelevisionRadioParticipatingProgramTDS = parseFloat(IncomeTelevisionRadioParticipatingProgramTDS);

    rawForm.IncomeTelevisionRadioParticipatingProgramTaxable == '' || rawForm.IncomeTelevisionRadioParticipatingProgramTaxable == null ?
      IncomeTelevisionRadioParticipatingProgramTaxable = 0 : IncomeTelevisionRadioParticipatingProgramTaxable = rawForm.IncomeTelevisionRadioParticipatingProgramTaxable;
    IncomeTelevisionRadioParticipatingProgramTaxable = parseFloat(IncomeTelevisionRadioParticipatingProgramTaxable);

    let OthersAmount, OthersTDS, OthersTaxable = null;

    rawForm.OthersAmount == '' || rawForm.OthersAmount == null ?
      OthersAmount = 0 : OthersAmount = rawForm.OthersAmount;
    OthersAmount = parseFloat(OthersAmount);

    rawForm.OthersTDS == '' || rawForm.OthersTDS == null ?
      OthersTDS = 0 : OthersTDS = rawForm.OthersTDS;
    OthersTDS = parseFloat(OthersTDS);

    rawForm.OthersTaxable == '' || rawForm.OthersTaxable == null ?
      OthersTaxable = 0 : OthersTaxable = rawForm.OthersTaxable;
    OthersTaxable = parseFloat(OthersTaxable);

    let TotalAmount, TotalTDS, TotalTaxable = null;

    rawForm.TotalAmount == '' || rawForm.TotalAmount == null ?
      TotalAmount = 0 : TotalAmount = rawForm.TotalAmount;
    TotalAmount = parseFloat(TotalAmount);

    rawForm.TotalTDS == '' || rawForm.TotalTDS == null ?
      TotalTDS = 0 : TotalTDS = rawForm.TotalTDS;
    TotalTDS = parseFloat(TotalTDS);

    rawForm.TotalTaxable == '' || rawForm.TotalTaxable == null ?
      TotalTaxable = 0 : TotalTaxable = rawForm.TotalTaxable;
    TotalTaxable = parseFloat(TotalTaxable);

    var otherResourcesClass = new OtherResourcesClass(

      InterestMutualOrUnitFundAmount, InterestMutualOrUnitFundTDS, InterestMutualOrUnitFundTaxable,

      CashDividendStockExchangeAmount, CashDividendStockExchangeTDS, CashDividendStockExchangeTaxable,

      InterestIncomeWEDBAmount, InterestIncomeWEDBTDS, InterestIncomeWEDBTaxable,

      USDollarPremiumInvestmentBondAmount, USDollarPremiumInvestmentBondTDS, USDollarPremiumInvestmentBondTaxable,

      PoundSterlingPremiumInvestmentBondAmount, PoundSterlingPremiumInvestmentBondTDS, PoundSterlingPremiumInvestmentBondTaxable,

      EuroPremiumInvestmentBondAmount, EuroPremiumInvestmentBondTDS, EuroPremiumInvestmentBondTaxable,

      ZeroCouponBondAmount, ZeroCouponBondTDS, ZeroCouponBondTaxable,

      InvestmentPensionerSavingsInstrumentAmount,

      InterestReceivedPensionerSavingsInstrumentAmount, InterestReceivedPensionerSavingsInstrumentTDS, InterestReceivedPensionerSavingsInstrumentTaxable,

      SumReceivedBecauseOfPositionLikeDirectorFeeAmount, SumReceivedBecauseOfPositionLikeDirectorFeeTDS, SumReceivedBecauseOfPositionLikeDirectorFeeTaxable,

      HonoriumWritingArticleAmount, HonoriumWritingArticleTDS, HonoriumWritingArticleTaxable,

      RemunerationExaminerInvigilatorAmount, RemunerationExaminerInvigilatorTDS, RemunerationExaminerInvigilatorTaxable,

      IncomeTuitionAmount, IncomeTuitionTDS, IncomeTuitionTaxable,

      IncomeTelevisionRadioParticipatingProgramAmount, IncomeTelevisionRadioParticipatingProgramTDS, IncomeTelevisionRadioParticipatingProgramTaxable,

      OthersAmount, OthersTDS, OthersTaxable,

      TotalAmount, TotalTDS, TotalTaxable,
    )

    this.form.patchValue({
      InterestMutualOrUnitFundTaxable: Number(otherResourcesClass.InterestMutualOrUnitFundTaxable).toFixed(2),
      CashDividendStockExchangeTaxable: Number(otherResourcesClass.CashDividendStockExchangeTaxable).toFixed(2),
      InterestIncomeWEDBTaxable: Number(otherResourcesClass.InterestIncomeWEDBTaxable).toFixed(2),
      USDollarPremiumInvestmentBondTaxable: Number(otherResourcesClass.USDollarPremiumInvestmentBondTaxable).toFixed(2),
      PoundSterlingPremiumInvestmentBondTaxable: Number(otherResourcesClass.PoundSterlingPremiumInvestmentBondTaxable).toFixed(2),
      EuroPremiumInvestmentBondTaxable: Number(otherResourcesClass.EuroPremiumInvestmentBondTaxable).toFixed(2),
      ZeroCouponBondTaxable: Number(otherResourcesClass.ZeroCouponBondTaxable).toFixed(2),
      InterestReceivedPensionerSavingsInstrumentTaxable: Number(otherResourcesClass.InterestReceivedPensionerSavingsInstrumentTaxable).toFixed(2),
      SumReceivedBecauseOfPositionLikeDirectorFeeTaxable: Number(otherResourcesClass.SumReceivedBecauseOfPositionLikeDirectorFeeTaxable).toFixed(2),
      HonoriumWritingArticleTaxable: Number(otherResourcesClass.HonoriumWritingArticleTaxable).toFixed(2),
      RemunerationExaminerInvigilatorTaxable: Number(otherResourcesClass.RemunerationExaminerInvigilatorTaxable).toFixed(2),
      IncomeTuitionTaxable: Number(otherResourcesClass.IncomeTuitionTaxable).toFixed(2),
      IncomeTelevisionRadioParticipatingProgramTaxable: Number(otherResourcesClass.IncomeTelevisionRadioParticipatingProgramTaxable).toFixed(2),
      OthersTaxable: Number(otherResourcesClass.OthersTaxable).toFixed(2),
      TotalAmount: Number(otherResourcesClass.TotalAmount).toFixed(2),
      TotalTDS: Number(otherResourcesClass.TotalTDS).toFixed(2),
      TotalTaxable: Number(otherResourcesClass.TotalTaxable).toFixed(2),
    })
  }
}


