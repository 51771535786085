<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <div style="margin-bottom: 0;
                    position: relative;
                    font-size: 40px;
                    line-height: 1.2;
                    -weight: 700;
                    font-weight: bold;">
                        {{ 'FAQ.HEADER' | translate }}</div>
                    <p style="text-align: center; color: #212529">{{ 'CONTACT.HELPLINE' | translate }} <a href="tel:09643 224488">{{ 'CONTACT.R5' | translate }}</a>&nbsp;&nbsp;<a href="tel:+8801791321068">{{ 'CONTACT.R6' | translate }}</a> | {{ 'CONTACT.EMAIL' | translate }} <a href="mailto:support@digitax.com.bd">support@digitax.com.bd</a></p>
                </div>
            </div>
        </div>
    </div>


</div>

<section class="faq-area ptb-100"
    style="padding-top: 0;">
    <div class="container">


        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="about-content">
                    <span class="sub-title"
                        style="margin-top: 10px; margin-bottom: 20px; font-size: 20px;">সাধারণ</span>
                </div>
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_01_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_01_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_02_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_02_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_03_Q' | translate }}

                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_03_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_04_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_04_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_05_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_05_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_06_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_06_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_07_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_07_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_08_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_08_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_09_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_09_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.GEN_10_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.GEN_10_A' | translate }}</p>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="about-content">
                    <span class="sub-title"
                        style="margin-top: 10px; margin-bottom: 20px; font-size: 20px;">কর সম্পর্কিত</span>
                </div>
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_01_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_01_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_02_Q' | translate }}
                            </a>
                            <p class="accordion-content"> {{ 'FAQ.TAX_02_A' | translate }}
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_03_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_03_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_04_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_04_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_05_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_05_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_06_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_06_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_07_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_07_A' | translate }}</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_08_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_08_A' | translate }}</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_09_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_09_A' | translate }}</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_10_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_10_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_11_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_11_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_12_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_12_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_13_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_13_A' | translate }}</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title"
                                href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                {{ 'FAQ.TAX_14_Q' | translate }}
                            </a>
                            <p class="accordion-content">{{ 'FAQ.TAX_14_A' | translate }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

