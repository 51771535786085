<div fxLayout="column"
    fxLayoutAlign="space-between">
    <div fxLayout="column"
        *ngFor="let data of dialogdata.items">
        <div fxLayout="row"
            class="pb-4 pt-4">
            <mat-icon style="color: green;"
                *ngIf="data.status=='filled'">check_circle_outline</mat-icon>
            <mat-icon style="color: #c4c4c4;"
                *ngIf="data.status!='filled'">check_circle_outline</mat-icon>
            &nbsp; &nbsp; {{data.item}}
        </div>
    </div>
    <div mat-dialog-actions
        fxLayoutAlign="end center">

        <button mat-button
            class="warn"
            fxFlex="0 0 auto"
            fxLayout="row"
            fxLayoutAlign="center center"
            style="padding: 10px; margin-right: 10px; border: 0; cursor: pointer;"
            (click)="onNoClick()">
            <span>Close</span>
        </button>
    </div>
</div>
