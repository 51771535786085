import { Directive, HostListener, HostBinding, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[fileDragDrop]'
})

export class FileDragNDropDirective
{
  //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#eee';
  @HostBinding('style.border') private borderStyle = '2px dashed';
  @HostBinding('style.border-color') private borderColor = '#696D7D';
  @HostBinding('style.border-radius') private borderRadius = '5px';

  constructor(
    private _matSnackBar: MatSnackBar,
  ) { }

  @HostListener('dragover', ['$event']) public onDragOver(evt)
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'lightgray';
    this.borderColor = 'cadetblue';
    this.borderStyle = '3px solid';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt)
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
    this.borderColor = '#696D7D';
    this.borderStyle = '2px dashed';
  }

  @HostListener('drop', ['$event']) public onDrop(evt)
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
    this.borderColor = '#696D7D';
    this.borderStyle = '2px dashed';
    
    let files = evt.dataTransfer.files;
    let valid_files: Array<File> = files;

    if ((valid_files[0].type == 'image/jpeg' || valid_files[0].type == 'image/png' || valid_files[0].type == 'image/webp' || valid_files[0].type == 'application/pdf') && valid_files[0].size < 10485760)
    {
      this.filesChangeEmiter.emit(valid_files);
    }
    else
    {
      this._matSnackBar.open("Only Image or Pdf Files under 10 MB Allowed!", 'Close', {
        verticalPosition: 'top',
        duration: 2000,
      });
    }
  }
}
