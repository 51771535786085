import { Component, HostListener, Input } from '@angular/core';


import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SalaryListService } from './Salary-List.service';

import Utils from 'app/util/utils';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SalaryEmitterService } from 'app/services/salaryEmiter.service';
import { Subscription } from 'rxjs';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';
@Component({
    selector: 'SalaryList',
    templateUrl: './Salary-List.component.html',
    styleUrls: ['./Salary-List.component.scss']
})
export class SalaryListComponent
{
    message: string;
    // subscription: Subscription;
    @Input() controls: BaseControl<string>[] = [];
    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    getData: any;
    data: any;
    IsGovtStaff: boolean;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    IsCurrentYear: any;

    formcontrols$: Observable<BaseControl<string>[]>;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: SalaryListService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private eventEmitterService: EventEmitterService,
        private dataService: SalaryEmitterService,
        private back: BackButtonService,
        private sidePanelDeleteService: SidePanelDeleteService,



    )
    {

        this.meta.addTags([
            { name: 'description', content: "digiTax Income From Salary" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Income From Salary" }
        ]);
        this.setTitle("digiTax | Income From Salary");

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }


    goto(id, typeInfo): void
    {
        this._router.navigate(['/Salary/save/' + id]);
        ;
        if (typeInfo == true)
        {
            this._router.navigate(['/Salary/Govt/save/' + id]);


        }
        else
        {
            this._router.navigate(['/Salary/save/' + id]);

        }


    }



    ngOnInit(): void
    {



        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));


        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));
        if (profileData.iscomplete == false)
        {
            let incompletefields = null;

            if (profileData.incompletefields[0] != null)
            {
                incompletefields = profileData.incompletefields[0].fields;
            }

            let incompleteprofile = null;
            if (profileData.incompleteprofile[0] != null)
            {
                incompleteprofile = profileData.incompleteprofile[0];
            }

            if (incompleteprofile == "1")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ETIN_OTHER : bangla.data.ETIN.R_ETIN_OTHER, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/EtinOthers/save']);
            }

            if (incompleteprofile == "2")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_PERSONAL_INFO : bangla.data.PERSONAL.R_ENTER_PERSONAL_INFO, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/PersonalInfo/save']);
            }

            if (incompleteprofile == "3")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONALIZE.R_PER_RETURN : bangla.data.PERSONALIZE.R_PER_RETURN, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/PersonalizeReturn']);
            }

        }
        else
        {
            this.getPageData();
        }
    }

    getPageData(): void
    {
        this._formControlService.getControlJson().subscribe((data: any) =>
        {

            if (data != null)
            {
                this._spinner.hide();
            }

            this.getData = data.data;

            if (Utils.isEmptyObject(data.data) || Utils.isEmptyObject(data.data.Body))
            {
                this._router.navigate(['/Salary/save']);
            }
        });
    }

    CheckDelete(id): void
    {
        if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
        {
            this._matSnackBar.open(this.lang == 'en' ? "Archived Data Can't be Deleted" : "Archived Data Can't be Deleted", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_DELETE : bangla.data.COMMON.LABEL_DELETE;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.deleteItem(id);
                }
                this.confirmDialogRef = null;
            });
        }
    }

    deleteItem(id): void
    {
        this._spinner.show();
        this._formControlService.deleteControl(id).subscribe((data: any) =>
        {
            if (data.isError === false)
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DELETED : bangla.data.COMMON.BUTTON_DELETED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });

                this.getPageData();
                this.sidePanelDeleteService.changeMessage(true);
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        })
    }

    nextIMenu(): void
    {
        let stepMenu = JSON.parse(localStorage.getItem('stepMenu'));
        let incomeMenu = '';
        let nextItem = '';
        let currentUrl = this._router.url;
        if (stepMenu)
        {
            incomeMenu = stepMenu.incomeMenu;
        }
        // console.log(incomeMenu, 'incomeMenu');
        // console.log(this._router.url, 'incomeMenu');
        // console.log(this._router.url.replace('/save',''), 'incomeMenu');
        // console.log(this._router.url.includes('/save'), 'incomeMenu');

        if (currentUrl.includes('/save'))
        {
            currentUrl = currentUrl.replace('/save', '');
        };

        const currentIndex = incomeMenu.indexOf(currentUrl);
        const nextIndex = (currentIndex + 1) % incomeMenu.length;
        nextItem = incomeMenu[nextIndex];
        // console.log(nextItem, 'nextItem');

        // debugger;
        // if (incomeMenu.includes(currentUrl))
        // {
        //     let index = incomeMenu.indexOf(currentUrl);
        //     if (index >= 0 && index < incomeMenu.length - 1)
        //     {
        //         nextItem = incomeMenu[index + 1];
        //     }
        //     nextItem = incomeMenu[index + 1];
        //     console.log(nextItem, 'nextItem');
        // }
    }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'income');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    prevIMenu(): void
    {
        let stepMenu = JSON.parse(localStorage.getItem('stepMenu'));
        let incomeMenu = '';
        let prevItem = '';
        let currentUrl = this._router.url;
        if (stepMenu)
        {
            incomeMenu = stepMenu.incomeMenu;
        }
        // console.log(incomeMenu, 'incomeMenu');
        // console.log(this._router.url, 'incomeMenu');
        // console.log(this._router.url.replace('/save',''), 'incomeMenu');
        // console.log(this._router.url.includes('/save'), 'incomeMenu');

        if (currentUrl.includes('/save'))
        {
            currentUrl = currentUrl.replace('/save', '');
        };

        const currentIndex = incomeMenu.indexOf(currentUrl);
        const prevIndex = (currentIndex - 1) % incomeMenu.length;
        prevItem = incomeMenu[prevIndex];
        if (prevItem == null)
        {
            prevItem = 'null'
        }
        // console.log(prevItem, 'prevItem');

        // debugger;
        // if (incomeMenu.includes(currentUrl))
        // {
        //     let index = incomeMenu.indexOf(currentUrl);
        //     if (index >= 0 && index < incomeMenu.length - 1)
        //     {
        //         nextItem = incomeMenu[index + 1];
        //     }
        //     nextItem = incomeMenu[index + 1];
        //     console.log(nextItem, 'nextItem');
        // }




    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }

}
