export class BuisnessOrProfessionClass
{
  constructor(
    private _NetProfitOfSummeryOfIncome: number,
    private _GrossProfit: number,
    private _SalesOrTurnoverOrReceipts: number,
    private _GeneralExpenses: number,
    private _TotalAssets: number,
    private _CashInhandAndBank: number,
    private _Inventories: number,
    private _FixedAssets: number,
    private _OtherAssets: number,
    private _ClosingCapital: number,
    private _OpeningCapital: number,
    private _WithdrawInIncomeYear: number,
    private _TotalCapitalAndLiabilities: number,
    private _Liabilities: number,
  )
  {

  }


  public get NetProfitOfSummeryOfIncome(): number
  {
    let ns = Number(this.GrossProfit) - Number(this.GeneralExpenses)
    return this._NetProfitOfSummeryOfIncome = ns;
  }
  public set NetProfitOfSummeryOfIncome(x: number)
  {
    this._NetProfitOfSummeryOfIncome = x;
  }

  public get GrossProfit(): number
  {
    return this._GrossProfit;
  }
  public set GrossProfit(x: number)
  {
    this._GrossProfit = x;
  }

  public get SalesOrTurnoverOrReceipts(): number
  {
    return this._SalesOrTurnoverOrReceipts;
  }
  public set SalesOrTurnoverOrReceipts(x: number)
  {
    this._SalesOrTurnoverOrReceipts = x;
  }

  public get GeneralExpenses(): number
  {
    return this._GeneralExpenses;
  }
  public set GeneralExpenses(x: number)
  {
    this._GeneralExpenses = x;
  }

  public get TotalAssets(): number
  {
    let ta = Number(this.CashInhandAndBank) +
      Number(this.Inventories) + Number(this.FixedAssets) + Number(this.OtherAssets);
    return this._TotalAssets = ta;
  }
  public set TotalAssets(x: number)
  {
    this._TotalAssets = x;
  }

  public get CashInhandAndBank(): number
  {
    return this._CashInhandAndBank;
  }
  public set CashInhandAndBank(x: number)
  {
    this._CashInhandAndBank = x;
  }

  public get Inventories(): number
  {
    return this._Inventories;
  }
  public set Inventories(x: number)
  {
    this._Inventories = x;
  }

  public get FixedAssets(): number
  {
    return this._FixedAssets;
  }
  public set FixedAssets(x: number)
  {
    this._FixedAssets = x;
  }

  public get OtherAssets(): number
  {
    return this._OtherAssets;
  }
  public set OtherAssets(x: number)
  {
    this._OtherAssets = x;
  }

  public get ClosingCapital(): number
  {
    let CapitalSum = Number(this.OpeningCapital) +
      Number(this.NetProfitOfSummeryOfIncome)
    return this._ClosingCapital = CapitalSum - Number(this.WithdrawInIncomeYear);
  }
  public set ClosingCapital(x: number)
  {
    this._ClosingCapital = x;
  }

  public get OpeningCapital(): number
  {
    return this._OpeningCapital;
  }
  public set OpeningCapital(x: number)
  {
    this._OpeningCapital = x;
  }

  public get WithdrawInIncomeYear(): number
  {
    return this._WithdrawInIncomeYear;
  }
  public set WithdrawInIncomeYear(x: number)
  {
    this._WithdrawInIncomeYear = x;
  }
  public get TotalCapitalAndLiabilities(): number
  {
    let tl = Number(this.ClosingCapital) + Number(this.Liabilities)
    return this._TotalCapitalAndLiabilities = tl;
  }
  public set TotalCapitalAndLiabilities(x: number)
  {
    this._TotalCapitalAndLiabilities = x;
  }

  public get Liabilities(): number
  {
    return this._Liabilities;
  }
  public set Liabilities(x: number)
  {
    this._Liabilities = x;
  }

}
