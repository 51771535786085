import { Component } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as lodash from 'lodash';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { QuickPanelService } from 'app/layout/components/quick-panel/quick-panel.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SalaryEmitterService } from 'app/services/salaryEmiter.service';
import { Subscription } from 'rxjs';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { FormControl } from '@angular/forms';
import { ArchiveService } from 'app/pages/Archive/Archive.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'my-demo-sidebar',
    templateUrl: './my-demo-sidebar.component.html',
    styleUrls: ['./my-demo-sidebar.component.scss']
})
export class MyDemoSidebarComponent
{

    subscription: Subscription;
    lang = '';

    topEtinData = '';
    topOwedData = null;
    topYearData = null;
    AllData = null;
    YearSwitchData = null;
    archiveStat: any;
    userInfo: any;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    YearSwitchFormControl = new FormControl();

    fiscalyear = getCurrentFinancialYear();

    private _transformer = (node: PanelNode, level: number) =>
    {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            value: node.value,
            sign: node.sign,
            id: node.id,
            url: node.url,
            info: node.info,
            nameColor: node.nameColor,
            valueColor: node.valueColor,
            level: level,
        };
    }
    hasChild = (_: number, node: MyPanelNode) => node.expandable;

    treeControl = new FlatTreeControl<MyPanelNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    nestedNodeMap = new Map<PanelNode, MyPanelNode>();

    /**
     * Constructor
     */
    constructor(
        private sidePanelSharedService: SidePanelSharedService,
        private eventEmitterService: EventEmitterService,
        private _router: Router,
        private _matDialog: MatDialog,
        private _spinner: NgxSpinnerService,

        private salaryEmitterService: SalaryEmitterService,
        private sidePanelDeleteService: SidePanelDeleteService,
        private _matSnackBar: MatSnackBar,

        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _formControlService: ArchiveService,

        private _quickPanelService: QuickPanelService,
        private _fuseNavigationService: FuseNavigationService,

    )
    {
        this.lang = localStorage.getItem('selectedLanguage');
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.YearSwitchData = JSON.parse(this.userInfo.MultiReturnList);

        let mainReturnId = this.userInfo.ReturnId;
        // console.log(Number(mainReturnId), ' Number(mainReturnId)');

        let CurrentReturnDD = lodash.filter(this.YearSwitchData, { ReturnId: Number(mainReturnId), });
        // console.log(CurrentReturnDD, 'CurrentReturnId');
        let CurrentReturnId = CurrentReturnDD[0].ReturnId;

        // let SwitchedReturnID = localStorage.getItem('SwitchedReturnID');

        // if (SwitchedReturnID != null)
        // {
        //     this.YearSwitchFormControl.setValue(SwitchedReturnID);
        // }
        // else
        // {
        //     this.YearSwitchFormControl.setValue(CurrentReturnId);

        // }
        this.YearSwitchFormControl.setValue(CurrentReturnDD[0]);
        // this.YearSwitchFormControl.setValue(CurrentReturnId);


    }

    ngOnInit(): void
    {
        this.getData();
        this.subscription = this.sidePanelDeleteService.currentMessage.subscribe(message =>
        {

            if (message == true)
            {
                this.getData();
            }
        })

    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }




    ConfirmSwitch(event): void
    {
        // console.log(event, 'event');

        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to switch year?';

        this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

        this.confirmDialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.SwitchReturn(event.value)
            }
            this.confirmDialogRef = null;
        });

    }

    SwitchReturn(object): void
    {
        this._spinner.show();

        this._formControlService.switchReturnWIthReturnId(object.ReturnId).subscribe((data: any) =>
        {
            localStorage.setItem('userToken', data.access_token);
            localStorage.setItem('userRefToken', data.refresh_token);

            delete data['access_token'];
            delete data['refresh_token'];
            delete data['token_type'];
            delete data['expires_in'];
            delete data['error'];
            delete data['.issued'];
            delete data['.expires'];

            localStorage.setItem('userInfo', JSON.stringify(data));
            localStorage.setItem('TokenInfo', data.UserId);
            localStorage.setItem('userName', data.UserName);
            localStorage.setItem('IsCurrentYear', JSON.stringify(object));
            // localStorage.setItem('SwitchedReturnID', ReturnId);
            this._spinner.hide();

            if (data.ActiveRoleId == "8")
            {
                // this._router.navigateByUrl('/Dashboard');
                window.location.href.includes('http://localhost') ? window.location.href = '/Dashboard' : window.location.href = '/Dashboard';
            }

        },
            (err: HttpErrorResponse) =>
            {
                console.log(err, 'err');
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        )
    }

    getData()
    {
        this._quickPanelService.getControlJson().subscribe((data: any) =>
        {
            // this._spinner.show();
            if (data.data != null)
            {
                this.AllData = data.data;
            }
            if (data.data.rightpanelTop != null)
            {
                let ted = lodash.filter(data.data.rightpanelTop, { "name": "etin", });
                if (ted.length > 0)
                {
                    this.topEtinData = ted[0].value;
                }

                let tod = lodash.filter(data.data.rightpanelTop, { "name": "taxowned", });
                if (tod.length > 0)
                {
                    this.topOwedData = tod;
                }
                let tyd = lodash.filter(data.data.rightpanelTop, { "name": "taxyear", });
                if (tyd.length > 0)
                {
                    this.topYearData = tyd;
                }



            }

            if (data.data.leftmenulist != null)
            {
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', data.data.leftmenulist);
                this._fuseNavigationService.setCurrentNavigation('main');

                // console.log(data.data.leftmenulist, 'leftmenulist');

                // debugger;
                let incomeMenuList = data.data.leftmenulist.filter(d => d.id == "income")[0];
                let incomeMenu = [];
                if (incomeMenuList != null)
                {
                    incomeMenu = incomeMenuList.children.map(a => a.url);
                }
                // console.log(incomeMenu, 'incomeMenu');


                let assetsMenuList = data.data.leftmenulist.filter(d => d.id == "assets")[0];
                let assetsMenu = [];

                if (assetsMenuList != null)
                {
                    assetsMenu = assetsMenuList.children.map(a => a.url);
                }
                // console.log(assetsMenu, 'assetsMenu');

                let liabilitiesMenuList = data.data.leftmenulist.filter(d => d.id == "liabilities")[0];
                let liabilitiesMenu = [];

                if (liabilitiesMenuList != null)
                {
                    liabilitiesMenu = liabilitiesMenuList.children.map(a => a.url);
                }
                // console.log(liabilitiesMenu, 'liabilitiesMenu');

                let expensesMenuList = data.data.leftmenulist.filter(d => d.id == "expenses")[0];
                let expensesMenu = [];

                if (expensesMenuList != null)
                {
                    expensesMenu = expensesMenuList.children.map(a => a.url);
                }
                // console.log(expensesMenu, 'expensesMenu');



                let stepMenu = { "incomeMenu": incomeMenu, "assetsMenu": assetsMenu, "liabilitiesMenu": liabilitiesMenu, "expensesMenu": expensesMenu, };
                // debugger;
                // console.log(stepMenu, 'stepMenu');
                localStorage.setItem('stepMenu', JSON.stringify(stepMenu));




                // let result = data.data.leftmenulist.map(a => a.url);


                // let result = lodash.map(data.data.leftmenulist.filter(d => d.type == "collapsable"));
                // console.log(result, 'result');

                // let incomeMenu = data.data.leftmenulist.filter(d => d.id == "income")[0].children.filter(d => d.badge != null).map(x => x.url);

                // let assetMenu = data.data.leftmenulist.filter(d => d.id == "assets")[0].children.filter(d => d.badge != null).map(x => x.url);

                // let stepMenu = { "incomeMenu": incomeMenu, "assetMenu": assetMenu };

                // debugger;
                // console.log(stepMenu, 'stepMenu');

                // localStorage.setItem('stepMenu', JSON.stringify(stepMenu));
            };

            // debugger;
            if (data.data.rightpanel != null)
            {
                this.dataSource.data = data.data.rightpanel;

                let TaxCal = lodash.findIndex(this.treeControl.dataNodes, { id: 'TaxCalculation' });

                this.treeControl.expand(this.treeControl.dataNodes[0]);
                this.treeControl.expand(this.treeControl.dataNodes[TaxCal]);

                // this.treeControl.expandAll();
            };

            if (data.data.checkprofile != null)
            {
                let pd = JSON.stringify(data.data.checkprofile);
                localStorage.setItem('profileData', pd);

            };
            this.sidePanelDeleteService.changeMessage(false)
            // this._spinner.hide();

        });
    }

}

function getCurrentFinancialYear()
{
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3)
    {
        fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else
    {
        fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    return fiscalyear
}

interface MyPanelNode
{
    expandable: boolean;
    name: string;
    id?: string;
    level: number;
}

interface PanelNode
{
    name: string;
    id?: string;
    value?: string;
    sign?: string;
    url?: string;
    info?: string;
    nameColor?: string;
    valueColor?: string;
    children?: PanelNode[];
}

const TREE_DATA: PanelNode[] = [
    {
        "name": "Tax Payable",
        "value": "10,45,000",
        "sign": "",
        "id": "TaxPayable",
        "url": "",
        "nameColor": "",
        "valueColor": "red",
        "info": "",
        "children":
            [
                {
                    "name": "Net Taxable Income",
                    "value": "50,45,000",
                    "sign": "+",
                    "id": "NetTaxableIncome",
                    "url": "",
                    "nameColor": "",
                    "valueColor": "",
                    "info": "",
                    "children":
                        [
                            {
                                "name": "Salary",
                                "value": "90,45,000",
                                "sign": "",
                                "id": "link",
                                "url": "/Salary",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                            {
                                "name": "Interest On Securities",
                                "value": "5,45,000",
                                "sign": "",
                                "id": "link",
                                "url": "/InterestOnSecurities",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                        ]
                },
                {
                    "name": "Tax Calculation",
                    "value": "50,00,000",
                    "sign": "-",
                    "id": "TaxCalculation",
                    "url": "",
                    "nameColor": "",
                    "valueColor": "",
                    "info": "",
                    "children":
                        [
                            {
                                "name": "1st - 0% of 3,00,000",
                                "value": "0",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "Tax-free income for male is 3,00,000",
                                "children": null,
                            },
                            {
                                "name": "2nd - 5% of 1,00,000",
                                "value": "5,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                            {
                                "name": "3rd - 10% of 3,00,000",
                                "value": "30,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                            {
                                "name": "4th - 15% of 4,00,000",
                                "value": "60,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                            {
                                "name": "5th - 20% of 5,00,000",
                                "value": "1,00,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                            {
                                "name": "6th - 25% of 34,00,000",
                                "value": "8,50,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                            {
                                "name": "Minimum Tax for Dhaka City corporation",
                                "value": "8,50,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "Dhaka City corporation",
                                "children": null,
                            },
                        ]
                },
                {
                    "name": "Surcharge",
                    "value": "5000",
                    "sign": "",
                    "id": "Surcharge",
                    "url": "",
                    "nameColor": "",
                    "valueColor": "",
                    "info": "",
                    "children":
                        [
                            {
                                "name": "1st- 10% of 10,45,000",
                                "value": "10,45,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "owner of more than 1 motor car",
                                "children": null,
                            },
                            {
                                "name": "Minimum Surcharge for 1st slab",
                                "value": "3000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "1st slab info",
                                "children": null,
                            },
                        ]
                },
                {
                    "name": "Rebate & Adjustment",
                    "value": "1,000",
                    "sign": "-",
                    "id": "RebateAdjustment",
                    "url": "",
                    "nameColor": "",
                    "valueColor": "",
                    "info": "",
                    "children":
                        [
                            {
                                "name": "Eligible Amount Up to 1.5 Million",
                                "value": "15%",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children":
                                    [
                                        {
                                            "name": "Eligible Amount",
                                            "value": "10,000",
                                            "sign": "",
                                            "id": "EligibleAmount",
                                            "url": "",
                                            "nameColor": "",
                                            "valueColor": "",
                                            "info": "The Lesser Of (a), (b) & (c)",
                                            "children":
                                                [
                                                    {
                                                        "name": "(a) Allowable Amount",
                                                        "value": "10,000",
                                                        "sign": "",
                                                        "id": "infoPad",
                                                        "url": "",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "",
                                                        "children": null,
                                                    },
                                                    {
                                                        "name": "(b) 25% of Total Income",
                                                        "value": "72,000",
                                                        "sign": "",
                                                        "id": "infoPad",
                                                        "url": "",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "Excluding any income for which a tax exemption or a reduced rate is applicable",
                                                        "children": null
                                                    },
                                                    {
                                                        "name": "(c) Or",
                                                        "value": "1,50,00,000",
                                                        "sign": "",
                                                        "id": "infoPad",
                                                        "url": "",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "",
                                                        "children": null,
                                                    },
                                                ]
                                        },
                                    ],
                            },
                            {
                                "name": "Eligible Amount over 1.5 Million",
                                "value": "10%",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null
                            },
                        ]
                },
                {
                    "name": "Asset Value",
                    "value": "1,00,000",
                    "sign": "",
                    "id": "AssetValue",
                    "url": "",
                    "nameColor": "",
                    "valueColor": "",
                    "info": "",
                    "children":
                        [
                            {
                                "name": "Previous Year Net Wealth",
                                "value": "3,50,000",
                                "sign": "",
                                "id": "infoPad",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children": null,
                            },
                            {
                                "name": "Changes in This Year",
                                "value": "4,50,000",
                                "sign": "",
                                "id": "ChangesinThisYear",
                                "url": "",
                                "nameColor": "",
                                "valueColor": "",
                                "info": "",
                                "children":
                                    [
                                        {
                                            "name": "Income",
                                            "value": "4,50,000",
                                            "sign": "+",
                                            "id": "Income",
                                            "url": "",
                                            "nameColor": "",
                                            "valueColor": "",
                                            "info": "",
                                            "children":
                                                [
                                                    {
                                                        "name": "Salary",
                                                        "value": "3,50,000",
                                                        "sign": "+",
                                                        "id": "link",
                                                        "url": "/Salary",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "",
                                                        "children": null,
                                                    },
                                                    {
                                                        "name": "Interest On Securities",
                                                        "value": "1,50,000",
                                                        "sign": "-",
                                                        "id": "link",
                                                        "url": "/InterestOnSecurities",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "",
                                                        "children": null,
                                                    }
                                                ]
                                        },
                                        {
                                            "name": "Expenses",
                                            "value": "50,000",
                                            "sign": "",
                                            "id": "Expenses",
                                            "url": "",
                                            "nameColor": "",
                                            "valueColor": "",
                                            "info": "",
                                            "children":
                                                [
                                                    {
                                                        "name": "Food Clothing & Other Essentials",
                                                        "value": "3,50,000",
                                                        "sign": "+",
                                                        "id": "link",
                                                        "url": "/FoodClothingOtherEssentials",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "",
                                                        "children": null,
                                                    },
                                                    {
                                                        "name": "Payment of Tax Charges",
                                                        "value": "1,50,000",
                                                        "sign": "-",
                                                        "id": "link",
                                                        "url": "/PaymentTaxCharges",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "",
                                                        "children": null,
                                                    }
                                                ]
                                        },
                                        {
                                            "name": "Liabilities",
                                            "value": "50,000",
                                            "sign": "",
                                            "id": "Liabilities",
                                            "url": "",
                                            "nameColor": "",
                                            "valueColor": "",
                                            "info": "",
                                            "children":
                                                [
                                                    {
                                                        "name": "Bank Loans",
                                                        "value": "3,50,000",
                                                        "sign": "+",
                                                        "id": "link",
                                                        "url": "/BankLoans",
                                                        "nameColor": "",
                                                        "valueColor": "",
                                                        "info": "",
                                                        "children": null,
                                                    },

                                                ]
                                        }
                                    ]
                            }
                        ]
                }
            ]
    }
]
