import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ArchiveComponent } from './Archive.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseDemoModule, FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ArchiveService } from './Archive.service';
import { ViewArchiveGuard } from 'app/auth/viewArchive.guard';
import { PaymentService } from '../Payment/Payment.service';

const routes = [
    {
        path: 'Archive',
        component: ArchiveComponent,
        canActivate: [AuthGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        ArchiveComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatButtonModule,

        MatMenuModule,

        MatFormFieldModule,

        MatInputModule,

        MatSlideToggleModule,

        MatSelectModule,

        MatRadioModule,

        MatSnackBarModule,

        MatTooltipModule,

        MatCheckboxModule,

        FuseProgressBarModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,
    ],
    exports: [
        ArchiveComponent
    ],
    providers: [

        ArchiveService,

        PaymentService
    ],
})

export class ArchiveModule
{
}
