import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '@fuse/shared.module';

import { ElectronicEquipmentComponent } from './ElectronicEquipment.component';
import { ElectronicEquipmentService } from './ElectronicEquipment.service';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ViewDashboardGuard } from 'app/auth/viewDashboard.guard';
import { MatMenuModule } from '@angular/material/menu';

const routes = [
    {
        path: 'ElectronicEquipment/save',
        component: ElectronicEquipmentComponent,
        canActivate: [AuthGuard, ViewDashboardGuard],
        data: { layout: Layouts.Admin },
    },
    {
        path: 'ElectronicEquipment/save/:id',
        component: ElectronicEquipmentComponent,
        canActivate: [AuthGuard, ViewDashboardGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        ElectronicEquipmentComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatFormFieldModule,

        MatInputModule,

        MatMenuModule,

        MatSnackBarModule,

        MatSlideToggleModule,

        MatSelectModule,

        MatTooltipModule,

        MatCheckboxModule,

        MatRadioModule,

        MatTabsModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,
    ],
    exports: [
        ElectronicEquipmentComponent
    ],

    providers: [

        ElectronicEquipmentService
    ],
})

export class ElectronicEquipmentModule
{
}
