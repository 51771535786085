import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ListDialogComponent } from '@fuse/components/list-dialog/list-dialog.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsListService } from './ClientsList.service';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';
import * as lodash from 'lodash';

import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AppUser } from 'app/auth';
import Utils from 'app/util/utils';
import { ProfileListService } from 'app/pages/Profile/Profile-List/ProfileList.service';
import { FetchClientsService } from 'app/services/fetchClients.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { ShowNoClient } from 'app/services/showNoClient.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { BulkUploadDocumentDialog } from './bulk-upload-dialog/bulk-Dialog.component';
import { SlipUploadDocumentDialog } from './slip-upload-dialog/slip-Dialog.component';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { BillDialog } from './bill-dialog/bill-Dialog.component';
import { CollectionDialog } from './collection-dialog/collection-Dialog.component';
import { RemissionDialog } from './remission-dialog/remission-Dialog.component';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';
import { LedgerDialog } from './ledger-dialog/ledger-Dialog.component';
import { ClientChalanDialog } from './chalan-dialog/client-chalan-Dialog.component';
import { BulkSalaryUploadDocumentDialog } from './bulk-salary-upload-dialog/bulk-salary-Dialog.component';
import { GeneralSmsDialog } from './general-sms-dialog/general-sms-dialog.component';
import { ClientEmailDialog } from './email-dialog/client-email-Dialog.component';
import { ClientDocumentDialog } from './document-dialog/client-document-Dialog.component';

@Component({
    selector: 'ClientsList',
    templateUrl: './ClientsList.component.html',
    styleUrls: ['./ClientsList.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ],
})
export class ClientsListComponent
{

    lang = '';
    hasClient = false;
    loading = true;
    foundClient = false;
    showFilter = false;
    getData: any;
    dashBoardData: any;
    dropDownData: any;
    user: AppUser;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    subscription_noClient: Subscription;
    moment: any = moment;

    ETINSearchControl = new FormControl();
    AssesseeNameSearchControl = new FormControl();
    ContactNoSearchControl = new FormControl();
    EmailSearchControl = new FormControl();
    IsDuesClientSearchControl = new FormControl();
    IsCompleteSearchControl = new FormControl();
    AssessmentYearSearchControl = new FormControl();

    AssesseeNamefilteredOptions: Observable<any[]>;

    AssesseeNameSearchData: any;


    // displayedColumns: string[] = ['ETIN', 'name', 'weight', 'symbol'];
    // displayedColumns: string[] = ['ETIN'];

    displayedColumns: string[] = ['Actions', 'DuesAmount', 'ETIN', 'AssesseeName', 'ContactNo', 'Email', 'Reference'];

    // columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];

    dataSource = new MatTableDataSource([]);

    // dataSource: any;

    expandedElement: PeriodicElement | null;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: ClientsListService,
        private _transactionControlService: TransactionService,
        private _profileControlService: ProfileListService,
        private back: BackButtonService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private eventEmitterService: EventEmitterService,
        private _route: ActivatedRoute,
        private sidePanelSharedService: SidePanelSharedService,
        private _fuseNavigationService: FuseNavigationService,
        private fetchClientsService: FetchClientsService,
        private http: HttpClient,
        private showNoClient: ShowNoClient,
        public dialog: MatDialog,
        private _liveAnnouncer: LiveAnnouncer

    )
    {

        this.meta.addTags([
            { name: 'description', content: "digiTax Clients List" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Clients List" }
        ]);
        this.setTitle("digiTax | Clients List");


    }

    @ViewChild(MatSort) sort: MatSort;

    // ngAfterViewInit()
    // {
    //     if (this.dataSource != null)
    //     {
    //         this.dataSource.sort = this.sort;
    //     }
    // }

    announceSortChange(sortState: Sort)
    {
        // console.log(sortState.direction, 'sortState.direction');

        // This example uses English messages. If your application supports
        // multiple language, you would internationalize these strings.
        // Furthermore, you can customize the message to add additional
        // details about the values being sorted.
        if (sortState.direction)
        {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else
        {
            this._liveAnnouncer.announce('Sorting cleared');
        }
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    setReturn(AssesseeId, AssesseeName): void
    {
        // debugger;
        localStorage.setItem('AssesseeId', JSON.stringify({ 'AssesseeId': AssesseeId, 'AssesseeName': AssesseeName }));
        this.fetchClientsService.changeMessage(true);
        // window.location.reload();
        this._router.navigateByUrl('/Dashboard');
        // window.location.reload();
    }

    LaunchClient(ReturnId, AssesseeId, AssesseeName): void
    {
        // console.log(AssesseeId, 'AssesseeId');

        this._spinner.show();

        this._formControlService.switchReturnWIthReturnId_AssesseeId(ReturnId, AssesseeId).subscribe((data: any) =>
        {
            // console.log(data, 'data');

            localStorage.setItem('userToken', data.access_token);
            localStorage.setItem('userRefToken', data.refresh_token);

            delete data['access_token'];
            delete data['refresh_token'];
            delete data['token_type'];
            delete data['expires_in'];
            delete data['error'];
            delete data['.issued'];
            delete data['.expires'];

            localStorage.setItem('userInfo', JSON.stringify(data));
            localStorage.setItem('TokenInfo', data.UserId);
            localStorage.setItem('userName', data.UserName);
            // localStorage.setItem('IsCurrentYear', JSON.stringify(object));
            // localStorage.setItem('SwitchedReturnID', ReturnId);
            localStorage.setItem('AssesseeId', JSON.stringify({ 'AssesseeId': AssesseeId, 'AssesseeName': AssesseeName }));


            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let YearSwitchData = JSON.parse(userInfo.MultiReturnList);

            let mainReturnId = userInfo.ReturnId;
            // console.log(Number(mainReturnId), ' Number(mainReturnId)');

            let CurrentReturnDD = lodash.filter(YearSwitchData, { ReturnId: Number(mainReturnId), });

            localStorage.setItem('IsCurrentYear', JSON.stringify(CurrentReturnDD));


            this.fetchClientsService.changeMessage(true);
            this._spinner.hide();

            // if (data.ActiveRoleId == "8")
            // {
            // this._router.navigateByUrl('/Dashboard');
            window.location.href.includes('http://localhost') ? window.location.href = '/Dashboard' : window.location.href = '/Dashboard';
            // }

        },
            (err: HttpErrorResponse) =>
            {
                console.log(err, 'err');
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        )
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.lang = localStorage.getItem('selectedLanguage');
        this.user = Utils.getUserInfo();
        this.AssesseeNameSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.AssesseeNamefilteredOptions = response.data;
                        // console.log(response, 'response');

                    });
                }
                else
                {
                    return null;
                }
            })
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }
        this.subscription_noClient = this.showNoClient.currentMessage.subscribe(message =>
        {

            if (message == true)
            {
                this._matSnackBar.open("Please Select A Client", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 5000
                });
                this.showNoClient.changeMessage(false);
            }
        })
        if (this.lang == 'en')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        if (this.lang == 'bn')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        this._spinner.show();
        this.checkProfileData();
        // this.dataSource.sort = this.sort;



        // this.getData = this._formControlService.getJson();
        // console.log(this.getData, 'getData');

    }

    checkProfileData(): void
    {
        this._profileControlService.getControlJson().subscribe((data: any) =>
        {
            if (data != null)
            {
                if (Utils.isEmptyObject(data.data) || data.data == null)
                {
                    this._spinner.hide();
                    this._router.navigate(['/Profile/save']);
                    this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_Profile : bangla.data.PROFILE.ENTER_Profile, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    // this.getPageData('null');
                    this.getPanelData();
                }
            }


        });
    }

    getPageData(param): void
    {
        this._formControlService.getControlJson(param).subscribe((data: any) =>
        {
            // if (data != null)
            // {

            // }
            // debugger;
            if (Utils.isEmptyObject(data.data) || data.data == null)
            {
                this.hasClient = false;
                this.loading = false;
                // this._router.navigate(['/Clients/save']);
            }
            else
            {
                this.hasClient = true;
                this.loading = false;
            }
            this.getData = data.data;

            this.dataSource = new MatTableDataSource(this.getData);

            this.dataSource.sort = this.sort;

            this._spinner.hide();
            // console.log(this.getData, 'this.getData clientlist');

            //    if (Utils.isEmptyObject(data.data))
            //     {
            //         this._router.navigate(['/AdvancePaidTax']);
            //     }
        });
    }


    getMainDropData(): void
    {
        this._formControlService.GetCommomDropdown().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
                this.dropDownData = data;
                // this.AssessmentYearSearchControl.setValue(this.dropDownData.CurrentYearId.toString());

            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        });
    }


    getPanelData()
    {
        this._spinner.show();
        forkJoin([
            this._formControlService.getControlJson('null'),
            this._transactionControlService.GetCommomDropdown(),
            // this._formControlService.GetClientsDashBoardData({}),
            this._formControlService.GetClientsDashBoardData(),

        ]).subscribe(
            (result: any) =>
            {
                let pageDataResponse = result[0];
                // console.log(pageDataResponse, 'pageDataResponse');

                let dropDownResponse = result[1];
                // console.log(dropDownResponse, 'dropDownResponse');

                let dashBoardResponse = result[2];
                // console.log(dashBoardResponse, 'dashBoardResponse');
                // debugger;
                if (Utils.isEmptyObject(pageDataResponse.data) || pageDataResponse.data == null)
                {
                    this.hasClient = false;
                    this.foundClient = false;
                    this.loading = false;
                    // this._router.navigate(['/Clients/save']);
                }
                else
                {
                    this.hasClient = true;
                    this.foundClient = true;
                    this.loading = false;
                }
                if (!Utils.isEmptyObject(dashBoardResponse.data) || dashBoardResponse.data != null)
                {
                    this.dashBoardData = dashBoardResponse.data;
                }

                this.getData = pageDataResponse.data;

                // console.log(this.getData, 'this.getData ');


                this.dataSource = new MatTableDataSource(this.getData);

                this.dataSource.sort = this.sort;

                if (dropDownResponse != null)
                {
                    this.dropDownData = dropDownResponse;
                    // this.AssessmentYearSearchControl.setValue(this.dropDownData.CurrentYearId.toString());
                }
                this._spinner.hide();

            }, (error: any) =>
        {
            this._spinner.hide();
            console.log(error, 'error');
        })

    }

    showSeachFilter()
    {
        this.showFilter = !this.showFilter;
        if (this.showFilter == false)
        {
            this.clearSerachFilters();
        }
    }

    clearSerachFilters()
    {
        this.ETINSearchControl.setValue('');
        this.AssesseeNameSearchControl.setValue('');
        this.ContactNoSearchControl.setValue('');
        this.EmailSearchControl.setValue('');
        this.IsDuesClientSearchControl.setValue('');
        this.IsCompleteSearchControl.setValue('');
        this.AssessmentYearSearchControl.setValue('');
        this.AssesseeNamefilteredOptions = null;
        this.AssesseeNameSearchData = null;
    }

    RefreshSearch()
    {
        this.clearSerachFilters();
        this.getPanelData();
    }

    SearchClient()
    {
        this._spinner.show();

        let isComplete = null;

        if (this.IsCompleteSearchControl.value == "1")
        {
            isComplete = "Yes";
        }
        if (this.IsCompleteSearchControl.value == "2")
        {
            isComplete = "No";
        }
        if (this.IsCompleteSearchControl.value == "")
        {
            isComplete = null;
        }

        let isDue = null;
        if (this.IsDuesClientSearchControl.value == "1")
        {
            isDue = 'Yes';
        }
        if (this.IsDuesClientSearchControl.value == "2")
        {
            isDue = 'No';
        }
        if (this.IsDuesClientSearchControl.value == "")
        {
            isDue = null;
        }

        let AssesseId = null;
        if (this.AssesseeNameSearchData != null)
        {
            AssesseId = this.AssesseeNameSearchData.AssesseId;
        }

        let body = {
            "AssesseeId": AssesseId,
            "ETIN": this.ETINSearchControl.value,
            // "AssesseeName": this.AssesseeNameSearchControl.value,
            "ContactNo": this.ContactNoSearchControl.value,
            "Email": this.EmailSearchControl.value,
            "IsDuesClient": isDue,
            "IsComplete": isComplete,
            "AssessmentYearId": this.AssessmentYearSearchControl.value,
        }
        // console.log(body, 'body');

        this._formControlService.getControlJson(body).subscribe((data: any) =>
        {

            if (Utils.isEmptyObject(data.data) || data.data == null)
            {
                this.foundClient = false;
            }
            else
            {
                this.foundClient = true;
            }
            this.getData = data.data;

            this.dataSource = new MatTableDataSource(this.getData);

            this.dataSource.sort = this.sort;

            this._spinner.hide();
            // console.log(this.getData, 'this.getData clientlist');

            //    if (Utils.isEmptyObject(data.data))
            //     {
            //         this._router.navigate(['/AdvancePaidTax']);
            //     }
        });
    }

    ConfirmSms(AssesseeId, ReturnId, SearchFlag): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to send SMS to this client?';

        this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

        this.confirmDialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.SendSms(AssesseeId, ReturnId, SearchFlag)
            }
            this.confirmDialogRef = null;
        });

    }

    SendSms(AssesseeId, ReturnId, SearchFlag): void
    {
        this._spinner.show();

        let body = {};

        if (SearchFlag == 'missing-docs')
        {
            body = {
                "AssesseeId": AssesseeId,
                "ReturnId": ReturnId,
                "SearchFlag": SearchFlag
            }
        }

        if (SearchFlag == 'dues-notice')
        {
            body = {
                "AssesseeId": AssesseeId,
                "SearchFlag": SearchFlag
            }
        }

        if (SearchFlag == 'date-running-out')
        {
            body = {
                "AssesseeId": AssesseeId,
                "SearchFlag": SearchFlag
            }
        }

        // console.log(body, 'body');

        this._formControlService.NotificationSendSms(body).subscribe((data: any) =>
        {
            // console.log(data, 'data');

            if (data.isError === false)
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? "Sms is being sent" : "Sms is being sent", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        })
    }

    openGeneralSmsDialog(ReturnId, AssesseeId): void
    {
        const dialogRef = this.dialog.open(GeneralSmsDialog, {
            disableClose: true,
            width: '500px',
            data: {
                ReturnId: ReturnId, AssesseeId: AssesseeId
            }
        });


        // dialogRef.afterClosed().subscribe(result =>
        // {
        //     if (result == 'refresh')
        //     {
        //         this.RefreshSearch();
        //     }
        // });
    }

    CheckDelete(id, type): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_DELETE : bangla.data.COMMON.LABEL_DELETE;

        this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

        this.confirmDialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                if (type == 'slip')
                {
                    this.deleteSlip(id);

                } else
                {
                    this.deleteItem(id);

                }
            }
            this.confirmDialogRef = null;
        });

    }

    deleteItem(id): void
    {
        this._spinner.show();
        this._formControlService.deleteControl(id).subscribe((data: any) =>
        {
            if (data.isError === false)
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DELETED : bangla.data.COMMON.BUTTON_DELETED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                // this.getPageData('null');
                this.RefreshSearch();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        })
    }

    deleteSlip(id): void
    {
        this._formControlService.deleteSlipControl(id).subscribe((data: any) =>
        {
            if (data.isError === false)
            {

                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DELETED : bangla.data.COMMON.BUTTON_DELETED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this.getPageData('null');
            }
            else
            {

                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        })
    }

    downloadReurnFile(AssesseeId, ReturnId): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.REPORT_DL + `${AssesseeId}` + `/${ReturnId}`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, 'Return File');
                }
                this._spinner.hide();

                // const fileURL = URL.createObjectURL(res);


                // window.open(fileURL, '_blank');
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');

            })
    }

    summaryPDF(AssesseeId, ReturnId): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.SUMMARY_DL + `${AssesseeId}` + `/${ReturnId}`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, 'Summary File');
                }
                this._spinner.hide();
                // const fileURL = URL.createObjectURL(res);


                // window.open(fileURL, '_blank');
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');

            })
    }

    AddClient(): void
    {
        if (this.dashBoardData.NumberOfClient >= this.dashBoardData.AllowedNumberOfClient)
        {
            this._matSnackBar.open("You Have Not Purchased Enough Client for this operation", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            this._router.navigateByUrl('/Clients/save');
        }
    }

    openBulkUploadDialog(): void
    {
        if (this.dashBoardData.NumberOfClient >= this.dashBoardData.AllowedNumberOfClient)
        {
            this._matSnackBar.open("You Have Not Purchased Enough Client for this operation", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            const dialogRef = this.dialog.open(BulkUploadDocumentDialog, {
                width: '500px',
                height: '350px',
                // data: { name: value }
            });

            dialogRef.afterClosed().subscribe(result =>
            {
                if (result == 'no')
                {
                    console.log('dialog canceled');
                }
                if (result == 'yes')
                {
                    // console.log('file uploaded');
                    this.getPageData('null');
                }
            });
        }
    }

    openBulkSalaryUploadDialog(): void
    {
        const dialogRef = this.dialog.open(BulkSalaryUploadDocumentDialog, {
            width: '500px',
            height: '350px',
            // data: { name: value }
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'no')
            {
                console.log('dialog canceled');
            }
            if (result == 'yes')
            {
                // console.log('file uploaded');
                this.getPageData('null');
            }
        });
    }

    openSlipUploadDialog(ReturnId, AssesseeId): void
    {
        const dialogRef = this.dialog.open(SlipUploadDocumentDialog, {
            width: '500px',
            height: '300px',
            data: { ReturnId: ReturnId, AssesseeId: AssesseeId, }
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'no')
            {
                console.log('dialog canceled');
            }
            if (result == 'yes')
            {
                // console.log('file uploaded');
                this.getPageData('null');
            }
        });
    }


    openBillDialog(ReturnId, AssesseeId): void
    {
        const dialogRef = this.dialog.open(BillDialog, {
            disableClose: true,
            data: {
                ReturnId: ReturnId, AssesseeId: AssesseeId,
                AssessmentYears: this.dropDownData.AssessmentYears,
                year: this.dropDownData.CurrentYearId.toString()
            }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'refresh')
            {
                this.RefreshSearch();
            }
        });
    }

    openCollectionDialog(ReturnId, AssesseeId): void
    {
        const dialogRef = this.dialog.open(CollectionDialog, {
            disableClose: true,
            data: {
                ReturnId: ReturnId, AssesseeId: AssesseeId,
                year: this.dropDownData.CurrentYearId.toString(),
                paymentTypes: this.dropDownData.PractitionerPaymentTypes
            }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'refresh')
            {
                this.RefreshSearch();
            }
        });
    }

    openRemissionDialog(ReturnId, AssesseeId): void
    {
        const dialogRef = this.dialog.open(RemissionDialog, {
            disableClose: true,
            data: { ReturnId: ReturnId, AssesseeId: AssesseeId, year: this.dropDownData.CurrentYearId.toString() }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'refresh')
            {
                this.RefreshSearch();
            }
        });
    }


    openLedgerDialog(ReturnId, AssesseeId, ETIN, AssesseeName, ContactNo, Reference): void
    {
        const dialogRef = this.dialog.open(LedgerDialog, {
            disableClose: true,
            data: {
                ReturnId: ReturnId, AssesseeId: AssesseeId,
                ETIN: ETIN, AssesseeName: AssesseeName, ContactNo: ContactNo, Reference: Reference
            }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            console.log(`Dialog result: ${result}`);
        });
    }

    openChalanDialog(AssesseeId, ReturnId): void
    {
        // console.log(AssesseeId, ReturnId, 'AssesseeId, ReturnId)');

        const dialogRef = this.dialog.open(ClientChalanDialog, {
            disableClose: true,
            data: {
                ReturnId: ReturnId, AssesseeId: AssesseeId,
            }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            console.log(`Dialog result: ${result}`);
        });
    }

    openEmailDialog(AssesseeId, ReturnId, AcknowledgementSlipName): void
    {
        // console.log(AssesseeId, ReturnId, 'AssesseeId, ReturnId)');

        const dialogRef = this.dialog.open(ClientEmailDialog, {
            disableClose: true,
            data: {
                ReturnId: ReturnId, AssesseeId: AssesseeId,
                AcknowledgementSlipName: AcknowledgementSlipName
            }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            console.log(`Dialog result: ${result}`);
        });
    }

    openClientDocumentDialog(AssesseeId): void
    {
        const dialogRef = this.dialog.open(ClientDocumentDialog, {
            disableClose: true,
            data: {
                AssesseeId: AssesseeId,
            }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            console.log(`Dialog result: ${result}`);
        });
    }

    downloadSlip(PATH): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.SLIP_DOWNLOADFILE + `${PATH}`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, PATH);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');

            })
    }


    expandPanel(matExpansionPanel: MatExpansionPanel, event: any)
    {
        event.stopPropagation();

        if (!this._isExpansionIndicator(event.target))
        {
            // this.card9Expanded = !this.card9Expanded;
            matExpansionPanel.close();
        }
    }

    private _isExpansionIndicator(target: EventTarget | any): boolean
    {
        const expansionIndicatorClass = "mat-expansion-indicator";
        return (
            target.classList && target.classList.contains(expansionIndicatorClass)
        );
    }

    applyFilter(event: Event)
    {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    AssesseeNameSearch(value): void
    {
        this.AssesseeNameSearchData = value;
        // console.log(this.AssesseeNameSearchData, 'AssesseeNameSearchData');

    }



    ngOnDestroy()
    {
        this.subscription_noClient.unsubscribe();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}


// export interface PeriodicElement
// {
//     name: string;
//     position: number;
//     weight: number;
//     symbol: string;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//     { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
//     { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
//     { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
//     { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
//     { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
//     { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
//     { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
//     { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
//     { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
//     { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
// ];

export interface PeriodicElement
{
    Id: number;
    PractitionerId: any;
    AssesseeId: any;
    ReturnId: any;
    ETIN: any;
    AssesseeName: any;
    ContactNo: any;
    Email: any;
    DuesAmount: any;
    Percent: any;
    Reference: any;
    Zone: any;
    Circle: any;
    ResidentialAreaId: any;
    Gender: any;
    DateOfBirth: any;
    FatherName: any;
    MotherName: any;
    MaritalStatus: any;
    SpouseName: any;
    SpouseTIN: any;
    ReturnHistory: any;
};


