import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class TaxDeductedAtSourceService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }
  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    if (id != null)
    {
      let urlConfig = new UrlConfig();
      return this.service.get(urlConfig.TAX_RA_Tax_Deducted_At_Source_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));
    }
    else
    {
      let urlConfig = new UrlConfig();
      return this.service.get(urlConfig.TAX_RA_Tax_Deducted_At_Source_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }


  }

  submitTaxDeductedAtSource(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
      let urlConfig = new UrlConfig();
      return this.service.post(urlConfig.TAX_RA_Tax_Deducted_At_Source_Submit_Url, model);
    }
    else
    {
      let urlConfig = new UrlConfig();
      return this.service.post(urlConfig.TAX_RA_Tax_Deducted_At_Source_Submit_Url + `/${id}`, model);
    }
  }


  getJson(): any
  {
    const jsonData = [
      {
        "control": "TextboxControl",
        "key": "TDSAmount",
        "label": "Amount",
        "required": true,
        "errorType": "",
        "message": "Is % Required",
        "tooltip": "Info about the Field",
        "order": 1
      },
      {
        "control": "TextboxControl",
        "key": "TDSDesc",
        "label": "Description",
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 2
      },
      {
        "control": "CheckboxControl",
        "key": "IsIncomeUnder82C",
        "label": "Is this an income under section 82C?",
        "required": false,
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 3
      }
    ];
    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
