import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { ShareofProfitInaFirmClass } from './ShareofProfitInaFirm.Class';

@Component({
  selector: 'ShareofProfitInaFirm-form-control',
  templateUrl: './ShareofProfitInaFirm-form-control.component.html',
  styleUrls: ['./ShareofProfitInaFirm.component.scss']

})
export class ShareofProfitInaFirmFormControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
  }

  onKey(event: any, key)
  {
    if (key == "PercentOfShare" || key == "FirmIncome")
    {
      let rawForm = this.form.getRawValue();

      let PercentOfShare, FirmIncome = null;

      rawForm.PercentOfShare == '' ?
        PercentOfShare = 0 : PercentOfShare = rawForm.PercentOfShare;

      rawForm.FirmIncome == '' ?
        FirmIncome = 0 : FirmIncome = rawForm.FirmIncome;

      var shareofProfitInaFirmClass = new ShareofProfitInaFirmClass(
        PercentOfShare,
        rawForm.NetValueOfShare,
        FirmIncome,
      )

      this.form.patchValue({
        NetValueOfShare: Number(shareofProfitInaFirmClass.NetValueOfShare).toFixed(2),
      })
    }
  }
}
