import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class BuisnessOrProfessionService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    if (id != null)
    {
      return this.service.get(urlConfig.INCOME_Buisness_Or_Profession_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
      return this.service.get(urlConfig.INCOME_Buisness_Or_Profession_Control_Url + "0").pipe(map(item => Utils.toControls(item.data)));
    }

  }

  submitBuisnessOrProfession(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Buisness_Or_Profession_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Buisness_Or_Profession_Submit_Url + `/${id}`, model);
    }
  }


  getJson(): any
  {
    const jsonData = [
      {
        "control": "LabelControl",
        "key": "detials",
        "label": "ব্যবসা বা পেশার বিবরণ",
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "",
        "order": 1
      },
      {
        "control": "DropdownControl",
        "key": "BusinessOrProfessionType",
        "label": "ব্যবসা বা পেশার ধরণ",
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি করদাতার ব্যবসা বা পেশার ধরণকে বোঝায়। আয়কর অধ্যাদেশ ১৯৮৪-এর ষষ্ঠ তফসিলের অধীনে ব্যবসা বা পেশাকে আয়কর থেকে ছাড় দেওয়া হবে।",
        "options": [
          {
            "label": "ব্যবসায়",
            "items": [
              {
                "key": "1",
                "value": "বিজ্ঞাপন ফার্ম"
              },
              {
                "key": "2",
                "value": "বেকারি (তৈরি)"
              },
              {
                "key": "3",
                "value": "প্রসাধনের ও অঙ্গসজ্জার দোকান"
              },
              {
                "key": "4",
                "value": "বিড়ি তৈরি"
              },
              {
                "key": "5",
                "value": "বিড়ি তামাক"
              },
              {
                "key": "6",
                "value": "বিস্কুট তৈরি"
              },
              {
                "key": "7",
                "value": "বই প্রকাশনা"
              },
              {
                "key": "8",
                "value": "বই ক্রয় বিক্রয়"
              },
              {
                "key": "9",
                "value": "ইটখোলা"
              },
              {
                "key": "10",
                "value": "নির্মাতা ও ডেভেলপার"
              },
              {
                "key": "11",
                "value": "কেবল অপারেটর"
              },
              {
                "key": "12",
                "value": "সি এবং এফ এজেন্ট এবং মাল হ্যান্ডলার"
              },
              {
                "key": "13",
                "value": "সিমেন্ট পাইকারি"
              },
              {
                "key": "14",
                "value": "চানাচুর মিষ্টান্ন"
              },
              {
                "key": "15",
                "value": "চাইনিজ, ভারতীয় খাদ্যের দোকান"
              },
              {
                "key": "16",
                "value": "সিনেমা হল"
              },
              {
                "key": "17",
                "value": "সিনেমা প্রযোজক"
              },
              {
                "key": "18",
                "value": "কাপড় (খুচরা)"
              },
              {
                "key": "19",
                "value": "কাপড় (পাইকারী)"
              },
              {
                "key": "20",
                "value": "কোচিং সেন্টার"
              },
              {
                "key": "21",
                "value": "কমিউনিটি সেন্টার"
              },
              {
                "key": "22",
                "value": "কম্পিউটার প্রশিক্ষণ কেন্দ্র"
              },
              {
                "key": "23",
                "value": "মিষ্টান্ন"
              },
              {
                "key": "24",
                "value": "চুক্তি কাজ (নির্মাণ)"
              },
              {
                "key": "25",
                "value": "কুরিয়ার সার্ভিস"
              },
              {
                "key": "26",
                "value": "ক্রোকারিজ"
              },
              {
                "key": "27",
                "value": "সাইবার ক্যাফে"
              },
              {
                "key": "28",
                "value": "সাইকেলের যন্ত্রাংশ"
              },
              {
                "key": "29",
                "value": "সাইকেল বিক্রয়"
              },
              {
                "key": "30",
                "value": "ডাল (পালস) খুচরো"
              },
              {
                "key": "31",
                "value": "বিতরণ"
              },
              {
                "key": "32",
                "value": "ডাই ও কেমিক্যালস (স্থানীয়)"
              },
              {
                "key": "33",
                "value": "ডাই ও কেমিক্যালস পাইকারি"
              },
              {
                "key": "34",
                "value": "বৈদ্যুতিক মালামাল"
              },
              {
                "key": "35",
                "value": "প্রকৌশল কর্মশালা"
              },
              {
                "key": "36",
                "value": "ফাস্ট ফুড"
              },
              {
                "key": "37",
                "value": "ফল"
              },
              {
                "key": "38",
                "value": "আসবাবপত্র"
              },
              {
                "key": "39",
                "value": "কাচবস্তু"
              },
              {
                "key": "40",
                "value": "স্বর্ণ, রূপা ও ব্রোঞ্জ তৈরি"
              },
              {
                "key": "41",
                "value": "স্বর্ণ বিক্রয় (জুয়েলারী)"
              },
              {
                "key": "42",
                "value": "মুদি (খুচরা)"
              },
              {
                "key": "43",
                "value": "মুদি (পাইকারি)"
              },
              {
                "key": "44",
                "value": "অতিথিশালা"
              },
              {
                "key": "45",
                "value": "গুর"
              },
              {
                "key": "46",
                "value": "তাঁত কাপড়"
              },
              {
                "key": "47",
                "value": "হার্ডওয়্যার (খুচরা)"
              },
              {
                "key": "48",
                "value": "হার্ডওয়্যার (পাইকারী)"
              },
              {
                "key": "49",
                "value": "হোসিয়ারি (তৈরি)"
              },
              {
                "key": "50",
                "value": "হোসিয়ারি (বেশি)"
              },
              {
                "key": "51",
                "value": "হোটেল (অনাআবাসিক)"
              },
              {
                "key": "52",
                "value": "হোটেল (আবাসিক)"
              },
              {
                "key": "53",
                "value": "হোটেল এবং বোর্ডিং"
              },
              {
                "key": "54",
                "value": "হোটেল এন্ড রেঁস্তোরা"
              },
              {
                "key": "55",
                "value": "আইসক্রিম ফ্যাক্টরি"
              },
              {
                "key": "56",
                "value": "ওষুধ আমদানি"
              },
              {
                "key": "57",
                "value": "ইন্টারনেট সার্ভিস প্রোভাইডার (আইএসপি)"
              },
              {
                "key": "58",
                "value": "জুয়েলারী ব্যবসা"
              },
              {
                "key": "59",
                "value": "কে তেল পরিবেশক"
              },
              {
                "key": "60",
                "value": "কিন্ডারগার্টেন"
              },
              {
                "key": "61",
                "value": "শ্রম ও বাঁধ"
              },
              {
                "key": "62",
                "value": "লন্ড্রি প্রাপ্তি"
              },
              {
                "key": "63",
                "value": "লেদার ও বৈদ্যুতিক তৈরি"
              },
              {
                "key": "64",
                "value": "চর্মপেটিকা ​​(তৈরি)"
              },
              {
                "key": "65",
                "value": "যন্ত্রপাতি সরঞ্জাম"
              },
              {
                "key": "66",
                "value": "চার্জ (অলঙ্কার) মেকিং"
              },
              {
                "key": "67",
                "value": "ওষুধ (খুচরা)"
              },
              {
                "key": "68",
                "value": "ওষুধ (পাইকারী)"
              },
              {
                "key": "69",
                "value": "ওষুধ (রোগী)"
              },
              {
                "key": "70",
                "value": "রাবার স্ট্যাম্প তৈরি"
              },
              {
                "key": "71",
                "value": "মিল তৈরি কাপড় (পাইকারি)"
              },
              {
                "key": "72",
                "value": "মিল দোকান"
              },
              {
                "key": "73",
                "value": "বিবিধ ব্যবসা"
              },
              {
                "key": "74",
                "value": "মুদ্রা পরিবর্তক"
              },
              {
                "key": "75",
                "value": "সরিষা তৈরি"
              },
              {
                "key": "76",
                "value": "সরিষা তেল (খুচরা)"
              },
              {
                "key": "77",
                "value": "সরিষা তেল (পাইকারী)"
              },
              {
                "key": "78",
                "value": "অফিস স্টেশনারি"
              },
              {
                "key": "79",
                "value": "অপটিক্যাল বিক্রয় & মেরামত"
              },
              {
                "key": "80",
                "value": "অন্যান্য"
              },
              {
                "key": "81",
                "value": "প্যাকেজ প্রোগ্রাম"
              },
              {
                "key": "82",
                "value": "রঙ (তৈরি)"
              },
              {
                "key": "83",
                "value": "ফটোগ্রাফি"
              },
              {
                "key": "84",
                "value": "প্রেসক্রিপশন ভ্যালু (রশিদ)"
              },
              {
                "key": "85",
                "value": "মুদ্রণ প্রেস (উপর প্রাপ্তি)"
              },
              {
                "key": "86",
                "value": "বেসরকারি কলেজ"
              },
              {
                "key": "87",
                "value": "বেসরকারী হাসপাতাল ক্লিনিক ডায়াগনস্টিক সেন্টার"
              },
              {
                "key": "88",
                "value": "প্রাইভেট বিদ্যালয়"
              },
              {
                "key": "89",
                "value": "ব্যক্তিগত সিকিউরিটিজ পরিষেবা"
              },
              {
                "key": "90",
                "value": "উৎপাদক"
              },
              {
                "key": "91",
                "value": "রেডিও, টিভি ও গ্রামোফোন বিক্রয়"
              },
              {
                "key": "92",
                "value": "রেডিও, টিভি মেরামত"
              },
              {
                "key": "93",
                "value": "রেডিও অংশ (খুচরা)"
              },
              {
                "key": "94",
                "value": "রেডিও গাড়ির পার্টস, যন্ত্রাংশ"
              },
              {
                "key": "95",
                "value": "রেশনযুক্ত পণ্য"
              },
              {
                "key": "96",
                "value": "তৈরি পোশাক"
              },
              {
                "key": "97",
                "value": "পুনরায় রোলিং মিল"
              },
              {
                "key": "98",
                "value": "চাল (খুচরো)"
              },
              {
                "key": "99",
                "value": "চাল (পাইকারী)"
              },
              {
                "key": "100",
                "value": "অন্যান্য সামগ্রী পাইকারি সঙ্গে লবণ"
              },
              {
                "key": "101",
                "value": "স্যানিটারি জিনিসপত্র"
              },
              {
                "key": "102",
                "value": "লোহার টুকরা"
              },
              {
                "key": "103",
                "value": "জুতা (খুচরা)"
              },
              {
                "key": "104",
                "value": "সাবান (তৈরি)"
              },
              {
                "key": "105",
                "value": "স্টেশনারি (খুচরা)"
              },
              {
                "key": "106",
                "value": "পাথর"
              },
              {
                "key": "107",
                "value": "চিনি, রেশন দোকানের জন্য নয়"
              },
              {
                "key": "108",
                "value": "সাপ্লাই ব্যবসা"
              },
              {
                "key": "109",
                "value": "মিষ্টান্ন (তৈরি)"
              },
              {
                "key": "110",
                "value": "দর্জির কাজ"
              },
              {
                "key": "111",
                "value": "চা (খুচরা)"
              },
              {
                "key": "112",
                "value": "চা পাতা"
              },
              {
                "key": "113",
                "value": "কাঠ"
              },
              {
                "key": "114",
                "value": "তামাক"
              },
              {
                "key": "115",
                "value": "ট্রাভেল এজেন্ট"
              },
              {
                "key": "116",
                "value": "টায়ারের যন্ত্রাংশ"
              },
              {
                "key": "117",
                "value": "ছাতা তৈরি এবং বিক্রয়"
              },
              {
                "key": "118",
                "value": "ওয়াশিং লন্ড্রি"
              }
            ]
          },
          {
            "label": "পেশা",
            "items": [
              {
                "key": "119",
                "value": "অভিনেতা"
              },
              {
                "key": "120",
                "value": "অভিনেত্রী"
              },
              {
                "key": "121",
                "value": "উপদেষ্টা"
              },
              {
                "key": "122",
                "value": "স্থপতি"
              },
              {
                "key": "123",
                "value": "শিল্পী"
              },
              {
                "key": "124",
                "value": "চার্টার্ড হিসাবরক্ষক"
              },
              {
                "key": "125",
                "value": "পরামর্শকারী"
              },
              {
                "key": "126",
                "value": "ডাক্তার"
              },
              {
                "key": "127",
                "value": "প্রকৌশলী"
              },
              {
                "key": "128",
                "value": "আইনজীবী"
              },
              {
                "key": "129",
                "value": "অন্যান্য"
              },
              {
                "key": "130",
                "value": "কম্পউণ্ডার"
              },
              {
                "key": "131",
                "value": "প্রয়োজক"
              },
              {
                "key": "132",
                "value": "প্রোগ্রামার"
              },
              {
                "key": "133",
                "value": "গায়ক"
              }
            ]
          }
        ],
        "order": 2
      },
      {
        "control": "TextboxControl",
        "key": "BusinessOrProfessionName",
        "label": "ব্যবসা বা পেশার নাম (ট্রেড লাইসেন্স অনুসারে)",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি করদাতার ব্যবসায় বা পেশার নাম বোঝায়।",
        "order": 3
      },
      {
        "control": "TextboxControl",
        "key": "Address",
        "label": "ঠিকানা",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি করদাতার ব্যবসায় বা পেশার ঠিকানা বোঝায়।",
        "order": 4
      },
      {
        "control": "LabelControl",
        "key": "summary",
        "label": "আয়ের সংক্ষিপ্ত বিবরণ",
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "",
        "order": 5
      },
      {
        "control": "LabelGroupControl",
        "key": "summaryLg",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 6,
        "children": [
          {
            "control": "TextboxControl",
            "key": "summaryLgSource",
            "label": "উৎস সমূহ",
            "required": false,
            "errorType": "",
            "message": "আবশ্যক",
            "tooltip": "",
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "summaryLgSourceAmount",
            "label": "পরিমাণ",
            "required": false,
            "errorType": "",
            "message": "আবশ্যক",
            "tooltip": "",
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxControl",
        "key": "GrossProfit",
        "label": "পুরো লাভ ",
        "required": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি করদাতার ব্যবসায় বা পেশার বার্ষিক মোট মুনাফার পরিমাণকে বোঝায়।",
        "order": 7
      },
      {
        "control": "TextboxControl",
        "key": "SalesOrTurnoverOrReceipts",
        "label": "বিক্রি",
        "required": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি করদাতার ব্যবসায় বা পেশার বার্ষিক বিক্রয় / খাটানো টাকা / প্রাপ্তির পরিমাণকে বোঝায়।",
        "order": 8
      },
      {
        "control": "TextboxControl",
        "key": "GeneralExpenses",
        "label": "অন্যান্য খরচ",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি করদাতার ব্যবসায় বা পেশার বার্ষিক সাধারণ, প্রশাসনিক, বিক্রয় এবং অন্যান্য ব্যয়ের পরিমাণকে বোঝায়।",
        "order": 9
      },
      {
        "control": "TextboxControl",
        "key": "NetProfitOfSummeryOfIncome",
        "label": "মোট লাভ",
        "tabindex": "-1",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি ব্যবসা বা পেশা থেকে নীট মুনাফা আয় বোঝায়।",
        "order": 10
      },
      {
        "control": "LabelControl",
        "key": "summaryBalance",
        "label": "ব্যালেন্স শীট সারসংক্ষেপ",
        "errorType": "",
        "message": "আবশ্যক",
        "tabindex": "-1",
        "readonly": true,
        "tooltip": "",
        "order": 11
      },
      {
        "control": "LabelGroupControl",
        "key": "summaryBalanceLg",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 12,
        "children": [
          {
            "control": "LabelControl",
            "key": "summaryBalanceLgSource",
            "label": "উৎস সমূহ",
            "errorType": "",
            "message": "আবশ্যক",
            "tooltip": "",
            "order": 1
          },
          {
            "control": "LabelControl",
            "key": "summaryBalanceLgAmount",
            "label": "পরিমাণ",
            "required": false,
            "errorType": "",
            "message": "আবশ্যক",
            "tooltip": "",
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxControl",
        "key": "CashInhandAndBank",
        "label": "নগদ বা ব্যাংক তহবিল",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি নগদ বা ব্যাংকে রাখা অর্থের পরিমাণ বোঝায়।",
        "order": 13
      },
      {
        "control": "TextboxControl",
        "key": "Inventories",
        "label": "গৃহোপকরন,আসবাবপত্র,পণ্যদ্রব্য ইত্যাদি",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি গৃহোপকরন,আসবাবপত্র,পণ্যদ্রব্য ইত্যাদির ক্ষেত্রে মোট ব্যয়কে বোঝায়।",
        "order": 14
      },
      {
        "control": "TextboxControl",
        "key": "FixedAssets",
        "label": "স্থায়ী সম্পদ",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি স্থায়ী সম্পদের পরিমাণ বোঝায়।",
        "order": 15
      },
      {
        "control": "TextboxControl",
        "key": "OtherAssets",
        "label": "অন্যান্য সম্পদ",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি অন্যান্য সম্পদের পরিমাণ বোঝায়।",
        "order": 16
      },
      {
        "control": "TextboxControl",
        "key": "TotalAssets",
        "label": "মোট সম্পদ",
        "tabindex": "-1",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি মোট সম্পদের পরিমাণ বোঝায়।",
        "order": 17
      },
      {
        "control": "TextboxControl",
        "key": "OpeningCapital",
        "label": "প্রারম্ভিক মূলধন",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি ব্যবসা বা পেশার উদ্বোধনী মূলধনের পরিমাণকে বোঝায়।",
        "order": 18
      },
      {
        "control": "TextboxControl",
        "key": "NetProfitOfSummeryOfBalanceSheet",
        "label": "মোট লাভ",
        "tabindex": "-1",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি ব্যবসা বা পেশা থেকে নীট লাভকে বোঝায়।",
        "order": 19
      },
      {
        "control": "TextboxControl",
        "key": "WithdrawInIncomeYear",
        "label": "আয়কৃত বছরে উত্তোলন",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি আয়কৃত বছরে মোট উত্তোলন করা টাকার পরিমাণকে বোঝায়।",
        "order": 20
      },
      {
        "control": "TextboxControl",
        "key": "ClosingCapital",
        "label": "সর্বশেষ মূলধন",
        "required": false,
        "tabindex": "-1",
        "readonly": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি সর্বশেষ মূলধনের পরিমাণ বোঝায়।",
        "order": 21
      },
      {
        "control": "TextboxControl",
        "key": "Liabilities",
        "label": "ঋণ",
        "required": false,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি দায়বদ্ধতার পরিমাণ বোঝায়।",
        "order": 22
      },
      {
        "control": "TextboxControl",
        "key": "TotalCapitalAndLiabilities",
        "label": "ঋণকৃত মূলধন",
        "required": false,
        "tabindex": "-1",
        "readonly": true,
        "errorType": "",
        "message": "আবশ্যক",
        "tooltip": "এটি মোট মূলধন দায়গুলির পরিমাণ বোঝায়।",
        "order": 23
      },
      {
        "control": "CheckboxControl",
        "key": "IsTaxFreeBusiness",
        "label": "আপনার ব্যবসায়ের আয় কি আয়কর অধ্যাদেশ ১৯৮৪ এর ষষ্ঠ তফসিলের আওতায় আয়কর থেকে অব্যাহতি পেয়েছে?",
        "value": null,
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 24
      },
      {
        "control": "TextboxGroupControl",
        "key": "final",
        "errorType": "",
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 24,
        "children": [
          {
            "control": "TextboxControl",
            "key": "TotalIncome",
            "label": "আয়ের পরিমাণ",
            "required": false,
            "tabindex": "-1",
            "readonly": true,
            "errorType": "",
            "message": "আবশ্যক",
            "tooltip": "এটি ব্যবসা বা পেশা থেকে আয়ের মোট পরিমাণ বোঝায়।",
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "TaxableIncome",
            "label": "নীট করযোগ্য আয়",
            "required": false,
            "tabindex": "-1",
            "readonly": true,
            "errorType": "",
            "message": "আবশ্যক",
            "tooltip": "এটি ব্যবসা বা পেশা থেকে করযোগ্য আয়ের মোট পরিমাণ বোঝায়।",
            "order": 2
          }
        ]
      }
    ];
    return jsonData;
  }

  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
