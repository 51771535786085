import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class FoodClothingOtherEssentialsListService
{
  constructor(private service: Service) { }

  getControlJson(categoryId: string): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.EXPENSES_LIST + `/${categoryId}`);
  }

  getJson(): any
  {
    const jsonData = {
      "Header": {
        "Header1": "Sl",
        "Header2": "Amount",
        "Header3": "Comment",
        "Header4": "Description"
      },
      "Body": [
        {
          "Category": "1",
          "SubCategoryId": "1",
          "SubCategory": "Food",
          "SlNo": "1",
          "Amount": "50.00",
          "Comment": "test c",
          "Description": "test des"
        },
        {
          "Category": "1",
          "SubCategoryId": "3",
          "SubCategory": "Other Essential",
          "SlNo": "1",
          "Amount": "1212.00",
          "Comment": "234",
          "Description": "43"
        }
      ]
    }
    return jsonData;
  }
}
