<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            fusePerfectScrollbar
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>
    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">
            <span class="h1"
                style="padding: 10px; margin-left: 10px;">
                {{ 'ETIN.HEADER' | translate }}
            </span>
            <a [routerLink]="'/EtinOthers/save'"
                mat-raised-button
                style="margin-right: 20px;"
                class="mat-button dupl"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="s-16 mr-sm-4">create</mat-icon>
                <span>{{ 'COMMON.BUTTON_EDIT' | translate }}</span>
            </a>

        </div>
        <div class="content mb-48"
            fusePerfectScrollbar>
            <div class="course-step p-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row">
                <table class="simple invoice-table"
                    *ngIf="getData!=null"
                    fxLayout="column"
                    fxLayoutAlign="start">

                    <tr *ngFor="let i of getData">
                        <th>
                            <h2 style="color: #206030;"> {{i.head}}</h2>
                        </th>
                        <td *ngFor="let data of i.data"
                            style="margin-left: 20px; padding: 10px;"
                            fxLayout="row wrap"
                            fxLayoutAlign="start space-between">
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB; white-space:pre-wrap; word-wrap:break-word;">
                                {{data.source}}</div>
                            <div fxFlex="40"
                                style=" border-bottom: 1px solid #C5C6CB;">{{data.value}}</div>
                        </td>
                    </tr>

                </table>
            </div>
        </div>
        <div class="step-navigation">
            <button mat-raised-button
                [routerLink]="'/PersonalInfo'"
                style="border: 2px solid #37793c;
                background-color: #ffffff;
                line-height: 30px;
                cursor: pointer;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>
    </div>
</div>
