<div [formGroup]="form">

  <div [ngSwitch]="control.controlType"
    fxLayout="column"
    fxLayoutAlign="start"
    fxFlex="100">
    <div class="h2 mb-24"
      style="margin-top:20px;color: #206030;"
      *ngSwitchCase="'label'">{{control.label}}</div>
    <div *ngSwitchCase="'textbox'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">
      <div fxLayoutAlign="space-between center"
        fxLayout="row wrap"
        fxFlex="100"
        style="margin-bottom: 20px;">

        <span class="name"
          style="font-size: 15px;"
          fxFlex="50"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>

        <mat-form-field appearance="outline"
          class="{{control.readonly==true ? 'readonly' : ''}}"
          *ngIf="control.key=='LocationAndDescription'"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            tabindex={{control.tabindex}}
            class="leftA"
            autocomplete="off"
            (keyup)="onKey($event,control.key)"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
          class="{{control.readonly==true ? 'readonly' : ''}}"
          *ngIf="control.key=='TotalArea'"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            tabindex={{control.tabindex}}
            class="leftA"
            autocomplete="off"
            (keyup)="onKey($event,control.key)"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
          *ngIf="control.key!='LocationAndDescription'&&control.key!='TotalArea'"
          class="{{control.readonly==true ? 'readonly' : ''}}"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            tabindex={{control.tabindex}}
            class="rightA"
            NumbersOnly
            type="text"
            decimals="100"
            negative="1"
            (click)="$event.target.select()"
            (keyup)="onKey($event,control.key)"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="'radio'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">
      <div fxLayout="row wrap"
        fxLayoutAlign="center space-between"
        appearance="outline"
        fxFlex="100"
        style="margin-bottom: 20px;">
        <span class="name"
          style="font-size: 15px;"
          fxFlex="50"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>
        <div fxLayout="row"
          fxFlex="50"
          style="margin-top: 10px;margin-bottom: 10px;">
          <mat-radio-group [formControlName]="control.key"
            [ngClass]="{'invalid': form.get('PropertyType').invalid && rPropertyType}"
            required={{control.required}}>
            <mat-radio-button style="margin-right: 10px;"
              *ngFor="let Source of control.options"
              [value]="Source.key"
              (change)="radioChange2($event)">
              {{Source.value}}
            </mat-radio-button>
          </mat-radio-group>

        </div>
      </div>
    </div>
    <div *ngSwitchCase="'textarea'"
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      fxFlex="100">
      <div fxLayoutAlign="space-between center"
        fxLayout="row wrap"
        fxFlex="100"
        style="margin-bottom: 20px;">

        <span class="name"
          style="font-size: 15px;"
          fxFlex="50"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>
        <mat-form-field appearance="outline"
          fxFlex="50">
          <textarea *ngSwitchCase="'textarea'"
            tabindex={{control.tabindex}}
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            rows="5"
            matInput
            required={{control.required}}>
             </textarea>

          <mat-error> {{control.message}}</mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>

</div>
