import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService, Registeration } from 'app/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { BackButtonService } from 'app/services/BackButtonService';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import Utils from 'app/util/utils';

@Component({
    selector: 'register-home',
    templateUrl: './register-home.component.html',
    styleUrls: ['./register-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RegisterHomeComponent implements OnInit, OnDestroy
{
    registerForm: FormGroup;
    registerFormInd: FormGroup;
    registerFormPro: FormGroup;
    errorMessage: string;
    isLoginError = false;
    submitted = false;
    submittedInd = false;
    submittedPro = false;
    isIndFrom = true;
    acceptTermInd = false;
    acceptTermPro = false;
    showTerm = false;
    fieldTextType: boolean;
    fieldTextTypeInd: boolean;
    fieldTextTypePro: boolean;
    // Private
    private _unsubscribeAll: Subject<any>;
    user: Registeration;
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

    passwordPattern = "(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}";

    preType: any;


    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: HomeTranslationLoaderService,
        private back: BackButtonService,
        private meta: Meta,
        private title: Title,
        private _route: ActivatedRoute,

    )
    {
        this.meta.addTags([
            { name: 'description', content: 'Create Account digiTax' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'Create Account digiTax, digiTax Registration' }
        ]);
        this.setTitle('digiTax | Create Account');
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._spinner.hide();
        this.fieldTextType = false;
        this.fieldTextTypeInd = false;
        this.fieldTextTypePro = false;
        // this.registerForm = this._formBuilder.group({
        //     name: ['', Validators.required],
        //     email: ['', Validators.compose([Validators.required, Utils.emailPatternValidator()])],
        //     userType: ['', Validators.required],
        //     password: ['', Validators.compose([Validators.required, Utils.passwordPatternValidator()])],
        //     passwordConfirm: ['', [Validators.required]]
        // }, { validator: Utils.MatchPassword('password', 'passwordConfirm'), });

        this.buildForm();

        this.preType = this._route.snapshot.paramMap.get('preType');

        if (this.preType == 'p')
        {
            this.setPro();
        }

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    get registerFormIndControl()
    {
        return this.registerFormInd.controls;
    }
    get registerFormProControl()
    {
        return this.registerFormPro.controls;
    }


    buildForm(): void
    {
        this.registerFormInd = this._formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.compose([Validators.required, Utils.emailPatternValidator()])],
            userType: ['1'],
            acceptTerm: ['', Validators.required],
            password: ['', Validators.compose([Validators.required, Utils.passwordPatternValidator()])],
            passwordConfirm: ['', [Validators.required]]
        }, { validator: Utils.MatchPassword('password', 'passwordConfirm'), });

        this.registerFormPro = this._formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.compose([Validators.required, Utils.emailPatternValidator()])],
            userType: ['2'],
            acceptTerm: ['', Validators.required],
            password: ['', Validators.compose([Validators.required, Utils.passwordPatternValidator()])],
            passwordConfirm: ['', [Validators.required]]
        }, { validator: Utils.MatchPassword('password', 'passwordConfirm'), });
    }

    resetFormInd(): void
    {
        this.registerFormInd.reset();
        this.registerFormInd.markAsPristine();
        this.registerFormInd.markAsUntouched();
        this.registerFormInd.updateValueAndValidity();
        this.submittedInd = false;

    }

    resetFormPro(): void
    {
        this.registerFormPro.reset();
        this.registerFormPro.markAsPristine();
        this.registerFormPro.markAsUntouched();
        this.registerFormPro.updateValueAndValidity();
        this.submittedPro = false;

    }

    toggleFieldTextType()
    {
        this.fieldTextType = !this.fieldTextType;
    }

    toggleFieldTextTypeInd()
    {
        this.fieldTextTypeInd = !this.fieldTextTypeInd;
    }

    toggleFieldTextTypePro()
    {
        this.fieldTextTypePro = !this.fieldTextTypePro;
    }

    setInd(): void
    {
        this.isIndFrom = true;
        let indSet: HTMLElement = document.getElementById("indSet") as HTMLElement;

        if (!indSet.classList.contains('mySelected'))
        {
            indSet.classList.add('mySelected');
        }

        let proSet: HTMLElement = document.getElementById("proSet") as HTMLElement;

        if (proSet.classList.contains('mySelected'))
        {
            proSet.classList.remove('mySelected');
        }
        this.resetFormPro();
        this.buildForm();
        // console.log(this.registerFormInd.value, 'this.registerFormInd.');

    }

    setPro(): void
    {
        this.isIndFrom = false;
        let indSet: HTMLElement = document.getElementById("indSet") as HTMLElement;

        if (indSet.classList.contains('mySelected'))
        {
            indSet.classList.remove('mySelected');
        }

        let proSet: HTMLElement = document.getElementById("proSet") as HTMLElement;

        if (!proSet.classList.contains('mySelected'))
        {
            proSet.classList.add('mySelected');
        }
        this.resetFormInd();
        this.buildForm();
        // console.log(this.registerFormPro.value, 'this.registerFormPro.');

    }

    termAcceptInd()
    {
        var checkBox = document.getElementById("acceptTermInd") as HTMLInputElement;
        if (checkBox.checked == true)
        {
            this.acceptTermInd = true;
        }
        if (checkBox.checked == false)
        {
            this.acceptTermInd = false;
        }
    }

    termAcceptPro()
    {
        var checkBox = document.getElementById("acceptTermPro") as HTMLInputElement;
        if (checkBox.checked == true)
        {
            this.acceptTermPro = true;
        }
        if (checkBox.checked == false)
        {
            this.acceptTermPro = false;
        }
    }

    OnSubmitInd(): void
    {
        this.submittedInd = true;

        if (this.registerFormInd.valid)
        {
            this._spinner.show();
            this._authService.register(this.registerFormInd.value)
                .subscribe((data: any) =>
                {
                    if (data.Error === false)
                    {
                        this._spinner.hide();
                        const email = this.registerFormInd.get('email').value;
                        this.resetFormInd();
                        this._router.navigate(['/mail-confirm', email]);
                    }
                    else
                    {
                        this._spinner.hide();
                        this.isLoginError = true;
                        this.errorMessage = data.Message;
                    }
                },
                    (error: any) =>
                    {
                        this._spinner.hide();
                        this.isLoginError = true;
                        this.errorMessage = 'Opps!!! Somthing is wrong, please try again.';
                    });
        }
    }

    OnSubmitPro(): void
    {
        this.submittedPro = true;
        if (this.registerFormPro.valid)
        {
            this._spinner.show();
            this._authService.register(this.registerFormPro.value)
                .subscribe((data: any) =>
                {
                    if (data.Error === false)
                    {
                        this._spinner.hide();
                        const email = this.registerFormPro.get('email').value;
                        this.resetFormPro();
                        this._router.navigate(['/mail-confirm', email]);
                    }
                    else
                    {
                        this._spinner.hide();
                        this.isLoginError = true;
                        this.errorMessage = data.Message;
                    }
                },
                    (error: any) =>
                    {
                        this._spinner.hide();
                        this.isLoginError = true;
                        this.errorMessage = 'Opps!!! Somthing is wrong, please try again.';
                    });
        }
    }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event)
    // {

    //     this.back.setParam(true, 'fuse');
    // }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>
{

    if (!control.parent || !control)
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm)
    {
        return null;
    }

    if (passwordConfirm.value === '')
    {
        return null;
    }

    if (password.value === passwordConfirm.value)
    {
        return null;
    }

    return { passwordsNotMatching: true };
};
