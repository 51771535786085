<h2 mat-dialog-title>Client Submitted Documents</h2>
<mat-dialog-content class="mat-typography"
    *ngIf="PageResponseData!=null">

    <div fxLayout="column"
        *ngIf="shownData.length>0"
        class="fuse-card auto-width mb-16 mt-16">

        <mat-accordion class="example-headers-align"
            [togglePosition]="'before'"
            style="height: max-content !important;">
            <mat-expansion-panel #matExpansionPanel1
                *ngFor="let value of shownData"
                style="height: max-content !important;">
                <mat-expansion-panel-header style="height: max-content !important; padding: 12px 6px !important;"
                    (click)="expandPanel(matExpansionPanel1, $event)">
                    <mat-panel-title fxLayout="row"
                        fxLayoutAlign="start center">
                        <!-- <h3 fxLayout="row"
                            fxLayoutAlign="center center">{{value.DocumentName}} &nbsp;<h3 *ngIf="value.Items.length!=0"
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                style="color: #444444; font-weight: bold;">
                                <mat-icon class="file"
                                    style="color: #29793C">attach_file</mat-icon>{{value.Items.length}}
                            </h3>
                        </h3> -->
                        {{value.DocumentName}}&nbsp; <mat-icon class="file"
                            style="color: #29793C">attach_file</mat-icon>{{value.Items.length}}
                    </mat-panel-title>
                    <!-- <mat-panel-description fxLayout="row"
                        fxLayoutAlign="end center"
                        style="width: 120px !important; max-width: 120px !important; min-width: 120px !important;">
                        <button (click)="openUploadDialog(value.Id)"
                            style="background-color: #37793c; border: 0; border-radius: 5px; border-top-right-radius: 5px; border-bottom-right-radius: 5px; color: #ffffff; padding-left: 0; margin-right: 2px; cursor: pointer; padding-top: 2px; padding-bottom: 2px;">
                            <span fxLayout="row"
                                fxLayoutAlign="center center"
                                style="padding: 0;margin: 0;">
                                <mat-icon style="color: #ffffff;">arrow_upward
                                </mat-icon> <span>Upload</span>
                            </span>
                        </button>

                    </mat-panel-description> -->
                </mat-expansion-panel-header>
                <mat-expansion-panel-body fxLayout="column"
                    fxFlex="100"
                    fxLayoutAlign="start"
                    style="overflow-y: scroll !important;">
                    <table class="simple invoice-table"
                        *ngIf="value.Items.length>0">
                        <thead>
                            <tr>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{ 'DOCUMENTS.FILE_TH' | translate }}
                                </th>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{ 'DOCUMENTS.FILE_SIZE_TH' | translate }}
                                </th>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{ 'DOCUMENTS.UPLOAD_DATE_TH' | translate }}

                                </th>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{ 'DOCUMENTS.COMMENTS_TH' | translate }}

                                </th>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{ 'DOCUMENTS.ACTION_TH' | translate }}

                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let data of value.Items">
                                <td>
                                    {{data.FilePath}}
                                </td>
                                <td>
                                    {{data.FileSize}}
                                </td>
                                <td>
                                    {{data.CreateTime}}
                                </td>
                                <td>
                                    {{data.Comments}}
                                </td>
                                <td>
                                    <div fxLayout="row wrap"
                                        fxLayoutAlign="start center">
                                        <!-- <a style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                            fxLayout="column wrap"
                                            (click)="openEditDialog(data.Id,data.Comments)"
                                            matTooltip="{{ 'COMMON.BUTTON_EDIT' | translate }}">
                                            <mat-icon class="nav-link-icon">create</mat-icon>
                                        </a>
                                        <a style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                            fxLayout="column wrap"
                                            (click)="CheckDelete(data.Id)"
                                            matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                            <mat-icon class="nav-link-icon">delete</mat-icon>
                                        </a> -->
                                        <a style="cursor: pointer; border: 0; color: #29793C; margin: 5px;"
                                            fxLayout="column wrap"
                                            (click)="DownloadFile(data.FilePath)"
                                            matTooltip="{{ 'DOCUMENTS.DOC_DL_FILE' | translate }}">
                                            <mat-icon class="nav-link-icon">arrow_downward</mat-icon>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="value.Items.length==0">
                        <h3 style="padding-left: 24px;">{{ 'DOCUMENTS.FILE_N' | translate }}</h3>
                    </div>
                </mat-expansion-panel-body>

            </mat-expansion-panel>
        </mat-accordion>

    </div>

    <div fxLayout="column"
        *ngIf="shownData.length==0"
        class="auto-width mb-16 mt-16">
        <span class="h2 p-12">Client Has No Document Uploaded</span>
    </div>

</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end"
    *ngIf="PageResponseData!=null">
    <button class="mat-button mat-button-base primary-100 secondary-text"
        cdkFocusInitial
        mat-dialog-close>Close</button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
