<!-- Start Navbar Area -->
<div class="navbar-area"
    style="background-color: #fff !important;">
    <div class="karlson-nav">
        <div class="container">

            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand"
                    routerLink="/"><img src="assets/home/img/logo-n.png"
                        width="40%"
                        height="40%"
                        alt="logo"></a>

                <button class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent">
                    <ul class="navbar-nav">

                        <li class="nav-item">
                            <a routerLink="/"
                                routerLinkActive="active"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                [routerLinkActiveOptions]="{exact: true}"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_HOME' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/about"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                routerLinkActive="active"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_ABOUT' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/how-it-works"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                routerLinkActive="active"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_HOW' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/pricing"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                routerLinkActive="active"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_PRICE' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/faq"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                routerLinkActive="active"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_HELP' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/blog"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                routerLinkActive="active"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_BLOG' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/news-events"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                routerLinkActive="active"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_NEWS' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                routerLinkActive="active"
                                class="nav-link"
                                style="color: #212529;">{{ 'COMMON.NAV_CONTACT' | translate }}</a>
                        </li>
                        <li class="nav-item"
                            style="margin-top: -7px;">
                            <button *ngIf="selectedLanguage.id=='en'"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                style="border: 0; padding: 7px; padding-bottom: 4px; background-color: transparent;"
                                (click)="setLanguage(languages[1])">
                                <img [src]="'assets/icons/flags/bn.png'"
                                    style="margin-top: -2px;"> বাংলা

                            </button>

                            <button *ngIf="selectedLanguage.id=='bn'"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                style="border: 0; padding: 7px; padding-bottom: 4px; background-color: #fff;"
                                (click)="setLanguage(languages[0])">
                                <img [src]="'assets/icons/flags/us.png'"
                                    style="margin-top: -2px;"> English
                            </button>
                        </li>
                        <li class="nav-item dropdown"
                            *ngIf="isLoggedInNav==true">
                            <a href="javascript:void(0)"
                                class="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                                style="text-align: center;"><i class="fas fa-user"
                                    style="font-size: 15px !important;"></i> {{userInfo.Name}}<i
                                    class="fas fa-chevron-down"></i></a>

                            <ul class="dropdown-menu">
                                <li *ngIf="userInfo.ActiveRoleId == '8'"
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a (click)="GotoDashboard()"
                                        class="nav-link"
                                        style="color: #29793C; border: 0; background: transparent; align-items: flex-start !important; cursor: pointer;">
                                        Dashboard
                                    </a>

                                </li>
                                <li *ngIf="userInfo.ActiveRoleId == '7'"
                                    class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a (click)="GotoProfile()"
                                        class="nav-link"
                                        style="color: #29793C; border: 0; background: transparent; align-items: flex-start !important; cursor: pointer;">
                                        Profile
                                    </a>

                                </li>

                                <li class="nav-item"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse.show">
                                    <a (click)="Logout()"
                                        class="nav-link"
                                        style="color: red; border: 0; background: transparent; align-items: flex-start !important; cursor: pointer;">
                                        Logout
                                    </a>

                                </li>
                            </ul>
                        </li>
                        <!-- <li class="nav-item"
                            *ngIf="isLoggedInNav==true"
                            style="margin-top: -7px;">
                            <button (click)="GotoDashboard()"
                                style="background-color: #29793C; color: white; border: 0; padding: 7px; padding-bottom: 4px; border-radius: 5px;">
                                Dashboard
                            </button>
                        </li>
                        <li class="nav-item"
                            *ngIf="isLoggedInNav==true"
                            style="margin-top: -7px;">
                            <button (click)="Logout()"
                                style="background-color:red; color: white; border: 0; padding: 7px; padding-bottom: 4px; border-radius: 5px;">
                                Logout
                            </button>
                        </li> -->

                        <li class="nav-item"
                            *ngIf="isLoggedInNav==false"
                            style="padding-top: 8px !important;">
                            <a [routerLink]="'/login-home'"
                                class="btn btn-sm btn-primary rounded"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                style="text-align: center; background-color: #29793C; padding: 5px; padding-left: 15px; padding-right: 15px;
                                border-color: #29793C;
                                -webkit-box-shadow: 0px 10px 30px 0px rgb(2 173 136 / 40%);
                                box-shadow: 0px 10px 30px 0px rgb(2 173 136 / 40%);
                                border-radius: 2rem !important;">
                                <span style="color: #fff;">Login</span>
                            </a>
                        </li>

                        <!-- <li class="nav-item"
                            *ngIf="isLoggedInNav==false">
                            <a [routerLink]="'/login-home'"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                style="text-align: center;">
                                <i class="fas fa-user"
                                    style="font-size: 15px !important;"></i> <span style="font-weight: bold;"> LOGIN</span>
                            </a>
                        </li> -->

                    </ul>

                    <!-- <div class="others-options">
                        <div class="option-item"><i class="search-btn flaticon-search"></i>
                            <i class="close-btn flaticon-close"></i>

                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="burger-menu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
