<div fxLayout="column"
    fxFlex>
    <div class="header mat-elevation-z4 primary"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="background-color: transparent !important;">

        <h3 matLine
            style="color: #29793C; font-weight: bold; margin-left: 15px;">Clients</h3>
        <button mat-icon-button
            class="toggle-sidebar-open mr-8"
            (click)="toggleSidebarOpen();">
            <mat-icon class="dupl">close</mat-icon>
        </button>

    </div>

    <div fxLayout="column"
        fxFlex
        fusePerfectScrollbar>
        <mat-list>

            <mat-list-item *ngFor="let data of getData">
                <a style="cursor: pointer; border: 0; color: #29793C;"
                    fxLayout="column wrap"
                    (click)="setReturn(data.AssesseeId,data.AssesseeName)">
                    <h3 matLine>{{data.AssesseeName}}</h3>
                </a>
            </mat-list-item>
        </mat-list>
    </div>

    <!-- <div style="align-items: center;">

        <div fxLayout="row"
            fxLayoutAlign="center center"
            style="margin-left: 10px; margin-right: 10px;">
        </div>
        <div fxLayout="column"
            fxLayoutAlign="center center"
            style="margin: 5px;">
            <div style="color: red;"
                fxLayout="row"
                fxLayoutAlign="center center">
                <span style="font-size: 20px;"
                    id="CalculatedTotalTax">{{myCount}}</span>
                <span style="margin-top: 5px;">&nbsp;BDT</span>

            </div>

            <span class="username mr-12">Tax Owed
            </span>
        </div>
        <div>
            <div mat-button
                fxFlex="1 1 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 5px;background-color: #3f51b5;">
                <span style="color: white;">2020-2021 Fiscal year</span>
            </div>
        </div>
    </div> -->
</div>
