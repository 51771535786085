import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getControlJson(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    // console.log(urlConfig.DASHBOARD_GETDATA_URL, 'urlConfig.DASHBOARD_GETDATA_URL');

    return this.service.get(urlConfig.DASHBOARD_GETDATA_URL);
  }



}
