<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'BLOG.HEADER' | translate }}</h2>

                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে"><img src="assets/home/img/blog-image/blog_m01_1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে">digiTax</a></li>
                                <li>August 15, 2021</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে">{{ 'BLOG.R1' | translate }}</a></h3>
                        <a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"><img src="assets/home/img/blog-image/blog_m02_1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">Md. Lokman Hossain</a></li>
                                <li>August 16, 2021</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R2' | translate }}</a></h3>

                        <a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"><img src="assets/home/img/blog-image/blog_m03_1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">Md. Lokman Hossain</a></li>
                                <li>August 16, 2021</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R3' | translate }}</a></h3>

                        <a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/home/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">digiTax</a></li>
                                <li>June 24, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">{{ 'BLOG.R4' | translate }}</a></h3>

                        <a routerLink="/blog-details"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/home/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">digiTax</a></li>
                                <li>June 26, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">{{ 'BLOG.R5' | translate }}</a></h3>

                        <a routerLink="/blog-details"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/home/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">digiTax</a></li>
                                <li>June 25, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">{{ 'BLOG.R6' | translate }}</a></h3>

                        <a routerLink="/blog-details"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/home/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">digiTax</a></li>
                                <li>June 24, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">{{ 'BLOG.R7' | translate }}</a></h3>

                        <a routerLink="/blog-details"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/home/img/blog-image/blog1.jpg"
                                alt="image"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/">digiTax</a></li>
                                <li>June 26, 2019</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">{{ 'BLOG.R8' | translate }}</a></h3>

                        <a routerLink="/blog-details"
                            class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</section>
<!-- End Blog Area -->
