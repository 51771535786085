import { AfterViewInit, ChangeDetectorRef, Component, HostListener, ViewChild } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { DocumentsService } from './Documents.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { DomSanitizer } from '@angular/platform-browser';

import * as lodash from 'lodash';
import { UploadDocumentDialog } from './upload-dialog/Dialog.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { EditDocumentDialog } from './edit-dialog/edit-Dialog.component';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';
import { Meta, Title } from '@angular/platform-browser';
import { log } from 'console';

@Component({
    selector: 'Documents',
    templateUrl: './Documents.component.html',
    styleUrls: ['./Documents.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class DocumentsComponent implements AfterViewInit
{

    @ViewChild(MatAccordion) accordion: MatAccordion;
    displayedColumns: string[] = ['name', 'symbol'];
    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    expandedElement: PeriodicElement | null;
    animal: any;
    name: string;
    card9Expanded: boolean;
    selectedType: string;
    lang = '';
    searchTerm: string;
    getData: any;
    fillteredData = [];
    fileUrl: any;
    shownData: any;
    IsCurrentYear: any;
    readonly = false;


    dumm = [
        {
            "Id": 1,
            "SlNo": 0,
            "DocumentName": "Docs of any other income which you want to show;",
            "ExampleFilePath": "",
            "DocumentCategory": 1,
            "IsRecomended": false,
            "Items": []
        },
        {
            "Id": 2,
            "SlNo": 1,
            "DocumentName": "Salary statement for salary income;",
            "ExampleFilePath": "salary.pdf",
            "DocumentCategory": 2,
            "IsRecomended": false,
            "Items": []
        },
        {
            "Id": 3,
            "SlNo": 2,
            "DocumentName": "Certificate for interest on savings instruments;",
            "ExampleFilePath": "",
            "DocumentCategory": 3,
            "IsRecomended": false,
            "Items": []
        },
        {
            "Id": 4,
            "SlNo": 3,
            "DocumentName": "Interest statement out of SC;",
            "ExampleFilePath": "",
            "DocumentCategory": 3,
            "IsRecomended": false,
            "Items": []
        },
        {
            "Id": 5,
            "SlNo": 4,
            "DocumentName": "Rent agreement,",
            "ExampleFilePath": "",
            "DocumentCategory": 4,
            "IsRecomended": false,
            "Items": []
        },
        {
            "Id": 6,
            "SlNo": 5,
            "DocumentName": "receipts of municipal tax and land revenue,",
            "ExampleFilePath": "",
            "DocumentCategory": 4,
            "IsRecomended": false,
            "Items": []
        }
    ];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit()
    {
        this.dataSource.paginator = this.paginator;
    }

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        public dialog: MatDialog,
        private _formControlService: DocumentsService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private changeDetectorRefs: ChangeDetectorRef,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,
        private http: HttpClient,
        private sanitizer: DomSanitizer
    )
    {

        this.meta.addTags([
            { name: 'description', content: "digiTax Upload & Download Documents" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Upload & Download Documents" }
        ]);
        this.setTitle("digiTax | Upload & Download Documents");
        this.card9Expanded = false;
        this.searchTerm = '';
        this.selectedType = '';
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

        if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
        {
            this.readonly = true;
        }
        else
        {
            this.readonly = false;
        }

        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));

        if (profileData.iscomplete == false)
        {
            let incompletefields = null;

            if (profileData.incompletefields[0] != null)
            {
                incompletefields = profileData.incompletefields[0].fields;
            }

            let incompleteprofile = null;
            if (profileData.incompleteprofile[0] != null)
            {
                incompleteprofile = profileData.incompleteprofile[0];
            }

            if (incompleteprofile == "1")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ETIN_OTHER : bangla.data.ETIN.R_ETIN_OTHER, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/EtinOthers/save']);
            }

            if (incompleteprofile == "2")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_PERSONAL_INFO : bangla.data.PERSONAL.R_ENTER_PERSONAL_INFO, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/PersonalInfo/save']);
            }

            if (incompleteprofile == "3")
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONALIZE.R_PER_RETURN : bangla.data.PERSONALIZE.R_PER_RETURN, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top'
                });
                this._router.navigate(['/PersonalizeReturn']);
            }

        }
        else
        {
            this.getPageData();
        }
    }

    getPageData(): void
    {
        this._formControlService.getControlJson()
            .subscribe((data: any) =>
            {

                if (data.isError === false)
                {
                    this.getData = data.data;
                    // console.log(this.getData, 'this.getData');
                    this.fillteredData = lodash.filter(this.getData, { IsRecomended: true });
                    // console.log(this.fillteredData, 'this.fillteredData');
                    if (this.fillteredData.length > 0)
                    {
                        this.shownData = this.fillteredData;
                        this.selectedType = 'rec';
                    } else
                    {
                        this.shownData = this.getData;
                        this.selectedType = 'all';
                    }


                }
                else
                {
                    console.log('error');
                    this._spinner.hide();
                }
                this._spinner.hide();

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            });
    }

    DownloadFile(path): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.DOCUMENT_DOWNLOADFILE + `${path}`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`
            }), responseType: 'blob'
        }).subscribe(res =>
        {
            // console.log(blob, 'blob');
            if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
            {
                let reader = new FileReader();
                let base64data = null;
                reader.readAsDataURL(res);
                reader.onloadend = function ()
                {
                    base64data = reader.result;
                    
                    let pdfWindow = window.open("")
                    pdfWindow.document.write(
                        "<iframe width='100%' height='100%' src=" +
                        base64data + "></iframe>"
                    )
                }
            }
            else
            {
                saveAs(res, path);
            }
            this._spinner.hide();
        },
            error =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            })
    }

    filterCoursesByTerm(): void
    {
        const searchTerm = this.searchTerm.toLowerCase();

        // Search
        if (searchTerm === '')
        {
            this.setType();
        }
        else
        {
            this.shownData = this.shownData.filter((data) =>
            {
                return data.DocumentName.toLowerCase().includes(searchTerm);
            });
        }
    }

    clearSearch(): void
    {
        this.searchTerm = '';
        this.setType();
    }


    setType(): void
    {
        if (this.selectedType == 'all') 
        {
            this.shownData = this.getData;
        }
        if (this.selectedType == 'rec') 
        {
            // this.shownData = this.dumm;
            this.shownData = this.fillteredData;
        }
        this.changeDetectorRefs.detectChanges();
    }

    switchData(type): void
    {
        let allbutton: HTMLElement = document.getElementById("allbutton") as HTMLElement;
        let recbutton: HTMLElement = document.getElementById("recbutton") as HTMLElement;
        this.selectedType = type;
        if (type == 'all')
        {
            this.shownData = this.getData;

            if (allbutton.classList.contains('primary-A400'))
            {
                allbutton.classList.remove('primary-A400');
            }
            if (allbutton.classList.contains('secondary-text'))
            {
                allbutton.classList.remove('secondary-text');
            }
            if (!allbutton.classList.contains('sdupl'))
            {
                allbutton.classList.add('dupl');
            }
            if (recbutton.classList.contains('dupl'))
            {
                recbutton.classList.remove('dupl');
            }
            if (!recbutton.classList.contains('primary-A400'))
            {
                recbutton.classList.add('primary-A400');
            }
            if (!recbutton.classList.contains('secondary-text'))
            {
                recbutton.classList.add('secondary-text');
            }
        }
        if (type == 'rec')
        {
            this.shownData = this.fillteredData;

            if (recbutton.classList.contains('primary-A400'))
            {
                recbutton.classList.remove('primary-A400');
            }
            if (recbutton.classList.contains('secondary-text'))
            {
                recbutton.classList.remove('secondary-text');
            }
            if (!recbutton.classList.contains('sdupl'))
            {
                recbutton.classList.add('dupl');
            }
            if (allbutton.classList.contains('dupl'))
            {
                allbutton.classList.remove('dupl');
            }
            if (!allbutton.classList.contains('primary-A400'))
            {
                allbutton.classList.add('primary-A400');
            }
            if (!allbutton.classList.contains('secondary-text'))
            {
                allbutton.classList.add('secondary-text');
            }

        };
        this.changeDetectorRefs.detectChanges();
    }


    toggle(event: Event): void
    {
        let elementId: string = (event.target as Element).id;

        // do something with the id... 
    }

    toggleYesRule(event)
    {
        this.card9Expanded = !this.card9Expanded;
    }

    CheckDelete(id): void
    {
        if (this.readonly == true)
        {
            this.DisabledButtonMessage();
        }
        else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_DELETE : bangla.data.COMMON.LABEL_DELETE;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.deleteItem(id);
                }
                this.confirmDialogRef = null;
            });

        }
    }


    deleteItem(id): void
    {
        this._spinner.show();
        this._formControlService.deleteControl(id).subscribe((data: any) =>
        {
            if (data.isError === false)
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DELETED : bangla.data.COMMON.BUTTON_DELETED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this.getPageData();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        })
    }

    DisabledButtonMessage(): void
    {
        this._matSnackBar.open(this.lang == 'en' ? "Button Disabled" : "Button Disabled", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
            verticalPosition: 'top'
        });
    }

    openUploadDialog(value): void
    {

        if (this.readonly == true)
        {
            this.DisabledButtonMessage();
        }
        else
        {

            const dialogRef = this.dialog.open(UploadDocumentDialog, {
                width: '500px',
                height: '400px',
                data: { name: value }
            });

            dialogRef.afterClosed().subscribe(result =>
            {
                if (result == 'no')
                {
                    console.log('dialog canceled');
                }
                if (result == 'yes')
                {
                    // console.log('file uploaded');
                    this.getPageData();
                }
            });
        }

    }

    openEditDialog(Id, Comments): void
    {


        const dialogRef = this.dialog.open(EditDocumentDialog, {
            width: '500px',
            height: '230px',
            data: { Id: Id, Comments: Comments, }
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'no')
            {
                console.log('dialog canceled');
            }
            if (result == 'yes')
            {
                // console.log('file uploaded');
                this.getPageData();
            }
        });

    }

    expandPanel(matExpansionPanel: MatExpansionPanel, event: any)
    {
        event.stopPropagation();

        if (!this._isExpansionIndicator(event.target))
        {
            // this.card9Expanded = !this.card9Expanded;
            matExpansionPanel.close();
        }
    }

    private _isExpansionIndicator(target: EventTarget | any): boolean
    {
        const expansionIndicatorClass = "mat-expansion-indicator";
        return (
            target.classList && target.classList.contains(expansionIndicatorClass)
        );
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
export interface PeriodicElement
{
    name: string;
    symbol: string;
    description: string;
}

// export interface DialogData {
//     animal: string;
//     name: string;
//   }

const ELEMENT_DATA: PeriodicElement[] = [
    {
        name: 'Salary - Salary statement', symbol: 'Upload File', description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
        atomic weight of 1.008, hydrogen is the lightest element on the periodic table.` },
    {
        name: 'Salary - Salary certificate', symbol: 'Upload File', description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
    atomic weight of 1.008, hydrogen is the lightest element on the periodic table.` },
    {
        name: 'Salary - Salary statement', symbol: 'Upload File', description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
    atomic weight of 1.008, hydrogen is the lightest element on the periodic table.` },
    {
        name: 'Interest of securities- statement', symbol: 'Upload File', description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
    atomic weight of 1.008, hydrogen is the lightest element on the periodic table.` },
    {
        name: 'Salary - Salary statement', symbol: 'Upload File', description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
    atomic weight of 1.008, hydrogen is the lightest element on the periodic table.` },

];


