<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">
    <div class="center">

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px;">Notification
            </span>
        </div>
        <div class="content p-24">
            <div class="mb-24">
                <div fxLayout="row wrap"
                    fxLayoutAlign="space-between start"
                    fxLayout.gt-md="row">
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">Recipient:
                        </span>

                        <mat-form-field class="example-full-width"
                            appearance="outline">
                            <mat-select [formControl]="RecipientTypeControl">
                                <mat-option [value]="2">
                                    Contact Number
                                </mat-option>
                                <mat-option [value]="3">
                                    Email
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">Gateway:
                        </span>
                        <mat-form-field class="example-full-width"
                            *ngIf="dropDownData!=null"
                            appearance="outline">
                            <mat-select [formControl]="RecipientGatewayControl">
                                <mat-option *ngFor="let option of GatewayDropdown"
                                    [value]="option.Value">
                                    {{option.Text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">SMS BALANCE: {{SmsBalance}}
                        </span>
                        <div fxLayout="row"
                            fxLayoutAlign="space-between">
                            <button mat-raised-button
                                (click)="openTemplateListDialog()"
                                style="margin: 5px;"
                                class="mat-button mat-button-base primary">
                                SELECT TEMPLATE
                            </button>
                            <button mat-raised-button
                                (click)="SaveTemplate()"
                                style="margin: 5px;"
                                class="mat-button mat-button-base dupl">
                                SAVE TEMPLATE
                            </button>
                        </div>
                    </div>
                </div>

                <div fxLayoutAlign="space-between"
                    fxLayout="column wrap"
                    style="padding-right: 10px;">

                    <span class="name"
                        style="font-size: 15px;">SMS Body</span>

                    <mat-form-field appearance="outline">
                        <textarea style="resize:vertical"
                            [mention]="items"
                            [mentionConfig]="mentionConfig"
                            rows="2"
                            type="text"
                            class="leftA"
                            [formControl]="SMSBodyControl"
                            matInput>
                         </textarea>
                    </mat-form-field>

                </div>

                <div fxLayoutAlign="start center"
                    fxLayout="row wrap"
                    fxLayoutAlign="space-between start"
                    fxLayout.gt-md="row"
                    style="margin-top: 20px">
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">ETIN:
                        </span>

                        <mat-form-field appearance="outline">
                            <input class="leftA"
                                [formControl]="EtinFilterControl"
                                id="clientRemarks"
                                value=""
                                matInput>

                        </mat-form-field>
                    </div>
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">Mobile No:
                        </span>
                        <mat-form-field appearance="outline">
                            <input class="leftA"
                                [formControl]="MobileFilterControl"
                                id="clientRemarks"
                                value=""
                                matInput>

                        </mat-form-field>
                    </div>
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">Email:
                        </span>
                        <mat-form-field appearance="outline">
                            <input class="leftA"
                                [formControl]="EmailFilterControl"
                                id="clientRemarks"
                                value=""
                                matInput>

                        </mat-form-field>
                    </div>
                </div>

                <div fxLayoutAlign="start center"
                    fxLayout="row wrap"
                    fxLayoutAlign="space-between start"
                    fxLayout.gt-md="row"
                    style="margin-top: 20px">
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">Add Client:
                        </span>

                        <mat-form-field class="example-full-width"
                            appearance="outline">
                            <input type="text"
                                tabindex="-1"
                                matInput
                                [formControl]="ClientFilterControl"
                                [matAutocomplete]="ClientFilterAuto">
                            <mat-autocomplete #ClientFilterAuto="matAutocomplete">
                                <mat-option *ngFor="let option of ClientfilteredOptions"
                                    (onSelectionChange)="AutoSearchedClient(option)"
                                    [value]="option.AssesseName">
                                    {{option.AssesseName}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">Reference:
                        </span>
                        <mat-form-field appearance="outline">
                            <input class="leftA"
                                [formControl]="RefFilterControl"
                                id="clientRemarks"
                                value=""
                                matInput>

                        </mat-form-field>
                    </div>
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        fxFlex="33"
                        style="margin: 10px;">
                        <span class="name"
                            style="font-size: 15px;"
                            fxFlex="50">Sorting Option:
                        </span>
                        <mat-form-field appearance="outline">
                            <mat-select [formControl]="SortingFilterControl">
                                <mat-option [value]="1">
                                    Assessee Id
                                </mat-option>
                                <mat-option [value]="2">
                                    Assessee Name
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row"
                    fxLayoutAlign="center center">

                    <button mat-raised-button
                        [disabled]="showTable"
                        style="margin: 10px;"
                        (click)="PrepareNotification()"
                        class="mat-button mat-button-base dupl px-16">
                        Prepare
                    </button>

                    <button mat-raised-button
                        style="color: #444444 !important; margin: 10px;"
                        (click)="ClearPage()"
                        class="mat-button mat-button-base primary-100 secondary-text">
                        Refresh
                    </button>
                </div>
                <div fxLayoutAlign="start"
                    fxLayout="column wrap"
                    *ngIf="showTable">
                    <div fxLayout="row"
                        fxLayoutAlign="space-between">

                        <button mat-raised-button
                            style="margin: 10px;"
                            (click)="SendNotification()"
                            class="mat-button mat-button-base dupl px-16">
                            SEND MESSSAGE
                        </button>

                        <div fxLayout="row"
                            fxLayoutAlign="center center">
                            <span style="margin-right: 5px;"> SELECTED RECORDS: {{selection.selected.length}}</span>
                            <span style="margin-left: 5px;"> TOTAL RECORDS: {{dataSource.data.length}}</span>
                        </div>
                    </div>
                    <mat-table #table
                        style="width: 100%"
                        [dataSource]="dataSource">

                        <ng-container matColumnDef="SL"
                            style="flex: 0 0 10%;">
                            <mat-header-cell *matHeaderCellDef> SL </mat-header-cell>
                            <mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="SELECTION"
                            style="flex: 0 0 10%;">
                            <mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="CLIENTID">
                            <mat-header-cell *matHeaderCellDef> CLIENT ID </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.AssesseeId}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="CLIENTNAME">
                            <mat-header-cell *matHeaderCellDef> CLIENT NAME </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.AssesseName}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="CONTACT">
                            <mat-header-cell *matHeaderCellDef> CONTACT </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.ContactNo}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="SMSTEXT">
                            <mat-header-cell *matHeaderCellDef> SMSTEXT </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.MessageText}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="LENGTH">
                            <mat-header-cell *matHeaderCellDef> LENGTH </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ bodyLength(element.MessageText) }} </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            (click)="selection.toggle(row)">
                        </mat-row>
                    </mat-table>

                </div>
            </div>

        </div>
    </div>
</div>
