import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSelectionListChange, MatSelectionList } from "@angular/material/list";

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';

import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';
import { PaymentService } from 'app/pages/Payment/Payment.service';
import * as lodash from 'lodash';
import { ClientsListService } from '../ClientsList.service';


@Component({
    selector: 'ClientEmailDialog',
    templateUrl: 'client-email-Dialog.component.html',
    styleUrls: ['./client-email-Dialog.component.scss'],
})
export class ClientEmailDialog
{


    lang = '';

    DropdownData: any;
    PageResponseData: any;
    ChalanResponseData: any;
    MainListGroup: any;
    SubListGroup: any;


    TaxAmountControl = new FormControl();
    ChalanAmountControl = new FormControl();
    PaymentModeControl = new FormControl();
    ChequePOControl = new FormControl();
    BankNamesControl = new FormControl();
    BranchNamesControl = new FormControl();
    SubmittedByControl = new FormControl();
    MainListGroupControl = new FormControl();
    SubListGroupControl = new FormControl();

    TransactionNoControl = new FormControl();
    TransactionRemarksControl = new FormControl();

    paytypes = [
        {
            "key": "1",
            "value": "Cash",

        },
        {
            "key": "2",
            "value": "Cheque",

        },
        {
            "key": "3",
            "value": "Pay Order",

        }
    ]

    BcTabMainForm: FormGroup;

    maxDate = new Date();
    BcTabBillEnabled = false;
    SlipDisabled = false;



    constructor(
        public dialogRef: MatDialogRef<ClientEmailDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: PaymentService,
        private _clientsListService: ClientsListService,
        private _transactionControlService: TransactionService,
        private http: HttpClient,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        dialogRef.disableClose = true;
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');

        if (this.dialogdata.AcknowledgementSlipName == null || this.dialogdata.AcknowledgementSlipName == '')
        {
            this.SlipDisabled = true;
        }
        else
        {
            this.SlipDisabled = false;
        }

        this.GetEmailData();

    }


    onSelect(event: MatSelectionListChange)
    {
        // debugger;
        // console.log("onSelectionChange", event.option.value);

        // console.log(this.MainListGroupControl.value, 'MainListGroupControl');

        let hasChalan = lodash.filter(this.MainListGroupControl.value, { "DocumentName": "Challan" });
        // console.log(hasChalan, 'hasChalan');



        let floorElement1: HTMLElement = document.getElementById("ChalanDiv") as HTMLElement;


        if (hasChalan.length > 0)
        {
            this.GetChalanData();
            if (floorElement1.classList.contains('hidden'))
            {
                floorElement1.classList.remove('hidden');
            }
            if (!floorElement1.classList.contains('block'))
            {
                floorElement1.classList.add('block');
            }

            // this.BankNamesControl.setValue(null)
            // this.BranchNamesControl.setValue('');
            // this.ChequePOControl.setValue('');
            // this.ChalanAmountControl.setValue('');
            // this.PaymentModeControl.setValue('');
            // this.SubmittedByControl.setValue('');
        }
        else
        {
            if (!floorElement1.classList.contains('hidden'))
            {
                floorElement1.classList.add('hidden');
            }
            if (floorElement1.classList.contains('block'))
            {
                floorElement1.classList.remove('block');
            }


            this.BankNamesControl.setValue(null)
            this.BranchNamesControl.setValue('');
            this.ChequePOControl.setValue('');
            this.ChalanAmountControl.setValue('');
            this.PaymentModeControl.setValue('');
            this.SubmittedByControl.setValue('');
            this.TransactionNoControl.setValue('');
            this.TransactionRemarksControl.setValue('');
        }

        // console.log(this.MainListGroupControl.value, 'MainListGroupControl');

    }

    radioChangeN(event)
    {

        // let floorElement1: HTMLElement = document.getElementById("Cheque") as HTMLElement;
        let floorElement2: HTMLElement = document.getElementById("BankN") as HTMLElement;
        let floorElement3: HTMLElement = document.getElementById("TransactionN") as HTMLElement;


        if (event.value == "2")
        {
            // if (floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.remove('hidden');
            // }
            // if (!floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.add('block');
            // }

            if (floorElement2.classList.contains('hidden'))
            {
                floorElement2.classList.remove('hidden');
            }
            if (!floorElement2.classList.contains('block'))
            {
                floorElement2.classList.add('block');
            }

            // if (!floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.add('hidden');
            // }
            // if (floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.remove('block');
            // }

            this.BankNamesControl.setValue(null)
            this.BranchNamesControl.setValue('');
            this.ChequePOControl.setValue('');
        }
        else
        {
            // if (!floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.add('hidden');
            // }
            // if (floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.remove('block');
            // }

            if (!floorElement2.classList.contains('hidden'))
            {
                floorElement2.classList.add('hidden');
            }
            if (floorElement2.classList.contains('block'))
            {
                floorElement2.classList.remove('block');
            }

            // if (floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.remove('hidden');
            // }
            // if (!floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.add('block');
            // }

            this.BankNamesControl.setValue(null)
            this.BranchNamesControl.setValue('');
            this.ChequePOControl.setValue('');
        }

        if (event.value == "3")
        {
            // if (floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.remove('hidden');
            // }
            // if (!floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.add('block');
            // }

            if (floorElement3.classList.contains('hidden'))
            {
                floorElement3.classList.remove('hidden');
            }
            if (!floorElement3.classList.contains('block'))
            {
                floorElement3.classList.add('block');
            }

            // if (!floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.add('hidden');
            // }
            // if (floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.remove('block');
            // }

            this.TransactionNoControl.setValue('');
            this.TransactionRemarksControl.setValue('');

        }
        else
        {
            // if (!floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.add('hidden');
            // }
            // if (floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.remove('block');
            // }

            if (!floorElement3.classList.contains('hidden'))
            {
                floorElement3.classList.add('hidden');
            }
            if (floorElement3.classList.contains('block'))
            {
                floorElement3.classList.remove('block');
            }

            // if (floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.remove('hidden');
            // }
            // if (!floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.add('block');
            // }

            this.TransactionNoControl.setValue('');
            this.TransactionRemarksControl.setValue('');
        }

    }



    GetEmailData(): void
    {
        this._spinner.show();
        let body = {
            "AssesseeId": this.dialogdata.AssesseeId,
            "ReturnId": this.dialogdata.ReturnId,
            "DateRange": null,
            "AssessmentYearId": null
        }
        // console.log(body, 'GetEmailData');

        forkJoin([
            this._formControlService.getCommonDropdown(),
            this._clientsListService.LoadEmailBasicInfo(this.dialogdata.ReturnId)]).subscribe(
                (result: any) =>
                {

                    let dropDownResponse = result[0];
                    // console.log(dropDownResponse, 'dropDownResponse');

                    this.DropdownData = dropDownResponse;

                    let pageDataResponse = result[1];
                    // console.log(pageDataResponse, 'pageDataResponse');

                    this.PageResponseData = pageDataResponse.data[0];

                    if (this.PageResponseData !== null)
                    {
                        this.TaxAmountControl.setValue(this.PageResponseData.TaxAmount);
                        this.ChalanAmountControl.setValue(this.PageResponseData.ChallanAmount);
                    }

                    if (this.PageResponseData.UploadedDocuments.length > 4)
                    {
                        // this.MainListGroup = this.PageResponseData.UploadedDocuments.slice(-4);
                        // this.SubListGroup = this.PageResponseData.UploadedDocuments.slice(0, -4);
                        // console.log(this.SubListGroup, 'this.SubListGroup');
                        // console.log(this.MainListGroup, 'this.MainListGroup');

                        let firstFour = this.PageResponseData.UploadedDocuments.slice(-4);
                        let remainder = this.PageResponseData.UploadedDocuments.slice(0, -4);
                        this.MainListGroup = firstFour.concat(remainder);
                    }
                    else
                    {
                        this.MainListGroup = this.PageResponseData.UploadedDocuments;
                    }

                    this._spinner.hide();


                }, (error: any) =>
            {
                this._spinner.hide();
                console.log(error, 'error');
            })
    }

    GetChalanData(): void
    {
        this._spinner.show();
        let body = {
            "AssesseeId": this.dialogdata.AssesseeId,
            "ReturnId": this.dialogdata.ReturnId,
            "DateRange": null,
            "AssessmentYearId": null
        }
        // console.log(body, 'GetChalanData');


        this._formControlService.LoadChalanBasicInfo(body).subscribe(
            (result: any) =>
            {


                let pageDataResponse = result;
                // console.log(pageDataResponse, 'pageDataResponse');

                this.ChalanResponseData = pageDataResponse.data

                if (this.ChalanResponseData !== null)
                {
                    this.TaxAmountControl.setValue(this.ChalanResponseData.TaxAmount);
                    this.ChalanAmountControl.setValue(this.ChalanResponseData.ChallanAmount);
                }
                this._spinner.hide();


            }, (error: any) =>
        {
            this._spinner.hide();
            console.log(error, 'error');
        })
    }

    SendEmail()
    {


        if (this.MainListGroupControl.value == null)
        {
            this._matSnackBar.open('Please Select Atleast 1 File', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        } else
        {

            let hasChalan = lodash.filter(this.MainListGroupControl.value, { "DocumentName": "Challan" });

            if (hasChalan.length > 0 && (this.ChalanAmountControl.value == null || this.ChalanAmountControl.value == ''))
            {
                this._matSnackBar.open('Please Enter Chalan Amount', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {

                if (hasChalan.length > 0 && (this.PaymentModeControl.value == null || this.PaymentModeControl.value == ''))
                {
                    this._matSnackBar.open('Please Select A Payment Mode', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                } else
                {
                    if (hasChalan.length > 0 && (this.PaymentModeControl.value == null || this.PaymentModeControl.value == ''))
                    {
                        this._matSnackBar.open('Please Select A Payment Mode', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                    else
                    {
                        if (hasChalan.length > 0 && (this.SubmittedByControl.value == null || this.SubmittedByControl.value == ''))
                        {
                            this._matSnackBar.open('Please select a submitted by', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        } else
                        {

                            let body =
                            {
                                "ETIN": this.PageResponseData.ETIN,
                                "AssesseName": this.PageResponseData.AssesseName,
                                "AssesseeId": this.dialogdata.AssesseeId,
                                "ReturnId": this.dialogdata.ReturnId,
                                "ContactNo": this.PageResponseData.ContactNo,
                                "Email": this.PageResponseData.Email,
                                "Year": this.PageResponseData.Year,
                                "PaymentTypeId": this.PaymentModeControl.value,
                                "ChequeNo": this.ChequePOControl.value,
                                "BankId": this.BankNamesControl.value != null ? this.BankNamesControl.value.key : null,
                                "BankName": this.BankNamesControl.value != null ? this.BankNamesControl.value.value : null,
                                "Branch": this.BranchNamesControl.value,
                                "TransactionNo": this.TransactionNoControl.value,
                                "TransactionRemarks": this.TransactionRemarksControl.value,
                                "ChallanAmount": this.ChalanAmountControl.value,
                                "SubmittedBy": this.SubmittedByControl.value == "1" ? "self" : "itp",
                                "UploadedDocuments": this.MainListGroupControl.value
                            }
                            // console.log(body, 'body');

                            this._spinner.show();
                            this._clientsListService.ManageClientEmail(body)
                                .subscribe((data: any) =>
                                {
                                    // console.log(data, 'data');
                                    if (data.isError == false)
                                    {
                                        this._matSnackBar.open(this.lang == 'en' ? "Email Will be sent soon" : "Email Will be sent soon", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                        this.dialogRef.close();
                                        this._spinner.hide();

                                    } else
                                    {
                                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                        this._spinner.hide();
                                    }

                                }, (error: any) =>
                                {
                                    this._spinner.hide();
                                    console.log(error, 'error');
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                });

                        }
                    }
                }
            }
        }
    }



    onNoClick(): void
    {
        this.dialogRef.close("no");
    }







}


let gg =

    [
        {
            "ETIN": "357951456987",
            "AssesseName": "Taijuddin",
            "AssesseeId": 2326,
            "ReturnId": 2365,
            "ContactNo": "01916387657",
            "Email": "allworksbd@gmail.com",
            "Year": "Year 2022-2023",
            "ChallanAmount": null,
            "SubmittedBy": null,
            "PaymentTypeId": 0,
            "ChequeNo": null,
            "BankId": 0,
            "BankName": null,
            "Branch": null,
            "UploadedDocuments": [
                {
                    "DocId": 2,
                    "SlNo": 1,
                    "DocumentName": "Salary statement for salary income;"
                },
                {
                    "DocId": 3,
                    "SlNo": 2,
                    "DocumentName": "Certificate for interest on savings instruments;"
                },
                {
                    "DocId": 4,
                    "SlNo": 3,
                    "DocumentName": "Interest statement out of SC;"
                },
                {
                    "DocId": 5,
                    "SlNo": 4,
                    "DocumentName": "Rent agreement,"
                },
                {
                    "DocId": 1000,
                    "SlNo": 1000,
                    "DocumentName": "Return File"
                },
                {
                    "DocId": 1001,
                    "SlNo": 1001,
                    "DocumentName": "Calculation Sheet"
                },
                {
                    "DocId": 1002,
                    "SlNo": 1002,
                    "DocumentName": "Challan"
                },
                {
                    "DocId": 1003,
                    "SlNo": 1003,
                    "DocumentName": "Acknowledgement Slip"
                }
            ]
        }
    ]


