<div fxLayout="column"
    fxLayoutAlign="start"
    style="margin: 0 !important;">
    <div fxLayout="column"
        fxLayoutAlign="center center"
        style="margin: 5px;">
        <div *ngIf="topEtinData!=''"
            fxLayout="row"
            fxLayoutAlign="center center">
            <span>{{ 'COMMON.ETIN' | translate }}:&nbsp;
                <span id="etinV">{{topEtinData}}</span>
                <!-- <span *ngIf="topEtinData!=null">{{topEtinData[0].value}}</span>
                <span *ngIf="topEtinData==null">Not set yet</span> -->
            </span>

        </div>
        <div style="color: red;"
            fxLayout="row"
            fxLayoutAlign="center center">
            <span style="font-size: 20px;"
                *ngIf="topOwedData!=null">{{topOwedData[0].value}}</span>
            <span style="font-size: 20px;"
                *ngIf="topOwedData==null">0</span>
            <span style="margin-top: 5px;">&nbsp;BDT</span>

        </div>

        <span class="username mr-12">{{ 'COMMON.TAX_OWED' | translate }}
        </span>
    </div>

    <div mat-button
        fxFlex="100"
        fxLayout="column wrap"
        fxLayoutAlign="center center"
        style="padding: 5px; background-color: #29793C; width: 100%; color: white;">
        <!-- <span style="color: white;">
            <span id="fyearV" *ngIf="topYearData!=null">{{topYearData[0].value}}</span>
            <span *ngIf="topYearData==null">{{this.fiscalyear}} </span>
            {{ 'COMMON.TAX_YEAR' | translate }}</span> -->
        <span style="color: white; padding-bottom: 5px;">{{ 'COMMON.TAX_YEAR' | translate }}</span>
        <mat-form-field appearance="outline"
            style="width: 100%;">
            <!-- <mat-select [formControl]="YearSwitchFormControl">
                <mat-option [value]="1">
                    Dhaka & Chittagong City Corporation
                </mat-option>
                <mat-option [value]="2">
                    Other City Corporation
                </mat-option>
                <mat-option [value]="3">
                    Other than the city corporation area
                </mat-option>
            </mat-select> -->
            <mat-select [formControl]="YearSwitchFormControl"
                (selectionChange)="ConfirmSwitch($event)"
                *ngIf="YearSwitchData != null">
                <mat-option *ngFor="let option of YearSwitchData"
                    [value]="option">
                    {{option.YearDesc}}
                </mat-option>
            </mat-select>

        </mat-form-field>
    </div>

    <div style="margin-top: 20px; margin-left: -10px; margin-right: 0 !important;">
        <mat-tree #tree
            [dataSource]="dataSource"
            [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node"
                matTreeNodePadding
                style="font-size: 12px; margin-top: -20px;"
                matTreeNodePaddingIndent="5">
                <button mat-icon-button></button>
                <div fxFlex="1 1 auto"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="margin-left: -10px;">
                    <a [routerLink]="node.url"
                        style="margin-top: 5px;"
                        *ngIf="node.id=='link'">
                        {{node.name}}</a>
                    <span *ngIf="node.id=='infoPad'"
                        [ngStyle]="{'color': node.nameColor}"
                        style="margin-top: 5px;padding-bottom: 15px;">{{node.name}}
                        <span *ngIf="node.info!=''"
                            style="font-size: 20px;margin-top: 5px;"
                            matTooltip={{node.info}}>ⓘ</span>
                    </span>

                    <span *ngIf="node.id!='infoPad'"
                        [ngStyle]="{'color': node.valueColor}"
                        style="margin-top: 5px; margin-right: 5px;">{{node.value}}</span>
                    <span *ngIf="node.id=='infoPad'"
                        [ngStyle]="{'color': node.valueColor}"
                        style="margin-top: 5px;padding-bottom: 15px; margin-right: 5px;">{{node.value}}</span>
                </div>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild"
                matTreeNodePadding
                matTreeNodePaddingIndent="5"
                style="font-size: 12px; padding-left: 0px; margin-top: -20px;">
                <button mat-icon-button
                    matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <div fxFlex="1 1 auto"
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                    style="margin-left: -10px;">
                    <div fxFlex="1 1 auto"
                        fxLayout="row"
                        fxLayoutAlign="start">
                        <span [ngStyle]="{'color': node.nameColor}"
                            style="font-weight: bold;">{{node.name}}
                            <span *ngIf="node.info!=''"
                                style="font-size: 20px;font-weight: normal;margin-top: 5px;"
                                matTooltip={{node.info}}>ⓘ</span></span>
                        <a [routerLink]="node.url"
                            *ngIf="node.id=='link'">
                            {{node.name}}</a>
                    </div>
                    <div fxFlex="1 1 auto"
                        fxLayout="row"
                        fxLayoutAlign="end">
                        <span *ngIf="node.sign=='-'"
                            style="font-weight: bold;color: green;">
                            {{node.sign}}
                        </span>
                        <span *ngIf="node.sign=='+'"
                            style="font-weight: bold;color: red;">
                            {{node.sign}}
                        </span>
                        <span [ngStyle]="{'color': node.valueColor}"
                            style="font-weight: bold; margin-right: 5px;">{{node.value}}
                        </span>
                    </div>
                </div>
            </mat-tree-node>
        </mat-tree>
    </div>
</div>
