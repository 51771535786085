import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class PropertyService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    if (id != null)
    {
      return this.service.get(urlConfig.INCOME_Property_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));
    } else
    {
      return this.service.get(urlConfig.INCOME_Property_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }
  }

  submitProperty(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Property_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Property_Submit_Url + `/${id}`, model);
    }
  }


  getJson(): any
  {
    const jsonData = [
      {
        "control": "LabelControl",
        "key": "propertyIncome",
        "label": "Property Income & Details",
        "errorType": "",
        "message": "Required",
        "tooltip": "",
        "order": 1
      },
      {
        "control": "RadioButtonControl",
        "key": "PropertyType",
        "label": "Type",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "The repairing expense is 25% of annual rental income for residential property and 30% of annual rental income for commercial property",
        "options": [
          {
            "key": "1",
            "value": "Residential"
          },
          {
            "key": "2",
            "value": "Commercial"
          }
        ],
        "order": 2
      },
      {
        "control": "TextAreaControl",
        "key": "LocationAndDescription",
        "label": "Location and Description of the Property",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the location and description of the property",
        "order": 3
      },
      {
        "control": "TextboxControl",
        "key": "TotalArea",
        "label": "Total Area (In Square Feet)",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the total area of the property",
        "order": 4
      },
      {
        "control": "TextboxControl",
        "key": "AnnualAmountReceived",
        "label": "Annual Rental Income",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers the total revenue or receipts comes from rental property including claim expense.",
        "order": 5
      },
      {
        "control": "LabelControl",
        "key": "propertyExpense",
        "label": "Expenses of Property",
        "errorType": "",
        "message": "Required",
        "tooltip": "",
        "order": 6
      },
      {
        "control": "TextboxControl",
        "key": "RepairCollectionEtc",
        "label": "Repair",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This claim expense is fixed for residential rental property claim expense is 25% of Annual rental income and commercial 30% of Annual rental income ",
        "order": 7
      },
      {
        "control": "TextboxControl",
        "key": "MunicipalLocalTax",
        "label": "Municipal Local Tax",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the total municipal or local tax or cantonment board tax of all rental properties of an income tax year",
        "order": 8
      },
      {
        "control": "TextboxControl",
        "key": "LandRevenue",
        "label": "Land Revenue",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the total land and building tax of rental property of an income tax year",
        "order": 9
      },
      {
        "control": "TextboxControl",
        "key": "LoanMortgageCapitalInterest",
        "label": "Loan/Interest/Mortgage/Capital Change",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the total interest expense on rental property related mortgages, loans, and capital charges of an income tax year",
        "order": 10
      },
      {
        "control": "TextboxControl",
        "key": "InsurancePremium",
        "label": "Insurance Premium",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the total insurance premium expense paid for the all rental properties of an income year.",
        "order": 11
      },
      {
        "control": "TextboxControl",
        "key": "VacancyAllowance",
        "label": "Vacancy Allowance",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the total allowance for all vacant rental properties of an income tax year.",
        "order": 12
      },
      {
        "control": "TextboxControl",
        "key": "Other",
        "label": "Others",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "If any other expense for house property.",
        "order": 13
      },
      {
        "control": "TextboxControl",
        "key": "TotalExpense",
        "label": "Claimed Expenses Total",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the total maintenance expenses for all rental properties of an income tax year",
        "order": 14
      },
      {
        "control": "TextboxControl",
        "key": "IncomeAfterDeduction",
        "label": "Net Property Income",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers the net taxable income from house property.",
        "order": 15
      },
      {
        "control": "LabelControl",
        "key": "partiallyOwned",
        "label": "If This Property is Partially Owned",
        "errorType": "",
        "message": "Required",
        "tooltip": "",
        "order": 16
      },
      {
        "control": "TextboxControl",
        "key": "AsesseeSharePercent",
        "label": "Share Percent of Property",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "If the property is partially owned, share percent of the property that is owned by the taxpayer.",
        "order": 17
      },
      {
        "control": "TextboxControl",
        "key": "ShareOfIncomeAmount",
        "label": "Share of Income",
        "required": false,
        "readonly": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the share of income of the taxpayer.",
        "order": 18
      }
    ];
    return jsonData;
  }

  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
