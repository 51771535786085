import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class ElectronicEquipmentListService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  deleteControl(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.delete(urlConfig.ASSET_Electronic_Equipment_Delete_Url + `${id}`);
  }

  getControlJson(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.ASSET_Electronic_Equipment_List_Url);
  }

  getJson(): any
  {
    const jsonData =
    {

      "Header": {
        "Header1": "Action",
        "Header2": "Description",
        "Header3": "Amount"
      },
      "Body": [
        {
          "Action": "",
          "Description": "",
          "Amount": "",
        },

      ],
      "TotalAmount": "",
      "TotalRows": "",
    }
    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
