import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';

import * as moment from 'moment';
import { ClientsListService } from 'app/pages/Manage-Clients/Clients-List/ClientsList.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
    selector: 'CreateCollectionDialog',
    templateUrl: 'create-collection-Dialog.component.html',
    styleUrls: ['./create-collection-Dialog.component.scss'],
})
export class CreateCollectionDialog
{


    lang = '';

    BcTabClientEtin: any = "";
    BcTabClientName: any = "";
    BcTabClientMobile: any = "";
    BcTabClientEmail: any = "";
    BcTabClientRef: any = "";
    BcTabgetData: any;
    BcTabTotalDues: any = "";
    BcTabTotalBill: any = "";
    BcTabTotalCollection = 0;

    BcTabRemarksControl = new FormControl();
    BcTabDateControl = new FormControl(new Date());
    BcTabPayTypeControl = new FormControl();

    BcTabMainForm: FormGroup;
    BcTabTempForm: FormGroup;

    maxDate = new Date();
    BcTabBillEnabled = false;

    entryDisabled = true;
    showModal = false;

    PageResponseData: [];
    dropDownData: any;
    BcTabSearchControl = new FormControl();
    BcTabfilteredOptions: Observable<any[]>;
    BcTabAutoSearchData: any;

    constructor(
        public dialogRef: MatDialogRef<CreateCollectionDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _clientsListService: ClientsListService,
        private _transactionControlService: TransactionService,

        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        dialogRef.disableClose = true;
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');
        // this.BcTabSearchClient();
        this.getInitialData();

        this.BcTabSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._transactionControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.BcTabfilteredOptions = response.data;
                        // console.log(response, 'response');

                    });
                }
                else
                {
                    this.BcTabAutoSearchData = null;
                    return null;
                }
            })
    }


    getMainDropData(): void
    {
        this._spinner.show();

        this._transactionControlService.GetCommomDropdown().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
                this.dropDownData = data;
                // console.loglog(this.dropDownData, 'this.dropDownData');

                // this.BcTabAssessmentYearControl.setValue(this.dropDownData.CurrentYearId.toString());

            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        });
    }

    getInitialData(): void
    {
        this._spinner.show();
        forkJoin([
            this._transactionControlService.GetCommomDropdown(),
            this._transactionControlService.GetAccountHead()]).subscribe(
                (result: any) =>
                {
                    let dropDownResponse = result[0];
                    // console.loglog(dropDownResponse, 'dropDownResponse');

                    this.dropDownData = dropDownResponse;

                    let pageDataResponse = result[1];
                    // console.log(pageDataResponse, 'pageDataResponse');

                    this.PageResponseData = pageDataResponse.data;

                    if (this.PageResponseData.length != 0)
                    {
                        this.showModal = true;
                        let tempData = [];
                        this.PageResponseData.forEach((item, index) =>
                        {
                            tempData.push(this.gotItem2(item));
                        });
                        this.BcTabTempForm = this._formBuilder.group({
                            items: this._formBuilder.array(tempData),
                        });
                        this.entryDisabled = true;
                    } else
                    {
                        this.showModal = false;
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 4000
                        });
                        this.dialogRef.close('no');
                    }
                    this._spinner.hide();


                }, (error: any) =>
            {
                this._spinner.hide();
                console.log(error, 'error');
            })
    }

    BcTabSearchClient(value): void
    {
        this._spinner.show();
        this.BcTabAutoSearchData = value;
        // this.BcTabSearchControl.disable();


        let query = {
            // "AssesseId": this.dialogdata.AssesseeId,
            // "ReturnId": this.dialogdata.ReturnId,
            "AssesseId": value.AssesseeId,
            "ReturnId": value.ReturnId,
            // "DateRange": `${moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY/MM/DD")}-${moment(new Date()).format("YYYY/MM/DD")}`,
            // "AssessmentYearId": this.dialogdata.year,
            "DateRange": null,
            "AssessmentYearId": null
        }

        // console.log(query, 'query');
        this._clientsListService.SearchTransaction(query).subscribe(data =>
        {
            // console.log(data, 'SearchTransaction');

            if (data != null)
            {
                this.BcTabgetData = data.data;

                this.BcTabClientEtin = data.data.ETIN;
                this.BcTabClientName = data.data.AssesseName;
                this.BcTabClientMobile = data.data.ContactNo;
                this.BcTabClientEmail = data.data.Email;
                this.BcTabClientRef = data.data.Reference;

                // this.BcTabTotalDues = data.data.TotalDues;
                // this.BcTabTotalBill = data.data.TotalBill;
                // this.BcTabTotalCollection = data.data.TotalCollection;


                // if (data.data.PaymentTypeId != null)
                // {
                //     this.BcTabPayTypeControl.setValue(data.data.PaymentTypeId.toString());
                // }

                // this.BcTabRemarksControl.setValue(data.data.Remarks);
                if (data.data.TransactionDetailsList.length != 0)
                {
                    this.showModal = true;
                    let tempData = [];
                    data.data.TransactionDetailsList.forEach((item, index) =>
                    {
                        tempData.push(this.gotItem(item));
                    });
                    this.BcTabMainForm = this._formBuilder.group({
                        items: this._formBuilder.array(tempData),
                    });
                    this.entryDisabled = false;
                } else
                {
                    this.showModal = false;
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 4000
                    });
                    this.dialogRef.close('no');
                }
                this._spinner.hide();

            } else
            {
                this._spinner.hide();

                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }


            this._spinner.hide();
        });

    }


    gotItem(item): FormGroup
    {
        return this._formBuilder.group({

            MasterId: item.MasterId,
            ReturnId: item.ReturnId,
            AssesseeId: item.AssesseeId,
            AssessmentYearId: item.AssessmentYearId,
            AccounHeadId: item.AccounHeadId,
            // AccounHeadtName: item.AccounHeadtName == 'TaxPay' ? 'Tax Pay' : item.AccounHeadtName,
            AccounHeadtName: Utils.transformAccounHeadtName(item.AccounHeadtName),
            Dues: item.Dues,
            // Bill: item.Bill,
            Collection: 0,
            // Remission: item.Remission,
            Notes: item.Notes
        })
    }

    gotItem2(item): FormGroup
    {
        return this._formBuilder.group({

            MasterId: '',
            ReturnId: '',
            AssesseeId: '',
            AssessmentYearId: '',
            AccounHeadId: '',
            // AccounHeadtName: item.AccounHeadtName == 'TaxPay' ? 'Tax Pay' : item.AccounHeadtName,
            AccounHeadtName: Utils.transformAccounHeadtName(item.AccounHeadtName),
            Dues: '',
            // Bill: item.Bill,
            Collection: '',
            // Remission: item.Remission,
            Notes: '',
        })
    }

    BcTabMainFormitems(): FormArray
    {
        return this.BcTabMainForm.get("items") as FormArray
    }

    BcTabTempFormitems(): FormArray
    {
        return this.BcTabTempForm.get("items") as FormArray
    }


    BcTabOnFocusoutEvent(event, key, i)
    {
        let rawForm = this.BcTabMainForm.getRawValue();

        let Bill = null;

        rawForm.items[i].Bill == '' ?
            Bill = 0 : Bill = rawForm.items[i].Bill;

        Bill = Number(Bill);

        let Collection = null;

        rawForm.items[i].Collection == '' ?
            Collection = 0 : Collection = rawForm.items[i].Collection;

        Collection = Number(Collection);


        // let Dues = null;

        // rawForm.items[i].Dues == '' ?
        //     Dues = 0 : Dues = rawForm.items[i].Dues;

        // Dues = Number(Dues);

        // console.log(this.BcTabgetData.TransactionDetailsList[i]);


        // if (key == "Bill" || key == "Collection")
        // {
        //     ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(Bill - Collection);



        // }

        if (key == "Bill")
        {

            // let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            // let duesCal = Dues + Bill;

            // ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(duesCal);

            // if (Bill == 0 || Bill == "")
            // {
            //     ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Bill').patchValue(this.BcTabgetData.TransactionDetailsList[i].Bill);
            // }
            this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);

        }

        if (key == "Collection")
        {
            // let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            // let duesCal = Dues - Collection;
            // ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(duesCal);

            let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            if (Collection > Dues)
            {

                ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Collection').patchValue(Number(Collection.toString().slice(0, -1)))

                this._matSnackBar.open(this.lang == 'en' ? "Collection amount can't be greater than Due amount" : "Collection amount can't be greater than Due amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }

            this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);


        }
        // this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0) - this.BcTabgetData.TotalRemission;

        // this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);
        // this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);
        // this.BcTabTotalDues = this.BcTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);


    }

    showBcTabBills(event)
    {
        if (event.checked == true)
        {
            this.BcTabBillEnabled = true;

            for (let index = 0; index < (this.BcTabMainForm.get('items') as FormArray).controls.length; index++)
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Bill').patchValue(0);
            }

            this.BcTabTotalBill = 0;


        }
        else
        {
            this.BcTabBillEnabled = false;

            this.BcTabgetData.TransactionDetailsList.forEach((item, index) =>
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Bill').patchValue(item.Bill);
            });

            this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);

            // this.BcTabTotalDues = this.BcTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);
        }


    }

    SaveCollection(): void
    {


        let tb = Number(this.BcTabTotalCollection) == 0;

        let tp = this.BcTabPayTypeControl.value == "" || this.BcTabPayTypeControl.value == null ? true : false;



        if (tb == true)
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please enter an amount" : "Please enter an amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {

            if (tp)
            {
                this._matSnackBar.open(this.lang == 'en' ? "Please select A payment Type" : "Please select A payment Type", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });

            }
            else
            {
                this._spinner.show();

                let flag = 'Collection';



                let child = this.BcTabMainForm.value.items.map(value =>
                ({
                    "TransactionId": 0,
                    "AccountHeadId": value.AccounHeadId,
                    // "Dues": value.Dues,
                    "Dues": 0,

                    "Bill": 0,
                    "Collection": value.Collection,
                    "Remission": 0,
                    "Notes": "",
                }));

                let query = {
                    "Id": 0,
                    "TransactionDate": moment(this.BcTabDateControl.value).format("YYYY-MM-DD"),
                    // "ReturnId": this.dialogdata.ReturnId,
                    // "AssesseeId": this.dialogdata.AssesseeId,
                    "ReturnId": this.BcTabAutoSearchData.ReturnId,
                    "AssesseeId": this.BcTabAutoSearchData.AssesseeId,
                    // "AssessmentYearId": this.dialogdata.year,
                    "AssessmentYearId": this.dropDownData.CurrentYearId,
                    "ReceiptNo": 0,
                    "TotalDues": 0,
                    "TotalBill": 0,
                    "TotalCollection": this.BcTabTotalCollection,
                    "TotalRemission": 0,
                    "PaymentTypeId": this.BcTabPayTypeControl.value,
                    "Remarks": this.BcTabRemarksControl.value,
                    "TransactionFlag": flag,
                    "PractitionerTransactionDetailsViewModels": child
                }
                // console.log(query, 'query');

                this._transactionControlService.SaveTransaction(query).subscribe(data =>
                {
                    // console.log(data, 'data');
                    if (data.isError == false)
                    {
                        if (this.BcTabgetData.Id == 0)
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        } else
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }

                        this._spinner.hide();
                        this.dialogRef.close('updated');
                    }
                    else
                    {
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                });

            }
        }


    }

    onNoClick(): void
    {
        this.dialogRef.close("no");
    }







}
