
export class OtherResourcesModel
{





  //1
  InterestMutualOrUnitFundAmount: string = "";
  InterestMutualOrUnitFundTDS: string = "";
  InterestMutualOrUnitFundTaxable: string = "";

  //2
  CashDividendStockExchangeAmount: string = "";
  CashDividendStockExchangeTDS: string = "";
  CashDividendStockExchangeTaxable: string = "";

  //3
  InterestIncomeWEDBAmount: string = "";
  InterestIncomeWEDBTDS: string = "";
  InterestIncomeWEDBTaxable: string = "";

  //4
  USDollarPremiumInvestmentBondAmount: string = "";
  USDollarPremiumInvestmentBondTDS: string = "";
  USDollarPremiumInvestmentBondTaxable: string = "";

  //5
  PoundSterlingPremiumInvestmentBondAmount: string = "";
  PoundSterlingPremiumInvestmentBondTDS: string = "";
  PoundSterlingPremiumInvestmentBondTaxable: string = "";

  //6
  EuroPremiumInvestmentBondAmount: string = "";
  EuroPremiumInvestmentBondTDS: string = "";
  EuroPremiumInvestmentBondTaxable: string = "";

  //7
  ZeroCouponBondAmount: string = "";
  ZeroCouponBondTDS: string = "";
  ZeroCouponBondTaxable: string = "";

  //8
  InvestmentPensionerSavingsInstrumentAmount: string = "";

  //9
  InterestReceivedPensionerSavingsInstrumentAmount: string = "";
  InterestReceivedPensionerSavingsInstrumentTDS: string = "";
  InterestReceivedPensionerSavingsInstrumentTaxable: string = "";

  //10
  SumReceivedBecauseOfPositionLikeDirectorFeeAmount: string = "";
  SumReceivedBecauseOfPositionLikeDirectorFeeTDS: string = "";
  SumReceivedBecauseOfPositionLikeDirectorFeeTaxable: string = "";

  //11
  HonoriumWritingArticleAmount: string = "";
  HonoriumWritingArticleTDS: string = "";
  HonoriumWritingArticleTaxable: string = "";

  //12
  RemunerationExaminerInvigilatorAmount: string = "";
  RemunerationExaminerInvigilatorTDS: string = "";
  RemunerationExaminerInvigilatorTaxable: string = "";

  //13
  IncomeTuitionAmount: string = "";
  IncomeTuitionTDS: string = "";
  IncomeTuitionTaxable: string = "";

  //14
  IncomeTelevisionRadioParticipatingProgramAmount: string = "";
  IncomeTelevisionRadioParticipatingProgramTDS: string = "";
  IncomeTelevisionRadioParticipatingProgramTaxable: string = "";

  //15
  OthersAmount: string = "";
  OthersTDS: string = "";
  OthersTaxable: string = "";

  //16
  TotalAmount: string = "";
  TotalTDS: string = "";
  TotalTaxable: string = "";

}
