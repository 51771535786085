import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { NotificationComponent } from './Notification.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseCountdownModule, FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NotificationService } from './Notification.service';
import { ViewProfileGuard } from 'app/auth/viewProfile.guard';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete'

import { MentionModule } from 'angular-mentions';
import { TemplateListDialog } from './template-list-dialog/template-list-Dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TemplateSaveDialog } from './save-template-dialog/save-template-Dialog.component';
import { TemplateEditDialog } from './edit-template-dialog/edit-template-Dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';


const routes = [
    {
        path: 'Notification',
        component: NotificationComponent,
        canActivate: [AuthGuard, ViewProfileGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        NotificationComponent,
        TemplateListDialog,
        TemplateSaveDialog,
        TemplateEditDialog,
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatTooltipModule,

        MatButtonModule,

        MatFormFieldModule,

        MatInputModule,

        MatTabsModule,

        MatDialogModule,

        MatCheckboxModule,

        MatSnackBarModule,

        MatCheckboxModule,

        MatSelectModule,

        MatTableModule,

        MatAutocompleteModule,

        MatDatepickerModule,

        FuseCountdownModule,

        FuseSharedModule,

        FuseSidebarModule,

        MentionModule,

        FuseDemoModule,

        QuickPanelModule,

        NgCircleProgressModule

    ],
    exports: [
        NotificationComponent
    ],
    providers: [

        NotificationService
    ],
})

export class NotificationModule
{
}






let opp = {
    "GetNCGatewayForDropdown": [
        {
            "Id": 0,
            "VendorId": 0,
            "Value": "2",
            "Text": "Bangla Phone Power SMS || 03590012452",
            "OptGroup": "SMS",
            "Selected": false,
            "NotificationVendor": 0
        },
        {
            "Id": 0,
            "VendorId": 0,
            "Value": "3",
            "Text": "SSL Wireless Masking || CLOUDCAMPUS",
            "OptGroup": "SMS",
            "Selected": false,
            "NotificationVendor": 0
        },
        {
            "Id": 0,
            "VendorId": 0,
            "Value": "5",
            "Text": "SendGrid || DUPL",
            "OptGroup": "Email",
            "Selected": false,
            "NotificationVendor": 0
        }
    ],
    "DefaultSelectedGatewayId": 2,
    "GetAllNCTemplate": [
        {
            "Id": 1,
            "TemplateName": "Test",
            "MessageText": "Dear @Assessee_Name (Client Id: @Assessee_Id) Assalamu Alaikum!",
            "DBFields": "test",
            "HiddenTemplate": "Dear @ITAXAssesseeInfo_Name (Client Id: @ITAXAssesseeInfo_Id) Assalamu Alaikum! "
        },
        {
            "Id": 2,
            "TemplateName": "Test1",
            "MessageText": "Dear @Assessee_Name(Client Id: @Assessee_Id) Assalamu Alaikum!",
            "DBFields": "Test",
            "HiddenTemplate": "Dear @ITAXAssesseeInfo_Name (Client Id: @ITAXAssesseeInfo_Id) Assalamu Alaikum!"
        },
        {
            "Id": 3,
            "TemplateName": "Test Template1",
            "MessageText": "Dear @Assessee_Name(Client Id: @Assessee_Id) Assalamu Alaikum!",
            "DBFields": "Test",
            "HiddenTemplate": "Dear @ITAXAssesseeInfo_Name (Client Id: @ITAXAssesseeInfo_Id) Assalamu Alaikum!"
        },
        {
            "Id": 4,
            "TemplateName": "Test Template9",
            "MessageText": "Dear @Assessee_Name(Client Id: @Assessee_Id) Assalamu Alaikum!",
            "DBFields": "Test",
            "HiddenTemplate": "Dear @ITAXAssesseeInfo_Name (Client Id: @ITAXAssesseeInfo_Id) Assalamu Alaikum!"
        }
    ],
    "GetAllNCDBField": [
        {
            "Id": 1,
            "KeyName": "ITAXAssesseeInfo_Id",
            "DisplayName": "Assessee_Id"
        },
        {
            "Id": 2,
            "KeyName": "ITAXAssesseeInfo_Name",
            "DisplayName": "Assessee_Name"
        },
        {
            "Id": 3,
            "KeyName": "ITAXAssesseeInfo_ContactNo",
            "DisplayName": "Contact_No"
        },
        {
            "Id": 4,
            "KeyName": "ITAXAssesseeInfo_Email",
            "DisplayName": "Email"
        }
    ]
}


let dfd={
    "isError": false,
    "responseStatus": "success",
    "responseCode": 200,
    "message": "Data retrieve Successfully.",
    "exceptionMessage": null,
    "errors": {},
    "data": {
        "GetNCGatewayForDropdown": [
            {
                "Id": 0,
                "VendorId": 0,
                "Value": "2",
                "Text": "Bangla Phone Power SMS || 03590012452",
                "OptGroup": "SMS",
                "Selected": false,
                "NotificationVendor": 0
            },
            {
                "Id": 0,
                "VendorId": 0,
                "Value": "3",
                "Text": "SSL Wireless Masking || CLOUDCAMPUS",
                "OptGroup": "SMS",
                "Selected": false,
                "NotificationVendor": 0
            },
            {
                "Id": 0,
                "VendorId": 0,
                "Value": "5",
                "Text": "SendGrid || DUPL",
                "OptGroup": "Email",
                "Selected": false,
                "NotificationVendor": 0
            }
        ],
        "DefaultSelectedGatewayId": 2,
        "GetAllNCTemplate": [
            {
                "Id": 2,
                "CategoryId": 1,
                "TemplateName": "Test",
                "MessageText": "Dear @Assessee_Name (Client Id: @Assessee_Id) Assalamu Alaikum!",
                "DBFields": "test",
                "HiddenTemplate": "Dear @ITAXAssesseeInfo_Name (Client Id: @ITAXAssesseeInfo_Id) Assalamu Alaikum! "
            },
            {
                "Id": 5,
                "CategoryId": 1,
                "TemplateName": "Test4",
                "MessageText": "Dear @Assessee_Name(Client Id: @Assessee_Id) Assalamu Alaikum!",
                "DBFields": "Test",
                "HiddenTemplate": "Dear @ITAXAssesseeInfo_Name (Client Id: @ITAXAssesseeInfo_Id) Assalamu Alaikum!"
            },
            {
                "Id": 6,
                "CategoryId": 1,
                "TemplateName": "TestByZahir",
                "MessageText": "Dear @Assessee_Name(Client Id: @Assessee_Id) Assalamu Alaikum!",
                "DBFields": "Test",
                "HiddenTemplate": "Dear @ITAXAssesseeInfo_Name (Client Id: @ITAXAssesseeInfo_Id) Assalamu Alaikum!"
            }
        ],
        "GetAllNCDBField": [
            {
                "Id": 2,
                "CategoryId": 1,
                "KeyName": "ITAXAssesseeInfo_Id",
                "DisplayName": "Assessee_Id"
            },
            {
                "Id": 3,
                "CategoryId": 1,
                "KeyName": "ITAXAssesseeInfo_Name",
                "DisplayName": "Assessee_Name"
            },
            {
                "Id": 4,
                "CategoryId": 1,
                "KeyName": "ITAXAssesseeInfo_ContactNo",
                "DisplayName": "Contact_No"
            },
            {
                "Id": 5,
                "CategoryId": 1,
                "KeyName": "ITAXAssesseeInfo_Email",
                "DisplayName": "Email"
            }
        ]
    }
}

// 123123123123
// sim kio
// 23423423423
// sdsd@rom.com


let gfg={
    "Id": 0,
    "CategoryId": "1",
    "TemplateId": 0,
    "MessageText": "Dear @Assessee_Name(Client Id: @Assessee_Id) Assalamu Alaikum!",
    "ActiveStatus": 1,
    "Recipient": "ContactNo",
    "NCGatewayIds": "2",
    "NCDetailsList": [
        {
            "Reference": "test ref 01",
            "ETIN": "656564546432",
            "AssesseName": "mobile",
            "AssesseeId": 2216,
            "ReturnId": 2205,
            "ContactNo": "13234234242",
            "Email": "sdads@dfdf.com",
            "MessageText": "Dear mobile(Client Id: 2216) Assalamu Alaikum!"
        }
    ]
}
