import { TaxConfig } from '../../../util/tax.config';

export class SalaryGovtClass
{
  constructor(
    private _BasicPayAmount: number,
    private _BasicPayExempted: number,
    private _BasicPayTaxable: number,

    private _SpecialPayAmount: number,
    private _SpecialPayExempted: number,
    private _SpecialPayTaxable: number,

    private _DearnessAllowanceAmount: number,
    private _DearnessAllowanceExempted: number,
    private _DearnessAllowanceTaxable: number,

    private _ConveyanceAllowanceAmount: number,
    private _ConveyanceAllowanceExempted: number,
    private _ConveyanceAllowanceTaxable: number,

    private _HouseRentAllowanceAmount: number,
    private _HouseRentAllowanceExempted: number,
    private _HouseRentAllowanceTaxable: number,

    private _MedicalAllowanceAmount: number,
    private _MedicalAllowanceExempted: number,
    private _MedicalAllowanceTaxable: number,

    private _AllowanceForSupportStaffAmount: number,
    private _AllowanceForSupportStaffExempted: number,
    private _AllowanceForSupportStaffTaxable: number,

    private _LeaveFairAssistanceStaffAmount: number,
    private _LeaveFairAssistanceStaffExempted: number,
    private _LeaveFairAssistanceStaffTaxable: number,

    private _LeaveEncashmentAmount: number,
    private _LeaveEncashmentExempted: number,
    private _LeaveEncashmentTaxable: number,

    private _HonorariumOrRewardAmount: number,
    private _HonorariumOrRewardExempted: number,
    private _HonorariumOrRewardTaxable: number,

    private _OvertimeAllowanceAmount: number,
    private _OvertimeAllowanceExempted: number,
    private _OvertimeAllowanceTaxable: number,

    private _BonusAmount: number,
    private _BonusExempted: number,
    private _BonusTaxable: number,

    private _OtherAllowancesAmount: number,
    private _OtherAllowancesExempted: number,
    private _OtherAllowancesTaxable: number,

    private _EmployersContributionProvidentFundAmount: number,
    private _EmployersContributionProvidentFundExempted: number,
    private _EmployersContributionProvidentFundTaxable: number,

    private _InterestAccruedProvidentFundRateOfInterestAmount: number,
    private _InterestAccruedProvidentFundRateOfInterestExempted: number,
    private _InterestAccruedProvidentFundRateOfInterestTaxable: number,


    private _DeemedIncomeTransportAmount: number,
    private _DeemedIncomeTransportExempted: number,
    private _DeemedIncomeTransportTaxable: number,

    private _EmployerPaidRentalAmount: number,
    private _DeemedFreeAccommodationExempted: number,
    private _FurnishedOrUnfurnishedHouseFromEmployerTaxable: number,

    private _FestivalBonusAmount: number,
    private _FestivalBonusExempted: number,
    private _FestivalBonusTaxable: number,

    private _BengaliNewYearBonusAmount: number,
    private _BengaliNewYearBonusExempted: number,
    private _BengaliNewYearBonusTaxable: number,

    private _FestivalAllowanceAmount: number,
    private _FestivalAllowanceExempted: number,
    private _FestivalAllowanceTaxable: number,

    private _PensionAmount: number,
    private _PensionExempted: number,
    private _PensionTaxable: number,

    private _ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount: number,
    private _ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted: number,
    private _ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable: number,


    private _OthersAmount: number,
    private _OthersExempted: number,
    private _OthersTaxable: number,

    private _ArrearPayAmount: number,
    private _ArrearPayExempted: number,
    private _ArrearPayTaxable: number,

    private _TotalAmount: number,
    private _TotalExempted: number,
    private _NetTaxable: number,

    private _GrossAmount: number,
    private _GrossExempted: number,
    private _GrossTaxable: number,
  )
  {

  }


  public get BasicPayAmount(): number
  {
    return this._BasicPayAmount;
  }
  public set BasicPayAmount(x: number)
  {
    this._BasicPayAmount = x;
  }

  public get BasicPayExempted(): number
  {
    return this._BasicPayExempted = 0.00;
  }
  public set BasicPayExempted(x: number)
  {
    this._BasicPayExempted = x;
  }

  public get BasicPayTaxable(): number
  {
    return this._BasicPayTaxable = this.BasicPayAmount;
  }
  public set BasicPayTaxable(x: number)
  {
    this._BasicPayTaxable = x;
  }

  public get SpecialPayAmount(): number
  {
    return this._SpecialPayAmount;
  }
  public set SpecialPayAmount(x: number)
  {
    this._SpecialPayAmount = x;
  }

  public get SpecialPayExempted(): number
  {
    return this._SpecialPayExempted = this.SpecialPayAmount;
  }
  public set SpecialPayExempted(x: number)
  {
    this._SpecialPayExempted = x;
  }

  public get SpecialPayTaxable(): number
  {
    return this._SpecialPayTaxable = 0.00;
  }
  public set SpecialPayTaxable(x: number)
  {
    this._SpecialPayTaxable = x;
  }

  public get DearnessAllowanceAmount(): number
  {
    return this._DearnessAllowanceAmount;
  }
  public set DearnessAllowanceAmount(x: number)
  {
    this._DearnessAllowanceAmount = x;
  }

  public get DearnessAllowanceExempted(): number
  {
    return this._DearnessAllowanceExempted = this.DearnessAllowanceAmount;
  }
  public set DearnessAllowanceExempted(x: number)
  {
    this._DearnessAllowanceExempted = x;
  }

  public get DearnessAllowanceTaxable(): number
  {
    return this._DearnessAllowanceTaxable = 0.00;
  }
  public set DearnessAllowanceTaxable(x: number)
  {
    this._DearnessAllowanceTaxable = x;
  }

  public get ConveyanceAllowanceAmount(): number
  {
    return this._ConveyanceAllowanceAmount;
  }
  public set ConveyanceAllowanceAmount(x: number)
  {
    this._ConveyanceAllowanceAmount = x;
  }

  public get ConveyanceAllowanceExempted(): number
  {
    return this._ConveyanceAllowanceExempted = this.ConveyanceAllowanceAmount;
  }

  public set ConveyanceAllowanceExempted(x: number)
  {
    this._ConveyanceAllowanceExempted = x;
  }

  public get ConveyanceAllowanceTaxable(): number
  {
    return this._ConveyanceAllowanceTaxable = 0.00;
  }
  public set ConveyanceAllowanceTaxable(x: number)
  {
    this._ConveyanceAllowanceTaxable = x;
  }

  public get HouseRentAllowanceAmount(): number
  {
    return this._HouseRentAllowanceAmount;
  }
  public set HouseRentAllowanceAmount(x: number)
  {
    this._HouseRentAllowanceAmount = x;
  }

  public get HouseRentAllowanceExempted(): number
  {
    return this._HouseRentAllowanceExempted = this.HouseRentAllowanceAmount;
  }

  public set HouseRentAllowanceExempted(x: number)
  {
    this._HouseRentAllowanceExempted = x;
  }

  public get HouseRentAllowanceTaxable(): number
  {
    return this._HouseRentAllowanceTaxable = 0.00;
  }
  public set HouseRentAllowanceTaxable(x: number)
  {
    this._HouseRentAllowanceTaxable = x;
  }

  public get MedicalAllowanceAmount(): number
  {
    return this._MedicalAllowanceAmount;
  }
  public set MedicalAllowanceAmount(x: number)
  {
    this._MedicalAllowanceAmount = x;
  }

  public get MedicalAllowanceExempted(): number
  {
    return this._MedicalAllowanceExempted = this.MedicalAllowanceAmount;
  }
  public set MedicalAllowanceExempted(x: number)
  {
    this._MedicalAllowanceExempted = x;
  }

  public get MedicalAllowanceTaxable(): number
  {
    return this._MedicalAllowanceTaxable = 0.00;
  }
  public set MedicalAllowanceTaxable(x: number)
  {
    this._MedicalAllowanceTaxable = x;
  }

  public get AllowanceForSupportStaffAmount(): number
  {
    return this._AllowanceForSupportStaffAmount;
  }
  public set AllowanceForSupportStaffAmount(x: number)
  {
    this._AllowanceForSupportStaffAmount = x;
  }

  public get AllowanceForSupportStaffExempted(): number
  {
    return this._AllowanceForSupportStaffExempted = this.AllowanceForSupportStaffAmount;
  }

  public set AllowanceForSupportStaffExempted(x: number)
  {
    this._AllowanceForSupportStaffExempted = x;
  }

  public get AllowanceForSupportStaffTaxable(): number
  {
    return this._AllowanceForSupportStaffTaxable = 0.00;
  }
  public set AllowanceForSupportStaffTaxable(x: number)
  {
    this._AllowanceForSupportStaffTaxable = x;
  }

  public get LeaveFairAssistanceStaffAmount(): number
  {
    return this._LeaveFairAssistanceStaffAmount;
  }
  public set LeaveFairAssistanceStaffAmount(x: number)
  {
    this._LeaveFairAssistanceStaffAmount = x;
  }

  public get LeaveFairAssistanceStaffExempted(): number
  {
    return this._LeaveFairAssistanceStaffExempted = this.LeaveFairAssistanceStaffAmount;
  }
  public set LeaveFairAssistanceStaffExempted(x: number)
  {
    this._LeaveFairAssistanceStaffExempted = x;
  }

  public get LeaveFairAssistanceStaffTaxable(): number
  {
    return this._LeaveFairAssistanceStaffTaxable = 0.00;
  }
  public set LeaveFairAssistanceStaffTaxable(x: number)
  {
    this._LeaveFairAssistanceStaffTaxable = x;
  }

  public get LeaveEncashmentAmount(): number
  {
    return this._LeaveEncashmentAmount;
  }
  public set LeaveEncashmentAmount(x: number)
  {
    this._LeaveEncashmentAmount = x;
  }

  public get LeaveEncashmentExempted(): number
  {
    return this._LeaveEncashmentExempted = this.LeaveEncashmentAmount;
  }
  public set LeaveEncashmentExempted(x: number)
  {
    this._LeaveEncashmentExempted = x;
  }

  public get LeaveEncashmentTaxable(): number
  {
    return this._LeaveEncashmentTaxable = 0.00;
  }
  public set LeaveEncashmentTaxable(x: number)
  {
    this._LeaveEncashmentTaxable = x;
  }

  public get HonorariumOrRewardAmount(): number
  {
    return this._HonorariumOrRewardAmount;
  }
  public set HonorariumOrRewardAmount(x: number)
  {
    this._HonorariumOrRewardAmount = x;
  }

  public get HonorariumOrRewardExempted(): number
  {
    return this._HonorariumOrRewardExempted = 0.00;
  }
  public set HonorariumOrRewardExempted(x: number)
  {
    this._HonorariumOrRewardExempted = x;
  }

  public get HonorariumOrRewardTaxable(): number
  {
    return this._HonorariumOrRewardTaxable = this.HonorariumOrRewardAmount;
  }
  public set HonorariumOrRewardTaxable(x: number)
  {
    this._HonorariumOrRewardTaxable = x;
  }

  public get OvertimeAllowanceAmount(): number
  {
    return this._OvertimeAllowanceAmount;
  }
  public set OvertimeAllowanceAmount(x: number)
  {
    this._OvertimeAllowanceAmount = x;
  }

  public get OvertimeAllowanceExempted(): number
  {
    return this._OvertimeAllowanceExempted = this.OvertimeAllowanceAmount;
  }
  public set OvertimeAllowanceExempted(x: number)
  {
    this._OvertimeAllowanceExempted = x;
  }

  public get OvertimeAllowanceTaxable(): number
  {
    return this._OvertimeAllowanceTaxable = 0.00;
  }
  public set OvertimeAllowanceTaxable(x: number)
  {
    this._OvertimeAllowanceTaxable = x;
  }

  public get BonusAmount(): number
  {
    return this._BonusAmount;
  }
  public set BonusAmount(x: number)
  {
    this._BonusAmount = x;
  }

  public get BonusExempted(): number
  {
    return this._BonusExempted = 0.00;
  }
  public set BonusExempted(x: number)
  {
    this._BonusExempted = x;
  }

  public get BonusTaxable(): number
  {
    return this._BonusTaxable = this.BonusAmount;
  }
  public set BonusTaxable(x: number)
  {
    this._BonusTaxable = x;
  }

  public get OtherAllowancesAmount(): number
  {
    return this._OtherAllowancesAmount;
  }
  public set OtherAllowancesAmount(x: number)
  {
    this._OtherAllowancesAmount = x;
  }

  public get OtherAllowancesExempted(): number
  {
    return this._OtherAllowancesExempted = this.OtherAllowancesAmount;
  }
  public set OtherAllowancesExempted(x: number)
  {
    this._OtherAllowancesExempted = x;
  }

  public get OtherAllowancesTaxable(): number
  {
    return this._OtherAllowancesTaxable = 0.00;
  }
  public set OtherAllowancesTaxable(x: number)
  {
    this._OtherAllowancesTaxable = x;
  }

  public get EmployersContributionProvidentFundAmount(): number
  {
    return this._EmployersContributionProvidentFundAmount;
  }
  public set EmployersContributionProvidentFundAmount(x: number)
  {
    this._EmployersContributionProvidentFundAmount = x;
  }

  public get EmployersContributionProvidentFundExempted(): number
  {
    return this._EmployersContributionProvidentFundExempted = this.EmployersContributionProvidentFundAmount;
  }
  public set EmployersContributionProvidentFundExempted(x: number)
  {
    this._EmployersContributionProvidentFundExempted = x;
  }

  public get EmployersContributionProvidentFundTaxable(): number
  {
    return this._EmployersContributionProvidentFundTaxable = 0.00;
  }
  public set EmployersContributionProvidentFundTaxable(x: number)
  {
    this._EmployersContributionProvidentFundTaxable = x;
  }

  public get InterestAccruedProvidentFundRateOfInterestAmount(): number
  {
    return this._InterestAccruedProvidentFundRateOfInterestAmount;
  }
  public set InterestAccruedProvidentFundRateOfInterestAmount(x: number)
  {
    this._InterestAccruedProvidentFundRateOfInterestAmount = x;
  }

  public get InterestAccruedProvidentFundRateOfInterestExempted(): number
  {
    return this._InterestAccruedProvidentFundRateOfInterestExempted = this.InterestAccruedProvidentFundRateOfInterestAmount;
  }
  public set InterestAccruedProvidentFundRateOfInterestExempted(x: number)
  {
    this._InterestAccruedProvidentFundRateOfInterestExempted = x;
  }

  public get InterestAccruedProvidentFundRateOfInterestTaxable(): number
  {
    return this._InterestAccruedProvidentFundRateOfInterestTaxable = 0.00;
  }
  public set InterestAccruedProvidentFundRateOfInterestTaxable(x: number)
  {
    this._InterestAccruedProvidentFundRateOfInterestTaxable = x;
  }

  public get DeemedIncomeTransportAmount(): number
  {
    return this._DeemedIncomeTransportAmount;
  }
  public set DeemedIncomeTransportAmount(x: number)
  {
    this._DeemedIncomeTransportAmount = x;
  }

  public get DeemedIncomeTransportExempted(): number
  {
    return this._DeemedIncomeTransportExempted = this.DeemedIncomeTransportAmount;
  }
  public set DeemedIncomeTransportExempted(x: number)
  {
    this._DeemedIncomeTransportExempted = x;
  }

  public get DeemedIncomeTransportTaxable(): number
  {
    return this._DeemedIncomeTransportTaxable = 0.00;
  }
  public set DeemedIncomeTransportTaxable(x: number)
  {
    this._DeemedIncomeTransportTaxable = x;
  }

  public get EmployerPaidRentalAmount(): number
  {
    return this._EmployerPaidRentalAmount;
  }
  public set EmployerPaidRentalAmount(x: number)
  {
    this._EmployerPaidRentalAmount = x;
  }

  public get DeemedFreeAccommodationExempted(): number
  {
    return this._DeemedFreeAccommodationExempted = this.EmployerPaidRentalAmount;
  }
  public set DeemedFreeAccommodationExempted(x: number)
  {
    this._DeemedFreeAccommodationExempted = x;
  }

  public get FurnishedOrUnfurnishedHouseFromEmployerTaxable(): number
  {
    return this._FurnishedOrUnfurnishedHouseFromEmployerTaxable = 0.00;
  }
  public set FurnishedOrUnfurnishedHouseFromEmployerTaxable(x: number)
  {
    this._FurnishedOrUnfurnishedHouseFromEmployerTaxable = x;
  }

  public get FestivalBonusAmount(): number
  {
    return this._FestivalBonusAmount;
  }
  public set FestivalBonusAmount(x: number)
  {
    this._FestivalBonusAmount = x;
  }

  public get FestivalBonusExempted(): number
  {
    return this._FestivalBonusExempted = 0.00;
  }
  public set FestivalBonusExempted(x: number)
  {
    this._FestivalBonusExempted = x;
  }

  public get FestivalBonusTaxable(): number
  {
    return this._FestivalBonusTaxable = this.FestivalBonusAmount;
  }
  public set FestivalBonusTaxable(x: number)
  {
    this._FestivalBonusTaxable = x;
  }

  public get BengaliNewYearBonusAmount(): number
  {
    return this._BengaliNewYearBonusAmount;
  }
  public set BengaliNewYearBonusAmount(x: number)
  {
    this._BengaliNewYearBonusAmount = x;
  }

  public get BengaliNewYearBonusExempted(): number
  {
    return this._BengaliNewYearBonusExempted = this.BengaliNewYearBonusAmount;
  }
  public set BengaliNewYearBonusExempted(x: number)
  {
    this._BengaliNewYearBonusExempted = x;
  }

  public get BengaliNewYearBonusTaxable(): number
  {
    return this._BengaliNewYearBonusTaxable = 0.00;
  }
  public set BengaliNewYearBonusTaxable(x: number)
  {
    this._BengaliNewYearBonusTaxable = x;
  }

  public get FestivalAllowanceAmount(): number
  {
    return this._FestivalAllowanceAmount;
  }
  public set FestivalAllowanceAmount(x: number)
  {
    this._FestivalAllowanceAmount = x;
  }

  public get FestivalAllowanceExempted(): number
  {
    return this._FestivalAllowanceExempted = 0.00;
  }
  public set FestivalAllowanceExempted(x: number)
  {
    this._FestivalAllowanceExempted = x;
  }

  public get FestivalAllowanceTaxable(): number
  {
    return this._FestivalAllowanceTaxable = this.FestivalAllowanceAmount;
  }
  public set FestivalAllowanceTaxable(x: number)
  {
    this._FestivalAllowanceTaxable = x;
  }

  public get PensionAmount(): number
  {
    return this._PensionAmount;
  }
  public set PensionAmount(x: number)
  {
    this._PensionAmount = x;
  }

  public get PensionExempted(): number
  {
    return this._PensionExempted = this.PensionAmount;
  }
  public set PensionExempted(x: number)
  {
    this._PensionExempted = x;
  }

  public get PensionTaxable(): number
  {
    return this._PensionTaxable = 0.00;
  }
  public set PensionTaxable(x: number)
  {
    this._PensionTaxable = x;
  }

  public get ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount(): number
  {
    return this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount;
  }
  public set ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount(x: number)
  {
    this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount = x;
  }

  public get ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted(): number
  {
    return this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted = this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount;
  }
  public set ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted(x: number)
  {
    this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted = x;
  }

  public get ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable(): number
  {
    return this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable = 0.00;
  }
  public set ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable(x: number)
  {
    this._ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable = x;
  }

  public get OthersAmount(): number
  {
    return this._OthersAmount;
  }
  public set OthersAmount(x: number)
  {
    this._OthersAmount = x;
  }

  public get OthersExempted(): number
  {
    return this._OthersExempted = this.OthersAmount;
  }
  public set OthersExempted(x: number)
  {
    this._OthersExempted = x;
  }

  public get OthersTaxable(): number
  {
    return this._OthersTaxable = 0.00;
  }
  public set OthersTaxable(x: number)
  {
    this._OthersTaxable = x;
  }

  public get ArrearPayAmount(): number
  {
    return this._ArrearPayAmount;
  }
  public set ArrearPayAmount(x: number)
  {
    this._ArrearPayExempted = x;
  }

  public get ArrearPayExempted(): number
  {
    return this._ArrearPayExempted = 0.00;
  }
  public set ArrearPayExempted(x: number)
  {
    this._ArrearPayExempted = x;
  }

  public get ArrearPayTaxable(): number
  {
    return this._ArrearPayTaxable = this.ArrearPayAmount;
  }
  public set ArrearPayTaxable(x: number)
  {
    this._ArrearPayTaxable = x;
  }

  public get TotalAmount(): number
  {
    let ta = Number(this.BasicPayAmount)
      + Number(this.SpecialPayAmount)
      + Number(this.DearnessAllowanceAmount)
      + Number(this.ConveyanceAllowanceAmount)
      + Number(this.HouseRentAllowanceAmount)
      + Number(this.MedicalAllowanceAmount)
      + Number(this.DeemedIncomeTransportAmount)
      + Number(this.AllowanceForSupportStaffAmount)
      + Number(this.LeaveFairAssistanceStaffAmount)
      + Number(this.LeaveEncashmentAmount)
      + Number(this.HonorariumOrRewardAmount)
      + Number(this.OvertimeAllowanceAmount)
      + Number(this.BonusAmount)
      + Number(this.OtherAllowancesAmount)
      + Number(this.EmployersContributionProvidentFundAmount)
      + Number(this.InterestAccruedProvidentFundRateOfInterestAmount)
      + Number(this.FestivalBonusAmount)
      + Number(this.EmployerPaidRentalAmount)
      + Number(this.BengaliNewYearBonusAmount)
      + Number(this.OthersAmount)
      + Number(this.ArrearPayAmount)
      + Number(this.FestivalAllowanceAmount)
      + Number(this.PensionAmount)
      + Number(this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount);


    return this._TotalAmount = ta;
  }
  public set TotalAmount(x: number)
  {
    this._TotalAmount = x;
  }

  public get TotalExempted(): number
  {
    let te = Number(this.BasicPayExempted)
      + Number(this.SpecialPayExempted)
      + Number(this.DearnessAllowanceExempted)
      + Number(this.ConveyanceAllowanceExempted)
      + Number(this.HouseRentAllowanceExempted)
      + Number(this.MedicalAllowanceExempted)
      + Number(this.DeemedIncomeTransportExempted)
      + Number(this.AllowanceForSupportStaffExempted)
      + Number(this.LeaveFairAssistanceStaffExempted)
      + Number(this.LeaveEncashmentExempted)
      + Number(this.HonorariumOrRewardExempted)
      + Number(this.OvertimeAllowanceExempted)
      + Number(this.BonusExempted)
      + Number(this.OtherAllowancesExempted)
      + Number(this.EmployersContributionProvidentFundExempted)
      + Number(this.InterestAccruedProvidentFundRateOfInterestExempted)
      // + Number(this.DeemedIncomeTransportTaxable)
      + Number(this.DeemedFreeAccommodationExempted)
      + Number(this.OthersExempted)
      + Number(this.ArrearPayExempted)
      + Number(this.FestivalBonusExempted)
      + Number(this.BengaliNewYearBonusExempted)
      + Number(this.FestivalAllowanceExempted)
      + Number(this.PensionExempted)
      + Number(this.ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted);

    return this._TotalExempted = te;
  }
  public set TotalExempted(x: number)
  {
    this._TotalExempted = x;
  }

  public get NetTaxable(): number
  {
    let nt = + Number(this.BasicPayAmount)
      + Number(this.HonorariumOrRewardAmount)
      + Number(this.BonusAmount)
      + Number(this.ArrearPayAmount)
      + Number(this.FestivalAllowanceAmount)
      + Number(this.FestivalBonusTaxable);

    return this._NetTaxable = nt;
  }
  public set NetTaxable(x: number)
  {
    this._NetTaxable = x;
  }

  public get GrossAmount(): number
  {
    return this._GrossAmount = this.TotalAmount;
  }
  public set GrossAmount(x: number)
  {
    this._GrossAmount = x;
  }

  public get GrossExempted(): number
  {
    return this._GrossExempted = this.TotalExempted;
  }
  public set GrossExempted(x: number)
  {
    this._GrossExempted = x;
  }

  public get GrossTaxable(): number
  {
    return this._GrossTaxable = this.NetTaxable;
  }
  public set GrossTaxable(x: number)
  {
    this._GrossTaxable = x;
  }
}
