import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DocumentUploadModel } from './Dialog.Model';
import Utils from 'app/util/utils';
import { DocumentsService } from '../Documents.service';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
    selector: 'UploadDocumentDialog',
    templateUrl: 'Dialog.component.html',
    styleUrls: ['./Dialog.component.scss'],
})
export class UploadDocumentDialog
{

    public files: any[] = [];
    FileBase64String: any;
    FileName: any;
    lang = '';
    constructor(
        public dialogRef: MatDialogRef<UploadDocumentDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: DocumentsService,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');

    }

    onFocusoutEvent()
    {
        let Comments = (<HTMLInputElement>document.getElementById('Comments')).value;

        // console.log(Comments, 'Comments');


    }

    OnSubmit(): void
    {
        if (!Utils.isEmptyObject(this.files))
        {
            let obj = {
                "Id": "0",
                "DocumentId": String(this.dialogdata.name),
                "FileName": this.FileName,
                "Comments": (<HTMLInputElement>document.getElementById('Comments')).value,
                "FileBase64String": this.FileBase64String
            };
            // console.log(obj, 'obj');
            // let stringed = JSON.stringify(obj);
            this._formControlService.DOCUMENT_SUBMIT(obj)
                .subscribe((data: any) =>
                {
                    if (data != null)
                    {
                        this._spinner.hide();
                    }

                    if (data.isError === false)
                    {
                        console.log('submit succesfull');
                    }
                    else
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                    }
                    if (data.isError === false && data.responseCode == "200")
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                        this.dialogRef.close("yes");
                    }
                }, (error: any) =>
                {
                    this._spinner.hide();
                    console.log(error, 'error');
                });
        }
        else
        {
            this._matSnackBar.open("Please attach a file!", 'Close', {
                verticalPosition: 'top',
                duration: 2000,
            });
        }
    }

    onNoClick(): void
    {
        this.dialogRef.close("no");
    }

    onFileChange(pFileList: File[])
    {
        this.files = Object.keys(pFileList).map(key => pFileList[key]);

        const file = this.files[0];

        const reader = new FileReader();
        // console.log(file, 'file');
        if (file.size < 10485760)
        {
            reader.readAsDataURL(file);
            reader.onload = () =>
            {
                this.FileBase64String = (<string>reader.result).split(',')[1];
                // console.log(this.FileBase64String);
                this.FileName = file.name;
            };
        } else
        {
            this.files = null;
            this._matSnackBar.open("File Size Cant exceed 10 Megabtyes", 'Close', {
                verticalPosition: 'top',
                duration: 2000,
            });
        }

        // console.log(this.FileBase64String);
        // console.log(this.FileName);
    }

    deleteFile(f)
    {
        this.files = this.files.filter(function (w) { return w.name != f.name });
        this._matSnackBar.open("File deleted!", 'Close', {
            verticalPosition: 'top',
            duration: 2000,
        });
    }

    deleteFromArray(index)
    {
        // console.log(this.files);
        this.files.splice(index, 1);
    }

}
