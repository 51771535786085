import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'list-confirm-dialog',
    templateUrl: './list-dialog.component.html',
    styleUrls: ['./list-dialog.component.scss']
})
export class ListDialogComponent
{
    public confirmMessage: string;
    public confirmTitle: string;
    public confirmButton: string;
    public cancelButton: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ListDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<ListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // console.log(this.dialogdata, 'this.dialogdata');
    }

    onNoClick(): void
    {
        this.dialogRef.close("no");
    }

}


