<h2 mat-dialog-title>Discount</h2>
<mat-dialog-content class="mat-typography"
    *ngIf="BcTabgetData!=null">
    <div fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16">
        <div fxLafxLayout="row wrap"
            fxLayoutAlign="space-between center">
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                Name: <b>{{BcTabClientName}}</b>
            </h3>
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                eTIN: <b>{{BcTabClientEtin}}</b>
            </h3>
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                Mobile: <b>{{BcTabClientMobile}}</b>
            </h3>
        </div>

        <div fxLafxLayout="row wrap"
            fxLayoutAlign="start center">
            <h3 style="margin: 10px;"
                fxFlex="33">
                Reference: <b>{{BcTabClientRef == null || BcTabClientRef == '' ? '--' : BcTabClientRef}}</b>
            </h3>

            <div fxLayout="row"
                fxLayoutAlign="start center"
                style="margin: 10px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Transaction Date:
                </span>

                <mat-form-field appearance="outline">
                    <input matInput
                        [formControl]="BcTabDateControl"
                        [matDatepicker]="BcTabTranDate"
                        readonly
                        [max]="maxDate"
                        (click)="BcTabTranDate.open()">
                    <mat-datepicker-toggle matSuffix
                        [for]="BcTabTranDate"></mat-datepicker-toggle>
                    <mat-datepicker #BcTabTranDate
                        disabled="false"></mat-datepicker>
                </mat-form-field>

            </div>
        </div>

    </div>
    <div *ngIf="TransactionDetailsList!=null"
        fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16">
        <table class="simple invoice-table"
            [formGroup]="BcTabMainForm">
            <thead>
                <tr>
                    <th class="text-left thNewline"
                        style="color: #444444;">
                        SL
                    </th>

                    <th class="text-left thNewline"
                        style="color: #444444; padding-left: 20px;">
                        Account Name
                    </th>
                    <th class="text-right thNewline"
                        style="color: #444444;  padding-right: 20px;">
                        Dues
                    </th>
                    <th class="text-right thNewline"
                        style="color: #444444;">
                        Discount
                    </th>
                    <th class="text-left thNewline"
                        style="color: #444444;">
                        Notes
                    </th>
                </tr>
            </thead>
            <tbody formArrayName="items"
                *ngFor="let item of BcTabMainFormitems().controls; let i=index">
                <tr [formGroupName]="i">
                    <td class="text-center">
                        {{i+1}}
                    </td>
                    <td>

                        <input type="text"
                            formControlName="AccounHeadtName"
                            readonly
                            class="leftA"
                            style="border:0;">
                    </td>
                    <td>
                        <input type="text"
                            formControlName="Dues"
                            readonly
                            class="rightA"
                            style="border:0;">
                    </td>
                    <td>
                        <mat-form-field appearance="outline">
                            <input NumbersOnly
                                type="text"
                                decimals="100"
                                negative="1"
                                formControlName="Remission"
                                (click)="$event.target.select()"
                                (keyup)="BcTabOnFocusoutEvent($event,'Remission',i)"
                                class="rightA"
                                autocomplete="off"
                                matInput>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="outline">
                            <input type="text"
                                decimals="100"
                                negative="1"
                                formControlName="Notes"
                                (click)="$event.target.select()"
                                class="leftA"
                                autocomplete="off"
                                matInput>
                        </mat-form-field>
                    </td>

                </tr>
            </tbody>

        </table>
        <div fxLafxLayout="row wrap"
            fxLayoutAlign="space-between center"
            style="margin-top: 10px; margin-bottom: 10px;">

            <div fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="75">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Remarks:
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <input class="leftA"
                        [formControl]="BcTabRemarksControl"
                        value=""
                        matInput>

                </mat-form-field>

            </div>
            <div style="margin: 10px;"
                fxFlex="25">
                <span>
                    <b> Total Discount: {{BcTabTotalRemission}}</b>
                </span>
            </div>

        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end"
    *ngIf="BcTabgetData!=null">
    <button class="mat-button mat-button-base primary-100 secondary-text"
        cdkFocusInitial
        mat-dialog-close>Cancel</button>
    <button class="mat-button mat-button-base dupl"
        (click)="SaveRemission()">{{ 'COMMON.BUTTON_SAVE' | translate }}</button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
