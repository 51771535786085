import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatRadioModule } from '@angular/material/radio';
import { FuseSharedModule } from '@fuse/shared.module';

import { Layouts } from 'app/enum/layouts.enum';
import { RegisterHomeComponent } from './register-home.component';
import { TranslateModule } from '@ngx-translate/core';

const routes = [
    {
        path: 'register-home',
        component: RegisterHomeComponent,
        data: { layout: Layouts.Home },
    },
    {
        path: 'register-home/:preType',
        component: RegisterHomeComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        RegisterHomeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        TranslateModule,

        MatSnackBarModule,
        MatRadioModule,
        FuseSharedModule
    ]
})
export class RegisterHomeModule
{
}
