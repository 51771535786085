import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';

@Component({
  selector: 'TaxDeductedAtSource-form-control',
  templateUrl: './TaxDeductedAtSource-form-control.component.html',
  styleUrls: ['./TaxDeductedAtSource.component.scss']

})
export class TaxDeductedAtSourceControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;


  ngOnInit(): void
  {

    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }

    let IsIncomeUnder82C = this.form.controls["IsIncomeUnder82C"].value;


    if (IsIncomeUnder82C == "false")
    {
      IsIncomeUnder82C = false
    }
    if (IsIncomeUnder82C == "true")
    {
      IsIncomeUnder82C = true
    }
    if (IsIncomeUnder82C != null)
    {
      this.form.patchValue({
        IsIncomeUnder82C: IsIncomeUnder82C
      });
    }
  }
}
