<div [formGroup]="form">
  <div [ngSwitch]="control.controlType"
    fxLayout="column wrap"
    fxLayoutAlign="start"
    fxFlex="100">
    <div *ngSwitchCase="'checkbox'"
      fxLayoutAlign="start"
      fxLayout="row wrap"
      fxFlex="100"
      style="margin-top: 10px;margin-bottom: 10px;">

      <span class="name"
        style="font-size: 15px;"
        [attr.for]="control.key">{{control.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip={{control.tooltip}}
          matSuffix>info
        </mat-icon></span>

      <mat-checkbox style="margin-left: 5px;"
        [formControlName]="control.key"
        [value]="control.value"></mat-checkbox>
    </div>
    <div class="h2 mb-24"
      style="margin-top:20px;color: #206030;"
      *ngSwitchCase="'label'">{{control.label}}</div>
    <div *ngSwitchCase="'labelGroup'"
      fxLayoutAlign="space-between start"
      fxLayout="wrap"
      fxFlex="100">
      <div fxLayout="wrap"
        fxLayoutAlign="space-between start"
        *ngFor="let child of control.children">

        <mat-label [attr.for]="child.key"
          class="headerLabel"
          style="width: 100%;font-weight: bold;color: #444444;font-size: 16px">{{child.label}}
        </mat-label>

      </div>
    </div>

    <div *ngSwitchCase="'textboxGroup'"
      fxLayout="row wrap"
      fxFlex="100"
      fxLayoutAlign="space-between center">
      <div fxLayout="row"
        fxFlex="100"
        fxLayoutAlign="space-between center"
        style="margin-top: 10px;margin-bottom: 10px;">
        <div fxLayout="column"
          fxLayoutAlign="space-between"
          fxFlex="30">
          <div fxLayout="row">
            <span class="name"
              style="font-size: 15px;"
              [attr.for]="control.key">{{control.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
                matTooltip={{control.tooltip}}
                matSuffix>info
              </mat-icon></span>

          </div>
          <div fxLayout="row"
            style="margin-top: 5px;margin-bottom: 5px;align-items: center;"
            *ngFor="let Source of control.innerContent"
            fxLayoutAlign="start center">
            <span class="name"
              [attr.for]="Source.key">{{Source.label}}
            </span>

            <div *ngIf="Source.controlType=='textbox'"
              style="align-self: center;border: .9px solid;border-radius: 5px;height: 30px;width: 100px;margin-left: 5px;">
              <input *ngSwitchCase="'textboxGroup'"
                style="padding: 5px"
                [formControlName]="Source.key"
                autocomplete="off"
                [id]="Source.key"
                [type]="Source.type"
                matInput
                NumbersOnly
                type="text"
                decimals="100"
                negative="1"
                required={{Source.required}}
                (keyup)="onFocusoutEvent($event,Source.key)"
                (click)="$event.target.select()">
            </div>
          </div>
        </div>
        <div *ngFor="let Source of control.children"
          fxLayout="row"
          fxLayoutAlign="space-between">

          <mat-form-field appearance="outline"
            class="{{Source.readonly==true ? 'readonly' : ''}}"
            fxLayout="row"
            [attr.for]="Source.key"
            fxLayoutAlign="space-between center"
            fxLayout="row">
            <input *ngSwitchCase="'textboxGroup'"
              tabindex={{Source.tabindex}}
              NumbersOnly
              autocomplete="off"
              type="text"
              decimals="100"
              negative="1"
              [formControlName]="Source.key"
              [id]="Source.key"
              [type]="Source.type"
              matInput
              required={{Source.required}}
              (keyup)="onFocusoutEvent($event,Source.key)"
              (click)="$event.target.select()">
            <mat-error>{{Source.message}}</mat-error>
          </mat-form-field>

        </div>
      </div>
    </div>

  </div>

</div>
