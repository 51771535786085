
export class ShareofProfitInaFirmClass
{
  constructor(
    private _PercentOfShare: number,
    private _NetValueOfShare: number,
    private _FirmIncome: number,
  )
  {

  }

  public get PercentOfShare(): number
  {
    return this._PercentOfShare;
  }
  public set PercentOfShare(x: number)
  {
    this._PercentOfShare = x;
  }

  public get NetValueOfShare(): number
  {
    if (this.PercentOfShare != 0 || this.PercentOfShare != 0.00)
    {
      return this._NetValueOfShare = (Number(this.FirmIncome) * Number(this.PercentOfShare) / 100);
    }
    if (this.PercentOfShare == 0)
    {
      return this._NetValueOfShare = Number(this.FirmIncome);
    }
  }
  public set NetValueOfShare(x: number)
  {
    this._NetValueOfShare = x;
  }

  public get FirmIncome(): number
  {
    return this._FirmIncome;
  }
  public set FirmIncome(x: number)
  {
    this._FirmIncome = x;
  }
}
