import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';



import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PersonalInformationService } from './Personal-Information.service';

import { MatRadioChange } from '@angular/material/radio';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { log } from 'console';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from 'app/auth';



@Component({
    selector: 'PersonalInfo',
    templateUrl: './Personal-Information.component.html',
    styleUrls: ['./Personal-Information.component.scss']
})
export class PersonalInfoComponent implements OnInit
{
    @Input() controls: BaseControl<string>[] = [];

    form: FormGroup;
    maritalStatus: string;
    formcontrols$: Observable<BaseControl<string>[]>;
    @Input() rGender: boolean;
    @Input() rMarital: boolean;
    id: any;
    lang = '';
    showCancel: boolean;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    payLoad = '';

    selectedGender: any;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {MatSnackBar} _matSnackBar
     * @param {FormBuilder} _formBuilder
     * 
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formBuilder: FormBuilder,
        private _formControlService: PersonalInformationService,
        private _userInfoService: Service,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _authService: AuthenticationService,
        private eventEmitterService: EventEmitterService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private _route: ActivatedRoute,
        private back: BackButtonService,


    )
    {
        this.meta.addTags([
            { name: 'description', content: 'digiTax Personal & Contact Information' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax Personal & Contact Information' }
        ]);
        this.setTitle('digiTax | Personal & Contact Information');
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.id = this._route.snapshot.paramMap.get('id');
        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));

        if (profileData.incompleteprofile.includes('2'))
        {
            this.showCancel = false;
        }
        else { this.showCancel = true; }
        this._formControlService.getControlJson(this.id).subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            this.controls = data;
            // console.log(this.controls, 'this.controls');

            this.form = this._formControlService.toFormGroup(this.controls);


            // if (this.form.controls.Gender.value != "")
            // {
            //     this.selectedGender = this.form.controls.Gender.value;
            // }
            // console.log(this.form.getRawValue(), 'getRawValue');

            this.selectedGender = this.form.controls.Gender.value;
            // console.log(this.selectedGender, 'selectedGender');

        });
        // this._formControlService.getControls().subscribe((data: any) =>
        // {
        //     this.controls = data;
        //     this.form = this._formControlService.toFormGroup(this.controls);
        // });
        this.form = this._formControlService.toFormGroup(this.controls);
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }




    ngOnInit(): void
    {

    }

    radioChange(event: MatRadioChange)
    {
        this.maritalStatus = event.value;
    }

    ConfirmLeave(url): void
    {
        if (this.form.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this._router.navigate([url]);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            this._router.navigate([url]);
        }
    }

    mySubmitExit(param): void
    {
        if (param == 'next')
        {
            if ((this.selectedGender == this.form.controls.Gender.value) || this.selectedGender == "")
            {
                this.OnSubmit(param);
            }
            else
            {
                this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                    disableClose: false
                });

                this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.PERSONAL.R_GENDER_CHANGE : bangla.data.PERSONAL.R_GENDER_CHANGE;

                this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

                this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

                this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

                this.confirmDialogRef.afterClosed().subscribe(result =>
                {
                    if (result)
                    {
                        this.OnSubmit(param);
                    }
                    this.confirmDialogRef = null;
                });
            }
        }

        if (param == 'exit')
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            if ((this.selectedGender == this.form.controls.Gender.value) || this.selectedGender == "")
            {
                this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;
            }
            else
            {
                this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.PERSONAL.R_GENDER_CHANGE_EXIT : bangla.data.PERSONAL.R_GENDER_CHANGE_EXIT;
            }

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param);
                }
                this.confirmDialogRef = null;
            });
        }
    }

    OnSubmit(param): void
    {
        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }
        if (!this.form.controls.Gender.valid)
        {
            this.rGender = true;
        }
        if (!this.form.controls.MaritalStatus.valid)
        {
            this.rMarital = true;
        }
        this._spinner.show();

        let obj = this.form.getRawValue();

        let date = null;
        if (obj.DateOfBirth != null && obj.DateOfBirth.length != 10)
        {
            if (this.form.value.DateOfBirth != null)
            {
                let date = moment(this.form.value.DateOfBirth).format("DD-MM-YYYY");
                date = obj.DateOfBirth;
            }
        }
        else
        {
            date = obj.DateOfBirth;
        }

        if (obj.hasPermanentAddress == true)
        {
            obj.PermanentPostCode = obj.PresentPostCode;
            obj.PermanentDistrictId = obj.PresentDistrictId;
            obj.PermanentAddress = obj.PresentAddress;
        };

        if (obj.MaritalStatus == "1")
        {
            obj.SpouseName = "";
            obj.SpouseTIN = "";
        };


        if (obj.NID == "0" || isNaN(Number(obj.NID)) == true)
        {
            obj.NID = "";
        }

        if (obj.SpouseTIN == "0" || isNaN(Number(obj.SpouseTIN)) == true)
        {
            obj.SpouseTIN = "";
        }

        if (obj.ContactNo == "0" || isNaN(Number(obj.ContactNo)) == true)
        {
            obj.ContactNo = "";
        }

        let stringed = JSON.stringify(obj);

        // console.log(moment(obj.DateOfBirth).isValid(), 'moment(obj.DateOfBirth).isValid()');


        if (obj.AssesseeName == null || obj.AssesseeName == "")
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_NAME : bangla.data.PERSONAL.R_ENTER_NAME, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',

            });
        }
        else
        {
            if (obj.NID.length < 9 && obj.NID != "")
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_NID_L : bangla.data.PERSONAL.R_ENTER_NID_L, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (moment(obj.DateOfBirth).isValid() === false)
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_DOB : bangla.data.PERSONAL.R_DOB, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }

                else
                {
                    if (obj.Gender == null || obj.Gender == "")
                    {
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_SELECT_GENDER : bangla.data.PERSONAL.R_SELECT_GENDER, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                    else
                    {

                        if (obj.MaritalStatus == null || obj.MaritalStatus == "")
                        {
                            this._spinner.hide();
                            this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_MARITAL_STATUS : bangla.data.PERSONAL.R_MARITAL_STATUS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                        else
                        {
                            // if (obj.MaritalStatus == "2" && obj.SpouseName == "")
                            // {
                            //     this._spinner.hide();
                            //     this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_SPOUSE_NAME : bangla.data.PERSONAL.R_SPOUSE_NAME, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            //         verticalPosition: 'top',
                            //         duration: 2000
                            //     });
                            // }
                            // else
                            // {
                            if (obj.ContactNo == null || obj.ContactNo == "" || obj.ContactNo.length < 11)
                            {
                                this._spinner.hide();
                                this.form.get("ContactNo").setErrors({ 'incorrect': true });
                                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_MOBILE : bangla.data.PERSONAL.R_MOBILE, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                if (obj.PresentAddress == null || obj.PresentAddress == "")
                                {
                                    this._spinner.hide();
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_PRESENT_ADDRESS : bangla.data.PERSONAL.R_PRESENT_ADDRESS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                }
                                else
                                {
                                    if (obj.PresentDistrictId == null || obj.PresentDistrictId == "")
                                    {
                                        this._spinner.hide();
                                        this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_PRESENT_DISTRICT : bangla.data.PERSONAL.R_PRESENT_DISTRICT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                    }
                                    else
                                    {
                                        if (obj.PresentPostCode == null || obj.PresentPostCode == "")
                                        {
                                            this._spinner.hide();
                                            this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_PRESENT_POSTAL : bangla.data.PERSONAL.R_PRESENT_POSTAL, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                verticalPosition: 'top',
                                                duration: 2000
                                            });
                                        }
                                        else
                                        {
                                            if (obj.PermanentAddress == null || obj.PermanentAddress == "")
                                            {
                                                this._spinner.hide();
                                                this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_PERMANENT_ADDRESS : bangla.data.PERSONAL.R_PERMANENT_ADDRESS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                    verticalPosition: 'top',
                                                    duration: 2000
                                                });
                                            }
                                            else
                                            {
                                                if (obj.PermanentDistrictId == null || obj.PermanentDistrictId == "")
                                                {
                                                    this._spinner.hide();
                                                    this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_SELECT_DISTRICT : bangla.data.PERSONAL.R_SELECT_DISTRICT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                        verticalPosition: 'top',

                                                    });
                                                }
                                                else
                                                {
                                                    if (obj.PermanentPostCode == null || obj.PermanentPostCode == "")
                                                    {
                                                        this._spinner.hide();
                                                        this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_POSTAL : bangla.data.PERSONAL.R_ENTER_POSTAL, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                            verticalPosition: 'top',

                                                        });
                                                    }
                                                    else
                                                    {
                                                        if (stringed != null)
                                                        {
                                                            this._formControlService.submitPersonalInfoJson(stringed)
                                                                .subscribe((data: any) =>
                                                                {
                                                                    if (data.isError === true)
                                                                    {
                                                                        this._spinner.hide();
                                                                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                            verticalPosition: 'top',
                                                                            duration: 5000
                                                                        });

                                                                    }
                                                                    if (data.isError === false && data.responseCode == "200")
                                                                    {
                                                                        if (this.id != null)
                                                                        {
                                                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                                verticalPosition: 'top',
                                                                                duration: 5000
                                                                            });
                                                                        }
                                                                        else
                                                                        {
                                                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                                verticalPosition: 'top',
                                                                                duration: 5000
                                                                            });
                                                                        }

                                                                        if (param == 'next')
                                                                        {
                                                                            this._router.navigate(['/PersonalizeReturn']);
                                                                        }
                                                                        if (param == 'exit')
                                                                        {
                                                                            this._authService.Logout();
                                                                        }
                                                                    }
                                                                }, (error: any) =>
                                                                {
                                                                    console.log(error, 'error');
                                                                });
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // }


    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

