import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { AdjustmentofTaxRefundListService } from './Adjustment-of-Tax-Refund-list.service';
import { AdjustmentofTaxRefundListComponent } from './Adjustment-of-Tax-Refund-list.component';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ViewDashboardGuard } from 'app/auth/viewDashboard.guard';





const routes = [
    {
        path: 'AdjustmentofTaxRefund',
        component: AdjustmentofTaxRefundListComponent,
        canActivate: [AuthGuard, ViewDashboardGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        AdjustmentofTaxRefundListComponent,
    
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatFormFieldModule,

        MatInputModule,

        MatSnackBarModule,

        MatSlideToggleModule,

        MatSelectModule,

        MatTooltipModule,

        MatCheckboxModule,

        MatRadioModule,

        MatTableModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,
    ],
    exports: [
        AdjustmentofTaxRefundListComponent
    ],
    providers: [

        AdjustmentofTaxRefundListService
    ],
})

export class AdjustmentofTaxRefundListModule
{
}
