import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseControl } from "app/models/dynamic-form";
import { of, Observable } from "rxjs";
import Utils from "app/util/utils";
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class AgricultureService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }
  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    if (id != null)
    {
      return this.service.get(urlConfig.INCOME_Agricultural_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
      return this.service.get(urlConfig.INCOME_Agricultural_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }

  }

  submitAgricultural(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Agricultural_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Agricultural_Submit_Url + `/${id}`, model);
    }
  }

  getJson(): any
  {


    const jsonData = [
      {
        "control": "TextboxControl",
        "key": "LandQuantity",
        "label": "Area of Land (in Bigha)",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the area of land.",
        "order": 1
      },
      {
        "control": "DropdownControl",
        "key": "CropsTypes",
        "label": "Crop Type",
        "required": false,
        "errorType": "",
        "message": "Required",
        "tooltip": "Crop type that grows in the taxpayer's land",
        "options": [
          {
            "key": "1",
            "value": "Rice"
          },
          {
            "key": "2",
            "value": "Vegetable"
          },
          {
            "key": "3",
            "value": "Wheat"
          },
          {
            "key": "4",
            "value": "Potato"
          }
        ],
        "order": 2
      },
      {
        "control": "TextboxControl",
        "key": "GrossRevenue",
        "label": "Total Revenue",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the amount of total annual revenue earned by the taxpayer.",
        "order": 3
      },
      {
        "control": "RadioButtonControl",
        "key": "IsAccountsRecordMaintened",
        "label": "Do You Have Any Books of Account?",
        "required": false,
        "options": [
          {
            "key": "1",
            "value": "Yes"
          },
          {
            "key": "2",
            "value": "No"
          }
        ],
        "errorType": "",
        "message": "Required",
        "tooltip": "If taxpayer do not have account book then flat 60% of the revenue will be exempted and if do have account book then the production cost will be minus from the revenue",
        "order": 4
      },
      {
        "control": "TextboxControl",
        "key": "ProductionCost",
        "label": "Production Cost",
        "required": true,
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers to the amount of total annual production cost of the taxpayer.",
        "order": 5
      },
      {
        "control": "TextboxControl",
        "key": "NetIncome",
        "label": "Net Income",
        "required": false,
        "readonly": true,
        "tabindex": "-1",
        "errorType": "",
        "message": "Required",
        "tooltip": "This refers the total taxable income from agriculture",
        "order": 6
      }
    ];


    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
