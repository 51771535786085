import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { bangla, english } from 'app/util/locale';
import Utils from 'app/util/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentService } from './Payment.service';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { ProfileListService } from '../Profile/Profile-List/ProfileList.service';
import { ChalanDialog } from './chalan-dialog/chalan-Dialog.component';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'Payment',
    templateUrl: './Payment.component.html',
    styleUrls: ['./Payment.component.scss']
})
export class PaymentComponent
{
    userIND = false;
    userPRAC = false;
    showTaxAmountDiv: boolean;
    lang = '';
    getData: any;

    hasRead: boolean;
    showTerm: boolean;

    currentDate = new Date();

    StarterPlanCouponControl = new FormControl();
    StandardPlanCouponControl = new FormControl();
    PremiumPlanCouponControl = new FormControl();

    PractitionerCouponControl = new FormControl('');


    nCount: number = 5;
    // countMin: number = 5;
    // countMax: number = 10000000;

    nCountTier1: number = 5;
    nCountTier2: number = 0;
    nCountTier3: number = 0;
    nCountTier4: number = 0;

    nPlanPrice1 = 0;
    nPlanPrice2 = 0;
    nPlanPrice3 = 0;
    nPlanPrice4 = 0;

    nPriceTier2: number = 0;
    nPriceTier3: number = 0;
    nPriceTier4: number = 0;
    totalPrice: number = 0;

    PractitionerTotalPrice: number = 0;
    PractitionerNewTotalPrice: number = 0;
    PractitionerCouponDiscount: number = 0

    NoOfClientFiles = 0;

    errMessage = '';

    showDetail = false;

    IsIncludeTaxAmount = false;


    hasTransaction: boolean;
    lastTransactionData: any;
    TransactionData: any;
    TotalTransactions: number = 0;
    TranDate: any;

    hasAppliedVouchers: boolean;
    AppliedVouchersData: any;
    TotalAppliedVouchers: number = 0;



    CurrentPlan: any;

    CurrentPlanName = "";

    // CurrentPlan = 0;

    // CurrentPlanName = "free";
    // CurrentPlanName = "starter";
    // CurrentPlanName = "standard";
    // CurrentPlanName = "premium";

    AllowDownload: boolean;

    IsUpgraded: boolean;

    hasPlan: boolean;
    FreePlan: any;
    StarterPlan: any;
    StarterPlanNewPrice: any;
    StandardPlan: any;
    StandardPlanNewPrice: any;
    PremiumPlan: any;
    PremiumPlanNewPrice: any;
    PremiumPlanSum: any;

    IsError: any;
    ErrorMessage: any;
    OrderId: any;
    PaymentStatus: any;


    dDate: any;

    user: any;


    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: PaymentService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private http: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
        private _profileControlService: ProfileListService,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,
        private sanitizer: DomSanitizer,
        public dialog: MatDialog,

    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Payment & Download" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Payment & Download" }
        ]);
        this.setTitle("digiTax | Payment & Download");
        this.showTaxAmountDiv = false;
        this.hasRead = false;
        this.showTerm = false;
        this.dDate = moment(new Date()).format("DD-MM-YYYY hh:mm A");
        // console.log(this.dDate, 'dDate');

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

        this._spinner.show();
        this.user = Utils.getUserInfo();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        // console.log(this.user, 'this.user');


        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));

        const HasIsError = this._route.snapshot.queryParamMap.has('IsError');
        const HasErrorMessage = this._route.snapshot.queryParamMap.has('ErrorMessage');
        const HasOrderId = this._route.snapshot.queryParamMap.has('OrderId');
        const HasPaymentStatus = this._route.snapshot.queryParamMap.has('PaymentStatus');

        if (HasIsError)
        {
            this.IsError = this._route.snapshot.queryParamMap.get('IsError');
        }
        if (HasErrorMessage)
        {
            this.ErrorMessage = this._route.snapshot.queryParamMap.get('ErrorMessage');
        }
        if (HasOrderId)
        {
            this.OrderId = this._route.snapshot.queryParamMap.get('OrderId');
        }
        if (HasPaymentStatus)
        {
            this.PaymentStatus = this._route.snapshot.queryParamMap.get('PaymentStatus');
        }

        if (this.user.ActiveRoleId == "8")
        {
            this.userIND = true;
            if (profileData.iscomplete == false)
            {
                let incompletefields = null;

                if (profileData.incompletefields[0] != null)
                {
                    incompletefields = profileData.incompletefields[0].fields;
                }

                let incompleteprofile = null;
                if (profileData.incompleteprofile[0] != null)
                {
                    incompleteprofile = profileData.incompleteprofile[0];
                }

                if (incompleteprofile == "1")
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ETIN_OTHER : bangla.data.ETIN.R_ETIN_OTHER, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top'
                    });
                    this._router.navigate(['/EtinOthers/save']);
                }

                if (incompleteprofile == "2")
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_PERSONAL_INFO : bangla.data.PERSONAL.R_ENTER_PERSONAL_INFO, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top'
                    });
                    this._router.navigate(['/PersonalInfo/save']);
                }

                if (incompleteprofile == "3")
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONALIZE.R_PER_RETURN : bangla.data.PERSONALIZE.R_PER_RETURN, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top'
                    });
                    this._router.navigate(['/PersonalizeReturn']);
                }

            }
            else
            {
                this.getPageDataIND();
            }
        }

        if (this.user.ActiveRoleId == "7")
        {
            this.userPRAC = true;
            if (this.lang == 'en')
            {
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
                this._fuseNavigationService.setCurrentNavigation('main');
            }
            if (this.lang == 'bn')
            {
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
                this._fuseNavigationService.setCurrentNavigation('main');
            }
            this.checkProfileData();
        }
    }

    checkProfileData(): void
    {
        this._profileControlService.getControlJson().subscribe((data: any) =>
        {
            if (data != null)
            {
                if (Utils.isEmptyObject(data.data) || data.data == null)
                {
                    this._spinner.hide();
                    this._router.navigate(['/Profile/save']);
                    this._matSnackBar.open(this.lang == 'en' ? english.data.PROFILE.ENTER_Profile : bangla.data.PROFILE.ENTER_Profile, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    this.getPageDataPRAC();
                }
            }


        });
    }

    getPageDataIND(): void
    {
        // let stringed = null;
        this._formControlService.getControlJson()
            .subscribe((data: any) =>
            {
                // console.log(data, 'getPageDataIND');
                if (data != null)
                {
                    this._spinner.hide();
                }
                if (data.isError === false)
                {
                    this.getData = data.data;
                    // console.log(this.getData, 'this.getData');


                    // this.CurrentPlan = 2;
                    // this.CurrentPlanName = "Starter";

                    this.CurrentPlan = data.data.CurrentPlan;
                    this.CurrentPlanName = data.data.CurrentPlanName;

                    this.AllowDownload = data.data.AllowDownload;
                    this.IsUpgraded = data.data.IsUpgraded;


                    if (Utils.isEmptyObject(data.data.Transactions))
                    {
                        this.hasTransaction = false;
                    }
                    else
                    {
                        this.hasTransaction = true;
                        this.lastTransactionData = data.data.Transactions.at(-1);
                        this.TranDate = moment(this.lastTransactionData.TranDate).format("DD-MM-YYYY, hh:mm A");
                        this.TransactionData = data.data.Transactions;
                        this.TotalTransactions = this.TransactionData.reduce((n, { TranAmount }) => n + Number(TranAmount), 0);
                        // console.log(this.TotalTransactions, 'TotalTransactions');
                    }

                    if (Utils.isEmptyObject(data.data.AppliedVouchers) || data.data.AppliedVouchers == null)
                    {
                        this.hasAppliedVouchers = false;
                    }
                    else
                    {
                        this.hasAppliedVouchers = true;
                        this.AppliedVouchersData = data.data.AppliedVouchers;
                        this.TotalAppliedVouchers = this.AppliedVouchersData.reduce((n, { AppliedAmount }) => n + Number(AppliedAmount), 0);
                        // console.log(this.TotalAppliedVouchers, 'TotalAppliedVouchers');

                    }

                    if (data.data.Plans != null)
                    {
                        // console.log(data.data.Plans, 'data.data.Plans');

                        this.hasPlan = true;
                        this.FreePlan = data.data.Plans[0];
                        this.StarterPlan = data.data.Plans[1];
                        this.StandardPlan = data.data.Plans[2];
                        // this.PremiumPlan = data.data.Plans[3];
                        // this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;

                        if (data.data.Plans.length == 4)
                        {
                            this.PremiumPlan = data.data.Plans[3];
                            this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;
                        }
                    }
                }
                else
                {
                    console.log('error');
                    this._spinner.hide();
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            });
    }

    getPageDataPRAC(): void
    {
        // let stringed = null;
        this._formControlService.getControlJson()
            .subscribe((data: any) =>
            {
                // console.log(data, 'data');
                if (data != null)
                {
                    this._spinner.hide();
                }
                if (data.isError === false)
                {
                    this.getData = data.data;
                    // debugger;
                    // this.CurrentPlan = data.data.CurrentPlan;
                    // this.CurrentPlanName = data.data.CurrentPlanName;
                    // this.AllowDownload = data.data.AllowDownload;
                    // this.IsUpgraded = data.data.IsUpgraded;
                    // debugger;
                    if (data.data.TotalPurchasedFiles == null || data.data.TotalPurchasedFiles == 0 || data.data.TotalPurchasedFiles == '0')
                    {
                        this.NoOfClientFiles = 0;
                        this.nCount = 5;
                        // this.nCount = 50;

                    } else
                    {
                        this.NoOfClientFiles = data.data.TotalPurchasedFiles;
                        this.nCount = 5 + data.data.TotalPurchasedFiles;
                        // this.nCount = 50;
                    }





                    if (Utils.isEmptyObject(data.data.Transactions))
                    {
                        this.hasTransaction = false;
                    }
                    else
                    {
                        this.hasTransaction = true;
                        this.lastTransactionData = data.data.Transactions.at(-1);
                        this.TranDate = moment(this.lastTransactionData.TranDate).format("DD-MM-YYYY, hh:mm A");
                        this.TransactionData = data.data.Transactions;
                        this.TotalTransactions = this.TransactionData.reduce((n, { TranAmount }) => n + Number(TranAmount), 0);
                        // console.log(this.TotalTransactions, 'TotalTransactions');

                    }


                    if (Utils.isEmptyObject(data.data.AppliedVouchers) || data.data.AppliedVouchers == null)
                    {
                        this.hasAppliedVouchers = false;
                    }
                    else
                    {
                        this.hasAppliedVouchers = true;
                        this.AppliedVouchersData = data.data.AppliedVouchers;
                        this.TotalAppliedVouchers = this.AppliedVouchersData.reduce((n, { AppliedAmount }) => n + Number(AppliedAmount), 0);
                        // console.log(this.TotalAppliedVouchers, 'TotalAppliedVouchers');

                    }

                    if (data.data.Plans != null)
                    {
                        // this.hasPlan = true;
                        // this.FreePlan = data.data.Plans[0];
                        // this.StarterPlan = data.data.Plans[1];
                        // this.StandardPlan = data.data.Plans[2];
                        // this.PremiumPlan = data.data.Plans[3];
                        // this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;

                        this.nCountTier1 = data.data.Plans[0].NoOfClientFiles;
                        this.nCountTier2 = data.data.Plans[1].NoOfClientFiles;
                        this.nCountTier3 = data.data.Plans[2].NoOfClientFiles;
                        this.nCountTier4 = data.data.Plans[3].NoOfClientFiles;


                        this.nPlanPrice1 = parseInt(data.data.Plans[0].PlanPrice);
                        this.nPlanPrice2 = parseInt(data.data.Plans[1].PlanPrice);
                        this.nPlanPrice3 = parseInt(data.data.Plans[2].PlanPrice);
                        this.nPlanPrice4 = parseInt(data.data.Plans[3].PlanPrice);
                    }
                }
                else
                {
                    console.log('error');
                    this._spinner.hide();
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            });
    }

    OnSubmitIND(plan): void
    {
        if (this.hasRead == false)
        {
            this._matSnackBar.open("Please confirm that you are aware of our Terms & Conditions", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            this._spinner.show();

            let IsInclude = false;

            let code = null;
            let newPrice = null;

            if (plan.Id == 4)
            {
                IsInclude = this.IsIncludeTaxAmount;
                code = this.PremiumPlanCouponControl.value;
                newPrice = this.PremiumPlanNewPrice;
            }
            else
            {
                IsInclude = false;
            }

            if (plan.Id == 2)
            {
                code = this.StarterPlanCouponControl.value;
                newPrice = this.StarterPlanNewPrice;
            }

            if (plan.Id == 3)
            {
                code = this.StandardPlanCouponControl.value;
                newPrice = this.StandardPlanNewPrice;
            }


            let stringed = {
                // "PayeeReturnId": 10,
                "PlanPrice": plan.PlanPrice,
                "SelectedPlanId": plan.Id,
                // "PlanPrice": plan.UpgradePlanPrice - this.TotalAppliedVouchers,
                "TaxAmount": plan.TaxAmount,
                "BankCharge": plan.BankCharge,
                "IsIncludeTaxAmount": IsInclude,
                "VoucherCode": code,
                "NewPrice": newPrice,
                "TotalPaidAmount": this.TotalTransactions
            };

            this._formControlService.TAX_PAYMENT_INITIATEPAYMENT_Url(stringed)
                .subscribe((data: any) =>
                {
                    if (data != null)
                    {
                        this._spinner.hide();
                    }

                    if (data.isError === false)
                    {
                        if (data.data.status == "SUCCESS")
                        {
                            window.location.href = data.data.GatewayPageURL;
                            // window.open(data.data.GatewayPageURL, "_blank");
                        }
                        else
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                    }
                    else
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }

                }, (error: any) =>
                {
                    console.log(error, 'error');
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                });
        }
    }

    downloafPDF2(): any
    {
        this._formControlService.getIsAssetFilled()
            .subscribe((data: any) =>
            {
                // console.log(data, 'data');

                if (data.isError === false)
                {

                    if (data.data.IsFilledUpAsset == true)
                    {
                        this.downloadPDF();
                    }
                    else
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.ENTER_ASSET : bangla.data.COMMON.ENTER_ASSET, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                }
                else
                {
                    console.log('error');
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this._spinner.hide();
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this._spinner.hide();
            });
    }

    test(): any
    {
        let etinV: HTMLElement = document.getElementById("etinV") as HTMLElement;

        let fyearV: HTMLElement = document.getElementById("fyearV") as HTMLElement;

        let latest_date = moment(this.currentDate).format("DD-MM-YYYY, hh:mm A")

        let fileName = `${etinV.innerHTML}_${fyearV.innerHTML}_${latest_date}`;

        // console.log(fileName, 'fileName');


    }

    downloadPDF(): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.REPORT_DL, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    // const fileURL = window.URL.createObjectURL(res);

                    // var a = document.createElement("a");
                    // document.body.appendChild(a);
                    // a.setAttribute("id", "downloadPDF_t");
                    // a.style.display = "none";
                    // a.href = fileURL;
                    // a.target = "_blank"
                    // a.click();
                    // window.URL.revokeObjectURL(fileURL);
                    // a.remove();

                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }

                    // var el = document.getElementById('downloadPDF_t');
                    // el.parentNode.removeChild(el);

                    // window.open(fileURL, '_blank');
                    // URL.revokeObjectURL(fileURL);
                }
                else
                {
                    let etinV: HTMLElement = document.getElementById("etinV") as HTMLElement;

                    // let fyearV: HTMLElement = document.getElementById("fyearV") as HTMLElement;

                    let latest_date = moment(this.currentDate).format("DD-MM-YYYY, hh:mm A")

                    // let fileName = `${etinV.innerHTML}_${fyearV.innerHTML}_${latest_date}`;
                    let fileName = `${etinV.innerHTML}_${latest_date}`;

                    saveAs(res, `${fileName}`);
                }
                this._spinner.hide();

                // const fileURL = URL.createObjectURL(res);


                // window.open(fileURL, '_blank');
            },
            error =>
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                console.log(error, 'error');

            })
    }

    summaryPDF(): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.SUMMARY_DL, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf')
                {
                    // const fileURL = URL.createObjectURL(res);
                    // var a = document.createElement("a");
                    // document.body.appendChild(a);
                    // a.setAttribute("id", "downloadPDF_t");
                    // a.style.display = "none";
                    // a.href = fileURL;
                    // a.target = "_blank"
                    // a.click();
                    // window.URL.revokeObjectURL(fileURL);
                    // a.remove();
                    // window.open(fileURL, '_blank');

                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, 'Summary File');
                }
                this._spinner.hide();
                // const fileURL = URL.createObjectURL(res);


                // window.open(fileURL, '_blank');
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');

            })
    }

    OnSubmit(): void
    {
        if (this.hasRead == false)
        {
            this._matSnackBar.open("Please confirm that you are aware of our Terms & Conditions", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (this.nCount == this.nCountTier1)
            {
                this._matSnackBar.open("You are on free plan, Please enter how many more client files you want to create.", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                this._spinner.show();

                let stringed = {
                    "NoOfClientFiles": this.nCount - this.nCountTier1 - this.NoOfClientFiles,
                    "VoucherCode": this.PractitionerCouponControl.value,
                    "PlanPrice": this.PractitionerTotalPrice,
                    "TotalAppliedDiscountAmount": this.TotalAppliedVouchers,
                };
                // console.log(stringed, 'stringed');
                // debugger;

                this._formControlService.TAX_PAYMENT_INITIATEPAYMENT_Url(stringed)
                    .subscribe((data: any) =>
                    {
                        // debugger;
                        if (data != null)
                        {
                            this._spinner.hide();
                        }

                        if (data.isError === false)
                        {
                            if (data.data.status == "SUCCESS")
                            {
                                // debugger;
                                // this.pform.reset();
                                (document.getElementById("inputCount") as HTMLInputElement).value = "";
                                // this.pform.patchValue({
                                //     inputCount: ""
                                // });
                                window.location.href = data.data.GatewayPageURL;
                                // window.open(data.data.GatewayPageURL, "_blank");
                            }
                            else
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                        }
                        else
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }

                    }, (error: any) =>
                    {
                        console.log(error, 'error');
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    });

            }
        }

    }

    nCountPlus(): void
    {
        // if (this.nCount >= this.countMin)
        // {
        this.nCount += 1;
        this.TierCount();
        // }
    }

    nCountMinus(): void
    {
        // if (this.nCount != this.countMin && this.nCount != 0)
        if (this.nCount != 0 && this.nCount != 5)
        {
            this.nCount -= 1;
            this.TierCount();
        }
        else
        {
            this.showErrorMessage();
        }
    }

    ShowDetails(): void
    {
        this.showDetail = !this.showDetail;
    }

    TierCount(): void
    {
        // debugger;
        this.nCountTier2 = this.nCount - this.nCountTier1;
        if (this.nCountTier2 > 45)
        {
            this.nCountTier2 = 45;
        }
        // else this.nCountTier2 = 0;
        if (this.nCount > 50)
        {
            this.nCountTier3 = this.nCount - 50;
            if (this.nCountTier3 > 50)
            {
                this.nCountTier3 = 50;
            }
        }
        if (this.nCount < 50)
        {
            this.nCountTier3 = 0;
        }
        if (this.nCount > 100)
        {
            this.nCountTier4 = this.nCount - 100;
        }
        else this.nCountTier4 = 0;

        if (this.nCount != 0)
        {
            this.nPriceTier2 = this.nCountTier2 * 250;
            this.nPriceTier3 = this.nCountTier3 * 200;
            this.nPriceTier4 = this.nCountTier4 * 150;
            this.PractitionerTotalPrice = this.nPriceTier2 + this.nPriceTier3 + this.nPriceTier4 - this.TotalTransactions - this.TotalAppliedVouchers;
        }

    }

    onFocusoutEvent(event)
    {
        let inputCount: HTMLInputElement = document.getElementById("inputCount") as HTMLInputElement;
        // console.log(inputCount.value, 'inputCount');
        let n = Number(inputCount.value);
        // console.log(isNaN(n), 'inputCount');


        // if (Number(inputCount.value) < this.countMin)
        // if (Number(inputCount.value))
        // {
        //     // inputCount.value = this.countMin.toString();
        //     this.nCount = Number(inputCount.value);
        //     this.showErrorMessage();
        // }
        // else
        // {
        //     this.nCount = Number(inputCount.value);
        //     this.TierCount();
        // }

        // debugger;
        if (n == 0)
        {
            // this.nCount = 5;
            this.nCount = 5 + this.NoOfClientFiles;
            this.showErrorMessage();
        }
        else
        {
            if (this.NoOfClientFiles == 0)
            {
                this.nCount = Number(inputCount.value) + 5;
                this.TierCount();
            }
            else
            {
                this.nCount = Number(inputCount.value) + 5 + this.NoOfClientFiles;
                this.TierCount();
            }

        }


    }

    showErrorMessage(): void
    {
        this.errMessage = `Please enter more than 0`;
        this._matSnackBar.open(this.errMessage, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
            verticalPosition: 'top',
            duration: 2000
        });
        this.PractitionerTotalPrice = 0;
        this.PractitionerNewTotalPrice = 0;
        // this.RemovePractitionerCoupon();
        this.nPriceTier2 = 0;
        this.nPriceTier3 = 0;
        this.nPriceTier4 = 0;

        this.nCountTier2 = 0;
        this.nCountTier3 = 0;
        this.nCountTier4 = 0;
    }

    showOptions(event, key)
    {
        if (key == "add")
        {
            let TaxAmountDiv: HTMLElement = document.getElementById("TaxAmountDiv") as HTMLElement;

            if (event.checked == true)
            {
                if (TaxAmountDiv.classList.contains('hidden'))
                {
                    TaxAmountDiv.classList.remove('hidden');
                }
                if (!TaxAmountDiv.classList.contains('block'))
                {
                    TaxAmountDiv.classList.add('block');
                }
                this.IsIncludeTaxAmount = true;
                // console.log(this.IsIncludeTaxAmount, 'this.IsIncludeTaxAmount');
            }
            if (event.checked == false)
            {
                if (TaxAmountDiv.classList.contains('block'))
                {
                    TaxAmountDiv.classList.remove('block');
                }
                if (!TaxAmountDiv.classList.contains('hidden'))
                {
                    TaxAmountDiv.classList.add('hidden');
                }
                this.IsIncludeTaxAmount = false;
                // console.log(this.IsIncludeTaxAmount, 'this.IsIncludeTaxAmount');
            }
        }
        if (key == "term")
        {
            if (event.checked == true)
            {
                this.hasRead = true;
            }
            if (event.checked == false)
            {
                this.hasRead = false;
            }
        }
    }

    ShowTerm()
    {
        this.showTerm = true;
    }

    openChalanDialog(): void
    {
        const dialogRef = this.dialog.open(ChalanDialog, {
            disableClose: true,
            data: {
                AssesseeId: this.user.AssesseeId,
                ReturnId: this.user.ReturnId,
            }
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            console.log(`Dialog result: ${result}`);
        });
    }

    RemovePremiumPlanCoupon(): void
    {
        this.PremiumPlanNewPrice = null;
        this.PremiumPlanCouponControl.setValue('');

        this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;
    }

    RemoveStandardPlanCoupon(): void
    {
        this.StandardPlanNewPrice = null;
        this.StandardPlanCouponControl.setValue('');
    }

    RemoveStarterPlanCoupon(): void
    {
        this.StarterPlanNewPrice = null;
        this.StarterPlanCouponControl.setValue('');
    }

    RemovePractitionerCoupon(): void
    {
        this.PractitionerNewTotalPrice = 0;
        this.PractitionerCouponDiscount = 0;
        this.PractitionerCouponControl.setValue('');
    }

    ApplyPremiumPlanCouponControl(Plan): void
    {
        if (this.PremiumPlanCouponControl.value == '' || this.PremiumPlanCouponControl.value == null)
        {
            this._matSnackBar.open("Please Enter a Coupon Code", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top'
            });
        }
        else
        {
            this._spinner.show();
            let body = {
                "VoucherCode": this.PremiumPlanCouponControl.value,
                "Plan": Plan.UpgradePlanPrice - this.TotalAppliedVouchers,
                "PlanPrice": Plan.PlanPrice,
                "TotalAppliedDiscountAmount": this.TotalAppliedVouchers,
                "SelectedPlanId": Plan.Id,
                "TotalPaidAmount": this.TotalTransactions
            }
            // console.log(body, 'body');
            this._formControlService.VoucherCodeApply(body).subscribe((data: any) =>
            {
                // console.log(data, 'data');

                if (data.isError === false)
                {
                    // this.PremiumPlanNewPrice = data.data.NewPrice;

                    if (data.data.NewPrice != 0)
                    {
                        this.PremiumPlanNewPrice = data.data.NewPrice;
                        this.PremiumPlanSum = this.PremiumPlanNewPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;
                    }
                    else
                    {
                        this._matSnackBar.open("This coupon is not applicable for this plan", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }



                    this.StarterPlanNewPrice = null;
                    this.StarterPlanCouponControl.setValue('');
                    this.StandardPlanNewPrice = null;
                    this.StandardPlanCouponControl.setValue('');
                    this._spinner.hide();
                }
                else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this._spinner.hide();
                }
            })
        }
    }

    ApplyStandardPlanCouponControl(Plan): void
    {
        if (this.StandardPlanCouponControl.value == '' || this.StandardPlanCouponControl.value == null)
        {
            this._matSnackBar.open("Please Enter a Coupon Code", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top'
            });
        }
        else
        {
            this._spinner.show();
            let body = {
                "VoucherCode": this.StandardPlanCouponControl.value,
                "Plan": Plan.UpgradePlanPrice - this.TotalAppliedVouchers,
                "PlanPrice": Plan.PlanPrice,
                "TotalAppliedDiscountAmount": this.TotalAppliedVouchers,
                "SelectedPlanId": Plan.Id,
                "TotalPaidAmount": this.TotalTransactions
            }
            // console.log(body, 'body');
            this._formControlService.VoucherCodeApply(body).subscribe((data: any) =>
            {
                // console.log(data, 'data');
                if (data.isError === false)
                {
                    // this.StandardPlanNewPrice = data.data.NewPrice;
                    if (data.data.NewPrice != 0)
                    {
                        this.StandardPlanNewPrice = data.data.NewPrice;
                    }
                    else
                    {
                        this._matSnackBar.open("This coupon is not applicable for this plan", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }

                    this.StarterPlanNewPrice = null;
                    this.StarterPlanCouponControl.setValue('');
                    this.PremiumPlanNewPrice = null;
                    this.PremiumPlanCouponControl.setValue('');
                    this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;

                    this._spinner.hide();
                }
                else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this._spinner.hide();
                }
            })
        }
    }

    ApplyStarterPlanCouponControl(Plan): void
    {
        if (this.StarterPlanCouponControl.value == '' || this.StarterPlanCouponControl.value == null)
        {
            this._matSnackBar.open("Please Enter a Coupon Code", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top'
            });
        }
        else
        {
            this._spinner.show();
            let body = {
                "VoucherCode": this.StarterPlanCouponControl.value,
                "Plan": Plan.UpgradePlanPrice,
                "SelectedPlanId": Plan.Id,
                "PlanPrice": Plan.PlanPrice,
            }
            // console.log(body, 'body');

            this._formControlService.VoucherCodeApply(body).subscribe((data: any) =>
            {
                // console.log(data, 'data');
                if (data.isError === false)
                {
                    if (data.data.NewPrice != 0)
                    {
                        this.StarterPlanNewPrice = data.data.NewPrice;
                    }
                    else
                    {
                        this._matSnackBar.open("This coupon is not applicable for this plan", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                    // this.StarterPlanNewPrice = data.data.NewPrice;

                    this.StandardPlanNewPrice = null;
                    this.StandardPlanCouponControl.setValue('');
                    this.PremiumPlanNewPrice = null;
                    this.PremiumPlanCouponControl.setValue('');
                    this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;

                    this._spinner.hide();
                }
                else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this._spinner.hide();
                }
            })
        }

    }

    ApplyPractitionerCouponControl(Plan): void
    {
        if (this.PractitionerCouponControl.value == '' || this.PractitionerCouponControl.value == null)
        {
            this._matSnackBar.open("Please Enter a Coupon Code", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top'
            });
        }
        else
        {
            this._spinner.show();
            let body = {
                "VoucherCode": this.PractitionerCouponControl.value,
                "Plan": Plan,
            }
            // console.log(body, 'body');

            this._formControlService.VoucherCodeApply(body).subscribe((data: any) =>
            {
                // console.log(data, 'data');
                if (data.isError === false)
                {
                    // debugger;
                    if (data.data.NewPrice != 0)
                    {
                        this.PractitionerNewTotalPrice = data.data.NewPrice;

                        this.PractitionerCouponDiscount = this.PractitionerTotalPrice - this.PractitionerNewTotalPrice;
                    }
                    else
                    {
                        this._matSnackBar.open("This coupon is not applicable for this plan", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }

                    // this.StandardPlanNewPrice = null;
                    // this.StandardPlanCouponControl.setValue('');
                    // this.PremiumPlanNewPrice = null;
                    // this.PremiumPlanCouponControl.setValue('');
                    // this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;

                    this._spinner.hide();
                }
                else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this._spinner.hide();
                }
            })
        }

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}


let df = {
    "NoOfExistClientFiles": 6,
    "TotalPurchasedFiles": 0,
    "TotalPaidAmount": 0,
    "CurrentPlan": 0,
    "CurrentPlanName": "Free",
    "AllowDownload": true,
    "AllowUpgrade": false,
    "Plans": [
        {
            "PlanType": 2,
            "SlNo": 1,
            "Id": 5,
            "PlanName": "Free",
            "PlanPrice": 0,
            "UpgradePlanPrice": 0,
            "TotalPaidAmount": 0,
            "TaxAmount": 0,
            "BankCharge": 0,
            "AllowUpgrade": false,
            "NoOfClientFiles": 5
        },
        {
            "PlanType": 2,
            "SlNo": 2,
            "Id": 6,
            "PlanName": "Starter",
            "PlanPrice": 250,
            "UpgradePlanPrice": 0,
            "TotalPaidAmount": 0,
            "TaxAmount": 0,
            "BankCharge": 0,
            "AllowUpgrade": false,
            "NoOfClientFiles": 45
        },
        {
            "PlanType": 2,
            "SlNo": 3,
            "Id": 7,
            "PlanName": "Standard",
            "PlanPrice": 200,
            "UpgradePlanPrice": 0,
            "TotalPaidAmount": 0,
            "TaxAmount": 0,
            "BankCharge": 0,
            "AllowUpgrade": false,
            "NoOfClientFiles": 50
        },
        {
            "PlanType": 2,
            "SlNo": 4,
            "Id": 8,
            "PlanName": "Premium",
            "PlanPrice": 150,
            "UpgradePlanPrice": 0,
            "TotalPaidAmount": 0,
            "TaxAmount": 0,
            "BankCharge": 0,
            "AllowUpgrade": false,
            "NoOfClientFiles": 0
        }
    ],
    "Transactions": [],
    "AppliedVouchers": null
}
