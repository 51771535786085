<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)"
  size="medium"
  color="#29793C"
  type="ball-clip-rotate"
  [fullScreen]="true">
  <!-- <p style="color: #039be6"> Loading... </p> -->
  <img width="128" style="margin-top: 30px !important;"
    src="assets/home/img/logo-n.png">
</ngx-spinner>

<!-- <fuse-splash-screen id="fuse-splash-screen"
  *ngIf="layout==Layouts.Admin">
  <div class="center">

    <div class="logo">
      <img width="128"
        src="assets/images/logos/fuse.svg">
    </div>
    <div class="spinner-wrapper">
      <div class="spinner">
        <div class="inner">
          <div class="gap"></div>
          <div class="left">
            <div class="half-circle"></div>
          </div>
          <div class="right">
            <div class="half-circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</fuse-splash-screen> -->

<ng-container [ngSwitch]="layout">

  <!-- Alternativerly use the main layout as the default switch case -->
  <app-home-layout *ngSwitchCase="Layouts.Home"
    style="font-family: 'Hind Siliguri';"></app-home-layout>
  <app-admin-layout *ngSwitchCase="Layouts.Admin"></app-admin-layout>
</ng-container>
