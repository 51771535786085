
export class TaxConfig
{

  MaxExemptedGratuityFund: number;
  MaxExemptedConveyance: number;
  MaxExemptedHouseRent: number;
  MaxExemptedMedicalAllowanceForDisabledPerson: number;
  MaxExemptedMedicalAllowance: number;
  MaxExemptedWorkersProfitParticipationFund: number;
  MaxLimiteOfdeposit : number;
  MaxExemptedInterestMutualOrUnitFund: number;
  MaxExemptedCashDividendStockExchange: number;
  MaxExemptedInvestmentPensionerSavingsInstrumentAmount: number;
  MaxCombinedAllowedShanchaypatraAndTreasuryBond: number;


}
