<ng-container *ngIf="!item.hidden">

    <!-- item.url -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && !item.function"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'dupl']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && !item.function"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link"
        [ngClass]="item.classes"
        *ngIf="!item.url && item.function"
        (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && item.function"
        (click)="item.function()"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'dupl']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && item.function"
        (click)="item.function()"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon"
            style="margin-left: -15px;"
            *ngIf="item.icon">{{item.icon}}</mat-icon>
        <span class="nav-link-title"
            *ngIf="item.id !='dashboard' && item.id !='clients' && item.id !='proPayment' && item.id !='proTransaction' && item.id !='proNotification'"
            id={{item.id}}
            style="margin-left: -35px;"
            [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-title"
            *ngIf="item.id =='dashboard'"
            id={{item.id}}
            style="margin-left: -10px;"
            [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-title"
            *ngIf="item.id =='clients'"
            id={{item.id}}
            style="margin-left: -10px;"
            [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-title"
            *ngIf="item.id =='proPayment'"
            id={{item.id}}
            style="margin-left: -10px;"
            [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-title"
            *ngIf="item.id =='proTransaction'"
            id={{item.id}}
            style="margin-left: -10px;"
            [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-title"
            *ngIf="item.id =='proNotification'"
            id={{item.id}}
            style="margin-left: -10px;"
            [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-badge"
            style="margin-right: -5px;font-size: 10px;"
            *ngIf="item.badge"
            [translate]="item.badge.translate"
            [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{(item.badge.translate | translate) || item.badge.title}}
        </span>
    </ng-template>

</ng-container>
