import { TaxConfig } from '../../../util/tax.config';

export class OtherResourcesClass
{
  constructor(
    private _InterestMutualOrUnitFundAmount: number,
    private _InterestMutualOrUnitFundTDS: number,
    private _InterestMutualOrUnitFundTaxable: number,

    private _CashDividendStockExchangeAmount: number,
    private _CashDividendStockExchangeTDS: number,
    private _CashDividendStockExchangeTaxable: number,

    private _InterestIncomeWEDBAmount: number,
    private _InterestIncomeWEDBTDS: number,
    private _InterestIncomeWEDBTaxable: number,

    private _USDollarPremiumInvestmentBondAmount: number,
    private _USDollarPremiumInvestmentBondTDS: number,
    private _USDollarPremiumInvestmentBondTaxable: number,

    private _PoundSterlingPremiumInvestmentBondAmount: number,
    private _PoundSterlingPremiumInvestmentBondTDS: number,
    private _PoundSterlingPremiumInvestmentBondTaxable: number,

    private _EuroPremiumInvestmentBondAmount: number,
    private _EuroPremiumInvestmentBondTDS: number,
    private _EuroPremiumInvestmentBondTaxable: number,

    private _ZeroCouponBondAmount: number,
    private _ZeroCouponBondTDS: number,
    private _ZeroCouponBondTaxable: number,

    private _InvestmentPensionerSavingsInstrumentAmount: number,

    private _InterestReceivedPensionerSavingsInstrumentAmount: number,
    private _InterestReceivedPensionerSavingsInstrumentTDS: number,
    private _InterestReceivedPensionerSavingsInstrumentTaxable: number,

    private _SumReceivedBecauseOfPositionLikeDirectorFeeAmount: number,
    private _SumReceivedBecauseOfPositionLikeDirectorFeeTDS: number,
    private _SumReceivedBecauseOfPositionLikeDirectorFeeTaxable: number,

    private _HonoriumWritingArticleAmount: number,
    private _HonoriumWritingArticleTDS: number,
    private _HonoriumWritingArticleTaxable: number,

    private _RemunerationExaminerInvigilatorAmount: number,
    private _RemunerationExaminerInvigilatorTDS: number,
    private _RemunerationExaminerInvigilatorTaxable: number,

    private _IncomeTuitionAmount: number,
    private _IncomeTuitionTDS: number,
    private _IncomeTuitionTaxable: number,

    private _IncomeTelevisionRadioParticipatingProgramAmount: number,
    private _IncomeTelevisionRadioParticipatingProgramTDS: number,
    private _IncomeTelevisionRadioParticipatingProgramTaxable: number,

    private _OthersAmount: number,
    private _OthersTDS: number,
    private _OthersTaxable: number,

    private _TotalAmount: number,
    private _TotalTDS: number,
    private _TotalTaxable: number,



  )
  {
    this.taxConfig = new TaxConfig();
    this.taxConfig.MaxExemptedInterestMutualOrUnitFund = 25000;
    this.taxConfig.MaxExemptedCashDividendStockExchange = 50000;
    this.taxConfig.MaxExemptedInvestmentPensionerSavingsInstrumentAmount = 500000;

  }
  taxConfig: TaxConfig;


  public get InterestMutualOrUnitFundAmount(): number
  {
    return this._InterestMutualOrUnitFundAmount;
  }
  public set InterestMutualOrUnitFundAmount(x: number)
  {
    this._InterestMutualOrUnitFundAmount = x;
  }

  public get InterestMutualOrUnitFundTDS(): number
  {
    return this._InterestMutualOrUnitFundTDS;
  }
  public set InterestMutualOrUnitFundTDS(x: number)
  {
    this._InterestMutualOrUnitFundTDS = x;
  }

  public get InterestMutualOrUnitFundTaxable(): number
  {
    if (this.InterestMutualOrUnitFundAmount <= this.taxConfig.MaxExemptedInterestMutualOrUnitFund)
    {
      return this._InterestMutualOrUnitFundTaxable = 0.00;
    }
    else
    {
      return this._InterestMutualOrUnitFundTaxable = this.InterestMutualOrUnitFundAmount - this.taxConfig.MaxExemptedInterestMutualOrUnitFund
    }
  }
  public set InterestMutualOrUnitFundTaxable(x: number)
  {
    this._InterestMutualOrUnitFundTaxable = x;
  }

  //

  public get CashDividendStockExchangeAmount(): number
  {
    return this._CashDividendStockExchangeAmount;
  }
  public set CashDividendStockExchangeAmount(x: number)
  {
    this._CashDividendStockExchangeAmount = x;
  }

  public get CashDividendStockExchangeTDS(): number
  {
    return this._CashDividendStockExchangeTDS;
  }
  public set CashDividendStockExchangeTDS(x: number)
  {
    this._CashDividendStockExchangeTDS = x;
  }

  public get CashDividendStockExchangeTaxable(): number
  {
    if (this.CashDividendStockExchangeAmount <= this.taxConfig.MaxExemptedCashDividendStockExchange)
    {
      return this._CashDividendStockExchangeTaxable = 0.00;
    }
    else
    {
      return this._CashDividendStockExchangeTaxable = this.CashDividendStockExchangeAmount - this.taxConfig.MaxExemptedCashDividendStockExchange
    }
  }
  public set CashDividendStockExchangeTaxable(x: number)
  {
    this._CashDividendStockExchangeTaxable = x;
  }

  //

  public get InterestIncomeWEDBAmount(): number
  {
    return this._InterestIncomeWEDBAmount;
  }
  public set InterestIncomeWEDBAmount(x: number)
  {
    this._InterestIncomeWEDBAmount = x;
  }

  public get InterestIncomeWEDBTDS(): number
  {
    return this._InterestIncomeWEDBTDS;
  }
  public set InterestIncomeWEDBTDS(x: number)
  {
    this._InterestIncomeWEDBTDS = x;
  }

  public get InterestIncomeWEDBTaxable(): number
  {
    return this._InterestIncomeWEDBTaxable = 0.00;
  }
  public set InterestIncomeWEDBTaxable(x: number)
  {
    this._InterestIncomeWEDBTaxable = x;
  }

  //

  public get USDollarPremiumInvestmentBondAmount(): number
  {
    return this._USDollarPremiumInvestmentBondAmount;
  }
  public set USDollarPremiumInvestmentBondAmount(x: number)
  {
    this._USDollarPremiumInvestmentBondAmount = x;
  }

  public get USDollarPremiumInvestmentBondTDS(): number
  {
    return this._USDollarPremiumInvestmentBondTDS;
  }
  public set USDollarPremiumInvestmentBondTDS(x: number)
  {
    this._USDollarPremiumInvestmentBondTDS = x;
  }

  public get USDollarPremiumInvestmentBondTaxable(): number
  {
    return this._USDollarPremiumInvestmentBondTaxable = 0.00;
  }
  public set USDollarPremiumInvestmentBondTaxable(x: number)
  {
    this._USDollarPremiumInvestmentBondTaxable = x;
  }

  //

  public get PoundSterlingPremiumInvestmentBondAmount(): number
  {
    return this._PoundSterlingPremiumInvestmentBondAmount;
  }
  public set PoundSterlingPremiumInvestmentBondAmount(x: number)
  {
    this._PoundSterlingPremiumInvestmentBondAmount = x;
  }

  public get PoundSterlingPremiumInvestmentBondTDS(): number
  {
    return this._PoundSterlingPremiumInvestmentBondTDS;
  }
  public set PoundSterlingPremiumInvestmentBondTDS(x: number)
  {
    this._PoundSterlingPremiumInvestmentBondTDS = x;
  }

  public get PoundSterlingPremiumInvestmentBondTaxable(): number
  {
    return this._PoundSterlingPremiumInvestmentBondTaxable = 0.00;
  }
  public set PoundSterlingPremiumInvestmentBondTaxable(x: number)
  {
    this._PoundSterlingPremiumInvestmentBondTaxable = x;
  }


  //

  public get EuroPremiumInvestmentBondAmount(): number
  {
    return this._EuroPremiumInvestmentBondAmount;
  }
  public set EuroPremiumInvestmentBondAmount(x: number)
  {
    this._EuroPremiumInvestmentBondAmount = x;
  }

  public get EuroPremiumInvestmentBondTDS(): number
  {
    return this._EuroPremiumInvestmentBondTDS;
  }
  public set EuroPremiumInvestmentBondTDS(x: number)
  {
    this._EuroPremiumInvestmentBondTDS = x;
  }

  public get EuroPremiumInvestmentBondTaxable(): number
  {
    return this._EuroPremiumInvestmentBondTaxable = 0.00;
  }
  public set EuroPremiumInvestmentBondTaxable(x: number)
  {
    this._EuroPremiumInvestmentBondTaxable = x;
  }

  //

  public get ZeroCouponBondAmount(): number
  {
    return this._ZeroCouponBondAmount;
  }
  public set ZeroCouponBondAmount(x: number)
  {
    this._ZeroCouponBondAmount = x;
  }

  public get ZeroCouponBondTDS(): number
  {
    return this._ZeroCouponBondTDS;
  }
  public set ZeroCouponBondTDS(x: number)
  {
    this._ZeroCouponBondTDS = x;
  }

  public get ZeroCouponBondTaxable(): number
  {
    return this._ZeroCouponBondTaxable = 0.00;
  }
  public set ZeroCouponBondTaxable(x: number)
  {
    this._ZeroCouponBondTaxable = x;
  }

  //

  public get InvestmentPensionerSavingsInstrumentAmount(): number
  {
    return this._InvestmentPensionerSavingsInstrumentAmount;
  }
  public set InvestmentPensionerSavingsInstrumentAmount(x: number)
  {
    this._InvestmentPensionerSavingsInstrumentAmount = x;
  }

  //

  public get InterestReceivedPensionerSavingsInstrumentAmount(): number
  {
    return this._InterestReceivedPensionerSavingsInstrumentAmount;
  }
  public set InterestReceivedPensionerSavingsInstrumentAmount(x: number)
  {
    this._InterestReceivedPensionerSavingsInstrumentAmount = x;
  }

  public get InterestReceivedPensionerSavingsInstrumentTDS(): number
  {
    return this._InterestReceivedPensionerSavingsInstrumentTDS;
  }
  public set InterestReceivedPensionerSavingsInstrumentTDS(x: number)
  {
    this._InterestReceivedPensionerSavingsInstrumentTDS = x;
  }

  public get InterestReceivedPensionerSavingsInstrumentTaxable(): number
  {
    if (this.InvestmentPensionerSavingsInstrumentAmount <= this.taxConfig.MaxExemptedInvestmentPensionerSavingsInstrumentAmount)
    {
      return this._InterestReceivedPensionerSavingsInstrumentTaxable = 0.00;
    }
    else
    {
      return this._InterestReceivedPensionerSavingsInstrumentTaxable = this.InterestReceivedPensionerSavingsInstrumentAmount;
    }
  }
  public set InterestReceivedPensionerSavingsInstrumentTaxable(x: number)
  {
    this._InterestReceivedPensionerSavingsInstrumentTaxable = x;
  }

  //

  public get SumReceivedBecauseOfPositionLikeDirectorFeeAmount(): number
  {
    return this._SumReceivedBecauseOfPositionLikeDirectorFeeAmount;
  }
  public set SumReceivedBecauseOfPositionLikeDirectorFeeAmount(x: number)
  {
    this._SumReceivedBecauseOfPositionLikeDirectorFeeAmount = x;
  }

  public get SumReceivedBecauseOfPositionLikeDirectorFeeTDS(): number
  {
    return this._SumReceivedBecauseOfPositionLikeDirectorFeeTDS;
  }
  public set SumReceivedBecauseOfPositionLikeDirectorFeeTDS(x: number)
  {
    this._SumReceivedBecauseOfPositionLikeDirectorFeeTDS = x;
  }

  public get SumReceivedBecauseOfPositionLikeDirectorFeeTaxable(): number
  {
    return this._SumReceivedBecauseOfPositionLikeDirectorFeeTaxable = this.SumReceivedBecauseOfPositionLikeDirectorFeeAmount;
  }
  public set SumReceivedBecauseOfPositionLikeDirectorFeeTaxable(x: number)
  {
    this._SumReceivedBecauseOfPositionLikeDirectorFeeTaxable = x;
  }

  //

  public get HonoriumWritingArticleAmount(): number
  {
    return this._HonoriumWritingArticleAmount;
  }
  public set HonoriumWritingArticleAmount(x: number)
  {
    this._HonoriumWritingArticleAmount = x;
  }

  public get HonoriumWritingArticleTDS(): number
  {
    return this._HonoriumWritingArticleTDS;
  }
  public set HonoriumWritingArticleTDS(x: number)
  {
    this._HonoriumWritingArticleTDS = x;
  }

  public get HonoriumWritingArticleTaxable(): number
  {
    return this._HonoriumWritingArticleTaxable = this.HonoriumWritingArticleAmount;
  }
  public set HonoriumWritingArticleTaxable(x: number)
  {
    this._HonoriumWritingArticleTaxable = x;
  }

  //

  public get RemunerationExaminerInvigilatorAmount(): number
  {
    return this._RemunerationExaminerInvigilatorAmount;
  }
  public set RemunerationExaminerInvigilatorAmount(x: number)
  {
    this._RemunerationExaminerInvigilatorAmount = x;
  }

  public get RemunerationExaminerInvigilatorTDS(): number
  {
    return this._RemunerationExaminerInvigilatorTDS;
  }
  public set RemunerationExaminerInvigilatorTDS(x: number)
  {
    this._RemunerationExaminerInvigilatorTDS = x;
  }

  public get RemunerationExaminerInvigilatorTaxable(): number
  {
    return this._RemunerationExaminerInvigilatorTaxable = this.RemunerationExaminerInvigilatorAmount;
  }
  public set RemunerationExaminerInvigilatorTaxable(x: number)
  {
    this._RemunerationExaminerInvigilatorTaxable = x;
  }

  //

  public get IncomeTuitionAmount(): number
  {
    return this._IncomeTuitionAmount;
  }
  public set IncomeTuitionAmount(x: number)
  {
    this._IncomeTuitionAmount = x;
  }

  public get IncomeTuitionTDS(): number
  {
    return this._IncomeTuitionTDS;
  }
  public set IncomeTuitionTDS(x: number)
  {
    this._IncomeTuitionTDS = x;
  }

  public get IncomeTuitionTaxable(): number
  {
    return this._IncomeTuitionTaxable = this.IncomeTuitionAmount;
  }
  public set IncomeTuitionTaxable(x: number)
  {
    this._IncomeTuitionTaxable = x;
  }

  //

  public get IncomeTelevisionRadioParticipatingProgramAmount(): number
  {
    return this._IncomeTelevisionRadioParticipatingProgramAmount;
  }
  public set IncomeTelevisionRadioParticipatingProgramAmount(x: number)
  {
    this._IncomeTelevisionRadioParticipatingProgramAmount = x;
  }

  public get IncomeTelevisionRadioParticipatingProgramTDS(): number
  {
    return this._IncomeTelevisionRadioParticipatingProgramTDS;
  }
  public set IncomeTelevisionRadioParticipatingProgramTDS(x: number)
  {
    this._IncomeTelevisionRadioParticipatingProgramTDS = x;
  }

  public get IncomeTelevisionRadioParticipatingProgramTaxable(): number
  {
    return this._IncomeTelevisionRadioParticipatingProgramTaxable = this.IncomeTelevisionRadioParticipatingProgramAmount;
  }
  public set IncomeTelevisionRadioParticipatingProgramTaxable(x: number)
  {
    this._IncomeTelevisionRadioParticipatingProgramTaxable = x;
  }

  //

  public get OthersAmount(): number
  {
    return this._OthersAmount;
  }
  public set OthersAmount(x: number)
  {
    this._OthersAmount = x;
  }

  public get OthersTDS(): number
  {
    return this._OthersTDS;
  }
  public set OthersTDS(x: number)
  {
    this._OthersTDS = x;
  }

  public get OthersTaxable(): number
  {
    return this._OthersTaxable = this.OthersAmount;
  }
  public set OthersTaxable(x: number)
  {
    this._OthersTaxable = x;
  }


  //


  public get TotalAmount(): number
  {
    return this._TotalAmount = Number(this.InterestMutualOrUnitFundAmount)
      + Number(this.CashDividendStockExchangeAmount)
      + Number(this.InterestIncomeWEDBAmount)
      + Number(this.USDollarPremiumInvestmentBondAmount)
      + Number(this.PoundSterlingPremiumInvestmentBondAmount)
      + Number(this.EuroPremiumInvestmentBondAmount)
      + Number(this.ZeroCouponBondAmount)
      // + Number(this.InvestmentPensionerSavingsInstrumentAmount)
      + Number(this.InterestReceivedPensionerSavingsInstrumentAmount)
      + Number(this.SumReceivedBecauseOfPositionLikeDirectorFeeAmount)
      + Number(this.HonoriumWritingArticleAmount)
      + Number(this.RemunerationExaminerInvigilatorAmount)
      + Number(this.IncomeTuitionAmount)
      + Number(this.IncomeTelevisionRadioParticipatingProgramAmount)
      + Number(this.OthersAmount)
  }
  public set TotalAmount(x: number)
  {
    this._TotalAmount = x;
  }

  public get TotalTDS(): number
  {
    return this._TotalTDS = Number(this.InterestMutualOrUnitFundTDS)
      + Number(this.CashDividendStockExchangeTDS)
      + Number(this.InterestIncomeWEDBTDS)
      + Number(this.USDollarPremiumInvestmentBondTDS)
      + Number(this.PoundSterlingPremiumInvestmentBondTDS)
      + Number(this.EuroPremiumInvestmentBondTDS)
      + Number(this.ZeroCouponBondTDS)
      + Number(this.InterestReceivedPensionerSavingsInstrumentTDS)
      + Number(this.SumReceivedBecauseOfPositionLikeDirectorFeeTDS)
      + Number(this.HonoriumWritingArticleTDS)
      + Number(this.RemunerationExaminerInvigilatorTDS)
      + Number(this.IncomeTuitionTDS)
      + Number(this.IncomeTelevisionRadioParticipatingProgramTDS)
      + Number(this.OthersTDS);
  }
  public set TotalTDS(x: number)
  {
    this._TotalTDS = x;
  }

  public get TotalTaxable(): number
  {
    return this._TotalTaxable = Number(this.InterestMutualOrUnitFundTaxable)
      + Number(this.CashDividendStockExchangeTaxable)
      + Number(this.InterestIncomeWEDBTaxable)
      + Number(this.USDollarPremiumInvestmentBondTaxable)
      + Number(this.PoundSterlingPremiumInvestmentBondTaxable)
      + Number(this.EuroPremiumInvestmentBondTaxable)
      + Number(this.ZeroCouponBondTaxable)
      + Number(this.InterestReceivedPensionerSavingsInstrumentTaxable)
      + Number(this.SumReceivedBecauseOfPositionLikeDirectorFeeTaxable)
      + Number(this.HonoriumWritingArticleTaxable)
      + Number(this.RemunerationExaminerInvigilatorTaxable)
      + Number(this.IncomeTuitionTaxable)
      + Number(this.IncomeTelevisionRadioParticipatingProgramTaxable)
      + Number(this.OthersTaxable)
  }
  public set TotalTaxable(x: number)
  {
    this._TotalTaxable = x;
  }
}
