<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex
        fxFill
        fxLayout="row"
        fxLayoutAlign="start center">

        <div fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center">

            <button mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

        <div *ngIf="_router.url.includes('/Clients') == false && _router.url.includes('/Profile') == false && _router.url.includes('/Payment') == false && _router.url.includes('/MyAccount') == false && _router.url.includes('/Transaction') == false && _router.url.includes('/Notification') == false" 
            fxLayout="row"
            fxLayoutAlign="start start">

            <button mat-button
                *ngIf="user.ActiveRoleId=='7'"
                fxLayout="row"
                fxLayoutAlign="center center"
                (click)="ExitClient()"
                matTooltip="Exit & return">
                <mat-icon class="secondary-text mr-4"
                    style="color: #ff0000;">input</mat-icon>
                <span *ngIf="AssesseeId!=null"
                    class="username mr-12"
                    fxHide
                    fxShow.gt-sm>{{AssesseeId.AssesseeName}}</span>
            </button>

        </div>

        <div class=""
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center">

            <button mat-button
                *ngIf="selectedLanguage.id=='en'"
                class="language-button"
                (click)="setLanguage(languages[1])">
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    <img class="flag mr-8"
                        [src]="'assets/icons/flags/bn.png'">
                    <span class="iso">বাংলা</span>
                </div>
            </button>

            <button mat-button
                *ngIf="selectedLanguage.id=='bn'"
                class="language-button"
                (click)="setLanguage(languages[0])">
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    <img class="flag mr-8"
                        [src]="'assets/icons/flags/us.png'">
                    <span class="iso">English</span>
                </div>
            </button>

            <button mat-button
                [matMenuTriggerFor]="userMenu"
                class="user-button">
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    <img *ngIf="user.PhotoUrl==''"
                        class="avatar mr-0 mr-sm-16"
                        src="assets/images/avatars/profile.jpg">
                    <img *ngIf="user.PhotoUrl!=''"
                        class="avatar mr-0 mr-sm-16"
                        src="https://allpicture.blob.core.windows.net/digitax/profile-pic/{{user.PhotoUrl}}">
                    <span class="username mr-12"
                        fxHide
                        fxShow.gt-sm>{{user.Name}}</span>
                    <mat-icon class="s-16"
                        fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu"
                [overlapTrigger]="false">

                <button mat-menu-item
                    [routerLink]="'/MyAccount'">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Account</span>
                </button>

                <button *ngIf="user.ActiveRoleId=='7'"
                    mat-menu-item
                    [routerLink]="'/Profile'">
                    <mat-icon>account_circle</mat-icon>
                    <span>Organization Profile</span>
                </button>

                <!-- <button mat-menu-item
                    (click)="openChangePasswordDialog()"
                    class="">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Change Password</span>
                </button> -->

                <button mat-menu-item
                    (click)="Logout()"
                    class="">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"
                fxHide
                fxShow.gt-xs></div>

            <button
                *ngIf="_router.url.includes('/Clients') == false && this._router.url.includes('/Payment') == false && this._router.url.includes('/Profile') == false && this._router.url.includes('/MyAccount') == false && _router.url.includes('/Transaction') == false && _router.url.includes('/Notification') == false"
                fxShow.gt-md="false"
                mat-icon-button
                class="quick-panel-toggle-button"
                (click)="toggleSidebarOpen('my-demo-sidebar')"
                aria-label="Toggle my-demo-sidebar">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <!-- <div
                *ngIf="_router.url.includes('/Clients') == false && _router.url.includes('/Profile') == false && _router.url.includes('/ProfessionalPayment') == false">
                <button *ngIf="user.ActiveRoleId=='7'"
                    mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                    <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
                </button>
            </div> -->
            <div class="toolbar-separator"
                *ngIf="!hiddenNavbar && rightNavbar"
                fxHide
                fxShow.gt-xs></div>

            <button mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
