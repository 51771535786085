import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import Utils from 'app/util/utils';
import { AppUser } from 'app/auth';
import { Meta, Title } from '@angular/platform-browser';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSelect } from '@angular/material/select';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';
import { NotificationService } from './Notification.service';
import * as lodash from 'lodash';
import { TemplateListDialog } from './template-list-dialog/template-list-Dialog.component';
import { TemplateSaveDialog } from './save-template-dialog/save-template-Dialog.component';
import { TemplateEditDialog } from './edit-template-dialog/edit-template-Dialog.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';





@Component({
    selector: 'Notification',
    templateUrl: './Notification.component.html',
    styleUrls: ['./Notification.component.scss']
})
export class NotificationComponent
{

    lang = '';


    dropDownData: any;
    GatewayDropdown: any;
    AllNCTemplate: any;
    GetAllNCDBField: any;
    SmsBalance: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    RecipientTypeControl = new FormControl();
    RecipientGatewayControl = new FormControl();
    SMSBodyControl = new FormControl();

    EtinFilterControl = new FormControl('');
    MobileFilterControl = new FormControl('');
    EmailFilterControl = new FormControl('');
    ClientFilterControl = new FormControl('');
    RefFilterControl = new FormControl('');
    SortingFilterControl = new FormControl('');

    ClientfilteredOptions: Observable<any[]>;


    moment: any = moment;

    bodyLength: any = getLength;

    // items: string[] = ['Noah', 'Liam', 'Mason', 'Jacob'];

    items: any;
    mentionConfig = {
        triggerChar: '@',
        maxItems: 10
        //mentionSelect: this.mentionSelect
    };

    displayedColumns = ['SL', 'SELECTION', 'CLIENTID', 'CLIENTNAME', 'CONTACT', 'SMSTEXT', 'LENGTH'];
    // dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);
    dataSource: any;

    selection = new SelectionModel<any>(true, []);

    showTable = false;

    AutoSearchData = null;


    // @ViewChild('selector', { static: false }) selector: MatInput;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: NotificationService,
        private back: BackButtonService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private eventEmitterService: EventEmitterService,
        private _route: ActivatedRoute,
        private sidePanelSharedService: SidePanelSharedService,
        private _fuseNavigationService: FuseNavigationService,
        private _formBuilder: FormBuilder,
        private http: HttpClient,
        public dialog: MatDialog,


    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Organization Notification" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Organization Notification" }
        ]);
        this.setTitle("digiTax | Organization Notification");
        // this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }

        // this.form = this._formBuilder.group({
        //     myControl: new FormControl(),
        // })
        this.lang = localStorage.getItem('selectedLanguage');
        if (this.lang == 'en')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        if (this.lang == 'bn')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.getMainDropData();


        this.ClientFilterControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.ClientfilteredOptions = response.data;
                        // console.log(response, 'response');
                    });
                }
                else
                {
                    return null;
                }
            })



    }


    AutoSearchedClient(value): void
    {
        this.AutoSearchData = value;
    }



    getMainDropData(): void
    {
        this._spinner.show();
        this._formControlService.GetCommomDropdown().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
                this.dropDownData = data;
                // console.log(data, 'getMainDropData');
                this.GatewayDropdown = data.data.GetNCGatewayForDropdown;
                this.AllNCTemplate = data.data.GetAllNCTemplate;
                this.GetAllNCDBField = data.data.GetAllNCDBField;

                // this.items = lodash.map(data.GetAllNCDBField, 'DisplayName').join(', ');
                // console.log(this.AllNCTemplate, 'this.AllNCTemplate');

                this.items = this.GetAllNCDBField.map(el => el.DisplayName);



                if (data.data.DefaultSelectedGatewayId != null)
                {
                    this.RecipientGatewayControl.setValue(data.data.DefaultSelectedGatewayId.toString());
                    this.GetSmsBalance(data.data.DefaultSelectedGatewayId);
                }





            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        },
            (err: any) =>
            {
                console.log(err, 'err');
                this._spinner.hide();
            });
    }




    GetSmsBalance(id)
    {
        this._formControlService.GetSmsBalanceById(id).subscribe((data: any) =>
        {


            if (data != null)
            {
                this.SmsBalance = data.data;
                this._spinner.hide();
            }
        },
            (err: any) =>
            {
                console.log(err, 'err');
                this._spinner.hide();
            });
    }

    SaveTemplate(): void
    {
        let val = this.SMSBodyControl.value;

        if (val == null || val == "") 
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please enter a template body" : "Please enter a template body", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            let Assessee_Id = /@Assessee_Id/gi;
            let Assessee_Name = /@Assessee_Name/gi;
            let Contact_No = /@Contact_No/gi;
            let Email = /@Email/gi;

            let rep = val.replace(Assessee_Id, "@ITAXAssesseeInfo_Id").replace(Assessee_Name, "@ITAXAssesseeInfo_Name").replace(Contact_No, "@ITAXAssesseeInfo_ContactNo").replace(Email, "@ITAXAssesseeInfo_Email");

            let body =
            {
                "Id": "0",
                "CategoryId": "1",
                "TemplateName": "",
                "MessageText": val,
                "DBFields": "Test",
                "ActiveStatus": 1,
                "HiddenTemplate": rep
            }

            this.openTemplateSaveDialog(body)

        }



    }



    CheckDelete(id): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_DELETE : bangla.data.COMMON.LABEL_DELETE;

        this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

        this.confirmDialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.deleteTemplate(id);
            }
            this.confirmDialogRef = null;
        });

    }

    deleteTemplate(id): void
    {
        this._formControlService.DeleteTemplate(id).subscribe((data: any) =>
        {
            if (data.isError === false)
            {

                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DELETED : bangla.data.COMMON.BUTTON_DELETED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this.getMainDropData();
            }
            else
            {

                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        })
    }


    openTemplateListDialog(): void
    {
        const dialogRef = this.dialog.open(TemplateListDialog, {
            width: '500px',
            disableClose: true,
            data: { data: this.AllNCTemplate }
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result.type == 'no')
            {
                console.log('dialog canceled');
            }
            if (result.type == 'edit')
            {

                this.openTemplateEditDialog(result.data)

            }
            if (result.type == 'pass')
            {

                this.SMSBodyControl.setValue("");
                this.SMSBodyControl.setValue(result.data.MessageText);
            }
            if (result.type == 'delete')
            {
                let body = { "Id": result.data.Id };
                this.CheckDelete(body);

            }
        });
    }


    openTemplateSaveDialog(body): void
    {
        const dialogRef = this._matDialog.open(TemplateSaveDialog, {
            width: '500px',
            height: '300px',
            data: { data: body }
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'no')
            {
                console.log('dialog canceled');
            }
            else
            {
                console.log('dialog closed');
                this.SMSBodyControl.setValue("");
                this.getMainDropData();
                // this.SMSBodyControl.setValue(result.MessageText);
            }
            // if (result == 'yes')
            // {
            //      console.log('file uploaded');

            // }
        });
    }

    openTemplateEditDialog(body): void
    {
        const dialogRef = this._matDialog.open(TemplateEditDialog, {
            width: '500px',
            height: '300px',
            data: { data: body }
        });

        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'no')
            {
                console.log('dialog canceled');
            }
            else
            {
                console.log('dialog closed');
                // this.SMSBodyControl.setValue("");
                this.getMainDropData();
                // this.SMSBodyControl.setValue(result.MessageText);
            }
            // if (result == 'yes')
            // {
            //      console.log('file uploaded');

            // }
        });
    }

    PrepareNotification(): void
    {

        if (this.RecipientTypeControl.value == "" || this.RecipientTypeControl.value == null)
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please Select a Recipient" : "Please Select a Recipient", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        } else
        {
            let val = this.SMSBodyControl.value;
            if (val == null || val == "")
            {
                this._matSnackBar.open(this.lang == 'en' ? "Please enter a template body" : "Please enter a template body", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                this._spinner.show();
                let Assessee_Id = /@Assessee_Id/gi;
                let Assessee_Name = /@Assessee_Name/gi;
                let Contact_No = /@Contact_No/gi;
                let Email = /@Email/gi;

                let rep = val.replace(Assessee_Id, "@ITAXAssesseeInfo_Id").replace(Assessee_Name, "@ITAXAssesseeInfo_Name").replace(Contact_No, "@ITAXAssesseeInfo_ContactNo").replace(Email, "@ITAXAssesseeInfo_Email");

                if (
                    this.AutoSearchData !== null ||
                    this.EtinFilterControl.value !== "" ||
                    this.MobileFilterControl.value !== "" ||
                    this.EmailFilterControl.value !== "" ||
                    this.RefFilterControl.value !== ""
                )
                {
                    let body = {
                        "AssesseeId": this.AutoSearchData == null ? null : this.AutoSearchData.AssesseId,
                        "ReturnId": this.AutoSearchData == null ? null : this.AutoSearchData.ReturnId,
                        "ETIN": this.EtinFilterControl.value,
                        "ContactNo": this.MobileFilterControl.value,
                        "Email": this.EmailFilterControl.value,
                        "Reference": this.RefFilterControl.value,
                        "template": rep
                    }
                    this._formControlService.NotificationPrepareSingleClient(body).subscribe(data =>
                    {
                        this._spinner.show();
                        // console.log(data, 'data');
                        if (data.data.length > 0)
                        {
                            this.showTable = true;
                            this._spinner.hide();
                            let ds = [];
                            if (this.dataSource !== undefined)
                            {
                                ds = this.dataSource.filteredData;
                                ds = ds.concat(data.data);
                                this.dataSource = new MatTableDataSource<any>(ds);
                            } else
                            {
                                this.dataSource = new MatTableDataSource<any>(data.data);
                            }

                            this.AutoSearchData = null;
                            this.ClientfilteredOptions = null;
                            this.EtinFilterControl.setValue('');
                            this.ClientFilterControl.setValue('');
                            this.MobileFilterControl.setValue('');
                            this.EmailFilterControl.setValue('');
                            this.RefFilterControl.setValue('');
                        }
                        else
                        {
                            // this.showTable = false;
                            // this.dataSource = null;
                            this._spinner.hide();
                            this._matSnackBar.open(this.lang == 'en' ? 'No Data Found' : 'No Data Found', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                    });
                }
                else
                {
                    let body = {
                        "OrderBy": SortAssesseeValue(this.SortingFilterControl.value),
                        "template": rep
                    }
                    this._formControlService.NotificationPrepareClients(body).subscribe(data =>
                    {
                        this._spinner.show();
                        // console.log(data, 'data');
                        if (data.data.length > 0)
                        {
                            this.showTable = true;
                            this._spinner.hide();
                            // if (Array.isArray(data))
                            // {
                            //     this.dataSource = new MatTableDataSource<any>(data);
                            // } else
                            // {
                            //     this.dataSource = new MatTableDataSource<any>([data]);
                            // }
                            this.dataSource = new MatTableDataSource<any>(data.data);

                        }
                        else
                        {
                            // this.showTable = false;
                            // this.dataSource = null;
                            this._spinner.hide();
                            this._matSnackBar.open(this.lang == 'en' ? 'No Data Found' : 'No Data Found', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                    });
                }


            }
        }
    }


    SendNotification(): void
    {


        if (this.selection.selected == null || this.selection.selected.length == 0)
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please Select a client" : "Please Select a client", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {

            this._spinner.show();

            // let val = this.SMSBodyControl.value;


            // let myval = this.selection.selected.map(value =>
            // ({
            //     "Reference": value.Reference,
            //     "ETIN": value.ETIN,
            //     "AssesseName": value.AssesseName,
            //     "AssesseeId": value.AssesseeId,
            //     "ReturnId": value.ReturnId,
            //     "ContactNo": "01916387657",
            //     "Email": "crhneefbqejka@arxxwalls.com",
            //     "MessageText": value.MessageText,
            // }));
            let body = {
                "Id": 0,
                "CategoryId": "1",
                "TemplateId": 0,
                "MessageText": this.SMSBodyControl.value,
                "ActiveStatus": 1,
                "Recipient": RecipientType(this.RecipientTypeControl.value),
                "NCGatewayIds": this.RecipientGatewayControl.value,
                "NCDetailsList": this.selection.selected,
            }

            // console.log(body, 'body');
            // debugger;

            this._formControlService.SendNotification(body).subscribe(data =>
            {
                // debugger;
                // console.log(data, 'data');
                if (data.isError == false)
                {

                    this.showTable = false;
                    this.dataSource = null;
                    this.selection = new SelectionModel<any>(true, []);
                    this.RecipientTypeControl.setValue('');
                    this.SMSBodyControl.setValue('');
                    this.EtinFilterControl.setValue('');
                    this.MobileFilterControl.setValue('');
                    this.EmailFilterControl.setValue('');
                    this.ClientFilterControl.setValue('');
                    this.RefFilterControl.setValue('');
                    this.SortingFilterControl.setValue('');

                    this.AutoSearchData = null;
                    this.ClientfilteredOptions = null;

                    this._spinner.hide();
                    this.getMainDropData();
                    this._matSnackBar.open(this.lang == 'en' ? 'Notification sent' : 'Notification sent', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
            });

        }
    }


    ClearPage()
    {
        this.showTable = false;
        this.dataSource = null;
        this.selection = new SelectionModel<any>(true, []);
        this.RecipientTypeControl.setValue('');
        this.SMSBodyControl.setValue('');
        this.EtinFilterControl.setValue('');
        this.MobileFilterControl.setValue('');
        this.EmailFilterControl.setValue('');
        this.ClientFilterControl.setValue('');
        this.RefFilterControl.setValue('');
        this.SortingFilterControl.setValue('');

        this.AutoSearchData = null;
        this.ClientfilteredOptions = null;
    }



    isAllSelected()
    {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }


    masterToggle()
    {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    onChangeTab(event: MatTabChangeEvent)
    {

        console.log(event.index);

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

export interface Element
{
    CLIENTNAME: string;
    CLIENTID: number;
    CONTACT: number;
    SMSTEXT: string;
}


export function getLength(t)
{
    return t.length;
}



const ELEMENT_DATA: Element[] = [
    { CLIENTID: 1, CLIENTNAME: 'Hydrogen', CONTACT: 1.0079, SMSTEXT: 'H' },
    { CLIENTID: 2, CLIENTNAME: 'Helium', CONTACT: 4.0026, SMSTEXT: 'He' },
    { CLIENTID: 3, CLIENTNAME: 'Lithium', CONTACT: 6.941, SMSTEXT: 'Li' },
    { CLIENTID: 4, CLIENTNAME: 'Beryllium', CONTACT: 9.0122, SMSTEXT: 'Be' },
    { CLIENTID: 5, CLIENTNAME: 'Boron', CONTACT: 10.811, SMSTEXT: 'B' },
    { CLIENTID: 6, CLIENTNAME: 'Carbon', CONTACT: 12.0107, SMSTEXT: 'C' },
    { CLIENTID: 7, CLIENTNAME: 'Nitrogen', CONTACT: 14.0067, SMSTEXT: 'N' },
    { CLIENTID: 8, CLIENTNAME: 'Oxygen', CONTACT: 15.9994, SMSTEXT: 'O' },
    { CLIENTID: 9, CLIENTNAME: 'Fluorine', CONTACT: 18.9984, SMSTEXT: 'F' },
    { CLIENTID: 10, CLIENTNAME: 'Neon', CONTACT: 20.1797, SMSTEXT: 'Ne' },
];



[
    {
        "Reference": "",
        "ETIN": "565464646464",
        "AssesseName": "asw werf",
        "AssesseeId": 1931,
        "ReturnId": 1910,
        "ContactNo": "24342342424",
        "Email": "asd@ef.com",
        "MessageText": "Dear asw werf(Client Id: 1931) Assalamu Alaikum!"
    },
    {
        "Reference": "test ref 01",
        "ETIN": "656564546432",
        "AssesseName": "mobile",
        "AssesseeId": 2216,
        "ReturnId": 2205,
        "ContactNo": "13234234242",
        "Email": "sdads@dfdf.com",
        "MessageText": "Dear mobile(Client Id: 2216) Assalamu Alaikum!"
    },
    {
        "Reference": "rrt",
        "ETIN": "123123123123",
        "AssesseName": "sim kio",
        "AssesseeId": 2217,
        "ReturnId": 2206,
        "ContactNo": "23423423423",
        "Email": "sdsd@rom.com",
        "MessageText": "Dear sim kio(Client Id: 2217) Assalamu Alaikum!"
    }
]


function RecipientType(params)
{
    if (params == '3')
    {
        return 'Email';
    }
    if (params == '2')
    {
        return 'ContactNo';
    }
}

function SortType(params)
{
    if (params == '1')
    {
        return 'Email';
    }
    if (params == '2')
    {
        return 'ContactNo';
    }
    if (params == '' || params == null)
    {
        return 'AssesseeId';
    }
}

function SortAssesseeValue(params)
{
    if (params == '1')
    {
        return 'AssesseeId';
    }
    if (params == '2')
    {
        return 'AssesseeName';
    }
    if (params == '' || params == null)
    {
        return 'AssesseeId';
    }
}

const typ = {
    General_Notice: 1,
    Transaction_Collection: 3,
    Transaction_Dues: 4,
    Clients_Documents: 5,
    Clients_Birthday_Greetings: 6
}
