import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class SalaryGovtService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getPageData(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.INCOME_Salary_Private_Control_Url + id);
  }
  
  submitPrivateSalaryJson(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Salary_Private_Submit_Url, model);
    }
    else
    {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.INCOME_Salary_Private_Submit_Url + `/${id}`, model);
    }
  }


  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    if (id != null)
    {
   
      return this.service.get(urlConfig.INCOME_Salary_Private_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
    
      return this.service.get(urlConfig.INCOME_Salary_Private_Control_Url).pipe(map(item => Utils.toControls(item.data)));

    }

  }





  // calculateSalaryGovtJson(model: any): Observable<any>
  // {
  //   return this.service.post(Config.INCOME_CALCULATE_Private_Salary, model);
  // }






  getJson(): any
  {

    const jsonData = [
      {
        "control": "LabelGroupControl",
        "key": 'salaryHeaders',
        "errorType": "",
        "label": "Pay & Allowance",
        "message": "",
        "tooltip": "",
        "order": 1,
        "children": [
          {
            "control": "LabelControl",
            "key": "header2",
            "label": "Amount Of Yearly Income",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 1
          },
          {
            "control": "LabelControl",
            "key": "header3",
            "label": "Amount Of Exempted Income",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 2
          },
          {
            "control": "LabelControl",
            "key": "header4",
            "label": "Net Taxable Income",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 3
          },
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'basicPay',
        "label": "Basic Pay",
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 2,
        "children": [
          {
            "control": "TextboxControl",

            "key": "BasicPayAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": true,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "BasicPayExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "BasicPayTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'specialPay',
        "label": 'Special Pay',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 3,
        "children": [
          {
            "control": "TextboxControl",

            "key": "SpecialPayAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "SpecialPayExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "SpecialPayTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'dearnessAllowance',
        "label": 'Dearness Allowance',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 4,
        "children": [
          {
            "control": "TextboxControl",

            "key": "DearnessAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "DearnessAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "DearnessAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'conveyanceStatus',
        "label": 'Conveyance Allowance',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 5,
        "children": [
          {
            "control": "TextboxControl",

            "key": "ConveyanceAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "ConveyanceAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "ConveyanceAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'houseRentAllowance',
        "label": 'House Rent Allowance',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 6,
        "children": [
          {
            "control": "TextboxControl",

            "key": "HouseRentAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "HouseRentAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "HouseRentAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'medicalAllowanceWoDisability',
        "label": 'Medical Allowance',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 7,
        "children": [
          {
            "control": "TextboxControl",

            "key": "MedicalAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "MedicalAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "MedicalAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'allowanceForSupportStaff',
        "label": 'Allowance For Support Staff',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 8,
        "children": [
          {
            "control": "TextboxControl",

            "key": "AllowanceForSupportStaffAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "AllowanceForSupportStaffExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "AllowanceForSupportStaffTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'lfa',
        "label": 'Leave Fare Assistance (LFA)',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 9,

        "children": [
          {
            "control": "TextboxControl",

            "key": "LeaveFairAssistanceStaffAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "LeaveFairAssistanceStaffExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "LeaveFairAssistanceStaffTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'leaveEncashment',
        "label": 'Leave Encashment',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 10,
        "children": [
          {
            "control": "TextboxControl",

            "key": "LeaveEncashmentAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "LeaveEncashmentExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "LeaveEncashmentTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },



      {
        "control": "TextboxGroupControl",
        "key": 'honoriumReward',
        "label": 'Honorarium or Reward',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 11,
        "children": [
          {
            "control": "TextboxControl",

            "key": "HonorariumOrRewardAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "HonorariumOrRewardExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "HonorariumOrRewardTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'overtimeAllowance',
        "label": 'Overtime Allowance',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 12,
        "children": [
          {
            "control": "TextboxControl",

            "key": "OvertimeAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "OvertimeAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "OvertimeAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'bonus',
        "label": 'Bonus',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 13,
        "children": [
          {
            "control": "TextboxControl",

            "key": "BonusAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "BonusExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "BonusTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'otherAllowances',
        "label": 'Other Allowances',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 14,
        "children": [
          {
            "control": "TextboxControl",

            "key": "OtherAllowancesAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "OtherAllowancesExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "OtherAllowancesTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'employersContributionProvidentFund',
        "label": 'Employers Contribution Provident Fund',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 15,
        "children": [
          {
            "control": "TextboxControl",

            "key": "EmployersContributionProvidentFundAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "EmployersContributionProvidentFundExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "EmployersContributionProvidentFundTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'interestAccruedProvidentFund',
        "label": 'Interest Accrued Provident Fund',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 16,
        "children": [
          {
            "control": "TextboxControl",
            "key": "InterestAccruedProvidentFundRateOfInterestAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "InterestAccruedProvidentFundRateOfInterestExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "InterestAccruedProvidentFundRateOfInterestTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'receivedAnyTransport',
        "label": 'Transport From Your Employer',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 17,
        "children": [
          {
            "control": "TextboxControl",

            "key": "DeemedIncomeTransportAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "DeemedIncomeTransportExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "DeemedIncomeTransportTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'receivedAnyHouse',
        "label": 'Received Any Furnished or Unfurnished House From Your Employer?',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 18,
        "children": [

          {
            "control": "TextboxControl",

            "key": "EmployerPaidRentalAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,

            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "DeemedFreeAccommodationExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "FurnishedOrUnfurnishedHouseFromEmployerTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'festivalBonus',
        "label": 'Festival Bonus',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 19,
        "children": [
          {
            "control": "TextboxControl",

            "key": "FestivalBonusAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "FestivalBonusExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "FestivalBonusTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'bengaliNewYearBonus',
        "label": 'Bengali New Year Bonus',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 20,
        "children": [
          {
            "control": "TextboxControl",

            "key": "BengaliNewYearBonusAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "BengaliNewYearBonusExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "BengaliNewYearBonusTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'festivalAllowance',
        "label": 'Festival Allowance',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 21,
        "children": [
          {
            "control": "TextboxControl",

            "key": "FestivalAllowanceAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "FestivalAllowanceExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "FestivalAllowanceTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'pension',
        "label": 'Pension',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 22,
        "children": [
          {
            "control": "TextboxControl",

            "key": "PensionAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "PensionExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "PensionTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'providentFundIncome',
        "label": 'Income Received From Recognized Provident Fund and Recognized Super Annuation Fund',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 23,
        "children": [
          {
            "control": "TextboxControl",

            "key": "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": 'others',
        "label": 'Others',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 24,
        "children": [
          {
            "control": "TextboxControl",

            "key": "OthersAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "OthersExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "OthersTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },

      {
        "control": "TextboxGroupControl",
        "key": 'arrearPay',
        "label": 'Arrear Pay (If Not Included in Taxable Income Earlier)',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 25,
        "children": [
          {
            "control": "TextboxControl",

            "key": "ArrearPayAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "required": false,
            "readonly": false,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "ArrearPayExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "ArrearPayTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },


      {
        "control": "TextboxGroupControl",
        "key": 'netTaxableIncome',
        "label": 'Net Taxable Income',
        "errorType": "",
        "message": "",
        "tooltip": "Info about the Field",
        "order": 26,
        "children": [
          {
            "control": "TextboxControl",

            "key": "TotalAmount",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 1
          },
          {
            "control": "TextboxControl",

            "key": "TotalExempted",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          },
          {
            "control": "TextboxControl",

            "key": "NetTaxable",
            "label": "",
            "errorType": "",
            "message": "Is % Required",
            "tooltip": "Info about the Circle",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 3
          }
        ]
      },
    ];
    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
