<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            fusePerfectScrollbar
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'GIFTEXP.HEADER' | translate }}
            </span>
            <a [routerLink]="'/GiftDonationContribution/save'"
                mat-raised-button
                style="margin-right: 20px;"
                class="mat-button dupl"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="s-16 mr-sm-4">create</mat-icon>
                <span>{{ 'COMMON.BUTTON_EDIT' | translate }}</span>
            </a>
        </div>
        <div class="content mb-48"
            fusePerfectScrollbar>
            <div class="course-step p-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">
                <div *ngIf="hasChildData==true"
                    style="margin-bottom: 15px;">
                    <span class="h2"
                        *ngIf="getData!=null"
                        style="color: #206030;">
                        {{ 'GIFTEXP.ITEM1' | translate }}
                    </span>
                    <table class="simple invoice-table">
                        <thead *ngIf="getData!=null">
                            <tr>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{getData.Header.Header1}}
                                </th>
                                <th class="text-right thNewline"
                                    style="color: #444444;">
                                    {{getData.Header.Header2}}
                                </th>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{getData.Header.Header3}}
                                </th>
                                <th class="thNewline"
                                    style="color: #444444;">
                                    {{getData.Header.Header4}}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let data of childData">
                                <td>
                                    {{data.SlNo}}
                                </td>
                                <td class="text-right">
                                    {{data.Amount}}
                                </td>
                                <td>
                                    {{data.Comment}}
                                </td>
                                <td>
                                    {{data.Description}}
                                </td>
                                <td>
                                    {{data.ProductionCost}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="step-navigation">
            <button mat-raised-button
                [routerLink]="'/LossDeductionsExpenses'"
                style="border: 2px solid #37793c;background-color: #ffffff;line-height: 30px;cursor: pointer; margin-right: 10px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>
            <button mat-raised-button
                [routerLink]="'/Documents'"
                style="border: 2px solid #37793c;background-color: #ffffff;line-height: 30px;
            cursor: pointer;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>
    </div>
</div>
