import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer/footer.component';
import { HomeLayoutComponent } from './homelayout.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PreloaderComponent } from './preloader/preloader.component';


@NgModule({
    declarations: [
        HomeLayoutComponent,
        FooterComponent,
        NavbarComponent,
        PreloaderComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule.forRoot(),
    ],
    exports: [
        HomeLayoutComponent
    ]
})

export class HomeLayoutModule
{
}
