


export class DirectorsShareholdingClass
{
  constructor(
    private _PerShareValue: number,
    private _NoOfShare: number,
    private _Amount: number,
  )
  {

  }

  public get PerShareValue(): number
  {
    return this._PerShareValue;
  }
  public set PerShareValue(x: number)
  {
    this._PerShareValue = x;
  }

  public get NoOfShare(): number
  {
    return this._NoOfShare;
  }
  public set NoOfShare(x: number)
  {
    this._NoOfShare = x;
  }

  public get Amount(): number
  {
    return this._Amount = Number(this.PerShareValue) * Number(this.NoOfShare);
  }
  public set Amount(x: number)
  {
    this._Amount = x;
  }
}
