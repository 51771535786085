import { Component, OnDestroy, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { EtinOtherService } from './Etin-Others.service';
import { Observable } from 'rxjs';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { util } from 'prismjs';
import Utils from 'app/util/utils';
import { FuseTranslationLoaderService, Locale } from '@fuse/services/translation-loader.service';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { bangla, english } from 'app/util/locale';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from 'app/auth';




@Component({
    selector: 'EtinOthers',
    templateUrl: './Etin-Others.component.html',
    styleUrls: ['./Etin-Others.component.scss']
})
export class EtinOthersComponent implements OnInit
{
    @Input() controls: BaseControl<string>[] = [];
    form: FormGroup;
    id: any;

    @Input() rStatus: boolean;
    @Input() nStatus: boolean;
    payLoad = '';
    getData: any;
    lang = '';
    CheckIfDisabledPerson: any;
    showCancel: boolean;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    selectedDisability: any;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {MatSnackBar} _matSnackBar
     * 
     */
    constructor(
        private _formControlService: EtinOtherService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private eventEmitterService: EventEmitterService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private back: BackButtonService,
        private _authService: AuthenticationService,

    )
    {
        this.meta.addTags([
            { name: 'description', content: 'digiTax E-TIN & Other Information' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax E-TIN & Other Information' }
        ]);
        this.setTitle('digiTax | E-TIN & Other Information');
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.id = this._route.snapshot.paramMap.get('id');
        this.lang = localStorage.getItem('selectedLanguage');
        let profileData = JSON.parse(localStorage.getItem('profileData'));

        if (profileData.incompleteprofile.includes('1'))
        {
            this.showCancel = false;
        }
        else { this.showCancel = true; }

        this._formControlService.getControlJson(this.id).subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            this.controls = data;
            this.form = this._formControlService.toFormGroup(this.controls);
            if (this.form.controls.DisablePerson.value == "False")
            {
                this.selectedDisability = false;
            }
            if (this.form.controls.DisablePerson.value == "True")
            {
                this.selectedDisability = true;
            }
            if (this.form.controls.DisablePerson.value == "")
            {
                this.selectedDisability = "";
            }
            // console.log(this.selectedDisability, 'selectedDisability');


        });
        // this._formControlService.getControls().subscribe((data: any) =>
        // {
        //     this.controls = data;
        //     this.form = this._formControlService.toFormGroup(this.controls);
        // });
        this.form = this._formControlService.toFormGroup(this.controls);
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

    }


    mySubmitExit(param): void
    {
        if (param == 'next')
        {
            if (this.selectedDisability == this.form.controls.DisablePerson.value)
            {
                this.OnSubmit(param);
            }
            else
            {
                this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                    disableClose: false
                });

                this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.ETIN.R_DISABILITY_CHANGE : bangla.data.ETIN.R_DISABILITY_CHANGE;

                this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

                this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

                this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

                this.confirmDialogRef.afterClosed().subscribe(result =>
                {
                    if (result)
                    {
                        this.OnSubmit(param);
                    }
                    this.confirmDialogRef = null;
                });
            }
        }

        if (param == 'exit')
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            if (this.selectedDisability == this.form.controls.DisablePerson.value)
            {
                this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;
            }
            else
            {
                this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.ETIN.R_DISABILITY_CHANGE_EXIT : bangla.data.ETIN.R_DISABILITY_CHANGE_EXIT;
            }

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param);
                }
                this.confirmDialogRef = null;
            });
        }
    }

    OnSubmit(param): void
    {
        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }
        if (!this.form.controls.stayed1.valid)
        {
            this.rStatus = true;
        }
        if (!this.form.controls.Nationality.valid)
        {
            this.nStatus = true;
        }

        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        let obj = this.form.getRawValue();

        if (obj.stayed1 == "1" && obj.stayed2 == "")
        {
            obj.ResidentStatus = '1';
        }
        if (obj.stayed1 == "1" && obj.stayed2 == null)
        {
            obj.ResidentStatus = '1';
        }
        if (obj.stayed1 == "2" && obj.stayed2 == "1")
        {
            obj.ResidentStatus = '2';
        }
        if (obj.stayed1 == "2" && obj.stayed2 == "2")
        {
            obj.ResidentStatus = '3';
        }
        if (obj.stayed1 == "2" && obj.stayed2 == "")
        {
            obj.ResidentStatus = '3';
        }
        if (obj.stayed1 == "2" && obj.stayed2 == null)
        {
            obj.ResidentStatus = '3';
        }

        if (obj.HasDisableChild == "")
        {
            obj.HasDisableChild = false;
        }

        if (obj.HasDisableChild == false)
        {
            if (obj.SpouseAvailExtendTaxExempt == "")
            {
                obj.SpouseAvailExtendTaxExempt = false;
            }
        }

        if (obj.DisablePerson == "")
        {
            obj.DisablePerson = false;
        }

        if (obj.WarWoundedFreedomFighter == "")
        {
            obj.WarWoundedFreedomFighter = false;
        }

        if (obj.ETIN == "0" || isNaN(Number(obj.ETIN)) == true)
        {
            obj.ETIN = "";
        }

        let stringed = JSON.stringify(obj);

        if (obj.ETIN.length != 12 || obj.ETIN == "")
        {
            this._spinner.hide();
            this.form.get("ETIN").setErrors({ 'incorrect': true });
            this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ETIN_12C : bangla.data.ETIN.R_ETIN_12C, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',

            });
        }
        else
        {
            if (obj.Circle == "")
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_CIRCLE : bangla.data.ETIN.R_CIRCLE, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',

                });
            } else
            {
                if (obj.Zone == "" || obj.Zone == null)
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ZONE : bangla.data.ETIN.R_ZONE, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',

                    });
                }
                else
                {
                    if (obj.Nationality == "" || obj.Nationality == null)
                    {
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_NAT_STATUS : bangla.data.ETIN.R_NAT_STATUS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',

                        });
                    }
                    else
                    {
                        if (obj.stayed1 == "" || obj.stayed1 == null)
                        {
                            this._spinner.hide();
                            this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_RES_STATUS : bangla.data.ETIN.R_RES_STATUS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',

                            });
                        }
                        else
                        {
                            if (obj.HasDisableChild == true && obj.NumberOfDisabledChildren == "")
                            {
                                this._spinner.hide();
                                this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_DISABLED_CHILDREN : bangla.data.ETIN.R_DISABLED_CHILDREN, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',

                                });

                            } else
                            {
                                if (obj.HasDisableChild == true && obj.NumberOfDisabledChildren == "0")
                                {
                                    this._spinner.hide();
                                    this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_DISABLED_CHILDREN : bangla.data.ETIN.R_DISABLED_CHILDREN, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                        verticalPosition: 'top',

                                    });

                                } else
                                {
                                    if (Utils.isEmptyObject(obj.ResidentialAreaId) || obj.ResidentialAreaId == "")
                                    {
                                        this._spinner.hide();
                                        this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_AREA_OF_RESIDENT : bangla.data.ETIN.R_AREA_OF_RESIDENT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',

                                        });
                                    }
                                    else
                                    {
                                        if (stringed != null)
                                        {
                                            this._formControlService.submitEtinJson(stringed)
                                                .subscribe((data: any) =>
                                                {
                                                    if (data != null)
                                                    {
                                                        this._spinner.hide();
                                                    }

                                                    if (data.isError === false)
                                                    {
                                                        console.log('submit succesfull');
                                                    }
                                                    else
                                                    {

                                                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                            verticalPosition: 'top',
                                                            duration: 5000
                                                        });


                                                    }
                                                    if (data.isError === false && data.responseCode == "200")
                                                    {
                                                        if (this.id != null)
                                                        {
                                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                verticalPosition: 'top',
                                                                duration: 5000
                                                            });
                                                        }
                                                        else
                                                        {
                                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                                verticalPosition: 'top',
                                                                duration: 5000
                                                            });
                                                        }
                                                        if (param == 'next')
                                                        {
                                                            this._router.navigate(['/PersonalInfo']);
                                                        }
                                                        if (param == 'exit')
                                                        {
                                                            this._authService.Logout();
                                                        }
                                                    }
                                                }, (error: any) =>
                                                {
                                                    console.log(error, 'error');
                                                });

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
