import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from 'app/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { ProfileListService } from 'app/pages/Profile/Profile-List/ProfileList.service';
import Utils from 'app/util/utils';
import { QuickPanelService } from 'app/layout/components/quick-panel/quick-panel.service';
import { forkJoin } from 'rxjs';


@Component({
    selector: 'login-home',
    templateUrl: './login-home.component.html',
    styleUrls: ['./login-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class LoginHomeComponent implements OnInit
{
    isLoggedInNav = false;
    userInfo: any;
    lu: any;

    loginForm: FormGroup;
    submitted = false;
    isLoginError = false;
    meassage: any;
    fieldTextType: boolean;
    url_D = '';
    url_P = '';
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _spinner: NgxSpinnerService,
        private _homeTranslationLoaderService: HomeTranslationLoaderService,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,
        private profileListService: ProfileListService,
        private _quickPanelService: QuickPanelService,
        private meta: Meta,
        private title: Title,
    )
    {
        if (localStorage.getItem("userToken") === null)
        {
            this.isLoggedInNav = false;
        }
        else
        {
            this.isLoggedInNav = true;
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        }
        this._spinner.hide();
        this.meta.addTags([
            { name: 'description', content: 'Login to digiTax' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'Login to digiTax, digiTax Login' }
        ]);
        this.setTitle('digiTax | Login');
        this._homeTranslationLoaderService.loadTranslations(english, bangla);
        this.lu = JSON.parse(localStorage.getItem('lastLogin'));
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true,
                    isLockedOpen: true,
                }
            }
        };
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._spinner.hide();
        this.fieldTextType = false;
        this.meassage = this._route.snapshot.paramMap.get('meassage');
        this.loginForm = this._formBuilder.group({
            // email: ['', [Validators.required, Validators.email]],
            // password: ['', Validators.required],
            // isRem: ['']

            email: ['', Validators.compose([Validators.required, Utils.emailPatternValidator()])],
            password: ['', Validators.required],
        });
    }

    toggleFieldTextType()
    {
        this.fieldTextType = !this.fieldTextType;
    }

    showtoast()
    {
        let errorToast: HTMLElement = document.getElementById("errorToast") as HTMLElement;
        errorToast.style.display = "none";
        // errorToast.setAttribute("style", "display: none;");
    }

    get loginFormControl()
    {
        return this.loginForm.controls;
    }

    Logout(): void
    {
        localStorage.removeItem('userToken');
        let lastLogin = {
            'lastUrl': this._router.url,
            'lastUser': localStorage.getItem('userName')
        };
        localStorage.setItem('lastLogin', JSON.stringify(lastLogin));
        localStorage.removeItem('userToken');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('profileData');
        localStorage.removeItem('userName');
        localStorage.removeItem('userRefToken');
        localStorage.removeItem('TokenInfo');
        localStorage.removeItem('AssesseeId');
        localStorage.removeItem('archiveReadiness');
        // localStorage.removeItem('SwitchedReturnID');
        localStorage.removeItem('stepMenu');
        localStorage.removeItem('IsCurrentYear');

        localStorage.setItem('selectedLanguage', 'en')
        window.location.href = `/home`;
    }

    OnSubmit(): void
    {
        this.submitted = true;
        if (this.loginForm.valid)
        {
            const userName = this.loginForm.get('email').value;
            const password = this.loginForm.get('password').value;
            // const isRem = this.loginForm.get('isRem').value;

            // debugger;
            this._spinner.show();
            // this._homeTranslationLoaderService.loadTranslations(english, bangla);

            this._authService.login(userName, password).subscribe((data: any) =>
            {
                // debugger;
                // console.log(data);

                // if (data != null && data.error == "false")
                // {
                //     this._spinner.hide();
                // }
                localStorage.setItem('userToken', data.access_token);
                localStorage.setItem('userRefToken', data.refresh_token);

                delete data['access_token'];
                delete data['refresh_token'];
                delete data['token_type'];
                delete data['expires_in'];
                delete data['error'];
                delete data['.issued'];
                delete data['.expires'];

                // if (isRem === true)
                // {
                //     localStorage.setItem('userInfo', JSON.stringify(data));
                //     localStorage.setItem('TokenInfo', data.UserId);
                // }
                // else
                // {
                //     localStorage.setItem('userInfo', JSON.stringify(data));
                //     localStorage.setItem('TokenInfo', data.UserId);
                // }

                localStorage.setItem('userInfo', JSON.stringify(data));
                localStorage.setItem('TokenInfo', data.UserId);
                localStorage.setItem('userName', data.UserName);


                if (data.ActiveRoleId == "8")
                {
                    this.getPanelData();
                    this.isLoginError = false;
                    // if (lu == null)
                    // {
                    //     this.url_D = '/Dashboard';
                    //     this.url_P = '/Dashboard';
                    // }
                    // else 
                    // {
                    //     if (data.UserName == lu.lastUser)
                    //     {
                    //         this.url_D = lu.lastUrl;
                    //         this.url_P = lu.lastUrl;
                    //     }
                    //     else
                    //     {
                    //         this.url_D = '/Dashboard';
                    //         this.url_P = '/Dashboard';
                    //         localStorage.removeItem('lastLogin');
                    //         localStorage.removeItem('profileData');
                    //     }
                    // }
                    // window.location.href.includes('http://localhost') ? window.location.href = this.url_D : window.location.href = this.url_P;
                    // this.isLoginError = false;
                }
                if (data.ActiveRoleId == "7")
                {
                    this.checkProfileData();
                    localStorage.removeItem('lastLogin');
                    localStorage.removeItem('profileData');
                    this.isLoginError = false;
                }
            },
                (err: HttpErrorResponse) =>
                {
                    console.log(err, 'err');

                    if (err.error.error_description == "Activate your account using link sent to your registered email.")
                    {
                        this._spinner.hide();
                        this._router.navigate(['/mail-confirm', userName]);
                    }
                    else
                    {
                        this._spinner.hide();
                        this.isLoginError = true;
                    }


                    // let errorToast: HTMLElement = document.getElementById("errorToast") as HTMLElement;
                    // errorToast.style.display = "block";
                }
                // ,
                // (error: any) =>
                // {
                //     this._spinner.hide();
                //     this.isLoginError = true;
                // }
            );
        }
    }

    getPanelData()
    {
        forkJoin([this._quickPanelService.getProfileCompleteJson(), this._quickPanelService.getArchiveReadiness()]).subscribe(
            (result: any) =>
            {
                let profileResponse = result[0];
                let archiveResponse = result[1];

                if (Utils.isEmptyObject(archiveResponse.data) || archiveResponse.data == null)
                {
                    console.log('archive data get error');

                } else
                {
                    let ar = JSON.stringify(archiveResponse.data);
                    localStorage.setItem('archiveReadiness', ar);
                }
                if (profileResponse.data.checkprofile != null)
                {
                    let pd = JSON.stringify(profileResponse.data.checkprofile);
                    localStorage.setItem('profileData', pd);
                    let uInfo = JSON.parse(localStorage.getItem('userInfo'));
                    // console.log(uInfo, 'uInfo');
                    if (this.lu == null)
                    {
                        this.url_D = '/Dashboard';
                        this.url_P = '/Dashboard';
                    }
                    else 
                    {
                        if (uInfo.UserName == this.lu.lastUser)
                        {
                            this.url_D = this.lu.lastUrl;
                            this.url_P = this.lu.lastUrl;
                        }
                        else
                        {
                            this.url_D = '/Dashboard';
                            this.url_P = '/Dashboard';
                            localStorage.removeItem('lastLogin');
                            localStorage.removeItem('profileData');
                        }
                    }
                    // this._spinner.hide();
                    window.location.href.includes('http://localhost') ? window.location.href = this.url_D : window.location.href = this.url_P;
                };
            }, (error: any) =>
        {
            this._spinner.hide();
            console.log(error, 'error');
        })
        // this._quickPanelService.getProfileCompleteJson().subscribe((data: any) =>
        // {

        //     if (data.data.checkprofile != null)
        //     {
        //         let pd = JSON.stringify(data.data.checkprofile);
        //         localStorage.setItem('profileData', pd);
        //         let uInfo = JSON.parse(localStorage.getItem('userInfo'));
        //         console.log(uInfo, 'uInfo');

        //         this._quickPanelService.getArchiveReadiness().subscribe((data: any) =>
        //         {
        //             console.log(data, 'data');
        //         })


        //         if (this.lu == null)
        //         {
        //             this.url_D = '/Dashboard';
        //             this.url_P = '/Dashboard';
        //         }
        //         else 
        //         {
        //             if (uInfo.UserName == this.lu.lastUser)
        //             {
        //                 this.url_D = this.lu.lastUrl;
        //                 this.url_P = this.lu.lastUrl;
        //             }
        //             else
        //             {
        //                 this.url_D = '/Dashboard';
        //                 this.url_P = '/Dashboard';
        //                 localStorage.removeItem('lastLogin');
        //                 localStorage.removeItem('profileData');
        //             }
        //         }
        //         // this._spinner.hide();
        //         window.location.href.includes('http://localhost') ? window.location.href = this.url_D : window.location.href = this.url_P;
        //     };
        // }, (error: any) =>
        // {
        //     this._spinner.hide();
        //     console.log(error, 'error');
        // });
    }

    getPanelData_OLD()
    {
        this._quickPanelService.getProfileCompleteJson().subscribe((data: any) =>
        {

            if (data.data.checkprofile != null)
            {
                let pd = JSON.stringify(data.data.checkprofile);
                localStorage.setItem('profileData', pd);
                let uInfo = JSON.parse(localStorage.getItem('userInfo'));
                // console.log(uInfo, 'uInfo');

                if (this.lu == null)
                {
                    this.url_D = '/Dashboard';
                    this.url_P = '/Dashboard';
                }
                else 
                {
                    if (uInfo.UserName == this.lu.lastUser)
                    {
                        this.url_D = this.lu.lastUrl;
                        this.url_P = this.lu.lastUrl;
                    }
                    else
                    {
                        this.url_D = '/Dashboard';
                        this.url_P = '/Dashboard';
                        localStorage.removeItem('lastLogin');
                        localStorage.removeItem('profileData');
                    }
                }
                // this._spinner.hide();
                window.location.href.includes('http://localhost') ? window.location.href = this.url_D : window.location.href = this.url_P;
            };
        }, (error: any) =>
        {
            this._spinner.hide();
            console.log(error, 'error');
        });
    }


    checkProfileData(): void
    {
        this.profileListService.getControlJson().subscribe((data: any) =>
        {
            // if (data != null)
            // {
            //     this._spinner.hide();
            // }
            if (Utils.isEmptyObject(data.data) || data.data == null)
            {
                this.url_D = '/Profile';
                this.url_P = '/Profile';
                window.location.href.includes('http://localhost') ? window.location.href = this.url_D : window.location.href = this.url_P;
            }
            else
            {
                this.url_D = '/Clients';
                this.url_P = '/Clients';
                window.location.href.includes('http://localhost') ? window.location.href = this.url_D : window.location.href = this.url_P;
            }
        });
    }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event)
    // {
    //     this.back.setParam(true, 'fuse');
    // }
}
