import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { BackButtonService } from 'app/services/BackButtonService';
import { AuthenticationService } from 'app/auth';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { bangla, english } from 'app/util/locale';
import { HttpErrorResponse } from '@angular/common/http';
import Utils from 'app/util/utils';

@Component({
    selector: 'forgot-password-home',
    templateUrl: './forgot-password-home.component.html',
    styleUrls: ['./forgot-password-home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordHomeComponent implements OnInit
{
    forgotPasswordForm: FormGroup;
    submitted = false;
    isLoginError = false;


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private eventEmitterService: EventEmitterService,
        private _homeTranslationLoaderService: HomeTranslationLoaderService,
        private back: BackButtonService,
        private meta: Meta,
        private title: Title,
    )
    {
        this._spinner.hide();
        this.meta.addTags([
            { name: 'description', content: 'Forgot Your Password?' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax Forgot Your Password, digiTax Password change' }
        ]);
        this.setTitle('digiTax | Forgot Your Password?');
        this._homeTranslationLoaderService.loadTranslations(english, bangla);

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', Validators.compose([Validators.required, Utils.emailPatternValidator()])],
        });
    }

    get forgotPasswordFormControl()
    {
        return this.forgotPasswordForm.controls;
    }

    OnSubmit(): void
    {
        this.submitted = true;
        if (this.forgotPasswordForm.valid)
        {
            const Email = this.forgotPasswordForm.get('email').value;

            this._spinner.show();
            // this._fuseTranslationLoaderService.loadTranslations(english, bangla);

            this._authService.forgotPassword(Email).subscribe((data: any) =>
            {
                if (data != null && data.Error == false)
                {
                    this._spinner.hide();
                    this.isLoginError = false;
                    this.forgotPasswordForm.reset();
                    this._router.navigate(['/password-confirm-home', Email]);
                }

            },
                (err: HttpErrorResponse) =>
                {
                    this._spinner.hide();
                    console.log(err, 'err');
                    this.isLoginError = true;
                },
                (error: any) =>
                {
                    this._spinner.hide();
                    console.log(error, 'error');
                    this.isLoginError = true;
                });
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

