<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            fusePerfectScrollbar
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">
            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'ETIN.HEADER' | translate }}
            </span>
        </div>
        <div class="content mb-48"
            fusePerfectScrollbar>
            <div class="course-step p-24">
                <form class="page-layout simple fullwidth"
                    fxLayout="column"
                    [formGroup]="form">
                    <div *ngFor="let control of controls"
                        class="form-row">
                        <etin-others-form-control [control]="control"
                            [rStatus]=rStatus
                            [nStatus]=nStatus
                            [form]="form">
                        </etin-others-form-control>
                    </div>
                </form>
            </div>
        </div>
        <div class="step-navigation"
            *ngIf="!showCancel">

            <button mat-raised-button
                style="margin-left: 10px;
                background-color: #37793c; border: 0; border-top-left-radius: 4px; border-bottom-left-radius: 4px; color: #ffffff; cursor: pointer;
                padding: 0 16px;
                line-height: 36px;"
                (click)="mySubmitExit('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>
            <button [matMenuTriggerFor]="sNeBtn"
                style=" background-color: #37793c; border: 0; border-radius: 4px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;
                cursor: pointer;
                line-height: 34px;">
                <mat-icon style="color: #ffffff;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit('exit')">
                    <span style="font-weight: bold; color: #37793c;">{{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}</span>
                </button>
            </mat-menu>
        </div>

        <div class="step-navigation"
            *ngIf="showCancel">
            <button mat-raised-button
                style="color: #444444 !important;"
                [routerLink]="'/EtinOthers'"
                class="mat-button mat-button-base primary-100 secondary-text">
                {{ 'COMMON.BUTTON_CANCEL' | translate }}
            </button>
            <button mat-raised-button
                style="margin-left: 10px;
                background-color: #37793c; border: 0; border-top-left-radius: 4px; border-bottom-left-radius: 4px; color: #ffffff; cursor: pointer;
                padding: 0 16px;
                line-height: 36px;"
                (click)="mySubmitExit('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>
            <button [matMenuTriggerFor]="sNeBtn"
                style=" background-color: #37793c; border: 0; border-radius: 4px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;
                cursor: pointer;
                line-height: 34px;">
                <mat-icon style="color: #ffffff;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit('exit')">
                    <span style="font-weight: bold; color: #37793c;">{{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}</span>
                </button>

            </mat-menu>
        </div>
    </div>
</div>
