<div [formGroup]="form"
  fxLayout="column wrap">
  <div [ngSwitch]="control.controlType"
    fxLayout="column wrap"
    fxLayoutAlign="start"
    fxFlex="100">

    <div class="h2 mb-24"
      style="margin-top:20px;color: #206030;"
      *ngSwitchCase="'label'">{{control.label}}</div>
    <div *ngSwitchCase="'textbox'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">
      <div fxLayoutAlign="space-between center"
        fxLayout="row wrap"
        fxFlex="100"
        style="margin-top: 10px;margin-bottom: 10px;">

        <span class="name"
          style="font-size: 15px;"
          fxFlex="50"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>

        <mat-form-field appearance="outline"
          *ngIf="control.key=='BusinessOrProfessionName'"
          class="{{control.readonly==true ? 'readonly' : ''}}"
          [id]="control.key"
          [attr.for]="control.key"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            class="leftA"
            tabindex={{control.tabindex}}
            autocomplete="off"
            (keyup)="onKey($event,control.key)"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
          *ngIf="control.key=='Address'"
          class="{{control.readonly==true ? 'readonly' : ''}}"
          [id]="control.key"
          [attr.for]="control.key"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            class="leftA"
            tabindex={{control.tabindex}}
            autocomplete="off"
            (keyup)="onKey($event,control.key)"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
          *ngIf="control.key!='BusinessOrProfessionName'&&control.key!='Address'"
          class="{{control.readonly==true ? 'readonly' : ''}}"
          [id]="control.key"
          [attr.for]="control.key"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            class="rightA"
            autocomplete="off"
            tabindex={{control.tabindex}}
            NumbersOnly
            type="text"
            decimals="100"
            negative="1"
            (click)="$event.target.select()"
            (keyup)="onKey($event,control.key)"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div *ngSwitchCase="'dropdown'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">
      <div fxLayoutAlign="space-between center"
        fxLayout="row wrap"
        fxFlex="100"
        style="margin-top: 10px;margin-bottom: 10px;">

        <span class="name"
          style="font-size: 15px;"
          fxFlex="50"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>

        <mat-form-field appearance="outline"
          [id]="control.key"
          [attr.for]="control.key"
          fxFlex="50">

          <mat-select *ngSwitchCase="'dropdown'"
            [ngClass]="{'invalid': form.get('BusinessOrProfessionType').invalid && rBusinessOrProfessionType}"
            [formControlName]="control.key"
            [id]="control.key">
            <mat-optgroup *ngFor="let group of control.options"
              [label]="group.label">
              <mat-option *ngFor="let item of group.items"
                [value]="item.key">
                {{item.value}}
              </mat-option>
            </mat-optgroup>
          </mat-select>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="'textboxGroup'"
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      style="margin-top: 10px;width: 100%;">
      <div *ngFor="let Source of control.children"
        fxLayoutAlign="start center"
        fxLayout="row wrap"
        style="margin-top: 10px;margin-bottom: 10px;">
        <div fxLayoutAlign="start space-between"
          fxLayout="column wrap">
          <div fxLayoutAlign="start center"
            [attr.for]="control.key"
            style="margin-top: 5px;margin-bottom: 5px;font-weight: bold;">{{Source.label}}
          </div>
          <mat-form-field appearance="outline"
            class="{{Source.readonly==true ? 'readonly' : ''}}"
            [id]="Source.key"
            *ngIf="Source.controlType=='textbox'"
            [attr.for]="Source.key"
            fxLayoutAlign="space-between center"
            fxLayout="row wrap">
            <input *ngSwitchCase="'textboxGroup'"
              (keyup)="onKey($event,control.key)"
              autocomplete="off"
              [formControlName]="Source.key"
              tabindex={{Source.tabindex}}
              [id]="Source.key"
              [type]="Source.type"
              matInput
              required={{control.required}}>
            <mat-error>{{Source.message}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'labelGroup'"
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="space-between start">
      <div *ngFor="let child of control.children"
        style="margin-top: 10px;margin-bottom: 10px;">

        <mat-label [attr.for]="child.key"
          fxLayoutAlign="start"
          style="width: 100%; font-weight: bold;color: #444444;font-size: 16px;">{{child.label}}
        </mat-label>

      </div>
    </div>
    <div *ngSwitchCase="'checkbox'"
      fxLayoutAlign="start"
      fxLayout="row wrap"
      fxFlex="100"
      style="margin-top: 10px;margin-bottom: 10px;">

      <span class="name"
        style="font-size: 15px;"
        [attr.for]="control.key">{{control.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip={{control.tooltip}}
          matSuffix>info
        </mat-icon></span>

      <mat-checkbox style="margin-left: 5px;"
        (change)="showOptions($event)"
        [formControlName]="control.key"
        [value]="control.value">{{control.tooltip}}</mat-checkbox>
    </div>
  </div>

</div>
