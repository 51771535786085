import { Component, OnInit } from "@angular/core";

import { bangla, english } from 'app/util/locale';
import { HomeTranslationLoaderService } from "../services/home-translation-loader.service";
@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
    currentYear: any;
    constructor(


        private _homeTranslationLoaderService: HomeTranslationLoaderService,
    
    
    
    
      )
      {
        this._homeTranslationLoaderService.loadTranslations(english, bangla);
    
      }

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
    }
}
