import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { bangla, english } from 'app/util/locale';
import Utils from 'app/util/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProPaymentService } from './ProPayment.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { AppUser } from 'app/auth';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ProPayment',
    templateUrl: './ProPayment.component.html',
    styleUrls: ['./ProPayment.component.scss']
})
export class ProPaymentComponent
{
    pform: FormGroup;

    showTaxAmountDiv: boolean;
    lang = '';
    errMessage = '';
    getData: any;
    user: AppUser;

    hasRead: boolean;
    showTerm: boolean;

    nCount: number = 5;
    // countMin: number = 5;
    // countMax: number = 10000000;

    nCountTier1: number = 5;
    nCountTier2: number = 0;
    nCountTier3: number = 0;
    nCountTier4: number = 0;

    nPlanPrice1 = 0;
    nPlanPrice2 = 0;
    nPlanPrice3 = 0;
    nPlanPrice4 = 0;

    nPriceTier2: number = 0;
    nPriceTier3: number = 0;
    nPriceTier4: number = 0;
    totalPrice: number = 0;


    hasTransaction: boolean;
    lastTransactionData: any;
    TranDate: any;

    showDetail = false;

    // CurrentPlan: any;

    // CurrentPlanName: any;

    CurrentPlan = 0;

    NoOfClientFiles = 0;

    CurrentPlanName = "free";
    // CurrentPlanName = "starter";
    // CurrentPlanName = "standard";
    // CurrentPlanName = "premium";

    AllowDownload: boolean;

    IsUpgraded: boolean;

    hasPlan: boolean;
    FreePlan: any;
    StarterPlan: any;
    StandardPlan: any;
    PremiumPlan: any;
    PremiumPlanSum: any;

    IsError: any;
    ErrorMessage: any;
    OrderId: any;
    PaymentStatus: any;
    form: FormGroup;

    dDate: any;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: ProPaymentService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private http: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
        private _formBuilder: FormBuilder,

        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,
    )
    {

        this.meta.addTags([
            { name: 'description', content: "digiTax User Payment" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax User Payment" }
        ]);
        this.setTitle("digiTax | User Payment");
        // this.form = this._formBuilder.group({
        //     count: [this.nCount.toString(), Validators.required,],
        // });

        this.pform = this._formBuilder.group({
            inputCount: [''],
        });
        // debugger;
        this.pform.patchValue({
            inputCount: ""
        });

        this.showTaxAmountDiv = false;
        this.hasRead = false;
        this.showTerm = false;
        this.dDate = moment(new Date()).format("DD-MM-YYYY hh:mm A");
        // console.log(this.dDate, 'dDate');




    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.lang = localStorage.getItem('selectedLanguage');
        this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }
        if (this.lang == 'en')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        if (this.lang == 'bn')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        // this._spinner.show();
        // this.checkProfileData();
        // this.getData = this._formControlService.getJson();
        // console.log(this.getData, 'getData');
        // this.pform.reset();
        this.getPageData();

    }

    getPageData(): void
    {
        // let stringed = null;
        this._formControlService.getControlJson()
            .subscribe((data: any) =>
            {
                // console.log(data, 'data');
                if (data != null)
                {
                    this._spinner.hide();
                }
                if (data.isError === false)
                {
                    this.getData = data.data;

                    // this.CurrentPlan = data.data.CurrentPlan;
                    // this.CurrentPlanName = data.data.CurrentPlanName;
                    // this.AllowDownload = data.data.AllowDownload;
                    // this.IsUpgraded = data.data.IsUpgraded;
                    this.NoOfClientFiles = data.data.NoOfClientFiles;

                    if (Utils.isEmptyObject(data.data.Transactions))
                    {
                        this.hasTransaction = false;
                    }
                    else
                    {
                        this.hasTransaction = true;
                        this.lastTransactionData = data.data.Transactions[0];
                        this.TranDate = moment(this.lastTransactionData.TranDate).format("DD-MM-YYYY, hh:mm A");
                    }

                    if (data.data.Plans != null)
                    {
                        // this.hasPlan = true;
                        // this.FreePlan = data.data.Plans[0];
                        // this.StarterPlan = data.data.Plans[1];
                        // this.StandardPlan = data.data.Plans[2];
                        // this.PremiumPlan = data.data.Plans[3];
                        // this.PremiumPlanSum = this.PremiumPlan.PlanPrice + this.PremiumPlan.TaxAmount + this.PremiumPlan.BankCharge;

                        this.nCountTier1 = data.data.Plans[0].NoOfClientFiles;
                        this.nCountTier2 = data.data.Plans[1].NoOfClientFiles;
                        this.nCountTier3 = data.data.Plans[2].NoOfClientFiles;
                        this.nCountTier4 = data.data.Plans[3].NoOfClientFiles;


                        this.nPlanPrice1 = parseInt(data.data.Plans[0].PlanPrice);
                        this.nPlanPrice2 = parseInt(data.data.Plans[1].PlanPrice);
                        this.nPlanPrice3 = parseInt(data.data.Plans[2].PlanPrice);
                        this.nPlanPrice4 = parseInt(data.data.Plans[3].PlanPrice);
                    }
                }
                else
                {
                    console.log('error');
                    this._spinner.hide();
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            });
    }

    OnSubmit(): void
    {
        if (this.hasRead == false)
        {
            this._matSnackBar.open("Please confirm that you are aware of our Terms & Conditions", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (this.nCount == this.nCountTier1)
            {
                this._matSnackBar.open("You are on free plan, Please enter how many more client files you want to create.", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                this._spinner.show();

                let stringed = {
                    "NoOfClientFiles": this.nCount - this.nCountTier1
                };
                // console.log(stringed, 'stringed');


                this._formControlService.TAX_PAYMENT_INITIATEPAYMENT_Url(stringed)
                    .subscribe((data: any) =>
                    {
                        if (data != null)
                        {
                            this._spinner.hide();
                        }

                        if (data.isError === false)
                        {
                            if (data.data.status == "SUCCESS")
                            {
                                // debugger;
                                // this.pform.reset();
                                (document.getElementById("inputCount") as HTMLInputElement).value = "";
                                // this.pform.patchValue({
                                //     inputCount: ""
                                // });
                                window.location.href = data.data.GatewayPageURL;
                                // window.open(data.data.GatewayPageURL, "_blank");
                            }
                            else
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                        }
                        else
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }

                    }, (error: any) =>
                    {
                        console.log(error, 'error');
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    });

            }
        }

    }

    nCountPlus(): void
    {
        // if (this.nCount >= this.countMin)
        // {
        this.nCount += 1;
        this.TierCount();
        // }
    }

    nCountMinus(): void
    {
        // if (this.nCount != this.countMin && this.nCount != 0)
        if (this.nCount != 0 && this.nCount != 5)
        {
            this.nCount -= 1;
            this.TierCount();
        }
        else
        {
            this.showErrorMessage();
        }
    }

    ShowDetails(): void
    {
        this.showDetail = !this.showDetail;
    }

    TierCount(): void
    {
        this.nCountTier2 = this.nCount - this.nCountTier1;
        if (this.nCountTier2 > 45)
        {
            this.nCountTier2 = 45;
        }
        // else this.nCountTier2 = 0;
        if (this.nCount > 50)
        {
            this.nCountTier3 = this.nCount - 50;
            if (this.nCountTier3 > 50)
            {
                this.nCountTier3 = 50;
            }
        }
        if (this.nCount < 50)
        {
            this.nCountTier3 = 0;
        }
        if (this.nCount > 100)
        {
            this.nCountTier4 = this.nCount - 100;
        }
        else this.nCountTier4 = 0;

        if (this.nCount != 0)
        {
            this.nPriceTier2 = this.nCountTier2 * 250;
            this.nPriceTier3 = this.nCountTier3 * 200;
            this.nPriceTier4 = this.nCountTier4 * 150;
            this.totalPrice = this.nPriceTier2 + this.nPriceTier3 + this.nPriceTier4;
        }

    }

    onFocusoutEvent(event)
    {
        let inputCount: HTMLInputElement = document.getElementById("inputCount") as HTMLInputElement;
        // console.log(inputCount.value, 'inputCount');
        let n = Number(inputCount.value);
        // console.log(isNaN(n), 'inputCount');


        // if (Number(inputCount.value) < this.countMin)
        // if (Number(inputCount.value))
        // {
        //     // inputCount.value = this.countMin.toString();
        //     this.nCount = Number(inputCount.value);
        //     this.showErrorMessage();
        // }
        // else
        // {
        //     this.nCount = Number(inputCount.value);
        //     this.TierCount();
        // }


        if (n == 0)
        {
            this.nCount = 5;
            this.showErrorMessage();
        }
        else
        {
            this.nCount = Number(inputCount.value) + 5;
            this.TierCount();
        }


    }

    showErrorMessage(): void
    {
        this.errMessage = `Please enter more than 0`;
        this._matSnackBar.open(this.errMessage, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
            verticalPosition: 'top',
            duration: 2000
        });
        this.totalPrice = 0;
        this.nPriceTier2 = 0;
        this.nPriceTier3 = 0;
        this.nPriceTier4 = 0;

        this.nCountTier2 = 0;
        this.nCountTier3 = 0;
        this.nCountTier4 = 0;
    }

    showOptions(event, key)
    {
        if (key == "term")
        {
            if (event.checked == true)
            {
                this.hasRead = true;
            }
            if (event.checked == false)
            {
                this.hasRead = false;
            }
        }
    }

    ShowTerm()
    {
        this.showTerm = true;
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}


