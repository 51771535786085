import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransactionService } from './Transaction.service';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import Utils from 'app/util/utils';
import { AppUser } from 'app/auth';
import { Meta, Title } from '@angular/platform-browser';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSelect } from '@angular/material/select';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';
import { CreateBillDialog } from './create-bill-dialog/create-bill-Dialog.component';
import { CreateCollectionDialog } from './create-collection-dialog/create-collection-Dialog.component';
import { CreateRemissionDialog } from './create-remission-dialog/create-remission-Dialog.component';





@Component({
    selector: 'Transaction',
    templateUrl: './Transaction.component.html',
    styleUrls: ['./Transaction.component.scss']
})
export class TransactionComponent
{

    lang = '';

    BcTabgetData: any;
    RmTabgetData: any;
    BrTabgetData: any;
    DrTabgetData: any;
    CrTabgetData: any;
    LgTabgetData: any;
    BrTabTotalDues: any;
    DrTabTotalDues: any;
    CrTabTotalDues: any;
    LgTabTotalDues: any;

    CrTabTotalCollection: any;
    BrTabTotalBill: any;

    BcTabBillEnabled = false;
    BcTabCollectionEnabled = false;

    user: AppUser;
    BcTabMainForm: FormGroup;
    RmTabMainForm: FormGroup;


    lastYearItem: any;

    now = new Date();
    firstDay = new Date(this.now.getFullYear(), this.now.getMonth(), 1);




    BcTabSearchControl = new FormControl();
    BcTabTaxYearControl = new FormControl();
    BcTabPayTypeControl = new FormControl();
    BcTabRemarksControl = new FormControl();
    BcTabDateControl = new FormControl(new Date());
    BcTabToDateControl = new FormControl(this.now);
    BcTabFromDateControl = new FormControl(this.firstDay);

    RmTabSearchControl = new FormControl();
    RmTabTaxYearControl = new FormControl();
    RmTabRemarksControl = new FormControl();
    RmTabDateControl = new FormControl(new Date());
    RmTabToDateControl = new FormControl(this.now);
    RmTabFromDateControl = new FormControl(this.firstDay);
    RmTabAutoSearchData: any;


    BrTabSearchControl = new FormControl();
    BrTabFromDateControl = new FormControl(this.firstDay);
    BrTabToDateControl = new FormControl(this.now);
    BrTabFromAmountControl = new FormControl();
    BrTabToAmountControl = new FormControl();
    BrTabAutoSearchData: any;

    DrTabSearchControl = new FormControl();
    DrTabFromDateControl = new FormControl(this.firstDay);
    DrTabToDateControl = new FormControl(this.now);
    DrTabFromAmountControl = new FormControl();
    DrTabToAmountControl = new FormControl();
    DrTabAutoSearchData: any;


    CrTabSearchControl = new FormControl();
    CrTabFromDateControl = new FormControl(this.firstDay);
    CrTabToDateControl = new FormControl(this.now);
    CrTabFromAmountControl = new FormControl();
    CrTabToAmountControl = new FormControl();
    CrTabPayTypeControl = new FormControl();
    CrTabAutoSearchData: any;


    LgTabSearchControl = new FormControl();
    LgTabFromDateControl = new FormControl(this.firstDay);
    LgTabToDateControl = new FormControl(new Date());
    LgTabAutoSearchData: any;


    filteredMovies: any;
    dropDownData: any = null;
    isLoading = false;
    errorMsg: string;
    minLengthTerm = 3;


    form: FormGroup;
    BcTabfilteredOptions: Observable<any[]>;
    RmTabfilteredOptions: Observable<any[]>;
    BrTabfilteredOptions: Observable<any[]>;
    DrTabfilteredOptions: Observable<any[]>;
    CrTabfilteredOptions: Observable<any[]>;
    LgTabfilteredOptions: Observable<any[]>;

    BcTabClientEtin: any = "";
    BcTabClientName: any = "";
    BcTabClientMobile: any = "";
    BcTabClientEmail: any = "";
    BcTabClientRef: any = "";


    RmTabClientEtin: any = "";
    RmTabClientName: any = "";
    RmTabClientMobile: any = "";
    RmTabClientEmail: any = "";
    RmTabClientRef: any = "";


    LgTabClientEtin: any = "";
    LgTabClientName: any = "";
    LgTabClientMobile: any = "";
    LgTabClientEmail: any = "";
    LgTabClientRef: any = "";


    BcTabTotalDues: any = "";
    BcTabTotalBill: any = "";
    BcTabTotalCollection: any = "";


    RmTabTotalDues: any = "";
    RmTabTotalBill: any = "";
    RmTabTotalRemission: any = "";

    maxDate = new Date();


    moment: any = moment;

    // @ViewChild('selector', { static: false }) selector: MatInput;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: TransactionService,
        private back: BackButtonService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private eventEmitterService: EventEmitterService,
        private _route: ActivatedRoute,
        private sidePanelSharedService: SidePanelSharedService,
        private _fuseNavigationService: FuseNavigationService,
        private _formBuilder: FormBuilder,
        private http: HttpClient,
        public dialog: MatDialog,

    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Organization Transaction" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Organization Transaction" }
        ]);
        this.setTitle("digiTax | Organization Transaction");
        // this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }

        // this.form = this._formBuilder.group({
        //     myControl: new FormControl(),
        // })
        this.lang = localStorage.getItem('selectedLanguage');
        if (this.lang == 'en')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        if (this.lang == 'bn')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this._spinner.show();
        this.getMainDropData();
        this.SearchCollectionAuto();
        // this._spinner.hide();

        // this.getPageData();

        // this.myControl.valueChanges
        //     .pipe(
        //         filter(res =>
        //         {
        //             return res !== null && res.length >= this.minLengthTerm
        //         }),
        //         distinctUntilChanged(),
        //         debounceTime(1000),
        //         tap(() =>
        //         {
        //             this.errorMsg = "";
        //             this.filteredMovies = [];
        //             this.isLoading = true;
        //         }),
        //         switchMap(value => this._formControlService.ClientAutoComplete({ "q": value })
        //             .pipe(
        //                 finalize(() =>
        //                 {
        //                     this.isLoading = false
        //                 }),
        //             )
        //         )
        //     )
        //     .subscribe((data: any) =>
        //     {
        //         if (data['Search'] == undefined)
        //         {
        //             this.errorMsg = data['Error'];
        //             this.filteredMovies = [];
        //         } else
        //         {
        //             this.errorMsg = "";
        //             this.filteredMovies = data['Search'];
        //         }
        //         console.log(this.filteredMovies);
        //     });

        // let tempData = [];
        // TransactionDetailsList.forEach((item, index) =>
        // {
        //     tempData.push(this.gotItem(item));
        // });
        // this.mainForm = this._formBuilder.group({
        //     items: this._formBuilder.array(tempData),
        // });

        this.BcTabSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.BcTabfilteredOptions = response.data;
                        // console.log(response, 'response');

                    });
                }
                else
                {
                    return null;
                }
            })

        this.RmTabSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.RmTabfilteredOptions = response.data;
                    });
                }
                else
                {
                    this.RmTabAutoSearchData = null;
                    return null;
                }
            })

        this.BrTabSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.BrTabfilteredOptions = response.data;
                        // console.log(response, 'response');

                    });
                }
                else
                {
                    this.BrTabAutoSearchData = null;
                    return null;
                }
            })

        this.DrTabSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.DrTabfilteredOptions = response.data;
                        // console.log(response, 'response');

                    });
                }
                else
                {
                    this.DrTabAutoSearchData = null;
                    return null;
                }
            })


        this.CrTabSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.CrTabfilteredOptions = response.data;
                        // console.log(response, 'response');

                    });
                }
                else
                {
                    this.CrTabAutoSearchData = null;
                    return null;
                }
            })


        this.LgTabSearchControl.valueChanges
            .subscribe(value =>
            {
                if (value.length >= 1)
                {
                    let query = { "q": value }
                    this._formControlService.ClientAutoComplete(query).subscribe(response =>
                    {
                        this.LgTabfilteredOptions = response.data;
                        // console.log(response, 'response');

                    });
                }
                else
                {
                    this.LgTabAutoSearchData = null;
                    return null;
                }
            })
    }

    BcTabMainFormitems(): FormArray
    {
        return this.BcTabMainForm.get("items") as FormArray
    }

    RmTabMainFormitems(): FormArray
    {
        return this.RmTabMainForm.get("items") as FormArray
    }

    BcTabOnFocusoutEvent(event, key, i)
    {
        let rawForm = this.BcTabMainForm.getRawValue();

        let Bill = null;

        rawForm.items[i].Bill == '' ?
            Bill = 0 : Bill = rawForm.items[i].Bill;

        Bill = Number(Bill);

        let Collection = null;

        rawForm.items[i].Collection == '' ?
            Collection = 0 : Collection = rawForm.items[i].Collection;

        Collection = Number(Collection);


        // let Dues = null;

        // rawForm.items[i].Dues == '' ?
        //     Dues = 0 : Dues = rawForm.items[i].Dues;

        // Dues = Number(Dues);

        // console.log(this.BcTabgetData.TransactionDetailsList[i]);


        // if (key == "Bill" || key == "Collection")
        // {
        //     ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(Bill - Collection);



        // }

        if (key == "Bill")
        {

            let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            let duesCal = Dues + Bill;

            // ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(duesCal);

            // if (Bill == 0 || Bill == "")
            // {
            //     ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Bill').patchValue(this.BcTabgetData.TransactionDetailsList[i].Bill);
            // }
            this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);

        }

        if (key == "Collection")
        {
            let Dues = Number(this.BcTabgetData.TransactionDetailsList[i].Dues);
            let duesCal = Dues - Collection;
            // ((this.BcTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(duesCal);
            this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);


        }
        // this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0) - this.BcTabgetData.TotalRemission;

        // this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);
        // this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);
        // this.BcTabTotalDues = this.BcTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);


    }

    RmTabOnFocusoutEvent(event, key, i)
    {
        let rawForm = this.RmTabMainForm.getRawValue();

        let Dues = null;

        rawForm.items[i].Dues == '' ?
            Dues = 0 : Dues = rawForm.items[i].Dues;
        Dues = Number(Dues);


        let Remission = null;

        rawForm.items[i].Remission == '' ?
            Remission = 0 : Remission = rawForm.items[i].Remission;
        Remission = Number(Remission);

        // if (Dues == 0)
        // {
        //     ((this.RmTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Remission').patchValue("0");
        //     this._matSnackBar.open(this.lang == 'en' ? "This row does not have any dues" : "This row does not have any dues", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //         verticalPosition: 'top',
        //         duration: 2000
        //     });

        // }

        // if (key == "Remission")
        // {
        // console.log(Dues, 'Dues');
        // console.log(Remission, 'Remission');

        // let TempDues = Dues - Remission;
        // console.log(TempDues, 'TempDues');

        // ((this.RmTabMainForm.get('items') as FormArray).at(i) as FormGroup).get('Dues').patchValue(TempDues);

        // }



        this.RmTabTotalDues = this.RmTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);

        this.RmTabTotalRemission = this.RmTabMainForm.value.items.reduce((n, { Remission }) => n + Number(Remission), 0);

        // console.log(this.RmTabTotalDues,'RmTabTotalDues');

        // console.log(this.RmTabTotalRemission,'RmTabTotalRemission');


        // this.RmTabTotalBill = Number(this.RmTabTotalDues) - Number(this.RmTabTotalRemission);

        // console.log(this.RmTabTotalBill,'RmTabTotalBill');

        // let Collection = null;

        // rawForm.items[i].Collection == '' ?
        //     Collection = 0 : Collection = rawForm.items[i].Collection;
        // Collection = Number(Collection);




    }

    BcTabSearchClient(value): void
    {
        this._spinner.show();

        this.BcTabSearchControl.disable();


        let query = {
            "AssesseId": value.AssesseeId,
            "ReturnId": value.ReturnId,
            // "TransactionDate": moment(this.BcTabDateControl.value).format("YYYY-MM-DD"),
            "DateRange": `${moment(this.BcTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.BcTabToDateControl.value).format("YYYY/MM/DD")}`,
            "AssessmentYearId": this.BcTabTaxYearControl.value
        }

        // console.log(query, 'query');

        this._formControlService.SearchTransaction(query).subscribe(data =>
        {
            // console.log(data, 'SearchTransaction');

            if (data != null)
            {
                this.BcTabgetData = data.data;

                this.BcTabClientEtin = data.data.ETIN;
                this.BcTabClientName = data.data.AssesseName;
                this.BcTabClientMobile = data.data.ContactNo;
                this.BcTabClientEmail = data.data.Email;
                this.BcTabClientRef = data.data.Reference;

                this.BcTabTotalDues = data.data.TotalDues;
                this.BcTabTotalBill = data.data.TotalBill;
                this.BcTabTotalCollection = data.data.TotalCollection;


                if (data.data.PaymentTypeId != null)
                {
                    this.BcTabPayTypeControl.setValue(data.data.PaymentTypeId.toString());
                }

                this.BcTabRemarksControl.setValue(data.data.Remarks);

                let tempData = [];
                data.data.TransactionDetailsList.forEach((item, index) =>
                {
                    tempData.push(this.gotItem(item));
                });
                this.BcTabMainForm = this._formBuilder.group({
                    items: this._formBuilder.array(tempData),
                });
                this._spinner.hide();

            } else
            {
                this._spinner.hide();

                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }



        });

    }

    // RmTabSearchClient(value): void
    // {
    //     this._spinner.show();

    //     this.RmTabSearchControl.disable();


    //     let query = {
    //         "AssesseId": value.AssesseId,
    //         "ReturnId": value.ReturnId,
    //         // "TransactionDate": moment(this.RmTabDateControl.value).format("YYYY-MM-DD"),
    //         "DateRange": `${moment(this.RmTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.RmTabToDateControl.value).format("YYYY/MM/DD")}`,
    //         "AssessmentYearId": this.RmTabTaxYearControl.value
    //     }

    //     // console.log(query, 'query');

    //     this._formControlService.SearchTransaction(query).subscribe(data =>
    //     {
    //         // console.log(data, 'SearchTransaction');

    //         if (data != null)
    //         {
    //             this.RmTabgetData = data.data;

    //             this.RmTabClientEtin = data.data.ETIN;
    //             this.RmTabClientName = data.data.AssesseName;
    //             this.RmTabClientMobile = data.data.ContactNo;
    //             this.RmTabClientEmail = data.data.Email;
    //             this.RmTabClientRef = data.data.Reference;

    //             this.RmTabTotalDues = data.data.TotalDues;
    //             this.RmTabTotalBill = data.data.TotalBill;
    //             this.RmTabTotalRemission = data.data.TotalRemission;




    //             this.RmTabRemarksControl.setValue(data.data.Remarks);

    //             let tempData = [];
    //             data.data.TransactionDetailsList.forEach((item, index) =>
    //             {
    //                 tempData.push(this.gotItem(item));
    //             });
    //             this.RmTabMainForm = this._formBuilder.group({
    //                 items: this._formBuilder.array(tempData),
    //             });
    //             this._spinner.hide();

    //         } else
    //         {
    //             this._spinner.hide();

    //             this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
    //                 verticalPosition: 'top',
    //                 duration: 2000
    //             });
    //         }



    //     });

    // }

    BrTabSearchClient(value): void
    {
        this.BrTabAutoSearchData = value;
        // console.log(this.BrTabAutoSearchData, 'BrTabAutoSearchData');

    }

    DrTabSearchClient(value): void
    {
        this.DrTabAutoSearchData = value;
        // console.log(this.DrTabAutoSearchData, 'DrTabAutoSearchData');

    }


    CrTabSearchClient(value): void
    {
        this.CrTabAutoSearchData = value;
        // console.log(this.CrTabAutoSearchData, 'CrTabAutoSearchData');

    }

    RmTabSearchClient(value): void
    {
        this.RmTabAutoSearchData = value;
        // console.log(this.DrTabAutoSearchData, 'DrTabAutoSearchData');

    }

    LgTabSearchClient(value): void
    {
        this.LgTabAutoSearchData = value;
        // console.log(this.LgTabAutoSearchData, 'LgTabAutoSearchData');

    }

    cancelBC(): void
    {
        this.BcTabgetData = null;
        this.BcTabMainForm.reset();
        this.BcTabSearchControl.setValue('');
        this.BcTabSearchControl.enable();
        this.BcTabDateControl.setValue(new Date());
        this.BcTabToDateControl.setValue(new Date());
        this.BcTabFromDateControl.setValue(new Date(new Date().getFullYear(), 0, 1));
        this.BcTabTaxYearControl.setValue(this.lastYearItem.key)
        this.BcTabPayTypeControl.setValue('');
        this.BcTabRemarksControl.setValue('');
        this.BcTabfilteredOptions = null;
        this.BcTabBillEnabled = false;
        this.BcTabCollectionEnabled = false;
        // console.log(this.BcTabgetData, 'this.BcTabgetData');
        // console.log(this.BcTabMainForm.value, 'this.BcTabMainForm');
        // console.log(this.BcTabSearchControl, 'this.BcTabSearchControl');

    }

    cancelCollectionRegister(): void
    {
        // this.CrTabgetData = null;
        this.CrTabSearchControl.setValue('');
        this.CrTabSearchControl.enable();
        this.CrTabToDateControl.setValue(this.now);
        this.CrTabFromDateControl.setValue(this.firstDay);
        this.CrTabPayTypeControl.setValue('');
        this.CrTabFromAmountControl.setValue('');
        this.CrTabToAmountControl.setValue('');
        this.CrTabfilteredOptions = null;
        this.CrTabAutoSearchData = null;
        // console.log(this.CrTabgetData, 'this.CrTabgetData');
        // console.log(this.CrTabMainForm.value, 'this.CrTabMainForm');
        // console.log(this.CrTabSearchControl, 'this.BcTabSearchControl');

    }

    cancelDuesRegister(): void
    {
        // this.DrTabgetData = null;
        this.DrTabSearchControl.setValue('');
        this.DrTabSearchControl.enable();
        this.DrTabToDateControl.setValue(this.now);
        this.DrTabFromDateControl.setValue(this.firstDay);
        this.DrTabFromAmountControl.setValue('');
        this.DrTabToAmountControl.setValue('');
        this.DrTabfilteredOptions = null;
        this.DrTabAutoSearchData = null;
        // console.log(this.DrTabgetData, 'this.DrTabgetData');
        // console.log(this.DrTabMainForm.value, 'this.DrTabMainForm');
        // console.log(this.DrTabSearchControl, 'this.BcTabSearchControl');

    }

    cancelBillRegister(): void
    {
        // this.BrTabgetData = null;
        this.BrTabSearchControl.setValue('');
        this.BrTabSearchControl.enable();
        this.BrTabToDateControl.setValue(this.now);
        this.BrTabFromDateControl.setValue(this.firstDay);
        this.BrTabFromAmountControl.setValue('');
        this.BrTabToAmountControl.setValue('');
        this.BrTabfilteredOptions = null;
        this.BrTabAutoSearchData = null;
        // console.log(this.BrTabgetData, 'this.BrTabgetData');
        // console.log(this.BrTabMainForm.value, 'this.BrTabMainForm');
        // console.log(this.BrTabSearchControl, 'this.BcTabSearchControl');

    }


    cancelRemissionRegister(): void
    {
        // this.RmTabgetData = null;
        this.RmTabSearchControl.setValue('');
        this.RmTabSearchControl.enable();
        this.RmTabToDateControl.setValue(this.now);
        this.RmTabFromDateControl.setValue(this.firstDay);
        this.RmTabfilteredOptions = null;
        this.RmTabAutoSearchData = null;
        this.RmTabTaxYearControl.setValue(this.lastYearItem.key);

        // console.log(this.RmTabgetData, 'this.RmTabgetData');
        // console.log(this.RmTabMainForm.value, 'this.RmTabMainForm');
        // console.log(this.RmTabSearchControl, 'this.BcTabSearchControl');

    }

    cancelLedgerRegister(): void
    {
        // this.LgTabgetData = null;
        this.LgTabSearchControl.setValue('');
        this.LgTabSearchControl.enable();
        this.LgTabToDateControl.setValue(this.now);
        this.LgTabFromDateControl.setValue(this.firstDay);
        this.LgTabfilteredOptions = null;
        this.LgTabAutoSearchData = null;

        // console.log(this.LgTabgetData, 'this.LgTabgetData');
        // console.log(this.LgTabMainForm.value, 'this.LgTabMainForm');
        // console.log(this.LgTabSearchControl, 'this.BcTabSearchControl');

    }


    cancelRM(): void
    {
        this.RmTabgetData = null;
        this.RmTabMainForm.reset();
        this.RmTabSearchControl.setValue('');
        this.RmTabSearchControl.enable();
        this.RmTabDateControl.setValue(new Date());
        this.RmTabToDateControl.setValue(new Date());
        this.RmTabFromDateControl.setValue(new Date(new Date().getFullYear(), 0, 1));
        this.RmTabTaxYearControl.setValue(this.lastYearItem.key);
        this.RmTabRemarksControl.setValue('');

        this.RmTabfilteredOptions = null;

        // console.log(this.RmTabgetData, 'this.RmTabgetData');
        // console.log(this.RmTabMainForm.value, 'this.RmTabMainForm');
        // console.log(this.RmTabSearchControl, 'this.RmTabSearchControl');

    }

    SaveBC(): void
    {

        let tb = Number(this.BcTabTotalBill) == 0;
        let tc = Number(this.BcTabTotalCollection) == 0;
        let tp = this.BcTabPayTypeControl.value == "" || this.BcTabPayTypeControl.value == null ? true : false;

        let goForit = false;

        if (this.BcTabBillEnabled == true && tb == false)
        {
            goForit = true;
        }

        if (this.BcTabCollectionEnabled == true && tc == false)
        {
            goForit = true;
        }

        if (this.BcTabBillEnabled == true && tb == false && this.BcTabCollectionEnabled == true && tc == false)
        {
            goForit = true;
        }

        if (tp)
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please select A payment Type" : "Please select A payment Type", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });

        }
        else
        {
            if (goForit == false)
            {
                this._matSnackBar.open(this.lang == 'en' ? "Please enter an amount" : "Please enter an amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {

                // let InitTotalDues = this.BcTabgetData.TransactionDetailsList.reduce((n, { Dues }) => n + Number(Dues), 0);

                // let InitTotalBill = this.BcTabgetData.TransactionDetailsList.reduce((n, { Bill }) => n + Number(Bill), 0);

                // let InitTotalCol = this.BcTabgetData.TransactionDetailsList.reduce((n, { Collection }) => n + Number(Collection), 0);

                // console.log(InitTotalDues, 'InitTotalDues');
                // console.log(InitTotalBill, 'InitTotalBill');
                // console.log(InitTotalCol, 'InitTotalCol');

                let flag = '';

                if (tb == false && this.BcTabBillEnabled)
                {
                    flag = 'Bill';
                }
                if (tc == false && this.BcTabCollectionEnabled)
                {
                    flag = 'Collection';
                }
                if (tb == false && tc == false && this.BcTabBillEnabled && this.BcTabCollectionEnabled)
                {
                    flag = 'Bill,Collection';
                }

                let child = this.BcTabMainForm.value.items.map(value =>
                ({
                    "TransactionId": this.BcTabgetData.Id,
                    "AccountHeadId": value.AccounHeadId,
                    // "Dues": value.Dues,
                    "Dues": 0,

                    "Bill": this.BcTabBillEnabled ? value.Bill : 0,
                    "Collection": this.BcTabCollectionEnabled ? value.Collection : 0,
                    "Remission": 0,
                    "Notes": value.Notes
                }));

                let query = {
                    "Id": 0,
                    "TransactionDate": moment(this.BcTabDateControl.value).format("YYYY-MM-DD"),
                    "ReturnId": this.BcTabgetData.ReturnId,
                    "AssesseeId": this.BcTabgetData.AssesseeId,
                    "AssessmentYearId": this.BcTabTaxYearControl.value,
                    "ReceiptNo": this.BcTabgetData.ReceiptNo,
                    "TotalDues": 0,
                    "TotalBill": this.BcTabBillEnabled ? this.BcTabTotalBill : 0,
                    "TotalCollection": this.BcTabCollectionEnabled ? this.BcTabTotalCollection : 0,
                    "TotalRemission": this.BcTabgetData.TotalRemission,
                    "PaymentTypeId": this.BcTabPayTypeControl.value,
                    "Remarks": this.BcTabRemarksControl.value,
                    "TransactionFlag": flag,
                    "PractitionerTransactionDetailsViewModels": child
                }
                // console.log(query, 'query');

                this._formControlService.SaveTransaction(query).subscribe(data =>
                {
                    this._spinner.show();
                    // console.log(data, 'data');
                    if (data.isError == false)
                    {
                        if (this.BcTabgetData.Id == 0)
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        } else
                        {
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                        this.cancelBC();
                        this._spinner.hide();
                    }
                    else
                    {
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                });

            }
            // }
        }
    }

    SaveRM(): void
    {
        // if (Number(this.RmTabTotalDues) == 0)
        // {
        //     this._matSnackBar.open(this.lang == 'en' ? "You do not have any dues" : "You do not have any dues", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //         verticalPosition: 'top',
        //         duration: 2000
        //     });

        // } else
        // {
        let child = this.RmTabMainForm.value.items.map(value =>
        ({
            "TransactionId": 0,
            "AccountHeadId": value.AccounHeadId,
            "Dues": 0,
            "Bill": 0,
            "Remission": value.Remission,
            "Notes": value.Notes
        }));

        let query = {
            "Id": 0,
            "TransactionDate": moment(this.RmTabDateControl.value).format("YYYY-MM-DD"),
            "ReturnId": this.RmTabgetData.ReturnId,
            "AssesseeId": this.RmTabgetData.AssesseeId,
            "AssessmentYearId": this.RmTabTaxYearControl.value,
            "ReceiptNo": this.RmTabgetData.ReceiptNo,
            "TotalDues": 0,
            "TotalBill": 0,
            "TotalRemission": this.RmTabTotalRemission,
            "PaymentTypeId": 1,
            "Remarks": this.RmTabgetData.Remarks,
            "PractitionerTransactionDetailsViewModels": child
        }
        // console.log(query, 'query');

        this._formControlService.SaveRemission(query).subscribe(data =>
        {
            this._spinner.show();
            // console.log(data, 'data');
            if (data.isError == false)
            {

                if (this.RmTabgetData.Id == 0)
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                } else
                {
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                this.cancelRM();
                this._spinner.hide();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        });
        // }
    }


    getMainDropData(): void
    {
        this._formControlService.GetCommomDropdown().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
                this.dropDownData = data;
                this.lastYearItem = this.dropDownData.AssessmentYears.at(-1);
                this.BcTabTaxYearControl.setValue(this.lastYearItem.key)
                this.RmTabTaxYearControl.setValue(this.lastYearItem.key)

            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        });
    }

    SearchBillAuto(): void
    {


        this._spinner.show();
        let query = {
            "AssesseId": 0,
            "ReturnId": 0,
            "DateRange": `${moment(this.firstDay).format("YYYY/MM/DD")}-${moment(this.now).format("YYYY/MM/DD")}`,
            "FromAmount": null,
            "ToAmount": null,
        };

        // debugger;
        // console.log(query, 'query');
        this._formControlService.BillRegisterSearchTransaction(query).subscribe(data =>
        {
            this._spinner.show();
            // console.log(data, 'data');

            if (data != null)
            {
                this.BrTabgetData = data.data;
                this.BrTabTotalDues = data.data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                this.BrTabTotalBill = data.data.reduce((n, { TotalBill }) => n + Number(TotalBill), 0);
                // console.log(this.BrTabTotalDues, 'this.BrTabTotalDues');

                this._spinner.hide();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }

        })




    }

    SearchBill(): void
    {

        let BrTabFromAmount = this.BrTabFromAmountControl.value;
        let BrTabToAmount = this.BrTabToAmountControl.value;

        if ((BrTabFromAmount != null || BrTabFromAmount != '') && (BrTabToAmount == null || BrTabToAmount == ''))
        {
            BrTabToAmount = 0;
        }

        if ((BrTabFromAmount == null || BrTabFromAmount == '') && (BrTabToAmount != null || BrTabToAmount != ''))
        {
            BrTabFromAmount = 0;
        }

        if (Number(BrTabToAmount) < Number(BrTabFromAmount))
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? "To Amount Has to be gretaer than From Amount" : "To Amount Has to be gretaer than From Amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        } else
        {
            this._spinner.show();
            let query = {
                // "AssesseId": this.BrTabAutoSearchData.AssesseeId,
                // "ReturnId": this.BrTabAutoSearchData.ReturnId,
                "AssesseId": this.BrTabAutoSearchData != null ? this.BrTabAutoSearchData.AssesseeId : 0,
                "ReturnId": this.BrTabAutoSearchData != null ? this.BrTabAutoSearchData.ReturnId : 0,
                "DateRange": `${moment(this.BrTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.BrTabToDateControl.value).format("YYYY/MM/DD")}`,
                // "FromAmount": this.BrTabFromAmountControl.value,
                // "ToAmount": this.BrTabToAmountControl.value,
                // "FromAmount": this.BrTabFromAmountControl.value != "" ? this.BrTabFromAmountControl.value : null,
                // "ToAmount": this.BrTabToAmountControl.value != "" ? this.BrTabToAmountControl.value : null,
                "FromAmount": BrTabFromAmount,
                "ToAmount": BrTabToAmount,
            };

            // debugger;
            // console.log(query, 'query');
            this._formControlService.BillRegisterSearchTransaction(query).subscribe(data =>
            {
                this._spinner.show();
                // console.log(data, 'data');

                if (data != null)
                {
                    this.BrTabgetData = data.data;
                    this.BrTabTotalDues = data.data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                    this.BrTabTotalBill = data.data.reduce((n, { TotalBill }) => n + Number(TotalBill), 0);
                    // console.log(this.BrTabTotalDues, 'this.BrTabTotalDues');

                    this._spinner.hide();
                }
                else
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }

            })

        }


    }

    SearchDuesAuto(): void
    {


        this._spinner.show();
        let query = {
            "AssesseId": 0,
            "ReturnId": 0,
            "DateRange": `${moment(this.firstDay).format("YYYY/MM/DD")}-${moment(this.now).format("YYYY/MM/DD")}`,
            "FromAmount": null,
            "ToAmount": null,
        };

        // console.log(query, 'query');
        this._formControlService.DuesSearchTransaction(query).subscribe(data =>
        {
            this._spinner.show();
            console.log(data, 'DuesSearchTransaction');

            if (data != null)
            {
                this.DrTabgetData = data.data;
                this.DrTabTotalDues = data.data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                // console.log(this.DrTabTotalDues, 'this.DrTabTotalDues');

                this._spinner.hide();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }

        })

        // }


    }

    SearchDues(): void
    {


        let DrTabFromAmount = this.DrTabFromAmountControl.value;
        let DrTabToAmount = this.DrTabToAmountControl.value;

        if ((DrTabFromAmount != null || DrTabFromAmount != '') && (DrTabToAmount == null || DrTabToAmount == ''))
        {
            DrTabToAmount = 0;
        }

        if ((DrTabFromAmount == null || DrTabFromAmount == '') && (DrTabToAmount != null || DrTabToAmount != ''))
        {
            DrTabFromAmount = 0;
        }

        if (Number(DrTabToAmount) < Number(DrTabFromAmount))
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? "To Amount Has to be gretaer than From Amount" : "To Amount Has to be gretaer than From Amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        } else
        {
            this._spinner.show();
            let query = {
                // "AssesseId": this.DrTabAutoSearchData.AssesseeId,
                // "ReturnId": this.DrTabAutoSearchData.ReturnId,
                "AssesseId": this.DrTabAutoSearchData != null ? this.DrTabAutoSearchData.AssesseeId : 0,
                "ReturnId": this.DrTabAutoSearchData != null ? this.DrTabAutoSearchData.ReturnId : 0,
                "DateRange": `${moment(this.DrTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.DrTabToDateControl.value).format("YYYY/MM/DD")}`,
                // "FromAmount": this.DrTabFromAmountControl.value,
                // "ToAmount": this.DrTabToAmountControl.value,
                // "FromAmount": this.DrTabFromAmountControl.value != "" ? this.DrTabFromAmountControl.value : null,
                // "ToAmount": this.DrTabToAmountControl.value != "" ? this.DrTabToAmountControl.value : null,
                "FromAmount": DrTabFromAmount,
                "ToAmount": DrTabToAmount,
            };

            // console.log(query, 'query');
            this._formControlService.DuesSearchTransaction(query).subscribe(data =>
            {
                this._spinner.show();
                // console.log(data, 'DuesSearchTransaction');

                if (data != null)
                {
                    this.DrTabgetData = data.data;
                    this.DrTabTotalDues = data.data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                    // console.log(this.DrTabTotalDues, 'this.DrTabTotalDues');

                    this._spinner.hide();
                }
                else
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }

            })

        }


    }

    SearchCollectionAuto(): void
    {

        this._spinner.show();
        let query = {
            "AssesseId": 0,
            "ReturnId": 0,
            "DateRange": `${moment(this.firstDay).format("YYYY/MM/DD")}-${moment(this.now).format("YYYY/MM/DD")}`,
            "FromAmount": null,
            "ToAmount": null,
            "PaymentType": 0,
        };
        // console.log(query, 'query');
        this._formControlService.CollectionRegisterSearchTransaction(query).subscribe(data =>
        {

            // console.log(data, 'CollectionRegisterSearchTransaction');

            if (data != null)
            {
                this.CrTabgetData = data.data;
                this.CrTabTotalDues = data.data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                this.CrTabTotalCollection = data.data.reduce((n, { TotalCollection }) => n + Number(TotalCollection), 0);

                // console.log(this.CrTabTotalDues, 'this.CrTabTotalDues');

                this._spinner.hide();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }

        })




    }


    SearchCollection(): void
    {

        let CrTabFromAmount = this.CrTabFromAmountControl.value;
        let CrTabToAmount = this.CrTabToAmountControl.value;

        if ((CrTabFromAmount != null || CrTabFromAmount != '') && (CrTabToAmount == null || CrTabToAmount == ''))
        {
            CrTabToAmount = 0;
        }

        if ((CrTabFromAmount == null || CrTabFromAmount == '') && (CrTabToAmount != null || CrTabToAmount != ''))
        {
            CrTabFromAmount = 0;
        }

        if (Number(CrTabToAmount) < Number(CrTabFromAmount))
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? "To Amount Has to be gretaer than From Amount" : "To Amount Has to be gretaer than From Amount", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        } else
        {
            this._spinner.show();
            let query = {
                // "AssesseId": this.CrTabAutoSearchData.AssesseeId,
                // "ReturnId": this.CrTabAutoSearchData.ReturnId,
                "AssesseId": this.CrTabAutoSearchData != null ? this.CrTabAutoSearchData.AssesseeId : 0,
                "ReturnId": this.CrTabAutoSearchData != null ? this.CrTabAutoSearchData.ReturnId : 0,
                "DateRange": `${moment(this.CrTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.CrTabToDateControl.value).format("YYYY/MM/DD")}`,
                // "FromAmount": this.CrTabFromAmountControl.value,
                // "ToAmount": this.CrTabToAmountControl.value,
                // "FromAmount": this.CrTabFromAmountControl.value != "" ? this.CrTabFromAmountControl.value : null,
                // "ToAmount": this.CrTabToAmountControl.value != "" ? this.CrTabToAmountControl.value : null,
                "FromAmount": CrTabFromAmount,
                "ToAmount": CrTabToAmount,
                "PaymentType": this.CrTabPayTypeControl.value,
            };

            // console.log(query, 'query');
            this._formControlService.CollectionRegisterSearchTransaction(query).subscribe(data =>
            {

                console.log(data, 'CollectionRegisterSearchTransaction');

                if (data != null)
                {
                    this.CrTabgetData = data.data;
                    this.CrTabTotalDues = data.data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                    this.CrTabTotalCollection = data.data.reduce((n, { TotalCollection }) => n + Number(TotalCollection), 0);

                    // console.log(this.CrTabTotalDues, 'this.CrTabTotalDues');

                    this._spinner.hide();
                }
                else
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }

            })

        }


    }


    SearchRemission(type): void
    {
        // if (this.RmTabAutoSearchData == null && type != 'auto')
        // {
        //     this._spinner.hide();
        //     this._matSnackBar.open(this.lang == 'en' ? "Please select a valid client" : "Please select a valid client", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //         verticalPosition: 'top',
        //         duration: 2000
        //     });
        // } else
        // {
        this._spinner.show();
        let query = {};
        if (type == 'auto')
        {
            query = {
                "AssesseId": 0,
                "ReturnId": 0,
                "DateRange": `${moment(this.firstDay).format("YYYY/MM/DD")}-${moment(this.now).format("YYYY/MM/DD")}`,
                "AssessmentYearId": 0
            };
        } else
        {
            query = {
                // "AssesseId": this.RmTabAutoSearchData.AssesseeId,
                // "ReturnId": this.RmTabAutoSearchData.ReturnId,
                "AssesseId": this.RmTabAutoSearchData != null ? this.RmTabAutoSearchData.AssesseeId : 0,
                "ReturnId": this.RmTabAutoSearchData != null ? this.RmTabAutoSearchData.ReturnId : 0,
                "DateRange": `${moment(this.RmTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.RmTabToDateControl.value).format("YYYY/MM/DD")}`,
                "AssessmentYearId": this.RmTabTaxYearControl.value
            };
        }
        // console.log(query, 'query');
        this._formControlService.SearchRemission(query).subscribe(data =>
        {

            // console.log(data, 'SearchRemission');

            if (data != null)
            {
                this.RmTabgetData = data.data;
                this.RmTabTotalDues = data.data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                this.RmTabTotalRemission = data.data.reduce((n, { TotalRemission }) => n + Number(TotalRemission), 0);
                // console.log(this.RmTabTotalDues, 'this.RmTabTotalDues');

                this._spinner.hide();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }

        })

        // }


    }


    SearchLedger(type): void
    {
        // if (this.LgTabAutoSearchData == null && type != 'auto')
        // {
        //     this._spinner.hide();
        //     this._matSnackBar.open(this.lang == 'en' ? "Please select a valid client" : "Please select a valid client", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //         verticalPosition: 'top',
        //         duration: 2000
        //     });
        // } else
        // {
        this._spinner.show();
        let query = {};
        if (type == 'auto')
        {
            query = {
                "AssesseId": 0,
                "ReturnId": 0,
                "DateRange": `${moment(this.firstDay).format("YYYY/MM/DD")}-${moment(this.now).format("YYYY/MM/DD")}`,
            }
        }
        else
        {
            query = {
                // "AssesseId": this.LgTabAutoSearchData.AssesseeId,
                // "ReturnId": this.LgTabAutoSearchData.ReturnId,
                "AssesseId": this.LgTabAutoSearchData != null ? this.LgTabAutoSearchData.AssesseeId : 0,
                "ReturnId": this.LgTabAutoSearchData != null ? this.LgTabAutoSearchData.ReturnId : 0,
                "DateRange": `${moment(this.LgTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.LgTabToDateControl.value).format("YYYY/MM/DD")}`
            }
        }
        // console.log(query, 'query');
        this._formControlService.LedgerSearchTransaction(query).subscribe(data =>
        {

            // console.log(data, 'data');

            if (data != null)
            {
                this.LgTabgetData = data.data;
                // this.LgTabTotalDues = data.reduce((n, { TotalDues }) => n + Number(TotalDues), 0);
                // console.log(this.LgTabTotalDues, 'this.LgTabTotalDues');

                this._spinner.hide();
            }
            else
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? "No data found" : "No data found", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }

        })

        // }


    }

    BrDownloadExcel()
    {
        let data = {
            "AssesseId": this.BrTabAutoSearchData != null ? this.BrTabAutoSearchData.AssesseeId : 0,
            "ReturnId": this.BrTabAutoSearchData != null ? this.BrTabAutoSearchData.ReturnId : 0,
            "DateRange": `${moment(this.BrTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.BrTabToDateControl.value).format("YYYY/MM/DD")}`,
            // "FromAmount": this.BrTabFromAmountControl.value,
            // "ToAmount": this.BrTabToAmountControl.value,
            "FromAmount": this.BrTabFromAmountControl.value != "" ? this.BrTabFromAmountControl.value : null,
            "ToAmount": this.BrTabToAmountControl.value != "" ? this.BrTabToAmountControl.value : null,
        };
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.post(Config.BILL_REGISTER_DOWNLOAD_EXCEL, data, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, `bill_Register_${moment(this.BrTabFromDateControl.value).format("YYYY-MM-DD")}-${moment(this.BrTabToDateControl.value).format("YYYY-MM-DD")}.xlsx`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            })
    }

    DrDownloadExcel()
    {
        let data = {
            "AssesseId": this.DrTabAutoSearchData != null ? this.DrTabAutoSearchData.AssesseeId : 0,
            "ReturnId": this.DrTabAutoSearchData != null ? this.DrTabAutoSearchData.ReturnId : 0,
            "DateRange": `${moment(this.DrTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.DrTabToDateControl.value).format("YYYY/MM/DD")}`,
            // "FromAmount": this.DrTabFromAmountControl.value,
            // "ToAmount": this.DrTabToAmountControl.value,
            "FromAmount": this.DrTabFromAmountControl.value != "" ? this.DrTabFromAmountControl.value : null,
            "ToAmount": this.DrTabToAmountControl.value != "" ? this.DrTabToAmountControl.value : null,
        };
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.post(Config.DUES_REGISTER_DOWNLOAD_EXCEL, data, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, `bill_Register_${moment(this.DrTabFromDateControl.value).format("YYYY-MM-DD")}-${moment(this.DrTabToDateControl.value).format("YYYY-MM-DD")}.xlsx`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            })
    }

    CrDownloadExcel()
    {
        let data = {
            "AssesseId": this.CrTabAutoSearchData != null ? this.CrTabAutoSearchData.AssesseeId : 0,
            "ReturnId": this.CrTabAutoSearchData != null ? this.CrTabAutoSearchData.ReturnId : 0,
            "DateRange": `${moment(this.CrTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.CrTabToDateControl.value).format("YYYY/MM/DD")}`,
            // "FromAmount": this.CrTabFromAmountControl.value,
            // "ToAmount": this.CrTabToAmountControl.value,
            "FromAmount": this.CrTabFromAmountControl.value != "" ? this.CrTabFromAmountControl.value : null,
            "ToAmount": this.CrTabToAmountControl.value != "" ? this.CrTabToAmountControl.value : null,
            "PaymentType": this.CrTabPayTypeControl.value,
        };
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.post(Config.COLLECTION_REGISTER_DOWNLOAD_EXCEL, data, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, `collection_Register_${moment(this.CrTabFromDateControl.value).format("YYYY-MM-DD")}-${moment(this.CrTabToDateControl.value).format("YYYY-MM-DD")}.xlsx`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            })
    }

    RmDownloadExcel()
    {
        let data = {
            "AssesseId": this.RmTabAutoSearchData != null ? this.RmTabAutoSearchData.AssesseeId : 0,
            "ReturnId": this.RmTabAutoSearchData != null ? this.RmTabAutoSearchData.ReturnId : 0,
            "DateRange": `${moment(this.RmTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.RmTabToDateControl.value).format("YYYY/MM/DD")}`,
            "AssessmentYearId": this.RmTabTaxYearControl.value
        };
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.post(Config.REMISSION_REGISTER_DOWNLOAD_EXCEL, data, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, `remission_Register_${moment(this.CrTabFromDateControl.value).format("YYYY-MM-DD")}-${moment(this.CrTabToDateControl.value).format("YYYY-MM-DD")}.xlsx`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            })
    }

    LgDownloadExcel()
    {
        let data = {
            "AssesseId": this.LgTabAutoSearchData != null ? this.LgTabAutoSearchData.AssesseeId : 0,
            "ReturnId": this.LgTabAutoSearchData != null ? this.LgTabAutoSearchData.ReturnId : 0,
            "DateRange": `${moment(this.LgTabFromDateControl.value).format("YYYY/MM/DD")}-${moment(this.LgTabToDateControl.value).format("YYYY/MM/DD")}`,
        };
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.post(Config.LEDGER_DOWNLOAD_EXCEL, data, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    saveAs(res, `ledger_Register_${moment(this.LgTabFromDateControl.value).format("YYYY-MM-DD")}-${moment(this.LgTabToDateControl.value).format("YYYY-MM-DD")}.xlsx`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            })
    }

    showBcTabBills(event)
    {
        if (event.checked == true)
        {
            this.BcTabBillEnabled = true;

            for (let index = 0; index < (this.BcTabMainForm.get('items') as FormArray).controls.length; index++)
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Bill').patchValue(0);
            }

            this.BcTabTotalBill = 0;
            if (this.BcTabCollectionEnabled == false)
            {
                this.BcTabTotalCollection = 0;
            }
            this.BcTabTotalDues = 0;


        }
        else
        {
            this.BcTabBillEnabled = false;

            this.BcTabgetData.TransactionDetailsList.forEach((item, index) =>
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Bill').patchValue(item.Bill);
            });

            this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);
            if (this.BcTabCollectionEnabled == false)
            {
                this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);
            }
            // this.BcTabTotalDues = this.BcTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);
        }


    }


    showBcTabCollections(event)
    {
        if (event.checked == true)
        {
            this.BcTabCollectionEnabled = true;
            for (let index = 0; index < (this.BcTabMainForm.get('items') as FormArray).controls.length; index++)
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Collection').patchValue(0);
            }


            if (this.BcTabBillEnabled == false)
            {
                this.BcTabTotalBill = 0;
            }
            this.BcTabTotalCollection = 0;
            this.BcTabTotalDues = 0;
        }
        else
        {
            this.BcTabCollectionEnabled = false;
            this.BcTabgetData.TransactionDetailsList.forEach((item, index) =>
            {
                ((this.BcTabMainForm.get('items') as FormArray).at(index) as FormGroup).get('Collection').patchValue(item.Collection);
            });
            if (this.BcTabBillEnabled == false)
            {
                this.BcTabTotalBill = this.BcTabMainForm.value.items.reduce((n, { Bill }) => n + Number(Bill), 0);
            }
            this.BcTabTotalCollection = this.BcTabMainForm.value.items.reduce((n, { Collection }) => n + Number(Collection), 0);
            // this.BcTabTotalDues = this.BcTabMainForm.value.items.reduce((n, { Dues }) => n + Number(Dues), 0);
        }


    }

    onSelected()
    {
        // console.log(this.selectedMovie);
        // this.selectedMovie = this.selectedMovie;
    }

    displayWith(value: any)
    {
        return value?.Title;
    }

    clearSelection()
    {
        // this.selectedMovie = "";
        this.filteredMovies = [];
    }



    gotItem(item): FormGroup
    {
        return this._formBuilder.group({

            MasterId: item.MasterId,
            ReturnId: item.ReturnId,
            AssesseeId: item.AssesseeId,
            AssessmentYearId: item.AssessmentYearId,
            AccounHeadId: item.AccounHeadId,
            AccounHeadtName: item.AccounHeadtName,
            Dues: item.Dues,
            Bill: item.Bill,
            Collection: item.Collection,
            Remission: item.Remission,
            Notes: item.Notes
        })
    }

    ChangedBillItem(item): FormGroup
    {
        return this._formBuilder.group({

            MasterId: item.MasterId,
            ReturnId: item.ReturnId,
            AssesseeId: item.AssesseeId,
            AssessmentYearId: item.AssessmentYearId,
            AccounHeadId: item.AccounHeadId,
            AccounHeadtName: item.AccounHeadtName,
            Dues: item.Dues,
            Bill: 0,
            Collection: item.Collection,
            Remission: item.Remission,
            Notes: item.Notes
        })
    }


    ChangedCollectionItem(item): FormGroup
    {
        return this._formBuilder.group({

            MasterId: item.MasterId,
            ReturnId: item.ReturnId,
            AssesseeId: item.AssesseeId,
            AssessmentYearId: item.AssessmentYearId,
            AccounHeadId: item.AccounHeadId,
            AccounHeadtName: item.AccounHeadtName,
            Dues: item.Dues,
            Bill: item.Bill,
            Collection: 0,
            Remission: item.Remission,
            Notes: item.Notes
        })
    }


    logForm(): void
    {
        // console.log(this.BcTabMainForm.value.items);
        // console.log(this.BcTabTaxYearControl.value);
        // console.log(this.BcTabDateControl.value);
        // console.log(moment(this.BcTabDateControl.value).format("YYYY-MM-DD"));


    }

    openBillDialog(): void
    {
        const dialogRef = this.dialog.open(CreateBillDialog, {
            disableClose: true
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            console.log(`Dialog result: ${result}`);
            if (result == 'updated')
            {
                this.cancelBillRegister();
                this.SearchBillAuto();
            }
        });
    }

    openCollectionDialog(): void
    {
        const dialogRef = this.dialog.open(CreateCollectionDialog, {
            disableClose: true
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'updated')
            {
                this.cancelCollectionRegister();
                this.SearchCollectionAuto();
            }
        });
    }


    openRemissionDialog(): void
    {
        const dialogRef = this.dialog.open(CreateRemissionDialog, {
            disableClose: true
        });


        dialogRef.afterClosed().subscribe(result =>
        {
            if (result == 'updated')
            {
                this.cancelRemissionRegister();
                this.SearchRemission('auto');
            }
        });
    }

    onChangeTab(event: MatTabChangeEvent)
    {

        // console.log(event.index);
        if (event.index == 0)
        {
            this.cancelCollectionRegister();
            this.SearchCollectionAuto();

        }
        if (event.index == 1)
        {
            this.cancelDuesRegister();
            this.SearchDuesAuto();

        }

        if (event.index == 2)
        {
            this.cancelBillRegister();
            this.SearchBillAuto();

        }

        if (event.index == 3)
        {
            this.cancelRemissionRegister();
            this.SearchRemission('auto');

        }

        if (event.index == 4)
        {
            this.cancelLedgerRegister();
            this.SearchLedger('auto');

        }



    }

    onFocusoutEvent(event, key)
    {
        if (key == 'CrTabFromAmountControl')
        {
            if (isNaN(Number(this.CrTabFromAmountControl.value)) == true)
            {
                this.CrTabFromAmountControl.setValue(0);
            }
        }

        if (key == 'CrTabToAmountControl')
        {
            if (isNaN(Number(this.CrTabToAmountControl.value)) == true)
            {
                this.CrTabToAmountControl.setValue(0);
            }
        }

        if (key == 'DrTabFromAmountControl')
        {
            if (isNaN(Number(this.DrTabFromAmountControl.value)) == true)
            {
                this.DrTabFromAmountControl.setValue(0);
            }
        }

        if (key == 'DrTabToAmountControl')
        {
            if (isNaN(Number(this.DrTabToAmountControl.value)) == true)
            {
                this.DrTabToAmountControl.setValue(0);
            }
        }

        if (key == 'BrTabFromAmountControl')
        {
            if (isNaN(Number(this.BrTabFromAmountControl.value)) == true)
            {
                this.BrTabFromAmountControl.setValue(0);
            }
        }

        if (key == 'BrTabToAmountControl')
        {
            if (isNaN(Number(this.BrTabToAmountControl.value)) == true)
            {
                this.BrTabToAmountControl.setValue(0);
            }
        }

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}







