import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseControl } from "app/models/dynamic-form";
import { of, Observable } from "rxjs";
import { RadioGroupControl } from "app/models/dynamic-form/radioGroup-control";
import Utils from "app/util/utils";
import Config from 'app/util/config';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';


@Injectable()
export class PersonalInformationListService
{
  constructor(private service: Service) { }


  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  getControlJson(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.BASIC_INFO_Personal_Information_List_Url);

  }



  getJson(): any
  {
    const jsonData = [
      {

        "head": "Personal Information",
        "data": [
          {
            "source": "Full Name",
            "value": "Name",
          },
          {
            "source": "Gender",
            "value": "Male",
          },
          {
            "source": "Date Of Birth",
            "value": "19-10-2001",
          },
          {
            "source": "Father's Name",
            "value": "Name",
          },

          {
            "source": "Mothers' Name",
            "value": "Name",
          },
          {
            "source": "NID",
            "value": "19102001",
          },
          {
            "source": "Marital Status",
            "value": "Married",
          },
          {
            "source": "Spouse's Name",
            "value": "Name",
          },
          {
            "source": "Spouse's Etin",
            "value": "19102001",
          },
        ]
      },
      {
        "head": "Contact Information",
        "data": [
          {
            "source": "Mobile No",
            "value": "11314134",
          },
          {
            "source": "Email",
            "value": "e@m.com",
          },
          {
            "source": "Present Address",
            "value": "Address",
          },
          {
            "source": "Permanent Address",
            "value": "Resident",
          },
        ]
      }
    ]
    return jsonData;
  }

  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
