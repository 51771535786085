import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { bangla, english } from 'app/util/locale';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent
{
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private back: BackButtonService,
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
    }

    goToHome(): void
    {
        // let url_D = "http://localhost:4200/home";
        let url_D = `/home`;
        let url_P = `/home`;
        // let url_P = `${window.location.hostname}/home`;
        // window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;

        window.location.href.includes('http://localhost') ? window.open(url_D, "_blank") : window.open(url_P, "_blank");

        // this._router.navigate(['/home']);
    }

    goToAbout(): void
    {
        // let url_D = "http://localhost:4200/about";
        let url_D = `/about`;
        let url_P = `/about`;
        // let url_P = `${window.location.hostname}/about`;
        // window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;

        window.location.href.includes('http://localhost') ? window.open(url_D, "_blank") : window.open(url_P, "_blank");

        // this._router.navigate(['/about']);
    }

    goToHowTo(): void
    {
        // let url_D = "http://localhost:4200/how-it-works";
        let url_D = `/how-it-works`;
        let url_P = `/how-it-works`;
        // let url_P = `${window.location.hostname}/how-it-works`;
        // window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;

        window.location.href.includes('http://localhost') ? window.open(url_D, "_blank") : window.open(url_P, "_blank");

        // this._router.navigate(['/about']);
    }

    goToPricing(): void
    {
        // let url_D = "http://localhost:4200/pricing";
        let url_D = `/pricing`;
        let url_P = `/pricing`;
        // let url_P = `${window.location.hostname}/pricing`;
        // window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;

        window.location.href.includes('http://localhost') ? window.open(url_D, "_blank") : window.open(url_P, "_blank");

        // this._router.navigate(['/pricing']);
    }

    goToFAQ(): void
    {
        // let url_D = "http://localhost:4200/faq"
        let url_D = `/faq`;
        let url_P = `/faq`;
        // let url_P = `${window.location.hostname}/faq`;
        // window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;

        window.location.href.includes('http://localhost') ? window.open(url_D, "_blank") : window.open(url_P, "_blank");

        // this._router.navigate(['/faq']);
    }

    goToBlog(): void
    {
        // let url_D = "http://localhost:4200/blog"
        let url_D = `/blog`;
        let url_P = `/blog`;
        // let url_P = `${window.location.hostname}/blog`;
        // window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;

        window.location.href.includes('http://localhost') ? window.open(url_D, "_blank") : window.open(url_P, "_blank");

        // this._router.navigate(['/faq']);
    }

    goToContact(): void
    {
        // let url_D = "http://localhost:4200/contact";
        let url_D = `/contact`;
        let url_P = `/contact`;
        // let url_P = `${window.location.hostname}/contact`;
        // window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;

        window.location.href.includes('http://localhost') ? window.open(url_D, "_blank") : window.open(url_P, "_blank");

        // this._router.navigate(['/contact']);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
