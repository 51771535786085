
export class ClientsSaveModel
{
  // AssesseeId: string = "";
  // ETIN: string = "";
  // AssesseeName: string = "";
  // MobileNo: string = "";
  // Email: string = "";

  AssesseeId: string = "";
  ETIN: string = "";
  ZoneId: string = "";
  FatherName: string = "";
  AssesseeName: string = "";
  Circle: string = "";
  MotherName: string = "";
  ContactNo: string = "";
  ResidentialAreaId: string = "";
  MaritalStatus: string = "";
  Email: string = "";
  Gender: string = "";
  SpouseName: string = "";
  Reference: string = "";
  DateOfBirth: string = "";
  SpouseTIN: string = "";

  ResidentStatus: string = "";
  Nationality: string = "";
  PresentAddress: string = "";
  PermanentAddress: string = "";


  PermanentDistrictId: string = "";
  PermanentPostCode: string = "";

  PresentDistrictId: string = "";
  PresentPostCode: string = "";
  
  Stayed1: string = "";
  Stayed2: string = "";
  // SameAsPresentAddress: boolean;
}
