<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2> {{ 'ABOUT.HEADER' | translate }}</h2>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100 extra-pb"
    style="padding-top: 0; ">
    <div class="container">
        <div class="row">

            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <br>
                    <!-- <span class="sub-title"
                        style="margin-top: 10px;">{{ 'ABOUT.TAGLINE' | translate }}</span> -->

                    <p style="color: #444444;"><b>{{ 'HOME.NAME' | translate }}</b> {{ 'ABOUT.ABOUT_1' | translate }}
                    </p>
                    <p style="color: #444444;">{{ 'ABOUT.ABOUT_2' | translate }}
                    </p>
                    <p style="color: #444444;">{{ 'ABOUT.ABOUT_3' | translate }}
                    </p>
                    <p style="color: #444444;">{{ 'ABOUT.ABOUT_4' | translate }}
                    </p>
                    <p style="color: #444444;">{{ 'ABOUT.ABOUT_5' | translate }}
                    </p>
                    <p style="color: #444444;">{{ 'ABOUT.ABOUT_6' | translate }}
                    </p>
                </div>
            </div>

        </div>

    </div>

</section>
<!-- End About Area -->

<section class="about-area extra-pb"
    style="padding-bottom: 0 !important; margin-bottom: 0 !important;">
    <div class="container">
        <!-- <div class="row">

            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h2>People at digiTax</h2>
                    <p style="color: #444444;">Over the last 35 years our people have lodged over two million tax
                        returns on behalf of Australian taxpayers.</p>
                    <p style="color: #444444;">Most people don’t really like doing their taxes, but for our teams at
                        Etax, this is what we do for a living. We think it’s fun. And we’re doing our best to make the
                        “tax return experience” at Etax a bit more fun for everyone.</p>

                    <p style="color: #444444;">Etax employs hundreds of accountants in Australia. It’s a truly
                        Australian business. Our senior accounting staff and management include CPA and CA-qualified
                        accountants and tax agents who directly manage the accounting team and who also review or check
                        individual tax returns entered by clients on Etax.com.au. The high level of training and
                        experience held by many of our senior accountants places our team at the forefront of personal
                        tax accounting in Australia.</p>
                    <p style="color: #444444;">Also working at the front of their field is our IT team of expert
                        software developers and IT & tax specialists. They keep Etax running all day, every day, so our
                        clients can access their tax return anytime. Also they work on user experience and continuous
                        improvement, making Etax better for everyone, year after year.</p>

                </div>
            </div>
        </div> -->

        <!-- <div class="row"
            style="margin-bottom: 100px; margin-top: 100px;"> -->

        <!-- <div class="col-lg-12 col-md-12"> -->
        <!-- <div class="about-content"> -->
        <!-- <h3>Adviser - Project and Information Management</h3>
                    <h4>Section will be added soon</h4> -->
        <!-- <p style="color: #444444;">“For 15 years I have enjoyed working with top accountants and IT
                        professionals building Australia’s favourite online tax return.</p>
                    <p style="color: #444444;">“Australian taxpayers have always sought trusted advice from a tax agent
                        who’s looking out for their best interests – and their best tax refund. Today, people want the
                        same things in a tax agent, but they want it with online convenience.</p>

                    <p style="color: #444444;">“Shockingly, studies show that most Australians over-pay their taxes and
                        this is one thing we’re committed to preventing for our clients.</p>
                    <p style="color: #444444;">“The Etax team labours year-round to make Etax.com.au the most
                        user-friendly, fast and easy way to do a tax return. And our tax agent team never stops finding
                        ways to serve clients better and more quickly.</p>
                    <p style="color: #444444;">“It gives me great pleasure to see our teams working with passion and
                        integrity to deliver honest, convenient service to Australian taxpayers.”</p> -->

        <!-- </div> -->
        <!-- </div> -->
        <!-- </div> -->

        <div class="row"
            style="margin-bottom: 100px;">

            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h3>Syed Abdullah As-Sayeed</h3>

                    <h5>Adviser - Project and Information Management</h5>
                    <div class="row">
                        <div class="col-md-9">
                            <p style="color: #444444;">Syed As-Sayeed is a management information system expert with
                                extensive experience in diverse areas of information management, IT-based process
                                simplification and IT project management. He brings over 27 years of experience with the
                                private sector in Bangladesh and Australian government agencies, with his experience
                                spanning multiple technology design and management initiatives.</p>
                            <p style="color: #444444;"> Mr. As-Sayeed has extensive knowledge and experience in
                                transforming business functions in technology solutions, bridging the gap between
                                technical application and beneficiary expectations, and data management to influence
                                policies and procedures.</p>

                            <p style="color: #444444;"> Syed As-Sayeed played a leading advisory role in the design of
                                an interactive digital platform “digiTAX” which will make the online tax return
                                submission in Bangladesh efficient and user friendly. His experience as a user of
                                electronic tax return submission in Australia played an important role for adaptation of
                                key features in digiTax.</p>

                            <!-- <p style="color: #444444;">As an Advocate he has experiences of appearing on various civil
                                and criminal cases before the Courts of Assistant Judge, Joint District-Judge, District
                                Judge, Metropolitan Magistrate, Chief-Metropolitan Magistrate, Additional District
                                Magistrate and Court of Sessions, Labour Court, Marine Court, Administrative Tribunals
                                etc.</p> -->
                        </div>
                        <div class="col-md-3">
                            <figure>
                                <img src="assets/home/img/client-image/adviser.jpg"
                                    style="width:100%">
                                <figcaption style="text-align: center;">Syed Abdullah As-Sayeed
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                    <p style="color: #444444;"></p>
                    <p style="color: #444444;">Syed As-Sayeed has substantial experience in the design of
                        technology-based business solutions and promotion of digital platforms to potential
                        beneficiaries. As a software application expert, he has conceptualized and designed
                        several IT-based business solutions and web application, which influenced organizational
                        efficiency and better service delivery in the financial sector in Bangladesh and health
                        sector in Australia.</p>

                    <p style="color: #444444;"> <b>Education</b>: Syed As-Sayeed holds a Master of Management degree
                        with specialization in information technology management from the Australian National University
                        (ANU), and Master of Business Administration (MBA) degree with specialization in Marketing from
                        ANU, Australia. </p>
                </div>
            </div>
        </div>

        <div class="row"
        style="margin-bottom: 100px;">

        <div class="col-lg-12 col-md-12">
            <div class="about-content">

                <h3>Musarrat Nayeema </h3>
                <h5>Advisor, digiTax | Executive Director, Desh Universal Private Limited</h5>
                <div class="row">
                    <div class="col-md-9">
                        <p style="color: #444444; margin-bottom: 0;">Musarrat Nayeema is a veteran college teacher
                            with twenty nine (29) years of experience in leadership position of top academic
                            institutions in Dhaka, Bangladesh. She served in the role of Principal (2011-2014) and
                            Vice-Principal (2005-2011) of Bir Sreshtho Munshi Abdur Rauf Public College, PeelKhana,
                            Dhaka. Under her leadership, the college transformed into one of the most successful
                            academic institutions in Bangladesh. During her tenure as a Principal, the college
                            experienced a significant improvement in student quality, result in public board
                            examinations and yearly revenue. She served the following academic institutions in
                            various capacities such as Principal, Vice-Principal, and Senior Lecturer:</p>

                        <ul style="color: #444444;">
                            <li>1. Ispahani Cantt public college, Cumilla Cantt.</li>
                            <li> 2. Meher Degree College, Chandpur.</li>
                            <li> 3. Shaheed Bir Uttam Lt Anwar Girls College, Dhaka Cantt.</li>
                            <li> 4. Bir Sreshtho Munshi Abdur Rauf Public College, PeelKhana, Dhaka.</li>
                        </ul>

                    </div>
                    <div class="col-md-3">
                        <figure>
                            <img src="assets/home/img/client-image/officer.jpg"
                                style="width:100%">
                            <figcaption style="text-align: center;">Musarrat Nayeema </figcaption>
                        </figure>
                    </div>
                </div>

                <p style="color: #444444;">During her long 29-year academic career, she has produced many
                    successful students who are now contributing to Bangladesh economy by generating
                    employment and tax payment to national exchequer, NBR. As a Principal, Musarrat
                    Nayeema’s one of the notable contributions was the introduction of computerized
                    automation system in publishing student results and collecting tuition fees. This
                    process-automation significantly reduced time and human errors in the publication of
                    terminal and yearly results. </p>

                <p style="color: #444444;"> In addition, she played an instrumental role in installing Smart Board
                    in the twenty four (24) classrooms that led to the digitalization of classroom education. Later
                    on, she joined Desh Universal Pvt. Limited, an information technology firm, as an Executive
                    Director where she provides valuable insights into user requirements and help the firm develop
                    user friendly software. </p>

                <p style="color: #444444;">She is also an honorary treasurer of a philanthropic organization based
                    in Dhaka named “Elevating Society through Skill Development (ESSD)” for last seven years. She is
                    also an active participant of Bangladesh Society for Total Quality Management (BSTQM). She
                    visited prominent Indian academic institutions as a leader of the team of academic and extra
                    curricula exchange programmes. During her tenure, she visited number of academic institutions in Hawaii,
                    USA, AIT, Bangkok and institutions in Chengdu, China. She completed her bachelor and masters in
                    English Literature from University of Dhaka.</p>

            </div>
        </div>
    </div>

        <div class="row"
            style="margin-bottom: 100px;">

            <div class="col-lg-12 col-md-12">
                <div class="about-content">

                    <h3>Md. Lokman Hossain </h3>
                    <h5>Consultant digiTax, Income tax specialist lawyer. Advocate, Supreme Court of Bangladesh.</h5>
                    <div class="row">
                        <div class="col-md-9">
                            <p style="color: #444444;">He obtained LL.B.(Hon’s), LL.M from the University of Dhaka and
                                completed Masters in Criminology & Criminal Justice from the same university. He studied
                                Master of Tax Management (MTM) under the Department of Banking and Insurance, University
                                of Dhaka.</p>

                            <p style="color: #444444;"> He was enrolled as an Advocate under Bangladesh Bar Council and
                                became a member of Dhaka
                                Bar Association (DBA) in December 2009. He got permitted to practice in the High Court
                                Division of Bangladesh Supreme Court in January 2017. He is also a member of Supreme
                                Court Bar Association (SCBA), Dhaka Taxes Bar Association (DTBA) and Brahmanbaria
                                District Bar Association. </p>

                            <p style="color: #444444;">As an Advocate he has experiences of appearing on various civil
                                and criminal cases before the Courts of Assistant Judge, Joint District-Judge, District
                                Judge, Metropolitan Magistrate, Chief-Metropolitan Magistrate, Additional District
                                Magistrate and Court of Sessions, Labour Court, Marine Court, Administrative Tribunals
                                etc.</p>
                        </div>
                        <div class="col-md-3">
                            <figure>
                                <img src="assets/home/img/client-image/lawyer.jpeg"
                                    style="width:100%">
                                <figcaption style="text-align: center;">Md. Lokman Hossain </figcaption>
                            </figure>
                        </div>
                    </div>

                    <p style="color: #444444;"> Apart from the above, he has experience of legal working i.e. perusing,
                        vetting &
                        furnishing legal opinion on various issues relating to the Banking laws, Tax VAT &
                        Company laws, Family laws, Labour laws & other commercial matters, drafting various
                        types of deeds/agreements and also engaged in practice regarding Income Tax, VAT and
                        company cases before Appellate Commissionerate of Taxes, Taxes Appellate Tribunal, VAT
                        Appellate Tribunal and RJSC along with others corporate and writ matters before the
                        Hon’ble High Court Division of the Supreme Court of Bangladesh. He is also working as a
                        Panel Lawyer of Sonali Bank Limited, EXIM Bank Limited along with a bundle of private
                        limited companies and business institutions.</p>

                    <p style="color: #444444;"> Mr. Hossain is involved in many social and development activities. He is
                        the Secretary
                        General of LSPR-Legal Support and People’s Rights Foundation, a Govt. approved human
                        rights organization [Govt. Reg. No. S-13047]. He is also a Panel Advocate of National
                        Legal Aid Services Organization, Dhaka District. He is a member of Bangladesh Ain
                        Somity, He visited United Kingdom (UK) and India. </p>
                </div>
            </div>
        </div>

        <div class="row"
            style="margin-bottom: 100px;">

            <div class="col-lg-12 col-md-12">
                <div class="about-content">

                    <h3>Md. Shidul Islam </h3>
                    <h5>Analyst and Project Manager, digiTax</h5>
                    <div class="row">
                        <div class="col-md-9">
                            <p style="color: #444444;">Md. Shidul Islam is a Microsoft Certified Solution Developer and
                                Information Technology Specialist with Masters in Business Administration. He has over
                                12+ years of IT experience in banking, educational, defense organizations, multinational
                                and local enterprises.</p>
                            <p style="color: #444444;"> He has earned notable success in directing IT corporate
                                initiatives while participating in the planning, analysis, designing, development and
                                lead the implementation of Information Technology and standards in direct support of
                                business objectives.</p>

                            <p style="color: #444444;">Md. Shidul Islam is strongly associated with digiTax for
                                analyzing, structuring, planning tasks, team coordination, system implementation to
                                ensure the project’s ultimate success with a delightful user experience.</p>

                        </div>
                        <div class="col-md-3">
                            <figure>
                                <img src="assets/home/img/client-image/manager.jpg"
                                    style="width:100%">
                                <figcaption style="text-align: center;">Md. Shidul Islam </figcaption>
                            </figure>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- <section class="partner-area ptb-100"
    style=" padding-top: 0 !important; margin-top: 0 !important;">
    <div class="d-table"
        style="margin-bottom: 100px;">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'COMMON.ITEM_3' | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="container">

        <div class="row align-items-center"
            style="justify-content: center !important;align-items: center !important;">
            <div class="single-partner-item">

                <img src="assets/home/img/partner-image/baksh_1.png"
                    alt="image">

            </div>
            <div class="single-partner-item">

                <img src="assets/home/img/partner-image/visa.png"
                    alt="image">

            </div>
            <div class="single-partner-item">

                <img src="assets/home/img/partner-image/master.png"
                    alt="image">

            </div>
            <div class="single-partner-item">

                <img src="assets/home/img/partner-image/ssl_1.png"
                    alt="image">

            </div>
        </div>
    </div>
</section> -->
