import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { LabelGroupControl } from 'app/models/dynamic-form/LabelGroup-control';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';
import { AppUser } from 'app/auth';


@Injectable()
export class QuickPanelService
{
    constructor(private service: Service) { }

    getControlJson(): Observable<BaseControl<string>[]>
    {        
        let urlConfig = new UrlConfig();

        return this.service.get(urlConfig.PROFILECHECK_LEFTMENU_RIGHTPANEL);
    }

    getProfileCompleteJson(): Observable<BaseControl<string>[]>
    {        
        let urlConfig = new UrlConfig();

        return this.service.get(urlConfig.PROFILE_CHECK_ONLY);
    }

    getArchiveReadiness(): Observable<BaseControl<string>[]>
    {        
        let urlConfig = new UrlConfig();

        return this.service.get(urlConfig.ARCHIVE_READINESS);
    }


    getUserInfo(): AppUser
    {
        try
        {
            let storage = JSON.parse(localStorage.getItem('userInfo'));
            let user = Object.assign(new AppUser(), storage);
            return user;
        } catch (error)
        {
            return null;
        }

    }

}
