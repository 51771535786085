<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            fusePerfectScrollbar
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">
            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'SALARY.HEADER' | translate }}
                <span *ngIf="id!=null">
                    <span *ngIf="isPrivate"
                        style="text-transform: uppercase;">({{ 'SALARY.SALARY_TYPE_R_PRI' | translate }})
                    </span>
                    <span *ngIf="!isPrivate"
                        style="text-transform: uppercase;">({{ 'SALARY.SALARY_TYPE_R_GOV' | translate }})
                    </span>
                </span>
            </span>
        </div>
        <div class="content mb-48"
            fusePerfectScrollbar>
            <div class="course-step p-24">
                <div *ngIf="id==null">
                    <div fxLayout="row"
                        fxLayoutAlign="center">
                        <button mat-raised-button
                            id="PrivateSet"
                            (click)="setPrivate()"
                            class="mat-button mat-button-base mySelected"
                            style="border-radius: 60px; background-color: #c9c9c9;
                             color: #888888;">
                            {{ 'SALARY.PJH' | translate }}
                        </button>

                        <button mat-raised-button
                            id="GovtSet"
                            (click)="dsetPrivate()"
                            class="mat-button mat-button-base"
                            style="border-radius: 60px; margin-left: -15px; background-color: #c9c9c9; color: #888888;">
                            {{ 'SALARY.GJH' | translate }}
                        </button>
                    </div>

                    <div *ngIf="isPrivate"
                        class="tabcontent">
                        <form class="page-layout simple fullwidth"
                            fxLayout="column"
                            [formGroup]="pform">

                            <div class="form-row">
                                <p-Salary-form [form]="pform">
                                </p-Salary-form>
                            </div>

                        </form>
                    </div>

                    <div *ngIf="!isPrivate"
                        class="tabcontent">
                        <form class="page-layout simple fullwidth"
                            fxLayout="column"
                            [formGroup]="gform">

                            <div class="form-row">
                                <g-Salary-form [form]="gform">
                                </g-Salary-form>
                            </div>

                        </form>
                    </div>
                </div>
                <div *ngIf="id!=null">
                    <form class="page-layout simple fullwidth"
                        fxLayout="column"
                        [formGroup]="pform">

                        <div class="form-row">
                            <p-Salary-form [form]="pform">
                            </p-Salary-form>
                        </div>

                    </form>
                </div>

            </div>
        </div>
        <div class="step-navigation"
            *ngIf="id==null">
            <button mat-raised-button
                (click)="ConfirmLeave('/PersonalizeReturn')"
                style="border: 2px solid #37793c;line-height: 30px;background-color: #ffffff;cursor: pointer;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>

            <button mat-raised-button
                *ngIf="isPrivate"
                style="margin-left: 10px; font-weight: bold !important; color: #37793c !important; background-color: #e5e5e5 ;border: 0; border-radius: 4px; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit_P('more')">
                {{ 'COMMON.BUTTON_SAVE_ADD_MORE' | translate }}
            </button>

            <button mat-raised-button
                *ngIf="isPrivate"
                style="margin-left: 10px;
            background-color: #37793c;border: 0;border-top-left-radius: 4px;border-bottom-left-radius: 4px;color: #ffffff; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit_P('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>

            <button [matMenuTriggerFor]="sNeBtn_P"
                *ngIf="isPrivate"
                style=" background-color: #37793c; border: 0; border-radius: 4px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;cursor: pointer; line-height: 34px;">
                <mat-icon style="color: #ffffff;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn_P="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit_P('exit')">
                    <span style="font-weight: bold; color: #37793c;"> {{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}
                    </span>
                </button>
            </mat-menu>

            <button mat-raised-button
                *ngIf="!isPrivate"
                style="margin-left: 10px; font-weight: bold !important; color: #37793c !important; background-color: #e5e5e5 ;border: 0; border-radius: 4px; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit_G('more')">
                {{ 'COMMON.BUTTON_SAVE_ADD_MORE' | translate }}
            </button>

            <button mat-raised-button
                *ngIf="!isPrivate"
                style="margin-left: 10px;
            background-color: #37793c;border: 0;border-top-left-radius: 4px;border-bottom-left-radius: 4px;color: #ffffff; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit_G('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>

            <button [matMenuTriggerFor]="sNeBtn_G"
                *ngIf="!isPrivate"
                style=" background-color: #37793c; border: 0; border-radius: 4px; border-top-left-radius: 0; border-bottom-left-radius: 0; color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;cursor: pointer; line-height: 34px;">
                <mat-icon style="color: #ffffff;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn_G="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit_G('exit')">
                    <span style="font-weight: bold; color: #37793c;"> {{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}
                    </span>
                </button>
            </mat-menu>

            <button mat-raised-button
                (click)="ConfirmLeave('next')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer; margin-left: 10px;line-height: 30px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>

        <div class="step-navigation"
            *ngIf="id!=null">
            <button mat-raised-button
                (click)="ConfirmLeave('/PersonalizeReturn')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer; margin-right: 10px;line-height: 30px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>
            <button mat-raised-button
                style="color: #444444 !important; margin-right: 10px;"
                [routerLink]="'/Salary'"
                class="mat-button mat-button-base primary-100 secondary-text">
                {{ 'COMMON.BUTTON_CANCEL' | translate }}
            </button>
            <button mat-raised-button
                style="margin-left: right;background-color: #37793c; border: 0; border-top-left-radius: 4px;border-bottom-left-radius: 4px; color: #ffffff; cursor: pointer;padding: 0 16px; line-height: 36px;"
                (click)="mySubmitExit_P('next')">
                {{ 'COMMON.BUTTON_SAVE_NEXT' | translate }}
            </button>
            <button [matMenuTriggerFor]="sNeBtn"
                style=" background-color: #37793c; border: 0; border-radius: 4px;border-top-left-radius: 0; border-bottom-left-radius: 0;color: #ffffff; padding-right: 0; padding-left: 0; margin-left: 2px;cursor: pointer; line-height: 34px;">
                <mat-icon style="color: #ffffff;">keyboard_arrow_down
                </mat-icon>
            </button>
            <mat-menu #sNeBtn="matMenu"
                [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="mySubmitExit_P('exit')">
                    <span style="font-weight: bold; color: #37793c;">{{ 'COMMON.BUTTON_SAVE_EXIT' | translate }}</span>
                </button>
            </mat-menu>
            <button mat-raised-button
                (click)="ConfirmLeave('next')"
                style="border: 2px solid #37793c;background-color: #ffffff;cursor: pointer; margin-left: 10px;line-height: 30px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>
    </div>
</div>
