import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseControl } from "app/models/dynamic-form";
import { Observable } from "rxjs";
import Utils from "app/util/utils";
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';


@Injectable()
export class TaxRebateService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }
  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    if (id != null)
    {
      let urlConfig = new UrlConfig();
      return this.service.get(urlConfig.TAX_RA_Tax_Rebate_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));
    }
    else
    {
      let urlConfig = new UrlConfig();
      return this.service.get(urlConfig.TAX_RA_Tax_Rebate_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }
  }

  submitTaxRebate(model: any, id: string): Observable<any>
  {
    if (id == null)
    {
      let urlConfig = new UrlConfig();
      return this.service.post(urlConfig.TAX_RA_Tax_Rebate_Submit_Url, model);
    }
    else
    {
      let urlConfig = new UrlConfig();
      return this.service.post(urlConfig.TAX_RA_Tax_Rebate_Submit_Url + `/${id}`, model);
    }
  }

  getJson(): any
  {
    const jsonData = [
      {
        "control": "LabelControl",
        "key": "investments",
        "label": "Investments",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 1
      },
      {
        "control": "LabelGroupControl",
        "key": "sourcesHeaders",
        "errorType": "",
        "label": "Fields",
        "message": "",
        "tooltip": "",
        "order": 2,
        "children": [
          {
            "control": "LabelControl",
            "key": "header1",
            "label": "Fields",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 1
          },
          {
            "control": "LabelControl",
            "key": "header2",
            "label": "Amount",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 2
          },
          {
            "control": "LabelControl",
            "key": "header3",
            "label": "Allowed",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": null,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "lifeInsurancePremium",
        "label": "Life Insurance Premium",
        "errorType": "",
        "message": "",
        "tooltip": "The larger amount between premium and policy value counts as allowable amount.",
        "order": 3,
        "innerContent": [
          {
            "control": "TextboxControl",
            "key": "LifeInsurancePolicyValue",
            "label": "Policy Value",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          }
        ],
        "children": [
          {
            "control": "TextboxControl",
            "key": "LifeInsurancePremiumAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "LifeInsurancePremiumAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "contributionPension",
        "label": "Contribution to deposit pension scheme (not exceeding allowable limit)",
        "errorType": "",
        "message": "",
        "tooltip": "Allowable limit is yearly tk 60,000.",
        "order": 4,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToDepositPensionSchemeAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToDepositPensionSchemeAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "investmentSavings",
        "label": "Investment in saving certificate/ shanchaypatra",
        "errorType": "",
        "message": "",
        "tooltip": "e.g. Shanchaypatra",
        "order": 5,
        "children": [
          {
            "control": "TextboxControl",
            "key": "InvestmentInApprovedSavingsCertificateAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "InvestmentInApprovedSavingsCertificateAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "investmentdebunture",
        "label": "Investment in approved debunture stock, stock or shares",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 6,
        "children": [
          {
            "control": "TextboxControl",
            "key": "InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "providendFund",
        "label": "Contribution to providend Fund to which Prodivend Fund act 1925 applies",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 7,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToProvidentFundToWhichProvidentFundAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToProvidentFundToWhichProvidentFundAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "contributionSelf",
        "label": "Self Contribution and employee's contribution to recognized provident",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 8,
        "children": [
          {
            "control": "TextboxControl",
            "key": "SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "contributionSuper",
        "label": "Contribution to super Annuation fund",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 9,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToSuperAnnuationFundAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToSuperAnnuationFundAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "contributionBenevolent",
        "label": "Contribution to Benevolent fund and group insurance premium",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 10,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToBenevolentFundAndGroupInsurancePremiumAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToBenevolentFundAndGroupInsurancePremiumAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "treasuryBond",
        "label": "Investment in Bangladesh Govt Treasury Bond",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 11,
        "children": [
          {
            "control": "TextboxControl",
            "key": "InvestmentInBangladeshGovtTreasuryBondAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "InvestmentInBangladeshGovtTreasuryBondAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "mutualFund",
        "label": "Mutual Fund",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 12,
        "children": [
          {
            "control": "TextboxControl",
            "key": "MutualFundAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "MutualFundAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "LabelControl",
        "key": "donation",
        "label": "Donations And Contributions",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 13
      },
      {
        "control": "TextboxGroupControl",
        "key": "zakat",
        "label": "Contributions to Zakat",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 14,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToZakatFundAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToZakatFundAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "fON",
        "label": "Donation to national Level Institution set up in memory of the Father of the Nation",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 15,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "charitableHospital",
        "label": "Donation to Charitable Hospital recognized by NBR",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 16,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DonationToCharitableHospitalRecognizedByNBRAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DonationToCharitableHospitalRecognizedByNBRAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "welfare",
        "label": "Donation to orginations set up for the welfare of retarded people",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 17,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "warMusuem",
        "label": "Contribution to liberation war musuem",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 18,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToLiberationWarMusuemAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToLiberationWarMusuemAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "agaKhan",
        "label": "Contribution to Aga Kahan Develeopment network",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 19,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToAgaKahanDeveleopmentNetworkAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToAgaKahanDeveleopmentNetworkAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "ahsania",
        "label": "Doantion to Ahsania cancer hospital",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 20,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DoantionToAhsaniaCancerHospitalAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DoantionToAhsaniaCancerHospitalAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "icddrb",
        "label": "Donation To ICDDRB (THe International Centre for Diarrhoeal Disease Research Bangladesh)",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 21,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DonationToICDDRBAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DonationToICDDRBAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "crp",
        "label": "Donation to the Centre for the Rehabilitation of the Paralyzed (CRP)",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 22,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DonationToCentreForRehabilitationOfParalyzedAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DonationToCentreForRehabilitationOfParalyzedAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "socialWelfare",
        "label": "Donation to Educational Institute or Social Welfare Recognized By Government",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 23,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "asiatec",
        "label": "Contribution to Asiatec Society, Bangladesh",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 24,
        "children": [
          {
            "control": "TextboxControl",
            "key": "ContributionToAsiatecSocietyBangladeshAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "ContributionToAsiatecSocietyBangladeshAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "liberationWar",
        "label": "Donation to National Level institute set up in the Memory of Liberation war",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 25,
        "children": [
          {
            "control": "TextboxControl",
            "key": "DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "NetRebatable",
        "label": "Net Rebatable",
        "errorType": "",
        "message": "",
        "tooltip": "",
        "order": 26,
        "children": [
          {
            "control": "TextboxControl",
            "key": "TotalInvestmentAmount",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "TotalInvestmentAllowed",
            "label": "",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "tabindex": "-1",
            "required": false,
            "readonly": true,
            "order": 2
          }
        ]
      }
    ]
      ;
    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
