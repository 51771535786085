import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';



import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';



import { MatRadioChange } from '@angular/material/radio';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { log } from 'console';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PersonalInformationListService } from './Personal-Information-List.service';
import Utils from 'app/util/utils';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'PersonalInfoList',
    templateUrl: './Personal-Information-List.component.html',
    styleUrls: ['./Personal-Information-List.component.scss']
})
export class PersonalInfoListComponent implements OnInit
{
    @Input() controls: BaseControl<string>[] = [];

    form: FormGroup;
    maritalStatus: string;
    formcontrols$: Observable<BaseControl<string>[]>;
    getData: any;



    id: any;
    checkData: any;
    lang = '';
    payLoad = '';
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {FormBuilder} _formBuilder
     * 
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formBuilder: FormBuilder,
        private _formControlService: PersonalInformationListService,
        private _userInfoService: Service,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,

    )
    {
        this.meta.addTags([
            { name: 'description', content: 'digiTax Personal & Contact Information' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax Personal & Contact Information' }
        ]);
        this.setTitle('digiTax | Personal & Contact Information');
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {
        this._formControlService.getControlJson();
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');

        this._formControlService.getControlJson().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }

            if (data.data != null)
            {
                this.checkData = data.data.filter(d => d.id == "personalInfo")[0].data.filter(d => d.id == "gender")[0].value;
            }
            if (this.checkData == "--")
            {
                this._router.navigate(['/PersonalInfo/save']);
            } else
            {
                this.getData = data.data;
            }
        });
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
