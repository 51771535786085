import { Component, OnInit } from '@angular/core';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { bangla, english } from 'app/util/locale';
import { Meta, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
    selector: 'about',
    templateUrl: './About.component.html',
    styleUrls: ['./About.component.scss']
})
export class AboutComponent implements OnInit
{

    constructor(

        private _spinner: NgxSpinnerService,

        private _homeTranslationLoaderService: HomeTranslationLoaderService,
        private back: BackButtonService,
        private meta: Meta,
        private title: Title,



    )
    {
        this._spinner.hide();
        this.meta.addTags([
            { name: 'description', content: 'About digiTax' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'About digiTax' }
        ]);
        this.setTitle('About digiTax');

        this._homeTranslationLoaderService.loadTranslations(english, bangla);
        this.back.sharedData.subscribe(result =>
        {
            if (result)
            {
                // window.location.reload(true);
                window.location.reload();

                this.back.setParam(false, '');
            }
        });
    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit()
    {
    }

}

