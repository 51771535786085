import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewContainerRef, APP_ID, } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { bangla, english } from 'app/util/locale';

import { navigation } from 'app/navigation/navigation';
// import { locale as navigationEnglish } from 'app/navigation/i18n/en';
// import { locale as navigationTurkish } from 'app/navigation/i18n/tr';

import
{
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd
} from "@angular/router";
import
{
    Location,
    LocationStrategy,
    PathLocationStrategy
} from "@angular/common";
import { filter } from "rxjs/operators";
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

declare let $: any;

@Component({
    selector: 'app-home-layout',
    templateUrl: './homelayout.component.html',
    styleUrls: ['./homelayout.component.scss'],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HomeLayoutComponent implements OnInit, OnDestroy
{
    location: any;
    routerSubscription: any;
    languages: any;
    id: string;

    selectedLanguage: any;
    constructor(
        private router: Router,
        private _translateService: TranslateService,
        private _spinner: NgxSpinnerService,
        @Inject(APP_ID) appId: string,
        @Inject(DOCUMENT) private document: any,

    )
    {
        // Add languages
        this._translateService.addLangs(['en', 'bn']);

        // Set the default language
        this._translateService.setDefaultLang('en');


        // Use a language
        this._translateService.use('en');

        
        this.id = localStorage.getItem('rootapp_id');
        let rootapp = this.document.getElementById("root-app") as HTMLLinkElement;
        if (rootapp)
        {
            rootapp.removeAttribute(this.id);
        }

    }

    ngOnInit()
    {
        this.recallJsFuntions();
    }

    recallJsFuntions()
    {
        this.router.events.subscribe(event =>
        {
            if (event instanceof NavigationStart)
            {
                $(".preloader").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    event =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe(event =>
            {
                $.getScript("/assets/home/js/custom.js");
                $(".preloader").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd))
                {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy()
    {
        this.routerSubscription.unsubscribe();
    }
}
