import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Layouts } from 'app/enum/layouts.enum';
import { PrivacyPolicyComponent } from './privacy-policy.component';


const routes = [
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        PrivacyPolicyComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule
    ],
    exports: [
        PrivacyPolicyComponent
    ]
})

export class PrivacyPolicyModule
{
}
