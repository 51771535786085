import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Config from 'app/util/config';
import { AppUser } from 'app/auth';

@Injectable({ providedIn: 'root' })
export class Service
{

    constructor(private http: HttpClient) { }

    get(url: string): Observable<any>
    {
        const authToken = localStorage.getItem('userToken');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        });
        return this.http.get(url, { headers: headers });
    }

    getAppUser(): AppUser
    {
        let storage = JSON.parse(localStorage.getItem('userInfo'));
        let user = Object.assign(new AppUser(), storage);
        return user;
    }



    post(url: string, body: any): Observable<any>
    {
        const authToken = localStorage.getItem('userToken');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        });
        return this.http.post(url, body, { headers: headers });
    }

    put(url: string, body: any): Observable<any>
    {
        const authToken = localStorage.getItem('userToken');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        });
        return this.http.put(url, body, { headers: headers });
    }

    delete(url: string): Observable<any>
    {
        const authToken = localStorage.getItem('userToken');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        });
        return this.http.delete(url, { headers: headers });
    }
}
