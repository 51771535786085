<section class="about-area ptb-100"
    style="background-color: #F5F5FC;">
    <div class="container">
        <div class="row align-items-center flex-column-reverse flex-lg-row">
            <div class="col-lg-7 col-md-12">

                <div class="about-content">
                    <h3 style="text-align: center; margin-bottom: 30px;">{{ 'HOW_IT_WORKS.HEADER_2' | translate }}</h3>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item"
                            src="https://www.youtube.com/embed/-f7pLaUHv8g"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div style="padding: 48px 48px 48px 48px; background-color: #fff;">

                    <div style="width: 128px;
                    margin: 32px auto;">
                        <img src="assets/home/img/logo-n.png"
                            style="max-width: 100%;height: auto;vertical-align: top;border: none;">
                    </div>

                    <div style="text-align: center;font-size: 21px;font-weight: 300;line-height: 1;">{{ 'AUTH_TRAN.TEXT_RESET' | translate }}
                    </div>

                    <div *ngIf="isLoginError"
                        style="color: #f44336; margin-top: 20px;">
                        {{errorMessage}}
                    </div>

                    <form name="resetPasswordForm"
                        style="width: 100%;padding-top: 32px;"
                        [formGroup]="resetPasswordForm"
                        novalidate
                        (ngSubmit)="OnSubmit()">

                        <div class="row align-items-center">

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextType ? 'text' : 'password'"
                                        required
                                        formControlName="password"
                                        name="password"
                                        minlength="6"
                                        id="password"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_N_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(resetPasswordFormControl.password.touched || submitted) && resetPasswordFormControl.password.errors?.required">
                                        &nbsp; New Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="resetPasswordFormControl.password.touched && resetPasswordFormControl.password.errors?.invalidPassword">
                                        &nbsp; Password should have minimum 6 characters, at least 1 uppercase letter, 1
                                        lowercase
                                        letter and 1 number
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input [type]="fieldTextType ? 'text' : 'password'"
                                        required
                                        formControlName="passwordConfirm"
                                        name="passwordConfirm"
                                        minlength="6"
                                        id="passwordConfirm"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_C_PASSWORD' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(resetPasswordFormControl.passwordConfirm.touched || submitted)&& resetPasswordFormControl.passwordConfirm.errors?.required">
                                        &nbsp; Confirm Password is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="resetPasswordFormControl.passwordConfirm.touched && resetPasswordFormControl.passwordConfirm.errors?.passwordMismatch">
                                        &nbsp; Passwords do not match
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12"
                                style="flex-direction: row;box-sizing: border-box;display: flex;place-content: center space-between;align-items: center;">

                                <div diasbled>

                                </div>
                                <div
                                    style="font-size: 13px;line-height: 24px;font-weight: 600;display: flex;place-content: center space-between;align-items: center;justify-content: center;">
                                    <a (click)="toggleFieldTextType()"
                                        style="cursor: pointer;"
                                        *ngIf="!fieldTextType">Show Password</a>
                                    <a (click)="toggleFieldTextType()"
                                        style="cursor: pointer;"
                                        *ngIf="fieldTextType">Hide Password</a>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    style="background-color: #29793C !important; color: #fff !important;  width: 100%;margin: 16px auto;display: block;box-sizing: border-box;position: relative;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;cursor: pointer;outline: none;border: none;-webkit-tap-highlight-color: transparent;display: inline-block;white-space: nowrap;text-decoration: none;text-align: center;margin: 0;min-width: 64px;line-height: 36px;padding: 0 16px;border-radius: 4px;overflow: visible;transform: translate3d(0, 0, 0);    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);"
                                    type="submit">
                                    {{ 'AUTH_TRAN.TEXT_SET' | translate }}
                                </button>
                            </div>
                        </div>

                    </form>

                    <div
                        style=" font-size: 15px;font-weight: 600;margin: 24px auto;position: relative;overflow: hidden;width: 100px;text-align: center;">
                        <span style=" display: inline-flex;position: relative;padding: 0 8px;z-index: 9999;">&#8213;
                            {{ 'AUTH_TRAN.TEXT_OR' | translate }} &#8213;</span>
                    </div>

                    <div
                        style="flex-direction: column;box-sizing: border-box;display: flex;place-content: center;align-items: center;">
                        <span style="margin-right: 8px;">{{ 'AUTH_TRAN.TEXT_NO_ACCOUNT' | translate }}</span>
                        <a class="link"
                            style="color: #29793C !important; font-weight: bold;"
                            [routerLink]="'/register-home'">{{ 'AUTH_TRAN.TEXT_CREATE_ACCOUNT' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>
