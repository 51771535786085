import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import Config from 'app/util/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { NotificationService } from '../Notification.service';



@Component({
    selector: 'TemplateSaveDialog',
    templateUrl: 'save-template-Dialog.component.html',
    styleUrls: ['./save-template-Dialog.component.scss'],
})
export class TemplateSaveDialog
{

    public files: any[] = [];
    FileBase64String: any;
    FileName: any;
    lang = '';

    templateNameFormControl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<TemplateSaveDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: NotificationService,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');

    }

    onFocusoutEvent()
    {


        // console.log(Comments, 'Comments');


    }



    onNoClick(): void
    {
        this.dialogRef.close("no");
    }



    SaveTemplate(): void
    {
        let val = this.templateNameFormControl.value;
        if (val == null || val == "") 
        {
            this._matSnackBar.open(this.lang == 'en' ? "Please enter template name" : "Please enter template name", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            this._spinner.show();

            let body = this.dialogdata.data;

            // console.log(body, 'body p');

            body.TemplateName = val;

            this._formControlService.SaveTemplate(body)
                .subscribe((data: any) =>
                {
                    if (data != null)
                    {
                        this._spinner.hide();
                    }

                    if (data.isError === false)
                    {
                        console.log('submit succesfull');
                    }
                    else
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                    }
                    if (data.isError === false && data.responseCode == "200")
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                        this.dialogRef.close("yes");
                    }
                }, (error: any) =>
                {
                    this._spinner.hide();
                    console.log(error, 'error');
                });


        }
    }


}
