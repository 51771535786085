<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>সরকারি চাকুরিজীবীর আয়কর রিটার্ন প্রস্তুতি। </h2>

                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/home/img/blog-image/blog_m03_1.jpg"
                            alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On: </span> <span style="color: #29793C;">August 16, 2021</span></li>
                                <li><span>Posted By: </span>
                                    <span style="color: #29793C;">Md. Lokman Hossain</span>
                                    <span>(Advocate, Supreme Court of Bangladesh.)</span>
                                </li>
                            </ul>
                        </div>

                        <h3>আয়কর রিটার্ন কি?</h3>
                        <p>আয়কর অধ্যাদেশ, ১৯৮৪ এর ৭৫ ধারার বিধান অনুযায়ী, কোন আয় বছরে যে কোন ব্যক্তির করমুক্ত সীমার
                            উর্ধ্বে আয় থাকলে পরবর্তী বছরের ১ জুলাই থেকে ৩০ নভেম্বরের মধ্যে সংশ্লিষ্ট আয়কর অফিসে রিটার্ন
                            দাখিল করতে হবে। এছাড়া কোন সরকারি চাকুরিজীবীর করমুক্ত সীমার উর্ধ্বে আয় না থাকলেও যদি তিনি আয়
                            বছরের কোন সময়ে ১৬,০০০/- টাকা বা তদোর্ধ্ব পরিমাণ মূল বেতন প্রাপ্ত হন অথবা তিনি কোন গাড়ির
                            মালিক হন তবে অবশ্যই তাকে রিটার্ন দাখিল করতে হবে। আয়কর আইন অনুযায়ী জাতীয় রাজস্ব বোর্ড কর্তৃক
                            নির্ধারিত ফরমে আয়কর রিটার্ন দাখিল করতে হয়। আয়কর রিটার্ন বলতে একজন করদাতার বার্ষিক আয়, ব্যয়
                            এবং সম্পদের তথ্যাবলি আয়কর কর্তৃপক্ষের নিকট উপস্থাপনের নির্ধারিত ফরমকে বোঝায়। করদাতাগণ
                            বিনামূল্যে আয়কর অফিস থেকে রিটার্ন ফরম সংগ্রহ করতে পারেন। এছাড়া জাতীয় রাজস্ব বোর্ডের ওয়েব
                            সাইট থেকেও রিটার্ন ফরম ডাউনলোড করা যায়। করদাতা নিজের আয় নিজেই নিরূপণ করে প্রযোজ্য আয়কর
                            পরিশোধপূর্বক সার্বজনীন স্বনির্ধারনী পদ্ধতিতে রিটার্ন দাখিল করলে আয়কর অফিস থেকে করদাতাকে যে
                            প্রাপ্তি স্বীকারপত্র প্রদান করা হয় তা-ই কর নির্ধারণী আদেশ (assessment order) বলে গণ্য হয়।
                            কোন ব্যক্তি-করদাতা ৩০ নভেম্বরের মধ্যে রিটার্ন দাখিল করতে ব্যর্থ হলে আয়কর আইন অনুযায়ী নিরুপিত
                            আয়করের উপর অতিরিক্ত সরল সুদ, বিলম্ব সুদ ও ক্ষেত্রমতে জরিমানা আরোপের বিধান রয়েছে। </p>

                        <p>একজন সরকারি চাকুরিজীবীর আয়কর নির্ণয়ের জন্য তার মোট আয় থেকে করযোগ্য আয় নিরূপণ করতে হবে। মোট
                            করযোগ্য আয়ের উপর নির্ধারিত করহার প্রয়োগ করে আরোপযোগ্য আয়করের পরিমাণ নিরূপণ করা হয়। বর্তমানে
                            ব্যক্তি করদাতার সর্বনিম্ন করহার ৫% এবং সর্বোচ্চ কর হার ২৫%। অর্থ আইন, ২০২১ এর ধারা-৩৩
                            অনুসারে ২০২১-২০২২ কর বছরে একজন পুরুষ করদাতার মোট করযোগ্য আয়ের উপর আরোপযোগ্য আয়করের হার হবে
                            প্রথম ৩,০০,০০০ টাকা পর্যন্ত আয়ের উপর শূন্যহার বা করমু্ক্ত; পরবর্তী ১,০০,০০০ টাকা পর্যন্ত
                            আয়ের উপর ৫%; এর পরবর্তী ৩,০০,০০০ টাকা পর্যন্ত আয়ের উপর ১০%; পরবর্তী ৪,০০,০০০ টাকা পর্যন্ত
                            আয়ের উপর ১৫%; পরবর্তী ৫,০০,০০০ টাকা পর্যন্ত আয়ের উপর ২০% এবং অবশিষ্ট আয়ের উপর ২৫%। করদাতা
                            যদি তৃতীয় লিঙ্গ বা মহিলা করদাতা হন অথবা ৬৫ বছর বা তদূর্ধ্ব বয়সের যে কোন করদাতা হন তাহলে তার
                            আয়কর আরোপের ক্ষেত্রে প্রথম ৩,৫০,০০০ টাকা পর্যন্ত শূন্যহার বা করমুক্ত আয় হবে। এছাড়া
                            প্রতিবন্ধী ব্যক্তি করদাতার ক্ষেত্রে প্রথম ৪,৫০,০০০ টাকা এবং গেজেটভুক্ত যুদ্ধাহত মুক্তিযোদ্ধা
                            করদাতার ক্ষেত্রে প্রথম ৪,৭৫,০০০ টাকা পর্যন্ত শূন্যহার বা করমুক্ত আয় হবে। পরবর্তী ধাপসমূহে
                            সবার ক্ষেত্রে একই কর হার প্রযোজ্য হবে। আয় বছরে আয়কর আইন অনুযায়ী নির্ধারিত খাতে করদাতার
                            বিনিয়োগ বা চাঁদা প্রদান থাকলে করদাতা বিনিয়োগজনিত কর রেয়াত পাবেন। করদাতার মোট আয় ১৫ লক্ষ টাকা
                            বা তার কম হলে অনুমোদনযোগ্য (eligible amount) বিনিয়োগের উপর ১৫% হারে; এবং মোট আয় ১৫ লক্ষ
                            টাকার বেশি হলে অনুমোদনযোগ্য বিনিয়োগের উপর ১০% হারে কর রেয়াত পাবেন। মোট আয়ের উপর আরোপযোগ্য
                            আয়করের পরিমাণ থেকে কর রেয়াতের পরিমাণ বাদ দিলে নীট প্রদেয় করের পরিমাণ পাওয়া যাবে। </p>

                        <p>এস,আর,ও নং ২১১-আইন/আয়কর/২০১৭ অনুযায়ী, সরকারি বেতন আদেশভুক্ত চাকুরিজীবীর সরকার কর্তৃক প্রদত্ত
                            শুধু মূল বেতন, বোনাস ও উৎসব ভাতা করযোগ্য আয় হিসেবে বিবেচিত হবে। এছাড়া সরকারি বেতন আদেশে
                            উল্লিখিত সকল ভাতা ও সুবিধাদি করমুক্ত হবে। যেমন, বাড়ী ভাড়া ভাতা, চিকিৎসা ভাতা, যাতায়াত ভাতা,
                            শ্রান্তি বিনোদন ভাতা, বাংলা নববর্ষ ভাতা ইত্যাদি করমুক্ত থাকবে। তবে কোন সরকারি চাকুরিজীবীর
                            বেতন ভাতাদি আদেশ বহির্ভূত কোন আয় থাকলে তা করযোগ্য আয়ের সাথে যোগ হবে এবং এর উপর প্রযোজ্য হারে
                            কর আরোপিত হবে। যেমন, কোন সরকারি চাকুরিজীবীর সরকারি একাডেমিতে লেকচার প্রদান বাবদ আয়, বিভিন্ন
                            সভায় অংশগ্রহণ বাবদ আয়, বিদেশ ভ্রমণ হতে আয়, কোন নিয়োগ পরীক্ষা বা বিভাগীয় পদোন্নতির কোন
                            পরীক্ষার খাতা মূল্যায়ন থেকে প্রাপ্ত আয় ইত্যাদি আয়ের উপর আয়কর প্রযোজ্য হবে। এছাড়াও কোন সরকারি
                            চাকুরিজীবীর বেতন খাতের আয় ব্যতিত অন্য কোন খাতে আয় যেমন, গৃহ-সম্পত্তি খাতে কিংবা ব্যাংক
                            ডিপোজিট হতে সুদ আয় থাকলেও তা করযোগ্য আয়ের সাথে যোগ হবে এবং এর উপর প্রযোজ্য হারে কর আরোপিত
                            হবে। বেতন খাতে করযোগ্য আয় নিরূপণের জন্য পৃথক তফসিল রয়েছে। বেতন খাতে করযোগ্য আয় নিরূপণের জন্য
                            তফসিল-২৪এ এবং বিনিয়োগজনিত কর রেয়াত নিরূপণের জন্য তফসিল-২৪ডি পূরণপূর্বক ব্যক্তি করদাতার জন্য
                            নিধারিত রিটার্ন ফরম আইটি-১১গ২০১৬ এর সাথে সংযুক্ত করতে হবে। এছাড়া করদাতার পরিসম্পদ, দায় ও
                            ব্যয় বিবরণী (আইটি-১০বি২০১৬) এবং জীবনযাত্রা সংশ্লিষ্ট ব্যয়ের বিবরণী(আইটি-১০বিবি২০১৬) মূল
                            রিটানের সাথে সংযুক্ত করতে হবে। </p>

                        <p>এছাড়াও কোন বেসরকারি চাকুরিজীবীর বেতন খাতের আয় ব্যতিত অন্য কোন খাতে আয় যেমন, গৃহ-সম্পত্তি খাতে
                            কিংবা ব্যাংক ডিপোজিট হতে সুদ আয় থাকলেও তা করযোগ্য আয়ের সাথে যোগ হবে এবং এর উপর প্রযোজ্য হারে
                            কর আরোপিত হবে। বেতন খাতে করযোগ্য আয় নিরূপণের জন্য পৃথক তফসিল রয়েছে। বেতন খাতে করযোগ্য আয়
                            নিরূপণের জন্য তফসিল-২৪এ এবং বিনিয়োগজনিত কর রেয়াত নিরূপণের জন্য তফসিল-২৪ডি পূরণপূর্বক ব্যক্তি
                            করদাতার জন্য নিধারিত রিটার্ন ফরম আইটি-১১গ২০১৬ এর সাথে সংযুক্ত করতে হবে। এছাড়া করদাতার
                            পরিসম্পদ, দায় ও ব্যয় বিবরণী (আইটি-১০বি২০১৬) এবং জীবনযাত্রা সংশ্লিষ্ট ব্যয়ের
                            বিবরণী(আইটি-১০বিবি২০১৬) মূল রিটানের সাথে সংযুক্ত করতে হবে। </p>

                        <p>রিটার্ন দাখিলের সময় কোন সরকারি চাকুরিজীবী বিদেশে কর্মরত বা অবস্থানরত থাকলে বিদেশে অবস্থিত বাংলাদেশ দূতাবাসেও রিটার্ন দাখিল করতে পারবেন। এছাড়া কোন সরকারি কর্মকর্তা প্রেষণে বা ছুটিতে বিদেশে উচ্চ শিক্ষারত বা প্রশিক্ষণরত থাকলে বা লিয়েনে বাংলাদেশের বাইরে কর্মরত থাকলে উক্ত প্রেষণ বা লিয়েন সমাপ্তিতে দেশে আসার তিন মাসের মধ্যে তার প্রেষণ বা লিয়েনকালীন সময়ের সকল রিটার্ন একত্রে দাখিল করবেন। আয়কর অধ্যাদেশ, ১৯৮৪ এর ধারা-৭৫, উপধারা-(৩) অনুযায়ী, যে কোন ব্যক্তি করদাতা তার পক্ষে উপযুক্ত ক্ষমতাপ্রাপ্ত ব্যক্তির মাধ্যমেও যথাসময়ে রিটার্ন দাখিল করতে পারবেন। </p>

                        <!-- <p>মো: লোকমান হোসাইন 

                            আয়কর ও কোম্পানী আইন উপদেষ্টা 
                            
                            এডভোকেট, বাংলাদেশ সুপ্রীম কোর্ট 
                            
                            Website: www.lawfirmbd.com </p> -->
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">

                    <section class="widget widget_karlson_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"
                                class="thumb">
                                <span class="fullimage cover bg2"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2021-08-16">August 16, 2021</time>
                                <h4 class="title usmall"><a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R2' | translate }}</a>
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে"
                                class="thumb">
                                <span class="fullimage cover bg1"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2021-08-15">August 15, 2021</time>
                                <h4 class="title usmall"><a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে">{{ 'BLOG.R1' | translate }}</a>
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <!-- <article class="item">
                            <a routerLink="/blog-details"
                                class="thumb">
                                <span class="fullimage cover bg3"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">{{ 'BLOG.R8' | translate }}</a>
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article> -->
                    </section>

                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/বেসরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R2' | translate }}</a></li>
                            <li><a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে">{{ 'BLOG.R1' | translate }}</a></li>
                            <!-- <li><a routerLink="/blog-details">{{ 'BLOG.R8' | translate }}</a></li> -->
                        </ul>
                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->
