<!-- Preloader -->
<div class="preloader">
    <!-- <div class="loader">
        <div class="shadow"></div>
        <div class="box"></div>
    </div> -->
    <div class="loader">
        <div style="justify-content: center !important;
    align-items: center !important;">
            <div class="myLoader"></div>
        </div>
    </div>
</div>
<!-- End Preloader -->
