import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import { AgricultureClass } from './Agriculture.Class';

@Component({
  selector: 'Agriculture-form-control',
  templateUrl: './Agriculture-form-control.component.html',
  styleUrls: ['./Agriculture.component.scss']

})
export class AgricultureControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  pageLoaded: boolean;
  IsCurrentYear: any;

  radioChangeTwo(event, key)
  {


    let drop2: HTMLElement = document.getElementById("ProductionCost") as HTMLElement;


    let IsAccountsRecordMaintened = key === 'IsAccountsRecordMaintened' ? event.value : this.form.controls['IsAccountsRecordMaintened'].value;

    if (IsAccountsRecordMaintened == "1")
    {
      if (drop2.classList.contains('readonly'))
      {
        drop2.classList.remove('readonly');
      }
      this.form.patchValue({
        ProductionCost: "",
        NetIncome: ""
      })
    }
    else
    {
      this.form.patchValue({
        ProductionCost: 0,
        NetIncome: 0
      })
      if (!drop2.classList.contains('readonly'))
      {
        drop2.classList.add('readonly');
      }
      let rawForm = this.form.getRawValue();
      let ProductionCost, NetIncome, GrossRevenue = null;


      rawForm.GrossRevenue == '' ?
        GrossRevenue = 0 : GrossRevenue = rawForm.GrossRevenue;

      rawForm.NetIncome == '' ?
        NetIncome = 0 : NetIncome = rawForm.NetIncome;

      if (isNaN(Number(rawForm.GrossRevenue)) == true)
      {
        GrossRevenue = '0.00';
      }

      if (isNaN(Number(rawForm.ProductionCost)) == true)
      {
        ProductionCost = '0';
      }

      var agricultureClass = new AgricultureClass(
        ProductionCost,
        rawForm.NetIncome,
        GrossRevenue,
        IsAccountsRecordMaintened
      )

      this.form.patchValue({

        NetIncome: Number(agricultureClass.NetIncome).toFixed(2),
        ProductionCost: Number(agricultureClass.ProductionCost).toFixed(2),
      })
    }



  }

  onKey(event: any, key)
  {
    if (key == "ProductionCost" || key == "GrossRevenue")
    {
      let rawForm = this.form.getRawValue();

      let ProductionCost, NetIncome, GrossRevenue = null;



      rawForm.GrossRevenue == '' ?
        GrossRevenue = 0 : GrossRevenue = rawForm.GrossRevenue;

      rawForm.ProductionCost == '' ?
        ProductionCost = 0 : ProductionCost = rawForm.ProductionCost;

      rawForm.NetIncome == '' ?
        NetIncome = 0 : NetIncome = rawForm.NetIncome;




      if (rawForm.IsAccountsRecordMaintened == "" || rawForm.IsAccountsRecordMaintened == "2" || rawForm.IsAccountsRecordMaintened == "false")
      {

        if (isNaN(Number(rawForm.GrossRevenue)) == true)
        {
          GrossRevenue = '0.00';
        }

        if (isNaN(Number(rawForm.ProductionCost)) == true)
        {
          ProductionCost = '0';
        }

        var agricultureClass = new AgricultureClass(
          ProductionCost,
          rawForm.NetIncome,
          GrossRevenue,
          rawForm.IsAccountsRecordMaintened
        )

        this.form.patchValue({

          NetIncome: Number(agricultureClass.NetIncome).toFixed(2),
          ProductionCost: Number(agricultureClass.ProductionCost).toFixed(2),
        })
      }

      if (rawForm.IsAccountsRecordMaintened == "1" || rawForm.IsAccountsRecordMaintened == "true")
      {

        if (isNaN(Number(rawForm.GrossRevenue)) == true)
        {
          GrossRevenue = '0.00';
        }

        if (isNaN(Number(rawForm.ProductionCost)) == true)
        {
          ProductionCost = '0';
        }

        var agricultureClass = new AgricultureClass(
          ProductionCost,
          rawForm.NetIncome,
          GrossRevenue,
          rawForm.IsAccountsRecordMaintened
        )

        this.form.patchValue({
          NetIncome: Number(agricultureClass.NetIncome).toFixed(2),
        })
      }
    }
  }

  get isValid()
  {
    if (this.pageLoaded)
    {
      return this.form.controls[this.control.key].valid;
    } else
    {
      return false;
    }
  }
  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }

    this.pageLoaded = true;

    let drop2: HTMLElement = document.getElementById("ProductionCost") as HTMLElement;

    let IsAccountsRecordMaintened = this.form.controls["IsAccountsRecordMaintened"].value;


    if (IsAccountsRecordMaintened != null)
    {
      if (IsAccountsRecordMaintened == "False" || IsAccountsRecordMaintened == "false" || IsAccountsRecordMaintened == "2" || IsAccountsRecordMaintened == "")
      {
        if (drop2 != null)
        {
          drop2.classList.add('readonly');
        }
        this.form.patchValue({
          IsAccountsRecordMaintened: "2"
        });
      }
      if (IsAccountsRecordMaintened == "True"
        || IsAccountsRecordMaintened == "true"
        || IsAccountsRecordMaintened == "1")
      {
        this.form.patchValue({
          IsAccountsRecordMaintened: "1"
        });
      }
    }
  }
}
