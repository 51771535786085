import { Component, HostListener, Input } from '@angular/core';


import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { AfterViewChecked, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/services/eventemiter.service';
import Utils from 'app/util/utils';
import * as lodash from 'lodash';
import { AutoTransportationService } from './AutoTransportation.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from 'app/auth';




@Component({
    selector: 'AutoTransportation',
    templateUrl: './AutoTransportation.component.html',
    styleUrls: ['./AutoTransportation.component.scss']
})
export class AutoTransportationComponent
{
    name = 'Angular';
    getData: any;
    payLoad: any;
    otherArray: any;
    driverArray: any;
    driverForm: FormGroup;
    showCancel: boolean;
    otherForm: FormGroup;
    categoryId = '3';
    subCategoryIdDriver = '5';
    lang = '';
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    subCategoryIdOther = '6';
    IsCurrentYear: any;
    readonly = false;



    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private _formControlService: AutoTransportationService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _authService: AuthenticationService,
        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,
    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Auto & Transportation Expenses" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Auto & Transportation Expenses" }
        ]);
        this.setTitle("digiTax | Auto & Transportation Expenses");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.driverForm = this.fb.group({
            driverItems: this.fb.array([this.newDriverItem()]),
        });

        this.otherForm = this.fb.group({
            otherItems: this.fb.array([this.newOtherItem()]),
        });

        this.lang = localStorage.getItem('selectedLanguage');

        this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

        this._formControlService.getControlJson(this.categoryId).subscribe((data: any) =>
        {
            this.getData = data.data;
            if (Utils.isEmptyObject(data.data))
            {
                this.driverForm = this.fb.group({
                    driverItems: this.fb.array([this.newDriverItem()]),
                });

                this.otherForm = this.fb.group({
                    otherItems: this.fb.array([this.newOtherItem()]),
                });

                if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
                {
                    this.readonly = true;
                }
                else
                {
                    this.readonly = false;
                }

            }
            else
            {
                let tempDriverData = [];
                let tempOtherData = [];

                this.driverArray = lodash.filter(this.getData, { "ExpSubCategory": parseInt(this.subCategoryIdDriver) });


                if (!Utils.isEmptyObject(this.driverArray))
                {
                    this.driverArray.forEach((item, index) =>
                    {
                        tempDriverData.push(this.getDriverItem(item));
                    });
                    this.driverForm = this.fb.group({
                        driverItems: this.fb.array(tempDriverData),
                    });
                }
                else
                {
                    this.driverForm = this.fb.group({
                        driverItems: this.fb.array([this.newDriverItem()]),
                    });
                }

                this.otherArray = lodash.filter(this.getData, { "ExpSubCategory": parseInt(this.subCategoryIdOther) });

                if (!Utils.isEmptyObject(this.otherArray))
                {
                    this.otherArray.forEach((item, index) =>
                    {
                        tempOtherData.push(this.getOtherItem(item));
                    });
                    this.otherForm = this.fb.group({
                        otherItems: this.fb.array(tempOtherData),
                    });
                }
                else
                {
                    this.otherForm = this.fb.group({
                        otherItems: this.fb.array([this.newOtherItem()]),
                    });
                }
                if (this.driverArray == null && this.otherArray == null)
                {
                    this.showCancel = false;
                }
                else { this.showCancel = true; }

                if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
                {
                    this.readonly = true;
                }
                else
                {
                    this.readonly = false;
                }
            }
            if (data != null)
            {
                this._spinner.hide();
            }
        });

        if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
        {
            this.readonly = true;
        }
        else
        {
            this.readonly = false;
        }
    }

    ngOnInit(): void
    {

    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngAfterViewChecked()
    {
        this.cdr.detectChanges();
    }

    driverItems(): FormArray
    {
        return this.driverForm.get("driverItems") as FormArray
    }

    otherItems(): FormArray
    {
        return this.otherForm.get("otherItems") as FormArray
    }

    getDriverItem(item): FormGroup
    {
        return this.fb.group({
            Id: item.Id,
            ALEId: item.ALEId,
            SlNo: item.SlNo,
            ExpCategory: item.ExpCategory,
            ExpSubCategory: item.ExpSubCategory,
            Amount: item.Amount,
            Comment: item.Comment,
            Description: item.Description,
        })
    }

    getOtherItem(item): FormGroup
    {
        return this.fb.group({
            Id: item.Id,
            ALEId: item.ALEId,
            SlNo: item.SlNo,
            ExpCategory: item.ExpCategory,
            ExpSubCategory: item.ExpSubCategory,
            Amount: item.Amount,
            Comment: item.Comment,
            Description: item.Description,
        })
    }

    newDriverItem(): FormGroup
    {
        return this.fb.group({
            SlNo: '',
            ExpCategory: this.categoryId,
            ExpSubCategory: this.subCategoryIdDriver,
            Amount: '',
            Comment: '',
            Description: '',
        })
    }

    newOtherItem(): FormGroup
    {
        return this.fb.group({
            SlNo: '',
            ExpCategory: this.categoryId,
            ExpSubCategory: this.subCategoryIdOther,
            Amount: '',
            Comment: '',
            Description: '',
        })
    }

    addDriverItem()
    {
        this.driverItems().push(this.newDriverItem());
    }

    addOtherItem()
    {
        this.otherItems().push(this.newOtherItem());
    }

    removeDriverItem(i: number)
    {
        this.driverItems().removeAt(i);
    }

    removeOtherItem(i: number)
    {
        this.otherItems().removeAt(i);
    }

    mySubmitExit(param): void
    {
        // debugger;
        if (param == 'next' || param == 'more')
        {
            this.OnSubmit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }


    OnSubmit(param): void
    {
        this._spinner.show();
        let driverItems = this.driverForm.value.driverItems;
        driverItems.forEach((item, index) =>
        {
            let i = index + 1;
            if (item.Amount == "" || item.Amount == "0" || isNaN(Number(item.Amount)) == true)
            {
                item.Amount = 0
            }
            item.Id != null ? item.Id = item.Id : item.Id = 0
            item.ALEId != null ? item.ALEId = item.ALEId : item.ALEId = 0
            item.SlNo = i.toString();
        });

        let otherItems = this.otherForm.value.otherItems;
        otherItems.forEach((item, index) =>
        {
            let i = index + 1;
            if (item.Amount == "" || item.Amount == "0" || isNaN(Number(item.Amount)) == true)
            {
                item.Amount = 0
            }
            item.Id != null ? item.Id = item.Id : item.Id = 0
            item.ALEId != null ? item.ALEId = item.ALEId : item.ALEId = 0
            item.SlNo = i.toString();
        });

        let stringed = driverItems.concat(otherItems);

        stringed = stringed.filter(item => item.Amount != 0);

        // if (stringed.length === 0)
        // {
        //     this._spinner.hide();
        //     this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_ENTER_AMOUNT : bangla.data.COMMON.BUTTON_ENTER_AMOUNT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
        //         verticalPosition: 'top',
        //         duration: 2000
        //     });
        // }
        // else
        // {
        this._formControlService.submitExpense(stringed, this.categoryId)
            .subscribe((data: any) =>
            {
                if (data != null)
                {
                    this._spinner.hide();
                }

                if (data.isError === false)
                {
                    console.log('submit succesfull');
                }
                else
                {

                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                if (data.isError === false && data.responseCode == "200")
                {

                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    if (param == 'next')
                    {
                        this._router.navigate(['/HouseholdUtility']);
                    }
                    if (param == 'exit')
                    {
                        this._authService.Logout();
                    }
                }
            }, (error: any) =>
            {
                this._spinner.hide();
                console.log(error, 'error');
            });
        // }
    }

    ConfirmLeave(url): void
    {
        if (this.driverForm.dirty || this.otherForm.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this._router.navigate([url]);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            this._router.navigate([url]);
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
