import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { TransactionComponent } from './Transaction.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseCountdownModule, FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TransactionService } from './Transaction.service';
import { ViewProfileGuard } from 'app/auth/viewProfile.guard';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatDialogModule } from '@angular/material/dialog';
import { CreateBillDialog } from './create-bill-dialog/create-bill-Dialog.component';
import { CreateCollectionDialog } from './create-collection-dialog/create-collection-Dialog.component';
import { CreateRemissionDialog } from './create-remission-dialog/create-remission-Dialog.component';

const routes = [
    {
        path: 'Transaction',
        component: TransactionComponent,
        canActivate: [AuthGuard, ViewProfileGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        TransactionComponent,
        CreateBillDialog,
        CreateCollectionDialog,
        CreateRemissionDialog
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatDialogModule,

        MatButtonModule,

        MatFormFieldModule,

        MatInputModule,

        MatTabsModule,

        MatCheckboxModule,

        MatSnackBarModule,

        MatCheckboxModule,

        MatSelectModule,

        MatAutocompleteModule,

        MatDatepickerModule,

        FuseCountdownModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,

        NgCircleProgressModule

    ],
    exports: [
        TransactionComponent
    ],
    providers: [

        TransactionService
    ],
})

export class TransactionModule
{
}



