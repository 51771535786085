
export class GovtSalaryModel
{
  Id: any;
  BasicPayAmount: string = "";
  BasicPayExempted: string = "";
  BasicPayTaxable: string = "";
  SpecialPayAmount: string = "";
  SpecialPayExempted: string = "";
  SpecialPayTaxable: string = "";
  DearnessAllowanceAmount: string = "";
  DearnessAllowanceExempted: string = "";
  DearnessAllowanceTaxable: string = "";
  ConveyanceAllowanceAmount: string = "";
  ConveyanceAllowanceExempted: string = "";
  ConveyanceAllowanceTaxable: string = "";
  HouseRentAllowanceAmount: string = "";
  HouseRentAllowanceExempted: string = "";
  HouseRentAllowanceTaxable: string = "";
  MedicalAllowanceAmount: string = "";
  MedicalAllowanceExempted: string = "";
  MedicalAllowanceTaxable: string = "";
  AllowanceForSupportStaffAmount: string = "";
  AllowanceForSupportStaffExempted: string = "";
  AllowanceForSupportStaffTaxable: string = "";
  LeaveFairAssistanceStaffAmount: string = "";
  LeaveFairAssistanceStaffExempted: string = "";
  LeaveFairAssistanceStaffTaxable: string = "";
  LeaveEncashmentAmount: string = "";
  LeaveEncashmentExempted: string = "";
  LeaveEncashmentTaxable: string = "";
  HonorariumOrRewardAmount: string = "";
  HonorariumOrRewardExempted: string = "";
  HonorariumOrRewardTaxable: string = "";
  OvertimeAllowanceAmount: string = "";
  OvertimeAllowanceExempted: string = "";
  OvertimeAllowanceTaxable: string = "";
  BonusAmount: string = "";
  BonusExempted: string = "";
  BonusTaxable: string = "";
  OtherAllowancesAmount: string = "";
  OtherAllowancesExempted: string = "";
  OtherAllowancesTaxable: string = "";
  EmployersContributionProvidentFundAmount: string = "";
  EmployersContributionProvidentFundExempted: string = "";
  EmployersContributionProvidentFundTaxable: string = "";
  InterestAccruedProvidentFundRateOfInterestAmount: string = "";
  InterestAccruedProvidentFundRateOfInterestExempted: string = "";
  InterestAccruedProvidentFundRateOfInterestTaxable: string = "";
  DeemedIncomeTransportAmount: string = "";
  DeemedIncomeTransportExempted: string = "";
  DeemedIncomeTransportTaxable: string = "";
  EmployerPaidRentalAmount: string = "";
  DeemedFreeAccommodationExempted: string = "";
  FurnishedOrUnfurnishedHouseFromEmployerTaxable: string = "";
  FestivalBonusAmount: string = "";
  FestivalBonusExempted: string = "";
  FestivalBonusTaxable: string = "";
  BengaliNewYearBonusAmount: string = "";
  BengaliNewYearBonusExempted: string = "";
  BengaliNewYearBonusTaxable: string = "";
  FestivalAllowanceAmount: string = "";
  FestivalAllowanceExempted: string = "";
  FestivalAllowanceTaxable: string = "";
  PensionAmount: string = "";
  PensionExempted: string = "";
  PensionTaxable: string = "";
  ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount: string = "";
  ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted: string = "";
  ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable: string = "";
  OthersAmount: string = "";
  OthersExempted: string = "";
  OthersTaxable: string = "";
  ArrearPayAmount: string = "";
  ArrearPayExempted: string = "";
  ArrearPayTaxable: string = "";
  TotalAmount: string = "";
  TotalExempted: string = "";
  NetTaxable: string = "";
  GrossAmount: string = "";
  GrossExempted: string = "";
  GrossTaxable: string = "";
  OrganizationName: string = "";
  OrganizationAddress: string = "";
  BIN: string = "";

}
