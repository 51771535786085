<h2 mat-dialog-title>SAVED TEMPLATE</h2>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16">
        <div fxLayoutAlign="start center"
            fxLayout="row">
            <table class="simple invoice-table"
                *ngIf="dialogdata.data!=null">

                <thead>
                    <tr>
                        <th class="thNewline"
                            style="color: #444444;">
                            SL
                        </th>
                        <th class="thNewline"
                            style="color: #444444;">
                            TITLE
                        </th>
                        <th class="thNewline"
                            style="color: #444444;">
                            MESSAGE TEXT
                        </th>
                        <th class="text-right thNewline"
                            style="color: #444444;">
                            ACTIONS
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let data of dialogdata.data; let i=index">

                        <td>
                            {{i + 1}}
                        </td>
                        <td>
                            {{data.TemplateName}}
                        </td>
                        <td class="text-right">
                            {{data.MessageText}}
                        </td>
                        <td>
                            <div fxLayout="row wrap"
                                fxLayoutAlign="start center">

                                <a style="cursor: pointer; border: 0; color: #29793C;"
                                    fxLayout="column wrap"
                                    (click)="passData(data)"
                                    matTooltip="Select Template">
                                    <mat-icon class="nav-link-icon">launch</mat-icon>
                                </a>
                                <a style="cursor: pointer; border: 0; color: #29793C;"
                                    fxLayout="column wrap"
                                    (click)="onEditClick(data)"
                                    matTooltip="{{ 'COMMON.BUTTON_EDIT' | translate }}">
                                    <mat-icon class="nav-link-icon">create</mat-icon>
                                </a>

                                <a style="cursor: pointer; border: 0; color: #29793C;"
                                    fxLayout="column wrap"
                                    (click)="deleteData(data)"
                                    matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                    <mat-icon class="nav-link-icon"
                                        style="color: #29793C;">delete</mat-icon>
                                </a>
                            </div>

                        </td>

                    </tr>
                </tbody>
            </table>
            <div *ngIf="dialogdata.data==null">
                <span>No template saved yet</span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
    <button mat-button
        class="warn"
        fxFlex="0 0 auto"
        fxLayout="row"
        fxLayoutAlign="center center"
        style="padding: 10px; margin-right: 10px; border: 0; cursor: pointer;"
        matTooltip="close"
        (click)="onNoClick()">
        <!-- <mat-icon class="s-16 mr-sm-4">arrow_upward</mat-icon> -->
        <span>CLOSE</span>
    </button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
