<div [formGroup]="form">

  <div fxLayout="column"
    fxLayoutAlign="start">
    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">
      <div fxFlex="40"
        style="margin-right: 8px !important; "
        fxLayoutAlign="center center">
        <div style="font-weight: bold;color: #444444;">{{ 'OTHERSOURCES.HEADER_1' | translate }}</div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center"
        style="margin-right: 8px !important; ">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'OTHERSOURCES.HEADER_2' | translate }}
        </div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center"
        style="margin-right: 8px !important; ">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'OTHERSOURCES.HEADER_3' | translate }}
        </div>
      </div>
      <div fxFlex="20"
        fxLayoutAlign="center center">
        <div style="text-align: center; font-weight: bold;color: #444444;">{{ 'OTHERSOURCES.HEADER_4' | translate }}
        </div>
      </div>
    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.InterestMutualOrUnitFund' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InterestMutualOrUnitFundAmount"
          (keyup)="onFocusoutEvent($event,'InterestMutualOrUnitFundAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InterestMutualOrUnitFundTDS"
          (keyup)="onFocusoutEvent($event,'InterestMutualOrUnitFundTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="InterestMutualOrUnitFundTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.CashDividendStockExchange' | translate }}
      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="CashDividendStockExchangeAmount"
          (keyup)="onFocusoutEvent($event,'CashDividendStockExchangeAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="CashDividendStockExchangeTDS"
          (keyup)="onFocusoutEvent($event,'CashDividendStockExchangeTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="CashDividendStockExchangeTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.InterestIncomeWEDB' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InterestIncomeWEDBAmount"
          (keyup)="onFocusoutEvent($event,'InterestIncomeWEDBAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InterestIncomeWEDBTDS"
          (keyup)="onFocusoutEvent($event,'InterestIncomeWEDBTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          NumbersOnly
          autocomplete="off"
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="InterestIncomeWEDBTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.USDollarPremiumInvestmentBond' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="USDollarPremiumInvestmentBondAmount"
          (keyup)="onFocusoutEvent($event,'USDollarPremiumInvestmentBondAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="USDollarPremiumInvestmentBondTDS"
          (keyup)="onFocusoutEvent($event,'USDollarPremiumInvestmentBondTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="USDollarPremiumInvestmentBondTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.PoundSterlingPremiumInvestmentBond' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="PoundSterlingPremiumInvestmentBondAmount"
          (keyup)="onFocusoutEvent($event,'PoundSterlingPremiumInvestmentBondAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="PoundSterlingPremiumInvestmentBondTDS"
          (keyup)="onFocusoutEvent($event,'PoundSterlingPremiumInvestmentBondTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="PoundSterlingPremiumInvestmentBondTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.EuroPremiumInvestmentBond' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="EuroPremiumInvestmentBondAmount"
          (keyup)="onFocusoutEvent($event,'EuroPremiumInvestmentBondAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="EuroPremiumInvestmentBondTDS"
          (keyup)="onFocusoutEvent($event,'EuroPremiumInvestmentBondTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="EuroPremiumInvestmentBondTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.ZeroCouponBond' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="ZeroCouponBondAmount"
          (keyup)="onFocusoutEvent($event,'ZeroCouponBondAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="ZeroCouponBondTDS"
          (keyup)="onFocusoutEvent($event,'ZeroCouponBondTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="ZeroCouponBondTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.InvestmentPensionerSavingsInstrument' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InvestmentPensionerSavingsInstrumentAmount"
          (keyup)="onFocusoutEvent($event,'InvestmentPensionerSavingsInstrumentAmount')"
          matInput>
      </mat-form-field>

      <div fxFlex="20"
        fxLayoutAlign="center center"
        style="margin-right: 8px !important;">

      </div>

      <div fxFlex="20"
        fxLayoutAlign="center center">

      </div>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.InterestReceivedPensionerSavingsInstrument' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InterestReceivedPensionerSavingsInstrumentAmount"
          (keyup)="onFocusoutEvent($event,'InterestReceivedPensionerSavingsInstrumentAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="InterestReceivedPensionerSavingsInstrumentTDS"
          (keyup)="onFocusoutEvent($event,'InterestReceivedPensionerSavingsInstrumentTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="InterestReceivedPensionerSavingsInstrumentTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.SumReceivedBecauseOfPositionLikeDirectorFee' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="SumReceivedBecauseOfPositionLikeDirectorFeeAmount"
          (keyup)="onFocusoutEvent($event,'SumReceivedBecauseOfPositionLikeDirectorFeeAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="SumReceivedBecauseOfPositionLikeDirectorFeeTDS"
          (keyup)="onFocusoutEvent($event,'SumReceivedBecauseOfPositionLikeDirectorFeeTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="SumReceivedBecauseOfPositionLikeDirectorFeeTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.HonoriumWritingArticle' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="HonoriumWritingArticleAmount"
          (keyup)="onFocusoutEvent($event,'HonoriumWritingArticleAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="HonoriumWritingArticleTDS"
          (keyup)="onFocusoutEvent($event,'HonoriumWritingArticleTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="HonoriumWritingArticleTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.RemunerationExaminerInvigilator' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="RemunerationExaminerInvigilatorAmount"
          (keyup)="onFocusoutEvent($event,'RemunerationExaminerInvigilatorAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="RemunerationExaminerInvigilatorTDS"
          (keyup)="onFocusoutEvent($event,'RemunerationExaminerInvigilatorTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="RemunerationExaminerInvigilatorTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.IncomeTuition' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="IncomeTuitionAmount"
          (keyup)="onFocusoutEvent($event,'IncomeTuitionAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="IncomeTuitionTDS"
          (keyup)="onFocusoutEvent($event,'IncomeTuitionTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="IncomeTuitionTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #dde1e2;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.IncomeTelevisionRadioParticipatingProgram' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="IncomeTelevisionRadioParticipatingProgramAmount"
          (keyup)="onFocusoutEvent($event,'IncomeTelevisionRadioParticipatingProgramAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="IncomeTelevisionRadioParticipatingProgramTDS"
          (keyup)="onFocusoutEvent($event,'IncomeTelevisionRadioParticipatingProgramTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="IncomeTelevisionRadioParticipatingProgramTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important;">
        {{ 'OTHERSOURCES.Others' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="OthersAmount"
          (keyup)="onFocusoutEvent($event,'OthersAmount')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          formControlName="OthersTDS"
          (keyup)="onFocusoutEvent($event,'OthersTDS')"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="OthersTaxable"
          matInput>
      </mat-form-field>

    </div>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      style="padding-top: 10px; padding-bottom: 10px;"
      fxFlex="1 0 auto">

      <div fxFlex="40"
        style="margin-right: 8px !important; margin-left: 8px !important; font-weight: bold;color: #444444;">
        {{ 'OTHERSOURCES.Total' | translate }}

      </div>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="TotalAmount"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly"
        style="margin-right: 8px !important;">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="TotalTDS"
          matInput>
      </mat-form-field>

      <mat-form-field appearance="outline"
        fxFlex="20"
        class="readonly">
        <input class="rightA"
          autocomplete="off"
          NumbersOnly
          type="text"
          decimals="100"
          negative="1"
          tabindex="-1"
          formControlName="TotalTaxable"
          matInput>
      </mat-form-field>

    </div>
  </div>

</div>
