<section class="about-area ptb-100"
    style="background-color: #F5F5FC;">
    <div class="container">
        <div class="row align-items-center flex-column-reverse flex-lg-row">
            <div class="col-lg-7 col-md-12">

                <div class="about-content">
                    <h3 style="text-align: center; margin-bottom: 30px;">{{ 'HOW_IT_WORKS.HEADER_2' | translate }}</h3>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item"
                            src="https://www.youtube.com/embed/-f7pLaUHv8g"
                            allowfullscreen></iframe>
                    </div>

                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div style="padding: 48px 48px 48px 48px; background-color: #fff;">

                    <div style="width: 128px;
                    margin: 32px auto;">
                        <img src="assets/home/img/logo-n.png"
                            style="max-width: 100%;height: auto;vertical-align: top;border: none;">
                    </div>

                    <div style="text-align: center;font-size: 21px;font-weight: 300;line-height: 1;">{{
                        'AUTH_TRAN.TEXT_RECOVER' | translate }}</div>

                    <div *ngIf="isLoginError">
                        <span style="margin-left: 6px; color: #f44336;">Invalid or unverified email address.</span>
                    </div>

                    <form name="forgoPasswordForm"
                        style="width: 100%;padding-top: 32px;"
                        [formGroup]="forgotPasswordForm"
                        novalidate
                        (ngSubmit)="OnSubmit()">

                        <div class="row align-items-center">

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email"
                                        formControlName="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="{{ 'AUTH_TRAN.PLACEHOLDER_EMAIL' | translate }}">
                                    <span class="text-danger"
                                        *ngIf="(forgotPasswordFormControl.email.touched || submitted) && forgotPasswordFormControl.email.errors?.required">
                                        &nbsp; Email is required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="forgotPasswordFormControl.email.touched && forgotPasswordFormControl.email.errors?.invalidEmail">
                                        &nbsp; Enter a valid email address
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12"
                                style="margin: 16px auto;">
                                <button
                                    style="background-color: #29793C !important;color: #fff !important;width: 100%;margin: 16px auto;display: block;box-sizing: border-box;position: relative;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;cursor: pointer;outline: none;border: none;-webkit-tap-highlight-color: transparent;display: inline-block;white-space: nowrap;text-decoration: none;text-align: center;margin: 0;min-width: 64px;line-height: 36px;padding: 0 16px;border-radius: 4px;overflow: visible;transform: translate3d(0, 0, 0);transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);"
                                    aria-label="SEND RESET LINK"
                                    type="submit">
                                    {{ 'AUTH_TRAN.TEXT_SEND_LINK' | translate }}
                                </button>
                            </div>

                        </div>
                    </form>

                    <div
                        style="flex-direction: column;box-sizing: border-box;display: flex;place-content: center;align-items: center;margin: 16px auto;">

                        <a class="link"
                            style="color: #29793C !important; font-weight: bold;"
                            [routerLink]="'/login-home'">{{ 'AUTH_TRAN.TEXT_BACK_LOGIN' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>
