import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PersonalizeReturnComponent } from './Personalize-Return.component';


import { PersonalizeReturnControlComponent } from './Personalize-Return-form-control.component';
import { PersonalizeReturnService } from './Personalize-Return.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseDemoModule, FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ViewDashboardGuard } from 'app/auth/viewDashboard.guard';
import { MatMenuModule } from '@angular/material/menu';

const routes = [
    {
        // path: 'PersonalizeReturn/save',
        path: 'PersonalizeReturn',
        component: PersonalizeReturnComponent,
        canActivate: [AuthGuard, ViewDashboardGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        PersonalizeReturnComponent,
        PersonalizeReturnControlComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatFormFieldModule,

        MatInputModule,

        MatMenuModule,

        MatSnackBarModule,

        MatListModule,

        MatSlideToggleModule,

        MatSelectModule,

        MatDialogModule,

        MatRadioModule,

        MatDatepickerModule,

        MatTooltipModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,
    ],
    exports: [
        PersonalizeReturnComponent
    ],
    providers: [

        PersonalizeReturnService
    ],
})

export class PersonalizeReturnModule
{
}
