<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{ 'COMMON.FOOLTER_CI' | translate }}</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>{{ 'COMMON.FOOLTER_WORK' | translate }}</span>
                            <a href="tel:09643 224488">{{ 'CONTACT.R5' | translate }}</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>{{ 'COMMON.FOOLTER_QUE' | translate }}</span>
                            <a href="mailto:support@digitax.com.bd">support@digitax.com.bd</a>
                        </li>
                        <li>
                            <i class="flaticon-social-media"></i>
                            <span>{{ 'COMMON.FOOLTER_SN' | translate }}</span>
                            <ul class="social">
                                <li><a href="https://www.facebook.com/digitax.com.bd/"
                                        target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/digitaxbd/"
                                        target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UCzYPNErfKeBNqBzxccr26qg"
                                        target="_blank"><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>{{ 'COMMON.FOOLTER_QL' | translate }}</h3>

                    <ul class="footer-quick-links">
                        <li>
                            <a routerLink="/">{{ 'COMMON.NAV_HOME' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/how-it-works">{{ 'COMMON.NAV_HOW' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/about">{{ 'COMMON.NAV_ABOUT' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/pricing">{{ 'COMMON.NAV_PRICE' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/faq">{{ 'COMMON.NAV_HELP' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/terms-and-conditions">{{ 'COMMON.NAV_TC' | translate }}</a>
                        </li>

                        <li>
                            <a routerLink="/contact">{{ 'COMMON.NAV_CONTACT' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/return-and-refund-policy">{{ 'COMMON.NAV_RRP' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/blog">{{ 'COMMON.NAV_BLOG' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/privacy-policy">{{ 'COMMON.NAV_PP' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/news-events">{{ 'COMMON.NAV_NEWS' | translate }}</a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>&copy; Copyright {{currentYear}} <a href="https://deshuniversal.com/home"
                            target="_blank">Desh Universal Pvt Limited</a>. All rights reserved</p>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li>Business Identification Number: 002771308-0401</li>
                        <!-- <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/return-and-refund-policy">Return & Refund Policy</a></li> -->

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-chevron-up"></i></div>
