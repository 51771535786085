import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { TaxRebateClass } from './TaxRebate.Class';

@Component({
  selector: 'TaxRebate-form-control',
  templateUrl: './TaxRebate-form-control.component.html',
  styleUrls: ['./TaxRebate.component.scss']

})
export class TaxRebateControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  IsCurrentYear: any;



  onFocusoutEvent(event, key)
  {

    let rawForm = this.form.getRawValue();
    let _maxLimiteOfdeposit = 60000;
    let _maxCombinedAllowedShanchaypatraAndTreasuryBond = 60000;

    let LifeInsurancePolicyValue, LifeInsurancePremiumAmount, LifeInsurancePremiumAllowed = null;

    rawForm.LifeInsurancePolicyValue == '' ?
      LifeInsurancePolicyValue = 0 : LifeInsurancePolicyValue = rawForm.LifeInsurancePolicyValue;
    LifeInsurancePolicyValue = parseFloat(LifeInsurancePolicyValue);

    rawForm.LifeInsurancePremiumAmount == '' ?
      LifeInsurancePremiumAmount = 0 : LifeInsurancePremiumAmount = rawForm.LifeInsurancePremiumAmount;
    LifeInsurancePremiumAmount = parseFloat(LifeInsurancePremiumAmount);

    rawForm.LifeInsurancePremiumAllowed == '' ?
      LifeInsurancePremiumAllowed = 0 : LifeInsurancePremiumAllowed = rawForm.LifeInsurancePremiumAllowed;
    LifeInsurancePremiumAllowed = parseFloat(LifeInsurancePremiumAllowed);

    let ContributionToDepositPensionSchemeAmount, ContributionToDepositPensionSchemeAllowed = null;

    rawForm.ContributionToDepositPensionSchemeAmount == '' ?
      ContributionToDepositPensionSchemeAmount = 0 : ContributionToDepositPensionSchemeAmount = rawForm.ContributionToDepositPensionSchemeAmount;
    ContributionToDepositPensionSchemeAmount = parseFloat(ContributionToDepositPensionSchemeAmount);

    rawForm.ContributionToDepositPensionSchemeAllowed == '' ?
      ContributionToDepositPensionSchemeAllowed = 0 : ContributionToDepositPensionSchemeAllowed = rawForm.ContributionToDepositPensionSchemeAllowed;
    ContributionToDepositPensionSchemeAllowed = parseFloat(ContributionToDepositPensionSchemeAllowed);

    let InvestmentInApprovedSavingsCertificateAmount,
      InvestmentInApprovedSavingsCertificateAllowed = null;

    rawForm.InvestmentInApprovedSavingsCertificateAmount == '' ?
      InvestmentInApprovedSavingsCertificateAmount = 0 : InvestmentInApprovedSavingsCertificateAmount = rawForm.InvestmentInApprovedSavingsCertificateAmount;
    InvestmentInApprovedSavingsCertificateAmount = parseFloat(InvestmentInApprovedSavingsCertificateAmount);

    rawForm.InvestmentInApprovedSavingsCertificateAllowed == '' ?
      InvestmentInApprovedSavingsCertificateAllowed = 0 : InvestmentInApprovedSavingsCertificateAllowed = rawForm.InvestmentInApprovedSavingsCertificateAllowed;
    InvestmentInApprovedSavingsCertificateAllowed = parseFloat(InvestmentInApprovedSavingsCertificateAllowed);


    let
      InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount,
      InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed = null;

    rawForm.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount == '' ?
      InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount = 0 : InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount = rawForm.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount;
    InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount = parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount);

    rawForm.InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed == '' ?
      InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed = 0 : InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed = rawForm.InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed;
    InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed = parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed);

    let
      ContributionToProvidentFundToWhichProvidentFundAmount,
      ContributionToProvidentFundToWhichProvidentFundAllowed = null;

    rawForm.ContributionToProvidentFundToWhichProvidentFundAmount == '' ?
      ContributionToProvidentFundToWhichProvidentFundAmount = 0 : ContributionToProvidentFundToWhichProvidentFundAmount = rawForm.ContributionToProvidentFundToWhichProvidentFundAmount;
    ContributionToProvidentFundToWhichProvidentFundAmount = parseFloat(ContributionToProvidentFundToWhichProvidentFundAmount);

    rawForm.ContributionToProvidentFundToWhichProvidentFundAllowed == '' ?
      ContributionToProvidentFundToWhichProvidentFundAllowed = 0 : ContributionToProvidentFundToWhichProvidentFundAllowed = rawForm.ContributionToProvidentFundToWhichProvidentFundAllowed;
    ContributionToProvidentFundToWhichProvidentFundAllowed = parseFloat(ContributionToProvidentFundToWhichProvidentFundAllowed);

    let
      SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount,
      SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed = null;

    rawForm.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount == '' ?
      SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount = 0 : SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount = rawForm.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount;
    SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount = parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount);

    rawForm.SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed == '' ?
      SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed = 0 : SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed = rawForm.SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed;
    SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed = parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed);

    let
      ContributionToSuperAnnuationFundAmount,
      ContributionToSuperAnnuationFundAllowed = null;

    rawForm.ContributionToSuperAnnuationFundAmount == '' ?
      ContributionToSuperAnnuationFundAmount = 0 : ContributionToSuperAnnuationFundAmount = rawForm.ContributionToSuperAnnuationFundAmount;
    ContributionToSuperAnnuationFundAmount = parseFloat(ContributionToSuperAnnuationFundAmount);

    rawForm.ContributionToSuperAnnuationFundAllowed == '' ?
      ContributionToSuperAnnuationFundAllowed = 0 : ContributionToSuperAnnuationFundAllowed = rawForm.ContributionToSuperAnnuationFundAllowed;
    ContributionToSuperAnnuationFundAllowed = parseFloat(ContributionToSuperAnnuationFundAllowed);

    let
      ContributionToBenevolentFundAndGroupInsurancePremiumAmount,
      ContributionToBenevolentFundAndGroupInsurancePremiumAllowed = null;

    rawForm.ContributionToBenevolentFundAndGroupInsurancePremiumAmount == '' ?
      ContributionToBenevolentFundAndGroupInsurancePremiumAmount = 0 : ContributionToBenevolentFundAndGroupInsurancePremiumAmount = rawForm.ContributionToBenevolentFundAndGroupInsurancePremiumAmount;
    ContributionToBenevolentFundAndGroupInsurancePremiumAmount = parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAmount);

    rawForm.ContributionToBenevolentFundAndGroupInsurancePremiumAllowed == '' ?
      ContributionToBenevolentFundAndGroupInsurancePremiumAllowed = 0 : ContributionToBenevolentFundAndGroupInsurancePremiumAllowed = rawForm.ContributionToBenevolentFundAndGroupInsurancePremiumAllowed;
    ContributionToBenevolentFundAndGroupInsurancePremiumAllowed = parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAllowed);

    let
      InvestmentInBangladeshGovtTreasuryBondAmount,
      InvestmentInBangladeshGovtTreasuryBondAllowed = null;

    rawForm.InvestmentInBangladeshGovtTreasuryBondAmount == '' ?
      InvestmentInBangladeshGovtTreasuryBondAmount = 0 : InvestmentInBangladeshGovtTreasuryBondAmount = rawForm.InvestmentInBangladeshGovtTreasuryBondAmount;
    InvestmentInBangladeshGovtTreasuryBondAmount = parseFloat(InvestmentInBangladeshGovtTreasuryBondAmount);

    rawForm.InvestmentInBangladeshGovtTreasuryBondAllowed == '' ?
      InvestmentInBangladeshGovtTreasuryBondAllowed = 0 : InvestmentInBangladeshGovtTreasuryBondAllowed = rawForm.InvestmentInBangladeshGovtTreasuryBondAllowed;
    InvestmentInBangladeshGovtTreasuryBondAllowed = parseFloat(InvestmentInBangladeshGovtTreasuryBondAllowed);

    let
      MutualFundAmount,
      MutualFundAllowed = null;

    rawForm.MutualFundAmount == '' ?
      MutualFundAmount = 0 : MutualFundAmount = rawForm.MutualFundAmount;
    MutualFundAmount = parseFloat(MutualFundAmount);

    rawForm.MutualFundAllowed == '' ?
      MutualFundAllowed = 0 : MutualFundAllowed = rawForm.MutualFundAllowed;
    MutualFundAllowed = parseFloat(MutualFundAllowed);

    let
      ContributionToZakatFundAmount,
      ContributionToZakatFundAllowed = null;

    rawForm.ContributionToZakatFundAmount == '' ?
      ContributionToZakatFundAmount = 0 : ContributionToZakatFundAmount = rawForm.ContributionToZakatFundAmount;
    ContributionToZakatFundAmount = parseFloat(ContributionToZakatFundAmount);

    rawForm.ContributionToZakatFundAllowed == '' ?
      ContributionToZakatFundAllowed = 0 : ContributionToZakatFundAllowed = rawForm.ContributionToZakatFundAllowed;
    ContributionToZakatFundAllowed = parseFloat(ContributionToZakatFundAllowed);

    let
      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount,
      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed = null;

    rawForm.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount == '' ?
      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount = 0 : DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount = rawForm.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount;
    DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount = parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount);

    rawForm.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed == '' ?
      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed = 0 : DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed = rawForm.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed;
    DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed = parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed);

    let
      DonationToCharitableHospitalRecognizedByNBRAmount,
      DonationToCharitableHospitalRecognizedByNBRAllowed = null;

    rawForm.DonationToCharitableHospitalRecognizedByNBRAmount == '' ?
      DonationToCharitableHospitalRecognizedByNBRAmount = 0 : DonationToCharitableHospitalRecognizedByNBRAmount = rawForm.DonationToCharitableHospitalRecognizedByNBRAmount;
    DonationToCharitableHospitalRecognizedByNBRAmount = parseFloat(DonationToCharitableHospitalRecognizedByNBRAmount);

    rawForm.DonationToCharitableHospitalRecognizedByNBRAllowed == '' ?
      DonationToCharitableHospitalRecognizedByNBRAllowed = 0 : DonationToCharitableHospitalRecognizedByNBRAllowed = rawForm.DonationToCharitableHospitalRecognizedByNBRAllowed;
    DonationToCharitableHospitalRecognizedByNBRAllowed = parseFloat(DonationToCharitableHospitalRecognizedByNBRAllowed);


    let
      DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount,
      DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed = null;

    rawForm.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount == '' ?
      DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount = 0 : DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount = rawForm.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount;
    DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount = parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount);

    rawForm.DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed == '' ?
      DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed = 0 : DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed = rawForm.DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed;
    DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed = parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed);

    let
      ContributionToLiberationWarMusuemAmount,
      ContributionToLiberationWarMusuemAllowed = null;

    rawForm.ContributionToLiberationWarMusuemAmount == '' ?
      ContributionToLiberationWarMusuemAmount = 0 : ContributionToLiberationWarMusuemAmount = rawForm.ContributionToLiberationWarMusuemAmount;
    ContributionToLiberationWarMusuemAmount = parseFloat(ContributionToLiberationWarMusuemAmount);

    rawForm.ContributionToLiberationWarMusuemAllowed == '' ?
      ContributionToLiberationWarMusuemAllowed = 0 : ContributionToLiberationWarMusuemAllowed = rawForm.ContributionToLiberationWarMusuemAllowed;
    ContributionToLiberationWarMusuemAllowed = parseFloat(ContributionToLiberationWarMusuemAllowed);

    let
      ContributionToAgaKahanDeveleopmentNetworkAmount,
      ContributionToAgaKahanDeveleopmentNetworkAllowed = null;

    rawForm.ContributionToAgaKahanDeveleopmentNetworkAmount == '' ?
      ContributionToAgaKahanDeveleopmentNetworkAmount = 0 : ContributionToAgaKahanDeveleopmentNetworkAmount = rawForm.ContributionToAgaKahanDeveleopmentNetworkAmount;
    ContributionToAgaKahanDeveleopmentNetworkAmount = parseFloat(ContributionToAgaKahanDeveleopmentNetworkAmount);

    rawForm.ContributionToAgaKahanDeveleopmentNetworkAllowed == '' ?
      ContributionToAgaKahanDeveleopmentNetworkAllowed = 0 : ContributionToAgaKahanDeveleopmentNetworkAllowed = rawForm.ContributionToAgaKahanDeveleopmentNetworkAllowed;
    ContributionToAgaKahanDeveleopmentNetworkAllowed = parseFloat(ContributionToAgaKahanDeveleopmentNetworkAllowed);

    let
      DoantionToAhsaniaCancerHospitalAmount,
      DoantionToAhsaniaCancerHospitalAllowed = null;

    rawForm.DoantionToAhsaniaCancerHospitalAmount == '' ?
      DoantionToAhsaniaCancerHospitalAmount = 0 : DoantionToAhsaniaCancerHospitalAmount = rawForm.DoantionToAhsaniaCancerHospitalAmount;
    DoantionToAhsaniaCancerHospitalAmount = parseFloat(DoantionToAhsaniaCancerHospitalAmount);

    rawForm.DoantionToAhsaniaCancerHospitalAllowed == '' ?
      DoantionToAhsaniaCancerHospitalAllowed = 0 : DoantionToAhsaniaCancerHospitalAllowed = rawForm.DoantionToAhsaniaCancerHospitalAllowed;
    DoantionToAhsaniaCancerHospitalAllowed = parseFloat(DoantionToAhsaniaCancerHospitalAllowed);

    let
      DonationToICDDRBAmount,
      DonationToICDDRBAllowed = null;

    rawForm.DonationToICDDRBAmount == '' ?
      DonationToICDDRBAmount = 0 : DonationToICDDRBAmount = rawForm.DonationToICDDRBAmount;
    DonationToICDDRBAmount = parseFloat(DonationToICDDRBAmount);

    rawForm.DonationToICDDRBAllowed == '' ?
      DonationToICDDRBAllowed = 0 : DonationToICDDRBAllowed = rawForm.DonationToICDDRBAllowed;
    DonationToICDDRBAllowed = parseFloat(DonationToICDDRBAllowed);

    let
      DonationToCentreForRehabilitationOfParalyzedAmount,
      DonationToCentreForRehabilitationOfParalyzedAllowed = null;

    rawForm.DonationToCentreForRehabilitationOfParalyzedAmount == '' ?
      DonationToCentreForRehabilitationOfParalyzedAmount = 0 : DonationToCentreForRehabilitationOfParalyzedAmount = rawForm.DonationToCentreForRehabilitationOfParalyzedAmount;
    DonationToCentreForRehabilitationOfParalyzedAmount = parseFloat(DonationToCentreForRehabilitationOfParalyzedAmount);

    rawForm.DonationToCentreForRehabilitationOfParalyzedAllowed == '' ?
      DonationToCentreForRehabilitationOfParalyzedAllowed = 0 : DonationToCentreForRehabilitationOfParalyzedAllowed = rawForm.DonationToCentreForRehabilitationOfParalyzedAllowed;
    DonationToCentreForRehabilitationOfParalyzedAllowed = parseFloat(DonationToCentreForRehabilitationOfParalyzedAllowed);

    let
      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount,
      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed = null;

    rawForm.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount == '' ?
      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount = 0 : DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount = rawForm.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount;
    DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount = parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount);

    rawForm.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed == '' ?
      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed = 0 : DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed = rawForm.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed;
    DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed = parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed);

    let
      ContributionToAsiatecSocietyBangladeshAmount,
      ContributionToAsiatecSocietyBangladeshAllowed = null;

    rawForm.ContributionToAsiatecSocietyBangladeshAmount == '' ?
      ContributionToAsiatecSocietyBangladeshAmount = 0 : ContributionToAsiatecSocietyBangladeshAmount = rawForm.ContributionToAsiatecSocietyBangladeshAmount;
    ContributionToAsiatecSocietyBangladeshAmount = parseFloat(ContributionToAsiatecSocietyBangladeshAmount);

    rawForm.ContributionToAsiatecSocietyBangladeshAllowed == '' ?
      ContributionToAsiatecSocietyBangladeshAllowed = 0 : ContributionToAsiatecSocietyBangladeshAllowed = rawForm.ContributionToAsiatecSocietyBangladeshAllowed;
    ContributionToAsiatecSocietyBangladeshAllowed = parseFloat(ContributionToAsiatecSocietyBangladeshAllowed);

    // let
    //   DonationToDhakaAhsaniaMissionCancerHospitalAmount,
    //   DonationToDhakaAhsaniaMissionCancerHospitalAllowed = null;

    // rawForm.DonationToDhakaAhsaniaMissionCancerHospitalAmount == '' ?
    //   DonationToDhakaAhsaniaMissionCancerHospitalAmount = 0 : DonationToDhakaAhsaniaMissionCancerHospitalAmount = rawForm.DonationToDhakaAhsaniaMissionCancerHospitalAmount;
    // DonationToDhakaAhsaniaMissionCancerHospitalAmount = parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAmount);

    // rawForm.DonationToDhakaAhsaniaMissionCancerHospitalAllowed == '' ?
    //   DonationToDhakaAhsaniaMissionCancerHospitalAllowed = 0 : DonationToDhakaAhsaniaMissionCancerHospitalAllowed = rawForm.DonationToDhakaAhsaniaMissionCancerHospitalAllowed;
    // DonationToDhakaAhsaniaMissionCancerHospitalAllowed = parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAllowed);

    let
      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount,
      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed = null;

    rawForm.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount == '' ?
      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount = 0 : DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount = rawForm.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount;
    DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount = parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount);

    rawForm.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed == '' ?
      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed = 0 : DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed = rawForm.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed;
    DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed = parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed);

    let
      TotalInvestmentAmount,
      TotalInvestmentAllowed
        = null;

    TotalInvestmentAmount = parseFloat(LifeInsurancePremiumAmount)
      + parseFloat(ContributionToDepositPensionSchemeAmount)
      + parseFloat(InvestmentInApprovedSavingsCertificateAmount)
      + parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount)
      + parseFloat(ContributionToProvidentFundToWhichProvidentFundAmount)
      + parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount)
      + parseFloat(ContributionToSuperAnnuationFundAmount)
      + parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAmount)
      + parseFloat(InvestmentInBangladeshGovtTreasuryBondAmount)
      + parseFloat(MutualFundAmount)
      + parseFloat(ContributionToZakatFundAmount)
      + parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount)
      + parseFloat(DonationToCharitableHospitalRecognizedByNBRAmount)
      + parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount)
      + parseFloat(ContributionToLiberationWarMusuemAmount)
      + parseFloat(ContributionToAgaKahanDeveleopmentNetworkAmount)
      + parseFloat(DoantionToAhsaniaCancerHospitalAmount)
      + parseFloat(DonationToICDDRBAmount)
      + parseFloat(DonationToCentreForRehabilitationOfParalyzedAmount)
      + parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount)
      + parseFloat(ContributionToAsiatecSocietyBangladeshAmount)
      // + parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAmount)
      + parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount);

    TotalInvestmentAllowed = parseFloat(LifeInsurancePremiumAllowed)
      + parseFloat(ContributionToDepositPensionSchemeAllowed)
      + parseFloat(InvestmentInApprovedSavingsCertificateAllowed)
      + parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed)
      + parseFloat(ContributionToProvidentFundToWhichProvidentFundAllowed)
      + parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed)
      + parseFloat(ContributionToSuperAnnuationFundAllowed)
      + parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAllowed)
      + parseFloat(InvestmentInBangladeshGovtTreasuryBondAllowed)
      + parseFloat(MutualFundAllowed)
      + parseFloat(ContributionToZakatFundAllowed)
      + parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed)
      + parseFloat(DonationToCharitableHospitalRecognizedByNBRAllowed)
      + parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed)
      + parseFloat(ContributionToLiberationWarMusuemAllowed)
      + parseFloat(ContributionToAgaKahanDeveleopmentNetworkAllowed)
      + parseFloat(DoantionToAhsaniaCancerHospitalAllowed)
      + parseFloat(DonationToICDDRBAllowed)
      + parseFloat(DonationToCentreForRehabilitationOfParalyzedAllowed)
      + parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed)
      + parseFloat(ContributionToAsiatecSocietyBangladeshAllowed)
      // + parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAllowed)
      + parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed);

    TotalInvestmentAmount = Number(TotalInvestmentAmount).toFixed(2);
    TotalInvestmentAllowed = Number(TotalInvestmentAllowed).toFixed(2);

    TotalInvestmentAllowed = parseFloat(LifeInsurancePremiumAllowed)
      + parseFloat(ContributionToDepositPensionSchemeAllowed)
      + parseFloat(InvestmentInApprovedSavingsCertificateAllowed)
      + parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed)
      + parseFloat(ContributionToProvidentFundToWhichProvidentFundAllowed)
      + parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed)
      + parseFloat(ContributionToSuperAnnuationFundAllowed)
      + parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAllowed)
      + parseFloat(InvestmentInBangladeshGovtTreasuryBondAllowed)
      + parseFloat(MutualFundAllowed)
      + parseFloat(ContributionToZakatFundAllowed)
      + parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed)
      + parseFloat(DonationToCharitableHospitalRecognizedByNBRAllowed)
      + parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed)
      + parseFloat(ContributionToLiberationWarMusuemAllowed)
      + parseFloat(ContributionToAgaKahanDeveleopmentNetworkAllowed)
      + parseFloat(DoantionToAhsaniaCancerHospitalAllowed)
      + parseFloat(DonationToICDDRBAllowed)
      + parseFloat(DonationToCentreForRehabilitationOfParalyzedAllowed)
      + parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed)
      + parseFloat(ContributionToAsiatecSocietyBangladeshAllowed)
      // + parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAllowed)
      + parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed);

    if (isNaN(Number(rawForm.LifeInsurancePolicyValue)) == true)
    {
      LifeInsurancePolicyValue = '0.00';
    }

    if (isNaN(Number(rawForm.LifeInsurancePremiumAmount)) == true)
    {
      LifeInsurancePremiumAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToDepositPensionSchemeAmount)) == true)
    {
      ContributionToDepositPensionSchemeAmount = '0.00';
    }

    if (isNaN(Number(rawForm.InvestmentInApprovedSavingsCertificateAmount)) == true)
    {
      InvestmentInApprovedSavingsCertificateAmount = '0.00';
    }

    if (isNaN(Number(rawForm.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount)) == true)
    {
      InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToProvidentFundToWhichProvidentFundAmount)) == true)
    {
      ContributionToProvidentFundToWhichProvidentFundAmount = '0.00';
    }

    if (isNaN(Number(rawForm.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount)) == true)
    {
      SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToSuperAnnuationFundAmount)) == true)
    {
      ContributionToSuperAnnuationFundAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToBenevolentFundAndGroupInsurancePremiumAmount)) == true)
    {
      ContributionToBenevolentFundAndGroupInsurancePremiumAmount = '0.00';
    }

    if (isNaN(Number(rawForm.InvestmentInBangladeshGovtTreasuryBondAmount)) == true)
    {
      InvestmentInBangladeshGovtTreasuryBondAmount = '0.00';
    }

    if (isNaN(Number(rawForm.MutualFundAmount)) == true)
    {
      MutualFundAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToZakatFundAmount)) == true)
    {
      ContributionToZakatFundAmount = '0.00';
    }

    if (isNaN(Number(rawForm.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount)) == true)
    {
      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount = '0.00';
    }

    if (isNaN(Number(rawForm.DonationToCharitableHospitalRecognizedByNBRAmount)) == true)
    {
      DonationToCharitableHospitalRecognizedByNBRAmount = '0.00';
    }

    if (isNaN(Number(rawForm.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount)) == true)
    {
      DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToLiberationWarMusuemAmount)) == true)
    {
      ContributionToLiberationWarMusuemAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToAgaKahanDeveleopmentNetworkAmount)) == true)
    {
      ContributionToAgaKahanDeveleopmentNetworkAmount = '0.00';
    }

    if (isNaN(Number(rawForm.DoantionToAhsaniaCancerHospitalAmount)) == true)
    {
      DoantionToAhsaniaCancerHospitalAmount = '0.00';
    }

    if (isNaN(Number(rawForm.DonationToICDDRBAmount)) == true)
    {
      DonationToICDDRBAmount = '0.00';
    }


    if (isNaN(Number(rawForm.DonationToCentreForRehabilitationOfParalyzedAmount)) == true)
    {
      DonationToCentreForRehabilitationOfParalyzedAmount = '0.00';
    }

    if (isNaN(Number(rawForm.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount)) == true)
    {
      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount = '0.00';
    }

    if (isNaN(Number(rawForm.ContributionToAsiatecSocietyBangladeshAmount)) == true)
    {
      ContributionToAsiatecSocietyBangladeshAmount = '0.00';
    }

    if (isNaN(Number(rawForm.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount)) == true)
    {
      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount = '0.00';
    }

    var taxRebateClass = new TaxRebateClass(
      LifeInsurancePolicyValue,
      LifeInsurancePremiumAmount,
      rawForm.LifeInsurancePremiumAllowed,
      rawForm.MaxLimiteOfdeposit,

      ContributionToDepositPensionSchemeAmount,
      rawForm.ContributionToDepositPensionSchemeAllowed,

      InvestmentInApprovedSavingsCertificateAmount,
      rawForm.InvestmentInApprovedSavingsCertificateAllowed,

      InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount,
      rawForm.InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed,

      ContributionToProvidentFundToWhichProvidentFundAmount,
      rawForm.ContributionToProvidentFundToWhichProvidentFundAllowed,


      SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount,
      rawForm.SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed,

      ContributionToSuperAnnuationFundAmount,
      rawForm.ContributionToSuperAnnuationFundAllowed,

      ContributionToBenevolentFundAndGroupInsurancePremiumAmount,
      rawForm.ContributionToBenevolentFundAndGroupInsurancePremiumAllowed,

      InvestmentInBangladeshGovtTreasuryBondAmount,
      rawForm.InvestmentInBangladeshGovtTreasuryBondAllowed,

      MutualFundAmount,
      rawForm.MutualFundAllowed,

      ContributionToZakatFundAmount,
      rawForm.ContributionToZakatFundAllowed,

      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount,
      rawForm.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed,

      DonationToCharitableHospitalRecognizedByNBRAmount,
      rawForm.DonationToCharitableHospitalRecognizedByNBRAllowed,

      DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount,
      rawForm.DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed,

      ContributionToLiberationWarMusuemAmount,
      rawForm.ContributionToLiberationWarMusuemAllowed,

      ContributionToAgaKahanDeveleopmentNetworkAmount,
      rawForm.ContributionToAgaKahanDeveleopmentNetworkAllowed,

      DoantionToAhsaniaCancerHospitalAmount,
      rawForm.DoantionToAhsaniaCancerHospitalAllowed,

      DonationToICDDRBAmount,
      rawForm.DonationToICDDRBAllowed,

      DonationToCentreForRehabilitationOfParalyzedAmount,
      rawForm.DonationToCentreForRehabilitationOfParalyzedAllowed,

      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount,
      rawForm.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed,

      ContributionToAsiatecSocietyBangladeshAmount,
      rawForm.ContributionToAsiatecSocietyBangladeshAllowed,

      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount,
      rawForm.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed,

      rawForm.TotalInvestmentAmount,
      rawForm.TotalInvestmentAllowed,
    )
    this.form.patchValue({
      // TotalInvestmentAmount: TotalInvestmentAmount,
      // TotalInvestmentAllowed: TotalInvestmentAllowed

      TotalInvestmentAmount: Number(taxRebateClass.TotalInvestmentAmount).toFixed(2),
      TotalInvestmentAllowed: Number(taxRebateClass.TotalInvestmentAllowed).toFixed(2),
    })

    if (key == "LifeInsurancePremiumAmount" || key == "LifeInsurancePolicyValue")
    {
      // ;
      let Oneh = LifeInsurancePolicyValue * .10;
      LifeInsurancePremiumAllowed = Math.min(Oneh, LifeInsurancePremiumAmount);

      LifeInsurancePremiumAllowed = Number(LifeInsurancePremiumAllowed).toFixed(2);

      this.form.patchValue({
        // LifeInsurancePremiumAllowed: LifeInsurancePremiumAllowed
        LifeInsurancePremiumAllowed: Number(taxRebateClass.LifeInsurancePremiumAllowed).toFixed(2),
      })

      TotalInvestmentAllowed = parseFloat(LifeInsurancePremiumAllowed)
        + parseFloat(ContributionToDepositPensionSchemeAllowed)
        + parseFloat(InvestmentInApprovedSavingsCertificateAllowed)
        + parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed)
        + parseFloat(ContributionToProvidentFundToWhichProvidentFundAllowed)
        + parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed)
        + parseFloat(ContributionToSuperAnnuationFundAllowed)
        + parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAllowed)
        + parseFloat(InvestmentInBangladeshGovtTreasuryBondAllowed)
        + parseFloat(MutualFundAllowed)
        + parseFloat(ContributionToZakatFundAllowed)
        + parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed)
        + parseFloat(DonationToCharitableHospitalRecognizedByNBRAllowed)
        + parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed)
        + parseFloat(ContributionToLiberationWarMusuemAllowed)
        + parseFloat(ContributionToAgaKahanDeveleopmentNetworkAllowed)
        + parseFloat(DoantionToAhsaniaCancerHospitalAllowed)
        + parseFloat(DonationToICDDRBAllowed)
        + parseFloat(DonationToCentreForRehabilitationOfParalyzedAllowed)
        + parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed)
        + parseFloat(ContributionToAsiatecSocietyBangladeshAllowed)
        // + parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAllowed)
        + parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed);

      TotalInvestmentAmount = Number(TotalInvestmentAmount).toFixed(2);
      TotalInvestmentAllowed = Number(TotalInvestmentAllowed).toFixed(2);

      this.form.patchValue({
        // TotalInvestmentAmount: TotalInvestmentAmount,
        // TotalInvestmentAllowed: TotalInvestmentAllowed

        TotalInvestmentAmount: Number(taxRebateClass.TotalInvestmentAmount).toFixed(2),
        TotalInvestmentAllowed: Number(taxRebateClass.TotalInvestmentAllowed).toFixed(2),
      })
    }

    if (key == "ContributionToDepositPensionSchemeAmount")
    {
      // ;
      ContributionToDepositPensionSchemeAllowed = ContributionToDepositPensionSchemeAmount > _maxLimiteOfdeposit ? _maxLimiteOfdeposit : ContributionToDepositPensionSchemeAmount;

      ContributionToDepositPensionSchemeAllowed = Number(ContributionToDepositPensionSchemeAllowed).toFixed(2);

      this.form.patchValue({
        // ContributionToDepositPensionSchemeAllowed: ContributionToDepositPensionSchemeAllowed

        ContributionToDepositPensionSchemeAllowed: Number(taxRebateClass.ContributionToDepositPensionSchemeAllowed).toFixed(2),
      })

      TotalInvestmentAllowed = parseFloat(LifeInsurancePremiumAllowed)
        + parseFloat(ContributionToDepositPensionSchemeAllowed)
        + parseFloat(InvestmentInApprovedSavingsCertificateAllowed)
        + parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed)
        + parseFloat(ContributionToProvidentFundToWhichProvidentFundAllowed)
        + parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed)
        + parseFloat(ContributionToSuperAnnuationFundAllowed)
        + parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAllowed)
        + parseFloat(InvestmentInBangladeshGovtTreasuryBondAllowed)
        + parseFloat(MutualFundAllowed)
        + parseFloat(ContributionToZakatFundAllowed)
        + parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed)
        + parseFloat(DonationToCharitableHospitalRecognizedByNBRAllowed)
        + parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed)
        + parseFloat(ContributionToLiberationWarMusuemAllowed)
        + parseFloat(ContributionToAgaKahanDeveleopmentNetworkAllowed)
        + parseFloat(DoantionToAhsaniaCancerHospitalAllowed)
        + parseFloat(DonationToICDDRBAllowed)
        + parseFloat(DonationToCentreForRehabilitationOfParalyzedAllowed)
        + parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed)
        + parseFloat(ContributionToAsiatecSocietyBangladeshAllowed)
        // + parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAllowed)
        + parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed);

      TotalInvestmentAmount = Number(TotalInvestmentAmount).toFixed(2);
      TotalInvestmentAllowed = Number(TotalInvestmentAllowed).toFixed(2);

      this.form.patchValue({
        // TotalInvestmentAmount: TotalInvestmentAmount,
        // TotalInvestmentAllowed: TotalInvestmentAllowed

        TotalInvestmentAmount: Number(taxRebateClass.TotalInvestmentAmount).toFixed(2),
        TotalInvestmentAllowed: Number(taxRebateClass.TotalInvestmentAllowed).toFixed(2),
      })
    }
    if (key == "InvestmentInApprovedSavingsCertificateAmount" || key == "InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount" || key == "ContributionToProvidentFundToWhichProvidentFundAmount" || key == "SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount" || key == "ContributionToSuperAnnuationFundAmount" || key == "ContributionToBenevolentFundAndGroupInsurancePremiumAmount" || key == "InvestmentInBangladeshGovtTreasuryBondAmount" || key == "ContributionToZakatFundAmount" || key == "MutualFundAmount" || key == "DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount" || key == "DonationToCharitableHospitalRecognizedByNBRAmount" || key == "DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount" || key == "ContributionToLiberationWarMusuemAmount" || key == "ContributionToAgaKahanDeveleopmentNetworkAmount" || key == "DoantionToAhsaniaCancerHospitalAmount" || key == "DonationToICDDRBAmount" || key == "DonationToCentreForRehabilitationOfParalyzedAmount" || key == "DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount" || key == "ContributionToAsiatecSocietyBangladeshAmount" || key == "DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount")
    {
      InvestmentInApprovedSavingsCertificateAllowed = InvestmentInApprovedSavingsCertificateAmount;

      InvestmentInApprovedSavingsCertificateAllowed = Number(InvestmentInApprovedSavingsCertificateAllowed).toFixed(2);

      this.form.patchValue({
        // InvestmentInApprovedSavingsCertificateAllowed: InvestmentInApprovedSavingsCertificateAllowed

        InvestmentInApprovedSavingsCertificateAllowed: Number(taxRebateClass.InvestmentInApprovedSavingsCertificateAllowed).toFixed(2),

      })

      InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed = InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount;

      InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed = Number(InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed).toFixed(2);

      this.form.patchValue({
        // InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed: InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed

        InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed: Number(taxRebateClass.InvestmentInApprovedDebentureOrDebentureStockOrSharesAmount).toFixed(2),

      })

      ContributionToProvidentFundToWhichProvidentFundAllowed = ContributionToProvidentFundToWhichProvidentFundAmount;

      ContributionToProvidentFundToWhichProvidentFundAllowed = Number(ContributionToProvidentFundToWhichProvidentFundAllowed).toFixed(2);

      this.form.patchValue({
        // ContributionToProvidentFundToWhichProvidentFundAllowed: ContributionToProvidentFundToWhichProvidentFundAllowed

        ContributionToProvidentFundToWhichProvidentFundAllowed: Number(taxRebateClass.ContributionToProvidentFundToWhichProvidentFundAmount).toFixed(2),

      })

      SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed =
        SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount;

      SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed = Number(SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed).toFixed(2);

      this.form.patchValue({
        // SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed: SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed

        SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed: Number(taxRebateClass.SelfContributionAndEmployersContributionToRecognizedProvidentFundAmount).toFixed(2),

      })

      ContributionToSuperAnnuationFundAllowed = ContributionToSuperAnnuationFundAmount;

      ContributionToSuperAnnuationFundAllowed = Number(ContributionToSuperAnnuationFundAllowed).toFixed(2);

      this.form.patchValue({
        // ContributionToSuperAnnuationFundAllowed: ContributionToSuperAnnuationFundAllowed

        ContributionToSuperAnnuationFundAllowed: Number(taxRebateClass.ContributionToSuperAnnuationFundAmount).toFixed(2),

      })

      ContributionToBenevolentFundAndGroupInsurancePremiumAllowed =
        ContributionToBenevolentFundAndGroupInsurancePremiumAmount;

      ContributionToBenevolentFundAndGroupInsurancePremiumAllowed = Number(ContributionToBenevolentFundAndGroupInsurancePremiumAllowed).toFixed(2);

      this.form.patchValue({
        // ContributionToBenevolentFundAndGroupInsurancePremiumAllowed: ContributionToBenevolentFundAndGroupInsurancePremiumAllowed

        ContributionToBenevolentFundAndGroupInsurancePremiumAllowed: Number(taxRebateClass.ContributionToBenevolentFundAndGroupInsurancePremiumAmount).toFixed(2),

      })

      InvestmentInBangladeshGovtTreasuryBondAllowed = InvestmentInBangladeshGovtTreasuryBondAmount;

      InvestmentInBangladeshGovtTreasuryBondAllowed = Number(InvestmentInBangladeshGovtTreasuryBondAllowed).toFixed(2);

      this.form.patchValue({
        // InvestmentInBangladeshGovtTreasuryBondAllowed: InvestmentInBangladeshGovtTreasuryBondAllowed

        InvestmentInBangladeshGovtTreasuryBondAllowed: Number(taxRebateClass.InvestmentInBangladeshGovtTreasuryBondAllowed).toFixed(2),

      })

      MutualFundAllowed = MutualFundAmount;

      MutualFundAllowed = Number(MutualFundAllowed).toFixed(2);

      this.form.patchValue({
        // MutualFundAllowed: MutualFundAllowed

        MutualFundAllowed: Number(taxRebateClass.MutualFundAllowed).toFixed(2),

      })

      ContributionToZakatFundAllowed = ContributionToZakatFundAmount;

      ContributionToZakatFundAllowed = Number(ContributionToZakatFundAllowed).toFixed(2);

      this.form.patchValue({
        // ContributionToZakatFundAllowed: ContributionToZakatFundAllowed

        ContributionToZakatFundAllowed: Number(taxRebateClass.ContributionToZakatFundAmount).toFixed(2),

      })

      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed = DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount;

      DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed = Number(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed).toFixed(2);

      this.form.patchValue({
        // DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed: DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed

        DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed: Number(taxRebateClass.DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAmount).toFixed(2),

      })

      DonationToCharitableHospitalRecognizedByNBRAllowed = DonationToCharitableHospitalRecognizedByNBRAmount;

      DonationToCharitableHospitalRecognizedByNBRAllowed = Number(DonationToCharitableHospitalRecognizedByNBRAllowed).toFixed(2);

      this.form.patchValue({
        // DonationToCharitableHospitalRecognizedByNBRAllowed: DonationToCharitableHospitalRecognizedByNBRAllowed

        DonationToCharitableHospitalRecognizedByNBRAllowed: Number(taxRebateClass.DonationToCharitableHospitalRecognizedByNBRAmount).toFixed(2),

      })

      DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed = DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount;

      DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed = Number(DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed).toFixed(2);

      this.form.patchValue({
        // DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed: DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed

        DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed: Number(taxRebateClass.DonationToOrginationsSetupForWelfareOfRetardedPeopleAmount).toFixed(2),

      })

      ContributionToLiberationWarMusuemAllowed = ContributionToLiberationWarMusuemAmount;

      ContributionToLiberationWarMusuemAllowed = Number(ContributionToLiberationWarMusuemAllowed).toFixed(2);

      this.form.patchValue({
        // ContributionToLiberationWarMusuemAllowed: ContributionToLiberationWarMusuemAllowed

        ContributionToLiberationWarMusuemAllowed: Number(taxRebateClass.ContributionToLiberationWarMusuemAmount).toFixed(2),

      })

      ContributionToAgaKahanDeveleopmentNetworkAllowed = ContributionToAgaKahanDeveleopmentNetworkAmount;

      ContributionToAgaKahanDeveleopmentNetworkAllowed = Number(ContributionToAgaKahanDeveleopmentNetworkAllowed).toFixed(2);


      this.form.patchValue({
        // ContributionToAgaKahanDeveleopmentNetworkAllowed: ContributionToAgaKahanDeveleopmentNetworkAllowed

        ContributionToAgaKahanDeveleopmentNetworkAllowed: Number(taxRebateClass.ContributionToAgaKahanDeveleopmentNetworkAmount).toFixed(2),

      })

      DoantionToAhsaniaCancerHospitalAllowed = DoantionToAhsaniaCancerHospitalAmount;

      DoantionToAhsaniaCancerHospitalAllowed = Number(DoantionToAhsaniaCancerHospitalAllowed).toFixed(2);

      this.form.patchValue({
        // DoantionToAhsaniaCancerHospitalAllowed: DoantionToAhsaniaCancerHospitalAllowed

        DoantionToAhsaniaCancerHospitalAllowed: Number(taxRebateClass.DoantionToAhsaniaCancerHospitalAmount).toFixed(2),

      })

      DonationToICDDRBAllowed = DonationToICDDRBAmount;

      DonationToICDDRBAllowed = Number(DonationToICDDRBAllowed).toFixed(2);

      this.form.patchValue({
        // DonationToICDDRBAllowed: DonationToICDDRBAllowed

        DonationToICDDRBAllowed: Number(taxRebateClass.DonationToICDDRBAmount).toFixed(2),

      })

      DonationToCentreForRehabilitationOfParalyzedAllowed = DonationToCentreForRehabilitationOfParalyzedAmount;

      DonationToCentreForRehabilitationOfParalyzedAllowed = Number(DonationToCentreForRehabilitationOfParalyzedAllowed).toFixed(2);

      this.form.patchValue({
        // DonationToCentreForRehabilitationOfParalyzedAllowed: DonationToCentreForRehabilitationOfParalyzedAllowed

        DonationToCentreForRehabilitationOfParalyzedAllowed: Number(taxRebateClass.DonationToCentreForRehabilitationOfParalyzedAmount).toFixed(2),

      })

      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed = DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount;

      DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed = Number(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed).toFixed(2);

      this.form.patchValue({
        // DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed: DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed

        DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed: Number(taxRebateClass.DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAmount).toFixed(2),

      })

      ContributionToAsiatecSocietyBangladeshAllowed = ContributionToAsiatecSocietyBangladeshAmount;

      ContributionToAsiatecSocietyBangladeshAllowed = Number(ContributionToAsiatecSocietyBangladeshAllowed).toFixed(2);

      this.form.patchValue({
        // ContributionToAsiatecSocietyBangladeshAllowed: ContributionToAsiatecSocietyBangladeshAllowed

        ContributionToAsiatecSocietyBangladeshAllowed: Number(taxRebateClass.ContributionToAsiatecSocietyBangladeshAmount).toFixed(2),

      })

      // DonationToDhakaAhsaniaMissionCancerHospitalAllowed = DonationToDhakaAhsaniaMissionCancerHospitalAmount;

      // this.form.patchValue({
      //   DonationToDhakaAhsaniaMissionCancerHospitalAllowed: DonationToDhakaAhsaniaMissionCancerHospitalAllowed
      // })

      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed = DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount;

      DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed = Number(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed).toFixed(2);

      this.form.patchValue({
        // DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed: DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed

        DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed: Number(taxRebateClass.DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAmount).toFixed(2),

      })


      TotalInvestmentAllowed = parseFloat(LifeInsurancePremiumAllowed)
        + parseFloat(ContributionToDepositPensionSchemeAllowed)
        + parseFloat(InvestmentInApprovedSavingsCertificateAllowed)
        + parseFloat(InvestmentInApprovedDebentureOrDebentureStockOrSharesAllowed)
        + parseFloat(ContributionToProvidentFundToWhichProvidentFundAllowed)
        + parseFloat(SelfContributionAndEmployersContributionToRecognizedProvidentFundAllowed)
        + parseFloat(ContributionToSuperAnnuationFundAllowed)
        + parseFloat(ContributionToBenevolentFundAndGroupInsurancePremiumAllowed)
        + parseFloat(InvestmentInBangladeshGovtTreasuryBondAllowed)
        + parseFloat(MutualFundAllowed)
        + parseFloat(ContributionToZakatFundAllowed)
        + parseFloat(DonationToNationalLevelInstitutionSetupInMemoryFatherOfNationAllowed)
        + parseFloat(DonationToCharitableHospitalRecognizedByNBRAllowed)
        + parseFloat(DonationToOrginationsSetupForWelfareOfRetardedPeopleAllowed)
        + parseFloat(ContributionToLiberationWarMusuemAllowed)
        + parseFloat(ContributionToAgaKahanDeveleopmentNetworkAllowed)
        + parseFloat(DoantionToAhsaniaCancerHospitalAllowed)
        + parseFloat(DonationToICDDRBAllowed)
        + parseFloat(DonationToCentreForRehabilitationOfParalyzedAllowed)
        + parseFloat(DonationToEducationalInstituteOrSocialWelfareRecognizedByGovernmentAllowed)
        + parseFloat(ContributionToAsiatecSocietyBangladeshAllowed)
        // + parseFloat(DonationToDhakaAhsaniaMissionCancerHospitalAllowed)
        + parseFloat(DonationToNationalLevelInstituteSetupInMemoryOfLiberationwarAllowed);

      TotalInvestmentAmount = Number(TotalInvestmentAmount).toFixed(2);
      TotalInvestmentAllowed = Number(TotalInvestmentAllowed).toFixed(2);

      this.form.patchValue({
        // TotalInvestmentAmount: TotalInvestmentAmount,
        // TotalInvestmentAllowed: TotalInvestmentAllowed

        TotalInvestmentAmount: Number(taxRebateClass.TotalInvestmentAmount).toFixed(2),
        TotalInvestmentAllowed: Number(taxRebateClass.TotalInvestmentAllowed).toFixed(2),
      })
    }
  }

  
  ngOnInit(): void
  {


    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }

    let IsLateSubmit = this.form.controls["IsLateSubmit"].value;


    if (IsLateSubmit == "false")
    {
      IsLateSubmit = false
    }
    if (IsLateSubmit == "true")
    {
      IsLateSubmit = true
    }
    if (IsLateSubmit != null)
    {
      this.form.patchValue({
        IsLateSubmit: IsLateSubmit
      });
    }
  }

}


