import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Layouts } from 'app/enum/layouts.enum';

import { NewTaxpayerComponent } from './new-taxpayer.component';

const routes = [
    {
        path     : 'নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে',
        component: NewTaxpayerComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        NewTaxpayerComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule        
    ],
    exports     : [
        NewTaxpayerComponent
    ]
})

export class NewTaxpayerModule
{
}
