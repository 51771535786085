
import { NgModule } from '@angular/core';
import { TaxReturnComponent } from './tax-return.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { TaxReturnService } from './tax-return.service';
import { Service } from 'app/services';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';

const routes = [
    {
        path: 'report/tax-returnvyobvyrdmju',
        component: TaxReturnComponent,
        canActivate: [AuthGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        TaxReturnComponent, 
    ],
    imports: [
        RouterModule.forChild(routes),
        MatSnackBarModule,
        TranslateModule,
        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,
    ],
    exports: [
        TaxReturnComponent
    ],
    providers: [

        TaxReturnService,
        Service,


    ],
})

export class TaxReturnModule
{
}

