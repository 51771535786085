import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AppUser } from 'app/auth';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { debug, log } from 'console';
import { QuickPanelService } from './quick-panel.service';
import { NestedTreeControl } from '@angular/cdk/tree';

import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as lodash from 'lodash';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ClientsListService } from 'app/pages/Manage-Clients/Clients-List/ClientsList.service';
import Utils from 'app/util/utils';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FetchClientsService } from 'app/services/fetchClients.service';
import { ReloadPageService } from 'app/services/reloadPage.service';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})



export class QuickPanelComponent
{


    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    user: AppUser;
    etin: string;
    showMessage: string = "Test";
    isDashboardUpdate = false;

    myCount = "0";
    getData: any;
    subscription: Subscription;


    /**
     * Constructor
     */
    constructor(
        private _quickPanelService: QuickPanelService,
        private eventEmitterService: EventEmitterService,
        private _fuseNavigationService: FuseNavigationService,
        private sidePanelSharedService: SidePanelSharedService,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: ClientsListService,
        private _router: Router,
        private fetchClientsService: FetchClientsService,
        private reloadPageService: ReloadPageService,


    )
    {
        this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == '7')
        // {
        //     this.getPageData();
        // }

    }

    ngOnInit(): void
    {

        // if (this.user.ActiveRoleId == '7')
        // {
        //     this.getPageData();
        // }
        // this.subscription = this.fetchClientsService.currentMessage.subscribe(message =>
        // {

        //     if (message == true)
        //     {
        //         this.getPageData();
        //     }
        // })

    }

    @ViewChild('tree') tree;

    ngAfterViewInit()
    {

    }

    toggleSidebarOpen(): void
    {
        this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();
    }

    setReturn(AssesseeId, AssesseeName): void
    {
        this.toggleSidebarOpen();
        localStorage.setItem('AssesseeId', JSON.stringify({ 'AssesseeId': AssesseeId, 'AssesseeName': AssesseeName }));
        localStorage.removeItem('profileData');
        this.reloadPageService.changeMessage(true);
        this._router.navigateByUrl('/Dashboard');
        // window.location.reload();
    }

    // getPageData(): void
    // {


    // }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }


    // ngOnInit(): void
    // {
    //     this.user = this._quickPanelService.getUserInfo();
    //     this.eventEmitterService.change.subscribe(x =>
    //     {

    //         this.isDashboardUpdate = x;

    //     });
    // }
}
