import { Component, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileListService } from './ProfileList.service';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import Utils from 'app/util/utils';
import { AppUser } from 'app/auth';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ProfileList',
    templateUrl: './ProfileList.component.html',
    styleUrls: ['./ProfileList.component.scss']
})
export class ProfileListComponent
{

    lang = '';

    getData: any;
    user: AppUser;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: ProfileListService,
        private back: BackButtonService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private eventEmitterService: EventEmitterService,
        private _route: ActivatedRoute,
        private sidePanelSharedService: SidePanelSharedService,
        private _fuseNavigationService: FuseNavigationService,

    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Organization Profile" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Organization Profile" }
        ]);
        this.setTitle("digiTax | Organization Profile");
        // this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }
        this.lang = localStorage.getItem('selectedLanguage');
        if (this.lang == 'en')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
        if (this.lang == 'bn')
        {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
            this._fuseNavigationService.setCurrentNavigation('main');
        }
    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }
    
    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this._spinner.show();
        this.getPageData();

    }


    getPageData(): void
    {
        this._formControlService.getControlJson().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            if (Utils.isEmptyObject(data.data) || data.data == null)
            {
                this._router.navigate(['/Profile/save']);
            }
            else
            {
                this.getData = data.data;
            }
        });
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

