import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProfileListComponent } from './ProfileList.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseCountdownModule, FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProfileListService } from './ProfileList.service';
import { ViewProfileGuard } from 'app/auth/viewProfile.guard';

const routes = [
    {
        path: 'Profile',
        component: ProfileListComponent,
        canActivate: [AuthGuard, ViewProfileGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        ProfileListComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatCheckboxModule,

        FuseCountdownModule,

        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,

        NgCircleProgressModule

    ],
    exports: [
        ProfileListComponent
    ],
    providers: [

        ProfileListService
    ],
})

export class ProfileListModule
{
}
