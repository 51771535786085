import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HomeTranslationLoaderService } from 'app/layout/home/services/home-translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { bangla, english } from 'app/util/locale';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'tax-return-gov',
  templateUrl: './tax-return-gov.component.html',
  styleUrls: ['./tax-return-gov.component.scss']
})
export class TaxReturnGovtmentComponent implements OnInit
{

  constructor(

    private _spinner: NgxSpinnerService,

    private _homeTranslationLoaderService: HomeTranslationLoaderService,
    private back: BackButtonService,
    private meta: Meta,
    private title: Title



  )
  {
    this._spinner.hide();

    this.meta.addTags([
      { name: 'description', content: 'Tax Return Government' },
      { name: 'author', content: 'digiTax' },
      { name: 'keywords', content: 'Tax Return Government' }
    ]);
    this.setTitle('digiTax সরকারি চাকুরিজীবীর আয়কর রিটার্ন প্রস্তুতি।');
    this._homeTranslationLoaderService.loadTranslations(english, bangla);
    this.back.sharedData.subscribe(result =>
    {
      if (result)
      {
        // window.location.reload(true);
        window.location.reload();

        this.back.setParam(false, '');
      }
    });
  }
  public setTitle(newTitle: string)
  {
    this.title.setTitle(newTitle);
  }

  ngOnInit()
  {
  }

}
