
export class PrivateSalaryModel
{
  Id: any;
  BasicPayAmount: string = "";
  BasicPayExempted: string = "";
  BasicPayTaxable: string = "";
  SpecialPayAmount: string = "";
  SpecialPayExempted: string = "";
  SpecialPayTaxable: string = "";
  DearnessAllowanceAmount: string = "";
  DearnessAllowanceExempted: string = "";
  DearnessAllowanceTaxable: string = "";
  ConveyanceAllowanceAmount: string = "";
  ConveyanceAllowanceExempted: string = "";
  ConveyanceAllowanceTaxable: string = "";
  HouseRentAllowanceAmount: string = "";
  HouseRentAllowanceExempted: string = "";
  HouseRentAllowanceTaxable: string = "";
  IsDisabledPerson: boolean = false;
  MedicalAllowanceAmount: string = "";
  MedicalAllowanceExempted: string = "";
  MedicalAllowanceTaxable: string = "";
  IsShareHolder: boolean = false;
  ReceivedForSurgeryOfHeartEyeLiverKidneyCancerAmount: string = "";
  ReceivedForSurgeryOfHeartEyeLiverKidneyCancerExempted: string = "";
  ReceivedForSurgeryOfHeartEyeLiverKidneyCancerTaxable: string = "";
  IsMentionedInEmploymentContract: boolean = false;
  LeaveFairAssistanceStaffAmount: string = "";
  LeaveFairAssistanceStaffExempted: string = "";
  LeaveFairAssistanceStaffTaxable: string = "";
  LeaveEncashmentAmount: string = "";
  LeaveEncashmentExempted: string = "";
  LeaveEncashmentTaxable: string = "";
  AllowanceForSupportStaffAmount: string = "";
  AllowanceForSupportStaffExempted: string = "";
  AllowanceForSupportStaffTaxable: string = "";
  HonorariumOrRewardAmount: string = "";
  HonorariumOrRewardExempted: string = "";
  HonorariumOrRewardTaxable: string = "";
  OvertimeAllowanceAmount: string = "";
  OvertimeAllowanceExempted: string = "";
  OvertimeAllowanceTaxable: string = "";
  BonusAmount: string = "";
  BonusExempted: string = "";
  BonusTaxable: string = "";
  OtherAllowancesAmount: string = "";
  OtherAllowancesExempted: string = "";
  OtherAllowancesTaxable: string = "";
  EmployersContributionProvidentFundAmount: string = "";
  EmployersContributionProvidentFundExempted: string = "";
  EmployersContributionProvidentFundTaxable: string = "";
  RateOfInterestOfProvidentFund: string = "";
  InterestAccruedProvidentFundRateOfInterestAmount: string = "";
  InterestAccruedProvidentFundRateOfInterestExempted: string = "";
  InterestAccruedProvidentFundRateOfInterestTaxable: string = "";
  IsReceivedAnyTransportFromEmployer: boolean = false;
  ReceivedTransportFromEmployerTaxable: string = "";
  IsReceivedAnyFurnishedOrUnfurnishedHouseFromEmployer: boolean = false;
  EmployerPaidRentalAmount: string = "";
  EmployeePaidRentalAmount: string = "";
  DeemedFreeAccommodationExempted: string = "";
  OthersAmount: string = "";
  OthersExempted: string = "";
  OthersTaxable: string = "";
  ArrearPayAmount: string = "";
  ArrearPayExempted: string = "";
  ArrearPayTaxable: string = "";
  ReceivedFromGratuityFundAmount: string = "";
  ReceivedFromGratuityFundExempted: string = "";
  ReceivedFromGratuityFundTaxable: string = "";
  WorkersProfitParticipationFundAmount: string = "";
  WorkersProfitParticipationFundExempted: string = "";
  WorkersProfitParticipationFundTaxable: string = "";
  PensionAmount: string = "";
  PensionExempted: string = "";
  PensionTaxable: string = "";
  ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundAmount: string = "";
  ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundExempted: string = "";
  ReceivedFromRecognizedProvidentFundAndRecognizedSuperAnnuationFundTaxable: string = "";
  TotalAmount: string = "";
  TotalExempted: string = "";
  NetTaxable: string = "";
  GrossAmount: string = "";
  GrossExempted: string = "";
  GrossTaxable: string = "";
  OrganizationName: string = "";
  OrganizationAddress: string = "";
  BIN: string = "";
}
