import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import { CapitalGainsClass } from './CapitalGains.Class';

@Component({
  selector: 'CapitalGains-form-control',
  templateUrl: './CapitalGains-form-control.component.html',
  styleUrls: ['./CapitalGains.component.scss']

})
export class CapitalGainsControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  @Input() rGainType: boolean;
  pageLoaded: boolean;
  IsCurrentYear: any;

  constructor(
    private cdr: ChangeDetectorRef,
  )
  {

  }

  onSelect(event, key)
  {
    let rawForm = this.form.getRawValue();

    let san1: HTMLElement = document.getElementsByClassName("shareholder")[0] as HTMLElement;

    let san2: HTMLElement = document.getElementsByClassName("shareholder")[1] as HTMLElement;

    let san3: HTMLElement = document.getElementsByClassName("shareholder")[2] as HTMLElement;

    let san4: HTMLElement = document.getElementsByClassName("shareholder")[3] as HTMLElement;


    if (key == "GainType")
    {
      if (event.value == "1" || event.value == "2" || event.value == "3" || event.value == "4" || event.value == "5")
      {
        if (san3.classList.contains('hidden'))
        {
          san3.classList.remove('hidden');
          san3.classList.add('block');
        }
        if (!san2.classList.contains('hidden'))
        {
          san2.classList.add('hidden');
          san2.classList.remove('block');
        }
        if (!san1.classList.contains('hidden'))
        {
          san1.classList.add('hidden');
          san1.classList.remove('block');
        }
        if (!san4.classList.contains('hidden'))
        {
          san4.classList.add('hidden');
          san4.classList.remove('block');
        }
        this.form.controls['GainWeight1'].reset();
        this.form.controls['GainWeight2'].reset();

        this.form.patchValue({
          TaxableIncome: '',
          AmountReceived: '',
        })
      }

      if (event.value == "6")
      {
        this.form.controls['GainWeight1'].setErrors({ 'required': true });
        this.form.controls['GainWeight2'].setErrors(null);
        if (san1.classList.contains('hidden'))
        {
          san1.classList.remove('hidden');
          san1.classList.add('block');
        }
        if (!san2.classList.contains('hidden'))
        {
          san2.classList.add('hidden');
          san2.classList.remove('block');
        }
        if (!san3.classList.contains('hidden'))
        {
          san3.classList.add('hidden');
          san3.classList.remove('block');
        }
        if (san4.classList.contains('hidden'))
        {
          san4.classList.remove('hidden');
          san4.classList.add('block');
        }

        this.form.controls['GainWeight2'].reset();
        this.form.controls['TaxDeductedAtSource_TDS'].reset();

        this.form.patchValue({
          TaxableIncome: '',
          AmountReceived: '',
        })
      }

      if (event.value == "7")
      {
        this.form.controls['GainWeight1'].setErrors(null);
        this.form.controls['GainWeight2'].setErrors({ 'required': true });
        if (san2.classList.contains('hidden'))
        {
          san2.classList.remove('hidden');
          san2.classList.add('block');
        }
        if (!san1.classList.contains('hidden'))
        {
          san1.classList.add('hidden');
          san1.classList.remove('block');
        }
        if (!san3.classList.contains('hidden'))
        {
          san3.classList.add('hidden');
          san3.classList.remove('block');
        }
        if (san4.classList.contains('hidden'))
        {
          san4.classList.remove('hidden');
          san4.classList.add('block');
        }

        this.form.controls['GainWeight1'].reset();
        this.form.controls['TaxDeductedAtSource_TDS'].reset();

        this.form.patchValue({
          TaxableIncome: '',
          AmountReceived: '',
        })
      }
    }
    if (key == "GainWeight1" || key == "GainWeight2")
    {

      var capitalGainsClass = new CapitalGainsClass(
        rawForm.AmountReceived,
        rawForm.TaxDeductedAtSource_TDS,
        rawForm.TaxableIncome,
        rawForm.GainType,
        event.value,
      )

      if (event.value == "1")
      {
        this.form.patchValue({
          TaxableIncome: 0
        })
      }
      else
      {
        this.form.patchValue({
          TaxableIncome: Number(capitalGainsClass.AmountReceived).toFixed(2),

        })
      }
    }


  }




  onKey(event: any, key)
  {

    let rawForm = this.form.getRawValue();





    let AmountReceived, TaxDeductedAtSource_TDS, TaxableIncome = null;

    rawForm.AmountReceived == '' ?
      AmountReceived = 0 : AmountReceived = rawForm.AmountReceived;

    rawForm.TaxDeductedAtSource_TDS == '' ?
      TaxDeductedAtSource_TDS = 0 : TaxDeductedAtSource_TDS = rawForm.TaxDeductedAtSource_TDS;



    if (key == "AmountReceived")
    {


      if (isNaN(Number(rawForm.AmountReceived)) == true)
      {
        AmountReceived = '0.00';
      }

      if (isNaN(Number(rawForm.TaxDeductedAtSource_TDS)) == true)
      {
        TaxDeductedAtSource_TDS = '0.00';
      }

      var capitalGainsClass = new CapitalGainsClass(
        AmountReceived,
        TaxDeductedAtSource_TDS,
        TaxableIncome,
        rawForm.GainType,
        rawForm.GainWeight1,
      )
      this.form.patchValue({
        // TaxableIncome: AmountReceived

        TaxableIncome: Number(capitalGainsClass.AmountReceived).toFixed(2),
      })

      if (rawForm.GainType == "1")
      {
        if (rawForm.GainWeight1 == "1")
        {
          this.form.patchValue({
            TaxableIncome: 0
          })
        }
        else
        {
          rawForm.AmountReceived == '' ?
            AmountReceived = 0 : AmountReceived = rawForm.AmountReceived;

          if (isNaN(Number(rawForm.AmountReceived)) == true)
          {
            AmountReceived = '0.00';
          }

          if (isNaN(Number(rawForm.TaxDeductedAtSource_TDS)) == true)
          {
            TaxDeductedAtSource_TDS = '0.00';
          }

          var capitalGainsClass = new CapitalGainsClass(
            AmountReceived,
            TaxDeductedAtSource_TDS,
            TaxableIncome,
            rawForm.GainType,
            rawForm.GainWeight1,
          )
          this.form.patchValue({
            // TaxableIncome: AmountReceived

            TaxableIncome: Number(capitalGainsClass.AmountReceived).toFixed(2),
          })
        }
      }

      if (rawForm.GainType == "2" || rawForm.GainType == "3" || rawForm.GainType == "4" || rawForm.GainType == "6")
      {
        rawForm.AmountReceived == '' ?
          AmountReceived = 0 : AmountReceived = rawForm.AmountReceived;

        if (isNaN(Number(rawForm.AmountReceived)) == true)
        {
          AmountReceived = '0.00';
        }

        if (isNaN(Number(rawForm.TaxDeductedAtSource_TDS)) == true)
        {
          TaxDeductedAtSource_TDS = '0.00';
        }

        var capitalGainsClass = new CapitalGainsClass(
          AmountReceived,
          TaxDeductedAtSource_TDS,
          TaxableIncome,
          rawForm.GainType,
          rawForm.GainWeight1,
        )

        this.form.patchValue({
          // TaxableIncome: AmountReceived

          TaxableIncome: Number(capitalGainsClass.AmountReceived).toFixed(2),
        })
      }

      if (rawForm.GainType == "5")
      {
        rawForm.AmountReceived == '' ?
          AmountReceived = 0 : AmountReceived = rawForm.AmountReceived;

        rawForm.TaxDeductedAtSource_TDS == '' ?
          TaxDeductedAtSource_TDS = 0 : TaxDeductedAtSource_TDS = rawForm.TaxDeductedAtSource_TDS;


        TaxableIncome = parseFloat(AmountReceived) - parseFloat(TaxDeductedAtSource_TDS)

        TaxableIncome = parseFloat(TaxableIncome).toFixed(2);

        if (isNaN(Number(rawForm.AmountReceived)) == true)
        {
          AmountReceived = '0.00';
        }

        if (isNaN(Number(rawForm.TaxDeductedAtSource_TDS)) == true)
        {
          TaxDeductedAtSource_TDS = '0.00';
        }

        var capitalGainsClass = new CapitalGainsClass(
          AmountReceived,
          TaxDeductedAtSource_TDS,
          TaxableIncome,
          rawForm.GainType,
          rawForm.GainWeight1,
        )

        this.form.patchValue({
          // TaxableIncome: TaxableIncome

          TaxableIncome: Number(capitalGainsClass.TaxableIncome).toFixed(2),
        })
      }

    }
    if (key == "TaxDeductedAtSource_TDS")
    {
      TaxableIncome = parseFloat(AmountReceived) - parseFloat(TaxDeductedAtSource_TDS)

      if (isNaN(Number(rawForm.AmountReceived)) == true)
      {
        AmountReceived = '0.00';
      }

      if (isNaN(Number(rawForm.TaxDeductedAtSource_TDS)) == true)
      {
        TaxDeductedAtSource_TDS = '0.00';
      }

      var capitalGainsClass = new CapitalGainsClass(
        AmountReceived,
        TaxDeductedAtSource_TDS,
        TaxableIncome,
        rawForm.GainType,
        rawForm.GainWeight1,
      )

      this.form.patchValue({
        // TaxableIncome: TaxableIncome

        TaxableIncome: Number(capitalGainsClass.TaxableIncome).toFixed(2),
      })
    }


  }
  get isValid()
  {
    if (this.pageLoaded)
    {
      return this.form.controls[this.control.key].valid;
    } else
    {
      return false;
    }

  }

  ngAfterViewInit()
  {
    this.cdr.detectChanges();
  }


  ngOnInit()
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }
    this.pageLoaded = true;

    let san1: HTMLElement = document.getElementsByClassName("shareholder")[0] as HTMLElement;
    let san2: HTMLElement = document.getElementsByClassName("shareholder")[1] as HTMLElement;
    let san3: HTMLElement = document.getElementsByClassName("shareholder")[2] as HTMLElement;
    let GainType = this.form.controls["GainType"].value;


    let Description = this.form.controls["Description"].value;


    if (Description != null)
    {
      this.form.patchValue({
        Description: Description
      })
    }

    // if (GainType == "" || GainType == "6")
    // {
    //   if (san1 != null)
    //   {
    //     san1.classList.add('hidden');
    //   }
    //   if (san2 != null)
    //   {
    //     san2.classList.add('hidden');
    //   }
    //   if (san3 != null)
    //   {
    //     san3.classList.add('hidden');
    //   }
    // }

    // if (GainType == "1")
    // {
    //   if (san2 != null)
    //   {
    //     san2.classList.add('hidden');
    //   }
    //   if (san3 != null)
    //   {
    //     san3.classList.add('hidden');
    //   }
    // }

    // if (GainType == "2" || GainType == "3" || GainType == "4")
    // {
    //   if (san1 != null)
    //   {
    //     san1.classList.add('hidden');
    //   }
    //   if (san3 != null)
    //   {
    //     san3.classList.add('hidden');
    //   }
    // }
    // if (GainType == "5")
    // {
    //   if (san1 != null)
    //   {
    //     san1.classList.add('hidden');
    //   }
    //   if (san2 != null)
    //   {
    //     san2.classList.add('hidden');
    //   }
    // }

    if (GainType == "")
    {
      if (san1 != null)
      {
        san1.classList.add('hidden');
      }
      if (san2 != null)
      {
        san2.classList.add('hidden');
      }
      if (san3 != null)
      {
        san3.classList.add('hidden');
      }
    }

    if (GainType == "1" || GainType == "2" || GainType == "3" || GainType == "4" || GainType == "5")
    {
      if (san3 != null)
      {
        if (san3.classList.contains('hidden'))
        {
          san3.classList.remove('hidden');
          san3.classList.add('block');
        }
      }
      if (san2 != null)
      {
        if (!san2.classList.contains('hidden'))
        {
          san2.classList.add('hidden');
          san2.classList.remove('block');
        }
      }
      if (san1 != null)
      {
        if (!san1.classList.contains('hidden'))
        {
          san1.classList.add('hidden');
          san1.classList.remove('block');
        }
      }
    }

    if (GainType == "6")
    {
      if (san1 != null)
      {
        if (san1.classList.contains('hidden'))
        {
          san1.classList.remove('hidden');
          san1.classList.add('block');
        }
      }
      if (san2 != null)
      {
        if (!san2.classList.contains('hidden'))
        {
          san2.classList.add('hidden');
          san2.classList.remove('block');
        }
      }
      if (san3 != null)
      {
        if (!san3.classList.contains('hidden'))
        {
          san3.classList.add('hidden');
          san3.classList.remove('block');
        }
      }
    }

    if (GainType == "7")
    {
      if (san2 != null)
      {
        if (san2.classList.contains('hidden'))
        {
          san2.classList.remove('hidden');
          san2.classList.add('block');
        }
      }
      if (san1 != null)
      {
        if (!san1.classList.contains('hidden'))
        {
          san1.classList.add('hidden');
          san1.classList.remove('block');
        }
      }
      if (san3 != null)
      {
        if (!san3.classList.contains('hidden'))
        {
          san3.classList.add('hidden');
          san3.classList.remove('block');
        }
      }
    }
  }
}
