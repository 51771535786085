import { Component, HostListener, Input } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { FurnitureService } from './Furniture.service';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { FurnitureModel } from './Furniture.Model';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import Utils from 'app/util/utils';
import { AuthenticationService } from 'app/auth';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';



@Component({
    selector: 'Furniture',
    templateUrl: './Furniture.component.html',
    styleUrls: ['./Furniture.component.scss']
})
export class FurnitureComponent
{
    @Input() controls: BaseControl<string>[] = [];
    @Input() rAmount: boolean;
    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    formcontrols$: Observable<BaseControl<string>[]>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    IsCurrentYear: any;



    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: FurnitureService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _authService: AuthenticationService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private _formBuilder: FormBuilder,
        private back: BackButtonService,
        private sidePanelDeleteService: SidePanelDeleteService,

    )
    {
        this.meta.addTags([
            { name: 'description', content: "digiTax Furniture Assets" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Furniture Assets" }
        ]);
        this.setTitle("digiTax | Furniture Assets");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');

        this.lang = localStorage.getItem('selectedLanguage');
        this.form = this._formBuilder.group(new FurnitureModel());
        
        this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

        if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
        {
            this.form.disable();
        }
        this.getData();

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    getData(): void
    {
        this._spinner.show();
        if (this.id != null)
        {
            this._formControlService.getPageData(this.id).subscribe((data: any) =>
            {


                if (data.data != null)
                {
                    this.form = this._formBuilder.group(data.data);
                }

                let UnknownAmount = this.form.controls["UnknownAmount"].value;


                let floorElement1: HTMLElement = document.getElementById("Amount") as HTMLElement;

                if (UnknownAmount == false)
                {
                    if (floorElement1.classList.contains('readonly'))
                    {
                        floorElement1.classList.remove('readonly');
                    }
                }

                if (UnknownAmount == true)
                {
                    this.form.patchValue({
                        Amount: "Unknown"
                    })
                    if (!floorElement1.classList.contains('readonly'))
                    {
                        floorElement1.classList.add('readonly');
                    }
                }
            })
        }
        this._spinner.hide();
    }

    showOptions(event)
    {


        let floorElement1: HTMLElement = document.getElementById("Amount") as HTMLElement;

        if (event.checked == false)
        {
            this.form.patchValue({
                Amount: "0"
            })
            if (floorElement1.classList.contains('readonly'))
            {
                floorElement1.classList.remove('readonly');
            }

        }

        if (event.checked == true)
        {
            this.form.patchValue({
                Amount: "Unknown"
            })
            if (!floorElement1.classList.contains('readonly'))
            {
                floorElement1.classList.add('readonly');
            }
        }
    }

    ngOnInit(): void
    {

    }

    mySubmitExit(param): void
    {
        // debugger;
        if (param == 'next' || param == 'more')
        {
            this.OnSubmit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }

    OnSubmit(param): void
    {
        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }
        if (!this.form.controls.Amount.valid)
        {
            this.rAmount = true;
        }
        this._spinner.show();
        this.id = this._route.snapshot.paramMap.get('id');

        let obj = this.form.getRawValue();



        if (isNaN(Number(obj.Amount)) == true)
        {
            obj.Amount = "0";
        }

        let stringed = JSON.stringify(obj);



        if ((obj.Amount == "" || obj.Amount == 0) && obj.UnknownAmount == false)
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_ENTER_AMOUNT : bangla.data.COMMON.BUTTON_ENTER_AMOUNT, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (stringed != null)
            {
                this._formControlService.submitFurniture(stringed, this.id)
                    .subscribe((data: any) =>
                    {
                        if (data != null)
                        {
                            this._spinner.hide();
                        }

                        if (data.isError === false)
                        {
                            console.log('submit succesfull');
                        }
                        else
                        {

                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 2000
                            });
                        }
                        if (data.isError === false && data.responseCode == "200")
                        {
                            if (this.id != null)
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            else
                            {
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 2000
                                });
                            }
                            if (param == 'next')
                            {
                                this.improvisedIMenu(param);
                            }
                            if (param == 'more')
                            {
                                this.sidePanelDeleteService.changeMessage(true);
                                this.form.reset();
                            }
                            if (param == 'exit')
                            {
                                this._authService.Logout();
                            }


                        }
                    }, (error: any) =>
                    {
                        console.log(error, 'error');
                    });
            }
        }
    }

    ConfirmLeave(url): void
    {
        if (this.form.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    // this._router.navigate([url]);
                    this.improvisedIMenu(url);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            // this._router.navigate([url]);
            this.improvisedIMenu(url);
        }
    }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'asset');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}
