<ng-container *ngIf="!item.hidden">

    <!-- normal collapsable -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="!item.url && !item.function"
        (click)="toggleOpen($event)">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && !item.function"
        (click)="toggleOpen($event)"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && !item.function"
        (click)="toggleOpen($event)"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link"
        [ngClass]="item.classes"
        *ngIf="!item.url && item.function"
        (click)="toggleOpen($event);item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && item.function"
        (click)="toggleOpen($event);item.function()"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a class="nav-link"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && item.function"
        (click)="toggleOpen($event);item.function()"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon"
            style="margin-left: -15px;"
            *ngIf="item.icon">{{item.icon}}</mat-icon>
        <span class="nav-link-title"
            style="margin-left: -10px;"
            [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-badge"
            *ngIf="item.badge"
            style="margin-right: -5px;font-size: 10px;"
            [translate]="item.badge.translate"
            [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{(item.badge.translate | translate) || item.badge.title}}
        </span>
        <mat-icon class="collapsable-arrow"
            style="margin-right: -15px;">keyboard_arrow_right</mat-icon>
    </ng-template>

    <div class="children"
        [@slideInOut]="isOpen">
        <ng-container *ngFor="let item of item.children">
            <fuse-nav-vertical-item *ngIf="item.type=='item'"
                [item]="item"></fuse-nav-vertical-item>
            <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                [item]="item"></fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-group *ngIf="item.type=='group'"
                [item]="item"></fuse-nav-vertical-group>
        </ng-container>
    </div>

</ng-container>
