<h2 mat-dialog-title>Ledger</h2>
<mat-dialog-content class="mat-typography"
    *ngIf="BcTabgetData!=null">

    <div fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16">
        <div fxLafxLayout="row wrap"
            fxLayoutAlign="space-between center">
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                Name: <b>{{dialogdata.AssesseeName}}</b>
            </h3>
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                eTIN: <b>{{dialogdata.ETIN}}</b>
            </h3>
            <h3 style="margin: 10px;"
                fxFlex="1 0 auto">
                Mobile: <b>{{dialogdata.ContactNo}}</b>
            </h3>
        </div>

        <div fxLafxLayout="row wrap"
            fxLayoutAlign="start center">
            <h3 style="margin: 10px;"
                fxFlex="33">
                Reference: <b>{{dialogdata.Reference == null || dialogdata.Reference == '' ? '--' :
                    dialogdata.Reference}}</b>
            </h3>

        </div>
        <table *ngIf="BcTabgetData!=null"
            class="simple invoice-table">
            <thead>
                <tr>
                    <th class="thNewline"
                        style="color: #444444;">
                        SL
                    </th>
                    <th class="thNewline"
                        style="color: #444444;">
                        TRAN DATE
                    </th>
                    <th class="thNewline"
                        style="color: #444444;">
                        TRAN TYPE
                    </th>
                    <th class="thNewline"
                        style="color: #444444;">
                        TRAN ID
                    </th>
                    <th class="thNewline"
                        style="color: #444444;">
                        REMARKS
                    </th>
                    <th class="text-right thNewline"
                        style="color: #444444;">
                        DEBIT
                    </th>
                    <th class="text-right thNewline"
                        style="color: #444444;">
                        CREDIT
                    </th>
                    <th class="text-right thNewline"
                        style="color: #444444;">
                        BALANCE
                    </th>
                </tr>
            </thead>
            <tbody *ngFor="let item of BcTabgetData; let i=index">
                <tr>
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                    </td>
                    <td>
                        {{item.TranType}}
                    </td>
                    <td>
                        {{item.TransactionId}}
                    </td>
                    <td>
                        {{item.Remarks}}
                    </td>
                    <td class="text-right">
                        {{item.Debit}}
                    </td>
                    <td class="text-right">
                        {{item.Credit}}
                    </td>
                    <td class="text-right">
                        {{item.Balance}}
                    </td>

                </tr>
            </tbody>

        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end"
    *ngIf="BcTabgetData!=null">
    <button class="mat-button mat-button-base primary-100 secondary-text"
        cdkFocusInitial
        mat-dialog-close>Close</button>
    <button class="mat-button mat-button-base dupl"
        [disabled]="BcTabgetData == null ? true : false"
        (click)="DownloadExcel()">Download Excel</button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
