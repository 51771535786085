<div [formGroup]="form"
  fxLayout="column wrap">
  <div [ngSwitch]="control.controlType"
    fxLayout="column wrap"
    fxLayoutAlign="start"
    fxFlex="1 0 auto">
    <div class="h2 mb-24"
      style="margin-top:20px;color: #206030;"
      *ngSwitchCase="'label'">{{control.label}}</div>

    <div *ngSwitchCase="'radio'"
      fxLayout="row"
      fxLayoutAlign="start"
      fxFlex="1 0 auto"
      style="margin-bottom: 5px;">
      <div fxLayout="column"
        fxLayoutAlign="center start"
        appearance="outline"
        fxFlex="100"
        id={{control.key}}>
        <span class="name"
          style="font-size: 16px;color: #444444;"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>
        <div fxLayout="row"
          style="margin-top: 10px;margin-bottom: 10px;">
          <mat-radio-group [formControlName]="control.key"
            [ngClass]="{'invalid': form.get('MainSourceOfIncome').invalid && rSource}"
            required={{control.required}}
            *ngIf="control.key=='MainSourceOfIncome'"
            [id]="control.key">
            <mat-radio-button style="margin-right: 10px;"
              *ngFor="let Source of control.options"
              [value]="Source.key"
              (change)="radioChange($event,control.key)">
              {{Source.value}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-radio-group [formControlName]="control.key"
            required={{control.required}}
            *ngIf="control.key!='MainSourceOfIncome'"
            [id]="control.key">
            <mat-radio-button style="margin-right: 10px;"
              *ngFor="let Source of control.options"
              [value]="Source.key"
              (change)="radioChangeTwo($event,control.key)">
              {{Source.value}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

    </div>

    <div *ngSwitchCase="'textbox'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">
      <div fxLayoutAlign="center"
        fxLayout="column wrap"
        fxFlex="100"
        class="hidden"
        id={{control.key}}>

        <span class="name"
          style="font-size: 15px;color: #444444;"
          [attr.for]="control.key">{{control.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>

        <mat-form-field appearance="outline">
          <input *ngSwitchCase="'textbox'"
            (click)="$event.target.select()"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error> {{control.message}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="'textboxGroup'">
      <div *ngFor="let Source of control.children"
        fxLayoutAlign="space-between start"
        fxFlex="100"
        class="hidden"
        id={{Source.key}}>

        <div *ngIf="Source.controlType=='textbox'"
          fxLayout="column"
          style="margin-top: -5px;margin-bottom: -30px;"
          fxLayoutAlign="space-between"
          fxFlex="100">
          <div fxLayoutAlign="space-between"
            fxLayout="column wrap"
            *ngIf="Source.key=='OrganizationAddress'"
            style="padding-right: 10px;">

            <span class="name"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key">{{Source.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              [attr.for]="Source.key"
              [id]="Source.key">
              <textarea *ngSwitchCase="'textboxGroup'"
                style="resize:vertical"
                rows="1"
                type="text"
                [formControlName]="Source.key"
                [id]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              </textarea>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>
          <div fxLayoutAlign="space-between"
            fxLayout="column wrap"
            *ngIf="Source.key=='BIN'"
            style="padding-left: 10px;">

            <span class="name"
              style="font-size: 15px;color: #444444;"
              [attr.for]="Source.key">{{Source.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
                matTooltip={{Source.tooltip}}
                matSuffix>info
              </mat-icon></span>

            <mat-form-field appearance="outline"
              [attr.for]="Source.key"
              [id]="Source.key">
              <input *ngSwitchCase="'textboxGroup'"
                (click)="$event.target.select()"
                NumbersOnly
                type="text"
                decimals="100"
                negative="1"
                maxlength={{Source.maxlength}}
                [formControlName]="Source.key"
                [id]="Source.key"
                [type]="Source.type"
                matInput
                required={{Source.required}}>
              <mat-error>{{Source.message}}</mat-error>
            </mat-form-field>

          </div>

        </div>

      </div>

    </div>
    <div *ngSwitchCase="'dropdown'"
      fxLayout="column"
      fxLayoutAlign="start space-between"
      fxFlex="1 0 auto"
      [ngClass]="{'hidden': control.key=='AcquiredAssets'}"
      id={{control.key}}>
      <span class="name"
        style="font-size: 15px;color: #444444;"
        [attr.for]="control.key"><span *ngIf="control.required==true"
          style="color: red;font-weight: bold;">* </span>
        <span *ngIf="control.key=='OtherIncomeSources'"
          [ngClass]="{'invalid': form.get('OtherIncomeSources').invalid && rSource}"
          style="font-size: 16px;">{{control.label}}</span>
        <span *ngIf="control.key=='AcquiredAssets'"
          [ngClass]="{'invalid': form.get('AcquiredAssets').invalid && gAssets}"
          style="font-size: 16px;">{{control.label}}</span>
        <mat-icon *ngIf="control.key!='OtherIncomeSources'"
          style="margin-top: 5px;margin-left: 5px"
          matTooltip={{control.tooltip}}
          matSuffix>info
        </mat-icon>
      </span>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxFlex="1 0 auto">

        <mat-selection-list *ngIf="control.key=='AcquiredAssets'"
          value="control.value"
          formControlName="{{control.key}}"
          [id]="control.key"
          required={{control.required}}>
          <mat-list-option *ngFor="let option of control.options"
            [value]="option.key"
            style="height: auto; margin-bottom: 20px;"
            checkboxPosition="before">
            <span style="color: #444444; font-weight: bold;">{{option.value}}</span>
            {{option.label}}
          </mat-list-option>
          <!-- <mat-list-option *ngFor="let option of control.options"
            [value]="option.key"
            style="display:inline-block; width:50%; text-align:left; height: auto; margin-bottom: 20px;"
            checkboxPosition="before">
            {{option.value}}
          </mat-list-option> -->
        </mat-selection-list>
        <mat-selection-list *ngIf="control.key=='OtherIncomeSources'"
          value="control.value"
          formControlName="{{control.key}}"
          [id]="control.key"
          required={{control.required}}>
          <mat-list-option *ngFor="let option of control.options"
            [value]="option.key"
            style="height: auto; margin-bottom: 20px;"
            checkboxPosition="before">
            <span style="color: #444444; font-weight: bold;">{{option.value}}</span>
            {{option.label}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>

</div>
