<div [formGroup]="form">

  <div [ngSwitch]="control.controlType"
    fxLayout="column wrap"
    fxLayoutAlign="start"
    fxFlex="100">
    <div class="h2 mb-24"
      style="margin-top:20px;color: #206030;"
      *ngSwitchCase="'label'">{{control.label}}</div>
    <div *ngSwitchCase="'textbox'"
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="1 0 auto">
      <div fxLayoutAlign="start center"
        fxLayout="row wrap"
        fxFlex="100"
        style="margin-top: 10px;margin-bottom: 10px;">

        <span class="name"
          style="font-size: 15px;"
          fxFlex="50"
          [attr.for]="control.key"><span *ngIf="control.required==true"
            style="color: red;font-weight: bold;">* </span>{{control.label}} <mat-icon
            style="margin-top: 5px;margin-left: 5px"
            matTooltip={{control.tooltip}}
            matSuffix>info
          </mat-icon></span>

        <mat-form-field appearance="outline"
          *ngIf="control.key=='IncomeDesc'"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            class="leftA"
            autocomplete="off"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
          *ngIf="control.key!='IncomeDesc'"
          fxFlex="50">
          <input *ngSwitchCase="'textbox'"
            class="rightA"
            autocomplete="off"
            NumbersOnly
            type="text"
            decimals="100"
            negative="1"
            (click)="$event.target.select()"
            [formControlName]="control.key"
            [id]="control.key"
            [type]="control.type"
            matInput
            required={{control.required}}>

          <mat-error>{{control.message}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngSwitchCase="'checkbox'"
      fxLayoutAlign="start"
      fxLayout="row wrap"
      fxFlex="100"
      style="margin-top: 10px;margin-bottom: 10px;">

      <span class="name"
        style="font-size: 15px;"
        [attr.for]="control.key">{{control.label}} <mat-icon style="margin-top: 5px;margin-left: 5px"
          matTooltip={{control.tooltip}}
          matSuffix>info
        </mat-icon></span>

      <mat-checkbox style="margin-left: 5px;"
        [formControlName]="control.key"
        [value]="control.value"></mat-checkbox>
    </div>
  </div>

</div>
