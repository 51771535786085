import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';


import { FuseSharedModule } from '@fuse/shared.module';

import { Layouts } from 'app/enum/layouts.enum';
import { LoginHomeComponent } from './login-home.component';
import { ProfileListService } from 'app/pages/Profile/Profile-List/ProfileList.service';
import { TranslateModule } from '@ngx-translate/core';


const routes = [
    {
        path: 'login-home',
        component: LoginHomeComponent,
        data: { layout: Layouts.Home },
    },
    {
        path: 'login-home/:meassage',
        component: LoginHomeComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        LoginHomeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        TranslateModule,

        MatSnackBarModule,

        FuseSharedModule
    ],
    providers: [

        ProfileListService
    ],
})
export class LoginHomeModule
{
}
