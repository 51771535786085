import { Component, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/util/utils';

import { NgxSpinnerService } from 'ngx-spinner';
import { TransactionService } from 'app/pages/Transaction/Transaction.service';

import * as moment from 'moment';
import { PaymentService } from '../Payment.service';
import { forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';


@Component({
    selector: 'ChalanDialog',
    templateUrl: 'chalan-Dialog.component.html',
    styleUrls: ['./chalan-Dialog.component.scss'],
})
export class ChalanDialog
{


    lang = '';

    DropdownData: any;
    PageResponseData: any;


    TaxAmountControl = new FormControl();
    ChalanAmountControl = new FormControl();
    PaymentModeControl = new FormControl();
    ChequePOControl = new FormControl();
    BankNamesControl = new FormControl();
    BranchNamesControl = new FormControl();

    TransactionNoControl = new FormControl();
    TransactionRemarksControl = new FormControl();

    BcTabMainForm: FormGroup;

    maxDate = new Date();
    BcTabBillEnabled = false;


    constructor(
        public dialogRef: MatDialogRef<ChalanDialog>,
        private _matSnackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _formControlService: PaymentService,
        private _transactionControlService: TransactionService,
        private http: HttpClient,
        private _spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,

        @Inject(MAT_DIALOG_DATA) public dialogdata: any
    )
    {
        // this.pform = this._formBuilder.group(new DocumentUploadModel());
        dialogRef.disableClose = true;
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);

        this.lang = localStorage.getItem('selectedLanguage');
        // console.log(this.dialogdata, 'this.dialogdata');

        this.GetChalanData();

    }

    radioChangeN(event)
    {

        // let floorElement1: HTMLElement = document.getElementById("Cheque") as HTMLElement;
        let floorElement2: HTMLElement = document.getElementById("BankN") as HTMLElement;
        let floorElement3: HTMLElement = document.getElementById("TransactionN") as HTMLElement;


        if (event.value == "2")
        {
            // if (floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.remove('hidden');
            // }
            // if (!floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.add('block');
            // }

            if (floorElement2.classList.contains('hidden'))
            {
                floorElement2.classList.remove('hidden');
            }
            if (!floorElement2.classList.contains('block'))
            {
                floorElement2.classList.add('block');
            }

            // if (!floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.add('hidden');
            // }
            // if (floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.remove('block');
            // }

            this.BankNamesControl.setValue(null);
            this.BranchNamesControl.setValue('');
            this.ChequePOControl.setValue('');
        }
        else
        {
            // if (!floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.add('hidden');
            // }
            // if (floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.remove('block');
            // }

            if (!floorElement2.classList.contains('hidden'))
            {
                floorElement2.classList.add('hidden');
            }
            if (floorElement2.classList.contains('block'))
            {
                floorElement2.classList.remove('block');
            }

            // if (floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.remove('hidden');
            // }
            // if (!floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.add('block');
            // }

            this.BankNamesControl.setValue(null);
            this.BranchNamesControl.setValue('');
            this.ChequePOControl.setValue('');
        }

        if (event.value == "3")
        {
            // if (floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.remove('hidden');
            // }
            // if (!floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.add('block');
            // }

            if (floorElement3.classList.contains('hidden'))
            {
                floorElement3.classList.remove('hidden');
            }
            if (!floorElement3.classList.contains('block'))
            {
                floorElement3.classList.add('block');
            }

            // if (!floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.add('hidden');
            // }
            // if (floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.remove('block');
            // }

            this.TransactionNoControl.setValue('');
            this.TransactionRemarksControl.setValue('');

        }
        else
        {
            // if (!floorElement1.classList.contains('hidden'))
            // {
            //     floorElement1.classList.add('hidden');
            // }
            // if (floorElement1.classList.contains('block'))
            // {
            //     floorElement1.classList.remove('block');
            // }

            if (!floorElement3.classList.contains('hidden'))
            {
                floorElement3.classList.add('hidden');
            }
            if (floorElement3.classList.contains('block'))
            {
                floorElement3.classList.remove('block');
            }

            // if (floorElement2.classList.contains('hidden'))
            // {
            //     floorElement2.classList.remove('hidden');
            // }
            // if (!floorElement2.classList.contains('block'))
            // {
            //     floorElement2.classList.add('block');
            // }

            this.TransactionNoControl.setValue('');
            this.TransactionRemarksControl.setValue('');
        }

    }



    GetChalanData(): void
    {
        this._spinner.show();
        let body = {
            "AssesseeId": this.dialogdata.AssesseeId,
            "ReturnId": this.dialogdata.ReturnId,
            "DateRange": null,
            "AssessmentYearId": null
        }

        forkJoin([
            this._formControlService.getCommonDropdown(),
            this._formControlService.LoadChalanBasicInfo(body)]).subscribe(
                (result: any) =>
                {

                    let dropDownResponse = result[0];
                    // console.log(dropDownResponse, 'dropDownResponse');

                    this.DropdownData = dropDownResponse;

                    let pageDataResponse = result[1];
                    // console.log(pageDataResponse, 'pageDataResponse');

                    this.PageResponseData = pageDataResponse.data

                    if (this.PageResponseData !== null)
                    {
                        this.TaxAmountControl.setValue(this.PageResponseData.TaxAmount);
                        this.ChalanAmountControl.setValue(this.PageResponseData.ChallanAmount);
                    }
                    this._spinner.hide();


                }, (error: any) =>
            {
                this._spinner.hide();
                console.log(error, 'error');
            })
    }


    DownloadChalan()
    {
        // console.log(this.BankNamesControl.value, 'this.BankNamesControl.value');

        if (this.ChalanAmountControl.value == null || this.ChalanAmountControl.value == '')
        {
            this._matSnackBar.open('Please Enter Chalan Amount', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (this.PaymentModeControl.value == null || this.PaymentModeControl.value == '')
            {
                this._matSnackBar.open('Please Select A Payment Mode', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            } else
            {
                let body =
                {
                    "AccountCodeNo": this.PageResponseData.AccountCodeNo,
                    "AssesseeId": this.PageResponseData.AssesseeId,
                    "AssesseeName": this.PageResponseData.AssesseeName,
                    "AssesseeAddress": this.PageResponseData.AssesseeAddress,
                    "BankId": this.BankNamesControl.value != null ? this.BankNamesControl.value.key : null,
                    "BankName": this.BankNamesControl.value != null ? this.BankNamesControl.value.value : null,
                    "Branch": this.BranchNamesControl.value,
                    "ChequeNo": this.ChequePOControl.value,
                    "ChallanAmount": this.ChalanAmountControl.value,
                    "Circle": this.PageResponseData.Circle,
                    "ContactNo": this.PageResponseData.ContactNo,
                    "Email": this.PageResponseData.Email,
                    "ETIN": this.PageResponseData.ETIN,
                    "PaymentTypeId": this.PaymentModeControl.value,
                    "PractitionerId": this.PageResponseData.PractitionerId,
                    "ReturnId": this.PageResponseData.ReturnId,
                    "SubmittedBy": "self",
                    // "SubmittedType" : "Test",
                    "TaxAmount": this.TaxAmountControl.value,
                    "TaxYear": this.PageResponseData.TaxYear,
                    "Zone": this.PageResponseData.Zone,
                    "PractitionerName": null,
                    "PractitionerBarMemberShipNo": null,
                    "PractitionerAddress": null,
                    "TransactionNo": this.TransactionNoControl.value,
                    "TransactionRemarks": this.TransactionRemarksControl.value,
                }
                // console.log(body, 'body');

                this._spinner.show();
                const authToken = localStorage.getItem('userToken');
                return this.http.post(Config.CHALAN_DOWNLOAD, body, {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    }), responseType: 'blob'
                }).subscribe(
                    res =>
                    {
                        // console.log(res, 'res');
                        if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                        {
                            let reader = new FileReader();
                            let base64data = null;
                            reader.readAsDataURL(res);
                            reader.onloadend = function ()
                            {
                                base64data = reader.result;
                                
                                let pdfWindow = window.open("")
                                pdfWindow.document.write(
                                    "<iframe width='100%' height='100%' src=" +
                                    base64data + "></iframe>"
                                )
                            }
                        }
                        else
                        {
                            saveAs(res, `Chalan_${this.dialogdata.AssesseeId}_${this.dialogdata.ReturnId}.pdf`);
                        }
                        this._spinner.hide();

                        this.dialogRef.close();

                        // this._matSnackBar.open('Chalan Download Succesfull', this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        //     verticalPosition: 'top',
                        //     duration: 2000
                        // });
                    },
                    error =>
                    {
                        this._spinner.hide();
                        console.log(error, 'error');
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    })
            }
        }
    }



    onNoClick(): void
    {
        this.dialogRef.close("no");
    }







}


let gg = {
    "Id": 0,
    "PractitionerId": 0,
    "PractitionerName": null,
    "PractitionerBarMemberShipNo": null,
    "PractitionerAddress": null,
    "AssesseeId": 317,
    "ReturnId": 315,
    "ETIN": "311231312212",
    "AssesseeName": "grthfyswibau",
    "AssesseeAddress": "23423423423",
    "ContactNo": null,
    "Email": null,
    "TaxAmount": null,
    "ChallanAmount": null,
    "Zone": "Tax Zone-1, Dhaka",
    "AccountCodeNo": "১-১১৪১-০০০১-০১১১",
    "Circle": "2232",
    "TaxYear": "Year 2021-2022",
    "PaymentTypeId": 0,
    "BankId": 0,
    "Branch": null,
    "SubmittedBy": 0,
    "SubmittedType": null
}
