<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2> Return & Refund Policy</h2>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area ptb-100"
    style="padding-top: 0; ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">
                    <div class="features-text">
                        <h4>Updated at 2021-09-06</h4>
                    </div>
                    <div class="features-text">
                        <h4>Definitions and key terms</h4>
                        <p style="color: #444444;">To help explain things as clearly as possible in this Return & Refund
                            Policy, every time any of these terms are referenced, are strictly defined as:
                        </p>
                        <p style="color: #444444;"> &bull; Company: when this policy mentions “Company,” “we,” “us,” or
                            “our,”
                            it refers to digiTax, which is responsible for your information under this Return & Refund
                            Policy.</p>
                        <p style="color: #444444;"> &bull; Customer: refers to the company, organization or person that
                            signs
                            up to use the digiTax Service to manage the relationships with your consumers or service
                            users.</p>
                        <p style="color: #444444;"> &bull; Device: any internet-connected device such as a phone,
                            tablet,
                            computer or any other device that can be used to visit digiTax and use the services.</p>
                        <p style="color: #444444;"> &bull; Service: refers to the service provided by digiTax as
                            described in
                            the relative terms (if available) and on this platform.</p>
                        <p style="color: #444444;"> &bull; Website: digiTax."’s" site, which can be accessed via this
                            URL:
                            https://digitax.com.bd</p>
                        <p style="color: #444444;"> &bull; You: a person or entity that is registered with digiTax to
                            use the
                            Services.</p>
                    </div>
                    <div class="features-text">
                        <h4>Return & Refund Policy</h4>
                        <p style="color: #444444;">Thanks for using digiTax. We appreciate the fact that you like to use
                            the product we build. We also want to make sure you have a rewarding experience while you’re
                            exploring, evaluating, and using our products.</p>
                        <p style="color: #444444;">As with any purchase experience, there are terms and conditions that
                            apply to transactions at digiTax. We’ll be as brief as our attorneys will allow. The main
                            thing to remember is that by making a purchase at digiTax, you agree to the terms set forth
                            below along with the Policy.</p>
                        <p style="color: #444444;">Once the payment has been completed and the download option is unlocked immediately, you will not be able to claim a refund.</p>
                    </div>
                    <div class="features-text">
                        <h4>Your Consent</h4>
                        <p style="color: #444444;">By using our website/app, registering an account, or making a
                            purchase, you hereby consent to our Return & Refund Policy and agree to its terms.</p>
                    </div>
                    <div class="features-text">
                        <h4>Changes To Our Return & Refund Policy</h4>
                        <p style="color: #444444;">Should we update, amend or make any changes to this document so that
                            they accurately reflect our Service and policies? Unless otherwise required by law, those
                            changes will be prominently posted here. Then, if you continue to use the Service, you will
                            be bound by the updated Return & Refund Policy. If you do not want to agree to this or any
                            updated Return & Refund Policy, you can discontinue using your account.</p>
                    </div>
                    <div class="features-text">
                        <h4>Contact Us</h4>
                        <p style="color: #444444;">Don't hesitate to contact us if you have any questions.</p>
                        <p style="color: #444444;"> &bull; Via Email: support@digitax.com.bd</p>
                        <p style="color: #444444;"> &bull; Via Phone Number: 09643 224488</p>
                        <p style="color: #444444;"> &bull; Via this Link: https://digitax.com.bd/contact</p>
                        <p style="color: #444444;"> &bull; Via this Address: Desh Universal (Pvt.) Limited As-Salam
                            Tower
                            (Level 5), 82-83 Zoo Road,
                            Block-A, Section-2, Mirpur, Dhaka-1216</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->
