<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            fusePerfectScrollbar
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>

    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'MOTOR.HEADER' | translate }}
            </span>

        </div>
        <div class="content mb-48"
            fusePerfectScrollbar>
            <div class="course-step p-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="column">

                <table class="simple invoice-table"
                    *ngIf="getData!=null">

                    <thead>
                        <tr>
                            <th class="thNewline"
                                style="color: #444444;">
                                {{getData.Header.Header1}}
                            </th>
                            <th class="thNewline"
                                style="color: #444444;">
                                {{getData.Header.Header2}}
                            </th>
                            <th class="thNewline"
                                style="color: #444444;">
                                {{getData.Header.Header3}}
                            </th>
                            <th class="thNewline"
                                style="color: #444444;">
                                {{getData.Header.Header4}}
                            </th>
                            <th class="thNewline"
                                style="color: #444444;">
                                {{getData.Header.Header5}}
                            </th>
                            <th class="text-right thNewline"
                                style="color: #444444;">
                                {{getData.Header.Header6}}
                            </th>

                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let data of getData.Body">
                            <td>
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="start center">
                                    <a class="nav-link"
                                        style="color: #29793C;"
                                        fxLayout="column wrap"
                                        [routerLink]="'/MotorVehicle/save/' + data.Id"
                                        matTooltip="{{ 'COMMON.BUTTON_EDIT' | translate }}">
                                        <mat-icon class="nav-link-icon">create</mat-icon>
                                    </a>

                                    <a style="cursor: pointer; border: 0; color: #29793C;"
                                        fxLayout="column wrap"
                                        (click)="CheckDelete(data.Id)"
                                        matTooltip="{{ 'COMMON.BUTTON_DELETE' | translate }}">
                                        <mat-icon class="nav-link-icon">delete</mat-icon>
                                    </a>
                                </div>
                            </td>
                            <td>
                                {{data.Type}}
                            </td>
                            <td>
                                {{data.Description}}
                            </td>
                            <td>
                                {{data.Engine}}
                            </td>
                            <td>
                                {{data.Registration}}
                            </td>
                            <td class="text-right">
                                {{data.Amount}}
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div fxLayout="row wrap"
                    *ngIf="getData!=null"
                    fxLayoutAlign="space-between center"
                    style=" border-top: 3px solid #29793c;padding: 5px;">
                    <div style="color: red;">
                        {{ 'COMMON.BUTTON_ROW' | translate }}: {{getData.TotalRows}}
                    </div>
                    <div style="color: red;">
                        {{ 'COMMON.BUTTON_AMOUNT' | translate }}: {{getData.TotalAmount}}
                    </div>
                </div>

            </div>
        </div>
        <div class="step-navigation">
            <button mat-raised-button
                (click)="improvisedIMenu('prev')"
                style="border: 2px solid #37793c;
        background-color: #ffffff;line-height: 30px;
        cursor: pointer; margin-right: 10px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_PREVIOUS_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_PREVIOUS' | translate }}
            </button>

            <a [routerLink]="'/MotorVehicle/save'"
                mat-raised-button
                class="mat-button dupl"
                fxFlex="0 0 auto"
                fxLayout="row"
                fxLayoutAlign="start center">
                <span>{{ 'COMMON.BUTTON_ADD_MORE' | translate }}</span>
            </a>

            <button mat-raised-button
                (click)="improvisedIMenu('next')"
                style="border: 2px solid #37793c;
        background-color: #ffffff;
        line-height: 30px;
        cursor: pointer; margin-left: 10px;"
                fxLayoutAlign="center center"
                matTooltip="{{ 'COMMON.BUTTON_NEXT_PAGE' | translate }}">
                {{ 'COMMON.BUTTON_NEXT' | translate }}
            </button>
        </div>
    </div>
</div>
