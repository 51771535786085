import { Component, HostListener, Input } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { BaseControl } from 'app/models/dynamic-form';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertyService } from './Property.service';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import Utils from 'app/util/utils';
import { AuthenticationService } from 'app/auth';
import { SidePanelDeleteService } from 'app/services/sidePanelDelete.service';



@Component({
    selector: 'Property',
    templateUrl: './Property.component.html',
    styleUrls: ['./Property.component.scss']
})
export class PropertyComponent
{
    @Input() controls: BaseControl<string>[] = [];
    form: FormGroup;
    id: any;
    lang = '';
    payLoad = '';
    formcontrols$: Observable<BaseControl<string>[]>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    @Input() rPropertyType: boolean;

    /**
     * Constructor
     * @param {MatSnackBar} _matSnackBar
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: PropertyService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private sidePanelDeleteService: SidePanelDeleteService,

        private _authService: AuthenticationService,

        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,

        private _route: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,



    )
    {

        this.meta.addTags([
            { name: 'description', content: "digiTax Income From Property" },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: "digiTax Income From Property" }
        ]);
        this.setTitle("digiTax | Income From Property");
        this._spinner.show();
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.id = this._route.snapshot.paramMap.get('id');

        this.lang = localStorage.getItem('selectedLanguage');
        this._formControlService.getControlJson(this.id).subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            this.controls = data;
            this.form = this._formControlService.toFormGroup(this.controls);
        });

        // this._formControlService.getControls().subscribe((data: any) =>
        // {
        //     this.controls = data;
        //     this.form = this._formControlService.toFormGroup(this.controls);
        // });
        this.form = this._formControlService.toFormGroup(this.controls);

    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {

    }


    mySubmitExit(param): void
    {
        // debugger;
        if (param == 'next' || param == 'more')
        {
            this.OnSubmit(param);
        } else
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.SAVE_EXIT : bangla.data.COMMON.SAVE_EXIT;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    this.OnSubmit(param)
                }
                this.confirmDialogRef = null;
            });
        }

    }

    OnSubmit(param): void
    {
        if (!this.form.valid)
        {
            this.form.markAllAsTouched();
        }

        if (!this.form.controls.PropertyType.valid)
        {
            this.rPropertyType = true;
        }
        this._spinner.show();
        this.id = this._route.snapshot.paramMap.get('id');

        let obj = this.form.getRawValue();

        let hasType = false;
        if (obj.PropertyType != "")
        {
            hasType = true;
        }

        if (isNaN(Number(obj.AnnualAmountReceived)) == true)
        {
            obj.AnnualAmountReceived = "";
        }

        if (isNaN(Number(obj.MunicipalLocalTax)) == true)
        {
            obj.MunicipalLocalTax = "";
        }

        if (isNaN(Number(obj.LandRevenue)) == true)
        {
            obj.LandRevenue = "";
        }

        if (isNaN(Number(obj.LoanMortgageCapitalInterest)) == true)
        {
            obj.LoanMortgageCapitalInterest = "";
        }

        if (isNaN(Number(obj.InsurancePremium)) == true)
        {
            obj.InsurancePremium = "";
        }

        if (isNaN(Number(obj.VacancyAllowance)) == true)
        {
            obj.VacancyAllowance = "";
        }

        if (isNaN(Number(obj.Other)) == true)
        {
            obj.Other = "";
        }

        if (isNaN(Number(obj.AsesseeSharePercent)) == true)
        {
            obj.AsesseeSharePercent = "";
        }

        if (obj.AsesseeSharePercent == '')
        {
            obj.AsesseeSharePercent = 100;
        }

        let stringed = JSON.stringify(obj);

        if (hasType == false)
        {
            this._spinner.hide();
            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_TYPE : bangla.data.COMMON.BUTTON_TYPE, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (obj.LocationAndDescription == "")
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.PROPERTY.R2 : bangla.data.PROPERTY.R2, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (obj.TotalArea == "")
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.PROPERTY.R3 : bangla.data.PROPERTY.R3, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    if (obj.AnnualAmountReceived == "" || obj.AnnualAmountReceived == 0)
                    {
                        this._spinner.hide();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.PROPERTY.R1 : bangla.data.PROPERTY.R1, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 2000
                        });
                    }
                    else
                    {
                        if (stringed != null)
                        {
                            this._formControlService.submitProperty(stringed, this.id)
                                .subscribe((data: any) =>
                                {
                                    if (data != null)
                                    {
                                        this._spinner.hide();
                                    }

                                    if (data.isError === false)
                                    {
                                        console.log('submit succesfull');
                                    }
                                    else
                                    {

                                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                            verticalPosition: 'top',
                                            duration: 2000
                                        });
                                    }
                                    if (data.isError === false && data.responseCode == "200")
                                    {
                                        if (this.id != null)
                                        {
                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_UPDATED : bangla.data.COMMON.BUTTON_UPDATED, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                verticalPosition: 'top',
                                                duration: 2000
                                            });
                                        }
                                        else
                                        {
                                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                                verticalPosition: 'top',
                                                duration: 2000
                                            });
                                        }
                                        if (param == 'next')
                                        {
                                            this.improvisedIMenu(param);
                                        }
                                        if (param == 'more')
                                        {
                                            this.sidePanelDeleteService.changeMessage(true);
                                            this.form.reset();
                                        }
                                        if (param == 'exit')
                                        {
                                            this._authService.Logout();
                                        }

                                    }
                                }, (error: any) =>
                                {
                                    console.log(error, 'error');
                                });
                        }
                    }
                }
            }
        }
    }

    ConfirmLeave(url): void
    {
        if (this.form.dirty)
        {
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: false
            });

            this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.LABEL_NOT_SAVED : bangla.data.COMMON.LABEL_NOT_SAVED;

            this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

            this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

            this.confirmDialogRef.afterClosed().subscribe(result =>
            {
                if (result)
                {
                    // this._router.navigate([url]);
                    this.improvisedIMenu(url);
                }
                this.confirmDialogRef = null;
            });
        }
        else
        {
            // this._router.navigate([url]);
            this.improvisedIMenu(url);
        }
    }

    improvisedIMenu(type): void
    {
        let url = Utils.IMenu(this._router.url, type, 'income');
        // console.log(url, 'url');
        this._router.navigate([url]);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

