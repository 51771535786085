<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">
    <div class="center">

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px;">Manage Bills
            </span>
        </div>
        <div class="content p-24">
            <div class="mb-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row">
                <mat-tab-group fxLayout="column"
                    fxFlex
                    (selectedTabChange)="onChangeTab($event)">

                    <mat-tab label="Collection Register">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="CrTabSearchControl"
                                                [matAutocomplete]="CrTabAuto">
                                            <mat-autocomplete #CrTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of CrTabfilteredOptions"
                                                    (onSelectionChange)="CrTabSearchClient(option)"
                                                    [value]="option.AssesseeName">
                                                    {{option.AssesseeName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="CrTabFromDateControl"
                                                [matDatepicker]="CrTabFromDate"
                                                readonly
                                                (click)="CrTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="CrTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #CrTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="CrTabToDateControl"
                                                [matDatepicker]="CrTabToDate"
                                                readonly
                                                (click)="CrTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="CrTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #CrTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between center"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Amount:
                                        </span>

                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="CrTabFromAmountControl"
                                                (keyup)="onFocusoutEvent($event,'CrTabFromAmountControl')"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Amount:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="CrTabToAmountControl"
                                                (keyup)="onFocusoutEvent($event,'CrTabToAmountControl')"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>

                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;"
                                        *ngIf="dropDownData!=null">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Payment Type:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <mat-select [formControl]="CrTabPayTypeControl">
                                                <mat-option *ngFor="let option of dropDownData.PractitionerPaymentTypes"
                                                    [value]="option.key">
                                                    {{option.value}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row"
                                    fxLayoutAlign="center center"
                                    style="margin: 10px;">
                                    <button mat-raised-button
                                        (click)="SearchCollection()"
                                        style="margin: 10px;"
                                        class="mat-button mat-button-base dupl px-16">
                                        SEARCH
                                    </button>
                                    <button mat-raised-button
                                        (click)="openCollectionDialog()"
                                        style="margin: 10px;"
                                        class="mat-button mat-button-base dupl px-16">
                                        CREATE
                                    </button>
                                </div>
                                <!-- <div fxLayout="column"
                                    fxLayoutAlign="center center"
                                    fxFlex="33"
                                    style="margin: 10px;">
                                    <button mat-raised-button
                                        (click)="SearchCollection('filtered')"
                                        style="margin: 10px;"
                                        class="mat-button mat-button-base dupl px-16">
                                        SEARCH
                                    </button>
                                </div> -->
                                <div fxLayout="column"
                                    *ngIf="CrTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">
                                    <table class="simple invoice-table"
                                        *ngIf="CrTabgetData.length>0">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>

                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TAX YEAR
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    DATE
                                                </th>
                                                <th class="text-right"
                                                    style="color: #444444;">
                                                    RECEIPT NO.
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    NAME
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    MOBILE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    PAYMENT TYPE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ACCOUNT
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    AMOUNT
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of CrTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.AssessmentYear}}
                                                </td>
                                                <td>
                                                    {{item.TransactionMasterId}}
                                                </td>
                                                <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td>
                                                <td>
                                                    {{item.ReceiptNo}}
                                                </td>
                                                <td>
                                                    {{item.TransactionMasterId}}
                                                </td>
                                                <td>
                                                    {{item.AssesseName}}
                                                </td>
                                                <td>
                                                    {{item.ContactNo}}
                                                </td>
                                                <td>
                                                    {{item.PaymentType}}
                                                </td>
                                                <td>
                                                    {{item.AccounHeadtName}}
                                                </td>
                                                <td>
                                                    {{item.Remarks}}
                                                </td>
                                                <td class="text-right thNewline">
                                                    {{item.TotalCollection}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>
                                    <div *ngIf="CrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="start space-between"
                                        style="padding: 10px;">
                                        <span style="margin: 10px;">
                                            <b> Total Records: {{CrTabgetData.length}}</b>
                                        </span>
                                        <span style="margin: 10px;">
                                            <b> Total Collection: {{CrTabTotalCollection}}</b>
                                        </span>
                                    </div>
                                    <div *ngIf="CrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="CrDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                    <div *ngIf="CrTabgetData.length==0"
                                        fxLayoutAlign="center center"
                                        class="h2 p-12">
                                        <span> No Transaction Found within the Data range of
                                            <span><b>{{moment(CrTabFromDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span> to
                                            <span><b>{{moment(CrTabToDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Dues Register">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="DrTabSearchControl"
                                                [matAutocomplete]="DrTabAuto">
                                            <mat-autocomplete #DrTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of DrTabfilteredOptions"
                                                    (onSelectionChange)="DrTabSearchClient(option)"
                                                    [value]="option.AssesseeName">
                                                    {{option.AssesseeName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="DrTabFromDateControl"
                                                [matDatepicker]="DrTabFromDate"
                                                readonly
                                                (click)="DrTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="DrTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #DrTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="DrTabToDateControl"
                                                [matDatepicker]="DrTabToDate"
                                                readonly
                                                (click)="DrTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="DrTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #DrTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between center"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Amount:
                                        </span>

                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="DrTabFromAmountControl"
                                                (keyup)="onFocusoutEvent($event,'DrTabFromAmountControl')"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Amount:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="DrTabToAmountControl"
                                                (keyup)="onFocusoutEvent($event,'DrTabToAmountControl')"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px; display: none;"
                                            fxFlex="50">
                                        </span>
                                        <button mat-raised-button
                                            (click)="SearchDues()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            SEARCH
                                        </button>
                                    </div>
                                </div>
                                <div fxLayout="column"
                                    *ngIf="DrTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">
                                    <table *ngIf="DrTabgetData.length>0"
                                        class="simple invoice-table">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>

                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TAX YEAR
                                                </th>
                                                <!-- <th class="thNewline"
                                                    style="color: #444444;">
                                                    DATE
                                                </th> -->
                                                <!-- <th class="thNewline"
                                                    style="color: #444444;">
                                                    ID
                                                </th> -->
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    NAME
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    MOBILE
                                                </th>
                                                <!-- <th class="thNewline"
                                                    style="color: #444444;">
                                                    ACCOUNT
                                                </th> -->
                                                <!-- <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th> -->
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    DUES
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of DrTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.AssessmentYear}}
                                                </td>
                                                <!-- <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td> -->
                                                <!-- <td>
                                                    {{item.TransactionMasterId}}
                                                </td> -->
                                                <td>
                                                    {{item.AssesseName}}
                                                </td>
                                                <td>
                                                    {{item.ContactNo}}
                                                </td>
                                                <!-- <td>
                                                    {{item.AccounHeadtName}}
                                                </td> -->
                                                <!-- <td>
                                                    {{item.Remarks}}
                                                </td> -->
                                                <td class="text-right">
                                                    {{item.TotalDues}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>
                                    <div *ngIf="DrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="start space-between"
                                        style="padding: 10px;">
                                        <span style="margin: 10px;">
                                            <b> Total Records: {{DrTabgetData.length}}</b>
                                        </span>
                                        <span style="margin: 10px;">
                                            <b> Total Dues: {{DrTabTotalDues}}</b>
                                        </span>

                                    </div>
                                    <div *ngIf="DrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="DrDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                    <div *ngIf="DrTabgetData.length==0"
                                        fxLayoutAlign="center center"
                                        class="h2 p-12">
                                        <span> No Transaction Found within the Data range of
                                            <span><b>{{moment(CrTabFromDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span> to
                                            <span><b>{{moment(CrTabToDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Bill Register">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="BrTabSearchControl"
                                                [matAutocomplete]="BrTabAuto">
                                            <mat-autocomplete #BrTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of BrTabfilteredOptions"
                                                    (onSelectionChange)="BrTabSearchClient(option)"
                                                    [value]="option.AssesseeName">
                                                    {{option.AssesseeName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="BrTabFromDateControl"
                                                [matDatepicker]="BrTabFromDate"
                                                readonly
                                                (click)="BrTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="BrTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #BrTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="BrTabToDateControl"
                                                [matDatepicker]="BrTabToDate"
                                                readonly
                                                (click)="BrTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="BrTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #BrTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between center"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Amount:
                                        </span>

                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="BrTabFromAmountControl"
                                                (keyup)="onFocusoutEvent($event,'BrTabFromAmountControl')"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Amount:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="BrTabToAmountControl"
                                                (keyup)="onFocusoutEvent($event,'BrTabToAmountControl')"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"
                                        fxFlex="33"
                                        fxLayoutAlign="center center"
                                        style="margin: 10px;">

                                        <button mat-raised-button
                                            (click)="SearchBill()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            SEARCH
                                        </button>
                                        <button mat-raised-button
                                            (click)="openBillDialog()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            CREATE
                                        </button>
                                    </div>
                                </div>

                                <!-- <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between center"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Amount:
                                        </span>

                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="BrTabFromAmountControl"
                                                (click)="$event.target.select()"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Amount:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="BrTabToAmountControl"
                                                (click)="$event.target.select()"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="space-between center"
                                        style="margin: 10px;">
                  
                                <button mat-raised-button
                                    (click)="SearchBill('filtered')"
                                    style="margin: 10px;"
                                    class="mat-button mat-button-base dupl px-16">
                                    SEARCH
                                </button>
                                <button mat-raised-button
                                    (click)="openBillDialog()"
                                    style="margin: 10px;"
                                    class="mat-button mat-button-base dupl px-16">
                                    CREATE
                                </button>
                            </div>
                        </div> -->
                                <div fxLayout="column"
                                    *ngIf="BrTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">
                                    <table *ngIf="BrTabgetData.length>0"
                                        class="simple invoice-table">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>

                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TAX YEAR
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    DATE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    NAME
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    MOBILE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ACCOUNT
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    BILL
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of BrTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.AssessmentYear}}
                                                </td>
                                                <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td>
                                                <td>
                                                    {{item.TransactionMasterId}}
                                                </td>
                                                <td>
                                                    {{item.AssesseName}}
                                                </td>
                                                <td>
                                                    {{item.ContactNo}}
                                                </td>
                                                <td>
                                                    {{item.AccounHeadtName}}
                                                </td>
                                                <td>
                                                    {{item.Remarks}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.TotalBill}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>
                                    <div *ngIf="BrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="start space-between"
                                        style="padding: 10px;">
                                        <span style="margin: 10px;">
                                            <b> Total Records: {{BrTabgetData.length}}</b>
                                        </span>
                                        <span style="margin: 10px;">
                                            <b> Total Bill: {{BrTabTotalBill}}</b>
                                        </span>

                                    </div>
                                    <div *ngIf="BrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="BrDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                    <div *ngIf="BrTabgetData.length==0"
                                        fxLayoutAlign="center center"
                                        class="h2 p-12">
                                        <span> No Transaction Found within the Data range of
                                            <span><b>{{moment(CrTabFromDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span> to
                                            <span><b>{{moment(CrTabToDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Remission Register">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayoutAlign="start center"
                                    fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="RmTabSearchControl"
                                                [matAutocomplete]="RmTabAuto">
                                            <mat-autocomplete #RmTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of RmTabfilteredOptions"
                                                    (onSelectionChange)="RmTabSearchClient(option)"
                                                    [value]="option.AssesseeName">
                                                    {{option.AssesseeName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Date:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="RmTabFromDateControl"
                                                [matDatepicker]="RmTabFromDate"
                                                readonly
                                                (click)="RmTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="RmTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #RmTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Date:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="RmTabToDateControl"
                                                [matDatepicker]="RmTabToDate"
                                                readonly
                                                (click)="RmTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="RmTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #RmTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="start center"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;"
                                        *ngIf="dropDownData!=null">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Tax Year:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <mat-select id="clientYear"
                                                [formControl]="RmTabTaxYearControl">
                                                <mat-option *ngFor="let option of dropDownData.AssessmentYears"
                                                    [value]="option.key">
                                                    {{option.value}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <!-- <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px; display: none;"
                                            fxFlex="50">
                                        </span>
                                        <button mat-raised-button
                                            (click)="SearchRemission('filtered')"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            SEARCH
                                        </button>
                                    </div> -->
                                    <div fxLayout="row"
                                        fxFlex="33"
                                        fxLayoutAlign="center center"
                                        style="margin: 10px;">

                                        <button mat-raised-button
                                            (click)="SearchRemission('filtered')"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            SEARCH
                                        </button>
                                        <button mat-raised-button
                                            (click)="openRemissionDialog()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            CREATE
                                        </button>
                                    </div>
                                </div>
                                <div fxLayout="column"
                                    *ngIf="RmTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">
                                    <table *ngIf="RmTabgetData.length>0"
                                        class="simple invoice-table">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>

                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TAX YEAR
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    DATE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    NAME
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    MOBILE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ACCOUNT
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    REMISSION
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of RmTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.AssessmentYear}}
                                                </td>
                                                <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td>
                                                <td>
                                                    {{item.TransactionMasterId}}
                                                </td>
                                                <td>
                                                    {{item.AssesseName}}
                                                </td>
                                                <td>
                                                    {{item.ContactNo}}
                                                </td>
                                                <td>
                                                    {{item.AccounHeadtName}}
                                                </td>
                                                <td>
                                                    {{item.Remarks}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.TotalRemission}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>
                                    <div *ngIf="RmTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="start space-between"
                                        style="padding: 10px;">
                                        <span style="margin: 10px;">
                                            <b> Total Records: {{RmTabgetData.length}}</b>
                                        </span>
                                        <span style="margin: 10px;">
                                            <b> Total Remission: {{RmTabTotalRemission}}</b>
                                        </span>

                                    </div>
                                    <div *ngIf="RmTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="RmDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                    <div *ngIf="RmTabgetData.length==0"
                                        fxLayoutAlign="center center"
                                        class="h2 p-12">
                                        <span> No Transaction Found within the Data range of
                                            <span><b>{{moment(CrTabFromDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span> to
                                            <span><b>{{moment(CrTabToDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Ledger">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="LgTabSearchControl"
                                                [matAutocomplete]="LgTabAuto">
                                            <mat-autocomplete #LgTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of LgTabfilteredOptions"
                                                    (onSelectionChange)="LgTabSearchClient(option)"
                                                    [value]="option.AssesseeName">
                                                    {{option.AssesseeName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="LgTabFromDateControl"
                                                [matDatepicker]="LgTabFromDate"
                                                readonly
                                                (click)="LgTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="LgTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #LgTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="LgTabToDateControl"
                                                [matDatepicker]="LgTabToDate"
                                                readonly
                                                (click)="LgTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="LgTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #LgTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="column"
                                    fxLayoutAlign="center center"
                                    fxFlex="33"
                                    style="margin: 10px;">
                                    <button mat-raised-button
                                        (click)="SearchLedger('filtered')"
                                        style="margin: 10px;"
                                        class="mat-button mat-button-base dupl px-16">
                                        SEARCH
                                    </button>
                                </div>
                                <div fxLayout="column"
                                    *ngIf="LgTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">

                                    <table class="simple invoice-table"
                                        *ngIf="LgTabgetData.length>0">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN DATE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN TYPE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    DEBIT
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    CREDIT
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    BALANCE
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of LgTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td>
                                                <td>
                                                    {{item.TranType}}
                                                </td>
                                                <td>
                                                    {{item.TransactionId}}
                                                </td>
                                                <td>
                                                    {{item.Remarks}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.Debit}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.Credit}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.Balance}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>

                                    <div *ngIf="LgTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="LgDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                    <div *ngIf="LgTabgetData.length==0"
                                        fxLayoutAlign="center center"
                                        class="h2 p-12">
                                        <span> No Transaction Found within the Data range of
                                            <span><b>{{moment(CrTabFromDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span> to
                                            <span><b>{{moment(CrTabToDateControl.value).format('MMMM Do,
                                                    YYYY')}}</b></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <!-- original -->

                    <!-- <mat-tab label="Bill Collection"
                        id="bc">
                        <div class="tab-content">

                            <div fxLayout="column">
                                <div fxLayoutAlign="start center"
                                    fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline"
                                            [ngClass]="{'readonly': BcTabgetData!=null}">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="BcTabSearchControl"
                                                [matAutocomplete]="BcTabAuto">
                                            <mat-autocomplete #BcTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of BcTabfilteredOptions"
                                                    (onSelectionChange)="BcTabSearchClient(option)"
                                                    [value]="option.AssesseName">
                                                    {{option.AssesseName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="25"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Date:
                                        </span>
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'readonly': BcTabgetData!=null}">
                                            <input matInput
                                                [formControl]="BcTabFromDateControl"
                                                [matDatepicker]="BcTabFromDate"
                                                readonly
                                                (click)="BcTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="BcTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #BcTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="25"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Date:
                                        </span>
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'readonly': BcTabgetData!=null}">
                                            <input matInput
                                                [formControl]="BcTabToDateControl"
                                                [matDatepicker]="BcTabToDate"
                                                readonly
                                                (click)="BcTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="BcTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #BcTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="25"
                                        style="margin: 10px;"
                                        *ngIf="dropDownData!=null">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Tax Year:
                                        </span>
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'readonly': BcTabgetData!=null}">
                                            <mat-select id="clientYear"
                                                [formControl]="BcTabTaxYearControl">
                                                <mat-option *ngFor="let option of dropDownData.AssessmentYears"
                                                    [value]="option.key">
                                                    {{option.value}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="column"
                                    *ngIf="BcTabgetData != null">
                                    <div fxLayout="column"
                                        class="fuse-card auto-width mb-16 mt-16">
                                        <div fxLayout="row"
                                            fxLayoutAlign="center space-between">
                                            <span style="margin: 10px;">
                                                E-TIN: <b>{{BcTabClientEtin}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                Name: <b>{{BcTabClientName}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                Mobile No: <b>{{BcTabClientMobile}}</b>
                                            </span>
                                        </div>
                                        <div fxLayout="row"
                                            fxLayoutAlign="center space-between">
                                            <span style="margin: 10px;">
                                                Email: <b>{{BcTabClientEmail}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                Reference: <b>{{BcTabClientRef}}</b>
                                            </span>
                                        </div>
                                    </div>

                                    <div fxLayout="column"
                                        class="fuse-card auto-width mb-16 mt-16">
                                        <table class="simple invoice-table"
                                            [formGroup]="BcTabMainForm">
                                            <thead>
                                                <tr>
                                                    <th class="thNewline"
                                                        style="color: #444444;">
                                                        SL
                                                    </th>

                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        Charge AS
                                                    </th>
                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        DUES
                                                    </th>
                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        <mat-checkbox style="margin-right: 5px;"
                                                            (change)="showBcTabBills($event)"></mat-checkbox> BILL
                                                    </th>
                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        <mat-checkbox style="margin-right: 5px;"
                                                            (change)="showBcTabCollections($event)"></mat-checkbox>
                                                        COLLECTION
                                                    </th>
                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        NOTES
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="items"
                                                *ngFor="let item of BcTabMainFormitems().controls; let i=index">
                                                <tr [formGroupName]="i">
                                                    <td>
                                                        {{i+1}}
                                                    </td>
                                                    <td>

                                                        <input type="text"
                                                            formControlName="AccounHeadtName"
                                                            readonly
                                                            style="border:0; text-align: center;">
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="outline"
                                                            class="readonly">
                                                            <input type="text"
                                                                formControlName="Dues"
                                                                decimals="100"
                                                                negative="1"
                                                                tabindex="-1"
                                                                (click)="$event.target.select()"
                                                                class="rightA"
                                                                autocomplete="off"
                                                                matInput>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="outline"
                                                            class="{{BcTabBillEnabled==false ? 'readonly' : ''}}">
                                                            <input NumbersOnly
                                                                type="text"
                                                                decimals="100"
                                                                negative="1"
                                                                formControlName="Bill"
                                                                (click)="$event.target.select()"
                                                                (keyup)="BcTabOnFocusoutEvent($event,'Bill',i)"
                                                                class="rightA"
                                                                autocomplete="off"
                                                                matInput>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="outline"
                                                            class="{{BcTabCollectionEnabled==false ? 'readonly' : ''}}">
                                                            <input NumbersOnly
                                                                type="text"
                                                                decimals="100"
                                                                negative="1"
                                                                formControlName="Collection"
                                                                (click)="$event.target.select()"
                                                                (keyup)="BcTabOnFocusoutEvent($event,'Collection',i)"
                                                                class="rightA"
                                                                autocomplete="off"
                                                                matInput>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="outline">
                                                            <input type="text"
                                                                decimals="100"
                                                                negative="1"
                                                                formControlName="Notes"
                                                                (click)="$event.target.select()"
                                                                class="rightA"
                                                                autocomplete="off"
                                                                matInput>
                                                        </mat-form-field>
                                                    </td>

                                                </tr>
                                            </tbody>

                                        </table>
                                        <div fxLayout="row"
                                            fxLayoutAlign="start space-between"
                                            style="padding: 10px;">
                                            <span style="margin: 10px;">
                                                <b> Total Dues: {{BcTabTotalDues}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                <b> Total Bill: {{BcTabTotalBill}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                <b> Total Collection: {{BcTabTotalCollection}}</b>
                                            </span>

                                        </div>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <div fxLayout="column"
                                            fxLayoutAlign="start"
                                            fxFlex="33"
                                            style="margin: 10px;"
                                            *ngIf="dropDownData!=null">
                                            <span class="name"
                                                style="font-size: 15px;"
                                                fxFlex="100">Transaction Date:
                                            </span>
                                            <mat-form-field appearance="outline">
                                                <input matInput
                                                    [formControl]="BcTabDateControl"
                                                    [matDatepicker]="BcTabTranDate"
                                                    readonly
                                                    [max]="maxDate"
                                                    (click)="BcTabTranDate.open()">
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="BcTabTranDate"></mat-datepicker-toggle>
                                                <mat-datepicker #BcTabTranDate
                                                    disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column"
                                            fxLayoutAlign="start"
                                            fxFlex="33"
                                            style="margin: 10px;"
                                            *ngIf="dropDownData!=null">
                                            <span class="name"
                                                style="font-size: 15px;"
                                                fxFlex="100">Payment Type:
                                            </span>
                                            <mat-form-field appearance="outline">
                                                <mat-select required
                                                    [formControl]="BcTabPayTypeControl">
                                                    <mat-option
                                                        *ngFor="let option of dropDownData.PractitionerPaymentTypes"
                                                        [value]="option.key">
                                                        {{option.value}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error>Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column"
                                            fxLayoutAlign="start"
                                            fxFlex="33"
                                            style="margin: 10px;">
                                            <span class="name"
                                                style="font-size: 15px;"
                                                fxFlex="100">Remarks:
                                            </span>
                                            <mat-form-field appearance="outline">
                                                <input class="leftA"
                                                    [formControl]="BcTabRemarksControl"
                                                    value=""
                                                    matInput>

                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="cancelBC()"
                                            style="color: #444444 !important; margin: 10px;"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            CANCEL
                                        </button>
                                        <button mat-raised-button
                                            (click)="SaveBC()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            {{ 'COMMON.BUTTON_SAVE' | translate }}
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </mat-tab> -->

                    <!-- <mat-tab label="Remission"
                        id="rm">
                        <div class="tab-content">

                            <div fxLayout="column">
                                <div fxLayoutAlign="start center"
                                    fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="25"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline"
                                            [ngClass]="{'readonly': RmTabgetData!=null}">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="RmTabSearchControl"
                                                [matAutocomplete]="RmTabAuto">
                                            <mat-autocomplete #RmTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of RmTabfilteredOptions"
                                                    (onSelectionChange)="RmTabSearchClient(option)"
                                                    [value]="option.AssesseName">
                                                    {{option.AssesseName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="25"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Date:
                                        </span>
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'readonly': RmTabgetData!=null}">
                                            <input matInput
                                                [formControl]="RmTabFromDateControl"
                                                [matDatepicker]="RmTabFromDate"
                                                readonly
                                                (click)="RmTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="RmTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #RmTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="25"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Date:
                                        </span>
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'readonly': RmTabgetData!=null}">
                                            <input matInput
                                                [formControl]="RmTabToDateControl"
                                                [matDatepicker]="RmTabToDate"
                                                readonly
                                                (click)="RmTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="RmTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #RmTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="25"
                                        style="margin: 10px;"
                                        *ngIf="dropDownData!=null">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Tax Year:
                                        </span>
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{'readonly': RmTabgetData!=null}">
                                            <mat-select id="clientYear"
                                                [formControl]="RmTabTaxYearControl">
                                                <mat-option *ngFor="let option of dropDownData.AssessmentYears"
                                                    [value]="option.key">
                                                    {{option.value}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="column"
                                    *ngIf="RmTabgetData != null">
                                    <div fxLayout="column"
                                        class="fuse-card auto-width mb-16 mt-16">
                                        <div fxLayout="row"
                                            fxLayoutAlign="center space-between">
                                            <span style="margin: 10px;">
                                                E-TIN: <b>{{RmTabClientEtin}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                Name: <b>{{RmTabClientName}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                Mobile No: <b>{{RmTabClientMobile}}</b>
                                            </span>
                                        </div>
                                        <div fxLayout="row"
                                            fxLayoutAlign="center space-between">
                                            <span style="margin: 10px;">
                                                Email: <b>{{RmTabClientEmail}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                Reference: <b>{{RmTabClientRef}}</b>
                                            </span>
                                        </div>
                                    </div>

                                    <div fxLayout="column"
                                        class="fuse-card auto-width mb-16 mt-16">
                                        <table class="simple invoice-table"
                                            [formGroup]="RmTabMainForm">
                                            <thead>
                                                <tr>
                                                    <th class="thNewline"
                                                        style="color: #444444;">
                                                        SL
                                                    </th>

                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        Charge AS
                                                    </th>
                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        DUES
                                                    </th>
                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        REMISSION
                                                    </th>
                                                    <th class="text-right thNewline"
                                                        style="color: #444444;">
                                                        NOTES
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="items"
                                                *ngFor="let item of RmTabMainFormitems().controls; let i=index">
                                                <tr [formGroupName]="i">
                                                    <td>
                                                        {{i+1}}
                                                    </td>
                                                    <td>

                                                        <input type="text"
                                                            formControlName="AccounHeadtName"
                                                            readonly
                                                            style="border:0; text-align: center;">
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="outline"
                                                            class="readonly">
                                                            <input type="text"
                                                                formControlName="Dues"
                                                                decimals="100"
                                                                negative="1"
                                                                tabindex="-1"
                                                                (click)="$event.target.select()"
                                                                class="rightA"
                                                                autocomplete="off"
                                                                matInput>
                                                        </mat-form-field>
                                                    </td>

                                                    <td>
                                                        <mat-form-field appearance="outline">
                                                            <input NumbersOnly
                                                                type="text"
                                                                decimals="100"
                                                                negative="1"
                                                                formControlName="Remission"
                                                                (click)="$event.target.select()"
                                                                (keyup)="RmTabOnFocusoutEvent($event,'Remission',i)"
                                                                class="rightA"
                                                                autocomplete="off"
                                                                matInput>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="outline">
                                                            <input type="text"
                                                                decimals="100"
                                                                negative="1"
                                                                formControlName="Notes"
                                                                (click)="$event.target.select()"
                                                                class="rightA"
                                                                autocomplete="off"
                                                                matInput>
                                                        </mat-form-field>
                                                    </td>

                                                </tr>
                                            </tbody>

                                        </table>

                                        <div fxLayout="row"
                                            fxLayoutAlign="start space-between"
                                            style="padding: 10px;">
                                            <span style="margin: 10px;">
                                                <b> Total Dues: {{RmTabTotalDues}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                <b> Total Bill: {{RmTabTotalBill}}</b>
                                            </span>
                                            <span style="margin: 10px;">
                                                <b> Total Remission: {{RmTabTotalRemission}}</b>
                                            </span>

                                        </div>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <div fxLayout="column"
                                            fxLayoutAlign="start"
                                            fxFlex="33"
                                            style="margin: 10px;">
                                            <span class="name"
                                                style="font-size: 15px;"
                                                fxFlex="100">Date:
                                            </span>
                                            <mat-form-field appearance="outline">
                                                <input matInput
                                                    [formControl]="RmTabDateControl"
                                                    [matDatepicker]="RmTabTranDate"
                                                    readonly
                                                    (click)="RmTabTranDate.open()">
                                                <mat-datepicker-toggle matSuffix
                                                    [for]="RmTabTranDate"></mat-datepicker-toggle>
                                                <mat-datepicker #RmTabTranDate
                                                    disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column"
                                            fxLayoutAlign="start"
                                            fxFlex="33"
                                            style="margin: 10px;">
                                            <span class="name"
                                                style="font-size: 15px;"
                                                fxFlex="100">Remarks:
                                            </span>
                                            <mat-form-field appearance="outline">
                                                <input class="leftA"
                                                    [formControl]="RmTabRemarksControl"
                                                    value=""
                                                    matInput>

                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="cancelRM()"
                                            style="color: #444444 !important; margin: 10px;"
                                            class="mat-button mat-button-base primary-100 secondary-text">
                                            CANCEL
                                        </button>
                                        <button mat-raised-button
                                            (click)="SaveRM()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            {{ 'COMMON.BUTTON_SAVE' | translate }}
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </mat-tab> -->

                    <!-- <mat-tab label="Bill Register">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="BrTabSearchControl"
                                                [matAutocomplete]="BrTabAuto">
                                            <mat-autocomplete #BrTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of BrTabfilteredOptions"
                                                    (onSelectionChange)="BrTabSearchClient(option)"
                                                    [value]="option.AssesseName">
                                                    {{option.AssesseName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="BrTabFromDateControl"
                                                [matDatepicker]="BrTabFromDate"
                                                readonly
                                                (click)="BrTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="BrTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #BrTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="BrTabToDateControl"
                                                [matDatepicker]="BrTabToDate"
                                                readonly
                                                (click)="BrTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="BrTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #BrTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between center"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Amount:
                                        </span>

                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="BrTabFromAmountControl"
                                                (click)="$event.target.select()"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Amount:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="BrTabToAmountControl"
                                                (click)="$event.target.select()"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="center center"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px; display: none;"
                                            fxFlex="50">
                                        </span>
                                        <button mat-raised-button
                                            (click)="SearchRegister()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            SEARCH
                                        </button>
                                    </div>
                                </div>
                                <div fxLayout="column"
                                    *ngIf="BrTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">
                                    <table class="simple invoice-table"
                                        *ngIf="BrTabgetData.length>0">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>

                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TAX YEAR
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    DATE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    NAME
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    MOBILE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ACCOUNT
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    DUES
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of BrTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.AssessmentYearId}}
                                                </td>
                                                <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td>
                                                <td>
                                                    ID
                                                </td>
                                                <td>
                                                    {{item.AssesseName}}
                                                </td>
                                                <td>
                                                    {{item.ContactNo}}
                                                </td>
                                                <td>
                                                    {{item.AccounHeadtName}}
                                                </td>
                                                <td>
                                                    {{item.Remarks}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.TotalDues}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start space-between"
                                        style="padding: 10px;">
                                        <span style="margin: 10px;">
                                            <b> Total Records: {{BrTabgetData.length}}</b>
                                        </span>
                                        <span style="margin: 10px;">
                                            <b> Total Dues: {{BrTabTotalDues}}</b>
                                        </span>

                                    </div>
                                    <div *ngIf="BrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="BrDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab> -->

                    <!-- <mat-tab label="Collection Register">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="CrTabSearchControl"
                                                [matAutocomplete]="CrTabAuto">
                                            <mat-autocomplete #CrTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of CrTabfilteredOptions"
                                                    (onSelectionChange)="CrTabSearchClient(option)"
                                                    [value]="option.AssesseName">
                                                    {{option.AssesseName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="CrTabFromDateControl"
                                                [matDatepicker]="CrTabFromDate"
                                                readonly
                                                (click)="CrTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="CrTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #CrTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="CrTabToDateControl"
                                                [matDatepicker]="CrTabToDate"
                                                readonly
                                                (click)="CrTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="CrTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #CrTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between center"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From Amount:
                                        </span>

                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="CrTabFromAmountControl"
                                                (click)="$event.target.select()"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To Amount:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input NumbersOnly
                                                type="text"
                                                decimals="100"
                                                negative="1"
                                                [formControl]="CrTabToAmountControl"
                                                (click)="$event.target.select()"
                                                class="rightA"
                                                autocomplete="off"
                                                matInput>

                                        </mat-form-field>
                                    </div>

                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;"
                                        *ngIf="dropDownData!=null">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Payment Type:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <mat-select [formControl]="CrTabPayTypeControl">
                                                <mat-option *ngFor="let option of dropDownData.PractitionerPaymentTypes"
                                                    [value]="option.key">
                                                    {{option.value}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="column"
                                    fxLayoutAlign="center center"
                                    fxFlex="33"
                                    style="margin: 10px;">
                                    <button mat-raised-button
                                        (click)="SearchCollection()"
                                        style="margin: 10px;"
                                        class="mat-button mat-button-base dupl px-16">
                                        SEARCH
                                    </button>
                                </div>
                                <div fxLayout="column"
                                    *ngIf="CrTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">
                                    <table class="simple invoice-table"
                                        *ngIf="CrTabgetData.length>0">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>

                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TAX YEAR
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    DATE
                                                </th>
                                                <th class="text-right"
                                                    style="color: #444444;">
                                                    RECEIPT NO.
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    NAME
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    MOBILE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    PAYMENT TYPE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    ACCOUNT
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    AMOUNT
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of CrTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.AssessmentYearId}}
                                                </td>
                                                <td>
                                                    {{item.TransactionMasterId}}
                                                </td>
                                                <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td>
                                                <td>
                                                    {{item.ReceiptNo}}
                                                </td>
                                                <td>
                                                    ID
                                                </td>
                                                <td>
                                                    {{item.AssesseName}}
                                                </td>
                                                <td>
                                                    {{item.ContactNo}}
                                                </td>
                                                <td>
                                                    {{item.PaymentType}}
                                                </td>
                                                <td>
                                                    {{item.AccounHeadtName}}
                                                </td>
                                                <td>
                                                    {{item.Remarks}}
                                                </td>
                                                <td class="text-right thNewline">
                                                    {{item.TotalDues}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start space-between"
                                        style="padding: 10px;">
                                        <span style="margin: 10px;">
                                            <b> Total Records: {{CrTabgetData.length}}</b>
                                        </span>
                                        <span style="margin: 10px;">
                                            <b> Total Dues: {{CrTabTotalDues}}</b>
                                        </span>

                                    </div>
                                    <div *ngIf="CrTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="CrDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab> -->

                    <!-- <mat-tab label="Ledger">
                        <div class="tab-content"
                            fxFlex="100">
                            <div fxLayout="column">
                                <div fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    fxLayout.gt-md="row">
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">Search By Id or Name:
                                        </span>

                                        <mat-form-field class="example-full-width"
                                            appearance="outline">
                                            <input type="text"
                                                tabindex="-1"
                                                matInput
                                                [formControl]="LgTabSearchControl"
                                                [matAutocomplete]="LgTabAuto">
                                            <mat-autocomplete #LgTabAuto="matAutocomplete">
                                                <mat-option *ngFor="let option of LgTabfilteredOptions"
                                                    (onSelectionChange)="LgTabSearchClient(option)"
                                                    [value]="option.AssesseName">
                                                    {{option.AssesseName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">From:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="LgTabFromDateControl"
                                                [matDatepicker]="LgTabFromDate"
                                                readonly
                                                (click)="LgTabFromDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="LgTabFromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #LgTabFromDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column"
                                        fxLayoutAlign="start"
                                        fxFlex="33"
                                        style="margin: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="100">To:
                                        </span>
                                        <mat-form-field appearance="outline">
                                            <input matInput
                                                [formControl]="LgTabToDateControl"
                                                [matDatepicker]="LgTabToDate"
                                                readonly
                                                (click)="LgTabToDate.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="LgTabToDate"></mat-datepicker-toggle>
                                            <mat-datepicker #LgTabToDate
                                                disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="column"
                                    fxLayoutAlign="center center"
                                    fxFlex="33"
                                    style="margin: 10px;">
                                    <button mat-raised-button
                                        (click)="SearchLedger('filtered')"
                                        style="margin: 10px;"
                                        class="mat-button mat-button-base dupl px-16">
                                        SEARCH
                                    </button>
                                </div>
                                <div fxLayout="column"
                                    *ngIf="LgTabgetData!=null"
                                    class="fuse-card auto-width mb-16 mt-16">
                                  
                                    <table class="simple invoice-table"
                                        *ngIf="LgTabgetData.length>0">
                                        <thead>
                                            <tr>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    SL
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN DATE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN TYPE
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    TRAN ID
                                                </th>
                                                <th class="thNewline"
                                                    style="color: #444444;">
                                                    REMARKS
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    DEBIT
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    CREDIT
                                                </th>
                                                <th class="text-right thNewline"
                                                    style="color: #444444;">
                                                    BALANCE
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of LgTabgetData; let i=index">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{moment(item.TransactionDate).format('DD-MM-YYYY')}}
                                                </td>
                                                <td>
                                                    {{item.TranType}}
                                                </td>
                                                <td>
                                                    {{item.TransactionId}}
                                                </td>
                                                <td>
                                                    {{item.Remarks}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.Debit}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.Credit}}
                                                </td>
                                                <td class="text-right">
                                                    {{item.Balance}}
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>
                               
                                    <div *ngIf="LgTabgetData.length>0"
                                        fxLayout="row"
                                        fxLayoutAlign="center center">
                                        <button mat-raised-button
                                            (click)="LgDownloadExcel()"
                                            style="margin: 10px;"
                                            class="mat-button mat-button-base dupl px-16">
                                            Download Excel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab> -->

                </mat-tab-group>
            </div>

        </div>
    </div>
</div>
