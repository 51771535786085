<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">

    <div class="center">

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                {{ 'CLIENT.HEADER' | translate }}
            </span>

        </div>
        <div class="content p-24">

            <div class="mb-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row"
                fusePerfectScrollbar>

                <!-- <form class="page-layout simple fullwidth"
                    fxLayout="column wrap"
                    [formGroup]="form">

                    <div fxLayout="column"
                        fxLayoutAlign="start">

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'CLIENT.ETIN' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'CLIENT.ETIN' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    NumbersOnly
                                    maxlength="12"
                                    minlength="12"
                                    formControlName="ETIN"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'CLIENT.AssesseeName' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'CLIENT.AssesseeName' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    formControlName="AssesseeName"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'CLIENT.Mobile' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'CLIENT.Mobile' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    NumbersOnly
                                    formControlName="MobileNo"
                                    maxlength="11"
                                    minlength="11"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            fxFlex="1 0 auto"
                            style="margin-top: 10px;margin-bottom: 10px;">

                            <span class="name"
                                style="font-size: 15px;"
                                fxFlex="50"><span style="color: red;font-weight: bold;">* </span>
                                {{ 'CLIENT.Email' | translate }}
                                <mat-icon style="margin-top: 5px;margin-left: 5px"
                                    matTooltip="{{ 'CLIENT.Email' | translate }}"
                                    matSuffix>info
                                </mat-icon>
                            </span>

                            <mat-form-field appearance="outline"
                                fxFlex="50">
                                <input class="leftA"
                                    type="email"
                                    formControlName="Email"
                                    [email]="true"
                                    required
                                    matInput>
                                <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                    translate }}</mat-error>
                            </mat-form-field>

                        </div>

                    </div>

                </form> -->

                <form class="page-layout simple fullwidth"
                    fxLayout="column wrap"
                    [formGroup]="form"
                    style="overflow-x: scroll;">
                    <div fxLayout="column"
                        fxLayoutAlign="start"
                        class="form-row">
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            fxFlex="1 0 auto">

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>ETIN <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Electronic Taxpayer's Identification Number. It contains 12 digits."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input NumbersOnly
                                        required
                                        maxlength="12"
                                        type="text"
                                        decimals="100"
                                        negative="1"
                                        tabindex=""
                                        formControlName="ETIN"
                                        class="rightA"
                                        autocomplete="off"
                                        matInput>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>
                                    Tax Zone <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer should submit his/her return to a certain circle under a tax zone. You can find your Tax Zone in your ETIN Certificate."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <!-- <mat-form-field appearance="outline">
                                    <input type="text"
                                        tabindex=""
                                        formControlName="ZoneId"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                </mat-form-field> -->
                                <mat-form-field appearance="outline"
                                    fxFlex="100"
                                    *ngIf="dropDownData != null">
                                    <mat-select formControlName="ZoneId"
                                        required>
                                        <mat-option *ngFor="let option of dropDownData.TaxZones"
                                            [value]="option.key">
                                            {{option.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    Father's Name <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's father's name."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input type="text"
                                        tabindex=""
                                        formControlName="FatherName"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            fxFlex="1 0 auto">

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>Name <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's full name as per e-tin certificate."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input type="text"
                                        required
                                        tabindex=""
                                        formControlName="AssesseeName"
                                        class="left"
                                        autocomplete="off"
                                        matInput>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>
                                    Tax Circle <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer should submit his/her return to a certain circle under a tax zone. You can find your Tax Zone in your ETIN Certificate."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input type="text"
                                        required
                                        tabindex=""
                                        formControlName="Circle"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    Mother's Name <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's mother's name."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input type="text"
                                        tabindex=""
                                        formControlName="MotherName"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            fxFlex="1 0 auto">

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>Mobile No. <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's contact information"
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input NumbersOnly
                                        required
                                        maxlength="11"
                                        type="text"
                                        decimals="100"
                                        negative="1"
                                        tabindex=""
                                        formControlName="ContactNo"
                                        class="rightA"
                                        autocomplete="off"
                                        matInput>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>
                                    Area of Resident <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's residential area."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline"
                                    fxFlex="100">
                                    <mat-select formControlName="ResidentialAreaId"
                                        required>
                                        <mat-option [value]="1">
                                            Dhaka & Chittagong City Corporation
                                        </mat-option>
                                        <mat-option [value]="2">
                                            Other City Corporation
                                        </mat-option>
                                        <mat-option [value]="3">
                                            Other than the city corporation area
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>
                                    Marital Status <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's marital status."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline"
                                    fxFlex="100">
                                    <mat-select formControlName="MaritalStatus"
                                        required
                                        (selectionChange)="onSelectMaritalStatus($event)">
                                        <mat-option [value]="1">
                                            Single
                                        </mat-option>
                                        <mat-option [value]="2">
                                            Married
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            fxFlex="1 0 auto">

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>Email <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's email address."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input type="email"
                                        required
                                        tabindex=""
                                        formControlName="Email"
                                        [email]="true"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>
                                    Gender <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's gender. For male taxpayer, tax exemption limit is Tk 3,00,000 and for female, exemption limit is Tk 3,50,000.."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline"
                                    fxFlex="100">
                                    <mat-select formControlName="Gender"
                                        required>
                                        <mat-option [value]="1">
                                            Male
                                        </mat-option>
                                        <mat-option [value]="2">
                                            Female
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span *ngIf="isMarried"
                                        style="color: red;font-weight: bold;">*
                                    </span>
                                    Spouse's Name <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's spouse's name"
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline"
                                    id="SpouseName">
                                    <input type="text"
                                        readonly={{isSingle}}
                                        required={{isMarried}}
                                        tabindex=""
                                        formControlName="SpouseName"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            fxFlex="1 0 auto">

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>Reference <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's Reference."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <input type="text"
                                        required
                                        tabindex=""
                                        formControlName="Reference"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>
                                    Date Of Birth <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's Date Of Birth."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline"
                                    fxFlex=100>

                                    <input matInput
                                        [matDatepicker]="dp3"
                                        formControlName="DateOfBirth"
                                        readonly
                                        required
                                        [max]="maxDate"
                                        (click)="dp3.open()">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="dp3"></mat-datepicker-toggle>
                                    <mat-datepicker #dp3
                                        disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="33">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span *ngIf="isMarried"
                                        style="color: red;font-weight: bold;">*
                                    </span>
                                    Spouse's E-TIN <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's spouse's E-TIN"
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline"
                                    id="SpouseTIN">
                                    <input NumbersOnly
                                        maxlength="12"
                                        type="text"
                                        decimals="100"
                                        negative="1"
                                        tabindex=""
                                        readonly={{isSingle}}
                                        required={{isMarried}}
                                        formControlName="SpouseTIN"
                                        class="rightA"
                                        autocomplete="off"
                                        matInput>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            fxFlex="1 0 auto">

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="50"
                                style="margin-right: 12px !important;">
                                <span class="name"
                                    style="font-size: 15px;color: #444444;">
                                    <span style="color: red;font-weight: bold;">*
                                    </span>Present Address <mat-icon style="margin-top: 5px;margin-left: 5px"
                                        matTooltip="Taxpayer's Present Address."
                                        matSuffix>info
                                    </mat-icon>
                                </span>
                                <mat-form-field appearance="outline">
                                    <textarea rows="7"
                                        type="text"
                                        required
                                        tabindex=""
                                        formControlName="PresentAddress"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                    </textarea>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="50">
                                <div fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="100"
                                    style="margin-bottom: 5px;">
                                    <span class="name"
                                        style="font-size: 15px;color: #444444;">
                                        <span style="color: red;font-weight: bold;">*
                                        </span>Present Postal Code. <mat-icon style="margin-top: 5px;margin-left: 5px"
                                            matTooltip="Taxpayer's Present Postal Code"
                                            matSuffix>info
                                        </mat-icon>
                                    </span>
                                    <mat-form-field appearance="outline">
                                        <input required
                                            type="text"
                                            decimals="100"
                                            negative="1"
                                            tabindex=""
                                            formControlName="PresentPostCode"
                                            class="leftA"
                                            autocomplete="off"
                                            matInput>
                                        <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                            translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="100"
                                    style="margin-top: 5px;">
                                    <span class="name"
                                        style="font-size: 15px;color: #444444;">
                                        <span style="color: red;font-weight: bold;">*
                                        </span>
                                        Present District<mat-icon style="margin-top: 5px;margin-left: 5px"
                                            matTooltip="Taxpayer's Present District."
                                            matSuffix>info
                                        </mat-icon>
                                    </span>
                                    <mat-form-field appearance="outline"
                                        fxFlex="100"
                                        *ngIf="dropDownData != null">
                                        <mat-select formControlName="PresentDistrictId"
                                            required>
                                            <mat-option *ngFor="let option of dropDownData.DistrictListEN"
                                                [value]="option.key">
                                                {{option.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            fxFlex="1 0 auto">

                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="50"
                                style="margin-right: 12px !important;">
                                <div fxLayout="row"
                                    fxLayoutAlign="start center"
                                    fxFlex="1 0 auto">
                                    <span class="name"
                                        style="font-size: 15px;color: #444444;">
                                        <span style="color: red;font-weight: bold;">*
                                        </span>Permanent Address <mat-icon style="margin-top: 5px;margin-left: 5px"
                                            matTooltip="Taxpayer's Permanent Address."
                                            matSuffix>info
                                        </mat-icon>
                                    </span>
                                    <span class="name"
                                        style="font-size: 15px;color: #444444;">
                                        <mat-checkbox style="margin-left: 5px; margin-right: 5px;"
                                            [formControl]="SameAsPresentAddress"
                                            (change)="showOptions($event)"></mat-checkbox>
                                        Same as Present Address
                                    </span>
                                </div>
                                <mat-form-field appearance="outline"
                                    id="PermanentAddress">
                                    <textarea rows="7"
                                        type="text"
                                        required
                                        readonly={{sameAddress}}
                                        tabindex=""
                                        formControlName="PermanentAddress"
                                        class="leftA"
                                        autocomplete="off"
                                        matInput>
                                    </textarea>
                                    <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                        translate }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start"
                                fxFlex="50">
                                <div fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="100"
                                    style="margin-bottom: 5px;">
                                    <span class="name"
                                        style="font-size: 15px;color: #444444;">
                                        <span style="color: red;font-weight: bold;">*
                                        </span>Permanent Postal Code. <mat-icon style="margin-top: 5px;margin-left: 5px"
                                            matTooltip="Taxpayer's Permanent Postal Code"
                                            matSuffix>info
                                        </mat-icon>
                                    </span>
                                    <mat-form-field appearance="outline"
                                        id="PermanentPostCode">
                                        <input required
                                            type="text"
                                            decimals="100"
                                            negative="1"
                                            tabindex=""
                                            readonly={{sameAddress}}
                                            formControlName="PermanentPostCode"
                                            class="leftA"
                                            autocomplete="off"
                                            matInput>
                                        <mat-error> {{ 'COMMON.BUTTON_REQ' |
                                            translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column"
                                    fxLayoutAlign="start"
                                    fxFlex="100"
                                    style="margin-top: 5px;">
                                    <span class="name"
                                        style="font-size: 15px;color: #444444;">
                                        <span style="color: red;font-weight: bold;">*
                                        </span>
                                        Permanent District<mat-icon style="margin-top: 5px;margin-left: 5px"
                                            matTooltip="Taxpayer's Permanent District."
                                            matSuffix>info
                                        </mat-icon>
                                    </span>
                                    <div fxFlex="100"
                                        id="PermanentDistrictId">
                                        <mat-form-field *ngIf="dropDownData != null"
                                            appearance="outline"
                                            fxFlex="100"
                                            style="width: 100%;">
                                            <mat-select formControlName="PermanentDistrictId"
                                                required
                                                tabindex="sameAddress ? -1 : ''">
                                                <mat-option *ngFor="let option of dropDownData.DistrictListEN"
                                                    [value]="option.key">
                                                    {{option.value}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="1 0 auto"
                            style="padding-top: 10px; padding-bottom: 10px;">
                            <span class="name"
                                style="font-size: 15px;color: #444444;">
                                <span style="color: red;font-weight: bold;">*
                                </span>Select your nationality
                            </span>
                            <mat-radio-group formControlName="Nationality"
                                [ngClass]="{'invalid': nStatus}"
                                required>
                                <mat-radio-button style="margin: 5px; margin-right: 10px;"
                                    [ngClass]="{'invalid': nStatus}"
                                    [value]="1"
                                    (change)="radioChangeN($event)">
                                    Bangladeshi
                                </mat-radio-button>
                                <mat-radio-button style="margin: 5px; margin-right: 10px;"
                                    [ngClass]="{'invalid': nStatus}"
                                    [value]="2"
                                    (change)="radioChangeN($event)">
                                    Non-Bangladeshi
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="1 0 auto"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            id="Stayed1"
                            class="hidden">
                            <span class="name"
                                style="font-size: 15px;color: #444444;">
                                <span style="color: red;font-weight: bold;">*
                                </span>In the income year of 2020-2021, for how long did you stay in Bangladesh?
                            </span>
                            <mat-radio-group formControlName="Stayed1"
                                [ngClass]="{'invalid': rStatus}"
                                required>
                                <mat-radio-button style="margin: 5px; margin-right: 10px;"
                                    [ngClass]="{'invalid': rStatus}"
                                    [value]="1"
                                    (change)="radioChange1($event)">
                                    More Than 180 Days
                                </mat-radio-button>
                                <mat-radio-button style="margin: 5px; margin-right: 10px;"
                                    [ngClass]="{'invalid': rStatus}"
                                    [value]="2"
                                    (change)="radioChange1($event)">
                                    Less Than 180 Days
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div fxLayout="column"
                            fxLayoutAlign="start"
                            fxFlex="1 0 auto"
                            style="padding-top: 10px; padding-bottom: 10px;"
                            id="Stayed2"
                            class="hidden">
                            <span class="name"
                                style="font-size: 15px;color: #444444;">
                                <span style="color: red;font-weight: bold;">*
                                </span>Did you stay in Bangladesh at least 365 days (can be in multiple periods) in the
                                last four income years?
                            </span>
                            <mat-radio-group formControlName="Stayed2"
                                [ngClass]="{'invalid': r2Status}"
                                required>
                                <mat-radio-button style="margin: 5px; margin-right: 10px;"
                                    [ngClass]="{'invalid': r2Status}"
                                    [value]="1"
                                    (change)="radioChange2($event)">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button style="margin: 5px; margin-right: 10px;"
                                    [ngClass]="{'invalid': r2Status}"
                                    [value]="2"
                                    (change)="radioChange2($event)">
                                    No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div style="margin-top: 10px;"
                            class="header"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                            id="ResidentStatus"
                            class="hidden">
                            <div disabled
                                class="mat-button mat-button-base primary-A400 secondary-text">

                            </div>
                            <div class="h3 mb-12"
                                style="color: #444444;">Your Residential Status: <span class="username"
                                    id="resident"
                                    style="font-weight: bold; color: #206030;"></span>
                            </div>
                            <div disabled
                                class="mat-button mat-button-base primary-A400 secondary-text">

                            </div>
                        </div>

                    </div>
                </form>

            </div>
            <div class="header"
                style="padding-bottom: 10px;"
                fxLayoutAlign="space-between center">
                <!-- <div disabled
                    *ngIf="id==null"
                    class="mat-button mat-button-base primary-A400 secondary-text">

                </div> -->

                <button mat-raised-button
                    style="color: #444444 !important;"
                    [routerLink]="'/Clients'"
                    class="mat-button mat-button-base primary-100 secondary-text">
                    {{ 'COMMON.BUTTON_CANCEL' | translate }}
                </button>
                <button mat-raised-button
                    *ngIf="id==null"
                    (click)="OnSubmit()"
                    class="mat-button mat-button-base dupl px-16">
                    {{ 'COMMON.BUTTON_SAVE' | translate }}
                </button>
                <button mat-raised-button
                    *ngIf="id!=null"
                    (click)="OnSubmit()"
                    class="mat-button mat-button-base dupl px-16">
                    {{ 'COMMON.BUTTON_UPDATE' | translate }}
                </button>
            </div>

        </div>
    </div>
</div>
