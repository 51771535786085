import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ClientsListComponent } from './ClientsList.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { FuseCountdownModule, FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ClientsListService } from './ClientsList.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ViewClientsGuard } from 'app/auth/viewClients.guard';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { BulkUploadDocumentDialog } from './bulk-upload-dialog/bulk-Dialog.component';
import { FileDragNDropDirective } from './file-drag-n-drop.directive';
import { SlipUploadDocumentDialog } from './slip-upload-dialog/slip-Dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BillDialog } from './bill-dialog/bill-Dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CollectionDialog } from './collection-dialog/collection-Dialog.component';
import { RemissionDialog } from './remission-dialog/remission-Dialog.component';
import { LedgerDialog } from './ledger-dialog/ledger-Dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClientChalanDialog } from './chalan-dialog/client-chalan-Dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { BulkSalaryUploadDocumentDialog } from './bulk-salary-upload-dialog/bulk-salary-Dialog.component';
import { GeneralSmsDialog } from './general-sms-dialog/general-sms-dialog.component';
import { ClientEmailDialog } from './email-dialog/client-email-Dialog.component';
import { MatListModule } from '@angular/material/list';
import { ClientDocumentDialog } from './document-dialog/client-document-Dialog.component';
import { FileDragNDropDirectiveForExcel } from './file-drag-n-drop-for-excel.directive';

const routes = [
    {
        path: 'Clients',
        component: ClientsListComponent,
        canActivate: [AuthGuard, ViewClientsGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        ClientsListComponent,
        BulkUploadDocumentDialog,
        BulkSalaryUploadDocumentDialog,
        SlipUploadDocumentDialog,
        BillDialog,
        ClientDocumentDialog,
        ClientChalanDialog,
        ClientEmailDialog,
        RemissionDialog,
        LedgerDialog,
        CollectionDialog,
        GeneralSmsDialog,
        FileDragNDropDirective,
        FileDragNDropDirectiveForExcel,
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatCheckboxModule,

        MatFormFieldModule,

        MatRadioModule,

        MatSelectModule,

        MatInputModule,

        MatDialogModule,

        MatListModule,

        MatMenuModule,

        MatButtonModule,

        MatTableModule,

        MatSortModule,

        MatDatepickerModule,

        MatAutocompleteModule,

        FuseCountdownModule,

        FuseSharedModule,

        FuseSidebarModule,

        MatExpansionModule,

        FuseDemoModule,

        QuickPanelModule,

        MatTooltipModule,

        NgCircleProgressModule

    ],
    exports: [
        ClientsListComponent
    ],
    providers: [

        ClientsListService
    ],
})

export class ClientsListModule
{
}


let ff = [
    {
        "Id": 95,
        "PractitionerId": 4,
        "AssesseeId": 2217,
        "ReturnId": 0,
        "ETIN": "123123123123",
        "AssesseeName": "sim kio",
        "MobileNo": "23423423423",
        "Email": "sdsd@rom.com",
        "TaxAmount": null,
        "Percent": "100%",
        "Reference": null,
        "Zone": null,
        "Circle": null,
        "ResidentialAreaId": null,
        "Gender": null,
        "DateOfBirth": null,
        "FatherName": null,
        "MotherName": null,
        "MaritalStatus": null,
        "SpouseName": null,
        "SpouseTIN": null,
        "ReturnHistory": [
            {
                "DocId": null,
                "AssesseeId": 2217,
                "ReturnId": 2206,
                "AcknowledgementSlipName": null,
                "TaxYear": "Year 2022-2023",
                "AmountToBePaid": null,
                "PaidWithReturn": null,
                "UploadedDate": "1900-01-01T00:00:00"
            }
        ]
    },
    {
        "Id": 94,
        "PractitionerId": 4,
        "AssesseeId": 2216,
        "ReturnId": 0,
        "ETIN": "656564546432",
        "AssesseeName": "mobile",
        "MobileNo": "13234234242",
        "Email": "sdads@dfdf.com",
        "TaxAmount": null,
        "Percent": "100%",
        "Reference": null,
        "Zone": null,
        "Circle": null,
        "ResidentialAreaId": null,
        "Gender": null,
        "DateOfBirth": null,
        "FatherName": null,
        "MotherName": null,
        "MaritalStatus": null,
        "SpouseName": null,
        "SpouseTIN": null,
        "ReturnHistory": [
            {
                "DocId": null,
                "AssesseeId": 2216,
                "ReturnId": 2205,
                "AcknowledgementSlipName": null,
                "TaxYear": "Year 2022-2023",
                "AmountToBePaid": null,
                "PaidWithReturn": null,
                "UploadedDate": "1900-01-01T00:00:00"
            }
        ]
    },
    {
        "Id": 77,
        "PractitionerId": 4,
        "AssesseeId": 1931,
        "ReturnId": 0,
        "ETIN": "565464646464",
        "AssesseeName": "asw werf",
        "MobileNo": "24342342424",
        "Email": "asd@ef.com",
        "TaxAmount": null,
        "Percent": "%",
        "Reference": null,
        "Zone": null,
        "Circle": null,
        "ResidentialAreaId": null,
        "Gender": null,
        "DateOfBirth": null,
        "FatherName": null,
        "MotherName": null,
        "MaritalStatus": null,
        "SpouseName": null,
        "SpouseTIN": null,
        "ReturnHistory": [
            {
                "DocId": null,
                "AssesseeId": 1931,
                "ReturnId": 1910,
                "AcknowledgementSlipName": null,
                "TaxYear": "Year 2021-2022",
                "AmountToBePaid": null,
                "PaidWithReturn": null,
                "UploadedDate": "1900-01-01T00:00:00"
            }
        ]
    }
]
