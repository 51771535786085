<h2 mat-dialog-title>Chalan</h2>
<mat-dialog-content class="mat-typography"
    *ngIf="PageResponseData!=null">
    <div fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16">
        <div fxLafxLayout="row wrap"
            fxLayoutAlign="space-between center">

            <h3 style="margin: 10px;">
                eTIN: <b>{{PageResponseData.ETIN}}</b>
            </h3>

            <h3 style="margin: 10px;">
                Tax Zone: <b>{{PageResponseData.Zone}}</b>
            </h3>
        </div>

        <div fxLafxLayout="row wrap"
            fxLayoutAlign="space-between center">
            <h3 style="margin: 10px;">
                Name: <b>{{PageResponseData.AssesseeName}}</b>
            </h3>
            <h3 style="margin: 10px;">
                Circle: <b>{{PageResponseData.Circle}}</b>
            </h3>
        </div>
        <div fxLafxLayout="row wrap"
            fxLayoutAlign="start center">
            <h3 style="margin: 10px;">
                Tax Year: <b>{{PageResponseData.TaxYear}}</b>
            </h3>
        </div>

    </div>
    <div fxLayout="column"
        class="fuse-card auto-width mb-16 mt-16"
        style="margin-top: 10px">
        <div fxLayout="row wrap"
            fxLayoutAlign="space-between"
            style="margin-bottom: 10px">
            <div fxLayout="row wrap"
                fxLayoutAlign="start center"
                style="margin: 5px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Tax Amount:
                </span>

                <mat-form-field appearance="outline"
                    >
                    <input class="rightA"
                        readonly
                        [formControl]="TaxAmountControl"
                        matInput>

                </mat-form-field>

            </div>
            <div fxLayout="row wrap"
                fxLayoutAlign="start center"
                style="margin: 5px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Chalan Amount:
                </span>

                <mat-form-field appearance="outline">
                    <input class="rightA"
                        NumbersOnly
                        [formControl]="ChalanAmountControl"
                        matInput>

                </mat-form-field>

            </div>
        </div>
        <div fxLayout="row wrap"
            fxLayoutAlign="start"
            style="margin-bottom: 10px">
            <div fxLayout="row wrap"
                fxLayoutAlign="start center"
                style="margin: 5px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Payment Mode:
                </span>

                <mat-radio-group fxLayout="row"
                    [formControl]="PaymentModeControl">
                    <mat-radio-button style="margin-right: 10px;"
                        *ngFor="let option of DropdownData.PractitionerPaymentTypes"
                        [value]="option.key"
                        (change)="radioChangeN($event)">
                        {{option.value}}
                    </mat-radio-button>
                </mat-radio-group>

            </div>
        </div>
        <div fxLayout="row wrap"
            fxLayoutAlign="start start"
            id="BankN"
            style="margin-bottom: 10px"
            class="hidden">
            <div fxLayout="column"
                fxLayoutAlign="start"
                style="margin: 10px;">
                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Cheque/ P.O. No:
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <input class="leftA"
                        [formControl]="ChequePOControl"
                        matInput>

                </mat-form-field>
            </div>
            <div fxLayout="column"
                fxLayoutAlign="start"
                style="margin: 10px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Bank:
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <mat-select [formControl]="BankNamesControl">
                        <mat-option *ngFor="let option of DropdownData.Banks"
                            [value]="option">
                            {{option.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div fxLayout="column"
                fxLayoutAlign="start"
                style="margin: 10px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Branch Name:
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <input class="leftA"
                        [formControl]="BranchNamesControl"
                        matInput>

                </mat-form-field>

            </div>
        </div>
        <div fxLayout="row wrap"
            fxLayoutAlign="start start"
            id="TransactionN"
            style="margin-bottom: 10px"
            class="hidden">
            <div fxLayout="column"
                fxLayoutAlign="start"
                style="margin: 10px;">
                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Transaction No:
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <input class="leftA"
                        [formControl]="TransactionNoControl"
                        matInput>

                </mat-form-field>
            </div>

            <div fxLayout="column"
                fxLayoutAlign="start"
                style="margin: 10px;">

                <span class="name"
                    style="font-size: 15px; margin-left: 10px; margin-right: 20px;">
                    Remarks:
                </span>

                <mat-form-field appearance="outline"
                    fxFlex="100">
                    <input class="leftA"
                        [formControl]="TransactionRemarksControl"
                        matInput>

                </mat-form-field>

            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end"
    *ngIf="PageResponseData!=null">
    <button class="mat-button mat-button-base primary-100 secondary-text"
        cdkFocusInitial
        mat-dialog-close>Cancel</button>
    <button class="mat-button mat-button-base dupl"
    (click)="DownloadChalan()">
        Download
    </button>
</mat-dialog-actions>
<!-- [mat-dialog-close]="true" -->
