import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
// import * as jspdf from 'jspdf';
// import html2canvas from 'html2canvas';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { bangla, english } from 'app/util/locale';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

import { TaxReturnService } from './tax-return.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { BackButtonService } from 'app/services/BackButtonService';

@Component({
  templateUrl: './tax-return.component.html',
  styleUrls: ['./tax-return.component.scss'],
})

export class TaxReturnComponent
{
  name = 'Angular Html To Pdf ';
  getData: any;
  genderM: any;
  genderF: any;
  ResidentStatusYes: any;
  ResidentStatusNo: any;
  WarWoundedFreedomFighter: any;
  DisablePerson: any;
  HasDisableChild: any;
  lang = '';

  SpouseExemptedDisableChildYes: any;
  SpouseExemptedDisableChildNo: any;

  RequiredIT10B2016Yes: any;
  RequiredIT10B2016No: any;

  ScheduleAnnexed24A: any;
  ScheduleAnnexed24B: any;
  ScheduleAnnexed24C: any;
  ScheduleAnnexed24D: any;
  StatementAnnexedIT10B2016: any;
  StatementAnnexedIT10BB2016: any;
  etinSplit: any;
  etinSplit0: any;
  etinSplit1: any;
  etinSplit2: any;
  etinSplit3: any;
  etinSplit4: any;
  etinSplit5: any;
  etinSplit6: any;
  etinSplit7: any;
  etinSplit8: any;
  etinSplit9: any;
  etinSplit10: any;
  etinSplit11: any;
  DateOfBirthStr: any;





  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _formControlService: TaxReturnService,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _matSnackBar: MatSnackBar,
    private http: HttpClient,
    private back: BackButtonService,
  )
  {
  }

  // public downloadAsPDF()
  // {
  //   const doc = new jspdf();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer)
  //     {
  //       return true;
  //     }
  //   };

  //   const pdfTable = this.pdfTable.nativeElement;

  //   doc.fromHTML(pdfTable.innerHTML, 15, 15, {
  //     width: 190,
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save('tableToPdf.pdf');
  // }

  // public captureScreen()  
  // {
  //   var data = document.getElementById('pdfTable');
  //   html2canvas(data).then(canvas =>
  //   {
  //     // Few necessary setting options  
  //     var imgWidth = 208;
  //     var pageHeight = 295;
  //     var imgHeight = canvas.height;
  //     var heightLeft = imgHeight;



  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf("p", "mm", "a4");
  //     var width = pdf.internal.pageSize.getWidth();
  //     var height = pdf.internal.pageSize.getHeight();
  //     var position = 0;

  //     var y = 500 // Height position of new content
  //     if (y >= height)
  //     {
  //       pdf.addPage();
  //       y = 0 // Restart height position
  //     }
  //     pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height)

  //     // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //     pdf.save('MYPdf.pdf'); // Generated PDF   
  //   });
  // }

  downloadPDF(): any
  {
    const authToken = localStorage.getItem('userToken');
    return this.http.get(Config.REPORT_DL, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }), responseType: 'blob'
    }).subscribe(
      res =>
      {

        const fileURL = URL.createObjectURL(res);


        window.open(fileURL, '_blank');
      }
    )
  }

  summaryPDF(): any
  {
    const authToken = localStorage.getItem('userToken');
    return this.http.get(Config.SUMMARY_DL, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }), responseType: 'blob'
    }).subscribe(
      res =>
      {

        const fileURL = URL.createObjectURL(res);


        window.open(fileURL, '_blank');
      }
    )
  }

  ngOnInit(): void
  {

    // if (this.getData.length == 0)
    // {
    //     this._router.navigate(['/Agriculture']);
    // }

    this._spinner.show();
    this._fuseTranslationLoaderService.loadTranslations(english, bangla);

    this.lang = localStorage.getItem('selectedLanguage');
    let profileData = JSON.parse(localStorage.getItem('profileData'));

    if (profileData.iscomplete == false)
    {
      let incompletefields = null;

      if (profileData.incompletefields[0] != null)
      {
        incompletefields = profileData.incompletefields[0].fields;
      }

      let incompleteprofile = null;
      if (profileData.incompleteprofile[0] != null)
      {
        incompleteprofile = profileData.incompleteprofile[0];
      }

      if (incompleteprofile == "1")
      {
        this._matSnackBar.open(this.lang == 'en' ? english.data.ETIN.R_ETIN_OTHER : bangla.data.ETIN.R_ETIN_OTHER, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
          verticalPosition: 'top',
          duration: 2000
        });
        this._router.navigate(['/EtinOthers/save']);
      }

      if (incompleteprofile == "2")
      {
        this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_ENTER_PERSONAL_INFO : bangla.data.PERSONAL.R_ENTER_PERSONAL_INFO, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
          verticalPosition: 'top',
          duration: 2000
        });
        this._router.navigate(['/PersonalInfo/save']);
      }

      if (incompleteprofile == "3")
      {
        this._matSnackBar.open(this.lang == 'en' ? english.data.PERSONAL.R_PRESENT_ADDRESS : bangla.data.PERSONAL.R_PRESENT_ADDRESS, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
          verticalPosition: 'top',
          duration: 2000
        });
        this._router.navigate(['/PersonalizeReturn']);
      }

    }
    else
    {
      this._formControlService.getControlJson().subscribe((data: any) =>
      {
        if (data != null)
        {
          this._spinner.hide();
        }


        if (data.isError == false && !Utils.isEmptyObject(data.data))
        {

          this.getData = data.data;

          this.getData.Gender == 1 ? this.genderM = "✓" : this.genderM = "";
          this.getData.Gender == 2 ? this.genderF = "✓" : this.genderF = "";

          this.getData.ResidentStatus == 1 || this.getData.ResidentStatus == 2 ? this.ResidentStatusYes = "✓" : this.ResidentStatusYes = "";
          // this.getData.ResidentStatus == 2 ? this.ResidentStatusYes = "✓" : this.ResidentStatusYes = "";
          this.getData.ResidentStatus == 3 ? this.ResidentStatusNo = "✓" : this.ResidentStatusNo = "";

          this.getData.WarWoundedFreedomFighter == true ? this.WarWoundedFreedomFighter = "✓" : this.WarWoundedFreedomFighter = "";

          this.getData.DisablePerson == true ? this.DisablePerson = "✓" : this.DisablePerson = "";

          this.getData.HasDisableChild == true ? this.HasDisableChild = "✓" : this.HasDisableChild = "";


          this.getData.SpouseExemptedDisableChild == true ? this.SpouseExemptedDisableChildYes = "✓" : this.SpouseExemptedDisableChildYes = "";

          this.getData.SpouseExemptedDisableChild == false ? this.SpouseExemptedDisableChildNo = "✓" : this.SpouseExemptedDisableChildNo = "";

          this.getData.RequiredIT10B2016 == true ? this.RequiredIT10B2016Yes = "✓" : this.RequiredIT10B2016Yes = "";

          this.getData.RequiredIT10B2016 == false ? this.RequiredIT10B2016No = "✓" : this.RequiredIT10B2016No = "";

          this.getData.ScheduleAnnexed24A == true ? this.ScheduleAnnexed24A = "✓" : this.ScheduleAnnexed24A = "";

          this.getData.ScheduleAnnexed24B == true ? this.ScheduleAnnexed24B = "✓" : this.ScheduleAnnexed24B = "";

          this.getData.ScheduleAnnexed24C == true ? this.ScheduleAnnexed24C = "✓" : this.ScheduleAnnexed24C = "";

          this.getData.ScheduleAnnexed24D == true ? this.ScheduleAnnexed24D = "✓" : this.ScheduleAnnexed24D = "";

          this.getData.StatementAnnexedIT10B2016 == true ? this.StatementAnnexedIT10B2016 = "✓" : this.StatementAnnexedIT10B2016 = "";

          this.getData.StatementAnnexedIT10BB2016 == true ? this.StatementAnnexedIT10BB2016 = "✓" : this.StatementAnnexedIT10BB2016 = "";
          if (this.getData.ETIN != null)
          {
            this.etinSplit = this.getData.ETIN;
          }
          if (this.getData.DateOfBirthStr != null)
          {
            this.DateOfBirthStr = this.getData.DateOfBirthStr;
          }


        }
      });

      this._formControlService.getAssetFilled().subscribe((data: any) =>
      {

      })


    }
  }

  // tese(): void
  // {
  //   this._spinner.show();
  //   this._fuseTranslationLoaderService.loadTranslations(english, bangla);
  //   this.download();
  //   this._spinner.hide();

  // }

  // download(): void
  // {

  //   var input = document.getElementById('pdfTable');
  //   const HTML_Height = input.clientHeight;

  //   const HTML_Width = input.clientWidth;



  //   var top_left_margin = 15;
  //   var PDF_Width = HTML_Width + (top_left_margin * 2);
  //   var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
  //   var canvas_image_width = HTML_Width;
  //   var canvas_image_height = HTML_Height;

  //   // var totalPDFPages = 4;
  //   var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

  //   this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DL : bangla.data.COMMON.BUTTON_DL, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
  //     verticalPosition: 'top',
  //     duration: 2000
  //   });
  //   html2canvas(input, { allowTaint: true }).then(function (canvas)
  //   {
  //     canvas.getContext('2d');




  //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
  //     pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


  //     for (var i = 1; i <= totalPDFPages; i++)
  //     {
  //       pdf.addPage(PDF_Width, PDF_Height);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
  //     }

  //     pdf.save("IT-11GA2016.pdf");
  //   })
  // }



  @HostListener('window:popstate', ['$event'])
  onPopState(event)
  {
    this.back.setParam(true, 'fuse');
  }
}
