import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Layouts } from 'app/enum/layouts.enum';
import { TermsConditionsComponent } from './terms-and-conditions.component';

const routes = [
    {
        path: 'terms-and-conditions',
        component: TermsConditionsComponent,
        data: { layout: Layouts.Home },
    }
];

@NgModule({
    declarations: [
        TermsConditionsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule
    ],
    exports: [
        TermsConditionsComponent
    ]
})

export class TermsConditionsModule
{
}
