import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';
import { ListDialogComponent } from './list-dialog.component';

@NgModule({
    declarations: [
        ListDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,

    ],
    entryComponents: [
        ListDialogComponent
    ],
})
export class ListDialogModule
{
}
