<h1 matDialogTitle>{{confirmTitle}}</h1>
<div mat-dialog-content
    style="white-space:pre-wrap; word-wrap:break-word;">{{confirmMessage}}</div>
<h1 matDialogTitle
    hidden></h1>
<div mat-dialog-actions
    class="header"
    fxLayoutAlign="end">
    <button mat-button
        (click)="dialogRef.close(false)">{{cancelButton}}</button>
    <button mat-raised-button
        class="mat-accent mr-16"
        (click)="dialogRef.close(true)">{{confirmButton}}</button>

</div>
<h1 matDialogTitle
    hidden></h1>
