import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';

@Injectable()
export class ArchiveService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }



  sendToArchive(model: any): Observable<any>
  {
    let urlConfig = new UrlConfig();
    return this.service.post(urlConfig.ARCHIVE_POST, model);
  }


  getControlJson(currentYear: string): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    return this.service.get(urlConfig.ARCHIVE_PREPARE + `?currentYear=${currentYear}`);
  }


  switchReturn(): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    // console.log(urlConfig.DASHBOARD_GETDATA_URL, 'urlConfig.DASHBOARD_GETDATA_URL');

    return this.service.get(urlConfig.SWITCH_RETURN);
  }


  switchReturnWIthReturnId(ReturnId): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    // console.log(urlConfig.DASHBOARD_GETDATA_URL, 'urlConfig.DASHBOARD_GETDATA_URL');

    return this.service.get(urlConfig.SWITCH_RETURN + `/${ReturnId}`);
  }
}
