import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { MatRadioChange } from '@angular/material/radio';
import * as moment from 'moment';
import { bangla, english } from 'app/util/locale';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'Personal-Information-form-control',
  templateUrl: './Personal-Information-form-control.component.html',
  styleUrls: ['./Personal-Information.component.scss'],

})
export class PersonalInformationControlComponent
{
  @Input() control: BaseControl<string>;
  @Input() form: FormGroup;
  maritalStatus: string;
  gender: string;
  DateOfBirth: any;
  pageLoaded: boolean;
  maxDate = new Date();
  IsCurrentYear: any;

  @Input() rGender: boolean;
  @Input() rMarital: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,


  )
  {
    this._fuseTranslationLoaderService.loadTranslations(english, bangla);

  }

  get isValid()
  {
    if (this.pageLoaded)
    {
      return this.form.controls[this.control.key].valid;
    } else
    {
      return false;
    }

  }


  ngAfterViewInit()
  {
    this.cdr.detectChanges();
  }
  radioChange(event, key)
  {

    if (key == "MaritalStatus")
    {

      let floorElement1: HTMLElement = document.getElementsByClassName("maritalStautus")[0] as HTMLElement;
      let floorElement2: HTMLElement = document.getElementsByClassName("maritalStautus")[1] as HTMLElement;

      if (event.value == "1")
      {
        this.form.patchValue({
          SpouseName: "",
          SpouseTIN: ""
        })
        if (floorElement1.classList.contains('maritalStautus'))
        {
          if (floorElement1.classList.contains('hidden'))
          {
            floorElement1.classList.remove('hidden');

          }
          if (floorElement1.classList.contains('block'))
          {
            floorElement1.classList.remove('block');

          }
          floorElement1.classList.add('hidden');

        }

        if (floorElement2.classList.contains('maritalStautus'))
        {
          if (floorElement2.classList.contains('hidden'))
          {
            floorElement2.classList.remove('hidden');

          }
          if (floorElement2.classList.contains('block'))
          {
            floorElement2.classList.remove('block');

          }
          floorElement2.classList.add('hidden');

        }

      }
      if (event.value == "2")
      {
        // this.form.controls['SpouseName'].setErrors({ 'required': true });
        if (floorElement1.classList.contains('maritalStautus'))
        {
          if (floorElement1.classList.contains('block'))
          {
            floorElement1.classList.remove('block');

          }
          if (floorElement1.classList.contains('hidden'))
          {
            floorElement1.classList.remove('hidden');

          }
          floorElement1.classList.add('block');

        }

        if (floorElement2.classList.contains('maritalStautus'))
        {
          if (floorElement2.classList.contains('block'))
          {
            floorElement2.classList.remove('block');

          }
          if (floorElement2.classList.contains('hidden'))
          {
            floorElement2.classList.remove('hidden');

          }
          floorElement2.classList.add('block');

        }
      }
    }

  }

  showOptions(event)
  {

    let floorElement1: HTMLElement = document.getElementById("PermanentAddress") as HTMLElement;

    let floorElement2: HTMLElement = document.getElementById("PermanentDistrictId") as HTMLElement;
    let floorElement3: HTMLElement = document.getElementById("PermanentPostCode") as HTMLElement;
    if (event.checked == true)
    {
      if (!floorElement1.classList.contains('readonly'))
      {
        floorElement1.classList.add('readonly');
      }
      if (!floorElement2.classList.contains('readonly'))
      {
        floorElement2.classList.add('readonly');
      }
      if (!floorElement3.classList.contains('readonly'))
      {
        floorElement3.classList.add('readonly');
      }
      if (this.form.controls["PresentAddress"].value != "")
      {
        this.form.patchValue({
          PermanentAddress: this.form.controls["PresentAddress"].value
        })
      }
      if (this.form.controls["PresentPostCode"].value != "")
      {
        this.form.patchValue({
          PermanentPostCode: this.form.controls["PresentPostCode"].value
        })
      }
      if (this.form.controls["PresentDistrictId"].value != "")
      {
        this.form.patchValue({
          PermanentDistrictId: this.form.controls["PresentDistrictId"].value
        })
      }
    }
    if (event.checked == false)
    {
      if (floorElement1.classList.contains('readonly'))
      {
        floorElement1.classList.remove('readonly');
      }
      if (floorElement2.classList.contains('readonly'))
      {
        floorElement2.classList.remove('readonly');
      }
      if (floorElement3.classList.contains('readonly'))
      {
        floorElement3.classList.remove('readonly');
      }
      this.form.patchValue({
        PermanentAddress: "",
        PermanentPostCode: "",
        PermanentDistrictId: "",
      })
    }
  }

  ngOnInit(): void
  {
    this.IsCurrentYear = JSON.parse(localStorage.getItem('IsCurrentYear'));

    if (this.IsCurrentYear != null && this.IsCurrentYear.IsCurrentYear == false)
    {
      this.form.disable();
    }

    this.pageLoaded = true;

    let DateOfBirth = this.form.controls["DateOfBirth"].value;

    if (DateOfBirth != null)
    {
      this.form.patchValue({
        DateOfBirth: moment(DateOfBirth, "DD-MM-YYYY")
      });
      this.DateOfBirth = new FormControl(moment(DateOfBirth, "DD-MM-YYYY"));
    }

    let maritalStautus1: HTMLElement = document.getElementsByClassName("maritalStautus")[0] as HTMLElement;
    let maritalStautus2: HTMLElement = document.getElementsByClassName("maritalStautus")[1] as HTMLElement;

    let MaritalStatus = this.form.controls["MaritalStatus"].value;

    if (MaritalStatus != 2 || MaritalStatus == "Single")
    {
      if (maritalStautus1 != null)
      {
        maritalStautus1.classList.add('hidden');
      }
      if (maritalStautus2 != null)
      {
        maritalStautus2.classList.add('hidden');
      }
    }

    let hasPermanentAddress = this.form.controls["hasPermanentAddress"].value;

    let floorElement11: HTMLElement = document.getElementById("PermanentAddress") as HTMLElement;
    let floorElement21: HTMLElement = document.getElementById("PermanentDistrictId") as HTMLElement;
    let floorElement31: HTMLElement = document.getElementById("PermanentPostCode") as HTMLElement;

    if (hasPermanentAddress == "false")
    {
      hasPermanentAddress = false
    }
    if (hasPermanentAddress == "true")
    {
      hasPermanentAddress = false
    }
    if (hasPermanentAddress != null)
    {
      this.form.patchValue({
        hasPermanentAddress: hasPermanentAddress
      });
    }
  }
}
