<div class="page-layout simple right-sidebar inner-scroll"
    style="background-color: #ffffff;">
    <fuse-sidebar *ngIf="usertype=='8'"
        class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>
    <div class="center">

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px;">MY ACCOUNT
            </span>
        </div>
        <div class="content p-24">
            <div class="mb-24"
                fxLayout="column wrap"
                fxLayoutAlign="start"
                fxLayout.gt-md="row">
                <mat-tab-group fxLayout="column"
                    fxFlex>

                    <mat-tab label="Basic Info">
                        <div *ngIf="basicData!=null"
                            class="tab-content"
                            fxFlex="100">

                            <div *ngIf="!showBasicEdit"
                                class="fuse-card auto-width mb-16 mt-16"
                                fxLayout="column"
                                style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">
                                <div class="header p-12"
                                    fxLayoutAlign="space-between center">
                                    <div disabled
                                        class="mat-button mat-button-base primary-A400 secondary-text">

                                    </div>
                                    <button mat-raised-button
                                        (click)="EditBasic()"
                                        class="mat-button mat-button-base primary-A400 dupl">
                                        EDIT
                                    </button>
                                </div>
                                <div class="p-12"
                                    fxLayout="column"
                                    fxLayoutAlign="start">
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;
                                        padding-top: 10px;padding-bottom: 10px;
                                        border-bottom: 1px solid #C5C6CB;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Photo: </span>
                                        <div fxFlex="50">
                                            <img *ngIf="basicData.PhotoUrl!=blobPath"
                                                class="avatar contact-avatar huge m-0"
                                                src={{basicData.PhotoUrl}}>

                                            <img *ngIf="basicData.PhotoUrl==''"
                                                class="avatar contact-avatar huge m-0"
                                                src="assets/images/avatars/profile.jpg">
                                            <img *ngIf="basicData.PhotoUrl==blobPath"
                                                class="avatar contact-avatar huge m-0"
                                                src="assets/images/avatars/profile.jpg">
                                        </div>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;
                                    padding-top: 10px;padding-bottom: 10px;
                                    border-bottom: 1px solid #C5C6CB;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Signature: </span>
                                        <div fxFlex="50">
                                            <img *ngIf="basicData.SignatureUrl!=sigblobPath"
                                                class="avatar contact-avatar huge m-0"
                                                src={{basicData.SignatureUrl}}>

                                            <img *ngIf="basicData.SignatureUrl==''"
                                                class="avatar contact-avatar huge m-0"
                                                src="assets/images/avatars/placeholder.png">
                                            <img *ngIf="basicData.SignatureUrl==sigblobPath"
                                                class="huge m-0" style="height: 50px !important;"
                                                src="assets/images/avatars/placeholder.png">
                                        </div>
                                    </div>

                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;
                                        padding-top: 10px;padding-bottom: 10px;
                                        border-bottom: 1px solid #C5C6CB;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Name: </span>
                                        <span *ngIf="basicData.Name==''"
                                            class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50"></span>
                                        <span *ngIf="basicData.Name!=''"
                                            class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">{{basicData.Name}}</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showBasicEdit"
                                class="fuse-card auto-width mb-16 mt-16"
                                fxLayout="column"
                                style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                                <div class="p-12"
                                    fxLayout="column"
                                    fxLayoutAlign="start">
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Photo: </span>
                                        <div fxFlex="50"
                                            fxLayout="column-reverse">

                                            <input type='file'
                                                id="imageUpload"
                                                accept=".png, .jpg, .jpeg"
                                                #fileInput
                                                (change)="uploadFile($event)"
                                                style="color: transparent;" />

                                            <div fxLayout="row"
                                                fxLayoutAlign="start">
                                                <img class="avatar contact-avatar huge m-0"
                                                    src={{imageUrl}}
                                                    style="margin-top: 10px !important;margin-bottom: 10px !important;margin-left: 20px !important; ">
                                                <a *ngIf="imageUrl !== 'assets/images/avatars/profile.jpg'"
                                                    style="cursor: pointer; border: 0; color: #29793C;"
                                                    fxLayout="column wrap"
                                                    (click)="clearImage()"
                                                    matTooltip="Clear Image">
                                                    <mat-icon class="nav-link-icon">clear</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Signature: </span>
                                        <div fxFlex="50"
                                            fxLayout="column-reverse">

                                            <input type='file'
                                                id="signatureUpload"
                                                accept=".png, .jpg, .jpeg"
                                                #fileInput
                                                (change)="uploadSignature($event)"
                                                style="color: transparent;" />

                                            <div fxLayout="row"
                                                fxLayoutAlign="start">
                                                <img class="huge m-0"
                                                    src={{signatureUrl}}
                                                    style="margin-top: 10px !important;margin-bottom: 10px !important;margin-left: 20px !important; height: 50px !important;">
                                                <a *ngIf="signatureUrl !== 'assets/images/avatars/placeholder.png'"
                                                    style="cursor: pointer; border: 0; color: #29793C;"
                                                    fxLayout="column wrap"
                                                    (click)="clearSignature()"
                                                    matTooltip="Clear Image">
                                                    <mat-icon class="nav-link-icon">clear</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50"><span style="color: red;font-weight: bold;">* </span>Name:
                                        </span>
                                        <mat-form-field appearance="outline"
                                            fxFlex="50">
                                            <input class="leftA"
                                                id="profName"
                                                (keyup)="onFocusoutEvent()"
                                                value="{{basicData.Name}}"
                                                required
                                                matInput>
                                            <mat-error> REQUIRED</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="header p-12"
                                    fxLayoutAlign="space-between center">
                                    <button mat-raised-button
                                        (click)="cancelBasic()"
                                        style="color: #444444 !important;"
                                        class="mat-button mat-button-base primary-100 secondary-text">
                                        CANCEL
                                    </button>
                                    <button mat-raised-button
                                        (click)="OnSubmit()"
                                        class="mat-button mat-button-base primary-A400 dupl">
                                        UPDATE
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="basicData==null"
                            class="tab-content"
                            fxFlex="100">
                            <div fxLayoutAlign="center center">
                                <span class="h1"
                                    style="padding: 10px;">Something Went Wrong!! Please reload the page.
                                </span>
                            </div>
                        </div>
                    </mat-tab>

                    <!-- <mat-tab label="Contact Info">
                        <div class="tab-content"
                            fxFlex="100">

                            <div *ngIf="!showContactEdit"
                                class="fuse-card auto-width mb-16 mt-16"
                                fxLayout="column"
                                style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">
                                <div class="header p-12"
                                    fxLayoutAlign="space-between center">
                                    <div disabled
                                        class="mat-button mat-button-base primary-A400 secondary-text">

                                    </div>
                                    <button mat-raised-button
                                        (click)="EditContact()"
                                        class="mat-button mat-button-base primary-A400 dupl">
                                        EDIT
                                    </button>
                                </div>
                                <div class="p-12"
                                    fxLayout="column"
                                    fxLayoutAlign="start">
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;
                                        padding-top: 10px;padding-bottom: 10px;
                                        border-bottom: 1px solid #C5C6CB;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Emial: </span>
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">My Emial</span>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;
                                        padding-top: 10px;padding-bottom: 10px;
                                        border-bottom: 1px solid #C5C6CB;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Mobile No: </span>
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">My Mobile</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showContactEdit"
                                class="fuse-card auto-width mb-16 mt-16"
                                fxLayout="column"
                                style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                                <div class="p-12"
                                    fxLayout="column"
                                    fxLayoutAlign="start">
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Email: </span>
                                        <mat-form-field appearance="outline"
                                            fxFlex="50"
                                            class="readonly">
                                            <input class="leftA"
                                                required
                                                matInput>
                                            <mat-error> REQUIRED</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Mobile No: </span>
                                        <mat-form-field appearance="outline"
                                            fxFlex="50">
                                            <input class="leftA"
                                                required
                                                matInput>
                                            <mat-error> REQUIRED</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="header p-12"
                                    fxLayoutAlign="space-between center">
                                    <button mat-raised-button
                                        (click)="cancelContact()"
                                        style="color: #444444 !important;"
                                        class="mat-button mat-button-base primary-100 secondary-text">
                                        CANCEL
                                    </button>
                                    <button mat-raised-button
                                        class="mat-button mat-button-base primary-A400 dupl">
                                        UPDATE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-tab> -->

                    <mat-tab label="Change Password">
                        <div class="tab-content"
                            fxFlex="100">
                            <div class="fuse-card auto-width mb-16 mt-16"
                                fxLayout="column"
                                style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">
                                <form name="ChangePasswordForm"
                                    class="p-12"
                                    fxLayout="column"
                                    [formGroup]="ChangePasswordForm"
                                    style="max-width: 100% !important;">
                                    <!-- <div class="p-12"
                                        fxLayout="column"
                                        fxFlex="1 0 auto"> -->
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Current Password<span style="color: red;">* </span></span>
                                        <mat-form-field appearance="outline"
                                            fxFlex="50">
                                            <input class="leftA"
                                                formControlName="CurrentPassword"
                                                required
                                                matInput>
                                            <mat-error> Required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">New Password<span style="color: red;">* </span></span>
                                        <mat-form-field appearance="outline"
                                            fxFlex="50">
                                            <input class="leftA"
                                                formControlName="NewPassword"
                                                required
                                                matInput>
                                            <mat-error> Required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxFlex="1 0 auto"
                                        style="margin-top: 10px;margin-bottom: 10px;">
                                        <span class="name"
                                            style="font-size: 15px;"
                                            fxFlex="50">Confirm Password<span style="color: red;">* </span></span>
                                        <mat-form-field appearance="outline"
                                            fxFlex="50">
                                            <input class="leftA"
                                                formControlName="ConfirmPassword"
                                                required
                                                matInput>
                                            <mat-error> Required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- </div> -->
                                </form>
                                <div class="header p-12"
                                    fxLayoutAlign="space-between center">
                                    <div disabled
                                        class="mat-button mat-button-base primary-A400 secondary-text">

                                    </div>
                                    <button mat-raised-button
                                        (click)="SubmitPassword()"
                                        class="mat-button mat-button-base primary-A400 dupl">
                                        CHANGE PASSWORD
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </div>

        </div>
    </div>
</div>
