import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatTooltipModule } from '@angular/material/tooltip';



import { MatCheckboxModule } from '@angular/material/checkbox';
import { Service } from 'app/services';
import { map } from 'rxjs/operators';
import { UrlConfig } from 'app/util/url.config';

import { FuseDemoModule, FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PersonalInfoListComponent } from './Personal-Information-List.component';
import { PersonalInformationListService } from './Personal-Information-List.service';
import { AuthGuard } from 'app/auth';
import { Layouts } from 'app/enum/layouts.enum';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ViewDashboardGuard } from 'app/auth/viewDashboard.guard';

const routes = [
    {
        path: 'PersonalInfo',
        component: PersonalInfoListComponent,
        canActivate: [AuthGuard, ViewDashboardGuard],
        data: { layout: Layouts.Admin },
    }
];

@NgModule({
    declarations: [
        PersonalInfoListComponent,

    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatIconModule,

        MatFormFieldModule,

        MatInputModule,

        MatSnackBarModule,

        MatSlideToggleModule,

        MatSelectModule,

        MatRadioModule,

        MatDatepickerModule,

        MatTooltipModule,

        MatCheckboxModule,

        
        FuseSharedModule,

        FuseSidebarModule,

        FuseDemoModule,

        QuickPanelModule,
    ],
    exports: [
        PersonalInfoListComponent
    ],
    providers: [

        PersonalInformationListService,
        Service,


    ],
})

export class PersonalInfoListModule
{
}
