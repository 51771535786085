<div class="page-layout simple right-sidebar inner-scroll"
    fxFLex=100>

    <fuse-sidebar class="sidebar"
        name="my-demo-sidebar"
        position="right"
        lockedOpen="gt-md">
        <div class="content"
            style="background-color: #ffffff;">
            <my-demo-sidebar></my-demo-sidebar>
        </div>
    </fuse-sidebar>
    <!-- / SIDEBAR -->

    <!-- CENTER -->
    <div class="center">
        <my-demo-archive-header></my-demo-archive-header>
        <!-- <div fxLayoutAlign="space-between center"
            style="background-color: #fde28b; margin-bottom: 10px;">
            <span fxLayout="row"
                fxLayoutAlign="center center"
                style="padding: 10px; margin-left: 10px;">
                <mat-icon style="transform: rotate(180deg)">wb_incandescent</mat-icon>
                <span style="margin-left: 10px;">Archive Return form</span>
                <a style="cursor: pointer; border: 0; margin-left: 5px; margin-right: 5px; color: #000000; text-decoration: underline; font-weight: bold;"
                [routerLink]="'/Archive'"
                    fxLayout="row wrap"
                    class="nav-link">
                    Click here
                </a>
            </span>
        </div> -->

        <div fxLayoutAlign="space-between center"
            style="background-color: #dde1e2;">

            <span class="h1"
                style="padding: 10px; margin-left: 10px; ">
                Dashboard
            </span>

        </div>

        <div class="content">

            <div fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="start start"
                style="margin:12px !important; margin-right: 6px !important">

                <div *ngIf="BasicCard!=null"
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width mb-32"
                        [ngClass.gt-sm]="'mb-0 mr-12'"
                        style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                        <div class="p-8 pb-0"
                            fxLayout="row wrap"
                            fxLayoutAlign="start">

                            <div class="pr-16">
                                <div class="h3 secondary-text">{{BasicCard.item}}</div>
                            </div>

                        </div>

                        <div fxLayout="row"
                            fxLayout.gt-sm="column"
                            fxLayoutAlign="space-between center">
                            <div style="margin:5px">
                                <circle-progress [percent]="BasicCard.percent"
                                    [backgroundGradient]="true"
                                    [radius]="40"
                                    [space]="-10"
                                    [outerStrokeGradient]="false"
                                    [outerStrokeWidth]="10"
                                    [outerStrokeColor]="'#3282ce'"
                                    [outerStrokeGradientStopColor]="'#53a9ff'"
                                    [outerStrokeLinecap]="'butt'"
                                    [innerStrokeColor]="'#63b3eb'"
                                    [innerStrokeWidth]="10"
                                    [title]="BasicCard.percent"
                                    [titleFontSize]="12"
                                    [showSubtitle]="false"
                                    [animationDuration]="1000"
                                    [showUnits]="false"
                                    [showBackground]="false"
                                    [clockwise]="true"
                                    [responsive]="false"
                                    [lazy]="true">
                                </circle-progress>
                            </div>

                        </div>
                        <div fxLayout="row"
                            fxLayout.gt-sm="row"
                            fxLayoutAlign="end">
                            <div class="pr-16 pb-8 pt-8"
                                fxLayout="row"
                                fxLayoutAlign="center center">
                                Completed:&nbsp;&nbsp;<span style="color: #6F7B8B;">
                                    <a style="cursor: pointer; border: 0; color: #29793C;"
                                        (click)="OpenDialog(BasicCard.modalItems,'BasicCard')"
                                        matTooltip="Details">{{BasicCard.completed}}</a> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <a style="cursor: pointer; border: 0;"
                                    fxLayout="column wrap"
                                    (click)="OpenLink(BasicCard.modalItems)"
                                    matTooltip="Go to page">
                                    <mat-icon class="nav-link-icon"
                                        style="color: #039BE5;">arrow_forward</mat-icon>
                                </a>

                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="IncomeCard!=null"
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width mb-32"
                        [ngClass.gt-sm]="'mb-0 mr-12'"
                        style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                        <div class="p-8 pb-0"
                            fxLayout="row wrap"
                            fxLayoutAlign="start">

                            <div class="pr-16">
                                <div class="h3 secondary-text">{{IncomeCard.item}}</div>

                            </div>
                        </div>

                        <div fxLayout="row"
                            fxLayout.gt-sm="column"
                            fxLayoutAlign="space-between center">
                            <div style="margin:5px">
                                <circle-progress [percent]="IncomeCard.percent"
                                    [backgroundGradient]="true"
                                    [radius]="40"
                                    [space]="-10"
                                    [outerStrokeGradient]="false"
                                    [outerStrokeWidth]="10"
                                    [outerStrokeColor]="'#3282ce'"
                                    [outerStrokeGradientStopColor]="'#53a9ff'"
                                    [outerStrokeLinecap]="'butt'"
                                    [innerStrokeColor]="'#63b3eb'"
                                    [innerStrokeWidth]="10"
                                    [title]="IncomeCard.percent"
                                    [titleFontSize]="12"
                                    [showSubtitle]="false"
                                    [animationDuration]="1000"
                                    [showUnits]="false"
                                    [showBackground]="false"
                                    [clockwise]="true"
                                    [responsive]="false"
                                    [lazy]="true">
                                </circle-progress>
                            </div>
                            <div class="pr-16">
                                <span class="h2 secondary-text">৳{{IncomeCard.amount}} </span>

                            </div>
                        </div>
                        <div fxLayout="row"
                            fxLayout.gt-sm="row"
                            fxLayoutAlign="end">
                            <div class="pr-16 pb-8 pt-8"
                                fxLayout="row"
                                fxLayoutAlign="center center">
                                Completed:&nbsp;&nbsp;<span style="color: #6F7B8B;">
                                    <a style="cursor: pointer; border: 0; color: #29793C;"
                                        (click)="OpenDialog(IncomeCard.modalItems,'IncomeCard')"
                                        matTooltip="Details">{{IncomeCard.completed}}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <a style="cursor: pointer; border: 0;"
                                    fxLayout="column wrap"
                                    (click)="OpenLink(IncomeCard.modalItems)"
                                    matTooltip="Go to page">
                                    <mat-icon class="nav-link-icon"
                                        style="color: #039BE5;">arrow_forward</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="AssetCard!=null"
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width"
                        style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                        <div class="p-8 pb-0"
                            fxLayout="row wrap"
                            fxLayoutAlign="start">

                            <div class="pr-16">
                                <div class="h3 secondary-text">{{AssetCard.item}}</div>

                            </div>

                        </div>

                        <div fxLayout="row"
                            fxLayout.gt-sm="column"
                            fxLayoutAlign="space-between center">
                            <div style="margin:5px">
                                <circle-progress [percent]="AssetCard.percent"
                                    [backgroundGradient]="true"
                                    [radius]="40"
                                    [space]="-10"
                                    [outerStrokeGradient]="false"
                                    [outerStrokeWidth]="10"
                                    [outerStrokeColor]="'#3282ce'"
                                    [outerStrokeGradientStopColor]="'#53a9ff'"
                                    [outerStrokeLinecap]="'butt'"
                                    [innerStrokeColor]="'#63b3eb'"
                                    [innerStrokeWidth]="10"
                                    [title]="AssetCard.percent"
                                    [titleFontSize]="12"
                                    [showSubtitle]="false"
                                    [animationDuration]="1000"
                                    [showUnits]="false"
                                    [showBackground]="false"
                                    [clockwise]="true"
                                    [responsive]="false"
                                    [lazy]="true">
                                </circle-progress>
                            </div>
                            <div class="pr-16">
                                <span class="h2 secondary-text">৳{{AssetCard.amount}} </span>

                            </div>
                        </div>
                        <div fxLayout="row"
                            fxLayout.gt-sm="row"
                            fxLayoutAlign="end">
                            <div class="pr-16 pb-8 pt-8"
                                fxLayout="row"
                                fxLayoutAlign="center center">
                                Completed:&nbsp;&nbsp;<span style="color: #6F7B8B;">
                                    <a style="cursor: pointer; border: 0; color: #29793C;"
                                        (click)="OpenDialog(AssetCard.modalItems,'AssetCard')"
                                        matTooltip="Details">{{AssetCard.completed}}</a> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <a style="cursor: pointer; border: 0;"
                                    fxLayout="column wrap"
                                    (click)="OpenLink(AssetCard.modalItems)"
                                    matTooltip="Go to page">
                                    <mat-icon class="nav-link-icon"
                                        style="color: #039BE5;">arrow_forward</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div fxLayout="column"
                fxLayoutAlign="start"
                fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="start start"
                style="margin:12px !important; margin-right: 6px !important; margin-bottom: 30px;">

                <div *ngIf="WealthCard!=null"
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width mb-32"
                        [ngClass.gt-sm]="'mb-0 mr-12'"
                        style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                        <div class="p-8 pb-0"
                            fxLayout="row wrap"
                            fxLayoutAlign="start">

                            <div class="pr-16">
                                <div class="h3 primary-text"
                                    style="font-weight: bold;">{{WealthCard.item}}</div>
                            </div>

                        </div>

                        <div class="pt-16 pb-8"
                            fxLayout="column wrap"
                            fxLayoutAlign="center center">

                            <div class="p-4">
                                <div class="h2 primary-text"
                                    style="font-weight: bold;">{{WealthCard.amount}}</div>
                            </div>
                            <div class="p-4">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{WealthCard.description}}</div>
                            </div>

                        </div>

                        <div class="p-8 pb-0"
                            fxLayout="column"
                            fxLayoutAlign="start">

                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text">{{WealthCard.cardItems[0].item}}</div>
                                <div class="h4 primary-text">{{WealthCard.cardItems[0].amount}}</div>
                            </div>
                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text">{{WealthCard.cardItems[1].item}}</div>
                                <div class="h4 primary-text">{{WealthCard.cardItems[1].amount}}</div>
                            </div>
                        </div>

                        <div class="p-8 pt-24"
                            fxLayout="column"
                            fxLayoutAlign="start">

                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{WealthCard.cardItems[2].item}}</div>
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{WealthCard.cardItems[2].amount}}</div>
                            </div>
                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{WealthCard.cardItems[3].item}}</div>
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{WealthCard.cardItems[3].amount}}</div>
                            </div>
                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{WealthCard.cardItems[4].item}}</div>
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{WealthCard.cardItems[4].amount}}</div>
                            </div>
                        </div>

                    </div>

                </div>

                <div *ngIf="TaxCard!=null"
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width mb-32"
                        [ngClass.gt-sm]="'mb-0 mr-12'"
                        style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                        <div class="p-8 pb-0"
                            fxLayout="row wrap"
                            fxLayoutAlign="start">

                            <div class="p-4">
                                <div class="h3 primary-text"
                                    style="font-weight: bold;">{{TaxCard.item}}</div>
                            </div>

                        </div>

                        <div class="pt-16 pb-8"
                            fxLayout="column wrap"
                            fxLayoutAlign="center center">

                            <div class="p-4">
                                <div class="h2 primary-text"
                                    style="font-weight: bold;">{{TaxCard.amount}}</div>
                            </div>
                            <div class="p-4">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{TaxCard.description}}</div>
                            </div>

                        </div>

                        <div class="p-8 pb-0"
                            fxLayout="column"
                            fxLayoutAlign="start">

                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text">{{TaxCard.cardItems[0].item}}</div>
                                <div class="h4 primary-text">{{TaxCard.cardItems[0].amount}}</div>
                            </div>
                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text">{{TaxCard.cardItems[1].item}}</div>
                                <div class="h4 primary-text">{{TaxCard.cardItems[1].amount}}</div>
                            </div>
                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text">{{TaxCard.cardItems[2].item}}</div>
                                <div class="h4 primary-text">{{TaxCard.cardItems[2].amount}}</div>
                            </div>
                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text">{{TaxCard.cardItems[3].item}}</div>
                                <div class="h4 primary-text">{{TaxCard.cardItems[3].amount}}</div>
                            </div>
                        </div>

                        <div class="p-8 pt-24"
                            fxLayout="column"
                            fxLayoutAlign="start">

                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{TaxCard.cardItems[4].item}}</div>
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{TaxCard.cardItems[4].amount}}</div>
                            </div>
                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{TaxCard.cardItems[5].item}}</div>
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">{{TaxCard.cardItems[5].amount}}</div>
                            </div>

                        </div>

                    </div>

                </div>

                <div *ngIf="FileCard!=null"
                    class="widget"
                    fxFlex="100"
                    fxFlex.gt-sm="33">

                    <div class="fuse-card auto-width"
                        style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">

                        <div class="p-8 pb-0"
                            fxLayout="row wrap"
                            fxLayoutAlign="start">

                            <div class="p-4">
                                <div class="h3 primary-text"
                                    style="font-weight: bold;">{{FileCard.item}}</div>
                            </div>

                        </div>

                        <div class="pt-16 pb-8"
                            *ngIf="FileCard.cardItems.length!=0">
                            <div class="p-8"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                style="border-bottom: 1px solid #E1E1E1;">
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">Name</div>
                                <div class="h4 primary-text"
                                    style="font-weight: bold;">Upload<br>Status</div>
                            </div>

                        </div>

                        <div class="p-8 pb-0"
                            *ngIf="FileCard.cardItems.length!=0"
                            fxLayout="column"
                            fxLayoutAlign="start"
                            style="height: 200px !important;overflow-y: scroll;">

                            <div class="p-4"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                                *ngFor="let card of FileCard.cardItems"
                                style="border-bottom: 1px solid #c4c4c4;">
                                <div class="h4 primary-text"
                                    fxLayout="row"
                                    fxLayoutAlign="center center">
                                    {{card.item}}
                                </div>
                                <div class="h4 primary-text">
                                    <mat-icon class="nav-link-icon"
                                        style="color: #039BE5;"
                                        *ngIf="card.status=='filled'">check_circle_outline</mat-icon>
                                    <mat-icon class="nav-link-icon"
                                        style="color: #c4c4c4;"
                                        *ngIf="card.status=='blank'">check_circle_outline</mat-icon>
                                </div>
                            </div>
                        </div>

                        <div class="p-8 pb-0"
                            *ngIf="FileCard.cardItems.length==0"
                            fxLayout="column"
                            fxLayoutAlign="center"
                            style="height: 200px !important;overflow-y: scroll;">
                            <div class="h3">You do not have any recomended documents to be attached.
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
