import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';


import { bangla, english } from 'app/util/locale';
import { HomeTranslationLoaderService } from '../services/home-translation-loader.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit
{
  isLoggedInNav = false;
  userInfo: any;
  languages: any;

  selectedLanguage: any;
  constructor(
    private router: Router,
    private _translateService: TranslateService,
    private _homeTranslationLoaderService: HomeTranslationLoaderService,
  )
  {
    this.languages = [
      {
        id: 'en',
        title: 'English',
        name: 'Eng',
        flag: 'us'
      },
      {
        id: 'bn',
        title: 'বাংলা',
        name: 'বাং',
        flag: 'bn'
      }
    ];
    this._homeTranslationLoaderService.loadTranslations(english, bangla);

  }

  ngOnInit()
  {

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    let localCulture = localStorage.getItem('selectedLanguage');

    if (localCulture != null)
    {
      this._translateService.use(localCulture);
      this.selectedLanguage = _.find(this.languages, { id: localCulture });
    }
    else
    {
      localStorage.setItem('selectedLanguage', this._translateService.currentLang)
    }

    if (localStorage.getItem("userToken") === null)
    {
      this.isLoggedInNav = false;
    }
    else
    {
      this.isLoggedInNav = true;
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
  }
  Logout(): void
  {
    localStorage.removeItem('userToken');
    let lastLogin = {
      'lastUrl': this.router.url,
      'lastUser': localStorage.getItem('userName')
    };
    localStorage.setItem('lastLogin', JSON.stringify(lastLogin));
    localStorage.removeItem('userToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('profileData');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRefToken');
    localStorage.removeItem('TokenInfo');
    localStorage.removeItem('AssesseeId');
    localStorage.removeItem('archiveReadiness');
    // localStorage.removeItem('SwitchedReturnID');
    localStorage.removeItem('stepMenu');
    localStorage.removeItem('IsCurrentYear');
    
    localStorage.setItem('selectedLanguage', 'en')
    // window.location.reload();
    // this.router.navigate(['home']);
    window.location.href = `/home`;
  }

  setLanguage(lang): void
  {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;
    if (localStorage.getItem('selectedLanguage') != null)
    {
      localStorage.removeItem('selectedLanguage');
    }
    localStorage.setItem('selectedLanguage', lang.id);
    // Use the selected language for translations
    this._translateService.use(lang.id);
    // window.location.reload();
  }

  GotoLogin(): void
  {
    this.router.navigateByUrl('/login-home');
    // this.router.navigate(['/login'], { replaceUrl: true });
  }

  GotoDashboard(): void
  {
    // this.router.navigateByUrl('/Dashboard');


    // let url_D = "http://localhost:4200/Dashboard";
    let url_D = `/Dashboard`;
    let url_P = `/Dashboard`;
    // let url_P = `${window.location.hostname}/Dashboard`;
    window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;
    // this.router.navigate(['/login'], { replaceUrl: true });
  }

  GotoProfile(): void
  {
    // this.router.navigateByUrl('/Dashboard');


    // let url_D = "http://localhost:4200/Dashboard";
    let url_D = `/Profile`;
    let url_P = `/Profile`;
    // let url_P = `${window.location.hostname}/Dashboard`;
    window.location.href.includes('http://localhost') ? window.location.href = url_D : window.location.href = url_P;
    // this.router.navigate(['/login'], { replaceUrl: true });
  }

}
