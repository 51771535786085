<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
    style="height: 300px; ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>বেসরকারি চাকুরিজীবীর আয়কর রিটার্ন প্রস্তুতি। </h2>

                </div>
            </div>
        </div>
    </div>

</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/home/img/blog-image/blog_m02_1.jpg"
                            alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On: </span> <span style="color: #29793C;">August 16, 2021</span></li>
                                <li><span>Posted By: </span>
                                    <span style="color: #29793C;">Md. Lokman Hossain</span>
                                    <span>(Advocate, Supreme Court of Bangladesh.)</span>
                                </li>
                            </ul>
                        </div>

                        <h3>আয়কর রিটার্ন কি?</h3>
                        <p>আয়কর অধ্যাদেশ, ১৯৮৪ এর ৭৫ ধারার বিধান অনুযায়ী, কোন আয় বছরে যে কোন ব্যক্তির করমুক্ত সীমার
                            উর্ধ্বে আয় থাকলে পরবর্তী বছরের ১ জুলাই থেকে ৩০ নভেম্বরের মধ্যে সংশ্লিষ্ট আয়কর অফিসে রিটার্ন
                            দাখিল করতে হবে। এছাড়া কোন বেসরকারি চাকুরিজীবীর করমুক্ত সীমার উর্ধ্বে আয় না থাকলেও যদি তিনি
                            বেসরকারি প্রতিষ্ঠানে নির্বাহী বা ব্যবস্থাপনার পদে থাকেন অথবা তিনি কোন গাড়ির মালিক হন তবে
                            অবশ্য তাকে রিটার্ন দাখিল করতে হবে। আয়কর আইন অনুযায়ী জাতীয় রাজস্ব বোর্ড কর্তৃক নির্ধারিত ফরমে
                            আয়কর রিটার্ন দাখিল করতে হয়। আয়কর রিটার্ন বলতে একজন করদাতার বার্ষিক আয়, ব্যয় এবং সম্পদের
                            তথ্যাবলি আয়কর কর্তৃপক্ষের নিকট উপস্থাপনের নির্ধারিত ফরমকে বোঝায়। একজন করদাতা বিনামূল্যে আয়কর
                            অফিস থেকে রিটার্ন ফরম সংগ্রহ করতে পারেন। এছাড়া জাতীয় রাজস্ব বোর্ডের ওয়েব সাইট থেকেও রিটার্ন
                            ফরম ডাউনলোড করা যায়। করদাতা নিজের আয় নিজেই নিরূপণ করে প্রযোজ্য আয়কর পরিশোধ পূর্বক সার্বজনীন
                            স্বনির্ধারনী পদ্ধতিতে রিটার্ন দাখিল করলে আয়কর অফিস থেকে করদাতাকে যে প্রাপ্তি স্বীকারপত্র
                            প্রদান করা হয় তা-ই কর নির্ধারণী আদেশ (assessment order) বলে গণ্য হয়। কোন ব্যক্তি-করদাতা ৩০
                            নভেম্বরের মধ্যে রিটার্ন দাখিল করতে ব্যর্থ হলে আয়কর আইন অনুযায়ী নিরুপিত আয়করের উপর অতিরিক্ত
                            সরল সুদ, বিলম্ব সুদ ও ক্ষেত্রমতে জরিমানা আরোপের বিধান রয়েছে। </p>

                        <p>একজন বেসরকারি চাকুরিজীবীর আয়কর নির্ণয়ের জন্য তার মোট আয় থেকে করযোগ্য আয় নিরূপণ করতে হবে। মোট
                            করযোগ্য আয়ের উপর নির্ধারিত করহার প্রয়োগ করে আরোপযোগ্য আয়করের পরিমাণ নিরূপণ করা হয়। বর্তমানে
                            ব্যক্তি করদাতার সর্বনিম্ন করহার ৫% এবং সর্বোচ্চ কর হার ২৫%। অর্থ আইন, ২০২১ এর ধারা-৩৩
                            অনুসারে ২০২১-২০২২ কর বছরে একজন পুরুষ করদাতার মোট করযোগ্য আয়ের উপর আরোপযোগ্য আয়করের হার হবে
                            প্রথম ৩,০০,০০০ টাকা পর্যন্ত আয়ের উপর শূন্য হার বা করমু্ক্ত; পরবর্তী ১,০০,০০০ টাকা পর্যন্ত
                            আয়ের উপর ৫%; এর পরবর্তী ৩,০০,০০০ টাকা পর্যন্ত আয়ের উপর ১০%; পরবর্তী ৪,০০,০০০ টাকা পর্যন্ত
                            আয়ের উপর ১৫%; পরবর্তী ৫,০০,০০০ টাকা পর্যন্ত আয়ের উপর ২০% এবং অবশিষ্ট আয়ের উপর ২৫%। করদাতা
                            যদি তৃতীয় লিঙ্গ বা মহিলা করদাতা হন অথবা ৬৫ বছর বা তদূর্ধ্ব বয়সের যে কোন করদাতা হন তাহলে তার
                            আয়কর আরোপের ক্ষেত্রে প্রথম ৩,৫০,০০০ টাকা পর্যন্ত শূন্য হার বা করমুক্ত আয় হবে। এছাড়া
                            প্রতিবন্ধী ব্যক্তি করদাতার ক্ষেত্রে প্রথম ৪,৫০,০০০ টাকা এবং গেজেটভুক্ত যুদ্ধাহত মুক্তিযোদ্ধা
                            করদাতার ক্ষেত্রে প্রথম ৪,৭৫,০০০ টাকা পর্যন্ত শূন্য হার বা করমুক্ত আয় হবে। পরবর্তী ধাপসমূহে
                            সবার ক্ষেত্রে একই হার প্রযোজ্য হবে। আয় বছরে আয়কর আইন অনুযায়ী নির্ধারিত খাতে করদাতার বিনিয়োগ
                            বা চাঁদা থাকলে করদাতা বিনিয়োগজনিত কর রেয়াত পাবেন। করদাতার মোট আয় ১৫ লক্ষ টাকা বা তার কম হলে
                            অনুমোদনযোগ্য (eligible amount) বিনিয়োগের উপর ১৫% হারে; মোট আয় ১৫ লক্ষ টাকার বেশি হলে
                            অনুমোদনযোগ্য বিনিয়োগের উপর ১০% হারে কর রেয়াত পাবেন। মোট আয়ের উপর আরোপযোগ্য আয়করের পরিমাণ
                            থেকে কর রেয়াতের পরিমাণ বাদ দিলে নীট প্রদেয় করের পরিমাণ পাওয়া যাবে। </p>

                        <p>আয়কর অধ্যাদেশ, ১৯৮৪ এর ধারা-২১ এবং আয়কর বিধিমালা, ১৯৮৪ এর বিধি-৩৩ অনুযায়ী, একজন বেসরকারি
                            চাকুরিজীবী করদাতার প্রাপ্ত মূল বেতন, উৎসব ভাতা, পরিচারক ভাতা, সম্মানী ভাতা, ওভারটাইম ভাতা,
                            স্বীকৃত ভবিষ্য তহবিলে নিয়োগকর্তার প্রদত্ত চাঁদা এবং বিভিন্ন পারকুইজিট বেতন খাতের করযোগ্য আয়।
                            সহজ কথায়, বেসরকারি চাকুরিজীবী করদাতার কতিপয় ক্ষেত্র ব্যতিত নিয়োগকর্তার নিকট হতে প্রাপ্ত সকল
                            বেতন, ভাতা ও সুবিধাদি করযোগ্য আয় হিসেবে গণ্য হবে। আয়কর বিধিমালা, ১৯৮৪ এর বিধি-৩৩, বিধি-৩৩এ
                            থেকে বিধি-৩৩জে এর বিধান অনুযায়ী, বেসরকারি চাকুরিজীবী করদাতার বেতন খাতে কতিপয় আয়ের নির্ধারিত
                            সীমা বা পরিমাণ পর্যন্ত করমুক্ত আয় হিসেবে বিবেচিত হবে। যেমন, নগদ বাড়ী ভাড়া ভাতার ক্ষেত্রে মূল
                            বেতনের ৫০% অথবা বার্ষিক ৩,০০,০০০ টাকা এ দুটির মধ্যে যেটি কম সেটি করমুক্ত আয় হবে। এর অতিরিক্ত
                            অংক করযোগ্য হবে। তেমনি, নগদ চিকিৎসা ভাতার ক্ষেত্রে মূল বেতনের ১০% অথবা বার্ষিক ১,২০,০০০ টাকা
                            এ দুটির মধ্যে যেটি কম সেটি করমুক্ত আয় হবে। নগদ যাতায়াত ভাতার ক্ষেত্রে বার্ষিক ৩০,০০০ টাকা
                            পর্যন্ত করমুক্ত আয় হবে। স্বীকৃত ভবিষ্য তহবিলে নিয়োগকর্তা কর্তৃক প্রদত্ত চাঁদার সম্পূর্ণ অংক
                            করযোগ্য হবে। তবে স্বীকৃত ভবিষ্য তহবিলে অর্জিত সুদ প্রাপ্তির ক্ষেত্রে বেতনের ১/৩ অংশ পর্যন্ত
                            অর্জিত সুদ(এখানে বেতন বলতে মূল বেতন ও মহার্ঘ ভাতা বুঝাবে) অথবা সরকার কর্তৃক নির্ধারিত হার
                            ১৪.৫০%, এ দুয়ের মধ্যে যেটি কম সে পরিমাণ অংক করমুক্ত এবং এর অতিরিক্ত অংক করযোগ্য আয় হবে।
                            বেসরকারি চাকুরিজীবী করদাতা যদি যানবাহন সুবিধা প্রাপ্তির ক্ষেত্রে ব্যক্তিগত ব্যবহারের জন্য
                            নিয়োগকর্তার নিকট থেকে গাড়ী পান তাহলে মূল বেতনের ৫% বা বার্ষিক ৬০,০০০/- টাকা এ দুটির মধ্যে
                            যেটি বেশি সেটি সরাসরি নীট করযোগ্য আয় হবে। করদাতা যদি নিয়োগকর্তার নিকট হতে যানবাহন সুবিধা এবং
                            নগদ যাতায়াত ভাতা দুটোই প্রাপ্ত হন তাহলে মূল বেতনের ৫% বা বার্ষিক ৬০,০০০/- টাকা এ দুটির মধ্যে
                            যেটি বেশি সেটি এবং নগদ প্রাপ্ত সম্পূর্ণ যাতায়াত ভাতা দুটোই তার বেতন খাতে করযোগ্য আয় হিসেবে
                            গণ্য হবে। যদি করদাতা নিয়োগকর্তা হতে বিনামূল্যে বাসস্থান সুবিধা পেয়ে থাকেন তাহলে তার মূল
                            বেতনের ২৫% করযোগ্য আয় হিসেবে গণ্য হবে। আর যদি নিয়োগকর্তা হতে হ্রাসকৃত হারে বাসস্থান সুবিধা
                            প্রাপ্ত হন তাহলে তার মূল বেতনের ২৫% হতে প্রকৃত পরিশোধিত ভাড়া বাদ দিয়ে অবশিষ্ট অংক করযোগ্য আয়
                            হিসেবে গণ্য হবে। কোন ব্যক্তি-করদাতা কর্তৃক বাংলাদেশ শ্রম আইন, ২০০৬ এর আওতায় প্রতিষ্ঠিত
                            ওয়ার্কার্স পার্টিসিপেশন ফান্ড থেকে ৫০,০০০/- টাকা পর্যন্ত প্রাপ্ত অর্থ করমুক্ত আয় হবে।
                            তদুপরি, বেসরকারি চাকুরিজীবী করদাতা নিয়োগকর্তার নিকট থেকে যে কোন প্রাপ্ত অর্থ বা প্রাপ্ত
                            সুবিধার সমপরিমাণ আর্থিক মূল্য করযোগ্য আয় হিসেবে দেখাতে হবে। </p>

                        <p>এছাড়াও কোন বেসরকারি চাকুরিজীবীর বেতন খাতের আয় ব্যতিত অন্য কোন খাতে আয় যেমন, গৃহ-সম্পত্তি খাতে
                            কিংবা ব্যাংক ডিপোজিট হতে সুদ আয় থাকলেও তা করযোগ্য আয়ের সাথে যোগ হবে এবং এর উপর প্রযোজ্য হারে
                            কর আরোপিত হবে। বেতন খাতে করযোগ্য আয় নিরূপণের জন্য পৃথক তফসিল রয়েছে। বেতন খাতে করযোগ্য আয়
                            নিরূপণের জন্য তফসিল-২৪এ এবং বিনিয়োগজনিত কর রেয়াত নিরূপণের জন্য তফসিল-২৪ডি পূরণপূর্বক ব্যক্তি
                            করদাতার জন্য নিধারিত রিটার্ন ফরম আইটি-১১গ২০১৬ এর সাথে সংযুক্ত করতে হবে। এছাড়া করদাতার
                            পরিসম্পদ, দায় ও ব্যয় বিবরণী (আইটি-১০বি২০১৬) এবং জীবনযাত্রা সংশ্লিষ্ট ব্যয়ের
                            বিবরণী(আইটি-১০বিবি২০১৬) মূল রিটানের সাথে সংযুক্ত করতে হবে। </p>

                        <p>রিটার্ন দাখিলের সময় কোন বেসরকারি চাকুরিজীবী বিদেশে অবস্থান করলে বিদেশে অবস্থিত বাংলাদেশ
                            দূতাবাসেও রিটার্ন দাখিল করতে পারবেন। আয়কর অধ্যাদেশ, ১৯৮৪ এর ধারা-৭৫, উপধারা-(৩) অনুযায়ী, যে
                            কোন ব্যক্তি করদাতা তার পক্ষে উপযুক্ত ক্ষমতাপ্রাপ্ত ব্যক্তির মাধ্যমেও যথাসময়ে রিটার্ন দাখিল
                            করতে পারবেন। </p>

                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area"
                    id="secondary">

                    <section class="widget widget_karlson_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন"
                                class="thumb">
                                <span class="fullimage cover bg3"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2021-08-16">August 16, 2021</time>
                                <h4 class="title usmall"><a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R3' |
                                        translate }}</a>
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে"
                                class="thumb">
                                <span class="fullimage cover bg1"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2021-08-15">August 15, 2021</time>
                                <h4 class="title usmall"><a
                                        routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে">{{ 'BLOG.R1' |
                                        translate }}</a>
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <!-- <article class="item">
                            <a routerLink="/blog-details"
                                class="thumb">
                                <span class="fullimage cover bg3"
                                    role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">{{ 'BLOG.R8' | translate }}</a>
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article> -->
                    </section>

                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/সরকারি-চাকুরিজীবীর-আয়কর-রিটার্ন">{{ 'BLOG.R3' | translate }}</a></li>
                            <li><a routerLink="/নতুন-করদাতা-কখন-হবেন-এবং-সরকার-কি-সুবিধা-দিবে">{{ 'BLOG.R1' | translate
                                    }}</a></li>
                            <!-- <li><a routerLink="/blog-details">{{ 'BLOG.R8' | translate }}</a></li> -->
                        </ul>
                    </section>

                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->
