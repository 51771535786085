import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';
import { utils } from 'protractor';



@Injectable()
export class EtinOtherService
{
    constructor(private service: Service) { }

    toFormGroup(items: BaseControl<string>[]): FormGroup
    {
        const group: any = {};
        items.forEach(item =>
        {

            group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
                : new FormControl(item.value || '');
            if (item.hasOwnProperty('children'))
            {
                item.children.forEach(child =>
                {
                    group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
                        : new FormControl(child.value || '');
                });
            }
            if (item.hasOwnProperty('innerContent'))
            {
                item.innerContent.forEach(child =>
                {
                    group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
                        : new FormControl(child.value || '');
                });
            }
        });

        return new FormGroup(group);
    }

    getControlJson(id: any): Observable<BaseControl<string>[]>
    {
        let urlConfig = new UrlConfig();

        if (id != null)
        {
            return this.service.get(urlConfig.BASIC_INFO_Etin_Others_Control_Url).pipe(map(item => Utils.toControls(item.data)));

        } else
        {
            return this.service.get(urlConfig.BASIC_INFO_Etin_Others_Control_Url).pipe(map(item => Utils.toControls(item.data)));
        }

    }
    submitEtinJson(model: any): Observable<any>
    {
        let urlConfig = new UrlConfig();
        return this.service.post(urlConfig.BASIC_INFO_Etin_Others_Submit_Url, model);
    }
    getJson(): any
    {

        const jsonData = [
            {
                "control": "LabelControl",
                "key": "eTinInfo",
                "label": "E-TIN Information",
                "tooltip": "",
                "required": false,
                "order": 1
            },
            {
                "control": "TextboxControl",
                "key": "ETIN",
                "maxlength": "12",
                "value": "",
                "label": "E-TIN",
                "errorType": "",
                "message": "Required",
                "tooltip": "Electronic Taxpayer's Identification Number. It contains 12 digits.",
                "required": true,
                "order": 2
            },
            {
                "control": "TextboxGroupControl",
                "key": "taxZoneBox",
                "errorType": "",
                "label": "Tax Zone",
                "message": "",
                "tooltip": "Taxpayer should submit his/her return to a certain circle under a tax zone. You can find your Tax Zone in your ETIN Certificate.",
                "order": 3,
                "children": [
                    {
                        "control": "TextboxControl",
                        "key": "Circle",
                        "value": "",
                        "label": "Circle",
                        "errorType": "",
                        "message": "Required",
                        "tooltip": "",
                        "required": true,
                        "order": 1
                    },
                    {
                        "control": "TextboxControl",
                        "key": "Zone",
                        "value": "",
                        "label": "Zone",
                        "errorType": "",
                        "message": "Required",
                        "tooltip": "",
                        "required": true,
                        "order": 2
                    }
                ]
            },
            {
                "control": "LabelControl",
                "key": "residentialStatusLabel",
                "label": "Residential Status",
                "errorType": "",
                "message": "",
                "tooltip": "An individual is treated as a resident of Bangladesh if that person stays in Bangladesh for 182 days or more in any income year; or 90 days or more in an income year and that person has also previously resided in Bangladesh for a period of more than 365 days during the four preceding years.",
                "order": 4
            },
            {
                "control": "RadioButtonControl",
                "key": "Nationality",
                "label": "Select your nationality",
                "errorType": "",
                "value": "",
                "message": "Required",
                "tooltip": "",
                "required": true,
                "order": 5,
                "options": [
                    {
                        "key": "1",
                        "value": "Bangladeshi"
                    },
                    {
                        "key": "2",
                        "value": "Non-Bangladeshi"
                    }
                ]
            },
            {
                "control": "RadioButtonControl",
                "key": "stayed1",
                "label": "In the income year of 2020-2021, for how long did you stay in Bangladesh?",
                "errorType": "",
                "value": "",
                "message": "Required",
                "tooltip": "",
                "required": true,
                "order": 5,
                "options": [
                    {
                        "key": "1",
                        "value": "More Than 180 Days"
                    },
                    {
                        "key": "2",
                        "value": "Less Than 180 Days"
                    }
                ]
            },
            {
                "control": "RadioButtonControl",
                "key": "stayed2",
                "label": "Did you stay in Bangladesh at least 365 days (can be in multiple periods) in the last four income years?",
                "errorType": "",
                "value": "",
                "message": "",
                "tooltip": "",
                "required": false,
                "order": 6,
                "options": [
                    {
                        "key": "1",
                        "value": "Yes"
                    },
                    {
                        "key": "2",
                        "value": "No"
                    }
                ]
            },
            {
                "control": "ButtonControl",
                "key": "ResidentStatus",
                "label": "Your Residential Status: ",
                "errorType": "",
                "message": "",
                "tooltip": "",
                "required": false,
                "order": 7
            },
            {
                "control": "LabelControl",
                "key": "residentialArea",
                "label": "Residential Area (Minimum Tax)",
                "errorType": "",
                "message": "",
                "tooltip": "Taxpayer's residential area",
                "order": 8
            },
            {
                "control": "DropdownControl",
                "key": "ResidentialAreaId",
                "label": "Area of Resident",
                "value": "",
                "errorType": "",
                "message": "",
                "tooltip": "Taxpayer's residential area",
                "options": [
                    {
                        "key": "1",
                        "value": "Dhaka & Chittagong City Corporation"
                    },
                    {
                        "key": "2",
                        "value": "Other City Corporation"
                    },
                    {
                        "key": "3",
                        "value": "Other than the city corporation area"
                    }
                ],
                "required": true,
                "order": 9
            },
            {
                "control": "LabelControl",
                "key": "otherInformation",
                "label": "Other Information",
                "errorType": "",
                "message": "",
                "tooltip": "",
                "order": 10
            },
            {
                "control": "SlideToggleControl",
                "key": "WarWoundedFreedomFighter",
                "label": "Are You a Gazetted War-Wounded Fighter?",
                "icon": "card_giftcard",
                "value": null,
                "errorType": "",
                "message": "",
                "tooltip": "Whether the taxpayer is a gazetted war-wounded freedom fighter",
                "required": false,
                "order": 11
            },
            {
                "control": "SlideToggleControl",
                "key": "DisablePerson",
                "label": "Are You a Person With Disability?",
                "icon": "card_giftcard",
                "value": null,
                "errorType": "",
                "message": "",
                "tooltip": "A person with a disability means an individual registered as a person with a disability under section 31 of Persons with Disabilities Rights and Protection Act 2013.",
                "required": false,
                "order": 12
            },
            {
                "control": "SlideToggleControl",
                "key": "HasDisableChild",
                "label": "Are You a Parent/ Legal Guardian of a Person With Disability?",
                "value": null,
                "errorType": "",
                "message": "",
                "tooltip": "Person with disability means an individual registered as person with disability under section 31 of Persons with Disabilities Rights and Protection Act 2013.",
                "icon": "card_giftcard",
                "required": false,
                "order": 13
            },
            {
                "control": "TextboxGroupControl",
                "key": "guardianOfDisabled",
                "errorType": "",
                "label": "",
                "message": "",
                "tooltip": "",
                "order": 14,
                "children": [
                    {
                        "control": "CheckboxControl",
                        "key": "SpouseAvailExtendTaxExempt",
                        "label": "Spouse has availed the extended tax exemption",
                        "value": null,
                        "errorType": "",
                        "message": "",
                        "tooltip": "Spouse has availed the extended tax exemption",
                        "required": false,
                        "order": 1
                    },
                    {
                        "control": "TextboxControl",
                        "key": "NumberOfDisabledChildren",
                        "maxlength": "",
                        "value": "",
                        "label": "Number of disabled children",
                        "errorType": "",
                        "message": "Required",
                        "tooltip": "Number of disabled children",
                        "required": false,
                        "order": 2
                    }
                ]
            }
        ]
        



        return jsonData;
    }
    getControls(): Observable<BaseControl<string>[]>
    {
        const json = this.getJson();
        return Utils.getControls(json);
    }
}
