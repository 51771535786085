import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ListDialogComponent } from '@fuse/components/list-dialog/list-dialog.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { SidePanelSharedService } from 'app/services/SidePanelShared.service';
import { bangla, english } from 'app/util/locale';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import Utils from 'app/util/utils';
import { AppUser } from 'app/auth';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChangePasswordModel } from './ChangePassword.Model';
import { MyAccountService } from './my-account.service';
import { Meta, Title } from '@angular/platform-browser';
import { ChangeImageService } from 'app/services/changeImage.service';

@Component({
    selector: 'my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent
{

    lang = '';
    form: FormGroup;
    ChangePasswordForm: FormGroup;
    usertype = '';
    basicData: any;
    contactData: any;
    user: AppUser;
    @ViewChild('fileInput') el: ElementRef;
    imageUrl: any = 'assets/images/avatars/profile.jpg';
    signatureUrl: any = 'assets/images/avatars/placeholder.png';
    editFile: boolean = true;
    removeUpload: boolean = false;
    submitted: boolean = false;
    showBasicEdit: boolean = false;
    showContactEdit: boolean = false;
    FileBase64String: any;
    FileName: any;
    SignatureBase64String: any;
    SignatureFileName: any;
    blobPath = "https://allpicture.blob.core.windows.net/digitax/profile-pic/";
    sigblobPath = "https://allpicture.blob.core.windows.net/digitax/signature/";

    registrationForm = this.fb.group({
        file: [null]
    })

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _fuseSidebarService: FuseSidebarService,
        private _formControlService: MyAccountService,
        private back: BackButtonService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private eventEmitterService: EventEmitterService,
        private _route: ActivatedRoute,
        private sidePanelSharedService: SidePanelSharedService,
        private _fuseNavigationService: FuseNavigationService,
        public fb: FormBuilder,
        private _formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        private changeImageService: ChangeImageService,

    )
    {
        // this.user = Utils.getUserInfo();
        // if (this.user.ActiveRoleId == "8")
        // {
        //     this._router.navigate(['/Dashboard']);
        // }
        this.meta.addTags([
            { name: 'description', content: 'digiTax my account' },
            { name: 'author', content: 'digiTax' },
            { name: 'keywords', content: 'digiTax my account' }
        ]);
        this.setTitle('digiTax | My Account');
        this.lang = localStorage.getItem('selectedLanguage');

        this.usertype = Utils.getUserInfo().ActiveRoleId;

        if (this.usertype == "7")
        {
            if (this.lang == 'en')
            {
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', navEng.ProNavigation_Eng);
                this._fuseNavigationService.setCurrentNavigation('main');
            }
            if (this.lang == 'bn')
            {
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', navBng.ProNavigation_Bng);
                this._fuseNavigationService.setCurrentNavigation('main');
            }
        }
        this.ChangePasswordForm = this._formBuilder.group(new ChangePasswordModel());

    }

    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this._spinner.show();
        this.getPageData('no');
    }

    EditBasic()
    {
        this.showBasicEdit = true;
    }

    cancelBasic()
    {
        // debugger;
        if (this.basicData.PhotoUrl != this.blobPath)
        {
            this.imageUrl = this.basicData.PhotoUrl;
        }
        else 
        {
            this.imageUrl = 'assets/images/avatars/profile.jpg';
        }

        if (this.basicData.SignatureUrl != this.sigblobPath)
        {
            this.signatureUrl = this.basicData.SignatureUrl;
        }
        else 
        {
            this.signatureUrl = 'assets/images/avatars/placeholder.png';
        }

        this.showBasicEdit = false;

    }

    EditContact()
    {
        this.showContactEdit = true;
    }

    cancelContact()
    {
        this.showContactEdit = false;
    }

    onFocusoutEvent()
    {
        let profName = (<HTMLInputElement>document.getElementById('profName')).value;

        // console.log(profName, 'profName');

    }

    getPageData(tes): void
    {
        this._formControlService.getProfileBasic().subscribe((data: any) =>
        {
            if (data != null)
            {
                this._spinner.hide();
            }
            if (data.isError === false)
            {
                console.log('get succesfull');
            }
            else
            {
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            if (data.isError === false && data.responseCode == "200")
            {
                // debugger;
                this.basicData = data.data;

                if (this.basicData.PhotoUrl != this.blobPath)
                {
                    this.imageUrl = this.basicData.PhotoUrl;

                    this.FileName = this.basicData.PhotoUrl.replace(this.blobPath, '');
                }
                else
                {
                    this.imageUrl = 'assets/images/avatars/profile.jpg';
                }

                if (this.basicData.SignatureUrl != this.sigblobPath)
                {
                    this.signatureUrl = this.basicData.SignatureUrl;

                    this.SignatureFileName = this.basicData.SignatureUrl.replace(this.sigblobPath, '');
                }
                else
                {
                    this.signatureUrl = 'assets/images/avatars/placeholder.png';
                }


                let uInfo = JSON.parse(localStorage.getItem('userInfo'));

                let pUrl = this.basicData.PhotoUrl.replace(this.blobPath, '');
                uInfo.PhotoUrl = pUrl;
                localStorage.setItem('userInfo', JSON.stringify(uInfo));
                if (tes == "yes")
                {
                    window.location.reload();
                    // this.changeImageService.changeMessage(true);
                    // this.showBasicEdit = false;
                }

                // this._formControlService.getProfileContact().subscribe((data: any) =>
                // {
                //     if (data != null)
                //     {
                //         this._spinner.hide();
                //     }
                //     if (data.isError === false)
                //     {
                //         console.log('get succesfull');
                //     }
                //     else
                //     {
                //         this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                //             verticalPosition: 'top',
                //             duration: 2000
                //         });
                //     }
                //     if (data.isError === false && data.responseCode == "200")
                //     {
                //         this.contactData = data.data;

                //     }
                // }, (error: any) =>
                // {
                //     console.log(error, 'error');
                // });
            }
        }, (error: any) =>
        {
            console.log(error, 'error');
            this._spinner.hide();
        });
    }

    uploadSignature(event)
    {
        let height = 0;
        let width = 0;

        let reader = new FileReader(); // HTML5 FileReader API
        let file = event.target.files[0];
        if (event.target.files && event.target.files[0])
        {
            // console.log(file, 'file');

            reader.readAsDataURL(file);

            // When file uploads set it to file formcontrol
            reader.onload = () =>
            {

                const img = new Image();
                img.src = reader.result as string;
                img.onload = () =>
                {
                    height = img.naturalHeight;
                    width = img.naturalWidth;
                    // console.log('Width and Height', width, height);
                    if (height > 50 && width > 200)
                    {
                        this._matSnackBar.open("Image height cant be over 50 pixel & Image width cant be over 200 pixel!", 'Close', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                    }
                    else
                    {
                        this.signatureUrl = reader.result;
                        this.SignatureBase64String = (<string>reader.result).split(',')[1];
                        // console.log(this.SignatureBase64String);
                        this.SignatureFileName = file.name;
                        // console.log(this.SignatureFileName);
                    }
                };


            }
            // ChangeDetectorRef since file is loading outside the zone
            this.cd.markForCheck();
        }

    }

    uploadFile(event)
    {
        let file = event.target.files[0];
        if (file.size > 51200)
        {
            this._matSnackBar.open("File Size Limit is 50 Kilobytes(KB)!", 'Close', {
                verticalPosition: 'top',
                duration: 2000,
            });

        }
        else
        {

            let reader = new FileReader(); // HTML5 FileReader API
            let file = event.target.files[0];
            if (event.target.files && event.target.files[0])
            {
                // console.log(file, 'file');

                reader.readAsDataURL(file);

                // When file uploads set it to file formcontrol
                reader.onload = () =>
                {
                    this.imageUrl = reader.result;
                    this.FileBase64String = (<string>reader.result).split(',')[1];
                    // console.log(this.FileBase64String);
                    this.FileName = file.name;
                    // console.log(this.FileName);

                    // this.registrationForm.patchValue({
                    //     file: reader.result
                    // });
                    // this.editFile = false;
                    // this.removeUpload = true;

                }
                // ChangeDetectorRef since file is loading outside the zone
                this.cd.markForCheck();
            }
        }
    }




    // Function to remove uploaded file
    removeUploadedFile()
    {
        let newFileList = Array.from(this.el.nativeElement.files);
        this.imageUrl = 'https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg';
        this.editFile = true;
        this.removeUpload = false;
        this.registrationForm.patchValue({
            file: [null]
        });
    }


    OnSubmit(): void
    {
        let profName = (<HTMLInputElement>document.getElementById('profName')).value;
        if (profName != '')
        {
            let obj = {
                // "Id": "",
                "Name": profName,
                "FileName": this.FileName == null ? "" : this.FileName,
                "FileBase64String": this.FileBase64String == null ? "" : this.FileBase64String,
                "SignatureFileName": this.SignatureFileName == null ? "" : this.SignatureFileName,
                "SignatureBase64String": this.SignatureBase64String == null ? "" : this.SignatureBase64String,
            };
            // console.log(obj, 'obj');
            // let stringed = JSON.stringify(obj);
            this._formControlService.submitProfileBasic(obj)
                .subscribe((data: any) =>
                {
                    if (data.isError === false)
                    {
                        console.log('submit succesfull');
                    }
                    else
                    {
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                    }
                    if (data.isError === false && data.responseCode == "200")
                    {
                        this.getPageData('yes');
                        // this.cancelBasic();
                        this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                            verticalPosition: 'top',
                            duration: 5000
                        });
                    }
                }, (error: any) =>
                {
                    this._spinner.hide();
                    console.log(error, 'error');
                });
        }
        else
        {
            this._matSnackBar.open("Please Enter Your Name!", 'Close', {
                verticalPosition: 'top',
                duration: 2000,
            });
        }
    }

    checkpassword(val)
    {
        const regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/);
        const valid = regex.test(val);
        return valid;
    }

    SubmitPassword(): void
    {

        if (!this.ChangePasswordForm.valid)
        {
            this.ChangePasswordForm.markAllAsTouched();
        }

        let obj = this.ChangePasswordForm.getRawValue();
        // console.log(obj, 'obj');

        let stringed = JSON.stringify(obj);
        // console.log(stringed, 'stringed');

        this._spinner.show();
        if (obj.CurrentPassword == "")
        {
            this._spinner.hide();
            this._matSnackBar.open("Please enter your current password", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                verticalPosition: 'top',
                duration: 2000
            });
        }
        else
        {
            if (this.checkpassword(obj.NewPassword) == false)
            {
                this._spinner.hide();
                this.ChangePasswordForm.controls['NewPassword'].setErrors({ 'incorrect': true });
                this._matSnackBar.open("Password should have minimum 6 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                if (obj.NewPassword != obj.ConfirmPassword)
                {
                    this._spinner.hide();
                    this.ChangePasswordForm.controls['ConfirmPassword'].setErrors({ 'incorrect': true });
                    this._matSnackBar.open("Passwords do not match", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
                else
                {
                    // debugger;
                    this._formControlService.changePasword(stringed)
                        .subscribe((data: any) =>
                        {
                            if (data.Error === false)
                            {
                                this._spinner.hide();
                                this.ChangePasswordForm.reset();
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_DATA : bangla.data.COMMON.BUTTON_DATA, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 5000
                                });
                            }
                            else
                            {
                                this._spinner.hide();
                                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                    verticalPosition: 'top',
                                    duration: 5000
                                });
                            }

                        }, (error: any) =>
                        {
                            console.log(error, 'error');
                            this._spinner.hide();
                            this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                                verticalPosition: 'top',
                                duration: 5000
                            });
                        });
                }
            }
        }
    }

    clearImage()
    {
        (<HTMLInputElement>document.getElementById('imageUpload')).value = "";
        // console.log(this.imageUrl, ' this.imageUrl');

        this.imageUrl = "assets/images/avatars/profile.jpg";
        // console.log(this.imageUrl, ' this.imageUrl');
        this.FileBase64String = "";
        this.FileName = "";
    }

    clearSignature()
    {
        (<HTMLInputElement>document.getElementById('signatureUpload')).value = "";
        // console.log(this.signatureUrl, ' this.signatureUrl');

        this.signatureUrl = "assets/images/avatars/placeholder.png";
        // console.log(this.signatureUrl, ' this.signatureUrl');
        this.SignatureBase64String = "";
        this.SignatureFileName = "";
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

