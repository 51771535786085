import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import {  Observable } from 'rxjs';
import Utils from 'app/util/utils';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';

@Injectable()
export class TaxDeductedAtSourceListService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  deleteControl(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();

    return this.service.delete(urlConfig.TAX_RA_Tax_Deducted_At_Source_Delete_Url + `${id}`);
  }

  getControlJson(): Observable<BaseControl<string>[]>
  {
      let urlConfig = new UrlConfig();
      return this.service.get(urlConfig.TAX_RA_Tax_Deducted_At_Source_List_Url);
  }

  getJson(): any
  {
    const jsonData =
    {
      "Header": {
        "Header1": "Actions",
        "Header2": "Amount",
        "Header3": "Description",
        "Header4": "Is Income Under 82C",

      },
      "Body": [
        {
          "Actions": "",
          "Amount": "",
          "Description": "",
          "IsIncomeUnder82C": "",
        }
      ],
      "TotalIncome": "",
      "TotalRows": ""
    }


    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
