<!-- Start Main Banner Area -->

<div class="page-title-area page-title-bg1 home-tag ptb-100 extra-pb"
    style="height: 600px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">

                <div class="page-title-content">

                    <div style="margin-bottom: 0;
                                position: relative;
                                font-size: 40px;
                                line-height: 1.2;
                                -weight: 700;
                                font-weight: bolder;
                                color: #fff;">
                        {{ 'HOME.TAGLINE_1' | translate }}</div>
                    <p style="text-align: center; color: #fff; font-size: 1.2rem;">{{ 'HOME.TAGLINE_2' |
                        translate }}</p>

                    <div class="row justify-content-center">
                        <div class="btn-box">
                            <a routerLink="/register-home"
                                class="default-btn">{{ 'COMMON.ITEM_4' | translate }} <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End Main Banner Area -->

<section class="solution-area-1"
    style="padding-top: 100px;padding-bottom: 100px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <!-- <span class="sub-title">{{ 'HOME.V_TAG_8' | translate }}</span> -->
                    <h2>{{ 'HOME.V_TAG_1' | translate }}</h2>
                    <!-- <p>
                    </p>
                    <a routerLink="/how-it-works"
                        class="default-btn">{{ 'HOME.V_TAG_2' | translate }}<span></span></a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item"
                        src="https://www.youtube-nocookie.com/embed/5zcsHtGD5Ys"
                        allowfullscreen></iframe>
                </div>
                <!-- <div class="solution-video-1">
                    <a href="https://www.youtube.com/watch?v=5zcsHtGD5Ys"
                        class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div> -->
            </div>

        </div>
    </div>
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-box-3">
                    <div style="font-size: 100px !important; line-height: 0; color: #1689D9;">
                        <i class="flaticon-tap"></i>
                    </div>
                    <div style="padding-top: 30px;">
                        <p style="color: #fff;">{{ 'HOME.V_TAG_3' | translate }} "<a routerLink="/register-home"
                                style="color: #00ffff;">{{ 'HOME.V_TAG_4' | translate }}</a>"</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-box-3">
                    <div style="font-size: 100px !important; line-height: 0; color: #fb7756;">
                        <i class="flaticon-resume"></i>
                    </div>
                    <div style="padding-top: 30px;">
                        <p style="color: #fff;">{{ 'HOME.V_TAG_5' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-box-3">
                    <div style="font-size: 100px !important; line-height: 0; color: #facd60;">
                        <i class="flaticon-conversation"></i>
                    </div>
                    <div style="padding-top: 30px;">
                        <p style="color: #fff;">{{ 'HOME.V_TAG_6' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-featured-box-3">
                    <div style="font-size: 100px !important; line-height: 0; color: #1ac0c6;">
                        <i class="flaticon-happy"></i>
                    </div>
                    <div style="padding-top: 30px;">
                        <p style="color: #fff;">{{ 'HOME.V_TAG_7' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>

    </div> -->
</section>

<!-- Start Featured Services Area -->
<section class="featured-services-area ptb-100">
    <div class="d-table"
        style="margin-bottom: 70px;">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'HOME.OFFER_SECTION' | translate }}</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="container">

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon">
                        <i class="flaticon-man"></i>
                    </div>
                    <h3>{{ 'HOME.OFFER_1' | translate }}</h3>
                    <p>{{ 'HOME.OFFER_1_TEXT' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon color-1ac0c6">
                        <i class="flaticon-businessman"></i>
                    </div>
                    <h3>{{ 'HOME.OFFER_3' | translate }}</h3>
                    <p>{{ 'HOME.OFFER_3_TEXT' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">

                <div class="single-featured-box">
                    <div class="icon color-facd60">
                        <i class="flaticon-team"></i>
                    </div>
                    <h3>{{ 'HOME.OFFER_2' | translate }}</h3>
                    <p>{{ 'HOME.OFFER_2_TEXT' | translate }}</p>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="features-area ptb-100 pt-0">
    <div class="container">
        <div class="d-table"
            style="margin-bottom: 70px;">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>{{ 'HOME.CHOOSE_SECTION' | translate }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box-2">
                    <div class="icon">
                        <i class="flaticon-tap-1"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_1' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_1_TEXT' | translate }}</p>

                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box-2">
                    <div class="icon color-facd60">
                        <i class="flaticon-discount"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_2' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_2_TEXT' | translate }}</p>

                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-featured-box-2">
                    <div class="icon color-1ac0c6">
                        <i class="flaticon-real-time"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_3' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_3_TEXT' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box-2">
                    <div class="icon">
                        <i class="flaticon-save"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_10' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_10_TEXT' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box-2">
                    <div class="icon color-facd60">
                        <i class="flaticon-file"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_11' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_11_TEXT' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-featured-box-2">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_4' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_4_TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box-2">
                    <div class="icon color-facd60">
                        <i class="flaticon-upload"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_5' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_5_TEXT' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box-2">
                    <div class="icon color-1ac0c6">
                        <i class="flaticon-documents"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_6' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_6_TEXT' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-featured-box-2">
                    <div class="icon">
                        <i class="flaticon-cloud"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_7' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_7_TEXT' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box-2">
                    <div class="icon color-facd60">
                        <i class="flaticon-responsive"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_8' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_8_TEXT' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-featured-box-2">
                    <div class="icon color-1ac0c6">
                        <i class="flaticon-24-hours-support"></i>
                    </div>
                    <h3>{{ 'HOME.CHOOSE_9' | translate }}</h3>
                    <p>{{ 'HOME.CHOOSE_9_TEXT' | translate }}</p>
                </div>
            </div>
        </div>
    </div>

</section>

<section class="features-area ptb-100 pt-0">
    <div class="container">

        <div class="page-title-content"
            style="padding-bottom: 65px;">
            <h2>{{ 'PRICING.HEADER' | translate }}</h2>
            <ul>
                <li><span class="sub-title">{{ 'PRICING.SUB_HEADER' | translate }}</span></li>
            </ul>
        </div>
        <div class="tab features-list-tab">

            <ul class="tabs"
                style="justify-content: center !important;
            align-items: center !important;">

                <li><a href="#"
                        class="bg-fa7070">
                        <i class="flaticon-man"></i>
                        <span>{{ 'PRICING.PLAN_1' | translate }}</span>
                    </a></li>
                <li><a href="#"
                        class="bg-c679e3">
                        <i class="flaticon-team"></i>
                        <span>{{ 'PRICING.PLAN_2' | translate }}</span>
                    </a></li>

            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row table-responsive">
                        <table class="table table-striped table-light table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col"
                                        style="font-size: 20px;">{{ 'PRICING.TABLE_HEADER_PLANS' | translate }}</th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_FREE' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_0' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STARTER' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_499' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STANDARD' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_999' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_PREMIUM' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_1999' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_YEAR' | translate }}</span>
                                        </strong>
                                    </th>

                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_2' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_3' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_4' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_5' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_6' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_17' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_16' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row"></th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
                <div class="tabs_item">
                    <!-- <div class="page-title-content">
                        <h2> Coming Soon</h2>
                    </div> -->
                    <div class="row table-responsive">
                        <table class="table table-striped table-light table-hover table-bordered">

                            <thead>
                                <tr>
                                    <th scope="col"
                                        style="font-size: 20px;">{{ 'PRICING.TABLE_HEADER_PLANS' | translate }}</th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_FREE' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_0' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STARTER' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_250' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_STANDARD' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_200' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>
                                    <th scope="col"
                                        style="text-align: center;"><span
                                            style="font-size: 20px !important; font-weight: normal;">{{
                                            'PRICING.TABLE_HEADER_PREMIUM' | translate }}</span><br>
                                        <strong>
                                            <sup>৳</sup><b style="font-size: 18px;">{{ 'PRICING.TABLE_HEADER_150' |
                                                translate
                                                }}</b><span style="color: #666666; font-weight: normal;">/{{
                                                'PRICING.TABLE_HEADER_FILE_YEAR' | translate }}</span>
                                        </strong>
                                    </th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_1' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_8' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_9' | translate }}<br>
                                        {{ 'PRICING.PLAN_TEXT_9a' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_10' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_11' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_12' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_13' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_14' | translate }}</th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <i class="fas fa-check"
                                            style="color: green;"></i>

                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">{{ 'PRICING.PLAN_TEXT_15' | translate }}</th>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_5c' | translate }}

                                    </td>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_50c' | translate }}

                                    </td>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_100c' | translate }}

                                    </td>
                                    <td class="text-center"
                                        style="color: black; font-weight: bold;">
                                        {{ 'PRICING.TABLE_HEADER_UN' | translate }}

                                    </td>
                                </tr>
                                <!-- <tr>
                                    <th scope="row"
                                        style="font-weight: normal;">Pricing Structure</th>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                        </ul>

                                    </td>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                            <li><span style="color: green;">&bull; </span> Next 45 clients ৳250 / per
                                                file yearly.</li>
                                        </ul>

                                    </td>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                            <li><span style="color: green;">&bull; </span>Next 45 clients ৳250 / per
                                                file yearly.</li>
                                            <li><span style="color: green;">&bull; </span>Next 50 clients ৳200/ per file
                                                yearly.</li>
                                        </ul>

                                    </td>
                                    <td class="text-left"
                                        style="color: black; font-weight: bold;">
                                        <ul>
                                            <li><span style="color: green;">&bull; </span>First 5 clients free.</li>
                                            <li><span style="color: green;">&bull; </span>Next 45 clients ৳250 / per
                                                file yearly.</li>
                                            <li><span style="color: green;">&bull; </span>Next 50 clients ৳200/ per file
                                                yearly.</li>
                                            <li><span style="color: green;">&bull; </span>All clients after 100 is
                                                ৳150/ per file yearly.</li>
                                        </ul>

                                    </td>
                                </tr> -->
                                <tr>
                                    <th scope="row"></th>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                    <td class="text-center"
                                        style="width: 145px !important;">
                                        <div
                                            style="background-color: #29793C; border: 0; padding: 10px; border-radius: 5px;">
                                            <a routerLink="/register-home"
                                                style="text-align: center; color: white;">{{ 'COMMON.ITEM_2' | translate
                                                }}</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<!-- Start Partner Area -->
<section class="partner-area">
    <!-- <div class="d-table"
        style="margin-bottom: 70px;">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ 'COMMON.ITEM_3' | translate }}</h2>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container">

        <div class="row align-items-center"
            style="justify-content: center !important;align-items: center !important;">
            <!-- <div class="single-partner-item"> -->
            <img src="assets/home/img/partners.jpeg"
                alt="image">
            <!-- <img src="assets/home/img/partner-image/baksh_1.png"
                    alt="image"> -->

            <!-- </div> -->
            <!-- <div class="single-partner-item">

                <img src="assets/home/img/partner-image/visa.png"
                    alt="image">

            </div>
            <div class="single-partner-item">

                <img src="assets/home/img/partner-image/master.png"
                    alt="image">

            </div>
            <div class="single-partner-item">

                <img src="assets/home/img/partner-image/ssl_1.png"
                    alt="image">

            </div> -->
        </div>
    </div>

</section>
<!-- End Partner Area -->
