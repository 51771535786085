import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseControl } from 'app/models/dynamic-form';
import { Observable } from 'rxjs';
import Utils from 'app/util/utils';
import Config from 'app/util/config';
import { Service } from 'app/services';
import { UrlConfig } from 'app/util/url.config';
import { map } from 'rxjs/operators';

@Injectable()
export class CashAssetsService
{
  constructor(private service: Service) { }

  toFormGroup(items: BaseControl<string>[]): FormGroup
  {
    const group: any = {};
    items.forEach(item =>
    {

      group[item.key] = item.required ? new FormControl(item.value || '', Validators.required)
        : new FormControl(item.value || '');
      if (item.hasOwnProperty('children'))
      {
        item.children.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
      if (item.hasOwnProperty('innerContent'))
      {
        item.innerContent.forEach(child =>
        {
          group[child.key] = child.required ? new FormControl(child.value || '', Validators.required)
            : new FormControl(child.value || '');
        });
      }
    });

    return new FormGroup(group);
  }

  submitCashAssets(model: any, id: string): Observable<any>
  {
    let urlConfig = new UrlConfig();
    if (id == null)
    {
      return this.service.post(urlConfig.ASSET_Cash_Assets_Submit_Url, model);
    }
    else
    {
      return this.service.post(urlConfig.ASSET_Cash_Assets_Submit_Url + `/${id}`, model);
    }
  }




  getControlJson(id: any): Observable<BaseControl<string>[]>
  {
    let urlConfig = new UrlConfig();
    if (id != null)
    {
      return this.service.get(urlConfig.ASSET_Cash_Assets_Control_Url + id).pipe(map(item => Utils.toControls(item.data)));

    } else
    {
      return this.service.get(urlConfig.ASSET_Cash_Assets_Control_Url).pipe(map(item => Utils.toControls(item.data)));
    }

  }

  getJson(): any
  {
    const jsonData = [
      {
        "control": "LabelGroupControl",
        "key": "sources",
        "errorType": "",
        "required": false,
        "label": "",
        "message": "",
        "tooltip": "",
        "order": 1,
        "children": [
          {
            "control": "LabelControl",
            "key": "sourcesheader1",
            "label": "Type",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 1
          },
          {
            "control": "LabelControl",
            "key": "sourcesheader2",
            "label": "Amount",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 2
          },
          {
            "control": "LabelControl",
            "key": "sourcesheader3",
            "label": "Description (Optional)",
            "errorType": "",
            "message": "",
            "tooltip": "",
            "required": false,
            "order": 3
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "CashInHand",
        "label": "Cash In Hand(Notes and Currencies)",
        "errorType": "",
        "message": "",
        "tooltip": "Add value",
        "order": 2,
        "children": [
          {
            "control": "TextboxControl",
            "key": "CashInHandAmount",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add value",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "CashInHandDescription",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add description",
            "required": false,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "CashAtBank",
        "label": "Cash At Bank(Banks, cards and other electronic cash)",
        "errorType": "",
        "message": "",
        "tooltip": "Add value",
        "order": 3,
        "children": [
          {
            "control": "TextboxControl",
            "key": "CashAtBankAmount",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add value",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "CashAtBankDescription",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add description",
            "required": false,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "OtherFunds",
        "label": "Other Funds(Provident fund and other funds)",
        "errorType": "",
        "message": "",
        "tooltip": "Add value",
        "order": 4,
        "children": [
          {
            "control": "TextboxControl",
            "key": "OtherFundsAmount",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add value",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "OtherFundsDescription",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add description",
            "required": false,
            "order": 2
          }
        ]
      },
      {
        "control": "TextboxGroupControl",
        "key": "OtherDeposits",
        "label": "Other Deposits(Other Deposits, balance and advance)",
        "errorType": "",
        "message": "",
        "tooltip": "Add value",
        "order": 5,
        "children": [
          {
            "control": "TextboxControl",
            "key": "OtherDepositsAmount",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add value",
            "required": false,
            "order": 1
          },
          {
            "control": "TextboxControl",
            "key": "OtherDepositsDescription",
            "label": "",
            "errorType": "",
            "message": "Required",
            "tooltip": "Add value",
            "required": false,
            "order": 2
          }
        ]
      }
    ];
    return jsonData;
  }
  getControls(): Observable<BaseControl<string>[]>
  {
    const json = this.getJson();
    return Utils.getControls(json);
  }

}
