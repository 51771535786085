import { Component, HostListener } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BackButtonService } from 'app/services/BackButtonService';
import { EventEmitterService } from 'app/services/eventemiter.service';
import { bangla, english } from 'app/util/locale';
import Utils from 'app/util/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchiveService } from './Archive.service';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUser } from 'app/auth';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import * as navEng from 'app/navigation/ProNavigation_Eng.json';
import * as navBng from 'app/navigation/ProNavigation_Bng.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { log } from 'console';
import { QuickPanelService } from 'app/layout/components/quick-panel/quick-panel.service';
import { forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PaymentService } from '../Payment/Payment.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import Config from 'app/util/config';
import { saveAs } from 'file-saver';

@Component({
    selector: 'Archive',
    templateUrl: './Archive.component.html',
    styleUrls: ['./Archive.component.scss']
})
export class ArchiveComponent
{
    pform: FormGroup;

    showTaxAmountDiv: boolean;
    lang = '';
    errMessage = '';
    getData: any;
    hasArchivedData = false;
    user: AppUser;

    hasRead: boolean;
    showTerm: boolean;

    nCount: number = 5;
    // countMin: number = 5;
    // countMax: number = 10000000;

    nCountTier1: number = 5;
    nCountTier2: number = 0;
    nCountTier3: number = 0;
    nCountTier4: number = 0;

    nPlanPrice1 = 0;
    nPlanPrice2 = 0;
    nPlanPrice3 = 0;
    nPlanPrice4 = 0;

    nPriceTier2: number = 0;
    nPriceTier3: number = 0;
    nPriceTier4: number = 0;
    totalPrice: number = 0;


    hasTransaction: boolean;
    lastTransactionData: any;
    TranDate: any;

    showDetail = false;

    // CurrentPlan: any;

    // CurrentPlanName: any;

    CurrentPlan = 0;

    NoOfClientFiles = 0;

    CurrentPlanName = "free";
    // CurrentPlanName = "starter";
    // CurrentPlanName = "standard";
    // CurrentPlanName = "premium";

    AllowDownload: boolean;

    IsUpgraded: boolean;

    hasPlan: boolean;
    FreePlan: any;
    StarterPlan: any;
    StandardPlan: any;
    PremiumPlan: any;
    PremiumPlanSum: any;

    IsError: any;
    ErrorMessage: any;
    OrderId: any;
    PaymentStatus: any;
    form: FormGroup;

    dDate: any;
    archiveStat: any;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private meta: Meta,
        private title: Title,
        private _formControlService: ArchiveService,
        private _paymentControlService: PaymentService,
        private _quickPanelService: QuickPanelService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _route: ActivatedRoute,
        private http: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
        private _formBuilder: FormBuilder,

        private eventEmitterService: EventEmitterService,
        private back: BackButtonService,
    )
    {

        // this.meta.addTags([
        //     { name: 'description', content: "digiTax User Payment" },
        //     { name: 'author', content: 'digiTax' },
        //     { name: 'keywords', content: "digiTax User Payment" }
        // ]);
        // this.setTitle("digiTax | User Payment");
        // this.form = this._formBuilder.group({
        //     count: [this.nCount.toString(), Validators.required,],
        // });

        // this.pform = this._formBuilder.group({
        //     inputCount: [''],
        // });
        // debugger;
        // this.pform.patchValue({
        //     inputCount: ""
        // });

        // this.showTaxAmountDiv = false;
        // this.hasRead = false;
        // this.showTerm = false;
        // this.dDate = moment(new Date()).format("DD-MM-YYYY hh:mm A");
        // console.log(this.dDate, 'dDate');




    }
    public setTitle(newTitle: string)
    {
        this.title.setTitle(newTitle);
    }

    ngOnInit(): void
    {
        this._fuseTranslationLoaderService.loadTranslations(english, bangla);
        this.lang = localStorage.getItem('selectedLanguage');
        this.archiveStat = JSON.parse(localStorage.getItem('archiveReadiness'));
        // console.log(this.archiveStat, 'archivestat ngOnInit');

        // debugger;
        this.getPageData(this.archiveStat.CurrentYearId);

    }

    getPageData(CurrentYearId): void
    {
        // debugger;

        this._formControlService.getControlJson(CurrentYearId)
            .subscribe((data: any) =>
            {
                // console.log(data, 'data');
                if (data != null)
                {
                    this._spinner.hide();
                }
                if (data.isError === false)
                {
                    this.getData = data.data;
                    if (Utils.isEmptyObject(this.getData) || this.getData.length == 0)
                    {
                        this.hasArchivedData = false;
                    } else
                    {
                        this.hasArchivedData = true;
                    }
                    // console.log(this.getData, 'this.getData ');

                }
                else
                {
                    console.log('error');
                    this._spinner.hide();
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
            });
    }


    ArchiveReturn(): any
    {
        this._spinner.show();
        this._paymentControlService.getIsAssetFilled()
            .subscribe((data: any) =>
            {
                this._spinner.hide();
                // console.log(data, 'data');

                if (data.isError === false)
                {

                    if (data.data.IsFilledUpAsset == true)
                    {
                        this.OnSubmit();
                    }
                    else
                    {
                        this.ConfirmSubmit()
                    }
                }
                else
                {
                    console.log('error');
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this._spinner.hide();
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                this._spinner.hide();
            });
    }

    ConfirmSubmit(): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = this.lang == 'en' ? english.data.COMMON.CONFIRM_ARCHIVE : bangla.data.COMMON.CONFIRM_ARCHIVE;

        this.confirmDialogRef.componentInstance.confirmTitle = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.confirmButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CONFIRM : bangla.data.COMMON.BUTTON_CONFIRM;

        this.confirmDialogRef.componentInstance.cancelButton = this.lang == 'en' ? english.data.COMMON.BUTTON_CANCEL : bangla.data.COMMON.BUTTON_CANCEL;

        this.confirmDialogRef.afterClosed().subscribe(result =>
        {
            if (result)
            {
                this.OnSubmit();
            }
            this.confirmDialogRef = null;
        });

    }


    OnSubmit(): void
    {
        this._spinner.show();
        this._formControlService.sendToArchive({})
            .subscribe((data: any) =>
            {
                if (data != null)
                {
                    this._spinner.hide();
                }

                if (data.isError === false)
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? "Data Archived" : "Data Archived", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                    this.SwitchReturn();

                }
                else
                {
                    this._spinner.hide();
                    this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }

            }, (error: any) =>
            {
                console.log(error, 'error');
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });



    }

    SwitchReturn(): void
    {
        this._spinner.show();
        this._formControlService.switchReturn().subscribe((data: any) =>
        {
            localStorage.setItem('userToken', data.access_token);
            localStorage.setItem('userRefToken', data.refresh_token);

            delete data['access_token'];
            delete data['refresh_token'];
            delete data['token_type'];
            delete data['expires_in'];
            delete data['error'];
            delete data['.issued'];
            delete data['.expires'];

            localStorage.setItem('userInfo', JSON.stringify(data));
            localStorage.setItem('TokenInfo', data.UserId);
            localStorage.setItem('userName', data.UserName);
            this._spinner.hide();
            if (data.ActiveRoleId == "8")
            {
                this.fetchUpdatedArchive();
            }

        })
    }

    fetchUpdatedArchive(): void
    {
        this._spinner.show();
        this._quickPanelService.getArchiveReadiness().subscribe((data: any) =>
        {
            if (Utils.isEmptyObject(data.data) || data.data == null)
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
            else
            {
                this._spinner.hide();
                let ar = JSON.stringify(data.data);
                localStorage.setItem('archiveReadiness', ar);

                // let as = JSON.parse(localStorage.getItem('archiveReadiness'));
                // console.log(as, 'archiveReadiness in fetchUpdatedArchive');

                this.archiveStat = data.data;
                // console.log(this.archiveStat, 'archivestat in fetchUpdatedArchive');

                // this.getPageData(data.data.CurrentYearId);
                window.location.reload();
            }
        })
    }


    downloadPDF(data): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.REPORT_DL + `0` + `/${data.ReturnId}`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    let fileName = `${data.PayerName}_${data.TaxYearName}_Return_file.pdf`;

                    saveAs(res, `${fileName}`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                console.log(error, 'error');

            })
    }

    summaryPDF(data): any
    {
        this._spinner.show();
        const authToken = localStorage.getItem('userToken');
        return this.http.get(Config.SUMMARY_DL + `0` + `/${data.ReturnId}`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).subscribe(
            res =>
            {
                if (res.type == 'application/pdf' || res.type == 'image/jpeg' || res.type === 'image/png')
                {
                    let reader = new FileReader();
                    let base64data = null;
                    reader.readAsDataURL(res);
                    reader.onloadend = function ()
                    {
                        base64data = reader.result;
                        
                        let pdfWindow = window.open("")
                        pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src=" +
                            base64data + "></iframe>"
                        )
                    }
                }
                else
                {
                    let fileName = `${data.PayerName}_${data.TaxYearName}_Summary_file.pdf`;

                    saveAs(res, `${fileName}`);
                }
                this._spinner.hide();
            },
            error =>
            {
                this._spinner.hide();
                this._matSnackBar.open(this.lang == 'en' ? english.data.COMMON.BUTTON_WRONG : bangla.data.COMMON.BUTTON_WRONG, this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
                    verticalPosition: 'top',
                    duration: 2000
                });
                console.log(error, 'error');

            })
    }


    NotPaid(): void
    {
        this._matSnackBar.open("You have not paid for this service", this.lang == 'en' ? english.data.COMMON.BUTTON_OK : bangla.data.COMMON.BUTTON_OK, {
            verticalPosition: 'top',
            duration: 2000
        });
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event)
    {
        this.back.setParam(true, 'fuse');
    }
}

