
export class CapitalGainsClass
{
  constructor(
    private _AmountReceived: number,
    private _TaxDeductedAtSource_TDS: number,
    private _TaxableIncome: number,
    private _GainType: string,
    private _GainWeight1: string,


  )
  {

  }
  
  public get AmountReceived(): number
  {
    return this._AmountReceived;
  }
  public set AmountReceived(x: number)
  {
    this._AmountReceived = x;
  }

  public get TaxDeductedAtSource_TDS(): number
  {
    return this._TaxDeductedAtSource_TDS;
  }
  public set TaxDeductedAtSource_TDS(x: number)
  {
    this._TaxDeductedAtSource_TDS = x;
  }

  public get TaxableIncome(): number
  {
    let ti = 0;

    if (this.GainType == "1" || this.GainType == "2" || this.GainType == "3" || this.GainType == "4" || this.GainType == "5")
    {    
      ti = 0;
    }

    if (this.GainType == "6" || this.GainType == "7")
    {
      if (this.GainWeight1 == "1")
      {
        ti = 0;
      } else
      {
        ti = this.AmountReceived
      }
    }   
    return this._TaxableIncome = ti;
  }  
  public set TaxableIncome(x: number)
  {
    this._TaxableIncome = x;
  }

  public get GainType(): string
  {
    return this._GainType;
  }
  public set GainType(x: string)
  {
    this._GainType = x;
  }

  public get GainWeight1(): string
  {
    return this._GainWeight1;
  }
  public set GainWeight1(x: string)
  {
    this._GainWeight1 = x;
  }
}
